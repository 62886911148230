import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { formatStringDate } from '../../utilities';
import { Link } from 'react-router';
import ImagePlaceholder from '../_helpers/Image/ImagePlaceholder';

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${rem(20)};
  align-items: start;
  ${({ theme }) => theme.media('xs')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Thumbnail = styled(ImagePlaceholder)``;

const Item = styled(Link)`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  padding: ${rem(10)};
  background: ${({ theme }) => theme.color.gray97};
  color: inherit;
  height: 100%;
  &:hover {
    text-decoration: none;
  }
  ${({ theme }) => theme.media(0, 'xs')} {
    &:last-child {
      margin-bottom: ${rem(10)};
    }
  }

  ${({ theme }) => theme.media('xs')} {
    flex-direction: row;
    display: block;
  }

  ${({ theme }) => theme.media('md')} {
    display: flex;
  }
`;

const ItemContent = styled.article`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: ${rem(20)};

  ${({ theme }) => theme.media('xs')} {
    display: block;
  }

  ${({ theme }) => theme.media('md')} {
    display: flex;
  }
`;

const ItemDate = styled.p`
  margin: 0;
  font-size: ${rem(12)};
`;

const ItemTitle = styled.h4`
  font-size: ${rem(16)};
  margin: ${rem(8)} 0;
`;

const ItemAnotation = styled.div`
  p {
    font-size: ${rem(14)};
    margin: 0;
  }
`;

const ThumbnailWrapper = styled.div`
  ${({ theme }) => theme.media(0, 'xs')} {
    align-self: center;
    margin-bottom: ${rem(10)};
  }
  ${({ theme }) => theme.media('xs')} {
    float: left;
    width: 50%;
    margin-right: 20px;
  }

  ${({ theme }) => theme.media('md')} {
    flex: 1;
    margin-right: 0px;
  }
`;

interface SchoolArticlesList {
  schoolArticles: any;
}

const SchoolArticlesList = (props: SchoolArticlesList) => {
  const { schoolArticles } = props;

  const placeholder = '/images/placeholder-100.png';
  const placeholder2x = '/images/placeholder-100@2x.png';

  return (
    <>
      {schoolArticles?.articles?.length ? (
        <Wrapper>
          {schoolArticles.articles.map((item, index) => {
            const url = item.url.startsWith('/') ? item.url : '/' + item.url;

            return (
              <Item key={index} to={url}>
                <ThumbnailWrapper>
                  <Thumbnail
                    src={item.image}
                    placeholder={placeholder}
                    retinaPlaceholder={placeholder2x}
                    w={250}
                    h={151}
                  />
                </ThumbnailWrapper>
                <ItemContent>
                  <ItemDate>
                    {formatStringDate(item.custom_date, 'D. MMM YYYY')}
                  </ItemDate>
                  <ItemTitle>{item.name}</ItemTitle>
                  <ItemAnotation
                    dangerouslySetInnerHTML={{
                      __html: item.json_content?.anotation,
                    }}
                  />
                </ItemContent>
              </Item>
            );
          })}
        </Wrapper>
      ) : (
        ''
      )}
    </>
  );
};

export default SchoolArticlesList;
