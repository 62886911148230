import * as React from 'react';
import { __ } from 'react-i18n';
import {
  Table,
  TableWrapper,
  Body,
} from '../../_helpers/Table/ResponsiveTable';
import Alert from '../../Alert/Alert';
import OrderHistorySingle from './OrderHistorySingle';

interface AccountHistoryListProps {
  orders: any;
  user: any;
  dispatch: any;
}

const OrderHistoryList = (props: AccountHistoryListProps) => {
  const { orders, user } = props;

  //const company = isCompany(user);

  return (
    <TableWrapper>
      {orders && orders.orders && orders.orders.length > 0 ? (
        <Table>
          {/*
          <Head>
            <Tr>
              <Th>{__('Obj. číslo')}</Th>
              <Th>{__('Dátum')}</Th>
              <Th>{company ? __('Suma (bez DPH)') : __('Suma (s DPH)')}</Th>
              <Th>{__('Spôsob platby')}</Th>
              <Th className="empty" />
            </Tr>
          </Head>
          */}
          <Body>
            {orders.orders.map((order, index) => (
              <OrderHistorySingle
                order={order}
                user={user}
                key={index}
                dispatch={props.dispatch}
              />
            ))}
          </Body>
        </Table>
      ) : (
        <Alert> {__('Zatiaľ ste nevytvorili žiadnu objednávku')} </Alert>
      )}
    </TableWrapper>
  );
};

export default OrderHistoryList;
