import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { Button2, StyledFooter } from './ChooseModal';
import { connect } from 'react-redux';
import { prop } from '../../../utilities';
import Overlay from '../Overlay/Overlay';
import { ContentModal, ContentModalBody } from './constants';
import { Loader } from '../Loader/Loader';
import { loadCmsContent } from '../../../containers/App/actions';
import { connectSsr } from 'ssr-service';

interface ModalProps {
  dispatch: any;
  sitemapUid: string;
  onCloseButtonClick?: (event) => void;
  cmsContent?: any;
  cmsContentLoading?: boolean;
}

const CmsContentModal = (props: ModalProps) => {
  const {
    dispatch,
    sitemapUid,
    onCloseButtonClick,
    cmsContent,
    cmsContentLoading,
  } = props;

  React.useEffect(() => {
    dispatch(loadCmsContent(sitemapUid));
  }, [props.sitemapUid]);

  const title = prop(
    cmsContent,
    `${sitemapUid}.content.json_content.name`,
    __('Obsah'),
  );
  const body = prop(cmsContent, `${sitemapUid}.content.json_content.body`, '');

  if (cmsContentLoading) {
    return (
      <Overlay>
        <Loader loading={cmsContentLoading} />
      </Overlay>
    );
  } else {
    return (
      <ContentModal
        title={title}
        body={
          <ContentModalBody
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        }
        footer={
          <StyledFooter>
            <Button2 className="primary lg" onClick={onCloseButtonClick}>
              {__('Zavrieť')}
            </Button2>
          </StyledFooter>
        }
        onCloseButtonClick={onCloseButtonClick}
      />
    );
  }
};

const mapStateToProps = (state) => {
  return {
    cmsContent: state.general.cmsContent,
    cmsContentLoading: state.general.cmsContentLoading,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CmsContentModal' })(CmsContentModal),
);
