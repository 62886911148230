import * as React from 'react';
import MetaTags from '../../components/MetaTags/MetaTags';
import styled from 'styled-components';
import { WithRouterProps } from 'react-router';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import Tabs from '../../components/_helpers/Tabs/Tabs';
import {
  TITLE_ACCOUNT_BULK_ORDER,
  TITLE_ACCOUNT_PRODUCTS_FOR_SETS,
  TITLE_ACCOUNT_SETS,
  TITLE_ACCOUNT_SETS_MANAGEMENT,
  URL_ACCOUNT_BULK_ORDER,
  URL_ACCOUNT_PRODUCTS_FOR_SETS,
  URL_ACCOUNT_SETS,
} from '../../constants';
import { prop } from '../../utilities';
import { theme } from '../../theme/theme';
import { rem } from 'polished';

const SetsContainer = styled.div``;

const ChildrenWrapper = styled.div`
  margin-top: ${rem(20)};
`;

interface SetsParentProps {
  dispatch: any;
  children: any;
  setTitle: any;
}

class SetsParent extends React.Component<SetsParentProps & WithRouterProps> {
  constructor(props) {
    super(props);
    props.setTitle(TITLE_ACCOUNT_SETS);
  }

  public render() {
    const currentPath = prop(this, 'props.router.location.pathname', '/');
    const { children, dispatch } = this.props;

    return (
      <SetsContainer>
        <MetaTags tags={{ title: `Daffer.sk` }} />
        <Tabs
          tabs={[
            {
              name: TITLE_ACCOUNT_SETS_MANAGEMENT,
              url: URL_ACCOUNT_SETS,
            },
            {
              name: TITLE_ACCOUNT_PRODUCTS_FOR_SETS,
              url: URL_ACCOUNT_PRODUCTS_FOR_SETS,
            },
            {
              name: TITLE_ACCOUNT_BULK_ORDER,
              url: URL_ACCOUNT_BULK_ORDER,
            },
          ]}
          currentPath={currentPath}
          dispatch={dispatch}
          selectedBackgroundColor={theme.color.secondary}
          selectedTextColor={theme.color.black}
        />
        <ChildrenWrapper>
          {React.cloneElement(children, { ...this.props })}
        </ChildrenWrapper>
      </SetsContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SetsParent' })(SetsParent),
);
