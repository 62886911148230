import * as React from 'react';

import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { __ } from 'react-i18n';

const Wrapper = styled.div`
  position: absolute;
  background: #2f71fc;
  border-radius: ${rem(8)};
  left: ${(props) => props.leftMobile || 50}%;
  bottom: ${(props) => props.bottomMobile || 100}%;
  transform: translateX(-50%);
  padding: ${rem(6)} ${rem(36)} ${rem(6)} ${rem(12)};
  z-index: 1;
  ${({ theme }) => theme.media('xs')} {
    left: ${(props) => props.left || 50}%;
    bottom: ${(props) => props.bottom || 100}%;
  }
  ${(props) =>
    props.position === 'bottom' &&
    css`
      &:after {
        content: '';
        position: absolute;
        bottom: ${rem(1)};
        left: 50%;
        width: 0;
        height: 0;
        border: ${rem(12)} solid transparent;
        border-top-color: #2f71fc;
        border-bottom: 0;
        margin-left: ${rem(-12)};
        margin-bottom: ${rem(-12)};
      }
    `}
  ${(props) =>
    props.position === 'top' &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: ${rem(1)};
        left: 50%;
        width: 0;
        height: 0;
        border: ${rem(12)} solid transparent;
        border-bottom-color: #2f71fc;
        border-top: 0;
        margin-left: ${rem(-12)};
        margin-top: ${rem(-12)};
      }
    `}
`;

const PopupContent = styled.p`
  position: relative;
  width: 200px;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  margin: 0;
  & > p {
    margin: 0;
  }
`;

const CrossImage = styled.img`
  position: absolute;
  top: ${rem(8)};
  right: ${rem(8)};
  &:hover {
    cursor: pointer;
  }
`;

interface PopupProps {
  content: string;
  left?: number;
  leftMobile?: number;
  bottom?: number;
  bottomMobile?: number;
  onClose?: () => void;
  position?: string;
}

export const Popup = (props: PopupProps) => {
  return (
    <Wrapper
      left={props.left}
      leftMobile={props.leftMobile}
      bottom={props.bottom}
      bottomMobile={props.bottomMobile}
      position={props.position || 'bottom'}
    >
      <PopupContent
        dangerouslySetInnerHTML={{
          __html: props.content,
        }}
      />
      <CrossImage
        src="/images/cross.png"
        onClick={props.onClose}
        alt={__('Zavrieť hint')}
      />
    </Wrapper>
  );
};
