import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { ContentContainerBlock } from '../_helpers/Contact/ContactStyles';
import { Content } from '../_layout/Page/ContentContainer';
import { BackIcon, Footer, NextButton } from './Cart1';
import {
  CartFormCol,
  CartFormRow,
  CartHeading,
  CartInput,
  CartInputWrapper,
  CartLabel,
  CartMainCol,
  CartSummaryCol,
} from './CartStyles';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import { Col, Row } from '../../theme/libraries/grid';
import CartSummary from './CartSummary';
import { CheckboxStyled } from '../_helpers/form/Checkbox';
import { SelectStyled } from '../_helpers/form/Select';
import { TextareaStyledBlock } from '../_helpers/form/Textarea';
import { Field, Form, FormSpy } from 'react-final-form';
import {
  addItemToCart,
  createOrder,
  isProblemTypeAvailability,
  productInCartDelete,
  recognizeCustomerCard,
  setLastValues,
  updateCart,
} from '../../containers/Cart/actions';
import Alert from '../Alert/Alert';
import API, { ThenArg } from '../../services/API';
import { BackButton } from './Cart2';
import { push } from 'react-router-redux';
import {
  debounce,
  flatten,
  formatPrice,
  getSchoolName,
  isCartCointainsSkvelkoSet,
  isCartCointainsUniversalSkvelkoSet,
  isChildCompany,
  isCompanyOrSchool,
  isDeliveryToSchool,
  isEmailValid,
  isRetail,
  isTeacher,
  prop,
  scrollToElement,
  scrollToTop,
  showPriceWithVat,
} from '../../utilities';
import { ChematCustomerType } from '../../library/ChematCustomerType';
import {
  openBusinessConditionsModal,
  openGdprModal,
  setArticleModal,
} from '../../containers/App/actions';
import EmailAutocomplete from '../_helpers/form/EmailAutocomplete';
import { HeaderSimpleType } from '../_layout/Header/HeaderSimple';
import AlertAvailability, {
  AlertAvailabilityType,
} from '../Alert/AlertAvailability';
import {
  composeValidators,
  dic,
  email,
  ico,
  phone,
  phoneMobile,
  postalCode,
  required,
  requiredIfIcoPresent,
  street,
} from '../../utilities/validators';
import { Loader } from '../_helpers/Loader/Loader';
import { ErrorPopup } from '../_helpers/form/FormStyles';
import { parsePostalCode } from '../../utilities/formParsers';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import CartInfoBox from './CartInfoBox';
import ChooseModal from '../_helpers/Modal/ChooseModal';
import { SKVELKO_COMPETITION_ARTICLE_ID } from '../../constants';
import 'react-perfect-scrollbar/dist/css/styles.css';
import ConfirmModal from '../_helpers/Modal/ConfirmModal';
import cookie from 'react-cookies';
import { CART_RESTRICTED_COD_ID } from '../../containers/Cart/constants';
import { Thumb } from './CartProductList';

export const Heading = styled(CartHeading)`
  &.in-content {
    margin-top: 0;
    margin-bottom: ${rem(8)};
    font-weight: bold;
  }
  &.small {
    font-size: ${rem(14)};
    ${({ theme }) => theme.media('sm')} {
      font-size: ${rem(18)};
    }
  }
`;

const ShippingPaymentCol = styled(CartMainCol)``;

const SummaryCol = styled(CartSummaryCol)``;

const FormWrapper = styled.div`
  max-width: ${rem(600)};
`;

const CartFormInnerRow = styled(Clearfix)`
  margin-right: ${rem(-5)};
  margin-left: ${rem(-5)};
`;

const CartFormInnerCol = styled(Col)`
  padding-right: ${rem(5)};
  padding-left: ${rem(5)};
  &.left {
    width: ${({ theme }) => theme.grid.col.col8};
  }
  &.right {
    width: ${({ theme }) => theme.grid.col.col4};
  }
  ${({ theme }) => theme.media('xs')} {
    &.right-md {
      width: ${({ theme }) => theme.grid.col.col4};
    }
  }
`;

const CartSelect = styled(SelectStyled)`
  width: 100%;
  ${(props: any) =>
    props.disabled
      ? 'border-color: #eeeeee; color: #b1b1b1 !important; background: white !important;'
      : ''}
`;

const AlertStyled = styled(Alert)`
  margin: ${rem(10)} 0;
`;

const CartTextarea = styled(TextareaStyledBlock)``;

const DeliverySchoolAddress = styled.article`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.color.blue};
  p {
    margin: ${rem(2)};
  }
`;

const ContentContainerBlockCart = styled(ContentContainerBlock)`
  padding-top: ${rem(5)};
  padding-bottom: ${rem(10)};
  ${({ theme }) => theme.media(1024)} {
    padding-top: ${rem(10)};
    padding-bottom: ${rem(20)};
  }
  &.noSeparator {
    padding-top: 0;
    ${({ theme }) => theme.media(1024)} {
      padding-top: 0;
    }
    border: none;
  }
`;

const SkvelkoSchoolBox = styled(ContentContainerBlockCart)`
  padding-bottom: ${rem(0)};
  ${({ theme }) => theme.media(1024)} {
    padding-bottom: ${rem(0)};
  }
`;

const FormCart = styled.form`
  margin-top: ${rem(12)};
  ${({ theme }) => theme.media(1024)} {
    margin-top: ${rem(24)};
  }
`;

const DeliveryPoint = styled.div``;
const DeliveryPointLabel = styled.div`
  font-weight: bold;
`;
const DeliveryPointDescription = styled.div``;

const CheckboxWrapper = styled.div`
  display: flex;
  align-self: center;
  padding: ${rem(6)} 0;
`;

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #7d7d7da8;
`;

const CartInfoBoxWrapper = styled.div`
  margin-bottom: ${rem(20)};
  margin-left: ${rem(15)};
  margin-right: ${rem(15)};
`;

const LockSvg = styled.img`
  position: absolute;
  top: ${rem(10)};
  right: ${rem(8)};
  width: ${rem(20)};
  fill: #b1b1b1;
`;

const SupportSchoolCheckbox = styled(CartFormRow)`
  margin-bottom: ${rem(0)};
`;
const Cart3UsellCheckbox = styled(CartFormRow)`
  margin-bottom: 0px;
`;

const SupportSchoolText = styled.div`
  margin-left: ${rem(34)};
  font-size: ${rem(14)};
  p {
    margin-top: 0px;
  }
`;

const SupportSchoolCheckboxText = styled.div`
  font-weight: bold;
`;

const Cart3UpsellCheckboxText = styled.div`
  font-weight: bold;
`;

const Cart3UpsellCheckboxTextYes = styled.span`
  color: red;
`;

const Cart3UpsellCheckboxTextNormal = styled.span``;

const Cart3UpsellCheckboxTextProduct = styled.span`
  color: #a2bb52;
`;

const Cart3UpsellWrapper = styled(ContentContainerBlockCart)`
  background: #f8fde7;
`;

const Cart3UpsellProductWrapper = styled.div`
  display: flex;
  margin-left: ${rem(34)};
  row-gap: ${rem(10)};
  column-gap: ${rem(10)};
`;

const Cart3UpsellProductPictureWrapper = styled.div``;

const Cart3UpsellProductContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Cart3UpsellProductContentFirstRow = styled.div`
  font-size: ${rem(15)};
`;

const Cart3UpsellProductContentFirstRowDiscount = styled.span`
  color: red;
  font-weight: bold;
`;

const Cart3UpsellProductContentFirstRowOldPrice = styled.span`
  text-decoration: line-through;
`;

const Cart3UpsellProductContentFirstRowPrice = styled.span`
  font-weight: bold;
`;

const Cart3UpsellProductContentSecondRow = styled.div`
  font-size: ${rem(14)};
`;

const SchoolSelectWrapper = styled.div`
  display: flex;
  padding-bottom: ${rem(14)};
`;

interface Cart3Props {
  cart: any;
  dispatch: any;
  user: any;
  setTitleText: (title: string) => void;
  setHeaderSimpleType: (simpleType: HeaderSimpleType) => void;
  lastValues: any;
  onDemandCartItems: ThenArg<typeof API.getCart>['items'];
  regularCartItems: ThenArg<typeof API.getCart>['items'];
}

const emailChanged = (dispatch, newEmail) =>
  dispatch(recognizeCustomerCard(newEmail));
const [recognizeCustomerCardDebounce] = debounce(emailChanged, 1000);

const [updateCartDebounce, updateCartDebounceCancel] = debounce(
  (dispatch, newCart) => dispatch(updateCart(newCart, true)),
  5000,
);

const Cart3 = (props: Cart3Props) => {
  const [deliveryAddress, setDeliveryAddress] = React.useState(false);
  const [useCompanyValues, setUseCompanyValues] = React.useState(false);
  const [deliverySchoolAddress, setDeliverySchoolAddress] = React.useState<any>(
    null,
  );
  const [problems, setProblems] = React.useState([] as any[]);
  const [showErrors, setShowErrors] = React.useState(false);
  const [skvelkoAwards, setSkvelkoAwards] = React.useState(null as any);
  const [skvelkoSchoolCities, setSkvelkoSchoolCities] = React.useState(
    [] as any,
  );
  const [skvelkoSchools, setSkvelkoSchools] = React.useState([] as any);
  const [supportSchoolCheckbox, setSupportSchoolCheckbox] = React.useState(
    false,
  );

  const [supportSchoolText, setSupportSchoolText] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [backStepLoading, setBackStepLoading] = React.useState(false);

  const [oldCustomerModalVisible, setOldCustomerModalVisible] = React.useState(
    false,
  );
  const [oldCustomerModalText, setOldCustomerModalText] = React.useState('');
  const [oldCustomerModalShowed, setOldCustomerModalShowed] = React.useState(
    false,
  );

  const [restrictedCodModalText, setRestrictedCodModalText] = React.useState(
    '',
  );
  const [
    restrictedCodModalVisible,
    setRestrictedCodModalVisible,
  ] = React.useState(false);

  const { cart, user, dispatch, onDemandCartItems, regularCartItems } = props;

  const isDemand: boolean =
    regularCartItems.length === 0 && onDemandCartItems.length > 0;

  React.useEffect(() => {
    setDeliveryAddress(cart.use_delivery_address);
    setProblems(getProblems());
    if (cart) {
      API.getSkvelkoAwards(cart.id).then((skvelkoAwardsResult) => {
        setSkvelkoAwards(skvelkoAwardsResult);
      });
    }
    if (cart && isDeliveryToSchool(cart.delivery?.id)) {
      API.getDeliverySchoolBaseInfo(cart.id).then((deliverySchoolBaseInfo) => {
        setDeliverySchoolAddress(deliverySchoolBaseInfo);
      });
    }

    if (isDemand) {
      setDeliveryAddress(false);
    }
  }, [cart]);

  React.useEffect(() => {
    if (props.setTitleText) {
      props.setTitleText('Adresa doručenia');
    }
    if (props.setHeaderSimpleType) {
      props.setHeaderSimpleType(HeaderSimpleType.CART);
    }

    API.getSkvelkoSchoolCities({ includeSchoolsWithoutSets: true }).then(
      (skvelkoSchoolCitiesResult) => {
        setSkvelkoSchoolCities(skvelkoSchoolCitiesResult.cities);
      },
    );

    API.loadOtherTexts('SUPPORT_SCHOOL_TEXT').then((loadOtherTextsResult) => {
      if (loadOtherTextsResult && loadOtherTextsResult.content) {
        if (loadOtherTextsResult.content.draft !== 1) {
          const text = prop(
            loadOtherTextsResult,
            'content.json_content.body',
            '',
          );
          if (text) {
            setSupportSchoolText(text);
          }
        }
      }
    });

    API.loadOtherTexts('OLD_CUSTOMER_MODAL_TEXT').then(
      (loadOtherTextsResult) => {
        if (loadOtherTextsResult && loadOtherTextsResult.content) {
          if (loadOtherTextsResult.content.draft !== 1) {
            const text = prop(
              loadOtherTextsResult,
              'content.json_content.body',
              '',
            );
            if (text) {
              setOldCustomerModalText(text);
            }
          }
        }
      },
    );

    API.loadOtherTexts('RESTRICTED_COD_TEXT').then((loadOtherTextsResult) => {
      if (loadOtherTextsResult && loadOtherTextsResult.content) {
        if (loadOtherTextsResult.content.draft !== 1) {
          const text = prop(
            loadOtherTextsResult,
            'content.json_content.body',
            '',
          );
          if (text) {
            setRestrictedCodModalText(text);
          }
        }
      }
    });

    if (isDemand) {
      setDeliveryAddress(false);
    }
  }, []);

  React.useEffect(() => {
    if (cart && cart.cart3_upsell) {
      const cart3Upsell = cart.cart3_upsell;

      API.impressionUpsell(
        cart3Upsell.upsell.id,
        {},
        {
          type: 'UPSELL',
          cart_id: cart.id,
        },
      );

      API.impressionUpsell(
        cart3Upsell.upsell.id,
        {},
        {
          type: 'UPSELL_PRODUCT',
          cart_id: cart.id,
          product_ids: [cart3Upsell.product.product_id],
        },
      );
    }
  }, [cart]);

  const onSkvelkoChoolCityChange = (e) => {
    API.searchSkvelkoSchools({ cityName: e.target.value }).then(
      (skvelkoSchoolsResult) => {
        setSkvelkoSchools(skvelkoSchoolsResult.schools);
      },
    );
  };

  const onSupportSchoolCheckboxChange = (e) => {
    setSupportSchoolCheckbox(!supportSchoolCheckbox);
  };

  const onCart3UpsellCheckboxChange = async (e) => {
    const cart3Upsell = cart?.cart3_upsell;
    if (cart3Upsell) {
      if (!cart3Upsell.checked) {
        setIsLoading(true);
        await props.dispatch(
          addItemToCart(
            cart3Upsell.product.product_id,
            cart3Upsell.product.main_good_id,
            1,
            undefined,
            undefined,
            undefined,
            undefined,
            cart3Upsell.upsell.id,
          ),
        );
        setIsLoading(false);
      } else {
        setIsLoading(true);
        for (const item of cart.items) {
          if (item?.upsell?.id === cart3Upsell.upsell.id) {
            await props.dispatch(
              productInCartDelete(
                item.product.product_id,
                item.good.good_id,
                [],
                '',
                item.uniq_id,
              ),
            );
          }
        }
        setIsLoading(false);
      }
    }
  };

  const getNewCart = (values) => {
    const phone = (values.person_phone || '')
      .replace(/\s/g, '')
      .replace(/\//g, '')
      .replace(/\\/g, '');

    let newCart = {
      ...cart,
      billing_address: {
        person: {
          name: values.person_name,
          surname: values.person_surname,
          phone: phone,
          email: values.person_email,
        },
        street: values.billing_street,
        street_number: values.billing_street_number,
        city: values.billing_city,
        zip: values.billing_zip,
        country: values.billing_country,
      },
      delivery_address: {
        person: {
          name: '',
          surname: '',
          phone: '',
          email: '',
        },
        street: '',
        street_number: '',
        city: '',
        zip: '',
        country: '',
      },
      use_delivery_address: deliveryAddress,
      terms_accept: values.terms_accept,
      competition_accept: values.competition_accept,
      newsletter_accept: true,
      company_newsletter_accept: values.company_newsletter_accept,
      note: values.note ? values.note : '',
      universal_set_note: values.universal_set_note
        ? values.universal_set_note
        : '',
      company: {
        name: values.company_name,
        ico: values.ico,
        dic: values.dic,
        ic_dph: values.ic_dph,
      },
    };

    if (
      cart.dpd_delivery_point_id ||
      cart.shop_personal_pickup_id ||
      cart.packeta_delivery_point_id
    ) {
      newCart = {
        ...newCart,
        use_delivery_address: true,
        delivery_address: cart.delivery_address,
      };
    } else if (deliveryAddress) {
      newCart = {
        ...newCart,
        delivery_address: {
          person: {
            name: values.person_name,
            surname: values.person_surname,
            phone: phone,
            email: values.person_email,
          },
          street: values.delivery_street,
          street_number: values.delivery_street_number,
          city: values.delivery_city,
          zip: values.delivery_zip,
          country: values.delivery_country,
        },
      };
    }

    if (supportSchoolCheckbox && values.school_from_select) {
      newCart = {
        ...newCart,
        awarded_school_id: Number(values.school_from_select),
        skvelko_class_id: undefined,
      };
    }
    return newCart;
  };

  const onChange = async ({ values }) => {
    if (JSON.stringify(props.lastValues) !== JSON.stringify(values)) {
      props.dispatch(setLastValues(values));
      const newCart = getNewCart(values);
      updateCartDebounce(dispatch, newCart);
    }
  };

  const onSubmit = async (values) => {
    if (isLoading) {
      return;
    }

    try {
      updateCartDebounceCancel();
      setIsLoading(true);
      const newCart = {
        ...getNewCart(values),
        check_stock_counts: true,
      };
      const updatedCart = await dispatch(updateCart(newCart));

      if (
        updatedCart.restricted_cod &&
        updatedCart?.payment?.id === 'DOBIERKA'
      ) {
        setRestrictedCodModalVisible(true);
      } else {
        if (updatedCart.problems.length === 0) {
          await dispatch(createOrder(updatedCart));
        } else {
          setShowErrors(true);
          setProblems(getProblems(updatedCart));
          scrollToTop();
        }
      }
    } catch (e) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const onDifferentBillingCheckboxChange = (e) => {
    setDeliveryAddress(!deliveryAddress);
  };

  const onUseCompanyValuesChange = (e) => {
    setUseCompanyValues(!useCompanyValues);
  };

  const getProblems = (updatedCart: any = undefined) => {
    let xproblems: any[] = [];

    (updatedCart?.problems || cart.problems).forEach((problem) => {
      if (problem.readable) {
        xproblems = xproblems.concat([problem.readable]);
      } else {
        delete problem.type;
        delete problem.step;
        const tempProblems = flatten(problem);
        xproblems = xproblems.concat(Object.values(tempProblems));
      }
    });

    return xproblems;
  };

  const onBackClick = async (e) => {
    if (isDemand) {
      setBackStepLoading(true);
      updateCartDebounceCancel();
      const lastCart = getNewCart(props.lastValues);
      const newCart = { ...lastCart, step: 1 };
      await dispatch(updateCart(newCart, true));
      setBackStepLoading(false);
      props.dispatch(push('/kosik'));
    } else {
      setBackStepLoading(true);
      updateCartDebounceCancel();
      const lastCart = getNewCart(props.lastValues);
      const newCart = { ...lastCart, step: 2 };
      await dispatch(updateCart(newCart, true));
      setBackStepLoading(false);
      props.dispatch(push('/doprava-a-platba'));
    }
  };

  const scrollToError = () => {
    scrollToElement('error', 250, 100);
  };

  const openCompetitionArticleModal = () => {
    props.dispatch(setArticleModal(SKVELKO_COMPETITION_ARTICLE_ID));
  };

  const onOldCustomerModalCancelClick = () => {
    setOldCustomerModalVisible(false);
  };

  const onOldCustomerModalLoginClick = () => {
    props.dispatch(push('/prihlasenie'));
  };

  const onRestrictedButtonModalClick = () => {
    cookie.save(CART_RESTRICTED_COD_ID, true, {
      path: '/',
    });

    props.dispatch(onBackClick(null));
  };

  const states = [{ name: 'Slovensko', value: 'Slovensko' }];

  const userEmail =
    props.user && props.user.email.includes('@') ? props.user.email : '';

  const companyOrSchool = isCompanyOrSchool(user);
  const isChildUser = isTeacher(user) || isChildCompany(user);

  const deliveryToSchool = isDeliveryToSchool(cart.delivery?.id);

  const childrenName = prop(cart, 'children_name');
  const deliveryClassName = prop(cart, 'delivery_class_name');
  const schoolName = prop(deliverySchoolAddress, 'name', '');
  const schoolStreet = prop(deliverySchoolAddress, 'street', '');
  const schoolStreetNumber = prop(deliverySchoolAddress, 'street_number', '');
  const schoolCity = prop(deliverySchoolAddress, 'city', '');
  const deliverySchoolAddressFormatted = `${schoolName}, ${schoolStreet} ${schoolStreetNumber}, ${schoolCity}`;

  const showWithVat = showPriceWithVat(user);

  if (
    cart.showOldCustomerModal &&
    !oldCustomerModalVisible &&
    !oldCustomerModalShowed &&
    !props.user
  ) {
    setOldCustomerModalVisible(true);
    setOldCustomerModalShowed(true);
  }

  if (!cart.showOldCustomerModal && oldCustomerModalShowed) {
    setOldCustomerModalShowed(false);
  }

  const placeholder = '/images/placeholder-200.png';
  const placeholder2x = '/images/placeholder-200@2x.png';

  return (
    <>
      <Form
        onSubmit={onSubmit}
        // tslint:disable-next-line:jsx-no-lambda
        render={({ handleSubmit }) => (
          <FormCart onSubmit={handleSubmit}>
            {isLoading && (
              <LoaderOverlay>
                <Loader
                  loading={true}
                  style={{ position: 'fixed', top: '50%', left: '50%' }}
                ></Loader>
              </LoaderOverlay>
            )}
            <FormSpy onChange={onChange} />
            <Loader loading={false}>
              {problems.length > 0 ? (
                <AlertAvailability
                  cart={cart}
                  type={AlertAvailabilityType.ORDER}
                />
              ) : (
                ''
              )}

              {problems.length > 0 && showErrors
                ? problems
                    .filter((p) => !isProblemTypeAvailability(p))
                    .map((problem, i) => {
                      return (
                        <AlertStyled key={i} type="error">
                          {problem}
                        </AlertStyled>
                      );
                    })
                : ''}

              <Row>
                <CartInfoBoxWrapper>
                  <CartInfoBox />
                </CartInfoBoxWrapper>
                <ShippingPaymentCol>
                  <Content>
                    {deliveryToSchool && deliverySchoolAddress ? (
                      <ContentContainerBlockCart>
                        <Heading className="in-content">
                          {__('Adresa doručenia')}
                        </Heading>
                        <DeliverySchoolAddress>
                          <p>
                            <strong>{__('Škola')}: </strong>
                            {deliverySchoolAddressFormatted}
                          </p>
                          {childrenName ? (
                            <p>
                              <strong>{__('Žiak')}: </strong>
                              {childrenName}
                            </p>
                          ) : (
                            ''
                          )}
                          {deliveryClassName ? (
                            <p>
                              <strong>{__('Trieda')}: </strong>
                              {deliveryClassName}
                            </p>
                          ) : (
                            ''
                          )}
                          <p>
                            {__(
                              'Balíček bude žiakom rozdaný najskôr na začiatok školského roka.',
                            )}
                          </p>
                        </DeliverySchoolAddress>
                      </ContentContainerBlockCart>
                    ) : (
                      ''
                    )}

                    {companyOrSchool ? (
                      <ContentContainerBlockCart
                        className={deliveryToSchool ? 'noSeparator' : ''}
                      >
                        <Heading className="in-content">
                          {__('Vaše kontaktné údaje')}
                        </Heading>

                        <FormWrapper>
                          <CartFormRow>
                            <Field
                              name="company_name"
                              validate={required}
                              defaultValue={cart.company.name}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="company_name">
                                    {__('Organizácia')}
                                  </CartLabel>
                                  <CartInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                            <Field
                              name="ico"
                              validate={composeValidators(required, ico)}
                              defaultValue={cart.company.ico}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="ico">
                                    {__('IČO')}
                                  </CartLabel>
                                  <CartInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                          </CartFormRow>
                          <CartFormRow>
                            <Field
                              name="dic"
                              validate={composeValidators(required, dic)}
                              defaultValue={cart.company.dic}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="dic">
                                    {__('DIČ')}
                                  </CartLabel>
                                  <CartInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                            <Field
                              name="ic_dph"
                              defaultValue={cart.company.ic_dph}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="ic_dph">
                                    {__('IČ DPH')}
                                  </CartLabel>
                                  <CartInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                          </CartFormRow>
                        </FormWrapper>
                      </ContentContainerBlockCart>
                    ) : (
                      ''
                    )}

                    <ContentContainerBlockCart
                      className={
                        deliveryToSchool || companyOrSchool ? 'noSeparator' : ''
                      }
                    >
                      {companyOrSchool ? (
                        user?.skvelko_type == 4 ? (
                          <Heading className="in-content small">
                            {__('Používateľ účtu')}
                          </Heading>
                        ) : (
                          <Heading className="in-content small">
                            {__('Kontaktná osoba')}
                          </Heading>
                        )
                      ) : (
                        <Heading className="in-content">
                          {__('Vaše kontaktné údaje')}
                        </Heading>
                      )}

                      <FormWrapper>
                        <CartFormRow>
                          <Field
                            name="person_name"
                            validate={required}
                            defaultValue={
                              cart.billing_address.person.name
                                ? cart.billing_address.person.name
                                : !cart.dpd_delivery_point_id &&
                                  !cart.shop_personal_pickup_id &&
                                  !cart.packeta_delivery_point_id
                                ? cart.delivery_address.person.name
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <CartFormCol>
                                <CartLabel htmlFor="forename">
                                  {__('Meno')}
                                </CartLabel>
                                <CartInput
                                  {...input}
                                  error={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <ErrorPopup className={'error'}>
                                    {meta.error}
                                  </ErrorPopup>
                                )}
                              </CartFormCol>
                            )}
                          </Field>

                          {user?.skvelko_type !== 2 &&
                          user?.skvelko_type !== 3 ? (
                            <Field
                              name="person_surname"
                              validate={required}
                              defaultValue={
                                cart.billing_address.person.surname
                                  ? cart.billing_address.person.surname
                                  : cart.delivery_address.person.surname
                              }
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="surname">
                                    {__('Priezvisko')}
                                  </CartLabel>
                                  <CartInput
                                    {...input}
                                    error={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                          ) : (
                            ''
                          )}
                        </CartFormRow>
                        <CartFormRow>
                          <Field
                            name="person_email"
                            validate={composeValidators(required, email)}
                            defaultValue={
                              cart.billing_address.person.email
                                ? cart.billing_address.person.email
                                : !cart.dpd_delivery_point_id &&
                                  !cart.shop_personal_pickup_id &&
                                  !cart.packeta_delivery_point_id &&
                                  cart.delivery_address.person.email
                                ? cart.delivery_address.person.email
                                : userEmail
                            }
                          >
                            {({ input, meta }) => (
                              <CartFormCol>
                                <CartLabel htmlFor="person_email">
                                  {__('E-mail')}
                                </CartLabel>
                                <EmailAutocomplete
                                  {...input}
                                  type={'text'}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    input.onChange(e);
                                    if (
                                      !isEmailValid(inputValue) ||
                                      (user &&
                                        user.skvelko_type !==
                                          ChematCustomerType.RETAIL) ||
                                      cart.giftcards.length > 0 ||
                                      cart.customer_card_discount ||
                                      cart.customer_card
                                    ) {
                                      return;
                                    }
                                    recognizeCustomerCardDebounce(
                                      dispatch,
                                      inputValue,
                                    );
                                  }}
                                  error={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <ErrorPopup className={'error'}>
                                    {meta.error}
                                  </ErrorPopup>
                                )}
                              </CartFormCol>
                            )}
                          </Field>
                          <Field
                            name="person_phone"
                            validate={composeValidators(
                              required,
                              phone,
                              phoneMobile,
                            )}
                            defaultValue={
                              cart.billing_address.person.phone
                                ? cart.billing_address.person.phone
                                : !cart.dpd_delivery_point_id &&
                                  !cart.shop_personal_pickup_id &&
                                  !cart.packeta_delivery_point_id
                                ? cart.delivery_address.person.phone
                                : ''
                            }
                          >
                            {({ input, meta }) => (
                              <CartFormCol>
                                <CartLabel htmlFor="phone">
                                  {__('Mobilné číslo')}
                                </CartLabel>
                                <CartInput
                                  {...input}
                                  type="tel"
                                  error={meta.error && meta.touched}
                                />
                                {meta.error && meta.touched && (
                                  <ErrorPopup className={'error'}>
                                    {meta.error}
                                  </ErrorPopup>
                                )}
                              </CartFormCol>
                            )}
                          </Field>
                        </CartFormRow>
                      </FormWrapper>
                    </ContentContainerBlockCart>
                    {!isChildUser ? (
                      <ContentContainerBlockCart className={'noSeparator'}>
                        <Heading className="in-content small">
                          {companyOrSchool
                            ? __('Fakturačná adresa')
                            : __('Vaša adresa')}
                        </Heading>

                        <FormWrapper>
                          <CartFormRow>
                            <CartFormCol>
                              <CartFormInnerRow>
                                <Field
                                  name="billing_street"
                                  validate={composeValidators(required, street)}
                                  defaultValue={cart.billing_address.street}
                                >
                                  {({ input, meta }) => (
                                    <CartFormInnerCol className="left">
                                      <CartLabel htmlFor="billing_street">
                                        {__('Ulica')}
                                      </CartLabel>
                                      <CartInput
                                        {...input}
                                        error={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched && (
                                        <ErrorPopup className={'error'}>
                                          {meta.error}
                                        </ErrorPopup>
                                      )}
                                    </CartFormInnerCol>
                                  )}
                                </Field>

                                <Field
                                  name="billing_street_number"
                                  validate={required}
                                  defaultValue={
                                    cart.billing_address.street_number
                                  }
                                >
                                  {({ input, meta }) => (
                                    <CartFormInnerCol className="right">
                                      <CartLabel htmlFor="billing_street_number">
                                        {__('Číslo')}
                                      </CartLabel>
                                      <CartInput
                                        {...input}
                                        error={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched && (
                                        <ErrorPopup className={'error'}>
                                          {meta.error}
                                        </ErrorPopup>
                                      )}
                                    </CartFormInnerCol>
                                  )}
                                </Field>
                              </CartFormInnerRow>
                            </CartFormCol>
                            <CartFormCol>
                              <CartFormInnerRow>
                                <Field
                                  name="billing_city"
                                  validate={required}
                                  defaultValue={cart.billing_address.city}
                                >
                                  {({ input, meta }) => (
                                    <CartFormInnerCol className="left">
                                      <CartLabel htmlFor="billing_city">
                                        {__('Mesto')}
                                      </CartLabel>
                                      <CartInput
                                        {...input}
                                        error={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched && (
                                        <ErrorPopup className={'error'}>
                                          {meta.error}
                                        </ErrorPopup>
                                      )}
                                    </CartFormInnerCol>
                                  )}
                                </Field>

                                <Field
                                  name="billing_zip"
                                  validate={composeValidators(
                                    required,
                                    postalCode,
                                  )}
                                  parse={parsePostalCode}
                                  defaultValue={cart.billing_address.zip}
                                >
                                  {({ input, meta }) => (
                                    <CartFormInnerCol className="right">
                                      <CartLabel htmlFor="billing_zip">
                                        {__('PSČ')}
                                      </CartLabel>
                                      <CartInput
                                        {...input}
                                        error={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched && (
                                        <ErrorPopup className={'error'}>
                                          {meta.error}
                                        </ErrorPopup>
                                      )}
                                    </CartFormInnerCol>
                                  )}
                                </Field>
                              </CartFormInnerRow>
                            </CartFormCol>
                          </CartFormRow>
                          <CartFormRow>
                            <Field
                              name="billing_country"
                              validate={required}
                              component="select"
                              defaultValue={states[0].name}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="billing_country">
                                    {__('Štát')}
                                  </CartLabel>
                                  <CartSelect {...input}>
                                    {states.map((state, i) => {
                                      return (
                                        <option key={i} value={state.value}>
                                          {' '}
                                          {state.name}{' '}
                                        </option>
                                      );
                                    })}
                                  </CartSelect>
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                          </CartFormRow>
                          {(!user || isRetail(user)) && !deliveryToSchool ? (
                            <>
                              <div>
                                <Field
                                  name="use_company_values"
                                  type="checkbox"
                                >
                                  {({ input, meta }) => (
                                    <CheckboxStyled
                                      {...input}
                                      value="1"
                                      id="use_company_values"
                                      checked={useCompanyValues}
                                      onChange={onUseCompanyValuesChange}
                                    >
                                      {isDemand
                                        ? __(
                                            'Zobraziť údaje pre dopyt ako organizácia (IČO, DIČ)',
                                          )
                                        : __(
                                            'Zobraziť údaje pre nákup ako organizácia (IČO, DIČ)',
                                          )}
                                    </CheckboxStyled>
                                  )}
                                </Field>
                              </div>
                              {useCompanyValues && (
                                <>
                                  <CartFormRow>
                                    <Field
                                      name="company_name"
                                      validate={requiredIfIcoPresent}
                                      defaultValue=""
                                    >
                                      {({ input, meta }) => (
                                        <CartFormCol>
                                          <CartLabel htmlFor="company_name">
                                            {__('Organizácia')}
                                          </CartLabel>
                                          <CartInput
                                            {...input}
                                            error={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <ErrorPopup className={'error'}>
                                              {meta.error}
                                            </ErrorPopup>
                                          )}
                                        </CartFormCol>
                                      )}
                                    </Field>
                                    <Field
                                      name="ico"
                                      validate={ico}
                                      defaultValue=""
                                    >
                                      {({ input, meta }) => (
                                        <CartFormCol>
                                          <CartLabel htmlFor="ico">
                                            {__('IČO')}
                                          </CartLabel>
                                          <CartInput
                                            {...input}
                                            error={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <ErrorPopup className={'error'}>
                                              {meta.error}
                                            </ErrorPopup>
                                          )}
                                        </CartFormCol>
                                      )}
                                    </Field>
                                  </CartFormRow>
                                  <CartFormRow>
                                    <Field
                                      name="dic"
                                      validate={dic}
                                      defaultValue=""
                                    >
                                      {({ input, meta }) => (
                                        <CartFormCol>
                                          <CartLabel htmlFor="dic">
                                            {__('DIČ')}
                                          </CartLabel>
                                          <CartInput
                                            {...input}
                                            error={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <ErrorPopup className={'error'}>
                                              {meta.error}
                                            </ErrorPopup>
                                          )}
                                        </CartFormCol>
                                      )}
                                    </Field>
                                    <Field name="ic_dph" defaultValue="">
                                      {({ input, meta }) => (
                                        <CartFormCol>
                                          <CartLabel htmlFor="ic_dph">
                                            {__('IČ DPH')}
                                          </CartLabel>
                                          <CartInput
                                            {...input}
                                            error={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <ErrorPopup className={'error'}>
                                              {meta.error}
                                            </ErrorPopup>
                                          )}
                                        </CartFormCol>
                                      )}
                                    </Field>
                                  </CartFormRow>
                                </>
                              )}
                            </>
                          ) : (
                            ''
                          )}

                          {!deliveryToSchool &&
                            !cart.dpd_delivery_point_id &&
                            !cart.shop_personal_pickup_id &&
                            !cart.packeta_delivery_point_id &&
                            cart.delivery?.id !== 'OSOBNY_ODBER' &&
                            !isDemand && (
                              <div>
                                <Field
                                  name="use_delivery_address"
                                  type="checkbox"
                                >
                                  {({ input, meta }) => (
                                    <CheckboxStyled
                                      {...input}
                                      value="1"
                                      id="use_delivery_address"
                                      checked={deliveryAddress}
                                      onChange={
                                        onDifferentBillingCheckboxChange
                                      }
                                    >
                                      {__(
                                        'Dodacia adresa je iná ako fakturačná',
                                      )}
                                    </CheckboxStyled>
                                  )}
                                </Field>
                              </div>
                            )}
                        </FormWrapper>
                      </ContentContainerBlockCart>
                    ) : (
                      <></>
                    )}

                    {isChildUser ? (
                      <ContentContainerBlockCart className={'noSeparator'}>
                        <Heading className="in-content">
                          {__('Fakturačné údaje')}
                        </Heading>
                        <FormWrapper>
                          <CartFormRow>
                            <Field
                              name="company_name"
                              validate={required}
                              defaultValue={cart.company.name}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="company_name">
                                    {__('Organizácia')}
                                  </CartLabel>
                                  <CartInputWrapper>
                                    <CartInput
                                      {...input}
                                      error={meta.error && meta.touched}
                                      disabled={true}
                                    />
                                    <LockSvg src="/images/lock_gray.svg" />
                                  </CartInputWrapper>
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                            <Field
                              name="ico"
                              validate={composeValidators(required, ico)}
                              defaultValue={cart.company.ico}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="ico">
                                    {__('IČO')}
                                  </CartLabel>
                                  <CartInputWrapper>
                                    <CartInput
                                      {...input}
                                      error={meta.error && meta.touched}
                                      disabled={true}
                                    />
                                    <LockSvg src="/images/lock_gray.svg" />
                                  </CartInputWrapper>
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                          </CartFormRow>
                          <CartFormRow>
                            <Field
                              name="dic"
                              validate={dic}
                              defaultValue={cart.company.dic}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="dic">
                                    {__('DIČ')}
                                  </CartLabel>
                                  <CartInputWrapper>
                                    <CartInput
                                      {...input}
                                      error={meta.error && meta.touched}
                                      disabled={true}
                                    />
                                    <LockSvg src="/images/lock_gray.svg" />
                                  </CartInputWrapper>
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                            <Field
                              name="ic_dph"
                              defaultValue={cart.company.ic_dph}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="ic_dph">
                                    {__('IČ DPH')}
                                  </CartLabel>
                                  <CartInputWrapper>
                                    <CartInput
                                      {...input}
                                      error={meta.error && meta.touched}
                                      disabled={true}
                                    />
                                    <LockSvg src="/images/lock_gray.svg" />
                                  </CartInputWrapper>
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                          </CartFormRow>
                          <CartFormRow>
                            <CartFormCol>
                              <CartFormInnerRow>
                                <Field
                                  name="billing_street"
                                  validate={composeValidators(required, street)}
                                  defaultValue={cart.billing_address.street}
                                >
                                  {({ input, meta }) => (
                                    <CartFormInnerCol className="left">
                                      <CartLabel htmlFor="billing_street">
                                        {__('Ulica')}
                                      </CartLabel>
                                      <CartInputWrapper>
                                        <CartInput
                                          {...input}
                                          error={meta.error && meta.touched}
                                          disabled={true}
                                        />
                                        <LockSvg src="/images/lock_gray.svg" />
                                      </CartInputWrapper>
                                      {meta.error && meta.touched && (
                                        <ErrorPopup className={'error'}>
                                          {meta.error}
                                        </ErrorPopup>
                                      )}
                                    </CartFormInnerCol>
                                  )}
                                </Field>

                                <Field
                                  name="billing_street_number"
                                  validate={required}
                                  defaultValue={
                                    cart.billing_address.street_number
                                  }
                                >
                                  {({ input, meta }) => (
                                    <CartFormInnerCol className="right">
                                      <CartLabel htmlFor="delivery_street_number">
                                        {__('Číslo')}
                                      </CartLabel>
                                      <CartInputWrapper>
                                        <CartInput
                                          {...input}
                                          error={meta.error && meta.touched}
                                          disabled={true}
                                        />
                                        <LockSvg src="/images/lock_gray.svg" />
                                      </CartInputWrapper>
                                      {meta.error && meta.touched && (
                                        <ErrorPopup className={'error'}>
                                          {meta.error}
                                        </ErrorPopup>
                                      )}
                                    </CartFormInnerCol>
                                  )}
                                </Field>
                              </CartFormInnerRow>
                            </CartFormCol>
                            <CartFormCol>
                              <CartFormInnerRow>
                                <Field
                                  name="billing_city"
                                  validate={required}
                                  defaultValue={cart.billing_address.city}
                                >
                                  {({ input, meta }) => (
                                    <CartFormInnerCol className="left">
                                      <CartLabel htmlFor="billing_city">
                                        {__('Mesto')}
                                      </CartLabel>
                                      <CartInputWrapper>
                                        <CartInput
                                          {...input}
                                          error={meta.error && meta.touched}
                                          disabled={true}
                                        />
                                        <LockSvg src="/images/lock_gray.svg" />
                                      </CartInputWrapper>
                                      {meta.error && meta.touched && (
                                        <ErrorPopup className={'error'}>
                                          {meta.error}
                                        </ErrorPopup>
                                      )}
                                    </CartFormInnerCol>
                                  )}
                                </Field>

                                <Field
                                  name="billing_zip"
                                  validate={composeValidators(
                                    required,
                                    postalCode,
                                  )}
                                  parse={parsePostalCode}
                                  defaultValue={cart.billing_address.zip}
                                >
                                  {({ input, meta }) => (
                                    <CartFormInnerCol className="right">
                                      <CartLabel htmlFor="billing_zip">
                                        {__('PSČ')}
                                      </CartLabel>
                                      <CartInputWrapper>
                                        <CartInput
                                          {...input}
                                          error={meta.error && meta.touched}
                                          disabled={true}
                                        />
                                        <LockSvg src="/images/lock_gray.svg" />
                                      </CartInputWrapper>
                                      {meta.error && meta.touched && (
                                        <ErrorPopup className={'error'}>
                                          {meta.error}
                                        </ErrorPopup>
                                      )}
                                    </CartFormInnerCol>
                                  )}
                                </Field>
                              </CartFormInnerRow>
                            </CartFormCol>
                          </CartFormRow>
                          <CartFormRow>
                            <Field
                              name="billing_country"
                              validate={required}
                              component="select"
                              defaultValue={cart.billing_address.country}
                            >
                              {({ input, meta }) => (
                                <CartFormCol>
                                  <CartLabel htmlFor="billing_country">
                                    {__('Štát')}
                                  </CartLabel>
                                  <CartInputWrapper>
                                    <CartSelect {...input}>
                                      {states.map((state, i) => {
                                        return (
                                          <option key={i} value={state.value}>
                                            {' '}
                                            {state.name}{' '}
                                          </option>
                                        );
                                      })}
                                    </CartSelect>
                                    {/*
                                    <LockSvg src="/images/lock_gray.svg" />
                                    */}
                                  </CartInputWrapper>
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                          </CartFormRow>

                          {!deliveryToSchool &&
                            !cart.dpd_delivery_point_id &&
                            !cart.shop_personal_pickup_id &&
                            !cart.packeta_delivery_point_id &&
                            cart.delivery?.id !== 'OSOBNY_ODBER' &&
                            !isDemand && (
                              <div>
                                <Field
                                  name="use_delivery_address"
                                  type="checkbox"
                                >
                                  {({ input, meta }) => (
                                    <CheckboxStyled
                                      {...input}
                                      value="1"
                                      id="use_delivery_address"
                                      checked={deliveryAddress}
                                      onChange={
                                        onDifferentBillingCheckboxChange
                                      }
                                    >
                                      {__(
                                        'Dodacia adresa je iná ako fakturačná',
                                      )}
                                    </CheckboxStyled>
                                  )}
                                </Field>
                              </div>
                            )}
                        </FormWrapper>
                      </ContentContainerBlockCart>
                    ) : (
                      <></>
                    )}

                    {(cart.dpd_delivery_point_id ||
                      cart.shop_personal_pickup_id ||
                      cart.packeta_delivery_point_id) && (
                      <ContentContainerBlockCart>
                        <Heading className="in-content">
                          {__('Vaša dodacia adresa')}
                        </Heading>
                        <DeliveryPoint>
                          <DeliveryPointLabel>
                            {cart.dpd_delivery_point_id
                              ? __('DPD odberné miesto')
                              : cart.packeta_delivery_point_id
                              ? __('Packeta odberné miesto')
                              : __('Odber na predajni')}
                          </DeliveryPointLabel>
                          <DeliveryPointDescription>
                            {cart.delivery_address.person.name},{' '}
                            {cart.delivery_address.street}{' '}
                            {cart.delivery_address.street_number},{' '}
                            {cart.delivery_address.city}
                          </DeliveryPointDescription>
                        </DeliveryPoint>
                      </ContentContainerBlockCart>
                    )}

                    {deliveryAddress &&
                      !cart.dpd_delivery_point_id &&
                      !cart.shop_personal_pickup_id &&
                      !cart.packeta_delivery_point_id &&
                      cart.delivery?.id !== 'OSOBNY_ODBER' &&
                      cart.delivery?.id !== 'DOPRAVA_DO_SKOLY' && (
                        <ContentContainerBlockCart>
                          <Heading className="in-content">
                            {__('Vaša dodacia adresa')}
                          </Heading>
                          <FormWrapper>
                            <CartFormRow>
                              <CartFormCol>
                                <CartFormInnerRow>
                                  <Field
                                    name="delivery_street"
                                    validate={composeValidators(
                                      required,
                                      street,
                                    )}
                                    defaultValue={cart.delivery_address.street}
                                  >
                                    {({ input, meta }) => (
                                      <CartFormInnerCol className="left">
                                        <CartLabel htmlFor="delivery_street">
                                          {__('Ulica')}
                                        </CartLabel>
                                        <CartInput
                                          {...input}
                                          error={meta.error && meta.touched}
                                        />
                                        {meta.error && meta.touched && (
                                          <ErrorPopup className={'error'}>
                                            {meta.error}
                                          </ErrorPopup>
                                        )}
                                      </CartFormInnerCol>
                                    )}
                                  </Field>

                                  <Field
                                    name="delivery_street_number"
                                    validate={required}
                                    defaultValue={
                                      cart.delivery_address.street_number
                                    }
                                  >
                                    {({ input, meta }) => (
                                      <CartFormInnerCol className="right">
                                        <CartLabel htmlFor="delivery_street_number">
                                          {__('Číslo')}
                                        </CartLabel>
                                        <CartInput
                                          {...input}
                                          error={meta.error && meta.touched}
                                        />
                                        {meta.error && meta.touched && (
                                          <ErrorPopup className={'error'}>
                                            {meta.error}
                                          </ErrorPopup>
                                        )}
                                      </CartFormInnerCol>
                                    )}
                                  </Field>
                                </CartFormInnerRow>
                              </CartFormCol>
                              <CartFormCol>
                                <CartFormInnerRow>
                                  <Field
                                    name="delivery_city"
                                    validate={required}
                                    defaultValue={cart.delivery_address.city}
                                  >
                                    {({ input, meta }) => (
                                      <CartFormInnerCol className="left">
                                        <CartLabel htmlFor="delivery_city">
                                          {__('Mesto')}
                                        </CartLabel>
                                        <CartInput
                                          {...input}
                                          error={meta.error && meta.touched}
                                        />
                                        {meta.error && meta.touched && (
                                          <ErrorPopup className={'error'}>
                                            {meta.error}
                                          </ErrorPopup>
                                        )}
                                      </CartFormInnerCol>
                                    )}
                                  </Field>

                                  <Field
                                    name="delivery_zip"
                                    validate={composeValidators(
                                      required,
                                      postalCode,
                                    )}
                                    parse={parsePostalCode}
                                    defaultValue={cart.delivery_address.zip}
                                  >
                                    {({ input, meta }) => (
                                      <CartFormInnerCol className="right">
                                        <CartLabel htmlFor="delivery_zip">
                                          {__('PSČ')}
                                        </CartLabel>
                                        <CartInput
                                          {...input}
                                          error={meta.error && meta.touched}
                                        />
                                        {meta.error && meta.touched && (
                                          <ErrorPopup className={'error'}>
                                            {meta.error}
                                          </ErrorPopup>
                                        )}
                                      </CartFormInnerCol>
                                    )}
                                  </Field>
                                </CartFormInnerRow>
                              </CartFormCol>
                            </CartFormRow>
                            <CartFormRow>
                              <Field
                                name="delivery_country"
                                validate={required}
                                component="select"
                                defaultValue={states[0].name}
                              >
                                {({ input, meta }) => (
                                  <CartFormCol>
                                    <CartLabel htmlFor="country">
                                      {__('Štát')}
                                    </CartLabel>
                                    <CartSelect {...input}>
                                      {states.map((state, i) => {
                                        return (
                                          <option key={i} value={state.value}>
                                            {' '}
                                            {state.name}{' '}
                                          </option>
                                        );
                                      })}
                                    </CartSelect>
                                    {meta.error && meta.touched && (
                                      <ErrorPopup className={'error'}>
                                        {meta.error}
                                      </ErrorPopup>
                                    )}
                                  </CartFormCol>
                                )}
                              </Field>
                            </CartFormRow>
                          </FormWrapper>
                        </ContentContainerBlockCart>
                      )}

                    {skvelkoAwards &&
                    skvelkoAwards.length === 0 &&
                    !isDemand &&
                    (!user ||
                      (user &&
                        user.skvelko_type === ChematCustomerType.RETAIL)) ? (
                      <SkvelkoSchoolBox className="inverse">
                        <FormWrapper>
                          <React.Fragment>
                            <SupportSchoolCheckbox>
                              <CartFormCol className="full-width">
                                <CheckboxStyled
                                  name="support"
                                  value="1"
                                  id="support"
                                  onChange={onSupportSchoolCheckboxChange}
                                  checked={supportSchoolCheckbox}
                                >
                                  <SupportSchoolCheckboxText>
                                    {__(
                                      'Chcem podporiť školu (nemusíte nič platiť)',
                                    )}
                                  </SupportSchoolCheckboxText>
                                </CheckboxStyled>
                                <SupportSchoolText
                                  dangerouslySetInnerHTML={{
                                    __html: supportSchoolText,
                                  }}
                                />
                              </CartFormCol>
                            </SupportSchoolCheckbox>
                            {supportSchoolCheckbox && (
                              <SchoolSelectWrapper>
                                <CartFormCol>
                                  <CartSelect
                                    onChange={onSkvelkoChoolCityChange}
                                  >
                                    <option hidden={true} value="">
                                      {__('Mesto')}
                                    </option>
                                    {skvelkoSchoolCities.map((city, i) => {
                                      return (
                                        <option key={i} value={city}>
                                          {' '}
                                          {city}{' '}
                                        </option>
                                      );
                                    })}
                                  </CartSelect>
                                </CartFormCol>

                                <Field name="school_from_select" type="select">
                                  {({ input, meta }) => (
                                    <CartFormCol>
                                      <CartSelect
                                        {...input}
                                        disabled={skvelkoSchools.length === 0}
                                      >
                                        <option hidden={true} value="">
                                          {__('Škola')}
                                        </option>
                                        {skvelkoSchools.map((school, i) => {
                                          return (
                                            <option key={i} value={school.id}>
                                              {getSchoolName(school)}
                                            </option>
                                          );
                                        })}
                                      </CartSelect>
                                    </CartFormCol>
                                  )}
                                </Field>
                              </SchoolSelectWrapper>
                            )}
                          </React.Fragment>
                        </FormWrapper>
                      </SkvelkoSchoolBox>
                    ) : (
                      ''
                    )}

                    {cart?.cart3_upsell?.product?.unitprice ? (
                      <Cart3UpsellWrapper>
                        <Cart3UsellCheckbox>
                          <CartFormCol className="full-width">
                            <CheckboxStyled
                              name="cart3_upsell"
                              value="1"
                              id="cart3_upsell"
                              onChange={onCart3UpsellCheckboxChange}
                              checked={cart.cart3_upsell.checked}
                            >
                              <Cart3UpsellCheckboxText>
                                <Cart3UpsellCheckboxTextYes>
                                  {__('Áno!')}
                                </Cart3UpsellCheckboxTextYes>
                                &nbsp;
                                <Cart3UpsellCheckboxTextNormal>
                                  {__('Pridajte')}
                                </Cart3UpsellCheckboxTextNormal>
                                &nbsp;
                                <Cart3UpsellCheckboxTextProduct>
                                  {cart.cart3_upsell.product.name}
                                </Cart3UpsellCheckboxTextProduct>
                                &nbsp;
                                <Cart3UpsellCheckboxTextNormal>
                                  {__('k mojej objednávke')}
                                </Cart3UpsellCheckboxTextNormal>
                              </Cart3UpsellCheckboxText>
                            </CheckboxStyled>
                          </CartFormCol>
                        </Cart3UsellCheckbox>
                        <Cart3UpsellProductWrapper>
                          <Cart3UpsellProductPictureWrapper>
                            <Thumb
                              src={cart?.cart3_upsell?.product?.picture}
                              placeholder={placeholder}
                              retinaPlaceholder={placeholder2x}
                              width={100}
                              height={100}
                              notLazyloading={true}
                            ></Thumb>
                          </Cart3UpsellProductPictureWrapper>
                          <Cart3UpsellProductContentWrapper>
                            <Cart3UpsellProductContentFirstRow>
                              <Cart3UpsellProductContentFirstRowDiscount>
                                {`${__('ZĽAVA')} ${
                                  cart.cart3_upsell.product.discount
                                }%`}
                              </Cart3UpsellProductContentFirstRowDiscount>
                              &nbsp;
                              {cart?.cart3_upsell?.product?.oldprice ? (
                                <Cart3UpsellProductContentFirstRowOldPrice>
                                  {formatPrice(
                                    cart.cart3_upsell.product.oldprice,
                                    'EUR',
                                  )}
                                </Cart3UpsellProductContentFirstRowOldPrice>
                              ) : (
                                <></>
                              )}
                              &nbsp;
                              <Cart3UpsellProductContentFirstRowPrice>
                                {formatPrice(
                                  cart.cart3_upsell.product.unitprice,
                                  'EUR',
                                )}
                              </Cart3UpsellProductContentFirstRowPrice>
                            </Cart3UpsellProductContentFirstRow>

                            <Cart3UpsellProductContentSecondRow>
                              {cart.cart3_upsell.upsell.description}
                            </Cart3UpsellProductContentSecondRow>
                          </Cart3UpsellProductContentWrapper>
                        </Cart3UpsellProductWrapper>
                      </Cart3UpsellWrapper>
                    ) : (
                      <></>
                    )}

                    <ContentContainerBlockCart>
                      <FormWrapper>
                        {isCartCointainsUniversalSkvelkoSet(cart) ? (
                          <CartFormRow>
                            <Field
                              name="universal_set_note"
                              defaultValue={cart.universal_set_note}
                              type="textarea"
                            >
                              {({ input, meta }) => (
                                <CartFormCol className="full-width">
                                  <CartLabel htmlFor="universal_set_note">
                                    {__(
                                      'Názov obce a školy ktorú navštevuje dieťa, pre ktoré je určená univerzálna sada',
                                    )}
                                  </CartLabel>
                                  <CartTextarea rows={2} {...input} />
                                  {meta.error && meta.touched && (
                                    <ErrorPopup className={'error'}>
                                      {meta.error}
                                    </ErrorPopup>
                                  )}
                                </CartFormCol>
                              )}
                            </Field>
                          </CartFormRow>
                        ) : (
                          <></>
                        )}

                        <CartFormRow>
                          <Field
                            name="note"
                            defaultValue={cart.note}
                            type="textarea"
                          >
                            {({ input, meta }) => (
                              <CartFormCol className="full-width">
                                <CartLabel htmlFor="note">
                                  {isDemand
                                    ? __('Poznámka k dopytu')
                                    : __('Poznámka k objednávke')}
                                </CartLabel>
                                <CartTextarea rows={3} {...input} />
                                {meta.error && meta.touched && (
                                  <ErrorPopup className={'error'}>
                                    {meta.error}
                                  </ErrorPopup>
                                )}
                              </CartFormCol>
                            )}
                          </Field>
                        </CartFormRow>

                        {/*
                      <Field
                        name="newsletter_accept"
                        defaultValue={cart.newsletter_accept}
                        type="checkbox"
                      >
                        {({ input, meta }) => (
                          <div>
                            <CheckboxStyled
                              {...input}
                              value="1"
                              id="newsletter_accept"
                            >
                              {__(
                                'Chcem dostávať novinky a špeciálne ponuky na svoj email',
                              )}
                            </CheckboxStyled>
                            {meta.error && meta.touched && (
                              <ErrorPopup className="error>
                                {meta.error}
                              </ErrorPopup>
                            )}
                          </div>
                        )}
                      </Field>
                      */}

                        {isCartCointainsSkvelkoSet(cart) ? (
                          <Field
                            name="competition_accept"
                            defaultValue={cart.competition_accept}
                            type="checkbox"
                          >
                            {({ input, meta }) => (
                              <CheckboxWrapper>
                                <CheckboxStyled
                                  {...input}
                                  value="1"
                                  id="competition_accept"
                                >
                                  <></>
                                </CheckboxStyled>
                                <span>
                                  {__('Súhlasím so zapojením sa do súťaže ')}{' '}
                                  <a onClick={openCompetitionArticleModal}>
                                    {__('"Balíček školských potrieb"')}
                                  </a>
                                </span>
                                {meta.error && meta.touched && (
                                  <ErrorPopup className={'error'}>
                                    {meta.error}
                                  </ErrorPopup>
                                )}
                              </CheckboxWrapper>
                            )}
                          </Field>
                        ) : (
                          ''
                        )}

                        <Field
                          name="terms_accept"
                          validate={required}
                          defaultValue={cart.terms_accept}
                          type="checkbox"
                        >
                          {({ input, meta }) => (
                            <>
                              <CheckboxWrapper>
                                <CheckboxStyled
                                  {...input}
                                  value="1"
                                  id="terms_accept"
                                >
                                  <></>
                                </CheckboxStyled>
                                <span>
                                  {__('Potvrdzujem, že som sa oboznámil s')}{' '}
                                  <a
                                    onClick={openBusinessConditionsModal.bind(
                                      null,
                                      dispatch,
                                    )}
                                  >
                                    {__('obchodnými podmienkami')}
                                  </a>
                                  {', '}
                                  {__(
                                    'porozumel som ich obsahu a v celom rozsahu s nimi súhlasím. Oboznámil som sa so zásadami spracúvania a ochrany ',
                                  )}{' '}
                                  <a
                                    onClick={openGdprModal.bind(null, dispatch)}
                                  >
                                    {__('osobných údajov')}
                                  </a>
                                  .
                                </span>
                              </CheckboxWrapper>
                              {meta.error && meta.touched && (
                                <ErrorPopup className={'error'}>
                                  {meta.error}
                                </ErrorPopup>
                              )}
                            </>
                          )}
                        </Field>
                      </FormWrapper>
                    </ContentContainerBlockCart>
                  </Content>
                </ShippingPaymentCol>
                <SummaryCol>
                  <CartSummary
                    cart={cart}
                    items={cart.items}
                    showWithVat={showWithVat}
                    isDemand={isDemand}
                    onDemandCartItems={onDemandCartItems}
                    regularCartItems={regularCartItems}
                  />
                </SummaryCol>
              </Row>
              <Footer>
                <Loader loading={backStepLoading}>
                  <BackButton
                    className="lg"
                    onClick={onBackClick}
                    type="button"
                  >
                    <BackIcon icon="chevron" />
                    {__('Späť')}
                  </BackButton>
                </Loader>
                <NextButton
                  className="primary lg"
                  type="submit"
                  onClick={scrollToError}
                  disabled={isLoading}
                >
                  {isDemand
                    ? __('Odoslať nezáväzný dopyt')
                    : __('Objednať s povinnosťou platby')}
                </NextButton>
              </Footer>
            </Loader>
          </FormCart>
        )}
      />

      {oldCustomerModalVisible ? (
        <ChooseModal
          title={__('Prihláste sa')}
          bodyText={
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: oldCustomerModalText,
                }}
              />
            </>
          }
          button1Text={__('Zrušiť')}
          button2Text={__('Prihlásiť')}
          onButton1Click={onOldCustomerModalCancelClick}
          onButton2Click={onOldCustomerModalLoginClick}
          notShowClose={true}
        />
      ) : (
        <></>
      )}

      {restrictedCodModalVisible ? (
        <ConfirmModal
          title={__('Info')}
          bodyText={
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: restrictedCodModalText,
                }}
              />
            </>
          }
          buttonText="OK"
          onButtonClick={onRestrictedButtonModalClick}
          onCloseButtonClick={onRestrictedButtonModalClick}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lastValues: state.cart.lastValues,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Cart3' })(Cart3),
);
