import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Col } from '../../theme/libraries/grid';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import { TitleCount } from '../_layout/Page/ContentContainer';

const Title = styled.h1`
  font-size: ${rem(24)};
  font-weight: 800;
  margin-top: 0;
  margin-bottom: ${rem(16)};
  ${({ theme }) => theme.media(1024)} {
    font-size: ${rem(32)};
  }
`;

interface CategoryTitleProps {
  name: string;
  count?: number;
}

export class CategoryTitle extends React.PureComponent<CategoryTitleProps> {
  public render() {
    const { name, count } = this.props;
    return (
      <Title>
        {name}
        {count && <TitleCount>{count}</TitleCount>}
      </Title>
    );
  }
}

const Placeholder = styled.p``;

interface CategoryPlaceholderProps {
  text: string;
}

export class CategoryPlaceholder extends React.PureComponent<CategoryPlaceholderProps> {
  public render() {
    const { text } = this.props;
    return <Placeholder>{text}</Placeholder>;
  }
}

export const ProductsRow = styled(Clearfix)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  ${({ theme }) => theme.media('sm')} {
    margin-top: ${rem(20)};
    margin-right: 0;
    margin-left: 0;
  }
`;

export const ProductCol = styled(Col)`
  border: ${rem(1)} solid ${({ theme }) => theme.color.gray93};
  padding: 0;
  width: ${({ theme }) => theme.grid.col.col6};
  ${({ theme }) => theme.media('xs')} {
    width: ${({ theme }) => theme.grid.col.col4};
  }
  ${({ theme }) => theme.media('sm')} {
    width: ${({ theme }) => theme.grid.col.col3};
  }
  ${({ theme }) => theme.media(1024)} {
  }
`;
