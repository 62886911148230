import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { formatPrice } from '../../../../utilities';

const getPriceColor = (props) => {
  if (props.sale) {
    return ({ theme }) => theme.color.primary;
  } else if (props.discount) {
    return ({ theme }) => theme.color.success;
  } else {
    return 'initial';
  }
};

const Price = styled.p`
  color: ${(props) =>
    props.priceAfterNoColor ? 'initial' : getPriceColor(props)};
  font-size: ${rem(14)};
  font-weight: ${(props) => (props.crossed ? 400 : 700)};
  text-decoration: ${(props) => (props.crossed ? 'line-through' : 'normal')};
  text-align: center;
  margin: ${rem(2)} 0;
`;

interface SkvelkoSetPriceProps {
  skvelkoSet: any;
  user: any;
  cart: any;
  priceAfterNoColor?: boolean;
}

const SkvelkoSetPrice = (props: SkvelkoSetPriceProps) => {
  const { skvelkoSet } = props;

  const renderPriceAfter = () => {
    if (skvelkoSet.price) {
      return <Price>{formatPrice(skvelkoSet.price, 'EUR')}</Price>;
    } else {
      return <Price>{formatPrice(0, 'EUR')}</Price>;
    }
  };

  return <>{renderPriceAfter()}</>;
};
export default SkvelkoSetPrice;
