import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button } from '../form/Button';
import { CloseIcon } from '../Icon/Close';
import Overlay from '../Overlay/Overlay';
import OnClickOutsideComponent from '../OnClickOutsideComponent/OnClickOutsideComponent';
import { Loader } from '../Loader/Loader';

const StyledOnClickOutsideComponent = styled(OnClickOutsideComponent)`
  max-width: 100%;
  margin: auto;
`;

const ModalDiv = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  width:  ${(props) => rem(props.width)}}
  max-width: 100%;
  position: relative;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  & + & {
    margin-top: ${rem(10)};
  }
`;

const Header = styled.header<{ titleFontWeight: string }>`
  font-size: ${rem(18)};
  line-height: 1.2;
  padding: ${rem(20)};
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  font-weight: ${({ titleFontWeight }) => titleFontWeight};
  &.closable {
    padding-right: ${rem(60)};
  }
`;

const ModalClose = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${rem(22)};
  z-index: 1;
`;

const Body = styled.div`
  padding: ${rem(20)};
  font-size: ${rem(14)};

  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const ModalCloseIcon = styled(CloseIcon)``;

export const Footer = styled.footer`
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  padding: ${rem(20)};
`;

interface ModalProps {
  title?: any;
  body?: any;
  footer?: any;
  onCloseButtonClick?: (event) => void;
  className?: string;
  loading?: boolean;
  notShowClose?: boolean;
  width?: number;
  onClickOutsideActive?: boolean;
  titleFontWeight?: string;
}

class BaseModal extends React.PureComponent<ModalProps> {
  public render() {
    const { titleFontWeight = 'bold' } = this.props;

    return (
      <Overlay>
        <StyledOnClickOutsideComponent
          onClickOutside={
            this.props.onClickOutsideActive !== false
              ? this.props.onCloseButtonClick
              : null
          }
        >
          <ModalDiv
            width={this.props.width ? this.props.width : 600}
            className={this.props.className}
          >
            <Loader loading={!!this.props.loading}>
              {this.props.title && (
                <Header className="closable" titleFontWeight={titleFontWeight}>
                  {this.props.title}
                </Header>
              )}

              {this.props.body && (
                <Body>
                  {typeof this.props.body === 'string' ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.body || '',
                      }}
                    />
                  ) : (
                    this.props.body
                  )}
                </Body>
              )}

              {this.props.footer && this.props.footer}

              {!this.props.notShowClose ? (
                <ModalClose onClick={this.props.onCloseButtonClick}>
                  <ModalCloseIcon />
                </ModalClose>
              ) : (
                ''
              )}
            </Loader>
          </ModalDiv>
        </StyledOnClickOutsideComponent>
      </Overlay>
    );
  }
}

export default BaseModal;
