import { Loader } from '../_helpers/Loader/Loader';
import styled, { css } from 'styled-components';
import { __ } from 'react-i18n';
import * as React from 'react';
import { OtherText } from '../../constants';
import { useAsyncConstructor } from '../../hooks/useAsyncConstructor';
import { rem } from 'polished';

const IntroLink = styled.a`
  margin-left: ${rem(6)};
  font-size: ${rem(12)};
  text-transform: uppercase;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

const IntroCssP = css`
  display: inline;
  margin: 0;
`;

const IntroCss = css`
  ${IntroCssP};
  p {
    ${IntroCssP};
  }
`;

const IntroLess = styled.p`
  ${IntroCss};
`;

const IntroMore = styled.p`
  ${IntroCss};
  margin-left: ${rem(6)};
`;

interface RegistrationIntroProps {
  dispatch: any;
  loadLess: () => void;
  loadMore: () => void;
  textLess?: OtherText;
  textMore?: OtherText;
}

export const RegistrationIntro = (props: RegistrationIntroProps) => {
  const [loadingIntro, setLoadingIntro] = React.useState(false);
  const [showLess, setShowLess] = React.useState(false);

  const { dispatch, loadLess, loadMore, textLess, textMore } = props;

  const introLess = textLess?.body;
  const introMore = textMore?.body;

  useAsyncConstructor(async () => {
    setLoadingIntro(true);
    await dispatch(loadLess());
    setLoadingIntro(false);
  });

  const handleLoadIntroMore = async () => {
    setShowLess(false);
    setLoadingIntro(true);
    await dispatch(loadMore());
    setLoadingIntro(false);
  };

  const handleShowLess = () => setShowLess(true);

  return (
    <Loader loading={loadingIntro}>
      {introLess && (
        <>
          <IntroLess
            dangerouslySetInnerHTML={{
              __html: introLess,
            }}
          />
          {introMore && !showLess ? (
            <>
              <IntroMore
                dangerouslySetInnerHTML={{
                  __html: introMore,
                }}
              />
              <IntroLink onClick={handleShowLess}>
                {__('Zobraziť menej')}»
              </IntroLink>
            </>
          ) : (
            !loadingIntro && (
              <IntroLink onClick={handleLoadIntroMore}>
                {__('Zobraziť viac')}»
              </IntroLink>
            )
          )}
        </>
      )}
    </Loader>
  );
};

export default RegistrationIntro;
