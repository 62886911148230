import * as React from 'react';
import { Content, Title } from '../../components/_layout/Page/ContentContainer';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { __ } from 'react-i18n';
import { rem } from 'polished';
import styled from 'styled-components';
import { ContentContainerBlock } from '../../components/_helpers/Contact/ContactStyles';
import Tabs from '../../components/_helpers/Tabs/Tabs';
import {
  URL_LOGIN,
  URL_REGISTRATION_COMPANY,
  URL_REGISTRATION_RETAIL,
  URL_REGISTRATION_SCHOOL,
} from '../../constants';
import { prop } from '../../utilities';
import { WithRouterProps } from 'react-router';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { Container, grid } from '../../theme/libraries/grid';

const RegistrationContainer = styled(Container)`
  padding-right: 0;
  padding-left: 0;
  ${({ theme }) => theme.media('md')} {
    padding-right: ${rem(grid.padding / 2)};
    padding-left: ${rem(grid.padding / 2)};
  }
`;

const Wrapper = styled(Content)`
  font-size: ${rem(14)};
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(40)};
  }
`;

const RegistrationContainerBlock = styled(ContentContainerBlock)`
  padding-top: ${rem(10)};
  ${({ theme }) => theme.media('md')} {
    padding-top: ${rem(20)};
  }
`;

const Description = styled.p``;

interface RegistrationParentProps {
  dispatch: any;
  children: any;
  push: (path: string) => any;
}

class RegistrationParent extends React.Component<
  RegistrationParentProps & WithRouterProps
> {
  public render() {
    const currentPath = prop(this, 'props.router.location.pathname', '/');
    const { children, dispatch } = this.props;
    return (
      <RegistrationContainer className="base">
        <Wrapper>
          <RegistrationContainerBlock>
            <Breadcrumbs
              items={[
                { name: 'Môj účet', url: URL_LOGIN },
                { name: 'Registrácia', url: currentPath },
              ]}
            />
            <Title>{__('Registrácia')}</Title>
            <Description>{__('Chcem sa registrovať ako')}:</Description>
            <Tabs
              tabs={[
                {
                  name: __('Súkromná osoba'),
                  url: URL_REGISTRATION_RETAIL,
                },
                {
                  name: __('Firma, organizácia'),
                  url: URL_REGISTRATION_COMPANY,
                },
                {
                  name: __('Školské zariadenie'),
                  url: URL_REGISTRATION_SCHOOL,
                },
              ]}
              currentPath={currentPath}
              dispatch={dispatch}
            />
            {React.cloneElement(children, { ...this.props })}
          </RegistrationContainerBlock>
        </Wrapper>
      </RegistrationContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'RegistrationParent' })(RegistrationParent),
);
