import * as React from 'react';
import styled from 'styled-components';
import { rem, rgba } from 'polished';

const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const SwitchInput = styled.input`
  appearance: none;
  width: ${rem(42)};
  height: ${rem(24)};
  display: inline-block;
  position: relative;
  border-radius: ${rem(50)};
  overflow: hidden;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.primaryDark};
  transition: background-color ease 0.3s;

  &:disabled {
    background-color: ${({ theme }) => rgba(theme.color.primaryDark, 0.5)};
  }

  &:before {
    content: '✓ x';
    display: block;
    position: absolute;
    z-index: 2;
    width: ${rem(18)};
    height: ${rem(18)};
    background: ${({ theme }) => theme.color.white};
    left: ${rem(4)};
    top: ${rem(3)};
    border-radius: 50%;
    font-size: ${rem(12)};
    text-transform: uppercase;
    font-weight: bold;
    text-indent: ${rem(-22)};
    word-spacing: ${rem(32)};
    color: ${({ theme }) => theme.color.white};
    text-shadow: ${rem(-1)} ${rem(-1)} rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    box-shadow: 0 ${rem(1)} ${rem(2)} rgba(0, 0, 0, 0.2);
    transition: all ease 0.3s;
    line-height: 1.6;
  }

  &:checked {
    background-color: ${({ theme }) => theme.color.green};

    &:disabled {
      background-color: ${({ theme }) => rgba(theme.color.green, 0.5)};
    }

    &:before {
      font-size: ${rem(16)};
      left: ${rem(20)};
      text-indent: ${rem(-14)};
      line-height: 1.2;
    }
  }
`;

const SwitchLabel = styled.label`
  margin-left: ${rem(8)};
  font-size: ${rem(12)};
  ${({ theme }) => theme.media('xs')} {
    font-size: ${rem(15)};
  }
`;

interface SwitchProps {
  id?: string | number;
  label?: string;
  value?: string | number;
  checked?: boolean;
  onChange?: (e?) => void;
  onClick?: () => void;
  disabled?: boolean;
}

export class Switch extends React.Component<SwitchProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      id,
      value,
      onChange,
      onClick,
      label,
      checked,
      disabled,
    } = this.props;
    return (
      <SwitchWrapper>
        <SwitchInput
          id={id}
          type="checkbox"
          value={value}
          onChange={onChange}
          onClick={onClick}
          checked={checked}
          disabled={disabled === true}
        />
        {label && <SwitchLabel>{label}</SwitchLabel>}
      </SwitchWrapper>
    );
  }
}
