import { capitalizeFirstLetter, getUrl } from '../../../../utilities';
import * as React from 'react';
import HighLightedText from './HighLightedText';
import styled from 'styled-components';
import Icon from '../../../_helpers/Icon/Icon';
import { rem } from 'polished';
import { Link } from 'react-router';
import { __ } from 'react-i18n/lib';
import { CategoryTextStyle, MoreButton } from './styles';

const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const CategoryIcon = styled(Icon)`
  position: absolute;
  top: ${rem(2)};
  left: 0;
  ${({ theme }) => theme.size(16)};
  fill: ${({ theme }) => theme.color.gray66};
`;

const SearchLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
  }
  &.wrap {
    flex-wrap: wrap;
  }
`;

const CategorySeparator = styled.p`
  ${CategoryTextStyle};
  margin: 0 ${rem(2)}; ;
`;

const Item = styled.li`
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(10)};
  ${({ theme }) => theme.transition('color')};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
  }
`;

const Category = styled(Item)`
  margin: 0;
  flex-wrap: wrap;
  padding-left: ${rem(20)};
  position: relative;
  max-width: ${rem(757)};
  &:last-child {
    margin-bottom: ${rem(10)};
  }
  &:hover {
    color: inherit;
  }
`;

const MoreButtonStyled = styled(MoreButton)`
  margin: ${rem(8)} 0;
`;

interface CategoryListProps {
  categories: any;
  searchedQuery: string;
  onRedirect?: (url) => void;
  onMoreClick?: () => void;
}

const CategoryList = (props: CategoryListProps) => {
  const { categories, onRedirect, searchedQuery, onMoreClick } = props;
  return (
    <>
      {categories?.catalog_category?.length ? (
        <List>
          {categories.catalog_category.map((category, index) => (
            <Category key={index}>
              <CategoryIcon icon="folder" />
              <SearchLink
                className={'wrap'}
                to={getUrl(category.url)}
                onClick={onRedirect?.bind(undefined, category.url)}
              >
                {category.parent_categories?.map((parentCategory, index) => (
                  <React.Fragment key={index}>
                    <HighLightedText
                      text={capitalizeFirstLetter(parentCategory.category_name)}
                      highlight={searchedQuery}
                    />
                    <CategorySeparator>/</CategorySeparator>
                  </React.Fragment>
                ))}
                <HighLightedText
                  text={capitalizeFirstLetter(category.category_name)}
                  highlight={searchedQuery}
                />
              </SearchLink>
            </Category>
          ))}
          {onMoreClick &&
          categories.catalog_category.length < categories.total ? (
            <MoreButtonStyled onClick={onMoreClick}>
              {__('Zobraziť viac kategórií')}
            </MoreButtonStyled>
          ) : (
            ''
          )}
        </List>
      ) : (
        ''
      )}
    </>
  );
};

export default CategoryList;
