import * as actionTypes from './constants';

const initialState = {
  addToCartModalOpen: false,
  schoolIsSelected: false,
  cityIsSelected: false,
  selectedSchoolId: null,
  selectedSkvelkoSetId: null,
  selectedClassId: null,
};

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_UNI_SETS:
      return {
        ...state,
        universalSets: action.payload.universalSets,
      };
    case actionTypes.SET_UNI_SETS_SPECIAL:
      return {
        ...state,
        universalSetsSpecial: action.payload.universalSetsSpecial,
      };
    case actionTypes.SET_COOPERATIONS:
      return {
        ...state,
        cooperations: action.payload.cooperations,
      };
    case actionTypes.SET_SKVELKO_LOADER:
      return {
        ...state,
        skvelkoLoader: action.payload.skvelkoLoader,
      };
    case actionTypes.SET_SKVELKO_SETS_LOADER:
      return {
        ...state,
        skvelkoSetsLoader: action.payload.skvelkoSetsLoader,
      };
    case actionTypes.SET_SKVELKO_CITIES:
      return {
        ...state,
        skvelkoCities: action.payload.skvelkoCities,
      };
    case actionTypes.SET_SKVELKO_SCHOOLS_BY_CITY:
      return {
        ...state,
        skvelkoSchools: action.payload.skvelkoSchools,
      };
    case actionTypes.SET_SKVELKO_SETS_BY_SCHOOL:
      return {
        ...state,
        skvelkoSets: action.payload.skvelkoSets,
      };
    case actionTypes.SET_SKVELKO_CLASSES_BY_SCHOOL:
      return {
        ...state,
        skvelkoClasses: action.payload.skvelkoClasses,
      };
    case actionTypes.SET_SKVELKO_SET:
      return {
        ...state,
        skvelkoSet: action.payload.skvelkoSet,
      };

    case actionTypes.SET_SELECTED_CITY_NAME:
      return {
        ...state,
        selectedCityName: action.payload.selectedCityName,
      };

    case actionTypes.SET_SELECTED_SCHOOL_ID:
      return {
        ...state,
        selectedSchoolId: action.payload.selectedSchoolId,
      };

    case actionTypes.SET_SELECTED_SKVELKO_SET_ID:
      return {
        ...state,
        selectedSkvelkoSetId: action.payload.selectedSkvelkoSetId,
      };

    case actionTypes.SET_SELECTED_CLASS_ID:
      return {
        ...state,
        selectedClassId: action.payload.selectedClassId,
      };

    case actionTypes.SET_SCHOOL_IS_SELECTED:
      return {
        ...state,
        schoolIsSelected: action.payload.schoolIsSelected,
      };

    case actionTypes.SET_CITY_IS_SELECTED:
      return {
        ...state,
        cityIsSelected: action.payload.cityIsSelected,
      };

    case actionTypes.SET_CURRENT_FILTER_TITLE:
      return {
        ...state,
        currentFilterTitle: action.payload.currentFilterTitle,
        currentSchoolName: action.payload.currentSchoolName,
      };

    case actionTypes.SET_CURRENT_SCHOOL_NAME:
      return {
        ...state,
        currentSchoolName: action.payload.currentSchoolName,
      };

    case actionTypes.SET_CURRENT_SET:
      return {
        ...state,
        currentSet: action.payload.currentSet,
      };

    case actionTypes.SET_CURRENT_CLASS_ID:
      return {
        ...state,
        currentClassId: action.payload.currentClassId,
      };

    case actionTypes.SET_NEWSLETTER_LOADER:
      return {
        ...state,
        newsletterLoader: action.payload.newsletterLoader,
      };

    case actionTypes.SET_NEWSLETTER_RESPONSE:
      return {
        ...state,
        newsletterResponse: action.payload.newsletterResponse,
      };

    case actionTypes.SET_SET_POPUP_TEXT:
      return {
        ...state,
        setPopupText: action.payload.setPopupText,
      };

    case actionTypes.SET_WARNING_REMOVE_FROM_SET_TEXT:
      return {
        ...state,
        warningRemoveFromSetText: action.payload.warningRemoveFromSetText,
      };

    default:
      return state;
  }
};
