import * as React from 'react';
import { Row } from '../../../theme/libraries/grid';
import { __ } from 'react-i18n/lib';
import FaqList from './FaqList';
import styled from 'styled-components';

interface Props {
  faq: any;
  content: any;
}

const FaqContentWrapper = styled.div`
  width: 100%;
`;

class Faq extends React.PureComponent<Props> {
  public render() {
    return (
      <Row>
        <FaqList items={this.props.faq.articles} />
        <FaqContentWrapper>
          {this.props.content ? (
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.content.toString(),
              }}
            />
          ) : (
            <p>
              {__('Nenašli ste odpoveď na vašu otázku?')}
              <br />
              <a href="/kontakt/kontaktne-informacie">{__('Napíšte nám')}</a>
            </p>
          )}
        </FaqContentWrapper>
      </Row>
    );
  }
}

export default Faq;
