import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { BackIcon, BackLinkStyles, Footer, NextButton } from './Cart1';
import { Row } from '../../theme/libraries/grid';
import CartRadios from './CartRadios';
import CartSummary from './CartSummary';
import { CartHeading, CartMainCol, CartSummaryCol } from './CartStyles';
import API, { ThenArg } from '../../services/API';
import {
  loadCustomerDetails,
  loadDpdDeliveryPointById,
  loadDpdDeliveryPoints,
  loadShopsPersonalPickup,
  setDpdDeliveryPoint,
  setShopPersonalPickup,
  setShopPersonalPickupById,
  updateCart,
} from '../../containers/Cart/actions';
import { ButtonStyledStyles, ButtonStyles } from '../_helpers/form/Button';
import { push } from 'react-router-redux';
import Alert from '../Alert/Alert';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import {
  isSchoolOrTeacher,
  normalizeString,
  scrollToElement,
  showPriceWithVat,
  splitStreetWithNumber,
} from '../../utilities';
import {
  setDeliveryPointModalPoint,
  setPacketaDeliveryPointModalPoint,
  setShopsPersonalPickupModal,
} from '../../containers/App/actions';
import { HeaderSimpleType } from '../_layout/Header/HeaderSimple';
import { Loader } from '../_helpers/Loader/Loader';
import CartInfoBox from './CartInfoBox';

export const Heading = styled(CartHeading)``;

const ShippingPaymentCol = styled(CartMainCol)``;

const StyledInfoText = styled.p`
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
  font-size: 0.875rem;
  color: rgb(150, 150, 150);
  text-align: center;
`;
const SummaryCol = styled(CartSummaryCol)``;

export const BackButton = styled.button`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${BackLinkStyles};
`;

const PaymentErrorWrapper: any = styled.div`
  margin-bottom: ${rem(20)};
`;

interface Cart2Props {
  cart: any;
  dispatch: any;
  user: any;
  customerDetails: any;
  deliveryPoints: any;
  deliveryPoint: any;
  packetaDeliveryPoint: any;
  shopsPersonalPickup: any;
  shopPersonalPickup: any;
  setTitleText: (title: string) => void;
  setHeaderSimpleType: (simpleType: HeaderSimpleType) => void;
  onDemandCartItems: ThenArg<typeof API.getCart>['items'];
  regularCartItems: ThenArg<typeof API.getCart>['items'];
}

const Cart2 = (props: Cart2Props) => {
  const [deliveryAndPayments, setDeliveryAndPayments] = React.useState([]);
  const [classes, setClasses] = React.useState([]);
  const [schoolInfo, setSchoolInfo] = React.useState<any>(null);
  const [
    someSetsHasDeliveryToSchoolActivated,
    setSomeSetsHasDeliveryToSchoolActivated,
  ] = React.useState(false);

  const [schoolIds, setSchoolIds] = React.useState([]);

  const [loadingTrustPoints, setLoadingTrustPoints] = React.useState(false);

  const [childrenName, setChildrenName] = React.useState('');

  const [deliveryClassPart1Name, setDeliveryClassPart1Name] = React.useState(
    '',
  );
  const [deliveryClassPart2Name, setDeliveryClassPart2Name] = React.useState(
    '',
  );

  const [cartLoaded, setCartLoaded] = React.useState(false);
  const [deliveryToSchoolError, setDeliveryToSchoolError] = React.useState(
    false,
  );

  const [paymentError, setPaymentError] = React.useState(false);
  const [deliveryError, setDeliveryError] = React.useState<string | undefined>(
    undefined,
  );

  const [loading, setLoading] = React.useState(false);
  const [paymentLoading, setPaymentLoading] = React.useState(false);
  const [deliveryLoading, setDeliveryLoading] = React.useState(false);
  const [nextStepLoading, setNextStepLoading] = React.useState(false);
  const [backStepLoading, setBackStepLoading] = React.useState(false);

  const {
    cart,
    dispatch,
    customerDetails,
    user,
    deliveryPoints,
    deliveryPoint,
    packetaDeliveryPoint,
    shopsPersonalPickup,
    shopPersonalPickup,
    onDemandCartItems,
    regularCartItems,
  } = props;

  const isDemand: boolean =
    regularCartItems.length === 0 && onDemandCartItems.length > 0;

  const showWithVat = showPriceWithVat(user);

  React.useEffect(() => {
    if (props.cart) {
      API.getDeliveryPaymentInfo(
        props.cart.id,
        {},
        { xAcceptLanguage: props.cart.lang },
      ).then((deliveryPaymentResponse: any) => {
        if (
          props.cart.delivery === null &&
          deliveryPaymentResponse.deliveryPayment.delivery.length === 1
        ) {
          const newCart = {
            ...props.cart,
            delivery: {
              id:
                deliveryPaymentResponse.deliveryPayment.delivery[0].delivery_id,
            },
          };
          dispatch(updateCart(newCart));
        } else if (props.cart.delivery === null) {
          let hasDeliveryToSchool = false;
          deliveryPaymentResponse.deliveryPayment.delivery.forEach(
            (delivery) => {
              if (delivery.delivery_id === 'DOPRAVA_DO_SKOLY') {
                hasDeliveryToSchool = true;
              }
            },
          );

          if (hasDeliveryToSchool) {
            const newCart = {
              ...props.cart,
              delivery: {
                id: 'DOPRAVA_DO_SKOLY',
              },
            };

            dispatch(updateCart(newCart));
          }
        }

        setDeliveryAndPayments(
          deliveryPaymentResponse.deliveryPayment.delivery,
        );
        setClasses(deliveryPaymentResponse.classes);
        setSchoolInfo(deliveryPaymentResponse.schoolInfo);
        setSomeSetsHasDeliveryToSchoolActivated(
          deliveryPaymentResponse.someSetsHasDeliveryToSchoolActivated,
        );

        setSchoolIds(deliveryPaymentResponse.schoolIds);
      });

      if (!cartLoaded) {
        setChildrenName(cart.children_name);

        const parsedClassName = parseDeliveryClassName(
          cart.delivery_class_name,
        );

        setDeliveryClassPart1Name(parsedClassName.part1);
        setDeliveryClassPart2Name(parsedClassName.part2);

        setCartLoaded(true);
        // setDeliveryClassName(cart.delivery_class_name);
      } else {
        setCartLoaded(true);
      }
    }
  }, [cart]);

  React.useEffect(() => {
    if (props.setTitleText) {
      props.setTitleText('Doprava a platba');
    }
    if (props.setHeaderSimpleType) {
      props.setHeaderSimpleType(HeaderSimpleType.CART);
    }
    if (cart && !cart.payment) {
      const newCart = { ...cart, payment: { id: 'GPWEBPAY' } };
      setPaymentError(false);
      dispatch(updateCart(newCart));
    }
    (async () => {
      setLoading(true);
      if (!customerDetails) {
        await props.dispatch(loadCustomerDetails());
      }
      if (!props.deliveryPoints) {
        await props.dispatch(loadDpdDeliveryPoints());
      }
      if (!props.shopsPersonalPickup) {
        await props.dispatch(loadShopsPersonalPickup());
      }
      if (props.cart?.dpd_delivery_point_id) {
        await props.dispatch(
          loadDpdDeliveryPointById(props.cart.dpd_delivery_point_id),
        );
      }
      if (props.cart?.shop_personal_pickup_id) {
        await props.dispatch(
          setShopPersonalPickupById(props.cart.shop_personal_pickup_id),
        );
      }
      setLoading(false);
    })();
  }, []);

  const parseDeliveryClassName = (deliveryClassName) => {
    let part1 = '';
    let part2 = '';

    if (deliveryClassName && deliveryClassName.includes('.')) {
      const splittedClass = deliveryClassName.split('.');
      if (splittedClass.length > 0) {
        part1 = splittedClass[0] + '.';
      }

      if (splittedClass.length > 1) {
        part2 = splittedClass[1];
      }
    }

    return { part1, part2 };
  };

  const onDeliveryPointInfo = (e) => {
    props.dispatch(setDeliveryPointModalPoint(deliveryPoint));
  };

  const onShopsPersonalPickupInfo = (e) => {
    props.dispatch(setShopsPersonalPickupModal(shopPersonalPickup));
  };

  const onPacketaDeliveryPointInfo = (e) => {
    props.dispatch(setPacketaDeliveryPointModalPoint(packetaDeliveryPoint));
  };

  const onDeliveryPointRemove = async (e) => {
    props.dispatch(setDpdDeliveryPoint(null));
    const newCart = {
      ...cart,
      dpd_delivery_point_id: null,
      delivery_address: {
        person: {
          name: null,
          surname: null,
          phone: null,
          email: null,
        },
        street: null,
        street_number: null,
        city: null,
        zip: null,
        country: null,
      },
    };
    setDeliveryLoading(true);
    await dispatch(updateCart(newCart));
    setDeliveryLoading(false);
  };

  const onPacketaDeliveryPointRemove = async (e) => {
    props.dispatch(setDpdDeliveryPoint(null));
    const newCart = {
      ...cart,
      packeta_delivery_point_id: null,
      packeta_delivery_point: null,
      delivery_address: {
        person: {
          name: null,
          surname: null,
          phone: null,
          email: null,
        },
        street: null,
        street_number: null,
        city: null,
        zip: null,
        country: null,
      },
    };
    setDeliveryLoading(true);
    await dispatch(updateCart(newCart));
    setDeliveryLoading(false);
  };

  const onShopsPersonalPickupRemove = async (e) => {
    props.dispatch(setShopPersonalPickup(null));
    const newCart = {
      ...cart,
      shop_personal_pickup_id: null,
      delivery_address: {
        person: {
          name: null,
          surname: null,
          phone: null,
          email: null,
        },
        street: null,
        street_number: null,
        city: null,
        zip: null,
        country: null,
      },
    };
    setDeliveryLoading(true);
    await dispatch(updateCart(newCart));
    setDeliveryLoading(false);
  };

  const onDeliveryChange = async (e) => {
    setDeliveryError(undefined);
    const newCart = {
      ...cart,
      delivery: { id: e.target.value },
      dpd_delivery_point_id: null,
      shop_personal_pickup_id: null,
      packeta_delivery_point_id: null,
      packeta_delivery_point: null,
      delivery_address: {
        person: {
          name: null,
          surname: null,
          phone: null,
          email: null,
        },
        street: null,
        street_number: null,
        city: null,
        zip: null,
        country: null,
      },
      payment: shouldRemovePayment(e.target.value) ? null : cart.payment,
    };

    props.dispatch(setDpdDeliveryPoint(null));
    props.dispatch(setShopPersonalPickup(null));

    setDeliveryLoading(true);
    await dispatch(updateCart(newCart));
    setDeliveryLoading(false);
  };

  const onDeliveryPointChange = async (e) => {
    setDeliveryError(undefined);

    const deliveryPoint = await props.dispatch(
      loadDpdDeliveryPointById(e.value),
    );

    const newCart = {
      ...cart,
      dpd_delivery_point_id: e.value,
      packeta_delivery_point_id: null,
      delivery_address: {
        person: {
          name: deliveryPoint.name,
          surname: '',
          phone: deliveryPoint.phone.toString(),
          email: deliveryPoint.email,
        },
        street: deliveryPoint.street,
        street_number: deliveryPoint.houseno,
        city: deliveryPoint.city,
        zip: deliveryPoint.zip,
        country: (deliveryPoint.country.id = 703
          ? 'Slovensko'
          : deliveryPoint.country.value),
      },
    };

    setDeliveryLoading(true);
    await dispatch(updateCart(newCart));
    setDeliveryLoading(false);
  };

  const onPacketaDeliveryPointChange = async (packetaDeliveryPoint) => {
    if (packetaDeliveryPoint) {
      let { street, number } = splitStreetWithNumber(
        packetaDeliveryPoint.street,
      );

      if (!street) {
        street = packetaDeliveryPoint.street;
      }

      const newCart = {
        ...cart,
        dpd_delivery_point_id: null,
        packeta_delivery_point_id: packetaDeliveryPoint.id,
        packeta_delivery_point: packetaDeliveryPoint,
        delivery_address: {
          person: {
            name: packetaDeliveryPoint.place,
            surname: '',
            phone: '',
            email: 'packeta@packeta.sk',
          },
          street: street,
          street_number: number,
          city: packetaDeliveryPoint.city,
          zip: packetaDeliveryPoint.zip,
          country:
            packetaDeliveryPoint.country === 'sk'
              ? 'Slovensko'
              : packetaDeliveryPoint.country,
        },
      };

      setDeliveryLoading(true);
      await dispatch(updateCart(newCart));
      setDeliveryLoading(false);
    }
  };

  const onShopPersonalPickupChange = async (e) => {
    setDeliveryError(undefined);

    const shop = await props.dispatch(setShopPersonalPickupById(e.value));

    const newCart = {
      ...cart,
      shop_personal_pickup_id: e.value,
      use_delivery_address: true,
      delivery_address: {
        person: {
          name: shop.name,
          surname: '',
          phone: shop.phone.toString(),
          email: shop.email,
        },
        street: shop.address,
        street_number: '',
        city: shop.city,
        zip: shop.zip,
        country: shop.state,
      },
    };

    setDeliveryLoading(true);
    await dispatch(updateCart(newCart));
    setDeliveryLoading(false);
  };

  const filterOption = (option, inputValue) => {
    const { data } = option;

    if (data.city) {
      return (
        !inputValue ||
        (data.city &&
          inputValue &&
          normalizeString(data.city).startsWith(normalizeString(inputValue)))
      );
    }

    if (data.name) {
      return (
        !inputValue ||
        (data.name &&
          inputValue &&
          normalizeString(data.name).startsWith(normalizeString(inputValue)))
      );
    }

    return false;
  };

  const onApplyTrustPointsChange = async (e) => {
    setLoadingTrustPoints(true);
    const newCart = {
      ...cart,
      apply_whole_trust_points: e.target.checked,
    };
    await dispatch(updateCart(newCart));
    setLoadingTrustPoints(false);
  };

  const onPaymentChange = async (e) => {
    const newCart = { ...cart, payment: { id: e.target.value } };
    setPaymentError(false);
    setPaymentLoading(true);
    await dispatch(updateCart(newCart));
    setPaymentLoading(false);
  };

  const onContinueClick = async (e) => {
    if (cart.delivery !== null && cart.payment !== null) {
      if (
        cart.delivery.id === 'DOPRAVA_DO_SKOLY' &&
        (!childrenName ||
          childrenName.trim() === '' ||
          !deliveryClassPart1Name ||
          deliveryClassPart1Name === '' ||
          !deliveryClassPart2Name ||
          deliveryClassPart2Name.trim() === '')
      ) {
        setDeliveryToSchoolError(true);
        scrollToElement('error', window.innerWidth > 1024 ? 200 : 50);
      } else if (
        cart.delivery.id === 'DPD_PICKUP' &&
        !cart.dpd_delivery_point_id
      ) {
        setDeliveryError(__('Zvoľte odberné miesto pre DPD'));
        scrollToElement('error', window.innerWidth > 1024 ? 200 : 50);
      } else if (
        cart.delivery.id === 'PREDAJNA' &&
        !cart.shop_personal_pickup_id
      ) {
        setDeliveryError(__('Zvoľte predajňu'));
        scrollToElement('error', window.innerWidth > 1024 ? 200 : 50);
      } else if (
        cart.delivery.id === 'PACKETA' &&
        !cart.packeta_delivery_point_id
      ) {
        setDeliveryError(__('Zvoľte výdajné miesto pre zásielkovňu'));
        scrollToElement('error', window.innerWidth > 1024 ? 200 : 50);
      } else {
        if (cart.delivery.id === 'DOPRAVA_DO_SKOLY') {
          const newCart = {
            ...cart,
            step: 3,
            delivery_school_id: schoolInfo?.id || null,
            delivery_school_ico: schoolInfo?.ico,
            children_name: childrenName,
            delivery_class_name:
              deliveryClassPart1Name + deliveryClassPart2Name,
          };
          setNextStepLoading(true);
          await dispatch(updateCart(newCart));
        } else if (cart.delivery.id === 'DPD_PICKUP') {
          const newCart = {
            ...cart,
            use_delivery_address: true,
            delivery_address: {
              person: {
                name: deliveryPoint.name,
                surname: '',
                phone: deliveryPoint.phone.toString(),
                email: deliveryPoint.email,
              },
              street: deliveryPoint.street,
              street_number: deliveryPoint.houseno,
              city: deliveryPoint.city,
              zip: deliveryPoint.zip,
              country: (deliveryPoint.country.id = 703
                ? 'Slovensko'
                : deliveryPoint.country.value),
            },
          };
          setNextStepLoading(true);
          await dispatch(updateCart(newCart));
        } else if (cart.delivery.id === 'PREDAJNA') {
          const newCart = {
            ...cart,
            use_delivery_address: true,
            delivery_address: {
              person: {
                name: shopPersonalPickup.name,
                surname: '',
                phone: shopPersonalPickup.phone.toString(),
                email: shopPersonalPickup.email,
              },
              street: shopPersonalPickup.address,
              street_number: '',
              city: shopPersonalPickup.city,
              zip: shopPersonalPickup.zip,
              country: shopPersonalPickup.state,
            },
          };
          setNextStepLoading(true);
          await dispatch(updateCart(newCart));
        } else {
          const newCart = {
            ...cart,
            step: 3,
            delivery_school_id: null,
            delivery_school_ico: null,
            children_name: null,
            delivery_class_name: null,
          };
          setNextStepLoading(true);
          await dispatch(updateCart(newCart));
        }
        setNextStepLoading(false);

        props.dispatch(push('/udaje-pre-dorucenie'));
      }
    }

    if (cart.payment === null) {
      setPaymentError(true);
      scrollToElement('error', window.innerWidth > 1024 ? 200 : 50);
    }
  };

  const onBackClick = async (e) => {
    const newCart = { ...cart, step: 1 };
    setBackStepLoading(true);
    await dispatch(updateCart(newCart));
    setBackStepLoading(false);
    props.dispatch(push('/kosik'));
  };

  const getPaymentsByDeliveryId = (id) => {
    let payments = [];
    deliveryAndPayments.forEach((delivery: any) => {
      if (delivery.delivery_id === id) {
        payments = delivery.payments;
      }
    });
    return payments;
  };

  const shouldRemovePayment = (newDeliveryId) => {
    let should = true;
    const actualPaymentId = cart && cart.payment ? cart.payment.id : null;
    deliveryAndPayments.forEach((delivery: any) => {
      if (delivery.delivery_id === newDeliveryId) {
        delivery.payments.forEach((payment: any) => {
          if (payment.payment_id === actualPaymentId) {
            should = false;
          }
        });
      }
    });
    return should;
  };

  const onChildrenNameChange = (e) => {
    setChildrenName(e.target.value);
  };

  const onClassPart1Change = (e) => {
    setDeliveryClassPart1Name(e.target.value);
  };

  const onClassPart2Change = (e) => {
    setDeliveryClassPart2Name(e.target.value);
  };

  let deliveryPointsItems: any = [];
  if (deliveryPoints && Array.isArray(deliveryPoints)) {
    deliveryPointsItems = deliveryPoints.map((point) => {
      return {
        city: point.city,
        value: point.id,
        label: (
          <>
            <div style={{ fontSize: '14px', paddingBottom: '2px' }}>
              <strong>{point.city}</strong>
            </div>
            <div style={{ fontSize: '13px' }}>
              {point.name}, {point.street} {point.houseno}, {point.city}{' '}
              {point.zip}
            </div>
          </>
        ),
      };
    });
  }

  let shopPersonalPickupItems: any = [];
  if (shopsPersonalPickup && Array.isArray(shopsPersonalPickup)) {
    shopPersonalPickupItems = shopsPersonalPickup.map((shops: any) => {
      return {
        name: shops.name,
        value: shops.id,
        label: (
          <>
            <div style={{ fontSize: '14px', paddingBottom: '2px' }}>
              <strong>{shops.name}</strong>
            </div>
            <div style={{ fontSize: '13px' }}>
              {shops.address}, {shops.zip} {shops.city}
            </div>
          </>
        ),
      };
    });
  }

  const shippingOptions = {
    name: 'shipping',
    items: deliveryAndPayments.map((deliveryAndPayment: any) => {
      return {
        id: deliveryAndPayment.delivery_id,
        label: deliveryAndPayment.delivery_name,
        price: deliveryAndPayment.price.price,
        price_without_vat: deliveryAndPayment.price.price_without_vat,
        options:
          deliveryAndPayment.delivery_id === 'DPD_PICKUP'
            ? deliveryPointsItems
            : deliveryAndPayment.delivery_id === 'PREDAJNA'
            ? shopPersonalPickupItems
            : [],
      };
    }),
  };

  const paymentOptions = {
    name: 'payment',
    items: getPaymentsByDeliveryId(
      cart && cart.delivery ? cart.delivery.id : null,
    )
      .map((payment: any) => {
        return {
          id: payment.payment_id,
          label: payment.payment_name,
          price: payment.price.price,
          price_without_vat: payment.price.price_without_vat,
        };
      })
      .filter((payment) => {
        if (user && isSchoolOrTeacher(user) && payment.id === 'VyzvaNaPrevod') {
          return false;
        }
        return true;
      }),
  };

  const shippingSelected = cart && cart.delivery ? cart.delivery.id : null;

  const setsCount =
    cart && cart.items
      ? cart.items
          .filter((item) => item.skvelko_set)
          .map((item) => item.skvelko_set.id)
          .filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
          }).length
      : 0;

  return (
    <Loader loading={loading}>
      <CartInfoBox />
      <Heading>{__('Zvoľte spôsob dopravy')}</Heading>
      <Row>
        <ShippingPaymentCol>
          {deliveryError ? (
            <PaymentErrorWrapper className="error">
              <Alert type="error"> {deliveryError} </Alert>
            </PaymentErrorWrapper>
          ) : (
            ''
          )}

          <Loader loading={deliveryLoading}>
            <CartRadios
              data={shippingOptions}
              onChange={onDeliveryChange}
              onDeliveryPointChange={onDeliveryPointChange}
              onDeliveryPointInfo={onDeliveryPointInfo}
              onDeliveryPointRemove={onDeliveryPointRemove}
              onPacketaDeliveryPointChange={onPacketaDeliveryPointChange}
              onPacketaDeliveryPointInfo={onPacketaDeliveryPointInfo}
              onPacketaDeliveryPointRemove={onPacketaDeliveryPointRemove}
              onShopPersonalPickupChange={onShopPersonalPickupChange}
              onShopPersonalPickupInfo={onShopsPersonalPickupInfo}
              onShopPersonalPickupRemove={onShopsPersonalPickupRemove}
              filterOption={filterOption}
              selected={shippingSelected}
              classes={classes}
              onChildrenNameChange={onChildrenNameChange}
              /*onClassChange={onClassChange}*/
              onClassPart1Change={onClassPart1Change}
              onClassPart2Change={onClassPart2Change}
              selectedChildrenName={childrenName}
              /*selectedClassName={deliveryClassName}*/
              selectedClassPart1Name={deliveryClassPart1Name}
              selectedClassPart2Name={deliveryClassPart2Name}
              deliveryToSchoolError={deliveryToSchoolError}
              schoolInfo={schoolInfo}
              showWithVat={showWithVat}
              user={user}
              someSetsHasDeliveryToSchoolActivated={
                someSetsHasDeliveryToSchoolActivated
              }
              schoolsLength={schoolIds ? schoolIds.length : 0}
              setsCount={setsCount}
            />
          </Loader>
          <StyledInfoText>
            * pri platbe vopred začína plynúť doba dodania odo dňa pripísania
            platby na náš účet
          </StyledInfoText>
          <Heading>{__('Zvoľte spôsob platby')}</Heading>

          {paymentError ? (
            <PaymentErrorWrapper className="error">
              <Alert type="error">
                {' '}
                {__('Spôsob platby je povinný údaj')}{' '}
              </Alert>
            </PaymentErrorWrapper>
          ) : (
            ''
          )}

          <Loader loading={paymentLoading}>
            <CartRadios
              data={paymentOptions}
              onChange={onPaymentChange}
              user={user}
              selected={cart && cart.payment ? cart.payment.id : null}
              showWithVat={showWithVat}
            />
          </Loader>
        </ShippingPaymentCol>
        <SummaryCol>
          <CartSummary
            showWithVat={showWithVat}
            cart={cart}
            customerDetails={customerDetails}
            onApplyTrustPointsChange={onApplyTrustPointsChange}
            loading={loadingTrustPoints}
            onDemandCartItems={onDemandCartItems}
            regularCartItems={regularCartItems}
            isDemand={isDemand}
          />
        </SummaryCol>
      </Row>
      <Footer>
        <Loader loading={backStepLoading}>
          <BackButton className="lg" onClick={onBackClick}>
            <BackIcon icon="chevron" />
            {__('Späť')}
          </BackButton>
        </Loader>
        <Loader loading={nextStepLoading}>
          <NextButton
            className="primary lg"
            onClick={onContinueClick}
            /*disabled={!cart || !cart.delivery || !cart.payment}*/
          >
            {__('Pokračovať na dodacie údaje')}
          </NextButton>
        </Loader>
      </Footer>
    </Loader>
  );
};

const mapStateToProps = (state) => {
  return {
    customerDetails: state.cart.customerDetails,
    deliveryPoints: state.cart.deliveryPoints,
    deliveryPoint: state.cart.deliveryPoint,
    shopsPersonalPickup: state.cart.shopsPersonalPickup,
    shopPersonalPickup: state.cart.shopPersonalPickup,
    packetaDeliveryPoint: state.cart.cart.packeta_delivery_point,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Cart2' })(Cart2),
);
