import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { ContentStyle } from '../UrlMapper/UrlMapper';
import { getBaseUrl } from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';

interface Props {
  urlMapData: any;
}

class Redirect extends React.Component<Props> {
  public static async getInitialProps(props) {
    return props;
  }

  public componentDidMount() {
    const urlMapData = this.props.urlMapData;
    if (urlMapData && urlMapData.type === 'redirect') {
      if (urlMapData.data && urlMapData.data.new_url && window) {
        window.location.replace(getBaseUrl(urlMapData.data.new_url));
      }
    }
  }

  public render() {
    return (
      <ContentStyle>
        <Loader loading={true} />
      </ContentStyle>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Redirect' })(Redirect),
);
