import * as actionTypes from './constants';
import {
  redirectReducer,
  REDIRECT,
} from '@bart.sk-ecommerce/react-eshop-redirects';
import { getSuccesfullLoginMsg } from '../../defaults';

export const generalInitialState = {
  lang: 'sk',
  currency: 'EUR',
  title: process.env.REACT_APP_TITLE,
  categoryData: null,
  searchedResults: {
    categories: [],
    products: {
      products: [],
    },
  },
  searchedResultsPreview: {
    categories: [],
    products: {
      products: [],
    },
  },
  protectionSettings: {},
  searchedResultsLoader: false,
  infoMultipleStoresShow: false,
  cmsContent: {},
  globalMetaTags: {},
  product: {},
  schoolArticle: {},
  schoolArticleConnections: {},
  banners: {},
  searchSessionId: null,
  searchId: null,
  settings: {
    cartActionFrom: null,
    cartActionTo: null,
    cartActionCategoryDesktopBanner: null,
    cartActionSetsDesktopBanner: null,
    cartActionModalWebContentId: null,
  },
};

export const generalReducer = (
  state = generalInitialState,
  { type, payload },
) => {
  switch (type) {
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        lang: payload.lang,
      };
    case actionTypes.SET_CURRENCY:
      return {
        ...state,
        currency: payload.currency,
      };
    case actionTypes.SET_DEFAULT_TITLE:
      return {
        ...state,
        title: payload.defaultTitle,
      };
    case actionTypes.SET_TOP_MENU_ITEMS:
      return {
        ...state,
        menuItems: payload.menuItems,
      };
    case actionTypes.SET_MAIN_MENU:
      return {
        ...state,
        mainMenu: payload.mainMenu,
      };
    case actionTypes.SET_FOOTER_DATA:
      return {
        ...state,
        footerData: payload.footerData,
      };
    case actionTypes.SET_CONTACT_INFO:
      return {
        ...state,
        contactInfo: payload.contactInfo,
      };
    case actionTypes.SET_SOCIAL_URLS:
      return {
        ...state,
        socialUrls: payload.socialUrls,
      };
    case actionTypes.SET_META_TAGS:
      return {
        ...state,
        metaTags: payload.metaTags,
      };
    case actionTypes.SET_CATEGORY_GROUP:
      return {
        ...state,
        categoriesGroup: payload.categoriesGroup,
      };
    case actionTypes.SET_CURRENT_CATEGORY_ID:
      return {
        ...state,
        currentCategoryId: payload.currentCategoryId,
      };
    case actionTypes.SET_CATEGORY_GROUP_MAIN:
      return {
        ...state,
        mainGroupCategory: payload.mainGroupCategory,
      };
    case actionTypes.SET_SEARCH_INITIAL_LOADED:
      return {
        ...state,
        searchInitialLoaded: payload.searchInitialLoaded,
      };
    case actionTypes.SET_SEARCHED_RESULTS_PREVIEW_LOADER:
      return {
        ...state,
        searchedResultsPreviewLoader: payload.searchedResultsPreviewLoader,
      };
    case actionTypes.SET_SEARCHED_RESULTS_PREVIEW:
      return {
        ...state,
        searchedResultsPreview: payload.searchedResultsPreview,
      };
    case actionTypes.SET_SEARCHED_RESULTS_LOADER:
      return {
        ...state,
        searchedResultsLoader: payload.searchedResultsLoader,
      };
    case actionTypes.SET_SEARCHED_RESULTS:
      return {
        ...state,
        searchedResults: payload.searchedResults,
      };
    case actionTypes.SET_OTHER_TEXTS:
      return {
        ...state,
        otherTexts: payload.otherTexts,
      };
    case actionTypes.SET_ADD_TO_CART_MODAL_PRODUCT:
      return {
        ...state,
        addToCartModalProduct: payload.addToCartModalProduct,
      };
    case actionTypes.SET_ADD_TO_CART_MAX_ITEMS_EXCEEDED_MODAL_PRODUCT:
      return {
        ...state,
        addToCartMaxItemExceededModalProduct:
          payload.addToCartMaxItemExceededProduct,
      };
    case actionTypes.SET_ADD_TO_CART_MODAL_PRODUCT_OUT_OF_STOCK:
      return {
        ...state,
        addToCartModalProductOutOfStock:
          payload.addToCartModalProductOutOfStock,
      };
    case actionTypes.SET_ADD_TO_CART_MODAL_PRODUCT_QUANTITY_LIMIT:
      return {
        ...state,
        addToCartModalProductQuantityLimit:
          payload.addToCartModalProductQuantityLimit,
      };
    case actionTypes.SET_DELIVERY_POINT_MODAL_POINT:
      return {
        ...state,
        deliveryPointModalPoint: payload.deliveryPointModalPoint,
      };
    case actionTypes.SET_PACKETA_DELIVERY_POINT_MODAL_POINT:
      return {
        ...state,
        packetaDeliveryPointModalPoint: payload.packetaDeliveryPointModalPoint,
      };
    case actionTypes.SET_SHOP_PERSONAL_PICKUP_MODAL:
      return {
        ...state,
        shopPersonalPickupModal: payload.shopPersonalPickupModal,
      };
    case actionTypes.SET_SELECT_VARIANT_MODAL:
      return {
        ...state,
        selectVariantModal: payload.selectVariantModal,
      };

    case actionTypes.CHANGE_AMOUNT_PRODUCT_OUT_OF_SOCK:
      return {
        ...state,
        amountProductOutOfStock: payload.amountProductOutOfStock,
      };

    case actionTypes.SET_REGISTRATION_SUCCESS_CMS:
      return {
        ...state,
        registrationSuccessCMS: payload.registrationSuccessCMS,
      };

    case actionTypes.SET_CURRENT_PRODUCT_FOR_PREVIEW:
      return {
        ...state,
        currentProductForPreview: payload.currentProductForPreview,
      };

    case actionTypes.SET_HTML_SITEMAP:
      return {
        ...state,
        htmlSitemap: payload.htmlSitemap,
      };

    case actionTypes.SET_SHOP_NETWORK_CURRENT_ARTICLE:
      return {
        ...state,
        shopNetworkCurrentArticle: payload.shopNetworkCurrentArticle,
      };

    case actionTypes.SET_CURRENT_CATEGORY_GROUP:
      return {
        ...state,
        currentCategoryGroup: payload.currentCategoryGroup,
      };

    case actionTypes.SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: payload.currentCategory,
      };

    case actionTypes.SET_GENERAL_FREE_DELIVERY_INFO: {
      return {
        ...state,
        deliveryFreePrice: payload.info.price,
      };
    }

    case actionTypes.SET_STATE_CATEGORY_LIST_NEW: {
      return {
        ...state,
        stateCategoryListNew: payload.stateCategoryListNew,
      };
    }

    case actionTypes.SET_CURRENT_CATEGORY_GROUP_ID: {
      return {
        ...state,
        currentCategoryGroupId: payload.currentCategoryGroupId,
      };
    }

    case actionTypes.SET_CATEGORY_CHANGED:
      return {
        ...state,
        categoryChanged: payload.categoryChanged,
      };

    case actionTypes.SET_URLMAP_DATA:
      return {
        ...state,
        urlMapData: payload.urlMapData,
      };

    case actionTypes.SET_SHOW_POPUP_SET_SELECT:
      return {
        ...state,
        showPopupSetSelect: payload.showPopupSetSelect,
      };

    case actionTypes.SET_SHOW_POPUP_SET_SELECT2:
      return {
        ...state,
        showPopupSetSelect2: payload.showPopupSetSelect2,
      };

    case actionTypes.SET_ALERT_SCHOOL_TEXT:
      return {
        ...state,
        alertSchoolText: payload.alertSchoolText,
      };

    case actionTypes.SET_CMS_CONTENT_MODAL:
      return {
        ...state,
        cmsContentModal: payload.cmsContentModal,
      };

    case actionTypes.SET_ARTICLE_MODAL:
      return {
        ...state,
        articleModal: payload.articleModal,
      };

    case actionTypes.SET_REGISTRATION_TEXT:
      return {
        ...state,
        registrationText: payload.registrationText,
      };

    case actionTypes.SET_SKVELKO_BOTTOM_TEXT:
      return {
        ...state,
        skvelkoBottomText: payload.skvelkoBottomText,
      };

    case actionTypes.SET_SKVELKO_DESCRIPTION_TEXT:
      return {
        ...state,
        skvelkoDescriptionText: payload.skvelkoDescriptionText,
      };

    case actionTypes.SET_INFO_MULTIPLE_STORES_TEXT:
      return {
        ...state,
        infoMultipleStoresText: payload.infoMultipleStoresText,
      };

    case actionTypes.SET_INFO_MULTIPLE_STORES_LOADING:
      return {
        ...state,
        infoMultipleStoresLoading: payload.infoMultipleStoresLoading,
      };

    case actionTypes.SET_INFO_MULTIPLE_STORES_SHOW:
      return {
        ...state,
        infoMultipleStoresShow: payload.infoMultipleStoresShow,
      };

    case actionTypes.SET_GIFT_REMAIN:
      return {
        ...state,
        giftRemain: payload.giftRemain,
      };

    case actionTypes.SET_CART_PROTECTION_MODAL:
      return {
        ...state,
        cartProtectionModal: payload.cartProtectionModal,
      };

    case actionTypes.SET_CART_PROTECTION_TEXT:
      return {
        ...state,
        cartProtectionText: payload.cartProtectionText,
      };

    case actionTypes.SET_CART_PROTECTION_TEXT_LOADING:
      return {
        ...state,
        cartProtectionTextLoading: payload.cartProtectionTextLoading,
      };

    case actionTypes.SET_CART_HAD_USER_MODAL:
      return {
        ...state,
        cartHadUserModal: payload.cartHadUserModal,
      };

    case actionTypes.SET_PREV_PATH:
      return {
        ...state,
        prevPath: payload.prevPath,
      };

    case actionTypes.SET_MAILCHIMP_SCHOOL_TEXT:
      return {
        ...state,
        mailchimpSchoolText: payload.mailchimpSchoolText,
      };

    case actionTypes.SET_MAILCHIMP_SCHOOL_LOADING:
      return {
        ...state,
        mailchimpSchoolLoading: payload.mailchimpSchoolLoading,
      };

    case actionTypes.SET_CMS_CONTENT:
      return {
        ...state,
        cmsContent: payload.cmsContent,
      };

    case actionTypes.SET_CMS_CONTENT_LOADING:
      return {
        ...state,
        cmsContentLoading: payload.cmsContentLoading,
      };

    case REDIRECT: {
      return redirectReducer(state, payload);
    }

    case actionTypes.SET_GLOBAL_META_TAGS:
      return {
        ...state,
        globalMetaTags: payload.globalMetaTags,
      };

    case actionTypes.SET_PRODUCT:
      return {
        ...state,
        product: payload.product,
      };

    case actionTypes.SET_PRODUCT_LOADING:
      return {
        ...state,
        productLoading: payload.productLoading,
      };

    case actionTypes.SET_PROTECTION_SETTINGS:
      return {
        ...state,
        protectionSettings: payload.protectionSettings,
      };

    case actionTypes.SET_GENERAL_SETTINGS:
      return {
        ...state,
        settings: payload.settings,
      };

    case actionTypes.SET_INVALID_EMAILS:
      return {
        ...state,
        invalidEmails: payload.invalidEmails,
      };

    case actionTypes.SET_SEARCH_SESSION_ID:
      return {
        ...state,
        searchSessionId: payload,
      };

    case actionTypes.SET_SEARCH_ID:
      return {
        ...state,
        searchId: payload,
      };

    case actionTypes.SET_FAVORITE_PRODUCTS_COUNT:
      return {
        ...state,
        favoriteProductsCount: payload,
      };

    case actionTypes.SET_SCHOOL_ARTICLES:
      return {
        ...state,
        schoolArticles: payload.schoolArticles,
      };

    case actionTypes.SET_SCHOOL_ARTICLES_LOADING:
      return {
        ...state,
        schoolArticlesLoading: payload.schoolArticlesLoading,
      };

    case actionTypes.SET_SCHOOL_ARTICLE:
      return {
        ...state,
        schoolArticle: payload.schoolArticle,
      };

    case actionTypes.SET_SINGLE_SCHOOL_ARTICLE:
      return {
        ...state,
        singleSchoolArticle: payload.singleSchoolArticle,
      };

    case actionTypes.SET_SCHOOL_ARTICLE_LOADING:
      return {
        ...state,
        schoolArticleLoading: payload.schoolArticleLoading,
      };

    case actionTypes.SET_SCHOOL_ARTICLE_CONNECTIONS:
      return {
        ...state,
        schoolArticleConnections: payload.schoolArticleConnections,
      };

    case actionTypes.SET_SCHOOL_ARTICLE_CONNECTIONS_LOADING:
      return {
        ...state,
        schoolArticleConnectionsLoading:
          payload.schoolArticleConnectionsLoading,
      };

    case actionTypes.SET_BANNERS:
      return {
        ...state,
        banners: payload.banners,
      };

    case actionTypes.SET_CURRENT_UNIQS:
      return {
        ...state,
        currentUniqs: payload.currentUniqs,
      };

    default:
      return state;
  }
};

const loginReducerInitialState = {};

export const loginReducer = (state = loginReducerInitialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_LOGIN_USER:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.RECEIVE_LOGIN_USER_SUCCESS:
      return {
        ...state,
        success: getSuccesfullLoginMsg(),
        isFetching: false,
      };
    case actionTypes.RECEIVE_LOGIN_USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };
    default:
      return state;
  }
};

const registrationReducerInitialState = {
  isFetching: false,
  success: '',
  error: '',
};

export const registrationReducer = (
  state = registrationReducerInitialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.REQUEST_REGISTER_USER:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.RECEIVE_REGISTER_USER_SUCCESS:
      return {
        success: getSuccesfullLoginMsg(),
        isFetching: false,
      };
    case actionTypes.RECEIVE_REGISTER_USER_FAILURE:
      return {
        error: action.payload.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
