import { rem } from 'polished';
import styled from 'styled-components';

export const Placeholder = styled.p`
  font-size: ${rem(14)};
  margin: 0 0 ${rem(12)} 0;
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(16)};
  }
`;

export const SectionTitle = styled.h2`
  font-weight: normal;
  font-size: ${rem(14)};
  text-transform: uppercase;
  margin: 0 0 ${rem(12)} 0;
`;
