import * as React from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import { __ } from 'react-i18n/lib';

const Wrapper = styled.li`
  display: flex;
  flex: 0 0 ${({ theme }) => theme.grid.col.col6};
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  text-align: center;
  border: 1px solid transparent;
  ${({ theme }) => theme.media('xs')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
  }
  ${({ theme }) => theme.media('sm')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col3};
  }
  ${({ theme }) => theme.media('md')} {
    flex: 0 0 20%;
  }
`;

const Content = styled(Link)`
  background: ${({ theme }) => theme.color.white};
  color: inherit;
  &:hover {
    text-decoration: none;
  }
`;

const Thumb = styled(ImagePlaceholder)`
  display: block;
`;

const TitleWrapper = styled.div`
  padding: ${rem(12)} ${rem(8)};
  ${({ theme }) => theme.media('md')} {
    padding: ${rem(8)} ${rem(24)} ${rem(32)} ${rem(24)};
  }
`;

const Title = styled.p`
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  margin: 0;
`;

interface SetPreviewProps {
  url: string;
  picture: string | null;
  name: string;
  onSetClick?: (e?) => void;
}

class SetPreview extends React.PureComponent<SetPreviewProps> {
  public render() {
    const { url, picture, name } = this.props;
    const prefixName = __('Univerzálna sada');
    return (
      <Wrapper>
        <Content to={url} onClick={this.props.onSetClick}>
          <Thumb src={picture ? picture : undefined} w={250} h={250} />
          <TitleWrapper>
            <Title>{prefixName}</Title>
            <Title>
              <strong>
                {name.replace(new RegExp(`^(${prefixName}\.)`), '')}
              </strong>
            </Title>
          </TitleWrapper>
        </Content>
      </Wrapper>
    );
  }
}

export default SetPreview;
