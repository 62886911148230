import * as React from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import { Fragment } from 'react';
import ReactDOMServer from 'react-dom/server';

const SelectDefault = styled(Select)`
  border-right: '1px solid red';
`;

interface Props {
  name?: string;
  disabled?: boolean;
  className?: string;
  multiple?: boolean;
  value?: string;
  defaultValue?: string;
  options: Array<{
    value: string;
    label?: string;
    selected?: boolean;
    disabled?: boolean;
  }>;
  onChange?: (event) => void;
  placeholder?: string;
  footerItem?: {
    title: string;
    option: string;
    href: string;
  };
  onClickFooterItem?: (event) => void;
  filterOption?: (option, inputValue) => void;
  dispatch?: any;
  noOptionsMessage?: any;
  showFooterOnlyIfAnyOptions?: boolean;
  showSecondFooterWithoutNoOptionMessageWhenNoOptions?: boolean;
}

interface State {
  selectedOption: string | null;
  hasOptions: boolean;
}

const colourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      maxHeight: '50px',
      position: 'relative',
      height: '100%',
      borderRadius: isDisabled ? '0' : '4',
      borderWidth: '0',
      boxShadow: 'none',
      flex: 'nowrap',
      '&:hover': {
        borderColor: '#fff',
        boxShadow: 'none',
      },
    };
  },

  container: (styles) => ({
    ...styles,
    padding: 0,
    maxHeight: '50px',
    border: 'initial',
    borderBottom: '1px solid #f1efef',
    '@media only screen and (min-width: 600px)': {
      borderRight: '1px solid #f1efef',
    },
  }),

  indicatorSeparator: (styles) => {
    return {
      display: 'none',
    };
  },

  menu: (styles) => ({
    ...styles,
    position: 'relative',
    top: 0,
    borderRadius: '4px 4px 0 0',
  }),

  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '20px',
  }),
};

export class ReactSelect extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    let selectedOption: any = null;
    this.props.options.forEach((option) => {
      if (option.selected === true) {
        selectedOption = option;
      }
    });

    this.state = {
      selectedOption,
      hasOptions: true,
    };
  }

  public handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    if (this.props.onChange) {
      this.props.onChange(selectedOption);
    }
  };

  public setSelectedOption() {
    let selectedOption: any = null;

    this.props.options.forEach((option) => {
      if (option.selected === true) {
        selectedOption = option;
      }
    });
    if (selectedOption !== this.state.selectedOption) {
      this.setState({
        selectedOption,
      });
    }
  }

  public Menu = (props) => {
    let hasFirstOption: any = null;
    if (
      this.props.showFooterOnlyIfAnyOptions ||
      this.props.showSecondFooterWithoutNoOptionMessageWhenNoOptions
    ) {
      const MenuComponent = (
        <components.Menu {...props}>{props.children}</components.Menu>
      );
      const htmlString = ReactDOMServer.renderToStaticMarkup(MenuComponent);
      hasFirstOption = htmlString.includes('react-select-');
    }

    const showFooter =
      this.props.showFooterOnlyIfAnyOptions !== true ||
      (this.props.showFooterOnlyIfAnyOptions === true && hasFirstOption);
    const showSecondFooterAndNoOptions =
      this.props.showSecondFooterWithoutNoOptionMessageWhenNoOptions === true &&
      !hasFirstOption;

    return (
      <Fragment>
        {!showSecondFooterAndNoOptions ? (
          <components.Menu {...props}>{props.children}</components.Menu>
        ) : (
          <></>
        )}
        {showFooter ? (
          !showSecondFooterAndNoOptions ? (
            <FooterItem
              onMouseDown={props.selectProps.onClickFooterItem}
              onTouchEnd={props.selectProps.onClickFooterItem}
            >
              <FooterItemDesc>
                {props.selectProps.footerItems.title}
              </FooterItemDesc>
              <FooterItemOption>
                {props.selectProps.footerItems.option}
              </FooterItemOption>
            </FooterItem>
          ) : (
            <FooterItem2
              onMouseDown={props.selectProps.onClickFooterItem}
              onTouchEnd={props.selectProps.onClickFooterItem}
            >
              <FooterItemDesc2>
                {props.selectProps.footerItems.title2}
              </FooterItemDesc2>
              <FooterItemOption2>
                {props.selectProps.footerItems.option2}
              </FooterItemOption2>
            </FooterItem2>
          )
        ) : (
          <></>
        )}
      </Fragment>
    );
  };

  public render() {
    const {
      className,
      options,
      name,
      placeholder,
      disabled,
      filterOption,
      footerItem,
      dispatch,
      onClickFooterItem,
    } = this.props;

    this.setSelectedOption();

    return (
      <SelectDefault
        name={name}
        isDisabled={disabled}
        className={className}
        onChange={this.handleChange}
        placeholder={placeholder}
        options={options}
        value={this.state.selectedOption}
        styles={colourStyles}
        filterOption={filterOption}
        dispatch={dispatch}
        footerItems={footerItem ? footerItem : null}
        components={footerItem ? { Menu: this.Menu } : null}
        // tslint:disable-next-line:jsx-no-lambda
        noOptionsMessage={() => 'Žiadne výsledky'}
        onClickFooterItem={onClickFooterItem}
      />
    );
  }
}

const FooterItem = styled.div`
  position: relative;
  background-color: white;
  top: -8px;
  border: 1px solid #dadada;
  z-index: 2;
  border-radius: 0 0 4px 4px;
  padding: 8px 12px;
  cursor: pointer;
`;

const FooterItemDesc = styled.div`
  margin-top: 2px;
  font-size: 11px;
  color: #0000ff;
`;

const FooterItemOption = styled.div`
  margin-top: 2px;
`;

const FooterItem2 = styled.div`
  position: relative;
  background-color: white;
  top: -8px;
  border: 1px solid #dadada;
  z-index: 2;
  border-radius: 0 0 4px 4px;
  padding: 8px 12px;
  cursor: pointer;
`;

const FooterItemDesc2 = styled.div`
  margin-top: 2px;
  font-size: 14px;
  color: #337bcc;
  text-align: left;
  font-weight: bold;
`;

const FooterItemOption2 = styled.div`
  margin-top: 8px;
  text-align: left;
  color: black;
`;
