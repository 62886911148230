import * as React from 'react';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { Col, Container } from '../../theme/libraries/grid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { __ } from 'react-i18n/lib';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import {
  countFinalPriceOfProducts,
  debounce,
  formatPrice,
  getSkvelkoSetName,
  isTablet,
} from '../../utilities';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import { Input, InputStyled } from '../../components/_helpers/form/Input';
import Icon from '../../components/_helpers/Icon/Icon';
import {
  ButtonDisableFocusOutline,
  ButtonStyled,
} from '../../components/_helpers/form/Button';
import {
  ListItemUnstyled,
  ListUnstyled,
} from '../../components/_helpers/typography/ListStyles';
import { InputWithSearchIcon } from '../../components/_helpers/form/FormStyles';
import ImagePlaceholder from '../../components/_helpers/Image/ImagePlaceholder';
import { loadEditSetMobileText, setNewSetState } from './actions';
import ProductPreviewModal from '../../components/_helpers/Modal/ProductPreviewModal';
import API from '../../services/API';
import Alert from '../../components/Alert/Alert';
import { CloseIconWhite } from '../../components/_helpers/Icon/Close';
import { NewSetState } from './NewSet';
import { browserHistory } from 'react-router';
import useUnload from '../../components/_helpers/UseUnload/useUnload';
import { Loader } from '../../components/_helpers/Loader/Loader';

const StyledCol = styled(Col)`
  padding-right: ${rem(5)};
  padding-left: ${rem(5)};
`;

const ListWrapper = styled.div`
  font-size: ${rem(14)};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Available = styled(ListWrapper)`
  height: ${rem(505)};
  ${({ theme }) => theme.media(1024)} {
    height: initial;
  }
`;

const Selected = styled(ListWrapper)`
  height: ${rem(480)};
  ${({ theme }) => theme.media(1024)} {
    position: relative;
    flex: 1;
  }
`;

const AmountCol = styled(StyledCol)`
  width: ${rem(64)};
  padding: 0;
`;

const Amount = styled(InputStyled)`
  width: ${rem(45)};
  padding: ${rem(7.5)} ${rem(5)};
  text-align: center;
`;

const SettingsContainer = styled.div`
  ${({ theme }) => theme.media(1024)} {
    height: 100%;
  }
  position: relative;
`;

const Settings = styled.div`
  display: none;
  margin: 0 ${rem(-5)};
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(${rem(-56)});
  ${({ theme }) => theme.media(1024)} {
    display: flex;
    flex-wrap: wrap;
    width: ${rem(50)};
    top: 50%;
    right: auto;
    left: ${rem(-60)};
    transform: translateY(-50%);
  }
`;

const SettingsButton = styled(ButtonStyled)`
  line-height: 0;
  padding: ${rem(12)};
  margin: ${rem(5)};
  ${({ theme }) => theme.media(1024)} {
    &:last-child {
      order: -1;
    }
  }
`;

const SettingsIcon = styled(Icon)`
  ${({ theme }) => theme.size(12)};
  ${({ theme }) => theme.transition('fill')};
  ${SettingsButton}:disabled & {
    fill: ${({ theme }) => theme.color.gray87};
  }
  &.return {
    transform: rotate(180deg);
  }
  ${({ theme }) => theme.media(1024)} {
    transform: rotate(-90deg);
    &.return {
      transform: rotate(90deg);
    }
  }
`;

const Name = styled.p`
  display: inline-block;
  margin: 0;
  padding-left: ${rem(2)};
`;

const Price = styled.small`
  padding-left: ${rem(6)};
  font-size: ${rem(12)};
  line-height: 1.7;
  color: ${({ theme }) => theme.color.blue};

  &:before {
    content: '(';
    padding-right: ${rem(2)};
  }

  &:after {
    content: ')';
    padding-left: ${rem(2)};
  }
`;

const ThumbWrapper = styled(StyledCol)`
  width: ${rem(50)};
  position: relative;
`;

const ThumbWrapperPreview = styled.a`
  display: block;
  position: relative;
  cursor: pointer;
`;

const PreviewIcon = styled(Icon)`
  ${({ theme }) => theme.size(16)};
  position: absolute;
  right: 0;
  bottom: 0;
`;

const ListItems = styled(ListUnstyled)`
  ${({ theme }) => theme.media(1024)} {
    &.left {
      top: 101px;
    }
    &.right {
      top: 0px;
    }

    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;

    position: absolute;
  }
  margin: 0;
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-top: none;
`;

const ItemClickWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const Item: any = styled(ListItemUnstyled)`
  display: flex;
  align-items: center;
  padding: ${rem(4)} ${rem(15)} ${rem(4)} ${rem(5)};
  ${({ theme }) => theme.transition('background-color')};
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.gray93};

  &:last-child {
    border-bottom: none;
  }
  &:nth-child(even) {
    background: ${({ theme }) => rgba(theme.color.black, 0.02)};
  }
  &.active {
    background: ${({ theme }) => rgba(theme.color.primary, 0.04)};
    color: red;
  }

  cursor: pointer;
`;

const HeaderClass = css`
  font-weight: bold;
  font-size: ${rem(14)};
`;

const HeaderName = styled(StyledCol)`
  ${HeaderClass};
  padding-right: ${rem(90)};
  margin-right: ${rem(-90)};
  font-weight: bold;
  color: ${({ theme }) => theme.color.white};
  small {
    font-size: ${rem(14)};
    font-weight: normal;
  }
`;

const HeaderPrice = styled(StyledCol)`
  ${HeaderClass};
  width: ${rem(70)};
`;

const SetsRow = styled.div`
  ${({ theme }) => theme.media(1024)} {
    display: flex;
    height: 100%;
  }
`;

const SetsCol = styled.div`
  padding-top: ${rem(1)};
  ${({ theme }) => theme.media(1024)} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col6};
    &.left {
      padding-right: ${rem(40)};
    }
    &.right {
      padding-left: ${rem(40)};
    }
  }
`;

const Products = styled.div`
  ${({ theme }) => theme.media(1024)} {
    height: 100%;
    display: flex;
    flex-direction: column;

    position: relative;
  }
  margin-right: ${rem(-10)};
  margin-left: ${rem(-10)};
  ${({ theme }) => theme.media('xs')} {
    margin-right: 0;
    margin-left: 0;
  }
  background: ${({ theme }) => theme.color.white};
`;

const ProductHighlightContent = css`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.gray38};
  padding: ${rem(10)} ${rem(20)};
`;

const ProductsHeader = styled(Clearfix)`
  ${ProductHighlightContent};
  font-weight: 700;
`;

const ProductFooter = styled.footer`
  ${ProductHighlightContent};
  font-size: ${rem(16)};
`;

const Thumb = styled(ImagePlaceholder)``;

const ContainerSetProducts = styled(Container)`
  ${({ theme }) => theme.media(1024)} {
    position: absolute;
    top: 94px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }

  margin-top: ${rem(30)};
`;

const SearchInput = styled(Input)`
  display: block;
  width: 100%;
  ${InputWithSearchIcon};
  padding: ${rem(20)} ${rem(56)} ${rem(20)} ${rem(20)};
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: 0;
  &::placeholder {
    color: ${({ theme }) => theme.color.black};
  }
`;

const ButtonAdd = styled.button`
  color: ${({ theme }) => theme.color.white};
  font-size: ${rem(14)};
  letter-spacing: ${rem(-0.5)};
  text-transform: uppercase;
  background: ${({ theme }) => theme.color.gray38};
  border-radius: ${rem(6)};
  border: none;
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray38};
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.gray38};
  ${ButtonDisableFocusOutline}
`;

const ButtonRemove = styled.button`
  line-height: 1;
  padding: ${rem(6)};
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  border: none;
  ${ButtonDisableFocusOutline}
`;

const ButtonRemoveIcon = styled(CloseIconWhite)`
  width: ${rem(14)};

  &:before,
  &:after {
    padding: ${rem(1.5)};
  }
`;

const ItemCount = styled.p`
  margin: 0;
  padding: 0 ${rem(6)} 0 ${rem(12)};

  &:after {
    content: '.';
  }
`;

const FooterSmall = styled.small`
  display: block;
`;

const Button = styled(ButtonStyled)`
  float: right;
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

const NameWrapper = styled.p`
  display: inline-block;
  margin: 0;
  max-width: ${rem(300)};
`;

interface SetProductsProps {
  dispatch: any;
  setName: string;
  setTitleText: (title: string) => void;
  setSubtitleText: (title: string) => void;
  setClosePath: (path: string) => void;
  editSetMobileText?: {
    name: string;
    body: string;
  };
  newSetState: NewSetState;
}

const SetProducts = (props: SetProductsProps) => {
  const [
    showPreviewProductModal,
    setShowPreviewProductModal,
  ] = React.useState<boolean>(false);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);

  const [previewProduct, setProductPreview] = React.useState<any>(null);
  const [pickedSelectedProductId, setPickedSelectedProductId] = React.useState<
    number | null
  >(null);
  const [pickedSearchedProductId, setPickedSearchedProductId] = React.useState<
    number | null
  >(null);
  const [searchedProducts, setSearchedProducts] = React.useState<any>([]);
  const [selectedProductsState, setSelectedProductsState] = React.useState<any>(
    [],
  );
  const [lastOperation, setLastOperation] = React.useState<any>(null);

  let rightScrollRef = React.createRef();

  const mobile = isTablet();

  const searchProducts = (text: string = '') => {
    setIsSearching(true);
    setPickedSelectedProductId(null);
    setPickedSearchedProductId(null);
    let parameters: any = {
      q: text,
      withPublish: '1',
      limit: 10000,
      onlyForSets: true,
      withRetailPrice: true,
    };
    if (text === '') {
      parameters = {
        ...parameters,
        sort: 'alphabetically',
        sortDir: 'asc',
      };
    }
    API.searchProducts(parameters).then((searchResult) => {
      setSearchedProducts(searchResult.products);
      setIsSearching(false);
    });
  };

  const searchProductsEvent = (e) => searchTextDebounce(e.target.value);

  const [searchTextDebounce] = debounce(searchProducts, 500);

  React.useEffect(() => {
    if (props.setTitleText) {
      props.setTitleText('Obsah zoznamu');
    }
    if (mobile) {
      props.dispatch(loadEditSetMobileText());
    }
    trySetSelectedProducts();
    searchProducts();
  }, []);

  React.useEffect(() => {
    trySetSelectedProducts();
    if (props.setSubtitleText && props.newSetState) {
      props.setSubtitleText(
        getSkvelkoSetName(
          props.newSetState.name1,
          props.newSetState.name2,
          props.newSetState.name3,
        ),
      );
    }
  }, [props.newSetState]);

  React.useEffect(() => {
    if (rightScrollRef && rightScrollRef.current && lastOperation === 'add') {
      const current: any = rightScrollRef.current;
      current.scrollTop = current.scrollHeight;
      setLastOperation(null);
    }
  }, [rightScrollRef]);

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = '';
  });

  const trySetSelectedProducts = () => {
    if (props.newSetState) {
      setSelectedProductsState(props.newSetState.selectedProducts);
    }
  };

  const showPreview = (product) => {
    setProductPreview(product);
    setShowPreviewProductModal(true);
  };

  const onSearchedProductClick = (productId) => {
    setPickedSearchedProductId(
      pickedSearchedProductId === productId ? null : productId,
    );
  };

  const handleAdd = (productId) => {
    setLastOperation('add');
    const pickedProduct = searchedProducts.find(
      (product) => product.product_id === productId,
    );
    const selectedProducts = selectedProductsState;

    let founded = false;

    for (const item of selectedProducts) {
      if (item.product.product_id === pickedProduct.product_id) {
        founded = true;
        item.count = (Number(item.count) + 1).toString();
      }
    }

    if (!founded) {
      selectedProducts.push({ count: '1', product: pickedProduct });
    }

    setPickedSelectedProductId(null);
    setSelectedProductsState([...selectedProducts]);
  };

  const handleRemove = (productId) => {
    setLastOperation('remove');
    const selectedProducts = selectedProductsState;

    const selectedIndex = selectedProducts.findIndex(
      (item) => item.product.product_id === productId,
    );
    selectedProducts.splice(selectedIndex, 1);

    setSelectedProductsState([...selectedProducts]);
  };

  const onRightClick = () => {
    handleAdd(pickedSearchedProductId);
  };

  const onLeftClick = () => {
    handleRemove(pickedSelectedProductId);
  };

  const onCountChange = (productId, event) => {
    let selectedProducts = selectedProductsState;

    for (const item of selectedProducts) {
      if (item.product.product_id === productId) {
        if (event.target.value === '') {
          item.count = event.target.value;
        } else {
          const value = parseInt(event.target.value, 10);
          if (!isNaN(value)) {
            item.count = value.toString();
          } else {
            item.count = '0';
          }
        }
      }
    }

    selectedProducts = selectedProducts.filter((item) => item.count !== '0');
    setSelectedProductsState([...selectedProducts]);
  };

  const onSelectedProductClick = (productId) => {
    setPickedSelectedProductId(
      pickedSelectedProductId === productId ? null : productId,
    );
  };

  const reorder = (array, startIndex, endIndex) => {
    const [removed] = array.splice(startIndex, 1);
    array.splice(endIndex, 0, removed);
    return array;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const selectedProducts = reorder(
      selectedProductsState,
      result.source.index,
      result.destination.index,
    );

    setSelectedProductsState([...selectedProducts]);
  };

  const closeProductPreviewModal = () => {
    setShowPreviewProductModal(false);
  };

  const handleDone = () => {
    props.dispatch(
      setNewSetState({
        ...props.newSetState,
        selectedProducts: selectedProductsState,
      }),
    );
    browserHistory.goBack();
  };

  const placeholder = '/images/placeholder-46.png';
  const placeholder2x = '/images/placeholder-46@2x.png';

  const setPrice = countFinalPriceOfProducts(selectedProductsState);

  return (
    <ContainerSetProducts className="base">
      {showPreviewProductModal && (
        <ProductPreviewModal
          product={previewProduct}
          onCloseButtonClick={closeProductPreviewModal}
        />
      )}
      {props.editSetMobileText && mobile && (
        <Alert
          heading={props.editSetMobileText.name}
          type={'error'}
          className={'separator'}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: props.editSetMobileText.body,
            }}
          />
        </Alert>
      )}
      <SetsRow>
        <SetsCol className="left">
          <Products>
            <ProductsHeader>
              <HeaderName>
                {__('Dostupné produkty')}{' '}
                <small>({searchedProducts.length}) </small>
              </HeaderName>
            </ProductsHeader>
            <SearchInput
              placeholder={__('Vyhľadávanie') + '...'}
              onChange={searchProductsEvent}
            />
            <Loader style={{ position: 'initial' }} loading={isSearching}>
              <Available>
                <ListItems className="left">
                  {searchedProducts.map((product: any, i) => {
                    return (
                      <Item
                        key={i}
                        className={
                          pickedSearchedProductId === product.product_id
                            ? 'active'
                            : ''
                        }
                      >
                        <ItemClickWrapper
                          onClick={onSearchedProductClick.bind(
                            null,
                            product.product_id,
                          )}
                        >
                          <ThumbWrapper>
                            <ThumbWrapperPreview
                              onClick={showPreview.bind(null, product)}
                            >
                              <Thumb
                                src={product.picture}
                                placeholder={placeholder}
                                retinaPlaceholder={placeholder2x}
                                w={92}
                                h={92}
                                notLazyloading={true}
                              />
                              <PreviewIcon icon="eye" />
                            </ThumbWrapperPreview>
                          </ThumbWrapper>
                          <NameWrapper>
                            {product.name
                              .split(' ')
                              .map((word: string, index: number) => {
                                return <Name key={index}>{`${word} `}</Name>;
                              })}
                            <Price>
                              {formatPrice(product.main_good.unitprice, 'EUR')}
                            </Price>
                          </NameWrapper>
                        </ItemClickWrapper>
                        <ButtonAdd
                          onClick={handleAdd.bind(null, product.product_id)}
                        >
                          {__('Pridať')}
                        </ButtonAdd>
                      </Item>
                    );
                  })}
                </ListItems>
              </Available>
            </Loader>
          </Products>
        </SetsCol>
        <SetsCol className="right">
          <SettingsContainer>
            <Products>
              <ProductsHeader>
                <HeaderName>
                  {__('Vybrané produkty')}{' '}
                  <small>({selectedProductsState.length})</small>
                </HeaderName>
                <HeaderPrice>{__('Počet')}</HeaderPrice>
              </ProductsHeader>
              <Selected>
                <ListItems className="right" ref={rightScrollRef}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {selectedProductsState.map((item, index) => {
                            return (
                              <Draggable
                                key={item.product.product_id}
                                draggableId={item.product.product_id}
                                index={index}
                              >
                                {(dragProvided, dragSnapshot) => (
                                  <Item
                                    className={
                                      pickedSelectedProductId ===
                                      item.product.product_id
                                        ? 'active'
                                        : ''
                                    }
                                    ref={dragProvided.innerRef}
                                    {...dragProvided.draggableProps}
                                    {...dragProvided.dragHandleProps}
                                  >
                                    <ItemClickWrapper
                                      onClick={onSelectedProductClick.bind(
                                        null,
                                        item.product.product_id,
                                      )}
                                    >
                                      <ItemCount>{index + 1}</ItemCount>
                                      <ThumbWrapper>
                                        <ThumbWrapperPreview
                                          onClick={showPreview.bind(
                                            null,
                                            item.product,
                                          )}
                                        >
                                          <Thumb
                                            src={item.product.picture}
                                            placeholder={placeholder}
                                            retinaPlaceholder={placeholder2x}
                                            w={92}
                                            h={92}
                                            notLazyloading={true}
                                          />
                                          <PreviewIcon icon="eye" />
                                        </ThumbWrapperPreview>
                                      </ThumbWrapper>
                                      <NameWrapper>
                                        {item.product.name
                                          .split(' ')
                                          .map(
                                            (word: string, index2: number) => {
                                              return (
                                                <Name key={index2}>
                                                  {`${word} `}
                                                </Name>
                                              );
                                            },
                                          )}
                                        <Price>
                                          {formatPrice(
                                            item.product.main_good.unitprice,
                                            'EUR',
                                          )}
                                        </Price>
                                      </NameWrapper>
                                    </ItemClickWrapper>
                                    <AmountCol>
                                      <Amount
                                        value={item.count}
                                        onChange={onCountChange.bind(
                                          null,
                                          item.product.product_id,
                                        )}
                                      />
                                    </AmountCol>
                                    <ButtonRemove
                                      onClick={handleRemove.bind(
                                        null,
                                        item.product.product_id,
                                      )}
                                    >
                                      <ButtonRemoveIcon />
                                    </ButtonRemove>
                                  </Item>
                                )}
                              </Draggable>
                            );
                          })}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </ListItems>
              </Selected>
              <ProductFooter>
                {__('Celková cena za zoznam')}:{' '}
                <strong>{formatPrice(setPrice, 'EUR')}</strong>
                <FooterSmall>
                  {__(
                    '(cena sa môže zmeniť ak je niektorý produkt v akcii alebo sa zmení jeho cena)',
                  )}
                </FooterSmall>
              </ProductFooter>
            </Products>
            <Settings>
              <SettingsButton
                disabled={pickedSelectedProductId === null}
                onClick={onLeftClick}
              >
                <SettingsIcon className="return" icon="chevron" />
              </SettingsButton>
              <SettingsButton
                disabled={pickedSearchedProductId === null}
                onClick={onRightClick}
              >
                <SettingsIcon icon="chevron" />
              </SettingsButton>
            </Settings>
          </SettingsContainer>
        </SetsCol>
      </SetsRow>
      <div>
        <Button className="primary lg separation" onClick={handleDone}>
          {__('Hotovo')}
        </Button>
      </div>
    </ContainerSetProducts>
  );
};

const mapStateToProps = (state) => {
  return {
    editSetMobileText: state.myAccount.editSetMobileText,
    newSetState: state.myAccount.newSetState,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SetProducts' })(SetProducts),
);
