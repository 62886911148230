import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

const Wrapper = styled.div`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  height: 100%;
  padding: ${rem(14)};
  ${({ theme }) => theme.media(1023)} {
    padding: ${rem(18)} ${rem(28)};
  }
  ${({ theme }) => theme.media(360)} {
    p,
    h3 {
      text-align: left;
    }
  }
  ${({ theme }) => theme.media(600)} {
    p,
    h3 {
      text-align: left;
    }
  }
`;

const Title = styled.h3`
  font-size: ${rem(16)};
  font-weight: 800;
  margin: 0 0 ${rem(12)};
  ${({ theme }) => theme.media(769)} {
    font-size: ${rem(18)};
  }
`;

const Body = styled.div`
  font-size: ${rem(14)};
  p {
    margin: 0;
    font-size: ${rem(16)};
  }
  button {
    margin: ${rem(8)} 0 0 0;
  }

  ${({ theme }) => theme.media(1023)} {
    p {
      font-size: ${rem(14)};
    }
  }
`;

export const TextWrapper = styled.p`
  font-size: ${rem(14)};
  line-height: 1.4;
`;

interface ContactBlockProps {
  title: string;
  children: any;
}

class ContactBlock extends React.PureComponent<ContactBlockProps> {
  public render() {
    return (
      <Wrapper>
        <Title>{this.props.title}</Title>
        <Body>{this.props.children}</Body>
      </Wrapper>
    );
  }
}

export default ContactBlock;
