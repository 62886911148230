import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { ButtonStyledStyles, ButtonStyles } from '../_helpers/form/Button';
import Icon from '../_helpers/Icon/Icon';
import { __ } from 'react-i18n';

const ButtonStyledStylesPagination = css`
  ${ButtonStyledStyles};
  &.inFilter:not(.primary) {
    background: ${({ theme }) => theme.form.border.color};
    border-color: ${({ theme }) => theme.color.gray89};
  }
`;

const PaginationWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  font-size: ${rem(14)};
  line-height: 1;
  text-align: center;
  padding: ${rem(20)} 0;
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  ${({ theme }) => theme.media('sm')} {
    margin-right: ${({ theme }) => rem(-theme.grid.padding / 4)};
    margin-left: ${({ theme }) => rem(-theme.grid.padding / 4)};
    background-color: initial;
  }
  &.no-margin {
    display: inline-block;
    padding: ${rem(6)} 0;
    text-align: center;
    float: right;
    ${({ theme }) => theme.media('sm')} {
      padding-right: ${rem(12)};
    }
  }
`;

const PaginationItemStyles = css`
  display: inline-block;
  border-radius: ${rem(4)};
  text-align: center;
  padding: ${rem(13)} ${rem(5)};
  margin-right: ${rem(2)};
  margin-left: ${rem(2)};
`;

const PaginationItem = styled.button`
  ${ButtonStyles};
  ${ButtonStyledStylesPagination};
  background-color: transparent;
  width: ${rem(40)};
  padding-right: ${rem(5)};
  padding-left: ${rem(5)};
  margin-right: ${rem(2)};
  margin-left: ${rem(2)};
`;

const PaginationItemActive = styled.span`
  ${ButtonStyles};
  ${ButtonStyledStylesPagination};
  width: ${rem(40)};
  padding-right: ${rem(5)};
  padding-left: ${rem(5)};
  margin-right: ${rem(2)};
  margin-left: ${rem(2)};
`;

const PaginationSpacer = styled.span`
  ${PaginationItemStyles};
  border-color: transparent;
  width: ${rem(40)};
  margin-right: ${rem(2)};
  margin-left: ${rem(2)};
`;

const PaginationItemArrow = styled(PaginationItem)`
  background-color: transparent;
  border-color: transparent;
  &:disabled {
    opacity: 0.2;
  }
`;

const PaginationPrev = styled(Icon)`
  ${({ theme }) => theme.size(12)};
  transform: rotate(90deg);
`;

const PaginationNext = styled(PaginationPrev)`
  transform: rotate(-90deg);
`;

interface PaginationIO {
  total: number;
  current: number | string;
  onPageChanged: (pageNumner: number) => void;
  inFilter?: boolean;
}

class Pagination extends React.Component<PaginationIO> {
  public render() {
    let { current } = this.props;
    const { total, inFilter } = this.props;
    if (typeof current === 'string') {
      current = parseInt(current, 10);
    }
    const paginationNumbers = this.pagination(current, total);

    return (
      <PaginationWrapper className={inFilter ? 'no-margin' : ''}>
        <PaginationItemArrow
          onClick={this.props.onPageChanged.bind(this, current - 1)}
          disabled={current === 1}
          className={inFilter ? 'inFilter' : ''}
          aria-label={__('Predchadzajúca stránka')}
        >
          <PaginationPrev icon="chevron" />
        </PaginationItemArrow>

        {paginationNumbers &&
          paginationNumbers.map((oneNumber, i) => {
            if (Number.isInteger(oneNumber)) {
              if (oneNumber === current) {
                return (
                  <PaginationItemActive
                    className={inFilter ? 'inFilter primary' : 'primary'}
                    onClick={this.props.onPageChanged.bind(this, oneNumber)}
                    key={i}
                  >
                    {oneNumber}
                  </PaginationItemActive>
                );
              } else {
                return (
                  <PaginationItem
                    className={inFilter ? 'inFilter' : ''}
                    onClick={this.props.onPageChanged.bind(this, oneNumber)}
                    key={i}
                  >
                    {oneNumber}
                  </PaginationItem>
                );
              }
            } else {
              return <PaginationSpacer key={i}>&hellip;</PaginationSpacer>;
            }
          })}

        <PaginationItemArrow
          className={inFilter ? 'inFilter' : ''}
          onClick={this.props.onPageChanged.bind(this, current + 1)}
          disabled={current === total}
          aria-label={__('Ďalšia stránka')}
        >
          <PaginationNext icon="chevron" />
        </PaginationItemArrow>
      </PaginationWrapper>
    );
  }

  public pagination(currentPage, pageCount) {
    const delta = 1;
    const left = currentPage - delta;
    const right = currentPage + delta + 1;
    let result: any = [];

    result = Array.from({ length: pageCount }, (v, k) => k + 1).filter(
      (i) => i && i >= left && i < right,
    );

    if (result.length > 1) {
      // Add first page and dots
      if (result[0] > 1) {
        if (result[0] > 2) {
          result.unshift('...');
        }
        result.unshift(1);
      }

      // Add dots and last page
      if (result[result.length - 1] < pageCount) {
        if (result[result.length - 1] !== pageCount - 1) {
          result.push('...');
        }
        result.push(pageCount);
      }
    }

    return result;
  }
}

export default Pagination;
