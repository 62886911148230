import { color } from './libraries/colors';
import { fonts } from './libraries/fonts';
import { grid } from './libraries/grid';
import { form } from './libraries/forms';
import { borderRadius } from './libraries/border-radius';
import { boxShadow } from './libraries/box-shadow';
import { transition, transitions } from './libraries/transition';
import { media, size, sizeWidth } from './libraries/media';
import { rgba } from 'polished';
import { css } from 'styled-components';

const breakpoints = {
  l1620: 1620,
  l1300: 1300,
  l1150: 1150,
  l1050: 1050,
  l925: 925,
  m810: 810,
  m760: 760,
  m600: 600,
  m580: 580,
  s450: 450,
};

const mediab = {
  l1620: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l1620}px) {
      ${css(arg, ...args)}
    }
  `,
  l1300: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l1300}px) {
      ${css(arg, ...args)}
    }
  `,
  l1150: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l1150}px) {
      ${css(arg, ...args)}
    }
  `,
  l1050: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l1050}px) {
      ${css(arg, ...args)}
    }
  `,
  l925: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.l925}px) {
      ${css(arg, ...args)}
    }
  `,
  m810: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.m810}px) {
      ${css(arg, ...args)}
    }
  `,
  m760: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.m760}px) {
      ${css(arg, ...args)}
    }
  `,
  m600: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.m600}px) {
      ${css(arg, ...args)}
    }
  `,
  m580: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.m580}px) {
      ${css(arg, ...args)}
    }
  `,
  s450: (arg: any, ...args: any[]) => css`
    @media (max-width: ${breakpoints.s450}px) {
      ${css(arg, ...args)}
    }
  `,
};

export const theme = {
  global: {
    font: {
      family: fonts.primary,
      smoothing: true,
    },
  },
  color,
  fonts,
  grid,
  form,
  borderRadius,
  boxShadow,
  transition,
  transitions,
  media,
  mediab,
  size,
  sizeWidth,
  colors: {
    loaderPrimary: color.primary,
    loaderSecondary: rgba(color.primary, 0.2),
  },
  index: {
    priorityContentShadow: 2, // excuse me wtf?
  },
};
