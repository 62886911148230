import * as React from 'react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Icon from '../_helpers/Icon/Icon';
import { getBaseUrl } from '../../utilities';
import { BreadcrumbsItems } from '../../constants';

const BreadcrumbsList = styled.map`
  color: ${({ theme }) => theme.color.gray66};
  font-size: ${rem(12)};
  line-height: 0.6;
`;

const BreadcrumbIconStyles = css`
  ${({ theme }) => theme.size(8)};
  fill: ${({ theme }) => theme.color.gray66};
`;

const BreadcrumbsBackIcon = styled(Icon)`
  ${BreadcrumbIconStyles};
  display: none;
`;

const BreadcrumbsLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: inline-block;
  padding: ${rem(5)} ${rem(3)} ${rem(5)} 0;
  ${({ theme }) => theme.media(1024)} {
    padding-top: ${rem(6)};
    padding-bottom: ${rem(10)};
  }
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

const BreadcrumbsNoLink = styled.div`
  color: inherit;
  text-decoration: none;
  display: inline-block;
  padding: ${rem(5)} ${rem(3)} ${rem(5)} 0;
  ${({ theme }) => theme.media(1024)} {
    padding-top: ${rem(6)};
    padding-bottom: ${rem(10)};
  }
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

const BreadcrumbsSeparator = styled(Icon)`
  ${BreadcrumbIconStyles};
  transform: translateY(${rem(-1)}) rotate(-90deg);
  margin-right: ${rem(10)};
  margin-left: ${rem(7)};
`;

const BreadcrumbsItemsHolder = styled.p`
  margin: 0 0 ${rem(10)} 0;
`;

interface BreadcrumbsProps {
  items?: BreadcrumbsItems;
  className?: string;
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { items, className } = props;
  if (items && items[0] && items[0].name && items[0].name !== 'Úvod') {
    items.unshift({
      name: 'Úvod',
      url: '/',
    });
  }

  return (
    <BreadcrumbsList className={className || ''}>
      <BreadcrumbsItemsHolder>
        {items &&
          items.map((item, i) => {
            const url = getBaseUrl(item.link ? item.link : item.url);
            return (
              <React.Fragment key={i}>
                {i !== 0 ? <BreadcrumbsSeparator icon="chevron" /> : ''}
                {items.length > 2 && i === items.length - 2 ? (
                  <BreadcrumbsBackIcon icon="chevron" />
                ) : (
                  ''
                )}
                {item.name &&
                  item.name.split(' ').map((value, index) => {
                    if (url) {
                      return (
                        <BreadcrumbsLink to={url} key={index}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: value,
                            }}
                          ></div>
                        </BreadcrumbsLink>
                      );
                    } else {
                      return (
                        <BreadcrumbsNoLink key={index}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: value,
                            }}
                          ></div>
                        </BreadcrumbsNoLink>
                      );
                    }
                  })}
              </React.Fragment>
            );
          })}
      </BreadcrumbsItemsHolder>
    </BreadcrumbsList>
  );
};

export default Breadcrumbs;
