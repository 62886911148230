import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem, rgba } from 'polished';
import { ButtonStyles } from '../../_helpers/form/Button';
import { CaretStyles } from '../../_helpers/Icon/Caret';
import {
  SelectStyledStyles,
  SelectWhiteStyles,
} from '../../_helpers/form/Select';
import AnimateHeight from 'react-animate-height';
import {
  loadFilterFromLocation,
  setCityIsSelected,
  setSelectedCityName,
  setSchoolIsSelected,
  setSelectedSkvelkoSetId,
  setSelectedClassId,
  setSelectedSchoolId,
} from '../../../containers/Home/actions';
import { ReactSelect } from '../../_helpers/form/ReactSelect';
import ImagePlaceholder from '../../_helpers/Image/ImagePlaceholder';
import { Popup } from '../../_helpers/Popup/Popup';
import {
  loadSkvelkoBottomText,
  loadSkvelkoDescriptionText,
  setShowPopupSetSelect,
  setShowPopupSetSelect2,
} from '../../../containers/App/actions';
import { isCartGiftsValid, normalizeString, prop } from '../../../utilities';
import { Loader } from '../../_helpers/Loader/Loader';
import Skvelko from '../../_helpers/Icon/Skvelko';
import { Media, MediaContextProvider } from '../../../theme/media';

const Wrapper: any = styled.div`
  background: ${({ theme }) => theme.color.secondary} center center
    url('/images/pattern/pattern.svg') repeat;
  background-size: ${rem(600)};
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  position: relative;
  z-index: 2;
  &:after {
    display: block;
    content: '';
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.color.secondary},
      ${({ theme }) => rgba(theme.color.secondary, 0)}
    );
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  ${({ theme }) => theme.media('md')} {
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    margin: 0 ${rem(5)} 0 ${rem(-5)};
    margin-left: ${rem(-5)};
    margin-right: ${rem(5)};
    flex: 0 0 ${(props) => (props.giftValid ? `76%` : '100%')};
  }
`;

const Body = styled.div`
  position: relative;
  z-index: 0;
  padding: ${rem(12)} ${rem(16)};
  ${({ theme }) => theme.media('xs')} {
    padding: ${rem(38)} ${rem(30)} ${rem(20)} ${rem(30)};
  }
`;

const Title = styled.p`
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;
  font-size: ${rem(15)};
  margin: ${rem(12)} 0 0 0;
  max-width: 65%;
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(26)};
    margin: 0 0 0 ${rem(12)};
    max-width: ${rem(315)};
  }
`;

const ToggleDescription = styled.button`
  ${ButtonStyles};
  font-size: ${rem(16)};
  padding: 0;
  ${({ theme }) => theme.media('xs')} {
    display: none;
  }
`;

const Caret = styled.span`
  ${CaretStyles};
  margin-left: ${rem(10)};
`;

const Description = styled(AnimateHeight)`
  font-size: ${rem(14)};
  ${({ theme }) => theme.media('xs')} {
    display: block;
    height: auto !important;
    overflow: visible !important;
    div {
      p {
        margin: 0;
      }
      display: block !important;
    }
  }
`;

const FilterForm = styled.div`
  margin-top: ${rem(20)};
  ${({ theme }) => theme.media('xs')} {
    display: flex;
  }
`;

const Selects = styled.div`
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  position: relative;
  ${({ theme }) => theme.media('xs')} {
    display: flex;
    flex: 1 1 auto;
  }
`;

const FilterSelect = styled(ReactSelect)`
  ${SelectStyledStyles};
  display: block;
  width: 100%;
  ${SelectWhiteStyles};
  &:focus {
    border-color: transparent;
  }
  border-radius: 0;
  & + & {
    border-top-color: ${({ theme }) => theme.form.border.color};
  }
  &:first-child {
    border-top-right-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-top-left-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  }
  &:last-child {
    border-bottom-right-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
    border-bottom-left-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
  }
  ${({ theme }) => theme.media('xs')} {
    flex: 1 0 ${({ theme }) => theme.grid.col.col4};
    & + & {
      border-top-color: transparent;
      border-left-color: ${({ theme }) => theme.form.border.color};
    }
    > div > div:first-child {
      padding-top: ${rem(8)};
      padding-bottom: ${rem(8)};
    }
    &.class {
      flex: 0 1 auto;
    }
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: ${({ theme }) =>
        rem(theme.borderRadius.default)};
      border-top-left-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    }
    &:last-child {
      border-radius: 0;
    }
  }
`;

const FooterText = styled.p`
  font-size: ${rem(14)};
  margin: ${rem(20)} 0 0 0;
  p {
    margin: 0;
  }
  a {
    color: inherit;
  }
`;

const Image = styled(ImagePlaceholder)`
  display: block;
  padding: 0;
`;

const ImageMobile = styled(Image)`
  position: absolute;
  right: 0;
  bottom: ${rem(115)};
`;

const HeaderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`;

const TextContent = styled.div`
  ${({ theme }) => theme.media('xs')} {
    flex: 0 0 80%;
  }
`;

const ImageContent = styled.div`
  ${({ theme }) => theme.media('xs')} {
    margin-top: ${rem(-20)};
    margin-bottom: ${rem(-20)};
    flex: 0 0 20%;
    align-self: flex-end;
  }
`;

const TitleContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${rem(10)};
  flex-direction: column;
  ${({ theme }) => theme.media('md')} {
    flex-direction: row;
    align-items: center;
    margin-bottom: ${rem(20)};
  }
`;

interface FilterProps {
  isLoading: boolean;
  cities?: any;
  schools?: any;
  sets?: any;
  classes?: any;
  onCityChange: (city: string) => void;
  onSchoolChange: (city: string) => void;
  onClassChange: (e) => void;
  skvelkoSetsIsEmpty: boolean;
  onSearch: (
    setId: number,
    classId: number | null,
    currentSchoolName: string | null,
    currentCityName: string | null,
    currentSetName: string | null,
  ) => void;
  dispatch: any;
  selectedCityName: string;
  selectedSchoolId: number | null;

  selectedSkvelkoSetId: number | null;
  selectedClassId: number | null;

  cityIsSelected: boolean;
  schoolIsSelected: boolean;

  skvelkoDescription: any;
  skvelkoBottom: any;

  showPopupSetSelect?: boolean;
  showPopupSetSelect2?: boolean;

  settings?: any;

  params?: {
    id?: string;
  };
  user?: any;
}

interface FilterStates {
  descriptionVisible: boolean;
  currentCities: any;
  currentSchools: any;
}

class Filter extends React.PureComponent<FilterProps, FilterStates> {
  public static async getInitialProps(parentProps) {
    const id = prop(parentProps, 'params.id');
    parentProps.dispatch(loadSkvelkoBottomText());
    parentProps.dispatch(loadSkvelkoDescriptionText());
    if (id) {
      parentProps.dispatch(loadFilterFromLocation(id));
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      descriptionVisible: false,
      currentCities: [],
      currentSchools: [],
    };

    this.handleToggleDescription = this.handleToggleDescription.bind(this);
  }

  public handleToggleDescription = () => {
    this.setState({
      descriptionVisible: !this.state.descriptionVisible,
    });
  };

  public hidePopups = () => {
    this.props.dispatch(setShowPopupSetSelect(false));
    this.props.dispatch(setShowPopupSetSelect2(false));
  };

  public handleLoadSchoolsByCity = (selectedOption) => {
    this.hidePopups();

    if (!this.props.cityIsSelected) {
      this.props.dispatch(setCityIsSelected(true));
    }
    this.props.dispatch(setSchoolIsSelected(false));
    this.props.dispatch(setSelectedSchoolId(null));
    this.props.dispatch(setSelectedSkvelkoSetId(null));
    this.props.dispatch(setSelectedClassId(null));

    this.props.dispatch(setSelectedCityName(selectedOption.value));
    this.props.onCityChange(selectedOption.value);
  };

  public handleLoadSchoolsByCityFooter = () => {
    this.hidePopups();

    if (!this.props.cityIsSelected) {
      this.props.dispatch(setCityIsSelected(true));
    }
    this.props.dispatch(setSchoolIsSelected(false));
    this.props.dispatch(setSelectedSchoolId(null));
    this.props.dispatch(setSelectedSkvelkoSetId(null));
    this.props.dispatch(setSelectedClassId(null));

    this.props.dispatch(setSelectedCityName('Univerzálna sada'));
    this.props.onCityChange('Univerzálna sada');
  };

  public handleLoadSetBySchoold = (selectedOption) => {
    this.hidePopups();

    if (!this.props.schoolIsSelected) {
      this.props.dispatch(setSchoolIsSelected(true));
    }

    this.props.dispatch(setSelectedSkvelkoSetId(null));
    this.props.dispatch(setSelectedClassId(null));
    this.props.dispatch(setSelectedSchoolId(selectedOption.value));
    this.props.onSchoolChange(selectedOption.value);
  };

  public handleLoadSetBySchooldFooter = () => {
    this.hidePopups();

    if (!this.props.schoolIsSelected) {
      this.props.dispatch(setSchoolIsSelected(true));
    }

    this.props.dispatch(setSelectedSkvelkoSetId(null));
    this.props.dispatch(setSelectedClassId(null));

    this.props.dispatch(setSelectedCityName('Univerzálna sada'));
    this.props.onCityChange('Univerzálna sada');

    this.props.dispatch(setSelectedSchoolId(11602));
    this.props.onSchoolChange('11602');
  };

  public handleSetSkvelkoSetId = (selectedOption) => {
    this.hidePopups();
    this.props.dispatch(setSelectedSkvelkoSetId(selectedOption.value));
    setTimeout(() => {
      this.handleSearch();
    }, 100);
  };

  public handleSearch = () => {
    if (this.props.selectedSkvelkoSetId !== null) {
      const currentSchool = this.props.schools
        ? this.props.schools.find(
            (searchSchool) =>
              searchSchool.value.toString() ===
              (this.props !== null && this.props.selectedSchoolId !== null
                ? this.props.selectedSchoolId.toString()
                : null),
          )
        : null;
      const currentCity = this.props.cities
        ? this.props.cities.find(
            (findCity) => findCity.value === this.props.selectedCityName,
          )
        : null;
      const currentSet = this.props.sets
        ? this.props.sets.find(
            (findSet) =>
              findSet.value.toString() ===
              (this.props !== null && this.props.selectedSkvelkoSetId
                ? this.props.selectedSkvelkoSetId.toString()
                : null),
          )
        : null;

      this.props.onSearch(
        this.props.selectedSkvelkoSetId,
        this.props.selectedClassId,
        currentSchool ? currentSchool.name : '',
        currentCity ? currentCity.value : '',
        currentSet ? currentSet.name : '',
      );
    }
  };

  public onClassChange = (e) => {
    this.props.dispatch(setSelectedClassId(e.target.value));
    this.props.onClassChange(e.target.value);
  };

  public closePopupSelect = () => {
    this.props.dispatch(setShowPopupSetSelect(false));
    this.props.dispatch(setShowPopupSetSelect2(false));
  };

  public closePopupSelect2 = () => {
    this.props.dispatch(setShowPopupSetSelect2(false));
  };

  public render() {
    const citiesWithSelected = this.props.cities.map((city) => {
      return {
        value: city.value,
        label: city.value,
        selected: city.value === this.props.selectedCityName,
      };
    });

    const schoolsWithSelected = this.props.schools.map((school) => {
      return {
        value: school.value,
        label: school.name,
        selected: school.value === this.props.selectedSchoolId,
      };
    });

    const setsWithSelected = this.props.sets.map((set) => {
      return {
        value: set.value,
        label: set.name,
        selected: set.value === this.props.selectedSkvelkoSetId,
      };
    });

    const filterOption = (option, inputValue) => {
      const { label } = option;
      return (
        !inputValue ||
        (label &&
          inputValue &&
          normalizeString(label).startsWith(normalizeString(inputValue)))
      );
    };

    const footerItemCities = {
      title: __('Ak obec nie je v zozname, môžete použiť univerzálnu sadu'),
      option: __('Univerzálna sada'),
      href: '/90001',

      title2: __('Obec nie je v zozname, môžete použiť univerzálnu sadu'),
      option2: __('Univerzálna sada'),
      href2: '/90001',
    };

    const footerItemSchool = {
      title: __('Ak škola nie je v zozname, môžete použiť univerzálnu sadu'),
      option: __('Univerzálna sada pre ZŠ'),
      href: '/90001',

      title2: __('Škola nie je v zozname, môžete použiť univerzálnu sadu'),
      option2: __('Univerzálna sada pre ZŠ'),
      href2: '/90001',
    };

    return (
      <Wrapper
        giftValid={isCartGiftsValid(this.props.settings, this.props.user)}
      >
        <Loader loading={this.props.isLoading}>
          <MediaContextProvider>
            <Body>
              <HeaderContent>
                <TextContent>
                  <TitleContent>
                    <Skvelko size={160} />
                    <Title>{__('Zoznamy pomôcok na nový školský rok')}</Title>
                  </TitleContent>
                  <ToggleDescription onClick={this.handleToggleDescription}>
                    {__('Čo je to Skvelko?')}
                    <Caret />
                  </ToggleDescription>
                  <Description
                    height={this.state.descriptionVisible ? 'auto' : 0}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.skvelkoDescription?.body,
                      }}
                    />
                  </Description>
                </TextContent>
                <ImageContent>
                  <Media greaterThanOrEqual="xs">
                    <Image src={'images/school_child.png'} w={164} h={246} />
                  </Media>
                </ImageContent>
              </HeaderContent>
              <FilterForm>
                <Selects>
                  {!this.props.showPopupSetSelect &&
                  this.props.showPopupSetSelect2 ? (
                    <Popup
                      content={__('Začnite TU výberom obce Vašej školy')}
                      left={17}
                      bottom={-154}
                      bottomMobile={5}
                      position={'top'}
                      onClose={this.closePopupSelect2}
                    />
                  ) : (
                    ''
                  )}
                  {this.state.descriptionVisible ? (
                    ''
                  ) : (
                    <Media lessThan="xs">
                      <ImageMobile
                        src={'images/school_child.png'}
                        w={100}
                        h={150}
                      />
                    </Media>
                  )}
                  <FilterSelect
                    options={citiesWithSelected}
                    placeholder={__('Zvoľte mesto')}
                    onChange={this.handleLoadSchoolsByCity}
                    className="lg"
                    footerItem={footerItemCities}
                    filterOption={filterOption}
                    dispatch={this.props.dispatch}
                    onClickFooterItem={this.handleLoadSchoolsByCityFooter}
                    //noOptionsMessage={renderCitiesNoOptionMessage()}
                    showFooterOnlyIfAnyOptions={false}
                    showSecondFooterWithoutNoOptionMessageWhenNoOptions={true}
                  />
                  <FilterSelect
                    key={this.props.selectedCityName}
                    options={schoolsWithSelected}
                    placeholder={__('Zvoľte školu')}
                    onChange={this.handleLoadSetBySchoold}
                    disabled={!this.props.cityIsSelected}
                    className="lg"
                    footerItem={footerItemSchool}
                    dispatch={this.props.dispatch}
                    onClickFooterItem={this.handleLoadSetBySchooldFooter}
                    //noOptionsMessage={renderSchoolsNoOptionMessage()}
                    showFooterOnlyIfAnyOptions={false}
                    showSecondFooterWithoutNoOptionMessageWhenNoOptions={true}
                  />
                  {this.props.showPopupSetSelect ? (
                    <Popup
                      content={__(
                        'Začnite TU výberom zoznamu pre požadovaný ročník',
                      )}
                      left={83}
                      bottom={122}
                      bottomMobile={45}
                      onClose={this.closePopupSelect}
                    />
                  ) : (
                    ''
                  )}
                  <FilterSelect
                    options={setsWithSelected}
                    placeholder={__('Zvoľte ročník')}
                    disabled={
                      !this.props.schoolIsSelected ||
                      this.props.skvelkoSetsIsEmpty
                    }
                    onChange={this.handleSetSkvelkoSetId}
                    className={'lg'}
                  />
                </Selects>
              </FilterForm>
              <Media greaterThanOrEqual="xs">
                <FooterText
                  dangerouslySetInnerHTML={{
                    __html: this.props.skvelkoBottom?.body,
                  }}
                />
              </Media>
            </Body>
          </MediaContextProvider>
        </Loader>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCityName: state.home.selectedCityName,
    selectedSchoolId: state.home.selectedSchoolId,
    selectedSkvelkoSetId: state.home.selectedSkvelkoSetId,
    selectedClassId: state.home.selectedClassId,

    schoolIsSelected: state.home.schoolIsSelected,
    cityIsSelected: state.home.cityIsSelected,
    skvelkoDescription: state.general.skvelkoDescriptionText,
    skvelkoBottom: state.general.skvelkoBottomText,

    showPopupSetSelect: state.general.showPopupSetSelect,
    showPopupSetSelect2: state.general.showPopupSetSelect2,
    settings: state.general.settings,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Filter' })(Filter),
);
