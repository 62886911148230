import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import {
  activeVariantByIdSelector,
  currentProductIdSelector,
} from '../Product/selectors';
import { Container } from '../../theme/libraries/grid';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import styled from 'styled-components';
import { ContentContainerBlock } from '../../components/_helpers/Contact/ContactStyles';
import { rem } from 'polished';
import { Content } from '../../components/_layout/Page/ContentContainer';
import { Heading2 } from '../../components/_helpers/Heading/Heading';
import { __ } from 'react-i18n/lib';
import { HeadingIconStyles } from '../../components/Cart/ThankYou';
import Icon from '../../components/_helpers/Icon/Icon';
import {
  ButtonStyledStyles,
  ButtonStyles,
} from '../../components/_helpers/form/Button';
import { Link } from 'react-router';
import API from '../../services/API';
import { Loader } from '../../components/_helpers/Loader/Loader';

const Wrapper = styled(Content)`
  font-size: ${rem(14)};
  text-align: center;
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(40)};
  }
  ${({ theme }) => theme.media('lg')} {
    padding: ${rem(40)};
  }
`;

const Success = styled(Icon)`
  ${HeadingIconStyles};
`;

const Heading = styled(Heading2)`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(40)};
`;

const FinishedButton = styled(Link)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  margin-top: ${rem(40)};
`;

interface Props {
  products: any[];
  categories: object[];
  banners: any[];
  parentProps: {};
  dispatch: any;
  isCartProcessing: number[];
  isError: boolean;
  detaultTitle: string;
  loaded: any;
  defaultTitle: string;
  token: string;
  lang: string;
  // activeVariant: ActiveVariantType;
  currentProductId: number | null;
}

const mapStateToProps = (state) => {
  return {
    isCartProcessing: state.cart.isProcessing,
    defaultTitle: state.general.title,
    token: state.auth.token,
    lang: state.general.lang,
    activeVariant: activeVariantByIdSelector(state),
    currentProductId: currentProductIdSelector(state),
  };
};

interface ActivationState {
  loading: boolean;
  message: string;
  success: boolean;
}

class Activation extends React.Component<Props, ActivationState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: '',
      success: false,
    };

    API.activateUser({
      activationCode: props.params.code,
    })
      .then(() => {
        this.setState({
          loading: false,
          message: __(
            'Aktivácia prebehla úspešne. Pre pokračovanie sa prosím prihláste.',
          ),
          success: true,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          message: __(
            'Pri aktivácií účtu nastala neznáma chyba. Skúste to prosím znova neskôr.',
          ),
          success: false,
        });
      });
  }

  public render() {
    return (
      <Container className="base">
        <Breadcrumbs />
        <Wrapper>
          <ContentContainerBlock>
            {this.state.loading ? (
              <Loader loading={true} />
            ) : this.state.success ? (
              <React.Fragment>
                <Success icon="checkCircle" />
                <Heading>{this.state.message}</Heading>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Success icon="errorCircle" />
                <Heading>{this.state.message}</Heading>
              </React.Fragment>
            )}

            <FinishedButton to="/" className="primary lg">
              {__('Ponuka internetového obchodu')}
            </FinishedButton>
          </ContentContainerBlock>
        </Wrapper>
      </Container>
    );
  }
}

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Activation' })(Activation),
);
