import { __ } from 'react-i18n';
import * as React from 'react';
import { normalizeString } from '../../../../utilities';
import { rem } from 'polished';
import styled from 'styled-components';

const Queries = styled.span`
  display: inline-block;
  margin: ${rem(8)} 0 ${rem(16)} 0;
  font-size: ${rem(14)};
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(15)};
  }
`;

const Founded = styled.span`
  color: ${({ theme }) => theme.color.error};
`;

const FoundQuery = styled.p`
  margin: ${rem(8)} 0 ${rem(16)} 0;
  font-size: ${rem(14)};
`;

interface Props {
  searchedResultsLoader: boolean;
  searchedQuery: string;
  foundedQueries: any;
  showFoundQuery?: boolean;
}

const FoundedQueries = (props: Props) => {
  const {
    searchedResultsLoader,
    searchedQuery,
    foundedQueries,
    showFoundQuery,
  } = props;

  const canShowFoundedQueries =
    !searchedResultsLoader &&
    foundedQueries &&
    !foundedQueries.some((h) =>
      normalizeString(searchedQuery.trim()).startsWith(
        normalizeString(h.trim()),
      ),
    );

  const foundedQueryCount = foundedQueries?.length;

  return canShowFoundedQueries ? (
    <Queries>
      <strong>{searchedQuery}</strong> {__('nenájdené')}
      {', '}
      <Founded>
        {__('mysleli ste')}{' '}
        {foundedQueries.map((highlight, index) => (
          <React.Fragment key={index}>
            <strong>{highlight}</strong>
            {foundedQueries[index + 1] && ', '}
            {foundedQueryCount - 2 === index &&
              !foundedQueries[index + 3] &&
              `${__('alebo')} `}
          </React.Fragment>
        ))}
        {'?'}
      </Founded>
    </Queries>
  ) : (
    <>
      {showFoundQuery && searchedQuery !== '' ? (
        <FoundQuery>
          {__('hľadali ste')} <strong>{searchedQuery}</strong>
        </FoundQuery>
      ) : (
        ''
      )}
    </>
  );
};

export default FoundedQueries;
