import * as React from 'react';
import { getBaseUrl } from '../../../utilities';
import styled, { css } from 'styled-components';
import { Sidebar } from '../../../theme/libraries/grid';
import { rem } from 'polished';
import { Link } from 'react-router';
import { Media, MediaContextProvider } from '../../../theme/media';

const Item = styled.li`
  list-style: none;
  &.hidden {
    display: none;
  }
  & + & {
    margin-top: ${rem(2)};
  }
`;

const ItemLinkStyles = css`
  display: block;
  background: ${({ theme }) => theme.color.gray97};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  color: inherit;
  padding: ${rem(13)} ${rem(20)};
  text-decoration: none;
  ${({ theme }) => theme.transition('background-color')};
`;

const ItemLinkActiveStyles = css`
  ${Item}.active & {
    background: ${({ theme }) => theme.color.secondary};
  }
  &:hover {
    background: ${({ theme }) => theme.color.gray93};
    text-decoration: none;
  }
`;

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

const ItemLink = styled(Link)`
  ${ItemLinkStyles};
  ${ItemLinkActiveStyles};
`;

const ItemCurrent = styled(Link)`
  ${ItemLinkStyles};
  ${ItemLinkActiveStyles};
`;

interface SidebarContainerProps {
  items: any;
}

const SidebarContainer = (props: SidebarContainerProps) => {
  const { items } = props;

  return (
    <MediaContextProvider>
      <Media greaterThanOrEqual="lg">
        <Sidebar>
          <Wrapper>
            {items?.length &&
              items.map((item, i) => (
                <Item key={i} className={item.active ? 'active' : ''}>
                  {item.active ? (
                    <ItemCurrent
                      to={getBaseUrl(
                        item.url === 'pre-skolske-zariadenia/skolsky-nabytok'
                          ? 'nabytok-a-vybavenie-skol'
                          : item.url,
                      )}
                    >
                      {item.text}
                    </ItemCurrent>
                  ) : (
                    <ItemLink
                      to={getBaseUrl(
                        item.url === 'pre-skolske-zariadenia/skolsky-nabytok'
                          ? 'nabytok-a-vybavenie-skol'
                          : item.url,
                      )}
                    >
                      {item.text}
                    </ItemLink>
                  )}
                </Item>
              ))}
          </Wrapper>
        </Sidebar>
      </Media>
    </MediaContextProvider>
  );
};

export default SidebarContainer;
