import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { MainBlock } from './Ucet';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import {
  downloadBase64,
  formatPrice,
  formatStringDate,
  isSsr,
} from '../../utilities';
import AnimateHeight from 'react-animate-height';
import Icon from '../../components/_helpers/Icon/Icon';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  formatToSqlDate,
  loadGroupedOrdersBySet,
  loadOrdersForSet,
  setOrdersBySetLoader,
} from './actions';
import API, { ThenArg } from '../../services/API';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { InputStyledStyles } from '../../components/_helpers/form/FormStyles';
import Alert from '../../components/Alert/Alert';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import dayjs from 'dayjs';
import { Loader } from '../../components/_helpers/Loader/Loader';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media('xs')} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  ${({ theme }) => theme.media('md')} {
    justify-content: flex-start;
  }
`;

const FromBlock = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media('xs')} {
    flex: 0 0 50%;
  }
  ${({ theme }) => theme.media('md')} {
    flex: 0 0 auto;
  }
`;

const ToBlock = styled(FromBlock)`
  margin-top: ${rem(20)};
  ${({ theme }) => theme.media('xs')} {
    margin-top: 0;
  }
`;

const From = styled.span`
  display: inline-block;
  font-size: ${rem(14)};
  padding-right: ${rem(10)};
`;

const To = styled(From)`
  ${({ theme }) => theme.media('xs')} {
    padding-left: ${rem(10)};
  }
`;

const StyledInputWrapper = styled.div`
  position: relative;
`;

const StyledDatePicker = styled(DatePicker)`
  ${InputStyledStyles};

  flex: 1 0 auto;
  width: ${rem(160)};
  padding-left: ${rem(50)};
`;

const StyledInputIcon = styled(Icon)`
  ${({ theme }) => theme.size(20)};
  fill: ${({ theme }) => theme.color.gray87};
  position: absolute;
  top: 50%;
  left: ${rem(15)};
  transform: translateY(-50%);
  cursor: pointer;
`;

const StyledButton = styled(ButtonStyled)`
  margin-top: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    margin-top: 0;
    margin-left: ${rem(10)};
  }
`;

const TableWrapper = styled.div`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  font-size: ${rem(14)};
  margin-top: ${rem(20)};
`;

const Table = styled.table`
  display: block;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  ${({ theme }) => theme.media('md')} {
    display: table;
  }
`;

const Thead = styled.thead`
  display: block;
  ${({ theme }) => theme.media('md')} {
    display: table-header-group;
  }
`;

const Tbody = styled.tbody`
  display: block;
  ${({ theme }) => theme.media('md')} {
    display: table-row-group;
  }
`;

const Tr = styled.tr`
  display: block;
  padding: ${rem(10)};
  cursor: pointer;
  ${Tbody} & {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
  &.gray {
    background: ${({ theme }) => theme.color.gray93};
    border: none;
    padding: 0;
  }
  ${({ theme }) => theme.media('md')} {
    display: table-row;
  }
`;

const Th = styled.th`
  display: block;
  text-align: left;
  ${({ theme }) => theme.media('md')} {
    display: table-cell;
    padding: ${rem(17)} ${rem(20)};
  }
`;

const Td = styled.td`
  display: block;
  &.caret {
    position: relative;
    padding-right: ${rem(50)};
  }
  ${({ theme }) => theme.media('md')} {
    display: table-cell;
    padding: ${rem(17)} ${rem(20)};
  }
`;

const Chevron = styled(Icon)`
  ${({ theme }) => theme.size(12)};
  position: absolute;
  top: 50%;
  right: ${rem(20)};
  transform: translateY(-50%);
  ${({ theme }) => theme.transition('transform')};
  &.active {
    transform: translateY(-50%) rotate(180deg);
  }
`;

const TableSmall = styled.table`
  display: block;
  cursor: default;
  ${({ theme }) => theme.media('md')} {
    display: table;
    width: 100%;
  }
`;

const TrSmall = styled.tr`
  display: block;
  padding: ${rem(10)};
  & + & {
    margin-top: ${rem(10)};
  }
  ${({ theme }) => theme.media('md')} {
    display: table-row;
  }
`;
const EmptyTrSmall = styled(TrSmall)`
  height: ${rem(30)};
`;

const ThSmall = styled.th`
  display: block;
  text-align: left;
  padding: ${rem(5)};
  ${({ theme }) => theme.media('md')} {
    display: table-cell;
    padding: ${rem(15)};
  }
`;

const TdSmall = styled.td`
  display: block;
  padding: ${rem(5)};
  &.colspan {
    padding: 0;
  }
  ${({ theme }) => theme.media('md')} {
    display: table-cell;
    padding: ${rem(5)} ${rem(15)};
  }
`;

const Checkbox = styled(CheckboxStyled)`
  margin-top: ${rem(16)};
`;

interface OrdersParentProp {
  dispatch: (a: any) => void;
  ordersBySetLoader: boolean;
  ordersBySet: ThenArg<typeof API.getOrdersGroupedBySet>;
  ordersForSet: any;
}

interface OrdersParentStates {
  activeAccordionSetIndex: number | null;
  dateFrom: string;
  dateTo: string;
  onlyDeliveryToSchool: boolean;
}

class OrdersParent extends React.PureComponent<
  OrdersParentProp,
  OrdersParentStates
> {
  public static async getInitialProps(props) {
    props.dispatch(loadGroupedOrdersBySet(null, null));
  }

  public dateFromRef;
  public dateToRef;

  constructor(props) {
    super(props);

    this.state = {
      activeAccordionSetIndex: null,
      dateFrom: '',
      dateTo: '',
      onlyDeliveryToSchool: false,
    };
  }

  public findOrdersForSet(skvelkoSetId) {
    let findedOrders = null;
    for (const oneOrders of this.props.ordersForSet) {
      if (String(oneOrders.skvelkoSetId) === String(skvelkoSetId)) {
        findedOrders = oneOrders.orders;
      }
    }
    return findedOrders;
  }

  public getOrdersForSet(skvelkoSetId) {
    let findedOrders = [];
    for (const oneOrders of this.props.ordersForSet) {
      if (String(oneOrders.skvelkoSetId) === String(skvelkoSetId)) {
        findedOrders = oneOrders.orders;
      }
    }
    return findedOrders;
  }

  public onDateFromChange = (date) => {
    this.setState({
      dateFrom: date,
    });
  };

  public onDateToChange = (date) => {
    this.setState({
      dateTo: date,
    });
  };

  public onFromCallendarClick = () => {
    if (this && this.dateFromRef) {
      this.dateFromRef.setFocus(true);
    }
  };

  public onToCallendarClick = () => {
    if (this && this.dateToRef) {
      this.dateToRef.setFocus(true);
    }
  };

  public onSearchClick = async (event) => {
    event.preventDefault();
    this.setState({ activeAccordionSetIndex: null });
    const dateTo = dayjs(this.state.dateTo).add(1, 'day');
    this.props.dispatch(
      loadGroupedOrdersBySet(
        this.state.dateFrom,
        dateTo,
        this.state.onlyDeliveryToSchool,
      ),
    );
  };

  public onPrintClick = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if (!isSsr()) {
      this.props.dispatch(setOrdersBySetLoader(true));
      const dateTo = dayjs(this.state.dateTo).add(1, 'day');
      const result: any = await API.getOrdersGroupedBySetPrint({
        dateFrom: formatToSqlDate(this.state.dateFrom),
        dateTo: formatToSqlDate(dateTo),
        onlyWithDeliveryToSchool: this.state.onlyDeliveryToSchool,
      });
      if (result && result.base64) {
        downloadBase64(result.base64, 'pdf');
      }
      this.props.dispatch(setOrdersBySetLoader(false));
    }
  };

  public onDeliveryToSchoolCheckboxChange = (event) => {
    this.setState({ onlyDeliveryToSchool: event.target.checked });
  };

  public render() {
    const { ordersBySet } = this.props;

    return (
      <>
        <MainBlock className="header">
          <Form>
            <FromBlock>
              <From>{__('od')}</From>
              <StyledInputWrapper>
                <StyledDatePicker
                  ref={(input) => {
                    this.dateFromRef = input;
                  }}
                  selected={this.state.dateFrom}
                  onChange={this.onDateFromChange}
                  dateFormat="dd.MM.yyyy"
                />
                <StyledInputIcon
                  icon="calendar"
                  onClick={this.onFromCallendarClick}
                />
              </StyledInputWrapper>
            </FromBlock>
            <ToBlock>
              <To>{__('do')}</To>
              <StyledInputWrapper>
                <StyledDatePicker
                  ref={(input) => {
                    this.dateToRef = input;
                  }}
                  selected={this.state.dateTo}
                  onChange={this.onDateToChange}
                  dateFormat="dd.MM.yyyy"
                />
                <StyledInputIcon
                  icon="calendar"
                  onClick={this.onToCallendarClick}
                />
              </StyledInputWrapper>
            </ToBlock>
            <StyledButton className="primary" onClick={this.onSearchClick}>
              {__('Zobraziť')}
            </StyledButton>
            <StyledButton className="primary" onClick={this.onPrintClick}>
              {__('Vytlačiť')}
            </StyledButton>
            <div>
              <Checkbox
                name="only-delivery-to-school"
                value="1"
                id="only-delivery-to-school"
                checked={this.state.onlyDeliveryToSchool}
                onChange={this.onDeliveryToSchoolCheckboxChange}
              >
                {__('zobraziť len objednávky s doručením do školy')}
              </Checkbox>
            </div>
          </Form>
        </MainBlock>
        <MainBlock>
          <TableWrapper>
            <Loader loading={this.props.ordersBySetLoader}>
              {ordersBySet.orders.length > 0 ? (
                <Table>
                  <Thead>
                    <Tr>
                      <Th>{__('Názov zoznamu')}</Th>
                      <Th>{__('Počet objednávok')}</Th>
                      <Th>{__('Suma objednávok')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {ordersBySet.orders.map((orders, i) => {
                      return (
                        <React.Fragment key={i}>
                          <Tr
                            onClick={this.toggleAccordionSet.bind(
                              this,
                              i,
                              orders.skvelko_set_ids,
                            )}
                          >
                            <Td>{orders.name}</Td>
                            <Td>{orders.count} </Td>
                            <Td className="caret">
                              {formatPrice(
                                orders.tprice ? orders.tprice : 0,
                                'EUR',
                              )}
                              <Chevron
                                icon="chevron"
                                className={
                                  this.state.activeAccordionSetIndex === i
                                    ? 'active'
                                    : ''
                                }
                              />
                            </Td>
                          </Tr>
                          <Tr className="gray">
                            <TdSmall colSpan={3} className="colspan">
                              <AnimateHeight
                                height={
                                  this.state.activeAccordionSetIndex === i
                                    ? 'auto'
                                    : 0
                                }
                              >
                                <TableSmall>
                                  <Thead>
                                    <TrSmall>
                                      <ThSmall>{__('Objednávateľ')}</ThSmall>
                                      <ThSmall>{__('Žiak')}</ThSmall>
                                      <ThSmall>{__('Dátum')}</ThSmall>
                                      <ThSmall>{__('Trieda')}</ThSmall>
                                      <ThSmall>
                                        {__('Doručiť do školy')}
                                      </ThSmall>
                                    </TrSmall>
                                  </Thead>
                                  <Tbody>
                                    {this.findOrdersForSet(
                                      orders.skvelko_set_ids,
                                    ) ? (
                                      this.getOrdersForSet(
                                        orders.skvelko_set_ids,
                                      ).map((item: any, j) => {
                                        return (
                                          <TrSmall key={j}>
                                            <TdSmall>
                                              {this.getNameFromOrder(item)}
                                            </TdSmall>
                                            <TdSmall>
                                              {item.children_name}
                                            </TdSmall>
                                            <TdSmall>
                                              {formatStringDate(
                                                item.created_datetime,
                                              )}
                                            </TdSmall>
                                            <TdSmall>
                                              {item.delivery_class_name
                                                ? item.delivery_class_name
                                                : ''}
                                            </TdSmall>
                                            <TdSmall>
                                              {item.delivery.delivery_type ===
                                              'DOPRAVA_DO_SKOLY'
                                                ? __('Áno')
                                                : __('Nie')}
                                            </TdSmall>
                                          </TrSmall>
                                        );
                                      })
                                    ) : (
                                      <EmptyTrSmall>
                                        <Loader loading={true} />
                                      </EmptyTrSmall>
                                    )}
                                  </Tbody>
                                </TableSmall>
                              </AnimateHeight>
                            </TdSmall>
                          </Tr>
                        </React.Fragment>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <Alert type="info">
                  {__('Vo zvolenom čase neboli žiadne objednávky.')}
                </Alert>
              )}
            </Loader>
          </TableWrapper>
        </MainBlock>
      </>
    );
  }

  private getNameFromOrder = (order) => {
    if (order.person) {
      return order.person.name + ' ' + order.person.surname;
    }

    return '';
  };

  private toggleAccordionSet = async (index, skvelkoSetId) => {
    const activeAccordionSetIndex =
      this.state.activeAccordionSetIndex === index ? null : index;

    if (
      activeAccordionSetIndex !== null &&
      this.findOrdersForSet(skvelkoSetId) === null
    ) {
      const dateTo = dayjs(this.state.dateTo).add(1, 'day');
      await this.props.dispatch(
        loadOrdersForSet(
          skvelkoSetId,
          this.state.dateFrom,
          dateTo,
          this.state.onlyDeliveryToSchool,
        ),
      );
    }

    this.setState({ activeAccordionSetIndex });
  };
}

const mapStateToProps = (state) => {
  return {
    ordersBySetLoader: state.myAccount.ordersBySetLoader,
    ordersBySet: state.myAccount.ordersBySet,
    ordersForSet: state.myAccount.ordersForSet,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'OrdersParent' })(OrdersParent),
);
