import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.overlayMobile};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  ${({ theme }) => theme.media('md')} {
    background: ${({ theme }) => theme.color.overlaySecondary};
  }
`;

class OverlaySearch extends React.PureComponent {
  public render() {
    return <Wrapper />;
  }
}

export default OverlaySearch;
