import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import { ContactButtonPrimary, IconPlusWhite } from './ContactStyles';
import { __ } from 'react-i18n/lib';

const Wrapper = styled.div`
  margin-bottom: ${rem(40)};
`;

const ContactPersonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: ${rem(-6)};
  position: relative;
  &.showLess::after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 60%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  ${({ theme }) => theme.media(1024)} {
    &.showLess::after {
      background: linear-gradient(
        to top,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
    }
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  flex: 0 1 ${({ theme }) => theme.grid.col.col12};
  margin: 0;
  padding: ${rem(6)};
  ${({ theme }) => theme.media(600)} {
    flex: 0 1 ${({ theme }) => theme.grid.col.col6};
    padding-right: ${rem(3)};
    padding-left: ${rem(3)};
  }
  ${({ theme }) => theme.media(1124)} {
    padding-right: ${rem(6)};
    padding-left: ${rem(6)};
  }
`;

const Item = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  border: ${({ fromAccountDetail, theme }) =>
    fromAccountDetail ? `` : `${rem(2)} solid ${theme.color.gray87}`};
`;

const ThumbWrapper = styled.div`
  flex: 0 0 ${rem(50)};
  margin-right: ${rem(20)};
  ${({ theme }) => theme.media('xs')} {
    flex: 0 0 ${rem(90)};
  }
`;

const ImgStyle = css``;

const Thumb = styled(ImagePlaceholder)`
  ${ImgStyle};
`;

const Img = styled.img`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray87};
  border-radius: 50%;
  max-width: ${({ fromAccountDetail }) =>
    fromAccountDetail ? `${rem(80)}` : `${rem(120)}`};
`;

const Body = styled.div`
  min-width: 0;
`;

const Name = styled.p`
  display: block;
  font-weight: 700;
  line-height: 1.2;
  margin: ${rem(2)} 0;
`;

const Position = styled.p`
  display: block;
  font-size: ${rem(16)};
  font-weight: 600;
  color: ${({ theme }) => theme.color.link};
  margin: 0;
`;

const Email = styled.a`
  display: block;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.color.link};
  margin: 0;
`;

const Phone = styled.a`
  display: block;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.color.text};
  margin: 0;
`;

const RegionsWrapper = styled.p`
  display: block;
  margin: ${rem(16)} 0 0 0;
`;

const RegionsTitle = styled.p`
  display: block;
  font-size: ${rem(14)};
  font-weight: 600;
  color: ${({ theme }) => theme.color.text};
  margin: 0;
`;

const Regions = styled.p`
  display: block;
  font-size: ${rem(14)};
  font-weight: 400;
  color: ${({ theme }) => theme.color.text};
  margin: 0;
`;

const Button = styled(ContactButtonPrimary)`
  margin-top: ${rem(-15)};
  font-size: ${rem(14)};
  position: relative;
  z-index: 2;
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(14)};
  }
  span {
    margin-right: 8px;
  }
`;

interface ContactPersonProps {
  item: {
    name: string;
    position: string;
    phone: string;
    phone2?: string;
    email: string;
    regions?: string;
    picture?: string | null;
  };

  fromAccountDetail?: boolean;
}

class ContactPerson extends React.PureComponent<ContactPersonProps> {
  public render() {
    const { item } = this.props;
    const { fromAccountDetail } = this.props;
    const placeholder = '/images/avatar/avatar.png';
    const placeholder2x = '/images/avatar/avatar@2x.png';
    return (
      <ItemWrapper>
        <Item fromAccountDetail={fromAccountDetail}>
          <ThumbWrapper>
            {item.picture ? (
              <Img src={item.picture} fromAccountDetail={fromAccountDetail} />
            ) : (
              <Thumb
                src={null}
                placeholder={placeholder}
                retinaPlaceholder={placeholder2x}
                w={90}
                h={90}
              />
            )}
          </ThumbWrapper>
          <Body>
            <Name>{item.name}</Name>
            {!fromAccountDetail ? <Position>{item.position}</Position> : null}
            <Email href={`mailto:${item.email}`}>{item.email}</Email>
            {item.phone2 ? (
              <Phone href={`tel:${item.phone2}`}>{item.phone2}</Phone>
            ) : (
              ''
            )}
            <Phone href={`tel:${item.phone}`}>{item.phone}</Phone>
            {item.regions ? (
              <RegionsWrapper>
                <RegionsTitle>{__('Starostlivosť o regióny:')}</RegionsTitle>
                <Regions>{item.regions}</Regions>
              </RegionsWrapper>
            ) : (
              ''
            )}
          </Body>
        </Item>
      </ItemWrapper>
    );
  }
}

interface ContactPersonsProps {
  persons: Array<{
    name: string;
    position: string;
    phone: string;
    phone2?: string;
    email: string;
    regions?: string;
    picture?: string | null;
  }>;
  contentBottom?: any;
}

interface ContactPersonsState {
  showMore: boolean;
}

class ContactPersons extends React.PureComponent<
  ContactPersonsProps,
  ContactPersonsState
> {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
    this.showMore = this.showMore.bind(this);
  }

  public showMore() {
    this.setState({ showMore: true });
  }

  public render() {
    const isMobile = (window && window.innerWidth) <= 600;

    let { persons } = this.props;
    const { contentBottom } = this.props;
    const { showMore } = this.state;
    if (!showMore) {
      persons = persons.slice(0, isMobile ? 1 : 2);
    }

    return (
      <Wrapper>
        <ContactPersonsWrapper className={showMore ? '' : 'showLess'}>
          {persons.map((person, i) => {
            return (
              <ContactPerson item={person} key={i} fromAccountDetail={false} />
            );
          })}
        </ContactPersonsWrapper>
        {!showMore ? (
          <Button onClick={this.showMore} className="primary">
            <IconPlusWhite />
            {__('Zobraziť viac')}
          </Button>
        ) : (
          contentBottom || ''
        )}
      </Wrapper>
    );
  }
}

export { ContactPerson };
export default ContactPersons;
