import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const OverlayStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.overlay};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Wrapper = styled.div`
  ${OverlayStyles};
  padding: ${rem(8)};
  ${({ theme }) => theme.media('sm')} {
    padding: ${rem(20)};
  }
`;

interface OverlayProps {
  children: any;
}

class Overlay extends React.PureComponent<OverlayProps> {
  public render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

export default Overlay;
