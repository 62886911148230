// const namespace = 'Cart';

// export const SET_CART_TOTAL_PRICE = `${namespace}/SET_CART_TOTAL_PRICE`;
// export const SET_CART_COUNT = `${namespace}/SET_CART_COUNT`;
// export const SET_CART_ID = `${namespace}/SET_CART_ID`;
// export const RESET_CART_ID = `${namespace}/RESET_CART_ID`;
// export const CHANGE_PROCESSING_STATUS = `${namespace}/CHANGE_PROCESSING_STATUS`;
// export const SET_STEP = `${namespace}/SET_STEP`;
// export const SHOW_3RD_STEP_PROBLEMS = `${namespace}/SHOW_3RD_STEP_PROBLEMS`;

// export const OPEN_MODAL = `${namespace}/OPEN_MODAL`;
// export const CLOSE_MODAL = `${namespace}/CLOSE_MODAL`;

// export const CART_COOKIE_ID = 'cart_id';

// export const REQUEST_FREE_DELIVERY_INFO = `${namespace}/REQUEST_FREE_DELIVERY_INFO`;
// export const RECEIVE_FREE_DELIVERY_INFO_SUCCESS = `${namespace}/RECEIVE_FREE_DELIVERY_INFO_SUCCESS`;
// export const RECEIVE_FREE_DELIVERY_INFO_FAILURE = `${namespace}/RECEIVE_FREE_DELIVERY_INFO_FAILURE`;
// export const SET_CART_ITEMS = `${namespace}/SET_CART_ITEMS`;

const namespace = 'Cart';

export enum CartProblemTypes {
  WARNING = 'warning',
  ERROR = 'error',
  GIFTCARD = 'giftcard',
  FORM = 'form',
  POINTS = 'points',
  DELIVERY = 'delivery',
  PAYMENT = 'payment',
  DELIVERY_PAYMENT = 'delivery-payment',
  CART = 'cart',
  TERMS = 'terms',
  PRICE = 'price',
  PRODUCT = 'product',
  AVAILABILITY = 'availability',
  SKVELKO = 'skvelko',
}

export const SET_CART_TOTAL_PRICE = `${namespace}/SET_CART_TOTAL_PRICE`;
export const SET_CART_COUNT = `${namespace}/SET_CART_COUNT`;
export const SET_CART_ID = `${namespace}/SET_CART_ID`;
export const RESET_CART_ID = `${namespace}/RESET_CART_ID`;
export const CHANGE_PROCESSING_STATUS = `${namespace}/CHANGE_PROCESSING_STATUS`;
export const SET_STEP = `${namespace}/SET_STEP`;
export const SHOW_3RD_STEP_PROBLEMS = `${namespace}/SHOW_3RD_STEP_PROBLEMS`;

export const OPEN_MODAL = `${namespace}/OPEN_MODAL`;
export const CLOSE_MODAL = `${namespace}/CLOSE_MODAL`;

export const CART_COOKIE_ID = 'cart_id';
export const CART_RESTRICTED_COD_ID = 'dn6r77GYpgnl';

export const REQUEST_FREE_DELIVERY_INFO = `${namespace}/REQUEST_FREE_DELIVERY_INFO`;
export const RECEIVE_FREE_DELIVERY_DATA_SUCCESS = `${namespace}/RECEIVE_FREE_DELIVERY_DATA_SUCCESS`;
export const RECEIVE_FREE_DELIVERY_DATA_ERROR = `${namespace}/RECEIVE_FREE_DELIVERY_DATA_ERROR`;
export const SET_CART_ITEMS = `${namespace}/SET_CART_ITEMS`;

export const REQUEST_DELIVERY_AND_PAYMENT_OPTIONS = `${namespace}/REQUEST_DELIVERY_AND_PAYMENT_OPTIONS`;
export const RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_SUCCESS = `${namespace}/RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_SUCCESS`;
export const RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_ERROR = `${namespace}/RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_ERROR`;

export const REQUEST_CREATE_NEW_CART = `${namespace}/REQUEST_CREATE_NEW_CART`;
export const RECEIVE_CREATE_NEW_CART_SUCCESS = `${namespace}/RECEIVE_CREATE_NEW_CART_SUCCESS`;
export const RECEIVE_CREATE_NEW_CART_ERROR = `${namespace}/RECEIVE_CREATE_NEW_CART_ERROR`;

export const CART_IS_FETCHING = `${namespace}/CART_IS_FETCHING`;

export const REQUEST_CART_DATA = `${namespace}/REQUEST_CART_DATA`;
export const RECEIVE_CART_DATA_SUCCESSFUL = `${namespace}/RECEIVE_CART_DATA_SUCCESSFUL`;
export const RECEIVE_CART_DATA_ERROR = `${namespace}/RECEIVE_CART_DATA_ERROR`;

export const SET_NEW_DELIVERY_OPTION = `${namespace}/SET_NEW_DELIVERY_OPTION`;
export const SET_NEW_PAYMENT_OPTION = `${namespace}/SET_NEW_PAYMENT_OPTION`;

export const REQUEST_UPDATE_CART_DATA = `${namespace}/REQUEST_UPDATE_CART_DATA`;
export const RECEIVE_UPDATE_CART_DATA_SUCCESSFUL = `${namespace}/RECEIVE_UPDATE_CART_DATA_SUCCESSFUL`;
export const RECEIVE_UPDATE_CART_DATA_ERROR = `${namespace}/RECEIVE_UPDATE_CART_DATA_ERROR`;

export const CHECKBOX_CLICK = `${namespace}/CHECKBOX_CLICK`;

export const REQUEST_PRODUCT_IN_CART_COUNT_UPDATE = `${namespace}/REQUEST_PRODUCT_IN_CART_COUNT_UPDATE`;
export const RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_SUCCESS = `${namespace}/RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_SUCCESS`;
export const RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_ERROR = `${namespace}/RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_ERROR`;

export const REQUEST_PRODUCT_IN_CART_DELETE = `${namespace}/REQUEST_PRODUCT_IN_CART_DELETE`;
export const RECEIVE_PRODUCT_IN_CART_DELETE_SUCCESS = `${namespace}/RECEIVE_PRODUCT_IN_CART_DELETE_SUCCESS`;
export const RECEIVE_PRODUCT_IN_CART_DELETE_ERROR = `${namespace}/RECEIVE_PRODUCT_IN_CART_DELETE_ERROR`;

export const REQUEST_ADD_GIFTCARD = `${namespace}/REQUEST_ADD_GIFTCARD`;
export const RECEIVE_ADD_GIFTCARD_SUCCESS = `${namespace}/RECEIVE_ADD_GIFTCARD_SUCCESS`;
export const RECEIVE_ADD_GIFTCARD_ERROR = `${namespace}/RECEIVE_ADD_GIFTCARD_ERROR`;

export const RECOGNIZED_CUSTOMER_CARD = `${namespace}/RECOGNIZED_CUSTOMER_CARD`;
export const SHOW_RECOGNIZED_CUSTOMER_CARD = `${namespace}/SHOW_RECOGNIZED_CUSTOMER_CARD`;

export const CHANGE_CONTACT_FORM_INPUT_VALUE = `${namespace}/CHANGE_CONTACT_FORM_INPUT_VALUE`;
export const CHANGE_COMPANY_FORM_INPUT_VALUE = `${namespace}/CHANGE_COMPANY_FORM_INPUT_VALUE`;
export const CHANGE_BILLING_FORM_INPUT_VALUE = `${namespace}/CHANGE_BILLING_FORM_INPUT_VALUE`;
export const CHANGE_CHECKBOX_VALUE = `${namespace}/CHANGE_CHECKBOX_VALUE`;
export const CHANGE_CART_DATA_VALUE = `${namespace}/CHANGE_CART_DATA_VALUE`;

export const REQUEST_CREATE_ORDER = `${namespace}/REQUEST_CREATE_ORDER`;
export const RECEIVE_CREATE_ORDER_SUCCESS = `${namespace}/RECEIVE_CREATE_ORDER_SUCCESS`;
export const RECEIVE_CREATE_ORDER_ERROR = `${namespace}/RECEIVE_CREATE_ORDER_ERROR`;

export const REQUEST_GIFTS = `${namespace}/REQUEST_GIFTS`;
export const RECEIVE_GIFTS_SUCCESS = `${namespace}/RECEIVE_GIFTS_SUCCESS`;
export const RECEIVE_GIFTS_ERROR = `${namespace}/RECEIVE_GIFTS_ERROR`;

export const CHANGE_GIFT = `${namespace}/CHANGE_GIFT`;
export const ESHOP_GIFTS = `${namespace}/ESHOP_GIFTS`;

export const REQUEST_DELETE_GIFTCARD = `${namespace}/REQUEST_DELETE_GIFTCARD`;
export const RECEIVE_DELETE_GIFTCARD_SUCCESS = `${namespace}/RECEIVE_DELETE_GIFTCARD_SUCCESS`;
export const RECEIVE_DELETE_GIFTCARD_ERROR = `${namespace}/RECEIVE_DELETE_GIFTCARD_ERROR`;

export const SET_CART = `${namespace}/SET_CART`;

export const SET_CUSTOMER_DETAILS_LOADER = `${namespace}/SET_CUSTOMER_DETAILS_LOADER`;
export const SET_CUSTOMER_DETAILS = `${namespace}/SET_CUSTOMER_DETAILS`;

export const SET_DPD_DELIVERY_POINTS = `${namespace}/SET_DPD_DELIVERY_POINTS`;
export const SET_DPD_DELIVERY_POINT = `${namespace}/SET_DPD_DELIVERY_POINT`;

export const SET_PACKETA_DELIVERY_POINT = `${namespace}/SET_PACKETA_DELIVERY_POINT`;

export const SET_SHOPS_PERSONAL_PICKUP = `${namespace}/SET_SHOPS_PERSONAL_PICKUP`;
export const SET_SHOP_PERSONAL_PICKUP = `${namespace}/SET_SHOP_PERSONAL_PICKUP`;

export const SET_LAST_VALUES = `${namespace}/SET_LAST_VALUES`;
export const SET_CART_INFO_TEXT = `${namespace}/SET_CART_INFO_TEXT`;

export const SET_CART1_UPSELL = `${namespace}/SET_CART1_UPSELL`;
