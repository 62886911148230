import { __ } from 'react-i18n';
import { debouncePromise, isEmailValid, isStringNumber } from './index';
import API from '../services/API';
import { Link } from 'react-router';
import * as React from 'react';

export const composeValidators = (...validators) => (...args) =>
  validators.reduce(
    (error, validator) => error || validator(...args),
    undefined,
  );

export const required = (value) => (value ? undefined : __('Povinné'));
export const requiredSelect = (value) => {
  return value && value != -1 ? undefined : __('Povinné');
};

export const email = (value) =>
  value && !isEmailValid(value) ? 'Email nie je v správnom tvare' : undefined;

const isEmailExistDebounce = debouncePromise(
  async (value) =>
    (await API.isEmailExist({ email: value })) && (
      <>
        {' '}
        {__('Užívateľ s týmto emailom už existuje')}
        {'. '}
        {__('Pre obnovu hesla môžete navštíviť')}{' '}
        <Link to="/zabudnute-heslo" target="_blank">
          {__('tento odkaz')}
        </Link>
        {'.'}
      </>
    ),
  2000,
);

const isLoginExistDebounce = debouncePromise(
  async (value) =>
    (await API.isLoginExist({ login: value })) && (
      <>
        {' '}
        {__('Organizácia s týmto IČO už existuje')}
        {'. '}
        {__('Pre obnovu hesla môžete navštíviť')}{' '}
        <Link to="/zabudnute-heslo" target="_blank">
          {__('tento odkaz')}
        </Link>
        {'.'}
      </>
    ),
  2000,
);

export const emailExist = async (value) => isEmailExistDebounce(value);

export const loginExist = async (value) => isLoginExistDebounce(value);

export const requiredIfIcoPresent = (value, allValues) =>
  !value && allValues.ico && allValues.ico !== '' ? __('Povinné') : undefined;

export const password = (value) =>
  value.length < 6 ? __('Heslo musí obsahovať minimálne 6 znakov') : undefined;

export const passwordMatch = (value, allValues) =>
  value !== allValues?.password ? __('Heslá sa nezhodujú') : undefined;

export const isPhone = (value) => !value || !!value.match(/^[+]?[\d\s\/\\]*$/g);

export const phone = (value) =>
  isPhone(value) ? undefined : __('Nevalídne číslo');

const isPhoneMobileDebounce = debouncePromise(
  async (phoneNumber) =>
    (await API.isPhoneMobile({ phone: phoneNumber }))
      ? undefined
      : __('Zadajte prosím mobilné číslo.'),
  2000,
);
export const phoneMobile = (value) =>
  value && value !== '' && isPhoneMobileDebounce(value);

export const ico = (value) =>
  isStringNumber(value) && value.length >= 8
    ? undefined
    : __(
        'IČO musí obsahovať minimálne 8 čísiel bez medzier, pomlčiek a lomitiek',
      );

export const dic = (value) =>
  isStringNumber(value)
    ? undefined
    : __('DIČ musí obsahovať iba čísla bez medzier, pomlčiek a lomitiek');

export const street = (value) =>
  value && value !== '' && !!value.match(/\d$/)
    ? __('Uveďte názov ulice bez popisneho čísla')
    : undefined;

export const postalCode = (value) => {
  if (value && value !== '') {
    const newValue = value.replace(' ', '');
    newValue && newValue !== '' && !newValue.match(/^\d{5}$/)
      ? __('PSČ musí obsahovať 5 číslic')
      : undefined;
  }
};
