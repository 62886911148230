import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  ${({ theme }) => theme.media('md')} {
    border-radius: ${rem(4)};
    margin: ${rem(15)} 0 0;
  }
`;

const WrapperInner = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => rem(theme.grid.padding / 2)};
  ${({ theme }) => theme.media('md')} {
    padding: ${rem(30)};
  }
`;

interface HeaderContainerIO {
  children: any;
  className?: string;
}

export class HeaderContainer extends React.PureComponent<HeaderContainerIO> {
  public render() {
    const { children } = this.props;
    return <Wrapper>{children}</Wrapper>;
  }
}

export class HeaderInnerContainer extends React.PureComponent<
  HeaderContainerIO
> {
  public render() {
    const { children, className } = this.props;
    return <WrapperInner className={className}>{children}</WrapperInner>;
  }
}
