import * as React from 'react';
import styled, { css } from 'styled-components';
import { __ } from 'react-i18n';
import { InputStyled } from '../../components/_helpers/form/Input';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { Form, Field, FormSpy } from 'react-final-form';
import { rem } from 'polished';
import API from '../../services/API';
import Alert from '../../components/Alert/Alert';
import {
  InputStyles,
  InputStyledStyles,
  ErrorPopup,
} from '../../components/_helpers/form/FormStyles';
import { Link } from 'react-router';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import {
  composeValidators,
  dic,
  email,
  ico,
  phone,
  required,
} from '../../utilities/validators';
import { scrollToError, scrollToTop } from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';

const Wrapper = styled.div`
  width: 100%;
`;
const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 10px;
  flex-direction: column;

  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
  }
`;

const CheckboxWrapper = styled.div``;

const CheckboxRow = styled.div``;

const SubmitButtonWrapper = styled.div`
  margin-top: 15px;
`;

const FormCol = styled.div`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
`;

const InputRow = styled.div`
  margin-bottom: 15px;
`;

const ColumnTitle = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
`;

const FormLabel = styled.div``;

const FormInputCss = css`
  width: 100%;
  ${(props: any) => {
    return props.error ? 'border:2px solid red;' : '';
  }}
`;

const FormInput = styled(InputStyled)`
  ${FormInputCss};
`;

const FormInputEmail = styled(EmailAutocomplete)`
  ${FormInputCss};
`;

const FormField = styled.form``;

const FormTitle = styled.div`
  font-weight: bold;
  font-size: ${rem(23)};
  margin-bottom: 15px;
`;

const FormTextarea: any = styled.textarea`
  ${InputStyles};
  ${InputStyledStyles};
  display: block;
  width: 100%;
  height: auto;
  ${(props: any) => {
    return props.error ? 'border:2px solid red;' : '';
  }}
`;

export const BenefitCardForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(0);

  const onSubmit = async (values) => {
    const copyValues = JSON.parse(JSON.stringify(values));

    copyValues.gdpr_card_accepted = values.gdpr_card_accepted ? 'Áno' : 'Nie';
    copyValues.gdpr_benefits_emails_accepted = values.gdpr_benefits_emails_accepted
      ? 'Áno'
      : 'Nie';
    copyValues.gdpr_profil_accepted = values.gdpr_profil_accepted
      ? 'Áno'
      : 'Nie';
    copyValues.gdpr_accepted = values.gdpr_accepted ? 'Áno' : 'Nie';

    setLoading(true);
    try {
      await API.benefitCardForm(
        {
          xAcceptLanguage: 'sk',
        },
        copyValues,
      );
      setStatus(1);
    } catch (e) {
      setStatus(-1);
    }
    setLoading(false);
    scrollToTop();
  };

  return (
    <Wrapper>
      <Loader loading={loading}>
        <FormTitle>Žiadosť o vydanie karty výhod</FormTitle>

        {status === 1 && (
          <Alert type="success">
            {' '}
            {__('Žiadosť o kartu výhod bola poslaná úspešne')}{' '}
          </Alert>
        )}

        {status === -1 && (
          <Alert type="error">
            {' '}
            {__('Žiadosť o kartu výhod sa nepodarilo odoslať')}{' '}
          </Alert>
        )}

        <Form
          onSubmit={onSubmit}
          // tslint:disable-next-line:jsx-no-lambda
          render={({ handleSubmit }) => (
            <FormField onSubmit={handleSubmit}>
              <FormWrapper>
                <FormCol>
                  <ColumnTitle>{__('Firemné údaje')}</ColumnTitle>
                  <Field name="company_name" validate={required}>
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> Názov firmy </FormLabel>
                        <FormInput
                          {...input}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>
                  <Field
                    name="business_id"
                    validate={composeValidators(required, ico)}
                  >
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> IČO </FormLabel>
                        <FormInput
                          {...input}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>

                  <Field
                    name="tax_id"
                    validate={composeValidators(required, dic)}
                  >
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> DIČ </FormLabel>
                        <FormInput
                          {...input}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>

                  <Field name="vat_registration_number" validate={required}>
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> IČ-DPH </FormLabel>
                        <FormInput
                          {...input}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>
                </FormCol>

                <FormCol>
                  <ColumnTitle>{__('Kontaktné údaje')}</ColumnTitle>

                  <Field name="contact_person" validate={required}>
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> Kontaktná osoba </FormLabel>
                        <FormInput
                          {...input}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>

                  <Field
                    name="tel"
                    validate={composeValidators(required, phone)}
                  >
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> Telefón </FormLabel>
                        <FormInput
                          {...input}
                          type="tel"
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>

                  <Field
                    name="email"
                    validate={composeValidators(required, email)}
                  >
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> Email </FormLabel>
                        <FormInputEmail
                          {...input}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>

                  <Field name="note">
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> Poznámka </FormLabel>
                        <FormTextarea
                          {...input}
                          rows={6}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>
                </FormCol>
              </FormWrapper>

              <CheckboxWrapper>
                <Field
                  name="gdpr_card_accepted"
                  type="checkbox"
                  validate={required}
                >
                  {({ input, meta }) => (
                    <CheckboxRow
                      className={meta.error && meta.touched ? 'error' : ''}
                    >
                      <CheckboxStyled
                        {...input}
                        value="1"
                        id="gdpr_card_accepted"
                      >
                        {__(
                          'Súhlasím so spracovaním osobných údajov za účelom vystavenia firemnej karty výhod Daffer',
                        )}
                      </CheckboxStyled>
                      {meta.error && meta.touched && (
                        <ErrorPopup className="error">{meta.error}</ErrorPopup>
                      )}
                    </CheckboxRow>
                  )}
                </Field>

                <Field name="gdpr_benefits_emails_accepted" type="checkbox">
                  {({ input, meta }) => (
                    <CheckboxRow
                      className={meta.error && meta.touched ? 'error' : ''}
                    >
                      <CheckboxStyled
                        {...input}
                        value="1"
                        id="gdpr_benefits_emails_accepted"
                      >
                        {__(
                          'Súhlasím so spracovaním osobných údajov za účelom zasielania výhodných ponúk na moju emailovú adresu.',
                        )}
                      </CheckboxStyled>
                      {meta.error && meta.touched && (
                        <ErrorPopup className="error">{meta.error}</ErrorPopup>
                      )}
                    </CheckboxRow>
                  )}
                </Field>

                <Field name="gdpr_profil_accepted" type="checkbox">
                  {({ input, meta }) => (
                    <CheckboxRow
                      className={meta.error && meta.touched ? 'error' : ''}
                    >
                      <CheckboxStyled
                        {...input}
                        value="1"
                        id="gdpr_profil_accepted"
                      >
                        {__(
                          'Súhlasím so spracovaním osobných údajov za účelom automatizovaného rozhodovania vrátane profilovania.',
                        )}
                      </CheckboxStyled>
                      {meta.error && meta.touched && (
                        <ErrorPopup className="error">{meta.error}</ErrorPopup>
                      )}
                    </CheckboxRow>
                  )}
                </Field>

                <Field name="gdpr_accepted" type="checkbox" validate={required}>
                  {({ input, meta }) => (
                    <CheckboxRow
                      className={meta.error && meta.touched ? 'error' : ''}
                    >
                      <CheckboxStyled {...input} value="1" id="gdpr_accepted">
                        {__('Potvrdzujem, že som bol oboznámený s')}{' '}
                        <Link to="/gdpr" target="_blank">
                          podmienkami ochrany osobných údajov
                        </Link>{' '}
                        {__('podľa GDPR')}
                      </CheckboxStyled>
                      {meta.error && meta.touched && (
                        <ErrorPopup className="error">{meta.error}</ErrorPopup>
                      )}
                    </CheckboxRow>
                  )}
                </Field>
              </CheckboxWrapper>

              <SubmitButtonWrapper>
                <ButtonStyled className="primary lg" type="submit">
                  {__('Odoslať')}
                </ButtonStyled>
              </SubmitButtonWrapper>

              <FormSpy
                subscription={{ submitFailed: true, modified: false }}
                onChange={() => {
                  scrollToError();
                }}
              />
            </FormField>
          )}
        />
      </Loader>
    </Wrapper>
  );
};
