import * as React from 'react';
import { IndexRoute, Route, Router } from 'react-router';
// containers
import Home from './containers/Home/Home';
import App from './containers/App/App';

import UrlMapper from './containers/UrlMapper/UrlMapper';
import Cart from './containers/Cart/Cart';
import Login from './containers/Login/Login';
import Ucet from './containers/Ucet/Ucet';
import MojUcet from './containers/Ucet/MojUcet';
import SetsParent from './containers/Ucet/SetsParent';
import NewSet from './containers/Ucet/NewSet';
import ProductsForSets from './containers/Ucet/ProductsForSets';
import ClassroomList from './containers/Ucet/ClassroomList';
import RemunerationSystem from './containers/Ucet/RemunerationSystem';
import NewSkvelkoAwardedUser from './containers/Ucet/NewSkvelkoAwardedUser';
import OrderHistory from './containers/Ucet/OrderHistory';
import FavoriteProducts from './containers/Ucet/FavoriteProducts';
import Sets from './containers/Sets/Sets';
import OrderDetail from './containers/Ucet/OrderDetail';
import Settings from './containers/Ucet/Settings';
import FinishOrder from './containers/Cart/FinishOrder';
import Logout from './containers/Logout/Logout';
import ForgottenPassword from './containers/Login/ForgottenPassword';
import ForgottenPasswordCompany from './containers/Login/ForgottenPasswordCompany';
import RenewPassword from './containers/Login/RenewPassword';
import RemunerationSystemAwarded from './containers/Ucet/RemunerationSystemAwarded';
import Activation from './containers/Activation/Activation';
import FinishedCompany from './containers/Registration/FinishedCompany';
import './theme/globalStyles';
//import './theme/customStyles';
import Credit from './containers/Ucet/Credit';
import Cart1 from './components/Cart/Cart1';
import Cart2 from './components/Cart/Cart2';
import Cart3 from './components/Cart/Cart3';
import SetProducts from './containers/Ucet/SetProducts';
import Sitemap from './containers/Sitemap/Sitemap';
import NotIncludedSchoolsFrom from './containers/NotIncludedSchoolsFrom/NotIncludedSchoolsFrom';
import { scrollToTop } from './utilities';
import ProductsCustomer from './containers/Ucet/ProductsCustomer';
import RequestProductPrice from './containers/Ucet/RequestProductPrice';
import {
  URL_ACCOUNT_BULK_ORDER,
  URL_ACCOUNT_PRODUCTS_FOR_SETS,
  URL_ACCOUNT_SETS,
  URL_SCHOOL_ARTICLES,
  URL_CREDIT,
  URL_LOGIN,
  URL_REGISTRATION_COMPANY,
  URL_REGISTRATION_COMPLETE_COMPANY,
  URL_REGISTRATION_COMPLETE_RETAIL,
  URL_REGISTRATION_COMPLETE_SCHOOL,
  URL_REGISTRATION_RETAIL,
  URL_REGISTRATION_SCHOOL,
  URL_REQUEST_PRODUCT_PRICE,
  URL_SEARCH,
  URL_SET,
  URL_SET_EDIT_PRODUCTS,
  URL_SET_NEW,
  URL_SET_NEW_CHOOSE,
  URL_SETS,
  URL_SKVELKOBOX_BENEFITS,
  URL_SKVELKOBOX_INSTRUCTIONS,
} from './constants';
import RegistrationParent from './containers/Registration/RegistrationParent';
import RegistrationSchool from './components/Registration/RegistrationSchool';
import RegistrationCompany from './components/Registration/RegistrationCompany';
import RegistrationRetail from './components/Registration/RegistrationRetail';
import FinishedRetail from './containers/Registration/FinishedRetail';
import FinishedSchool from './containers/Registration/FinishedSchool';
import SetsManagement from './containers/Ucet/SetsManagement';
import ErrorCaller from './containers/Errors/ErrorCaller';
import SetBulkOrder from './containers/Ucet/SetBulkOrder';
import SkvelkoBoxParent from './containers/Ucet/SkvelkoBoxParent';
import SkvelkoBoxBenefits from './containers/Ucet/SkvelkoBoxBenefits';
import SkvelkoBoxInstructions from './containers/Ucet/SkvelkoBoxInstructions';
import Search from './containers/Search/Search';
import NewSetChoose from './containers/Ucet/NewSetChoose';
import MostFrequentlyPurchasedProducts from './containers/Ucet/MostFrequentlyPurchasedProducts';
import SchoolArticles from './containers/SchoolArticle/SchoolArticles';
import ClearReduxCache from './containers/System/ClearReduxCache';
import Survey from './containers/Survey/Survey';
import Unsubscribe from './containers/Unsubscribe/Unsubscribe';

const thisScrollToTop = () => {
  if (!window.location.pathname.startsWith(URL_SET)) {
    scrollToTop();
  }
};

const Routes = (props: any) => {
  return (
    <Router onUpdate={thisScrollToTop} history={props.history}>
      <Route path="/" component={App}>
        <Route component={Ucet}>
          <Route path="/my-account" component={MojUcet} />
          <Route component={SetsParent}>
            <Route path={URL_ACCOUNT_SETS} component={SetsManagement} />
            <Route
              path={URL_ACCOUNT_PRODUCTS_FOR_SETS}
              component={ProductsForSets}
            />
            <Route path={URL_ACCOUNT_BULK_ORDER} component={SetBulkOrder} />
          </Route>
          <Route path={URL_SET_NEW} component={NewSet} />
          <Route path="/my-account/set/edit/:id" component={NewSet} />

          <Route path="/my-account/classrooms" component={ClassroomList} />

          <Route
            path="/my-account/renumeration"
            component={RemunerationSystem}
          />

          <Route
            path="/my-account/renumeration/awarded"
            component={RemunerationSystemAwarded}
          />

          <Route
            path="/my-account/renumeration/new-user"
            component={NewSkvelkoAwardedUser}
          />

          <Route path={URL_CREDIT} component={Credit} />

          <Route path="/my-account/my-orders" component={OrderHistory} />
          <Route
            path="/my-account/favorite-products"
            component={FavoriteProducts}
          />

          <Route
            path="/my-account/most-frequently-purchased-products"
            component={MostFrequentlyPurchasedProducts}
          />

          <Route
            path="/my-account/order/:orderPublicId"
            component={OrderDetail}
          />

          <Route path="/my-account/settings" component={Settings} />

          <Route component={SkvelkoBoxParent}>
            <Route
              path={URL_SKVELKOBOX_BENEFITS}
              component={SkvelkoBoxBenefits}
            />
            <Route
              path={URL_SKVELKOBOX_INSTRUCTIONS}
              component={SkvelkoBoxInstructions}
            />
          </Route>

          <Route
            path="/my-account/products-customer"
            component={ProductsCustomer}
          />
        </Route>

        <Route path="zabudnute-heslo" component={ForgottenPassword} />
        <Route
          path="zabudnute-heslo-firma"
          component={ForgottenPasswordCompany}
        />

        <Route path={URL_SET_NEW_CHOOSE} component={NewSetChoose} />

        <Route component={RegistrationParent}>
          <Route
            path={URL_REGISTRATION_RETAIL}
            component={RegistrationRetail}
          />
          <Route
            path={URL_REGISTRATION_COMPANY}
            component={RegistrationCompany}
          />
          <Route
            path={URL_REGISTRATION_SCHOOL}
            component={RegistrationSchool}
          />
        </Route>

        <Route
          path={URL_REGISTRATION_COMPLETE_RETAIL}
          component={FinishedRetail}
        />
        <Route
          path={URL_REGISTRATION_COMPLETE_COMPANY}
          component={FinishedCompany}
        />
        <Route
          path={URL_REGISTRATION_COMPLETE_SCHOOL}
          component={FinishedSchool}
        />

        <Route
          path={URL_REQUEST_PRODUCT_PRICE}
          component={RequestProductPrice}
        />

        <Route path={URL_SET_EDIT_PRODUCTS} component={SetProducts} />

        <Route component={Cart}>
          <Route path={'/kosik'} component={Cart1} />
          <Route path={'/doprava-a-platba'} component={Cart2} />
          <Route path={'/udaje-pre-dorucenie'} component={Cart3} />
        </Route>

        <Route
          path={'/objednavka-dokoncena/:orderPublicId'}
          component={FinishOrder}
        />

        <Route path={URL_LOGIN} component={Login} />
        <Route path="/logout" component={Logout} />

        <Route path="nove-heslo/:token" component={RenewPassword} />
        <Route path="activation/:code" component={Activation} />

        <Route path={URL_SETS} component={Sets} />
        <Route path={`${URL_SET}/:id`} component={Sets} />

        <Route path="mapa-stranok" component={Sitemap} />

        <Route
          path="formular-pre-nezaradene-skoly"
          component={NotIncludedSchoolsFrom}
        />

        <Route path={URL_SEARCH} component={Search} />
        <Route path={URL_SCHOOL_ARTICLES} component={SchoolArticles} />

        <Route path="survey/:orderPublicId" component={Survey} />

        <Route path="/NGdwmCpqsgmkAZ4t" component={ErrorCaller} />
        <Route path="/clear-redux-cache" component={ClearReduxCache} />

        <Route path="/unsubscribe/:userHash" component={Unsubscribe} />

        <Route path="**" component={UrlMapper} />
        <IndexRoute component={Home} />
      </Route>
    </Router>
  );
};

export default Routes;
