import styled from 'styled-components';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import React from 'react';
import {
  ButtonStyledNewStyles,
  ButtonStyledStyles,
  ButtonStyles,
} from '../../_helpers/form/Button';
import { Link } from 'react-router';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${rem(30)} 0;
`;

const Heading = styled.h3`
  font-size: ${rem(24)};
  font-weight: 800;
  margin-top: 0;
`;

const Button = styled(Link)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${ButtonStyledNewStyles};
`;

interface EshopOfferProps {
  link: string;
}

const EshopOffer = (props: EshopOfferProps) => {
  const { link } = props;
  return (
    <Wrapper>
      <Heading>{__('Internetový obchod')}</Heading>
      <Button to={link} className={'primary'}>
        {__('Ponuka eshopu')}
      </Button>
    </Wrapper>
  );
};

export default EshopOffer;
