import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { Heading3 } from '../Heading/Heading';
import { InputStyled } from '../form/Input';
import SubmitFormModal from './SubmitFormModal';

const Title = styled(Heading3)`
  margin-top: 0;
  font-size: ${rem(16)};
`;

const StyledModal = styled(SubmitFormModal)``;

const StyledInput = styled(InputStyled)`
  display: block;
  width: 100%;
`;

interface ModalProps {
  title?: string;
  type: 'create' | 'edit';
  className?: string;
  onCloseButtonClick?: (event) => void;
  onSubmitButtonClick: (className: string) => void;
}

interface ModalState {
  className: string;
}

class SkvelkoClassModal extends React.PureComponent<ModalProps, ModalState> {
  constructor(props) {
    super(props);
    this.state = {
      className: props.className ? props.className : '',
    };
  }

  public onClassNameChange = event => {
    this.setState({
      className: event.target.value,
    });
  };

  public onSubmitButtonClick = event => {
    this.props.onSubmitButtonClick(this.state.className);
  };

  public render() {
    const { title, type } = this.props;

    const body = (
      <div>
        <Title> {__('Názov triedy')} </Title>
        <StyledInput
          value={this.state.className}
          onChange={this.onClassNameChange}
          autoFocus={true}
        />
      </div>
    );

    return (
      <StyledModal
        title={title}
        body={body}
        submitButtonText={
          type === 'create' ? __('Vytvoriť triedu') : __('Editovať triedu')
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
        onSubmitButtonClick={this.onSubmitButtonClick}
      />
    );
  }
}

export default SkvelkoClassModal;
