import * as React from 'react';
import styled from 'styled-components';
import {
  ContactButtonSecondary,
  ContactButtonSecondaryWrapper,
  ContactButtonWrapper,
  ContactRef,
  ContactRefSpecial,
  IconPlusRed,
  IconRightRed,
} from './ContactStyles';
import ContactBlock, { TextWrapper } from './ContactBlock';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import { scrollToClass } from '../../../utilities';
import { Link } from 'react-router';

const ContactInfoRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${rem(-6)};
  margin-left: ${rem(-6)};
  margin-bottom: ${rem(10)};
  ${({ theme }) => theme.media(600)} {
    margin-bottom: ${rem(20)};
  }
`;

const ContactInfoCol = styled.div`
  flex: 0 0 ${({ theme }) => theme.grid.col.col12};
  padding-right: ${rem(6)};
  padding-left: ${rem(6)};
  margin: ${rem(-1)};
  ${({ theme }) => theme.media(600)} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
    margin: 0;
    padding-right: ${rem(3)};
    padding-left: ${rem(3)};
  }
  ${({ theme }) => theme.media(1124)} {
    padding-right: ${rem(6)};
    padding-left: ${rem(6)};
  }
`;

const InsideColWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media(600)} {
    flex-direction: row;
  }
`;

const InsideCol = styled.div`
  flex: 0 0 50%;
  padding-bottom: ${rem(20)};
`;

const ContactInfoDoubleCol = styled(ContactInfoCol)`
  ${({ theme }) => theme.media(600)} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col8};
  }
`;

interface Props {
  dispatch: any;
  openingHours: string;
}

interface State {
  showBillingAddress: boolean;
}

class ContactInfo extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showBillingAddress: false,
    };
    this.setShowBillingAddress = this.setShowBillingAddress.bind(this);
  }

  public setShowBillingAddress() {
    this.setState({ showBillingAddress: true });
  }

  public render() {
    return (
      <ContactInfoRow>
        {/*
        <ContactInfoCol>
          <ContactBlock title={__('Infolinka')}>
            <p>
              <ContactRefSpecial href="mailto:daffer@daffer.sk">
                daffer@daffer.sk
              </ContactRefSpecial>
              <br />
              <ContactRefSpecial href="tel:+421 46 519 87 11">
                +421 46 519 87 11
              </ContactRefSpecial>
              <br />
              <strong>Pondelok - Piatok</strong>
              <br />
              7:00 - 15:30
              <br />
              <ContactButtonSecondaryWrapper>
                <ContactButtonSecondary
                  className={'icon'}
                  onClick={scrollToClass.bind(null, '.contactForm')}
                >
                  <IconRightRed />
                  {__('Kontaktný formulár')}
                </ContactButtonSecondary>
              </ContactButtonSecondaryWrapper>
            </p>
          </ContactBlock>
        </ContactInfoCol>

        <ContactInfoCol>
          <ContactBlock title={__('Sídlo spoločnosti')}>
            <TextWrapper>
              Daffer spol. s r.o.
              <br />
              Včelárska 1
              <br />
              P.O.Box 184
              <br />
              971 01 Prievidza
              <br />
              Slovenská republika
              {this.state.showBillingAddress ? (
                <p>
                  <br />
                  IČO: 36 320 439
                  <br />
                  IČ DPH: SK2021592518
                  <br />
                  DIČ: 2021592518
                </p>
              ) : (
                <ContactButtonSecondaryWrapper>
                  <ContactButtonSecondary
                    className={'icon'}
                    onClick={this.setShowBillingAddress.bind(null, true)}
                  >
                    <IconPlusRed />
                    {__('Fakturačné údaje')}
                  </ContactButtonSecondary>
                </ContactButtonSecondaryWrapper>
              )}
            </TextWrapper>
          </ContactBlock>
        </ContactInfoCol>
        */}

        <ContactInfoDoubleCol>
          <ContactBlock title={__('Sídlo spoločnosti a centrálny sklad')}>
            <InsideColWrapper>
              <InsideCol>
                <p>
                  <ContactRefSpecial href="mailto:daffer@daffer.sk">
                    daffer@daffer.sk
                  </ContactRefSpecial>
                  <br />
                  <ContactRefSpecial href="tel:+421 46 519 87 11">
                    +421 46 519 87 11
                  </ContactRefSpecial>
                  <br />
                  {this.props.openingHours ? (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.openingHours,
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </p>
                <ContactButtonWrapper>
                  <ContactButtonSecondary
                    className={'icon'}
                    onClick={scrollToClass.bind(null, '.contactForm')}
                  >
                    <IconRightRed />
                    {__('Kontaktný formulár')}
                  </ContactButtonSecondary>
                </ContactButtonWrapper>
              </InsideCol>
              <InsideCol>
                <TextWrapper>
                  Daffer spol. s r.o.
                  <br />
                  Včelárska 1
                  <br />
                  P.O.Box 184
                  <br />
                  971 01 Prievidza
                  <br />
                  Slovenská republika
                  {this.state.showBillingAddress ? (
                    <p>
                      <br />
                      IČO: 36 320 439
                      <br />
                      IČ DPH: SK2021592518
                      <br />
                      DIČ: 2021592518
                    </p>
                  ) : (
                    <ContactButtonSecondaryWrapper>
                      <ContactButtonSecondary
                        className={'icon'}
                        onClick={this.setShowBillingAddress.bind(null, true)}
                      >
                        <IconPlusRed />
                        {__('Fakturačné údaje')}
                      </ContactButtonSecondary>
                    </ContactButtonSecondaryWrapper>
                  )}
                </TextWrapper>
              </InsideCol>
            </InsideColWrapper>
            {/*
              <InsideColWrapper>
                <InsideCol>
                    <ContactButtonSecondaryWrapper>
                      <ContactButtonSecondary
                        className={'icon'}
                        onClick={scrollToClass.bind(null, '.contactForm')}
                      >
                        <IconRightRed />
                        {__('Kontaktný formulár')}
                      </ContactButtonSecondary>
                    </ContactButtonSecondaryWrapper>
                </InsideCol>
                <InsideCol>
                     {this.state.showBillingAddress ? (
                      <p>
                        <br />
                        IČO: 36 320 439
                        <br />
                        IČ DPH: SK2021592518
                        <br />
                        DIČ: 2021592518
                      </p>
                    ) : (
                      <ContactButtonSecondaryWrapper>
                        <ContactButtonSecondary
                          className={'icon'}
                          onClick={this.setShowBillingAddress.bind(null, true)}
                        >
                          <IconPlusRed />
                          {__('Fakturačné údaje')}
                        </ContactButtonSecondary>
                      </ContactButtonSecondaryWrapper>
                    )}
                </InsideCol>
              </InsideColWrapper>
               */}
          </ContactBlock>
        </ContactInfoDoubleCol>

        <ContactInfoCol>
          <ContactBlock title={__('Internetový obchod')}>
            <p>
              <ContactRef href="mailto:eshop@daffer.sk">
                eshop@daffer.sk
              </ContactRef>
              <br />
              <ContactRef href="tel:+421 46 519 87 16">
                +421 / 46 / 519 87 16
              </ContactRef>
              <ContactButtonSecondaryWrapper>
                <Link
                  to={'/obchodne-podmienky'}
                  aria-label={__('Obchodné podmienky')}
                >
                  <ContactButtonSecondary className={'icon'}>
                    <IconRightRed />
                    {__('Obchodné podmienky')}
                  </ContactButtonSecondary>
                </Link>
                <Link
                  to="/reklamacny-formular"
                  aria-label={__('Reklamačný formulár')}
                >
                  <ContactButtonSecondary className={'icon'}>
                    <IconRightRed />
                    {__('Reklamačný formulár')}
                  </ContactButtonSecondary>
                </Link>
              </ContactButtonSecondaryWrapper>
            </p>
          </ContactBlock>
        </ContactInfoCol>
      </ContactInfoRow>
    );
  }
}

export default ContactInfo;
