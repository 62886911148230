import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { ButtonCogIcon, MyAccountBtn, MyAccountBtnIcon } from './MojUcet';
import { rem } from 'polished';
import {
  ListItemUnstyled,
  ListUnstyled,
} from '../../components/_helpers/typography/ListStyles';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { MainBlock } from './Ucet';
import {
  loadClasses,
  removeAndReloadClass,
  createAndReloadClass,
  updateAndReloadClass,
} from './actions';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import ChooseModal from '../../components/_helpers/Modal/ChooseModal';
import Alert from '../../components/Alert/Alert';
import SkvelkoClassModal from '../../components/_helpers/Modal/SkvelkoClassModal';
import { Loader } from '../../components/_helpers/Loader/Loader';

/*
const Descr = styled.p`
  font-size: ${rem(14)};
  line-height: 1.5;
`;
*/

const Classes = styled.div`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  font-size: ${rem(14)};
  line-height: 1;
  margin: ${rem(20)} 0;
  ${({ theme }) => theme.media('sm')} {
    margin-bottom: ${rem(40)};
  }
`;

const ClassHeader = styled.header`
  font-weight: 700;
  padding: ${rem(21)};
  margin-bottom: ${rem(-2)};
`;

const ClassList = styled(ListUnstyled)`
  margin: 0;
`;

const ClassItem = styled(ListItemUnstyled)`
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  padding: ${rem(7)} ${rem(7)} ${rem(7)} ${rem(21)};
`;

const Class = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ClassTitle = styled.div``;

const Buttons = styled.div``;

const AlertWrapper = styled.div`
  margin-bottom: ${rem(15)};
`;

interface ClassroomListProps {
  classListLoader: boolean;
  classSearch: ThenArg<typeof API.searchSkvelkoClass>;
  setTitle: (title: string, link?: string | null) => void;
  dispatch: (a: any) => void;
}

interface ClassroomListState {
  removeModalOpen: boolean;
  removeModalText: string;
  removeSkvelkoClassId: number | null;

  classModalOpen: boolean;
  classModalType: 'create' | 'edit';
  classModalTitle: string;
  classModalClassId: number | null;
  classModalClassName: string;
}

class ClassroomList extends React.PureComponent<
  ClassroomListProps,
  ClassroomListState
> {
  public static async getInitialProps(props) {
    await props.dispatch(loadClasses());
  }

  constructor(props) {
    super(props);
    this.state = {
      removeModalOpen: false,
      removeModalText: __('Naozaj chcete túto triedu odstrániť'),
      removeSkvelkoClassId: null,

      classModalOpen: false,
      classModalType: 'create',
      classModalTitle: '',
      classModalClassId: null,
      classModalClassName: '',
    };
    props.setTitle('Zoznam tried');
  }

  public closeModal = () => {
    this.setState({
      removeModalOpen: false,
      classModalOpen: false,

      classModalClassId: null,
      classModalClassName: '',
    });
  };

  public onRemoveClassClick = (skvelkoClassId, className) => {
    this.setState({
      removeModalOpen: true,
      removeModalText:
        __('Naozaj chcete zoznam') + ' ' + className + ' ' + __('odstrániť'),
      removeSkvelkoClassId: skvelkoClassId,
    });
  };

  public onModalRemoveClassClick = () => {
    this.props.dispatch(removeAndReloadClass(this.state.removeSkvelkoClassId));
    this.setState({
      removeModalOpen: false,
    });
  };

  public onNewClassClick = () => {
    this.setState({
      classModalOpen: true,
      classModalType: 'create',
      classModalTitle: __('Nová trieda'),
      classModalClassId: null,
    });
  };

  public onEditClassClick = (skvelkoClassId, className) => {
    this.setState({
      classModalOpen: true,
      classModalType: 'edit',
      classModalTitle: __('Editácia triedy'),
      classModalClassId: skvelkoClassId,
      classModalClassName: className,
    });
  };

  public onSubmitButtonClick = (className) => {
    if (this.state.classModalType === 'create') {
      this.props.dispatch(createAndReloadClass(className));
      this.closeModal();
    } else {
      this.props.dispatch(
        updateAndReloadClass(this.state.classModalClassId, className),
      );
      this.closeModal();
    }
  };

  public render() {
    const { classSearch, classListLoader } = this.props;
    const classes = classSearch ? classSearch.search : [];

    return (
      <MainBlock className={'no-top-padding'}>
        {this.state.removeModalOpen && (
          <ChooseModal
            title={__('Potvrdenie')}
            bodyText={this.state.removeModalText}
            button1Text={__('NIE')}
            button2Text={__('ÁNO')}
            onButton1Click={this.closeModal}
            onButton2Click={this.onModalRemoveClassClick}
            onCloseButtonClick={this.closeModal}
          />
        )}

        {this.state.classModalOpen && (
          <SkvelkoClassModal
            type={this.state.classModalType}
            title={this.state.classModalTitle}
            className={this.state.classModalClassName}
            onCloseButtonClick={this.closeModal}
            onSubmitButtonClick={this.onSubmitButtonClick}
          />
        )}
        {/* 
        <Descr>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum
          explicabo, harum ipsa laborum, laudantium maxime nemo nulla officia
          quis recusandae repudiandae rerum unde velit! Fuga illo ipsum iste
          repudiandae sit.
        </Descr>
        */}
        {!classSearch || classes.length > 0 ? (
          <Classes>
            <ClassHeader>{__('Trieda')}</ClassHeader>
            <ClassList>
              <Loader loading={classListLoader}>
                {classes.map((item, i) => {
                  return (
                    <ClassItem key={i}>
                      <Class>
                        <ClassTitle>{item.name}</ClassTitle>
                        <Buttons>
                          <MyAccountBtn>
                            <MyAccountBtnIcon
                              icon="trash"
                              onClick={this.onRemoveClassClick.bind(
                                this,
                                item.id,
                                item.name,
                              )}
                            />
                          </MyAccountBtn>
                          <MyAccountBtn>
                            <ButtonCogIcon
                              icon="cog"
                              onClick={this.onEditClassClick.bind(
                                this,
                                item.id,
                                item.name,
                              )}
                            />
                          </MyAccountBtn>
                        </Buttons>
                      </Class>
                    </ClassItem>
                  );
                })}
              </Loader>
            </ClassList>
          </Classes>
        ) : (
          <AlertWrapper>
            <Alert>{__('Triedu pridáte kliknutím na "Pridať triedu"')}</Alert>
          </AlertWrapper>
        )}
        <ButtonStyled className="primary lg" onClick={this.onNewClassClick}>
          {__('Pridať triedu')}
        </ButtonStyled>
      </MainBlock>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    classSearch: state.myAccount.classSearch,
    classListLoader: state.myAccount.classListLoader,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ClassroomList' })(ClassroomList),
);
