import * as React from 'react';
import {
  ButtonDefault,
  ButtonStyledStyles,
} from '../../components/_helpers/form/Button';
import { __ } from 'react-i18n/lib';
import { Container } from '../../theme/libraries/grid';
import { Content } from '../../components/_layout/Page/ContentContainer';
import styled from 'styled-components';
import { rem } from 'polished';

export const ButtonStyled = styled(ButtonDefault)`
  ${ButtonStyledStyles};
  margin: ${rem(16)};
`;

interface ErrorCallerState {
  error: boolean;
}

export default class ErrorCaller extends React.Component<{}, ErrorCallerState> {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
    };
  }

  public OnErrorCall = () => {
    this.setState(() => ({ error: true }));
  };

  public render() {
    if (this.state.error) {
      throw new Error('Manuálne vyvolanie vynimky 6');
    } else {
      return (
        <Container className="base">
          <Content>
            <ButtonStyled className="primary lg" onClick={this.OnErrorCall}>
              {__('Vyvolať chybu')}
            </ButtonStyled>
          </Content>
        </Container>
      );
    }
  }
}
