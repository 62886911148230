import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import { Link } from 'react-router';
import GiftBadge from '../Icon/GiftBadge';

const Wrapper = styled.div`
  position: relative;
  padding: ${rem(16)} ${rem(28)};
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};

  ${({ theme }) => theme.media('md')} {
    margin-left: ${rem(5)};
    margin-right: ${rem(-5)};
    flex: 0 0 24%;
  }
`;

const Heading = styled.h4`
  margin: 0;
  max-width: ${rem(140)};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 800;
  ${({ theme }) => theme.media(1096)} {
    max-width: ${rem(154)};
  }
`;

const SpanStyled = styled.span`
  font-size: ${rem(16)};
`;

const LinkStyled = styled(Link)`
  font-size: ${rem(14)};
  font-weight: 600;
  text-decoration: underline;
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  padding: ${rem(18)} 0 0 0;
  &:hover {
    cursor: pointer;
  }
`;

const GiftBadgeWrapper = styled.div`
  position: absolute;
  top: ${rem(16)};
  right: ${rem(30)};
`;

interface GiftsBannerProps {
  bannerUrl: string;
  onLinkClick: () => void;
}

const GiftsBanner = (props: GiftsBannerProps) => {
  const { onLinkClick, bannerUrl } = props;

  return (
    <Wrapper>
      <Heading>
        {__('Nakúp nad 40€ a získaš vzdelávacie materiály za 40€ ako DARČEK!')}
      </Heading>
      <SpanStyled>{__('Rozdávame darčeky na podporu vzdelávania.')}</SpanStyled>
      <LinkStyled onClick={onLinkClick}>{__('Viac o darčekoch')}</LinkStyled>
      <Image src={bannerUrl} width={222} height={152} onClick={onLinkClick} />
      <GiftBadgeWrapper>
        <GiftBadge />
      </GiftBadgeWrapper>
    </Wrapper>
  );
};

export default GiftsBanner;
