import React from 'react';
import API, { ThenArg } from '../../../services/API';
import {
  loadFavoriteProductsForWidget,
  removeFromUsersFavouriteProducts,
} from '../../../containers/Ucet/actions';
import { __ } from 'react-i18n/lib';
import ProductPreviews from './OrderItemPreview';
import { prop } from '../../../utilities';
import { Loader } from '../../_helpers/Loader/Loader';

interface FavoriteProductsWidgetProps {
  dispatch: any;
  user: any;
  cart: ThenArg<typeof API.getCart>;
  favoriteProductsWidget: ThenArg<typeof API.searchProducts> | undefined;
  favoriteProductsWidgetLoader: boolean;
}

const FavoriteProductsWidget = (props: FavoriteProductsWidgetProps) => {
  const loadMore = () => {
    props.dispatch(loadFavoriteProductsForWidget());
  };

  // Remove product from favorite's list
  const handleRemoveFromFavoritesClick = async (
    productId: number,
    userId: number,
  ) => {
    props.dispatch(removeFromUsersFavouriteProducts(productId, userId));
  };

  const {
    favoriteProductsWidget,
    favoriteProductsWidgetLoader,
    user,
    cart,
    dispatch,
  } = props;

  const items = prop(favoriteProductsWidget, 'products', []);
  const total = prop(favoriteProductsWidget, 'total', 0);

  return (
    <>
      {items && items.length ? (
        <>
          <Loader loading={favoriteProductsWidgetLoader || false}>
            <ProductPreviews
              items={items}
              total={total}
              user={user}
              cart={cart}
              loadMore={loadMore}
              dispatch={dispatch}
              fromFavoritesProducts={true}
              removeProduct={handleRemoveFromFavoritesClick}
            />
          </Loader>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default FavoriteProductsWidget;
