import React from 'react';
import {
  loadProductsCustomer,
  removeFromUsersFavouriteProducts,
} from '../../../containers/Ucet/actions';
import { Loader } from '../../_helpers/Loader/Loader';
import ProductPreviews from './OrderItemPreview';
import { __ } from 'react-i18n/lib';
import { prop } from '../../../utilities';
import API, { ThenArg } from '../../../services/API';

interface ProductsAgreedPricesProps {
  dispatch: any;
  user: any;
  cart: ThenArg<typeof API.getCart>;
  productsCustomer: any;
  productsCustomerLoader: boolean;
}

const ProductsCustomer = (props: ProductsAgreedPricesProps) => {
  const loadMore = () => {
    props.dispatch(loadProductsCustomer());
  };

  const { productsCustomer, productsCustomerLoader, user, cart } = props;

  const items = prop(productsCustomer, 'products', []);
  const total = prop(productsCustomer, 'total', 0);

  return (
    <>
      {items && items.length ? (
        <>
          <Loader loading={productsCustomerLoader || false}>
            <ProductPreviews
              items={items}
              total={total}
              user={user}
              cart={cart}
              loadMore={loadMore}
              dispatch={props.dispatch}
              removeProduct={removeFromUsersFavouriteProducts}
              fromFavoritesProducts={false}
            />
          </Loader>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default ProductsCustomer;
