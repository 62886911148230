import React from 'react';
import API from '../../../services/API';
import { InputStyled } from './Input';
import styled from 'styled-components';
import { rem } from 'polished';

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled(InputStyled)`
  display: block;
  width: 100%;
  border-color: ${(props: any) => (props.error ? 'red' : 'none')};
`;

const AutocompleteWrapper = styled.div`
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: ${rem(4)};
  box-shadow: 0 0 0 ${rem(1)} hsla(0, 0%, 0%, 0.1),
    0 ${rem(4)} ${rem(11)} hsla(0, 0%, 0%, 0.1);
  margin: ${rem(8)} 0;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
`;

const Autocomplete = styled.ul`
  max-height: ${rem(300)};
  overflow-y: auto;
  padding: ${rem(4)} 0;
  position: relative;
  box-sizing: border-box;
  margin: 0;
`;

const AutocompleteItem = styled.li`
  max-height: ${rem(300)};
  overflow-y: auto;
  padding: ${rem(4)} ${rem(16)};
  position: relative;
  box-sizing: border-box;
  text-align: start;
  &.active {
    background-color: #deebff;
  }
`;

interface Props {
  name: string;
  type?: string;
  value?: string;
  className?: string;
  onChange?: (event) => void;
  error?: boolean;
  placeholder?: string;
}

interface State {
  activeOption: number;
  filteredOptions: [];
  showOptions: boolean;
  value: string;
}

export class EmailAutocomplete extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      value: props.value || '',
    };
  }

  public callParentOnChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  // source: https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04
  public forceOnUpdate(value: string) {
    const input = document.querySelector('#email-autocomplete');
    const nativeInput = Object.getOwnPropertyDescriptor(
      (window as any).HTMLInputElement.prototype,
      'value',
    );
    const nativeInputValueSetter =
      (nativeInput && nativeInput.set) || undefined;
    if (nativeInputValueSetter) {
      nativeInputValueSetter.call(input, value);
    }
    const event = new Event('input', { bubbles: true });
    if (input) {
      input.dispatchEvent(event);
    }
  }

  public onChange = async (e) => {
    this.callParentOnChange(e);
    const value = e.currentTarget.value;

    if (!value || !value.includes('@') || /.+@.+\..+/.test(value)) {
      this.setState(() => ({
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        value,
      }));
      return;
    }

    const result: any = await API.emailAddressAutocomplete({ email: value });
    this.setState(() => ({
      activeOption: 0,
      filteredOptions: (result && result.emailAddressesRecommended) || [],
      showOptions: true,
      value,
    }));
  };

  public onClick = (e) => {
    const value = e.currentTarget.innerText;
    this.setState(() => ({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      value,
    }));
    this.forceOnUpdate(value);
  };

  public onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;

    if (e.keyCode === 13) {
      e.preventDefault();
      const value = filteredOptions[activeOption];
      this.setState(() => ({
        activeOption: 0,
        filteredOptions: [],
        showOptions: false,
        value,
      }));
      this.forceOnUpdate(value);
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState(() => ({
        activeOption: activeOption - 1,
      }));
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        return;
      }
      this.setState(() => ({
        activeOption: activeOption + 1,
      }));
    }
  };

  public render() {
    const {
      onChange,
      onClick,
      onKeyDown,

      props: { name, type, className, error, placeholder },

      state: { activeOption, filteredOptions, showOptions, value },
    } = this;

    let optionList;
    if (showOptions && value && filteredOptions && filteredOptions.length) {
      optionList = (
        <AutocompleteWrapper>
          <Autocomplete>
            {filteredOptions.map((optionName, index) => (
              <AutocompleteItem
                className={index === activeOption ? 'active' : ''}
                key={optionName}
                onClick={onClick}
              >
                {optionName}
              </AutocompleteItem>
            ))}
          </Autocomplete>
        </AutocompleteWrapper>
      );
    }

    return (
      <React.Fragment>
        <InputWrapper>
          <Input
            id={'email-autocomplete'}
            type={type || 'email'}
            name={name}
            className={className}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value}
            error={error}
            placeholder={placeholder}
          />
          {optionList}
        </InputWrapper>
      </React.Fragment>
    );
  }
}

export default EmailAutocomplete;
