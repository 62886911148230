import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { routerReducer } from 'react-router-redux';
import { cartReducer } from './containers/Cart/reducer';
import { generalReducer } from './containers/App/reducer';
import { productReducer } from './containers/Product/reducer';
import { homeReducer } from './containers/Home/reducer';
import { myAccountReducer } from './containers/Ucet/reducer';
import { helpAndSupportReducer } from './containers/HelpAndSupport/reducer';
import { shopDetailReducer } from './containers/ShopDetail/reducer';
import { loginReducer } from './containers/Login/reducer';
import { authReducer } from './containers/Auth/reducer';
import { registrationReducer } from './containers/Registration/reducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  auth: authReducer,
  cart: cartReducer,
  general: generalReducer,
  product: productReducer,
  form: formReducer,
  home: homeReducer,
  myAccount: myAccountReducer,
  helpAndSupport: helpAndSupportReducer,
  shopDetail: shopDetailReducer,
  login: loginReducer,
  registration: registrationReducer,
});

export default rootReducer;

export type StateType<ReducerOrMap> = ReducerOrMap extends (
  ...args: any[]
) => any
  ? ReturnType<ReducerOrMap>
  : ReducerOrMap extends object
  ? { [K in keyof ReducerOrMap]: StateType<ReducerOrMap[K]> }
  : never;

export type State = StateType<typeof rootReducer>;
