import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import GalleryModal from '../Modal/GalleryModal';

const Thumb = styled.div`
  padding: ${rem(5)};
`;

const ImageWrapperStyles = css`
  background: ${({ theme }) => theme.color.white};
`;

const ThumbWrapper = styled.div`
  display: block;
  ${ImageWrapperStyles};
  cursor: pointer;
  position: relative;
`;

const ThumbImage = styled(ImagePlaceholder)`
  border-radius: ${rem(4)};
  border: 1px solid ${({ theme }) => theme.color.gray97};
  padding: 0;
  width: 100%;
  display: block;
  object-fit: cover;
  height: 153px;
`;

const Thumbs = styled.div`
  margin: ${rem(5)} ${rem(-5)} ${rem(-5)};
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) => theme.media('md')} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #88888888;
  &:hover {
    opacity: 1;
  }
`;

const FaPlus = styled.b`
  color: white;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
`;

interface GalleryPropsIO {
  images: Array<string>;
}

interface GalleryStates {
  modalVisible: boolean;
  activeImageIndex: number;
}

class Gallery extends React.Component<GalleryPropsIO, GalleryStates> {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      activeImageIndex: 0,
    };
  }

  public closeModal = () => {
    this.setState({ modalVisible: false });
  };

  public showModal = (activeImageIndex) => {
    this.setState({
      modalVisible: true,
      activeImageIndex,
    });
  };

  public render() {
    const { images } = this.props;

    return (
      <>
        <Thumbs>
          {images &&
            images.map((photo, i) => {
              return (
                <Thumb key={i}>
                  <ThumbWrapper onClick={this.showModal.bind(this, i)}>
                    <ThumbImage
                      src={photo}
                      placeholder={photo}
                      retinaPlaceholder={photo}
                      notResize={false}
                      width={191}
                      height={153}
                      w={300}
                      h={153}
                      ignoreRequestHeight={true}
                    />
                    <Overlay>
                      <FaPlus>+</FaPlus>
                    </Overlay>
                  </ThumbWrapper>
                </Thumb>
              );
            })}
        </Thumbs>
        {this.state.modalVisible && (
          <GalleryModal
            pictures={images}
            onCloseButtonClick={this.closeModal}
            activeIndex={this.state.activeImageIndex}
          />
        )}
      </>
    );
  }
}

export default Gallery;
