import * as React from 'react';
import {
  TITLE_SKVELKOBOX,
  TITLE_SKVELKOBOX_INSTRUCTIONS,
  URL_SKVELKOBOX_BENEFITS,
} from '../../constants';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { loadCmsContent } from '../App/actions';
import { prop } from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';
import API, { ThenArg } from '../../services/API';

const sitemapUid = 'SKVELKOBOX_INSTRU';

interface SkvelkoBoxInstructionsProps {
  cmsContent?: any;
  cmsContentLoading?: boolean;
  userDetail?: ThenArg<typeof API.getCustomerDetails>;
}

class SkvelkoBoxInstructions extends React.PureComponent<SkvelkoBoxInstructionsProps> {
  public static async getInitialProps(props) {
    props.dispatch(loadCmsContent(sitemapUid));
  }

  constructor(props) {
    super(props);

    props.setBreadcrumbsItems([
      { name: TITLE_SKVELKOBOX, url: URL_SKVELKOBOX_BENEFITS },
      { name: TITLE_SKVELKOBOX_INSTRUCTIONS, url: props.currentPath },
    ]);
  }

  public render() {
    const { cmsContentLoading, cmsContent, userDetail } = this.props;
    const body = prop(
      cmsContent,
      `${sitemapUid}.content.json_content.body`,
      '',
    );
    return (
      <Loader loading={!!cmsContentLoading}>
        <div
          dangerouslySetInnerHTML={{
            __html: body?.replace(
              new RegExp('{{ SCHOOL_ID }}', 'g'),
              userDetail?.school_id || '',
            ),
          }}
        />
      </Loader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cmsContent: state.general.cmsContent,
    cmsContentLoading: state.general.cmsContentLoading,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SkvelkoBoxInstructions' })(SkvelkoBoxInstructions),
);
