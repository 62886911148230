import * as React from 'react';
import ContentContainer from '../../components/_layout/Page/ContentContainer';
import { __ } from 'react-i18n';
import { ContentContainerBlock } from '../../components/_helpers/Contact/ContactStyles';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
// import {
//   InputStyles,
//   InputStyledStyles,
// } from '../../components/_helpers/form/FormStyles';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import Alert from '../../components/Alert/Alert';
import { InputStyled } from '../../components/_helpers/form/Input';
import { Label } from '../../components/_helpers/form/Label';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import { isEmailValid, scrollToClass, scrollToTop } from '../../utilities';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';

// const Textarea: any = styled.textarea`
//   ${InputStyles};
//   ${InputStyledStyles};
//   display: block;
//   width: 100%;
//   height: auto;
//   ${(props: any) => {
//     return props.error ? 'border:1px solid red;' : '';
//   }}
// `;

const InputCss = css`
  display: block;
  width: 100%;

  &.small {
    ${({ theme }) => theme.media('xs')} {
      max-width: ${rem(290)};
    }
  }

  ${(props: any) => {
    return props.error ? 'border:1px solid red;' : '';
  }}
`;

const Input = styled(InputStyled)`
  ${InputCss};
`;

const InputEmailWrapper = styled.div`
  ${({ theme }) => theme.media('xs')} {
    width: ${rem(290)};
  }
`;

const InputEmail = styled(EmailAutocomplete)`
  ${InputCss};
`;

const Checkbox = styled(CheckboxStyled)`
  margin: ${rem(10)} 0 0;
  &.error {
    :before {
      border-color: ${({ theme }) => theme.color.error};
    }
  }
`;

const Row = styled(Clearfix)`
  margin-right: ${rem(-5)};
  margin-bottom: ${rem(10)};
`;

const Submit = styled(ButtonStyled)`
  display: block;
  margin-top: ${rem(20)};
`;

const Wrapper = styled.form`
  width: ${rem(700)};
  max-width: 100%;
`;

const Text = styled.p`
  margin-bottom: ${rem(40)};
`;

interface Props {
  dispatch: any;
}

interface States {
  loading: boolean;
  status: number;
  valueTown: string;
  valueEmail: string;
  valueSchool: string;
  valueUsage: string;
  valueClass: string;
  errorTown: string;
  errorEmail: string;
  errorSchool: string;
  errorUsage: string;
  errorClass: string;
  formSuccessfullySent: boolean;
  formSendError: boolean;
  agreeWithTerms: boolean | null;
}

class NotIncludedSchoolsFrom extends React.Component<Props, States> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: 0,
      valueTown: '',
      valueEmail: '',
      valueSchool: '',
      valueUsage: '',
      valueClass: '',
      errorTown: '',
      errorEmail: '',
      errorSchool: '',
      errorUsage: '',
      errorClass: '',
      formSuccessfullySent: false,
      formSendError: false,
      agreeWithTerms: null,
    };
  }

  public validateForm = (e) => {
    e.preventDefault();
    let error = false;
    if (this.state.formSuccessfullySent) {
      this.setState({ formSuccessfullySent: false });
    }
    if (this.state.valueTown === '') {
      this.setState({ errorTown: __('Zadajte mesto/obec školy') });
      this.scrollToRef('town');
      error = true;
    }
    if (this.state.valueSchool === '') {
      this.setState({ errorSchool: __('Zadajte názov školy') });
      if (!error) {
        this.scrollToRef('school');
      }
      error = true;
    }
    if (this.state.valueEmail === '') {
      this.setState({ errorEmail: __('Zadajte váš email') });
      if (!error) {
        this.scrollToRef('email');
      }
      error = true;
    } else {
      if (!isEmailValid(this.state.valueEmail)) {
        this.setState({ errorEmail: __('Prosím, skontrolujte email') });
        if (!error) {
          this.scrollToRef('email');
        }
        error = true;
      }
    }
    if (!this.state.agreeWithTerms) {
      this.setState({ agreeWithTerms: false });
      if (!error) {
        this.scrollToRef('gdpr');
      }
      error = true;
    }
    if (error) {
      return;
    }

    this.props.dispatch(this.submitForm());
  };

  public submitForm = () => {
    return async (dispatch, getState, API) => {
      this.setState({ loading: true });
      try {
        const currentState = getState();
        await API.notIncludedSchoolsFrom(
          {
            xAcceptLanguage: currentState.general.lang,
          },
          {
            town: this.state.valueTown,
            school: this.state.valueSchool,
            usage: this.state.valueUsage,
            class: this.state.valueClass,
            email: this.state.valueEmail,
          },
        );
        this.setState({
          formSuccessfullySent: true,
          valueTown: '',
          valueSchool: '',
          valueUsage: '',
          valueClass: '',
          valueEmail: '',
        });
        scrollToTop();
      } catch (e) {
        this.setState({ formSendError: true });
      }
      this.setState({ loading: false });
    };
  };

  public changeTown = (e) => {
    if (this.state.errorTown !== '') {
      this.setState({ errorTown: '' });
    }
    this.setState({
      valueTown: e.target.value,
    });
  };

  public changeSchool = (e) => {
    if (this.state.errorSchool !== '') {
      this.setState({ errorSchool: '' });
    }
    this.setState({
      valueSchool: e.target.value,
    });
  };

  public changeUsage = (e) => {
    if (this.state.errorUsage !== '') {
      this.setState({ errorUsage: '' });
    }
    this.setState({
      valueUsage: e.target.value,
    });
  };

  public changeClass = (e) => {
    if (this.state.errorClass !== '') {
      this.setState({ errorClass: '' });
    }
    this.setState({
      valueClass: e.target.value,
    });
  };

  public changeEmail = (e) => {
    if (this.state.errorEmail !== '') {
      this.setState({ errorEmail: '' });
    }
    this.setState({
      valueEmail: e.target.value,
    });
  };

  public scrollToRef = (ref) => scrollToClass('.' + ref);

  public offset = (el) => {
    const rect = el.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  };

  public setAgreeWithTerms = (e) => {
    this.setState({ agreeWithTerms: e.target.checked });
  };

  public render() {
    return (
      <ContentContainer title={__('Formulár pre nezaradené školy')}>
        <ContentContainerBlock>
          {this.state.formSuccessfullySent && (
            <Alert type="success"> {__('Odoslanie prebehlo úspešne')} </Alert>
          )}

          {this.state.formSendError && (
            <Alert type="error">
              {' '}
              {__(
                'Nepodarilo sa odoslať formulár. Skúste to, prosím, neskôr',
              )}{' '}
            </Alert>
          )}

          <Text>
            {__(
              'Vypíš prosím nasledujúci formulár a my sa pokúsime tvoju školu zaradiť do nášho zoznamu škôl, aby si si čo najskôr mohol pohodlne objednať zoznam školských potrieb, ktorý odporúča tvoj triedny učiteľ. V prípade ak chceš do zoznamu zaradiť viac škôl, vyplnenie dotazníku zopakuj. Ďakujeme za tvoj návrh.',
            )}
          </Text>

          <Wrapper onSubmit={this.validateForm}>
            <Loader loading={this.state.loading}>
              <Label className="town">
                {__('V akom meste/obci navštevujú vaše deti školu?*')}
              </Label>
              <Input
                name="town"
                value={this.state.valueTown}
                onChange={this.changeTown}
              />
              {this.state.errorTown !== '' ? (
                <ErrorPopup>{this.state.errorTown}</ErrorPopup>
              ) : (
                ''
              )}

              <Label className="school">
                {__(
                  'Zadajte názov školy ktorú odporúčate zaradiť do projektu Skvelko*',
                )}
              </Label>
              <Input
                name="school"
                value={this.state.valueSchool}
                onChange={this.changeSchool}
              />
              {this.state.errorSchool !== '' ? (
                <ErrorPopup>{this.state.errorSchool}</ErrorPopup>
              ) : (
                ''
              )}

              <Label>
                {__(
                  'Využívali ste už službu balíčka školských potrieb vo vašej škole?',
                )}
              </Label>
              <Input
                name="usage"
                value={this.state.valueUsage}
                onChange={this.changeUsage}
              />
              {this.state.errorUsage !== '' ? (
                <ErrorPopup>{this.state.errorUsage}</ErrorPopup>
              ) : (
                ''
              )}

              <Label>{__('Ktorý ročník bude navštevovať Vaše dieťa?')}</Label>
              <Input
                name="concern"
                value={this.state.valueClass}
                onChange={this.changeClass}
              />
              {this.state.errorClass !== '' ? (
                <ErrorPopup>{this.state.errorClass}</ErrorPopup>
              ) : (
                ''
              )}

              <InputEmailWrapper>
                <Label className="email">{__('Váš email*')}</Label>
                <InputEmail
                  name="email"
                  value={this.state.valueEmail}
                  onChange={this.changeEmail}
                  className="small"
                />
                {this.state.errorEmail !== '' ? (
                  <ErrorPopup>{this.state.errorEmail}</ErrorPopup>
                ) : (
                  ''
                )}
              </InputEmailWrapper>

              <Checkbox
                id="gdpr"
                name="gdpr"
                value="1"
                onChange={this.setAgreeWithTerms}
                className={
                  this.state.agreeWithTerms === null ||
                  this.state.agreeWithTerms
                    ? 'gdpr'
                    : 'gdpr error'
                }
              >
                {`${__('Súhlasím so')} `}
                <a href="/gdpr" target="_blank">
                  {__('spracovaním osobných údajov')}
                </a>
              </Checkbox>
              {this.state.agreeWithTerms === null ||
              this.state.agreeWithTerms ? (
                ''
              ) : (
                <ErrorPopup>
                  {__(
                    'Formulár nie je možné odoslať bez súhlasu o spracovaní osobných údajov.',
                  )}
                </ErrorPopup>
              )}

              <Row>
                <Submit type="submit" className="primary">
                  {__('Odoslať')}
                </Submit>
              </Row>
            </Loader>
          </Wrapper>
        </ContentContainerBlock>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'NotIncludedSchoolsFrom' })(NotIncludedSchoolsFrom),
);
