import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import { Link } from 'react-router';
import { MAX_FOUNDED_QUERIES, SearchResultProps } from './constants';
import {
  ButtonDefault,
  ButtonStyledStyles,
  ButtonStyles,
} from '../../../_helpers/form/Button';
import { ClearfixStyles } from '../../../../theme/libraries/h5b-helpers';
import {
  capitalizeFirstLetter,
  getSchoolNameFromSkvelkoSet,
  getUrl,
} from '../../../../utilities';
import ImagePlaceholder from '../../../_helpers/Image/ImagePlaceholder';
import HighLightedText from './HighLightedText';
import { Loader } from '../../../_helpers/Loader/Loader';
import ProductPrice from './ProductPrice';
import FoundedQueries from './FoundedQueries';
import SkvelkoSetPrice from './SkvelkoSetPrice';
import {
  concatSearchProductsAndSkvelkoSetsAndSoldOutProducts,
  SearchProductType,
} from '../../../../utilities/product';
import WebContentList from './WebContentList';

const Wrapper = styled.div`
  margin: ${rem(16)} ${rem(-4)} 0;
`;

const Tabs = styled.ul`
  list-style: none;
  margin: ${rem(10)} ${rem(-1)} 0;
  padding: 0;
  ${ClearfixStyles};
`;

const TabItem = styled.li`
  float: left;
  width: 33.333333333%;
  padding: 0 ${rem(1)};
`;

const Tab = styled(ButtonDefault)`
  ${ButtonStyledStyles};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 700;
  padding: ${rem(7)} ${rem(5)} ${rem(3)};
  width: 100%;
  ${({ active }) => {
    if (!active) {
      return css`
        margin-top: ${rem(6)};
      `;
    } else {
      return css`
        padding-top: ${rem(13)};
      `;
    }
  }};
  &.primary {
    background: ${({ theme }) => theme.color.primary};
    background-size: unset;
  }
`;

const SearchResultsCount = styled.div`
  font-size: ${rem(14)};
  font-weight: normal;
`;

const TabContent = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: ${rem(8)};
  margin-top: ${rem(2)};
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(101vh - 200px);
`;

const Title = styled.p`
  font-weight: 700;
  margin: 0 0 ${rem(6)};
`;

const CategoryItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  font-size: ${rem(15)};
  line-height: 1;
  & + & {
    margin-top: ${rem(6)};
  }
`;

const CategoryLink = styled(Link)`
  color: inherit;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const CategorySeparator = styled.p`
  margin: 0 ${rem(2)};
`;

const More = styled.button`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  display: block;
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray87};
  font-size: ${rem(14)};
  padding: ${rem(6)} ${rem(12)};
  margin: 0 auto ${rem(9)} auto;
`;

const List = styled.ul`
  padding: 0;
  margin: 0 0 ${rem(10)} 0;
  list-style: none;
`;

const ProductItem = styled.li`
  border-top: ${rem(1)} solid ${({ theme }) => theme.color.gray87};
  padding: 0 ${rem(9)};
  font-size: ${rem(16)};
  line-height: 1;
  &:last-child {
    border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.gray87};
  }
`;

const ProductLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  &:hover {
    text-decoration: none;
  }
`;

const Img = styled(ImagePlaceholder)`
  min-width: ${(props) => rem(props.w)};
  min-height: ${(props) => rem(props.h)};
  width: ${(props) => rem(props.w)};
  height: ${(props) => rem(props.h)};
  vertical-align: middle;
`;

const ProductImg = styled(Img)``;

const Name = styled.p`
  padding: 0 0 0 ${rem(12)};
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const ProductName = styled(Name)``;

const PriceWrapper = styled.div`
  margin: 0 0 0 auto;
`;

const SoldOutText = styled.div`
  color: red;
  font-weight: bold;
`;

const NotFoundText = styled.p`
  margin: 0 0 ${rem(10)} 0;
`;

const ProductNameWrapper = styled.div``;

const Space = styled.div`
  height: ${rem(100)};
`;

const MobileSearchResult = (props: SearchResultProps) => {
  const MAX_CATEGORIES = 3;
  const MAX_PRODUCTS = 5;
  const MAX_WEB_CONTENT = 5;

  const placeholder = '/images/placeholder-46.png';
  const placeholder2x = '/images/placeholder-46@2x.png';

  const [activeTab, setActiveTab] = useState(0);

  const {
    searchDropdownVisible,
    onRedirect,
    searchedQuery,
    onSubmitSearchInput,
    searchedResultsLoader,
    user,
    cart,
    searchedResults: {
      categories,
      demandCategories,
      webContent,
      products,
      demandProducts,
      products: { founded_queries },
    },
    settings,
  } = props;

  const productCount = products?.total ?? 0;
  const demandProductsCount = demandProducts?.total ?? 0;
  const categoryCount = categories?.total ?? 0;
  const demandCategoryCount = demandCategories?.total ?? 0;
  const webContentCount = webContent?.total ?? 0;

  const productsFoundedQueries =
    founded_queries?.length && founded_queries?.slice(0, MAX_FOUNDED_QUERIES);
  const demanProductsFoundedQueries =
    demandProducts?.founded_queries?.length &&
    demandProducts?.founded_queries?.slice(0, MAX_FOUNDED_QUERIES);

  const renderTabs = (tabs) => (
    <Tabs>
      {tabs.map(({ name, count }, index) => (
        <TabItem key={index}>
          <Tab
            className={activeTab === index ? 'primary' : ''}
            active={activeTab === index}
            onClick={setActiveTab.bind(undefined, index)}
          >
            {name}
            <SearchResultsCount>({count})</SearchResultsCount>
          </Tab>
        </TabItem>
      ))}
    </Tabs>
  );

  const renderContent = (tab) => {
    switch (tab) {
      case 0:
        return renderShop();
      case 1:
        return renderPages();
      case 2:
        return renderDemandShop();
      default:
        return renderShop();
    }
  };

  const renderDemandShop = () => (
    <TabContent>
      {renderDemandCategories()}
      {renderDemandProducts()}
    </TabContent>
  );

  const renderShop = () => (
    <TabContent>
      {renderEshopCategories()}
      {renderEshopProducts()}
    </TabContent>
  );

  const renderPages = () => <TabContent>{renderWebContents()}</TabContent>;

  const renderDemandCategories = () => {
    return renderCategories(demandCategories, demandCategoryCount);
  };

  const renderEshopCategories = () => {
    return renderCategories(categories, categoryCount);
  };

  const renderCategories = (categories, categoryCount) => (
    <>
      <Title>{__('Kategórie v obchode')}</Title>
      {!searchedResultsLoader && categories?.catalog_category?.length ? (
        <List>
          {categories.catalog_category
            .slice(0, MAX_CATEGORIES)
            .map((category, i) => (
              <CategoryItem key={i}>
                <CategoryLink
                  to={getUrl(category.url)}
                  onClick={onRedirect.bind(undefined, category.url)}
                >
                  {category.parent_categories?.map((parentCategory, index) => (
                    <React.Fragment key={index}>
                      <HighLightedText
                        text={capitalizeFirstLetter(
                          parentCategory.category_name,
                        )}
                        highlight={searchedQuery}
                      />
                      <CategorySeparator>/</CategorySeparator>
                    </React.Fragment>
                  ))}
                  <HighLightedText
                    text={capitalizeFirstLetter(category.category_name)}
                    highlight={searchedQuery}
                  />
                </CategoryLink>
              </CategoryItem>
            ))}
        </List>
      ) : (
        renderNotFound()
      )}
      {MAX_CATEGORIES < categoryCount &&
        renderMore(
          __('Ďaľšie kategórie'),
          categoryCount,
          onSubmitSearchInput.bind(null, '0'),
        )}
    </>
  );

  const renderEshopProducts = () => {
    const items = concatSearchProductsAndSkvelkoSetsAndSoldOutProducts(
      products.products,
      products.skvelko_sets,
      products.sold_out_products,
      products.positions,
    );
    return renderProducts(
      items,
      productCount,
      __('Produkty v eshope'),
      onSubmitSearchInput.bind(null, '0'),
      productsFoundedQueries,
      settings,
    );
  };

  const renderDemandProducts = () => {
    const items = concatSearchProductsAndSkvelkoSetsAndSoldOutProducts(
      demandProducts ? demandProducts.products : [],
      [],
      [],
      [],
    );
    return renderProducts(
      items,
      demandProductsCount,
      __('Produkty pre školské zariadenia'),
      onSubmitSearchInput.bind(null, '2'),
      demanProductsFoundedQueries,
      settings,
    );
  };

  const renderProducts = (
    items,
    productsCount,
    title,
    onSubmit,
    foundedQueries,
    settings,
  ) => {
    return (
      <>
        <Title>{title}</Title>
        <FoundedQueries
          searchedResultsLoader={searchedResultsLoader}
          searchedQuery={searchedQuery}
          foundedQueries={foundedQueries}
        />

        {!searchedResultsLoader && items && items.length ? (
          <List>
            {items.map((item, index) => {
              if (item.type === SearchProductType.SKVELKO_SET) {
                return (
                  <ProductItem key={index}>
                    <ProductLink
                      to={getUrl(`/sada/${item.item.id}`)}
                      onClick={onRedirect.bind(
                        undefined,
                        `/sada/${item.item.id}`,
                        item.item.id,
                      )}
                    >
                      <ProductImg
                        src={`images/uni_set_${index % 13}.jpg`}
                        w={46}
                        h={46}
                        placeholder={placeholder}
                        retinaPlaceholder={placeholder2x}
                        notLazyloading={true}
                      />
                      <ProductNameWrapper>
                        {item.item.schoolName && (
                          <ProductName>
                            {getSchoolNameFromSkvelkoSet(item.item)}
                          </ProductName>
                        )}
                        <ProductName>{item.item.fullName} </ProductName>
                      </ProductNameWrapper>
                      <PriceWrapper>
                        <SkvelkoSetPrice
                          skvelkoSet={item.item}
                          user={user}
                          cart={cart}
                        />
                      </PriceWrapper>
                    </ProductLink>
                  </ProductItem>
                );
              } else if (item.type === SearchProductType.PRODUCT) {
                return (
                  <ProductItem key={index}>
                    <ProductLink
                      to={getUrl(item.item.url)}
                      onClick={onRedirect.bind(undefined, item.item.url)}
                    >
                      <ProductImg
                        src={item.item.picture}
                        w={60}
                        h={60}
                        placeholder={placeholder}
                        retinaPlaceholder={placeholder2x}
                        notLazyloading={true}
                      />
                      <ProductName>{item.item.name}</ProductName>
                      <PriceWrapper>
                        <ProductPrice
                          product={item.item}
                          user={user}
                          cart={cart}
                          isOnDemand={item.item.on_demand ? true : false}
                          settings={settings}
                        />
                      </PriceWrapper>
                    </ProductLink>
                  </ProductItem>
                );
              } else if (item.type === SearchProductType.PRODUCT_SOLD_OUT) {
                return (
                  <ProductItem key={index}>
                    <ProductLink
                      to={getUrl(item.item.url)}
                      onClick={onRedirect.bind(undefined, item.item.url)}
                    >
                      <ProductImg
                        src={item.item.picture}
                        w={60}
                        h={60}
                        placeholder={placeholder}
                        retinaPlaceholder={placeholder2x}
                        notLazyloading={true}
                      />
                      <ProductName>{item.item.name}</ProductName>
                      <PriceWrapper>
                        <SoldOutText>{__('TOVAR NIE JE SKLADOM')}</SoldOutText>
                      </PriceWrapper>
                    </ProductLink>
                  </ProductItem>
                );
              } else {
                return <> </>;
              }
            })}
          </List>
        ) : (
          renderNotFound()
        )}

        {MAX_PRODUCTS < productsCount &&
          renderMore(
            __('Ďaľšie produkty'),
            productsCount,
            onSubmit.bind(null, '0'),
          )}

        <Space />
      </>
    );
  };

  const renderWebContents = () => (
    <>
      {!searchedResultsLoader && webContent?.web_content?.length ? (
        <WebContentList
          webContent={{
            ...webContent,
            web_content: webContent?.web_content?.slice(0, MAX_WEB_CONTENT),
          }}
          onRedirect={onRedirect}
        />
      ) : (
        renderNotFound()
      )}
      {MAX_WEB_CONTENT < webContentCount &&
        renderMore(
          __('Ďaľšie stránky'),
          webContentCount,
          onSubmitSearchInput.bind(null, '1'),
        )}
    </>
  );

  const renderNotFound = () =>
    !searchedResultsLoader && <NotFoundText>{__('Nenájdené')}</NotFoundText>;

  const renderMore = (text, count, onClick) => (
    <More onClick={onClick}>
      <strong>{text}</strong> ({count})
    </More>
  );

  return (
    <>
      {searchDropdownVisible && (
        <Wrapper>
          <Loader loading={searchedResultsLoader}>
            {renderTabs([
              {
                name: __('Obchod'),
                count:
                  (Number(categoryCount) || 0) + (Number(productCount) || 0),
              },
              {
                name: __('Stránky'),
                count: Number(webContentCount) || 0,
              },
              {
                name: __('Pre školy'),
                count:
                  (Number(demandCategoryCount) || 0) +
                  (Number(demandProductsCount) || 0),
              },
            ])}
            {renderContent(activeTab)}
          </Loader>
        </Wrapper>
      )}
    </>
  );
};

export default MobileSearchResult;
