import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Icon from '../_helpers/Icon/Icon';
import { __ } from 'react-i18n/lib';
import { isSchoolOrTeacher, showPriceWithVat } from '../../utilities';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: ${rem(10)} ${rem(20)};
  margin-top: ${rem(2)};
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  ${({ theme }) => theme.media('sm')} {
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    padding: 0 ${rem(20)} ${rem(20)} ${rem(20)};
    margin-top: 0;
  }
  &.no-shadow {
    box-shadow: none;
    padding: 0;
    ${({ theme }) => theme.media('sm')} {
      padding: 0;
    }
  }
`;

const Text = styled.div`
  font-size: ${rem(14)};
  text-align: center;
  margin: 0 0 ${rem(8)};
  ${({ theme }) => theme.media('sm')} {
    margin: 0;
  }
`;

const TextGroup = styled.div`
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  max-width: ${rem(190)};
  ${({ theme }) => theme.media('xs')} {
    max-width: initial;
  }
`;

const TextGreen = styled(TextGroup)`
  font-weight: bold;
  vertical-align: initial;
  color: ${({ theme }) => theme.color.success};
`;

const TextRed = styled(TextGroup)`
  vertical-align: initial;
  color: ${({ theme }) => theme.color.error};
`;

const ShippingIcon = styled(Icon)`
  ${({ theme }) => theme.size(58)};
  margin-right: ${rem(10)};
  ${({ theme }) => theme.transition('fill')};
  &.success {
    fill: ${({ theme }) => theme.color.success};
  }
  &.error {
    fill: ${({ theme }) => theme.color.error};
  }
`;

const Range = styled.div`
  background: ${({ theme }) => theme.color.gray93};
  border-radius: ${rem(2)};
  height: ${rem(4)};
  position: relative;
  overflow: hidden;
`;

const RangeFilled = styled.div`
  border-radius: ${rem(2)};
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${({ theme }) => theme.transitions('background-color, width')};
  &.success {
    background: ${({ theme }) => theme.color.success};
  }
  &.error {
    background: ${({ theme }) => theme.color.error};
  }
`;

interface CartShippingRangeProps {
  cart: any;
  user: any;
  freeShippingPrice: number;
  className?: string;
}

class CartShippingRange extends React.PureComponent<CartShippingRangeProps> {
  public render() {
    const { freeShippingPrice, cart, user, className } = this.props;
    const [giftcard] = cart?.giftcards;
    const showWithVat = showPriceWithVat(user);
    const price =
      showWithVat && cart?.total_items_price_with_discount
        ? cart.total_items_price_with_discount
        : cart?.total_items_price_without_vat
        ? cart.total_items_price_without_vat
        : 0;
    const isFreeShipping =
      isSchoolOrTeacher(user) ||
      price / freeShippingPrice >= 1 ||
      giftcard?.freeshipping;
    return (
      <Wrapper className={className || ''}>
        <Text>
          <ShippingIcon icon="shippingBold" className={'success'} />
          {isFreeShipping ? (
            <TextGroup>
              {__('Dopravu máte ')}
              <strong>{__('zadarmo!')}</strong>
            </TextGroup>
          ) : (
            <TextGroup>
              {__('Pri nákupe za ')}
              <TextRed>
                {__('ďalších')}
                &nbsp;
                <strong>{(freeShippingPrice - price).toFixed(2)} €</strong>
              </TextRed>
              {__(' získate ')}
              <TextGreen>{__('dopravu zadarmo.')}</TextGreen>
            </TextGroup>
          )}
        </Text>
        <Range>
          <RangeFilled
            className={'success'}
            style={{
              width: isFreeShipping
                ? '100%'
                : (price / freeShippingPrice) * 100 + '%',
            }}
          />
        </Range>
      </Wrapper>
    );
  }
}

export default CartShippingRange;
