import * as React from 'react';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { __ } from 'react-i18n/lib';
import { prop } from '../../../utilities';
import { Button1, Button2, StyledFooter } from './ChooseModal';
import Overlay from '../Overlay/Overlay';
import { loadMailchimpSchoolText } from '../../../containers/App/actions';
import BaseModal from './BaseModal';
import { rem } from 'polished';
import styled from 'styled-components';
import { useState } from 'react';
import * as cookie from 'react-cookies';
import { push } from 'react-router-redux';
import { Loader } from '../Loader/Loader';
import { USER_COOKIE } from '../../../containers/App/constants';
import { URL_LOGIN } from '../../../constants';

const StyledModal = styled(BaseModal)`
  width: ${rem(510)};
`;

const COOKIE_MAILCHIMP_SCHOOL = 'mailchimpShowModal';

interface ModalProps {
  dispatch: any;
  location: any;
  mailchimpSchoolText?: string;
  mailchimpSchoolLoading?: boolean;
}

const MailchimpShoolModal = (props: ModalProps) => {
  const {
    dispatch,
    mailchimpSchoolLoading,
    mailchimpSchoolText,
    location,
  } = props;

  const [show, setShow] = useState(false);

  React.useEffect(() => {
    const cookieCanShow = cookie.load(COOKIE_MAILCHIMP_SCHOOL);
    const mailchimp = prop(location, 'query.mailchimp');
    if (
      !cookie.load(USER_COOKIE) &&
      mailchimp === 'school' &&
      cookieCanShow === undefined
    ) {
      setShow(true);
      cookie.save(COOKIE_MAILCHIMP_SCHOOL, false, {
        path: '/',
        maxAge: 60 * 5, // 5 minutes
      });
    }
  }, []);

  React.useEffect(() => {
    if (!mailchimpSchoolText && show) {
      dispatch(loadMailchimpSchoolText());
    }
  }, [show]);

  const name = prop(mailchimpSchoolText, 'name', __('Info'));
  const body = prop(mailchimpSchoolText, 'body', '');

  const onLoginButtonClick = () => {
    dispatch(push(URL_LOGIN));
    setShow(false);
  };

  const onCloseButtonClick = () => {
    setShow(false);
  };

  if (show) {
    if (mailchimpSchoolLoading) {
      return (
        <Overlay>
          <Loader loading={mailchimpSchoolLoading} />
        </Overlay>
      );
    } else {
      return (
        <StyledModal
          title={name}
          body={body}
          footer={
            <StyledFooter>
              <Button2 className="primary lg" onClick={onLoginButtonClick}>
                {__('Prihlásiť')}
              </Button2>
              <Button1 className="lg" onClick={onCloseButtonClick}>
                {__('Zavrieť')}
              </Button1>
            </StyledFooter>
          }
          onCloseButtonClick={onCloseButtonClick}
        />
      );
    }
  }
  return '';
};

const mapStateToProps = (state) => {
  return {
    mailchimpSchoolText: state.general.mailchimpSchoolText,
    mailchimpSchoolLoading: state.general.mailchimpSchoolLoading,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MailchimpShoolModal' })(MailchimpShoolModal),
);
