import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router';
import { rem } from 'polished';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import { ProductPreviewTitle } from '../product/ProductPreview';
import { formatPrice, getSchoolNameFromSkvelkoSet } from '../../../utilities';
import { ProductAvailabilityWrapper } from '../product/Availability';
import { __ } from 'react-i18n';
import { ButtonIcon, ButtonStyles } from '../form/Button';
import { push } from 'react-router-redux';

const Wrapper = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.color.white};
  position: relative;
`;

const Anchor = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const Thumb = styled(ImagePlaceholder)`
  display: block;
  width: ${rem(120)};
  padding: 0;
  margin: auto;
  ${({ theme }) => theme.media(1024)} {
    width: auto;
  }
`;

const WrapperContent = styled.div`
  padding: ${rem(10)} ${rem(10)} ${rem(20)};
  ${({ theme }) => theme.media('xs')} {
    padding: ${rem(20)} ${rem(20)} ${rem(30)};
  }
`;

const Title = styled(ProductPreviewTitle)`
  ${ProductPreviewTitle};
  margin: ${rem(10)} 0 ${rem(15)};
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: ${rem(14 * 1.5 * 2)};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FormRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  &.with-button {
    padding-right: ${rem(60)};
  }
`;

const AvailPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Price = styled.span`
  font-weight: 700;
`;

const PreviewAvailability = styled(ProductAvailabilityWrapper)`
  font-size: ${rem(12)};
`;

const AddToCartWrapper = styled.div``;

const AddToCart = styled.a`
  ${ButtonStyles};
  background: ${({ theme }) => theme.color.cartButtonBackground};
  border-radius: 50%;
  line-height: 1;
  padding: ${rem(12)};
  ${({ theme }) => theme.transition('background-color')};
  outline: none;
  position: absolute;
  right: ${rem(10)};
  bottom: ${rem(10)};
  &:focus,
  &:not(:disabled):hover {
    background: ${({ theme }) => theme.color.primary};
  }
  ${({ theme }) => theme.media('xs')} {
    right: ${rem(20)};
    bottom: ${rem(20)};
  }
`;

const AddToCartIcon = styled(ButtonIcon)`
  ${ButtonStyles};
  margin: 0;
  ${({ theme }) => theme.size(32)};
  ${({ theme }) => theme.transition('fill')};
  ${AddToCart}:focus &,
  ${AddToCart}:not(:disabled):hover & {
    fill: ${({ theme }) => theme.color.white};
  }
`;

interface SetCategoryPreviewProps {
  skvelkoSet: any;
  dispatch: any;
  user: any;
  cart?: any;
}

const SetCategoryPreview = (props: SetCategoryPreviewProps) => {
  const placeholder = '/images/placeholder-200.png';
  const placeholder2x = '/images/placeholder-200@2x.png';

  const setUrl = `/sada/${props.skvelkoSet.id}`;

  const handleClick = () => props.dispatch(push(setUrl));

  return (
    <Wrapper className={'product'}>
      <Anchor className={'url'} to={setUrl}>
        <Thumb
          src={`images/uni_set_0.jpg`}
          placeholder={placeholder}
          retinaPlaceholder={placeholder2x}
          w={215}
          h={215}
        />
        <WrapperContent>
          <Title className={'title'}>
            {getSchoolNameFromSkvelkoSet(props.skvelkoSet).trim() +
              ', ' +
              props.skvelkoSet.fullName}
          </Title>
          <FormRow>
            <AvailPrice>
              <PreviewAvailability className="on-stock">
                {' '}
                {__('Skladom')}{' '}
              </PreviewAvailability>
              <Price className={'price'}>
                {props.skvelkoSet.price
                  ? formatPrice(props.skvelkoSet.price, 'EUR')
                  : ''}
              </Price>
            </AvailPrice>
          </FormRow>
        </WrapperContent>
      </Anchor>
      <AddToCartWrapper>
        <AddToCart
          className={'add-to-cart'}
          onClick={handleClick}
          aria-label={__('Pridať do košíka')}
        >
          <AddToCartIcon icon="cog" />
        </AddToCart>
      </AddToCartWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    cart: state.cart.cart,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SetCategoryPreview ' })(SetCategoryPreview),
);
