import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { Button, ButtonStyledStyles, ButtonStyles } from '../form/Button';
import BaseModal, { Footer } from './BaseModal';

const ModalButtonStyles = css`
  flex: 0 0 auto;
`;

export const Button1 = styled(Button)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${ModalButtonStyles};
  margin-top: 0;
  order: -1;
  &.lg {
    padding: ${rem(14)} ${rem(15)};
    ${({ theme }) => theme.media('sm')} {
      font-size: ${rem(16)};
      padding: ${rem(14)} ${rem(40)};
    }
  }
`;

export const Button2 = styled(Button)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${ModalButtonStyles};
  &.lg {
    padding: ${rem(14)} ${rem(15)};
    ${({ theme }) => theme.media('sm')} {
      font-size: ${rem(16)};
      padding: ${rem(14)} ${rem(40)};
    }
  }
`;

export const StyledFooter = styled(Footer)`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
`;

interface ModalProps {
  title?: string;
  bodyText?: any;
  button1Text?: string;
  button2Text?: string;
  onButton1Click?: (event) => void;
  onButton2Click?: (event) => void;
  onCloseButtonClick?: (event) => void;
  loading?: boolean;
  notShowClose?: boolean;
  rightPrimary?: boolean;
}

class ChooseModal extends React.PureComponent<ModalProps> {
  public render() {
    let {
      title,
      bodyText,
      button1Text,
      button2Text,
      loading,
      rightPrimary = true,
    } = this.props;

    return (
      <BaseModal
        title={<strong>{title}</strong>}
        body={bodyText}
        loading={loading}
        footer={
          <StyledFooter>
            <Button2
              className={`${rightPrimary ? 'primary' : ''} lg`}
              click={this.props.onButton2Click}
            >
              {button2Text}
            </Button2>
            <Button1 className="lg" click={this.props.onButton1Click}>
              {button1Text}
            </Button1>
          </StyledFooter>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
        notShowClose={this.props.notShowClose}
      />
    );
  }
}

export default ChooseModal;
