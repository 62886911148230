import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  activeVariantByIdSelector,
  currentProductIdSelector,
} from '../Product/selectors';
import {
  Title,
  TitleCount,
} from '../../components/_layout/Page/ContentContainer';
import { ContentWithSidebar } from '../../theme/libraries/grid';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import AccountNav from './AccountNav';
import { Button } from '../../components/_helpers/form/Button';
import { push } from 'react-router-redux';
import Icon from '../../components/_helpers/Icon/Icon';
import { BreadcrumbsItems } from '../../constants';
import { Loader } from '../../components/_helpers/Loader/Loader';
import ContentContainerWithSidebar from '../../components/_layout/Page/ContentContainerWithSidebar';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

const HeadingIcon = styled(Icon)`
  ${({ theme }) => theme.size(24)};
  margin-right: ${rem(5)};
  transform: translateY(${rem(-4)});
`;

export const MainBlock = styled.div`
  &.gray {
    background: ${({ theme }) => theme.color.gray97};
  }
  &.border-top {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
`;

interface Props {
  products: any[];
  categories: object[];
  banners: any[];
  parentProps: {};
  dispatch: (a: any) => void;
  isCartProcessing: number[];
  isError: boolean;
  detaultTitle: string;
  loaded: any;
  defaultTitle: string;
  token: string;
  lang: string;
  currentProductId: number | null;
  user: object;
  url: string;
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isCartProcessing: state.cart.isProcessing,
    defaultTitle: state.general.title,
    token: state.auth.token,
    lang: state.general.lang,
    activeVariant: activeVariantByIdSelector(state),
    currentProductId: currentProductIdSelector(state),
    url: state.routing.locationBeforeTransitions.pathname,
  };
};

interface States {
  titleText: string | null;
  titleLink: string | null;
  titleCount: number | null;
  breadcrumbsItems: BreadcrumbsItems;
}

class Ucet extends React.Component<Props, States> {
  constructor(props) {
    super(props);

    this.state = {
      titleText: __('Môj účet'),
      titleLink: null,
      titleCount: null,
      breadcrumbsItems: [],
    };
  }

  public setTitle = (text, link = null, count = null) => {
    this.setState({
      titleText: text,
      titleLink: link,
      titleCount: count,
    });
  };

  public setBreadcrumbsItems = (breadcrumbsItems) => {
    this.setState({
      breadcrumbsItems: [
        { name: 'Môj účet', url: '/my-account' },
        ...breadcrumbsItems,
      ],
    });
  };

  public onButtonBackClick = (link) => {
    this.props.dispatch(push(link));
  };

  public render() {
    return (
      <ContentContainerWithSidebar>
        <AccountNav currentPage={this.props.url} user={this.props.user} />
        <ContentWithSidebar>
          <Main>
            <MainBlock>
              <Breadcrumbs items={this.state.breadcrumbsItems} />
              <Title>
                {this.state.titleLink && (
                  <Button
                    click={this.onButtonBackClick.bind(
                      this,
                      this.state.titleLink,
                    )}
                  >
                    <HeadingIcon icon="back" />
                  </Button>
                )}

                {this.state.titleText}

                {this.state.titleCount ? (
                  <TitleCount>{this.state.titleCount}</TitleCount>
                ) : (
                  ''
                )}
              </Title>
            </MainBlock>
            {this.props.user ? (
              React.cloneElement(this.props.children as React.ReactElement, {
                setTitle: this.setTitle,
                setBreadcrumbsItems: this.setBreadcrumbsItems,
              })
            ) : (
              <Loader loading={true} />
            )}
          </Main>
        </ContentWithSidebar>
      </ContentContainerWithSidebar>
    );
  }
}

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Ucet' })(Ucet),
);
