import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import ImagePlaceholder from '../_helpers/Image/ImagePlaceholder';
import { CloseIcon } from '../_helpers/Icon/Close';
import { Button } from '../_helpers/form/Button';
import { ProductPreviewTitle } from '../_helpers/product/ProductPreview';
import { BlockMarginStyles } from '../_helpers/Block/Block';
import API, { ThenArg } from '../../services/API';
import {
  productInCartDelete,
  productInCartCountUpdate,
} from '../../containers/Cart/actions';
import Alert from '../Alert/Alert';
import InputSpinner from '../_helpers/form/InputSpinner';
import { getProductInCartDemandAttribs } from '../../containers/Product/actions';
import Icon from '../_helpers/Icon/Icon';
import {
  changeAmountProductOutOfStock,
  setCurrentProductForPreview,
} from '../../containers/App/actions';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import Variant from '../_helpers/product/Variant';
import { useRef, useState } from 'react';
import { Loader } from '../_helpers/Loader/Loader';

export const CartProductListWrapperStyles = css`
  ${({ theme }) => theme.media('md')} {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  ${BlockMarginStyles};
  ${CartProductListWrapperStyles};
  margin: ${rem(15)} 0;
`;

export const Header = styled.div`
  display: none;
  font-size: ${rem(14)};
  font-weight: 700;
  white-space: nowrap;
  padding: ${rem(10)};
  ${({ theme }) => theme.media('md')} {
    display: table-header-group;
  }
`;

export const HeaderRow = styled.div`
  display: table-row;
  padding-left: 0;
`;

export const HeaderCol = styled.div`
  display: table-cell;
  padding: 0 ${rem(20)} ${rem(10)};
`;

export const HeaderColCenter = styled(HeaderCol)`
  text-align: center;
`;

export const HeaderColProduct = styled(HeaderCol)`
  width: 100%;
  padding-left: 0;
`;

export const List = styled.div`
  ${({ theme }) => theme.media('md')} {
    display: table-row-group;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.white};
  font-size: ${rem(14)};
  padding: ${rem(10)} 3% ${rem(10)} 3%;
  margin-bottom: ${rem(2)};
  position: relative;
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  ${({ theme }) => theme.transition('box-shadow')};
  ${({ theme }) => theme.media('xs')} {
    justify-content: flex-end;
  }
  ${({ theme }) => theme.media('md')} {
    display: table-row;
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    &:hover {
      box-shadow: ${({ theme }) => theme.boxShadow.default};
    }
  }
`;

export const GroupRow = styled.div`
  display: table-cell;
  max-width: 1px;
  overflow: visible;
  padding-left: ${rem(95)};
  padding-bottom: ${rem(20)};
`;

const ColStyles = css`
  padding: 0;
  display: flex;

  ${({ theme }) => theme.media('md')} {
    display: table-cell;
    vertical-align: middle;
    padding: ${rem(20)};
  }
`;

export const InfoCol = styled.div`
  ${ColStyles};
  display: flex;
  flex: 1 0 100%;
  min-width: 1%;
  min-height: ${rem(70)};
  padding-right: ${rem(30)};
  overflow: hidden;
  margin-bottom: ${rem(10)};
  ${({ theme }) => theme.media('md')} {
    border-top-left-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-bottom-left-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
    margin-bottom: 0;
  }
`;

export const ThumbWrapperStyles = css`
  margin-right: ${rem(15)};
  width: ${rem(70)};
  min-width: ${rem(70)};

  ${({ theme }) => theme.media('md')} {
    float: left;
    width: ${rem(60)};
    min-width: ${rem(60)};
  }
`;

export const ThumbWrapperPreview = styled.a`
  ${ThumbWrapperStyles};
  display: block;
  position: relative;
  cursor: pointer;
`;

export const ThumbWrapper = styled.div`
  ${ThumbWrapperStyles};
`;

export const Thumb = styled(ImagePlaceholder)``;

export const Info = styled.div`
  padding-top: ${rem(0)};

  ${({ theme }) => theme.media('md')} {
    padding-top: ${rem(10)};
    overflow: hidden;
  }
`;

export const Name = styled(ProductPreviewTitle)`
  white-space: normal;
  font-weight: bold;
  font-size: ${rem(15)};

  ${({ theme }) => theme.media('md')} {
    overflow: hidden;
    white-space: nowrap;
    font-weight: normal;
    text-overflow: ellipsis;
    font-size: ${rem(14)};
  }

  &.big {
    font-weight: bold;
    font-size: ${rem(18)};
    white-space: normal;
    ${({ theme }) => theme.media('md')} {
      max-width: ${rem(350)};
    }
  }
`;

export const GroupName = styled(Name)`
  ${({ theme }) => theme.media('md')} {
    white-space: initial;
  }
`;

export const GroupMobileName = styled(GroupName)`
  font-weight: normal;
`;

export const GroupNamePrice = styled.div`
  display: inline;
  color: ${({ theme }) => theme.color.blue};
`;

export const PriceCol = styled.div`
  ${ColStyles};
  text-align: right;
  white-space: nowrap;
`;

export const Price = styled.div`
  font-weight: normal;
`;

export const AmountCol = styled.div`
  ${ColStyles};
`;

export const TotalPriceCol = styled.div`
  ${ColStyles};
  text-align: center;
  min-width: 120px;
`;

export const EmptyCol = styled.div`
  ${ColStyles};
  text-align: center;
`;

export const TotalPrice = styled.div`
  font-weight: 700;
  font-size: ${rem(16)};

  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(15)};
  }
`;

export const TotalPriceNew = styled.div`
  font-weight: 700;
`;

export const TotalPriceNewTitle = styled.div`
  color: ${({ theme }) => theme.color.primary};
  text-transform: uppercase;
  font-weight: 700;
`;

export const TotalPriceDiscountTitle = styled.div`
  color: ${({ theme }) => theme.color.success};
  text-transform: uppercase;
  font-weight: 700;
`;

const RemoveCol = styled.div`
  ${ColStyles};
  align-items: center;
  ${({ theme }) => theme.media('md')} {
    border-top-right-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-bottom-right-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
  }
  width: 100%;
`;

const RemoveButton = styled(Button)`
  line-height: 1;
  padding: ${rem(6)};
  top: ${rem(10)};
  right: ${rem(10)};
  ${({ theme }) => theme.media('md')} {
    position: static;
  }
  border-radius: 50%;
  color: ${({ theme }) => theme.color.text};
  background-color: ${({ theme }) => theme.color.gray95};

  &:hover {
    background-color: ${({ theme }) => theme.color.gray87};
  }
`;

const CartItemCloseIcon = styled(CloseIcon)`
  ${({ theme }) => theme.size(13, 2)};
  &:before,
  &:after {
    background: ${({ theme }) => theme.color.text};
    ${({ theme }) => theme.transition('background-color')};
  }
  ${RemoveButton}:hover & {
    &:before,
    &:after {
      background: ${({ theme }) => theme.color.black};
    }
  }
`;

const CartItemCloseText = styled.span`
  font-size: ${rem(9)};
  text-transform: uppercase;
  margin-top: ${rem(4)};
  font-weight: 600;
  display: block;
`;

const TableHeaderAdditionalText = styled.span`
  font-weight: normal;
`;

const MobilePriceContainer = styled.div`
  display: block;
  padding: 0 15px;
`;

export const PreviewIcon = styled(Icon)`
  ${({ theme }) => theme.size(16)};
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const EmptyRow = styled.div`
  display: none;
  ${({ theme }) => theme.media('md')} {
    display: table-row;
  }
`;

export const EmptyTd = styled.div`
  display: table-cell;
  padding: ${rem(5)};
  max-width: 1px;
`;

export const AlertWrapper = styled.div`
  margin-bottom: ${rem(10)};
`;

interface CartProductOnDemandListProps {
  items: ThenArg<typeof API.getCart>['items'];
  dispatch: any;
  user: any;
  amountProductOutOfStock?: any;
  isOnlyDemand: boolean;
  cart: any;
}

const CartProductOnDemandList = (props: CartProductOnDemandListProps) => {
  const placeholder = '/images/placeholder-100.png';
  const placeholder2x = '/images/placeholder-100@2x.png';

  const [loaderAmountId, setLoaderAmountId] = useState(-1);
  const [loaderRemoveId, setLoaderRemoveId] = useState(-1);

  const { dispatch } = props;
  let { items } = props;
  items = items ? items : [];

  const isMobile = (window && window.innerWidth) <= 992;

  const cartRef = useRef(null);

  const onRemoveButtonClick = async (
    productId,
    goodId,
    demandAttribs,
    itemUniqId,
  ) => {
    await dispatch(
      productInCartDelete(productId, goodId, demandAttribs, '', itemUniqId),
    );
  };

  const onItemCountChange = async (
    productId,
    goodId,
    actualCount,
    stockCount,
    skvelkoSetId,
    skvelkoClassId,
    itemGroupId,
    count,
    onDemand,
    demandAttribs,
  ) => {
    const updateCount = count - actualCount;
    if (updateCount > 0 && count > stockCount && !onDemand) {
      dispatch(
        changeAmountProductOutOfStock({
          stockCount,
        }),
      );
      return;
    }
    if (updateCount !== 0) {
      await dispatch(
        productInCartCountUpdate(
          productId,
          goodId,
          updateCount,
          skvelkoSetId,
          skvelkoClassId,
          itemGroupId,
          undefined,
          demandAttribs,
        ),
      );
    }
  };

  const showPreview = (product) => {
    props.dispatch(setCurrentProductForPreview(product));
  };

  const amountChange = async (
    productId,
    goodId,
    actualCount,
    stockCount,
    skvelkoSetId,
    skvelkoClassId,
    itemGroupId,
    counts,
    onDemand,
    demandAttribs,
  ) => {
    setLoaderAmountId(goodId);
    await onItemCountChange(
      productId,
      goodId,
      actualCount,
      stockCount,
      skvelkoSetId,
      skvelkoClassId,
      itemGroupId,
      counts,
      onDemand,
      demandAttribs,
    );
    setLoaderAmountId(-1);
  };

  const amountCol = (item) => {
    return (
      <AmountCol>
        <Loader loading={false}>
          <InputSpinner
            value={item.count}
            onChange={(count) => {
              amountChange(
                item.good.product_id,
                item.good.good_id,
                item.count,
                item.good.on_stock_count,
                item.skvelko_set ? item.skvelko_set.id : undefined,
                item.skvelko_class ? item.skvelko_class.id : undefined,
                item.item_group_id ? item.item_group_id : undefined,
                count,
                item.product.on_demand,
                item.demand_attribs,
              );
            }}
            min={
              item.good.order_quantity_limit && !item.skvelko_set
                ? item.good.order_quantity_limit
                : 1
            }
            inCart={true}
          />
        </Loader>
      </AmountCol>
    );
  };

  const removeClick = async (productId, goodId, demandAttribs, itemUniqId) => {
    setLoaderRemoveId(goodId);
    await onRemoveButtonClick(productId, goodId, demandAttribs, itemUniqId);
    setLoaderRemoveId(-1);
  };

  const removeCol = (item) => {
    return (
      <RemoveCol>
        <Loader loading={false}>
          <RemoveButton
            onClick={removeClick.bind(
              null,
              item.good.product_id,
              item.good.good_id,
              item.demand_attribs,
              item.uniq_id,
            )}
          >
            <CartItemCloseIcon />
            <CartItemCloseText>{__('Zmaž')}</CartItemCloseText>
          </RemoveButton>
        </Loader>
      </RemoveCol>
    );
  };

  const totalPriceCol = () => {
    if (!props.isOnlyDemand) {
      return (
        <TotalPriceCol>{__('Ponuku ceny Vám zašleme emailom')}</TotalPriceCol>
      );
    }
    return <TotalPriceCol></TotalPriceCol>;
  };

  return (
    <Loader loading={loaderAmountId > -1 || loaderRemoveId > -1}>
      {items.length ? (
        <Wrapper ref={cartRef}>
          <Header>
            <HeaderRow>
              <HeaderColProduct>
                {__('Produkty pre kalkuláciu')}
                <TableHeaderAdditionalText>
                  {' '}
                  (nezáväzná žiadosť o cenovú ponuku)
                </TableHeaderAdditionalText>
              </HeaderColProduct>
              <HeaderCol />
              <HeaderColCenter>{__('Množstvo')}</HeaderColCenter>
            </HeaderRow>
          </Header>
          <List>
            {items.map((item, i) => {
              const variants = getProductInCartDemandAttribs(item);

              return (
                <React.Fragment key={i}>
                  <Item>
                    <InfoCol>
                      <ThumbWrapperPreview
                        onClick={showPreview.bind(null, item.product)}
                      >
                        <Thumb
                          src={
                            item.product.picture ? item.product.picture : null
                          }
                          placeholder={placeholder}
                          retinaPlaceholder={placeholder2x}
                          w={100}
                          h={100}
                        />
                        <PreviewIcon icon="eye" />
                      </ThumbWrapperPreview>

                      <Info>
                        <Name>{item.product.name}</Name>
                        {variants &&
                          variants.map((variant) => (
                            <Name>
                              <Variant variant={variant} />
                            </Name>
                          ))}
                      </Info>
                    </InfoCol>
                    {isMobile ? (
                      <>
                        {amountCol(item)}
                        <MobilePriceContainer>
                          {totalPriceCol()}
                        </MobilePriceContainer>
                        {removeCol(item)}
                      </>
                    ) : (
                      ''
                    )}

                    {!isMobile ? (
                      <>
                        <EmptyCol />
                        {amountCol(item)}
                        {totalPriceCol()}
                        {removeCol(item)}
                      </>
                    ) : (
                      ''
                    )}
                  </Item>
                  <EmptyRow>
                    <EmptyTd />
                    <EmptyTd />
                    <EmptyTd />
                    <EmptyTd />
                    <EmptyTd />
                  </EmptyRow>
                </React.Fragment>
              );
            })}
          </List>
        </Wrapper>
      ) : (
        <AlertWrapper>
          <Alert> {__('Váš košik je prázdny')} </Alert>
        </AlertWrapper>
      )}
    </Loader>
  );
};

const mapStateToProps = (state) => {
  return {
    amountProductOutOfStock: state.general.amountProductOutOfStock,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartProductOnDemandList' })(
    CartProductOnDemandList,
  ),
);
