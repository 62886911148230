import styled, { css } from 'styled-components';
import { InputStyled } from '../_helpers/form/Input';
import { Label } from '../_helpers/form/Label';
import { Col } from '../../theme/libraries/grid';
import { rem } from 'polished';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import { SelectStyled } from '../_helpers/form/Select';
import Alert from '../Alert/Alert';
import { ErrorPopup } from '../_helpers/form/FormStyles';
import EmailAutocomplete from '../_helpers/form/EmailAutocomplete';
import Icon from '../_helpers/Icon/Icon';

export const RegistrationHeading = styled.h3`
  margin: ${rem(10)} 0 0;
`;

export const RegistrationErrorPopup = styled(ErrorPopup)``;

const inputCss = css`
  display: block;
  width: 100%;
  ${(props: any) => (props.error ? 'border-color: red' : '')}
`;

export const RegistrationInput: any = styled(InputStyled)`
  ${inputCss}
`;

export const RegistrationEmailInput: any = styled(EmailAutocomplete)`
  ${inputCss}
`;

export const RegistrationSelect: any = styled(SelectStyled)`
  ${inputCss}
`;

export const RegistrationLabel = styled(Label)`
  display: block;
  &.full-width {
    flex: 1 0 100%;
  }
`;

export const RegistrationFormRow = styled(Clearfix)`
  margin-right: ${rem(-10)};
  margin-left: ${rem(-10)};
  margin-bottom: ${rem(10)};
`;

export const RegistrationFormCol = styled(Col)`
  padding-right: ${rem(10)};
  padding-left: ${rem(10)};
  ${({ theme }) => theme.media('xs')} {
    width: ${({ theme }) => theme.grid.col.col6};
    &.full-width {
      width: ${({ theme }) => theme.grid.col.col12};
    }
  }
`;

export const RegistrationCheckboxWrapper = styled.div`
  display: flex;
  align-self: center;
  padding: ${rem(6)} 0;
`;

export const RegistrationAlert = styled(Alert)`
  margin-bottom: ${rem(5)};
`;

export const PasswordIcon = styled(Icon)`
  position: absolute;
  top: ${rem(12)};
  right: ${rem(12)};
  ${({ theme }) => theme.size(30)};
  fill: ${({ theme }) => theme.color.gray70};
`;

export const LoginInputPasswordWrapper = styled.div`
  position: relative;
`;

export const LoginInputCss = css`
  display: block;
  width: 100%;
`;

export const LoginInputPassword = styled(InputStyled)`
  ${LoginInputCss};
`;
