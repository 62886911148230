import * as React from 'react';
import styled from 'styled-components';
import { ContainerClearfix, grid, Row } from '../../../theme/libraries/grid';
import { rem } from 'polished';
import {
  BlockAppearanceStyles,
  BlockMarginStyles,
} from '../../_helpers/Block/Block';
import { ContentContainersStyles } from './ContentContainer';

const ContentContainer = styled(ContainerClearfix)`
  padding-right: 0;
  padding-left: 0;
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(20)};
    padding-right: ${rem(grid.padding / 2)};
    padding-left: ${rem(grid.padding / 2)};
  }
`;

const ContentWrapper = styled.div`
  ${BlockAppearanceStyles};
  ${BlockMarginStyles};
`;

const ContentContainerBlock = styled.div`
  ${ContentContainersStyles};
  & + & {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
  ${({ theme }) => theme.media('md')} {
    padding: ${rem(20)} ${rem(20)};
  }
`;

interface Props {
  children: any;
}

const ContentContainerWithSidebar = (props: Props) => {
  const { children } = props;

  return (
    <ContentContainer className="base">
      <ContentWrapper>
        <ContentContainerBlock>
          <Row>{children}</Row>
        </ContentContainerBlock>
      </ContentWrapper>
    </ContentContainer>
  );
};

export default ContentContainerWithSidebar;
