import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import {
  clearReduxCache,
  resetHomepageBanners,
  resetSchoolBanners,
} from '../App/actions';
import { BANNER_HOMEPAGE } from '../../constants';

interface Props {
  dispatch: any;
  homepageBanners: any[];
}
class ClearReduxCache extends React.Component<Props> {
  public static async getInitialProps(props) {
    await props.dispatch(clearReduxCache());
    await props.dispatch(resetSchoolBanners());
    await props.dispatch(resetHomepageBanners());
    return props;
  }

  constructor(props) {
    super(props);
  }

  public render() {
    return <>...</>;
  }
}

const mapStateToProps = (state) => {
  return {
    homepageBanners: state.general.banners?.[BANNER_HOMEPAGE]?.banners,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ClearReduxCache' })(ClearReduxCache),
);
