import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { Button1, Button2, StyledFooter } from './ChooseModal';
import BaseModal from './BaseModal';
import { rem } from 'polished';
import styled from 'styled-components';
import { loadCartProtectionText } from '../../../containers/App/actions';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import Overlay from '../Overlay/Overlay';
import { Loader } from '../Loader/Loader';
import { OtherText } from '../../../constants';
import { updateCart } from '../../../containers/Cart/actions';
import { Field, Form } from 'react-final-form';
import {
  composeValidators,
  email,
  phone,
  phoneMobile,
  required,
} from '../../../utilities/validators';
import { ErrorPopup } from '../form/FormStyles';
import {
  CartFormCol,
  CartFormRow,
  CartInput,
  CartLabel,
} from '../../Cart/CartStyles';
import EmailAutocomplete from '../form/EmailAutocomplete';

const StyledModal = styled(BaseModal)`
  width: ${rem(510)};
`;

interface ModalProps {
  cart: any;
  dispatch: any;
  onCloseButtonClick?: (event) => void;
  cartProtectionText?: OtherText;
  cartProtectionTextLoading?: boolean;
}

const CartProtectionModal = (props: ModalProps) => {
  const [loading, setLoading] = React.useState(false);

  const {
    dispatch,
    cart,
    onCloseButtonClick,
    cartProtectionText,
    cartProtectionTextLoading,
  } = props;

  React.useEffect(() => {
    if (!cartProtectionText) {
      dispatch(loadCartProtectionText());
    }
    if (!cart?.protection_modal_was_displayed) {
      dispatch(updateCart({ ...cart, protection_modal_was_displayed: true }));
    }
  }, []);

  const onSubmit = async (values) => {
    setLoading(true);
    if (values.phone !== '' || values.email !== '') {
      const newCart = {
        ...cart,
        billing_address: {
          ...cart.billing_address,
          person: {
            ...cart.billing_address.person,
            phone: values.phone,
            email: values.email,
          },
        },
      };
      await dispatch(updateCart(newCart));
    }
    setLoading(false);
    if (onCloseButtonClick) {
      onCloseButtonClick(undefined);
    }
  };

  if (cartProtectionTextLoading) {
    return (
      <Overlay>
        <Loader loading={cartProtectionTextLoading} />
      </Overlay>
    );
  } else {
    return (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <StyledModal
            title={cartProtectionText?.name || ''}
            body={
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: cartProtectionText?.body || '',
                  }}
                />

                <CartFormRow>
                  <Field
                    name="email"
                    validate={composeValidators(required, email)}
                  >
                    {({ input, meta }) => (
                      <CartFormCol>
                        <CartLabel htmlFor="person_email">
                          {__('E-mail')}
                        </CartLabel>
                        <EmailAutocomplete
                          {...input}
                          type={'email'}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </CartFormCol>
                    )}
                  </Field>
                  <Field
                    name="phone"
                    validate={composeValidators(required, phone, phoneMobile)}
                  >
                    {({ input, meta }) => (
                      <CartFormCol>
                        <CartLabel htmlFor="phone">
                          {__('Mobilné číslo')}
                        </CartLabel>
                        <CartInput
                          {...input}
                          type="tel"
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </CartFormCol>
                    )}
                  </Field>
                </CartFormRow>
              </>
            }
            footer={
              <StyledFooter>
                <Button1
                  className="lg separationBottom"
                  onClick={onCloseButtonClick}
                >
                  {__('Zavrieť')}
                </Button1>
                <Loader loading={loading}>
                  <Button2
                    className="primary lg separationBottom"
                    onClick={handleSubmit}
                  >
                    {__('Uložiť')}
                  </Button2>
                </Loader>
              </StyledFooter>
            }
            onCloseButtonClick={onCloseButtonClick}
          />
        )}
      />
    );
  }
};

const mapStateToProps = (state) => {
  return {
    cartProtectionText: state.general.cartProtectionText,
    cartProtectionTextLoading: state.general.cartProtectionTextLoading,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartProtectionModal' })(CartProtectionModal),
);
