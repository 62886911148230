import styled from 'styled-components';
import BaseModal from './BaseModal';
import { rem } from 'polished';

export const ContentModal = styled(BaseModal)`
  width: 100vw;
  height: 100%;
  ${({ theme }) => theme.media('md')} {
    max-width: ${rem(1000)};
  }
`;

export const ContentModalBody = styled.div`
  height: 70vh;
  overflow: scroll;
  overflow-x: auto;
`;
