import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { InputStyled } from './Input';
import { ButtonStyles } from './Button';
import { __ } from 'react-i18n';

const Wrapper = styled.div`
  position: relative;
  width: ${rem(120)};
  height: ${rem(50)};
`;

const StyledInput = styled(InputStyled)`
  padding-right: ${rem(40)}!important;
  padding-left: ${rem(40)}!important;
  width: 100%;
  height: 100%;
  text-align: center;
  border-color: ${({ theme }) => theme.color.gray93};
  ${(props) => (props.padding ? `padding: ${props.padding} !important;` : '')};
`;

const Button = styled.button`
  ${ButtonStyles};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  font-size: ${rem(18)};
  font-weight: 700;
  width: ${rem(40)};
  height: 100%;
  position: absolute;
  top: 0;
  outline: none;
  &:disabled {
    opacity: 0.5;
  }
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(18)};
  }
`;

const ButtonCart = styled(Button)`
  border-color: ${({ theme }) => theme.color.gray80};
`;

const Minus = styled(Button)`
  left: 0;
`;

const MinusCart = styled(ButtonCart)`
  left: 0;
  background-color: ${({ theme }) => theme.color.gray95};
  border-right-color: ${({ theme }) => theme.color.gray80};
`;

const Plus = styled(Button)`
  right: 0;
`;

const PlusCart = styled(ButtonCart)`
  right: 0;
  background-color: ${({ theme }) => theme.color.gray95};
`;

interface Props {
  value: number;
  onChange: (e: any) => void;
  min?: number;
  inCart?: boolean;
  padding?: string;
}

interface State {
  value: number;
  inCart: boolean;
}

class InputSpinner extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue ? props.defaultValue : 1,
      inCart: props.inCart ? props.inCart : false,
    };
  }

  public decrease = () => {
    this.props.onChange(this.props.value - 1);
  };

  public increase = () => {
    this.props.onChange(this.props.value + 1);
  };

  public onChange = (e) => {
    if (e.target.value === '') {
      this.props.onChange(1);
    } else {
      this.props.onChange(Math.abs(parseInt(e.target.value, 10)));
    }
  };

  public renderCartType(min) {
    return (
      <Wrapper>
        <StyledInput
          type="number"
          min={min ?? 1}
          className="lg"
          value={this.props.value}
          onChange={this.onChange}
          padding={this.props.padding}
          aria-label={__('Počet produktov')}
        />
        <MinusCart disabled={this.props.value === min} onClick={this.decrease}>
          –
        </MinusCart>
        <PlusCart onClick={this.increase}>+</PlusCart>
      </Wrapper>
    );
  }

  public renderBaseType(min) {
    return (
      <Wrapper>
        <StyledInput
          type="number"
          min={min ?? 1}
          className="lg"
          value={this.props.value}
          onChange={this.onChange}
          aria-label={__('Počet')}
        />
        <Minus disabled={this.props.value === min} onClick={this.decrease}>
          –
        </Minus>
        <Plus onClick={this.increase}>+</Plus>
      </Wrapper>
    );
  }

  public render() {
    const min = this.props.min === undefined ? 1 : this.props.min;

    return this.props.inCart
      ? this.renderCartType(min)
      : this.renderBaseType(min);
  }
}

export default InputSpinner;
