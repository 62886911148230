// import {
//   SET_CART_ID,
//   CHANGE_PROCESSING_STATUS,
//   SET_CART_TOTAL_PRICE,
//   OPEN_MODAL,
//   CLOSE_MODAL,
//   RESET_CART_ID,
//   SET_STEP,
//   SHOW_3RD_STEP_PROBLEMS,
//   REQUEST_FREE_DELIVERY_INFO,
//   RECEIVE_FREE_DELIVERY_INFO_SUCCESS,
//   RECEIVE_FREE_DELIVERY_INFO_FAILURE,
//   SET_CART_COUNT,
//   SET_CART_ITEMS,
// } from './constants';
// import { CartInterface } from './types';

// const INITIAL_STATE: CartInterface = {
//   totalPrice: 0,
//   currency: '',
//   cartId: '',
//   isProcessing: [],
//   modalData: undefined,
//   step: 1,
//   shouldShow3rdStepProblems: false,
//   freeDeliveryInfo: {
//     isFetching: false,
//     data: {
//       remaining_price: null,
//       cart_price: 0,
//       currency: '',
//     },
//   },
//   productsCount: 0,
//   cartItems: [],
// };

// export const cartReducer = (state = INITIAL_STATE, action) => {
//   switch (action.type) {
//     case RESET_CART_ID: {
//       return {
//         ...state,
//         cartId: '',
//         totalPrice: 0,
//       };
//     }
//     case OPEN_MODAL: {
//       return {
//         ...state,
//         modal: action.payload.modalData,
//       };
//     }
//     case CLOSE_MODAL: {
//       return {
//         ...state,
//         modal: undefined,
//       };
//     }
//     case SET_CART_TOTAL_PRICE: {
//       return {
//         ...state,
//         totalPrice: action.payload.price || 0,
//         currency: action.payload.currency,
//       };
//     }
//     case SET_CART_ID: {
//       return {
//         ...state,
//         cartId: action.payload.cartId,
//       };
//     }
//     case SET_CART_COUNT: {
//       return {
//         ...state,
//         productsCount: action.payload.count,
//       };
//     }
//     case SET_CART_ITEMS: {
//       return {
//         ...state,
//         cartItems: action.payload.items,
//       };
//     }
//     case CHANGE_PROCESSING_STATUS: {
//       const { productId } = action.payload;
//       const currentProcessingArr = [...state.isProcessing];

//       if (currentProcessingArr.indexOf(productId) === -1) {
//         currentProcessingArr.push(productId);
//       } else {
//         const index = currentProcessingArr.indexOf(productId);
//         currentProcessingArr.splice(index, 1);
//       }

//       return {
//         ...state,
//         isProcessing: currentProcessingArr,
//       };
//     }
//     case SET_STEP: {
//       return {
//         ...state,
//         step: action.payload.step,
//       };
//     }
//     case SHOW_3RD_STEP_PROBLEMS: {
//       return {
//         ...state,
//         shouldShow3rdStepProblems: action.payload.canShow,
//       };
//     }
//     case REQUEST_FREE_DELIVERY_INFO: {
//       return {
//         ...state,
//         freeDeliveryInfo: {
//           ...state.freeDeliveryInfo,
//           isFetching: true,
//         },
//       };
//     }
//     case RECEIVE_FREE_DELIVERY_INFO_SUCCESS: {
//       return {
//         ...state,
//         freeDeliveryInfo: {
//           ...state.freeDeliveryInfo,
//           isFetching: false,
//           data: action.payload.data,
//         },
//       };
//     }
//     case RECEIVE_FREE_DELIVERY_INFO_FAILURE: {
//       return {
//         ...state,
//         freeDeliveryInfo: {
//           ...state.freeDeliveryInfo,
//           isFetching: false,
//           error: action.payload.error,
//         },
//       };
//     }
//     default: {
//       return state;
//     }
//   }
// };

import {
  SET_STEP,
  REQUEST_DELIVERY_AND_PAYMENT_OPTIONS,
  RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_SUCCESS,
  RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_ERROR,
  CART_IS_FETCHING,
  REQUEST_CREATE_NEW_CART,
  RECEIVE_CREATE_NEW_CART_SUCCESS,
  RECEIVE_CREATE_NEW_CART_ERROR,
  REQUEST_CART_DATA,
  RECEIVE_CART_DATA_SUCCESSFUL,
  RECEIVE_CART_DATA_ERROR,
  RECEIVE_UPDATE_CART_DATA_ERROR,
  RECEIVE_UPDATE_CART_DATA_SUCCESSFUL,
  REQUEST_UPDATE_CART_DATA,
  REQUEST_PRODUCT_IN_CART_COUNT_UPDATE,
  RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_SUCCESS,
  RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_ERROR,
  REQUEST_FREE_DELIVERY_INFO,
  RECEIVE_FREE_DELIVERY_DATA_SUCCESS,
  RECEIVE_FREE_DELIVERY_DATA_ERROR,
  REQUEST_PRODUCT_IN_CART_DELETE,
  RECEIVE_PRODUCT_IN_CART_DELETE_SUCCESS,
  RECEIVE_PRODUCT_IN_CART_DELETE_ERROR,
  REQUEST_ADD_GIFTCARD,
  RECEIVE_ADD_GIFTCARD_ERROR,
  RECEIVE_ADD_GIFTCARD_SUCCESS,
  SET_NEW_DELIVERY_OPTION,
  SET_NEW_PAYMENT_OPTION,
  CHANGE_CONTACT_FORM_INPUT_VALUE,
  CHANGE_COMPANY_FORM_INPUT_VALUE,
  CHANGE_BILLING_FORM_INPUT_VALUE,
  CHANGE_CHECKBOX_VALUE,
  CHANGE_CART_DATA_VALUE,
  REQUEST_CREATE_ORDER,
  RECEIVE_CREATE_ORDER_SUCCESS,
  REQUEST_GIFTS,
  RECEIVE_GIFTS_SUCCESS,
  RECEIVE_GIFTS_ERROR,
  RECEIVE_CREATE_ORDER_ERROR,
  OPEN_MODAL,
  CLOSE_MODAL,
  CHANGE_GIFT,
  REQUEST_DELETE_GIFTCARD,
  RECEIVE_DELETE_GIFTCARD_ERROR,
  RECEIVE_DELETE_GIFTCARD_SUCCESS,
  SET_CART,
  SET_CUSTOMER_DETAILS_LOADER,
  SET_CUSTOMER_DETAILS,
  SHOW_RECOGNIZED_CUSTOMER_CARD,
  SET_DPD_DELIVERY_POINTS,
  SET_DPD_DELIVERY_POINT,
  SET_PACKETA_DELIVERY_POINT,
  SET_SHOPS_PERSONAL_PICKUP,
  SET_SHOP_PERSONAL_PICKUP,
  SET_LAST_VALUES,
  ESHOP_GIFTS,
  SET_CART_INFO_TEXT,
  SET_CART1_UPSELL,
} from './constants';

const INITIAL_STATE: any = {};

const mapDeliveryOptionsById = (deliveryOptions) => {
  return deliveryOptions.reduce((acc, deliveryOption) => {
    return {
      ...acc,
      [deliveryOption.delivery_id]: {
        ...deliveryOption,
      },
    };
  }, {});
};

export const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_CART_DATA:
    case REQUEST_CREATE_NEW_CART:
    case REQUEST_UPDATE_CART_DATA:
    case CART_IS_FETCHING: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case RECEIVE_CREATE_NEW_CART_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        cartData: {
          ...state.cartData,
          id: action.payload.id,
        },
      };
    }

    case RECEIVE_CART_DATA_SUCCESSFUL: {
      return {
        ...state,
        isFetching: false,
        cartData: action.payload.cartData,
      };
    }

    case RECEIVE_CART_DATA_ERROR:
    case RECEIVE_CREATE_NEW_CART_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    case REQUEST_DELIVERY_AND_PAYMENT_OPTIONS: {
      return {
        ...state,
        deliveryAndPaymentOptions: {
          ...state.deliveryAndPaymentOptions,
          isFetching: true,
        },
      };
    }
    case RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_SUCCESS: {
      return {
        ...state,
        deliveryAndPaymentOptions: {
          ...state.deliveryAndPaymentOptions,
          isFetching: false,
          data: mapDeliveryOptionsById(action.payload.data),
        },
      };
    }
    case RECEIVE_DELIVERY_AND_PAYMENT_OPTIONS_ERROR: {
      return {
        ...state,
        deliveryAndPaymentOptions: {
          ...state.deliveryAndPaymentOptions,
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case RECEIVE_UPDATE_CART_DATA_ERROR: {
      return {
        ...state,
        cartData: {},
        isFetching: false,
        error: action.payload.error,
      };
    }

    case RECEIVE_UPDATE_CART_DATA_SUCCESSFUL: {
      return {
        ...state,
        isFetching: false,
        cartData: {
          ...action.payload.updatedCartData,
        },
      };
    }

    case REQUEST_PRODUCT_IN_CART_COUNT_UPDATE:
    case REQUEST_PRODUCT_IN_CART_DELETE: {
      return {
        ...state,
        productInCartUpdating: true,
      };
    }

    case RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_SUCCESS:
    case RECEIVE_PRODUCT_IN_CART_DELETE_SUCCESS: {
      return {
        ...state,
        productInCartUpdating: false,
        cartData: {
          ...state.cartData,
          ...action.payload.updatedCart,
        },
      };
    }

    case RECEIVE_PRODUCT_IN_CART_COUNT_UPDATE_ERROR:
    case RECEIVE_PRODUCT_IN_CART_DELETE_ERROR: {
      return {
        ...state,
        productInCartUpdating: false,
        cartData: {},
        error: action.payload.error,
      };
    }

    case REQUEST_FREE_DELIVERY_INFO: {
      return {
        ...state,
        freeDelivery: {
          ...state.freeDelivery,
          isFetching: true,
        },
      };
    }

    case RECEIVE_FREE_DELIVERY_DATA_SUCCESS: {
      return {
        ...state,
        freeDelivery: {
          ...state.freeDelivery,
          isFetching: false,
          freeDeliveryData: action.payload.freeDeliveryData,
        },
      };
    }

    case RECEIVE_FREE_DELIVERY_DATA_ERROR: {
      return {
        ...state,
        freeDelivery: {
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case REQUEST_ADD_GIFTCARD:
    case REQUEST_DELETE_GIFTCARD: {
      return {
        ...state,
        giftcardIsFetching: true,
      };
    }

    case RECEIVE_ADD_GIFTCARD_ERROR:
    case RECEIVE_DELETE_GIFTCARD_ERROR: {
      return {
        ...state,
        giftcardIsFetching: false,
        error: action.payload.error,
      };
    }

    case RECEIVE_ADD_GIFTCARD_SUCCESS:
    case RECEIVE_DELETE_GIFTCARD_SUCCESS: {
      return {
        ...state,
        giftcardIsFetching: false,
        cart: {
          ...action.payload.updatedCart,
        },
        error: {
          ...action.payload.error,
        },
      };
    }

    case SET_NEW_DELIVERY_OPTION: {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          delivery: {
            id: action.payload.deliveryId,
          },
          payment: null,
        },
      };
    }

    case SET_NEW_PAYMENT_OPTION: {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          payment: {
            id: action.payload.paymentId,
          },
        },
      };
    }

    case SET_STEP: {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          step: action.payload.step,
        },
      };
    }

    case CHANGE_CONTACT_FORM_INPUT_VALUE: {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          delivery_address: {
            ...state.cartData.delivery_address,
            person: {
              ...state.cartData.delivery_address.person,
              [action.payload.inputName]: action.payload.inputValue,
            },
          },
          billing_address: {
            ...state.cartData.billing_address,
            person: {
              ...state.cartData.billing_address.person,
              [action.payload.inputName]: action.payload.inputValue,
            },
          },
        },
      };
    }

    case CHANGE_COMPANY_FORM_INPUT_VALUE: {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          company: {
            ...state.cartData.company,
            [action.payload.inputName]: action.payload.inputValue,
          },
        },
      };
    }

    case CHANGE_BILLING_FORM_INPUT_VALUE: {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          delivery_address: {
            ...state.cartData.delivery_address,
            [action.payload.inputName]: action.payload.inputValue,
          },
          billing_address: {
            ...state.cartData.billing_address,
            [action.payload.inputName]: action.payload.inputValue,
          },
        },
      };
    }

    case CHANGE_CHECKBOX_VALUE: {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          [action.payload.checkboxName]: !state.cartData[
            action.payload.checkboxName
          ],
        },
      };
    }

    case CHANGE_CART_DATA_VALUE: {
      return {
        ...state,
        cartData: {
          ...state.cartData,
          [action.payload.name]: action.payload.value,
        },
      };
    }

    case REQUEST_CREATE_ORDER: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case RECEIVE_CREATE_ORDER_SUCCESS: {
      return {
        isFetching: false,
      };
    }

    case RECEIVE_CREATE_ORDER_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    case REQUEST_GIFTS: {
      return {
        ...state,
        isFetchingGifts: true,
      };
    }

    case RECEIVE_GIFTS_SUCCESS: {
      return {
        ...state,
        gifts: { isFetchingGifts: false, data: action.payload.gifts },
      };
    }

    case RECEIVE_GIFTS_ERROR: {
      return {
        ...state,
        gifts: { isFetchingGifts: false, error: action.payload.error },
      };
    }

    case CHANGE_GIFT: {
      const { gift } = action.payload;
      const newChosenGift = gift
        ? {
            ...state.cartData.chosen_gift,
            product_id: gift,
          }
        : null;

      return {
        ...state,
        cartData: {
          ...state.cartData,
          chosen_gift: newChosenGift,
        },
      };
    }

    case ESHOP_GIFTS: {
      return {
        ...state,
        eshopGifts: action.payload.eshopGifts,
      };
    }

    case CLOSE_MODAL: {
      return {
        ...state,
        modal: action.payload,
      };
    }

    case OPEN_MODAL: {
      return {
        ...state,
        modal: action.payload.modalData,
      };
    }

    case SET_CART: {
      return {
        ...state,
        cart: action.payload.cart,
      };
    }

    case SET_CUSTOMER_DETAILS_LOADER: {
      return {
        ...state,
        customerDetailsLoader: action.payload.customerDetailsLoader,
      };
    }

    case SET_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: action.payload.customerDetails,
      };
    }

    case SET_DPD_DELIVERY_POINTS: {
      return {
        ...state,
        deliveryPoints: action.payload.deliveryPoints,
      };
    }

    case SET_DPD_DELIVERY_POINT: {
      return {
        ...state,
        deliveryPoint: action.payload.deliveryPoint,
      };
    }

    case SET_PACKETA_DELIVERY_POINT: {
      return {
        ...state,
        packetaDeliveryPoint: action.payload.deliveryPoint,
      };
    }

    case RECEIVE_ADD_GIFTCARD_SUCCESS: {
      return {
        ...state,
        cart: {
          ...action.payload.updatedCart,
        },
      };
    }

    case SHOW_RECOGNIZED_CUSTOMER_CARD: {
      return {
        ...state,
        recognizedCustomerCard: action.payload.showCustomerCardModal,
      };
    }

    case SET_SHOPS_PERSONAL_PICKUP: {
      return {
        ...state,
        shopsPersonalPickup: action.payload.shopsPersonalPickup,
      };
    }

    case SET_SHOP_PERSONAL_PICKUP: {
      return {
        ...state,
        shopPersonalPickup: action.payload.shopPersonalPickup,
      };
    }

    case SET_LAST_VALUES: {
      return {
        ...state,
        lastValues: action.payload.lastValues,
      };
    }

    case SET_CART_INFO_TEXT: {
      return {
        ...state,
        cartInfoText: action.payload.cartInfoText,
      };
    }

    case SET_CART1_UPSELL: {
      return {
        ...state,
        cart1Upsell: action.payload.cart1Upsell,
      };
    }

    default: {
      return state;
    }
  }
};
