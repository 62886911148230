import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Heading3 } from '../../components/_helpers/Heading/Heading';
import {
  Select,
  SelectStyledStyles,
} from '../../components/_helpers/form/Select';
import { InputStyled } from '../../components/_helpers/form/Input';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import ImagePlaceholder from '../../components/_helpers/Image/ImagePlaceholder';
import {
  countFinalPriceOfProducts,
  formatPrice,
  formatStringDate,
  isSsr,
  isTeacher,
  prop,
  removeHtmlTags,
} from '../../utilities';
import { MainBlock } from './Ucet';
import API, { ThenArg } from '../../services/API';
import ChooseModal from '../../components/_helpers/Modal/ChooseModal';
import ConfirmModal from '../../components/_helpers/Modal/ConfirmModal';
import { push } from 'react-router-redux';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import WarningModal from '../../components/_helpers/Modal/WarningModal';
import {
  loadDashboard,
  loadNotSavedChangesText,
  loadSaveInaccessibleText,
  loadTeacherAvailabilityText,
  setCreatingNewSetState,
  setNewSetState,
} from './actions';
import {
  ButtonCogIcon,
  MyAccountBtnContent,
  MyAccountTextBtn,
} from './MojUcet';
import { SelectedProducts } from './constants';
import { withRouter, WithRouterProps } from 'react-router';
import { URL_SET_EDIT_PRODUCTS } from '../../constants';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { Switch } from '../../components/_helpers/form/Switch';

const Title = styled(Heading3)`
  font-size: ${rem(18)};
  font-weight: 800;
`;

const FormWrapper = styled.div`
  max-width: ${rem(550)};
`;

const TitleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${rem(-5)};
  margin-left: ${rem(-5)};
`;

const TitleCol = styled.div`
  padding-right: ${rem(5)};
  padding-left: ${rem(5)};
`;

const SetFor = styled.span`
  flex: 0 1 auto;
  align-items: center;
  white-space: nowrap;
  padding-top: ${rem(9)};
  margin-right: ${rem(5)};
`;

const SelectSet = styled.div`
  flex: 1 0 auto;
`;

const FirstCol = styled(TitleCol)`
  display: flex;
  flex: 0 0 ${({ theme }) => theme.grid.col.col6};
  margin-bottom: ${rem(10)};
  ${({ theme }) => theme.media('xs')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
  }
  ${({ theme }) => theme.media('sm')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col6};
  }
  ${({ theme }) => theme.media('md')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
  }
`;

const SecondCol = styled(TitleCol)`
  margin-bottom: ${rem(10)};
  flex: 0 0 ${({ theme }) => theme.grid.col.col6};
  ${({ theme }) => theme.media('xs')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
  }
  ${({ theme }) => theme.media('sm')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col6};
  }
  ${({ theme }) => theme.media('md')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
  }
`;

const ThirdCol = styled(TitleCol)`
  flex: 0 0 ${({ theme }) => theme.grid.col.col12};
  ${({ theme }) => theme.media('xs')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
  }
  ${({ theme }) => theme.media('sm')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col12};
  }
  ${({ theme }) => theme.media('md')} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
  }
`;

const StyledSelect = styled(Select)`
  ${SelectStyledStyles};
  display: block;
  width: 100%;
`;

const SourceSelect = styled(Select)`
  ${SelectStyledStyles};
  display: block;
  margin-bottom: ${rem(40)};
`;

const StyledInput = styled(InputStyled)`
  display: block;
  width: 100%;
`;

const Descr = styled.div`
  color: ${({ theme }) => theme.color.gray66};
  font-size: ${rem(12)};
  padding: ${rem(5)}
    ${({ theme }) => rem(theme.form.border.width + theme.form.padding.vertical)};
`;

const Thumb = styled(ImagePlaceholder)``;

const LogTabel = styled.table`
  font-size: ${rem(15)};
  text-align: left;
  color: ${({ theme }) => theme.color.blue};
  width: 100%;
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
`;

const TextArea = styled.textarea`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  width: 100%;
  margin-bottom: ${rem(40)};
`;

const TeacherAvailabilityTextWrapper = styled.div`
  margin-left: ${rem(32)};
`;

const PaddingTopBottom = css`
  tr > th {
    padding-top: ${rem(6)};
    padding-bottom: ${rem(6)};
  }
`;

const Table = styled.table`
  display: block;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin-bottom: ${rem(20)};
  tr > th:first-child,
  tr > td:first-child {
    padding-left: ${rem(18)};
  }
  tr > th:last-child,
  tr > td:last-child {
    padding-right: ${rem(18)};
  }
  ${({ theme }) => theme.media('md')} {
    display: table;
  }
`;

const Thead = styled.thead`
  ${PaddingTopBottom};
  display: block;
  background: ${({ theme }) => theme.color.gray93};
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  ${({ theme }) => theme.media('md')} {
    display: table-header-group;
  }
`;

const Tbody = styled.tbody`
  display: block;
  ${({ theme }) => theme.media('md')} {
    display: table-row-group;
  }
`;

const TFoot = styled.tfoot`
  ${PaddingTopBottom};
  display: block;
  background: ${({ theme }) => theme.color.gray93};
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  ${({ theme }) => theme.media('md')} {
    display: table-footer-group;
  }
`;

const Tr = styled.tr`
  display: block;
  ${Tbody} & {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
    border-left: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
    border-right: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
  &:nth-child(even) {
    background: ${({ theme }) => theme.color.gray97};
  }
  ${({ theme }) => theme.media('md')} {
    display: table-row;
  }
`;

const Th = styled.th`
  display: block;
  text-align: left;
  font-weight: normal;
  ${({ theme }) => theme.media('md')} {
    display: table-cell;
  }
`;

const Td = styled.td`
  display: block;
  ${({ theme }) => theme.media('md')} {
    display: table-cell;
  }
  &.center {
    text-align: center;
  }
`;

const SetName = styled.p`
  margin: 0;
  display: inline-block;
  padding-left: ${rem(8)};
`;

const Small = styled.small`
  display: block;
  color: ${({ theme }) => theme.color.blue};
`;

const RightContent = styled.div`
  ${({ theme }) => theme.media('md')} {
    float: right;
    width: 25%;
  }
  &.right {
    text-align: right;
  }
`;

const LeftContent = styled.div`
  ${({ theme }) => theme.media('md')} {
    float: left;
    width: 75%;
  }
`;

const SetButton = styled(MyAccountTextBtn)`
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.blue};
  &.separation {
    margin-top: ${rem(24)};
  }
`;

const SetButtonIcon = styled(ButtonCogIcon)``;

const SetButtonContent = styled(MyAccountBtnContent)``;

const SetButtonText = styled.p`
  margin: 0 0 0 ${rem(10)};
  display: inline-block;
`;

const NotSets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${rem(32)};
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  margin-bottom: ${rem(20)};
`;

export const getInitialStateNewSet = () => {
  return {
    loader: false,
    loaderSave: false,
    skvelkoSetId: undefined,
    name1: '',
    oldName1: '',
    name2: '',
    oldName2: '',
    name3: '',
    oldName3: '',
    note: '',
    oldNote: '',
    availabilityId: 2,
    oldAvailabilityId: 2,

    modalCreatedOpen: false,
    modalUpdatedOpen: false,
    modalWarningOpen: false,
    modalNotUniqueOpen: false,
    modalNotNameOpen: false,
    modalInaccessibleOpen: false,
    modalNotSavedOpen: false,

    confirmInaccessible: false,
    confirmUnsaved: false,

    selectedSourceId: null,
    oldSelectedSourceId: null,
    selectedProducts: [],
    oldSelectedProducts: [],

    logs: null,
  };
};

export const getProductsFromResponse = (skvelkoSet) => {
  return skvelkoSet.productsWithCount
    ? skvelkoSet.productsWithCount.map((productWithCount) => {
        return {
          count: productWithCount.count
            ? productWithCount.count.toString()
            : '0',
          product: productWithCount.product ? productWithCount.product : null,
        };
      })
    : [];
};

interface NewSetProps {
  dispatch: (a: any) => void;
  setTitle: (title: string, link?: string | null) => void;
  skvelkoSet: any;
  loader: boolean;
  user: any;
  teacherAvailabilityText?: {
    name: string;
    body: string;
  };
  saveInaccessibleText?: {
    name: string;
    body: string;
  };
  notSavedChangesText?: {
    name: string;
    body: string;
  };
  newSetState: NewSetState;
  creatingNewSetState: boolean;
  params: {
    id?: string;
  };
  currentPath: string;
  dashboardData: ThenArg<typeof API.getDashboardData>;
}

export interface NewSetState {
  loader: boolean;
  loaderSave: boolean;
  skvelkoSetId: number | undefined;
  name1: string | undefined;
  oldName1: string | undefined;
  name2: string | undefined;
  oldName2: string | undefined;
  name3: string | undefined;
  oldName3: string | undefined;
  note: string | undefined;
  oldNote: string | undefined;
  availabilityId: number;
  oldAvailabilityId: number;

  modalCreatedOpen: boolean;
  modalUpdatedOpen: boolean;
  modalWarningOpen: boolean;
  modalNotUniqueOpen: boolean;
  modalNotNameOpen: boolean;
  modalInaccessibleOpen: boolean;
  modalNotSavedOpen: boolean;

  confirmInaccessible: boolean;
  confirmUnsaved: boolean;

  selectedSourceId: number | null | undefined;
  oldSelectedSourceId: number | null | undefined;
  selectedProducts: SelectedProducts;
  oldSelectedProducts: SelectedProducts;

  logs: any[] | null;
}

class NewSet extends React.PureComponent<
  NewSetProps & WithRouterProps,
  NewSetState
> {
  public static async getInitialProps(props) {
    const promises: any[] = [];

    if (!props.teacherAvailabilityText) {
      promises.push(props.dispatch(loadTeacherAvailabilityText()));
    }

    if (!props.saveInaccessibleText) {
      promises.push(props.dispatch(loadSaveInaccessibleText()));
    }

    if (!props.notSavedChangesText) {
      promises.push(props.dispatch(loadNotSavedChangesText()));
    }

    if (!props.dashboardData) {
      promises.push(props.dispatch(loadDashboard()));
    }

    await Promise.all(promises);
  }

  constructor(props) {
    super(props);
    this.state = {
      ...getInitialStateNewSet(),
      loader: !!props.params.id,
    };

    this.saveSet = this.saveSet.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.goToSetList = this.goToSetList.bind(this);

    let title;
    if (props.params.id) {
      title = __('Editácia zoznamu');
      props.setTitle(title);
      if (
        !this.props.newSetState ||
        this.props.newSetState.skvelkoSetId !== Number(this.props.params.id)
      ) {
        this.loadSkvelkoSet(props.params.id);
      }
    } else {
      title = __('Nový zoznam');
      props.setTitle(title);
    }
    props.setBreadcrumbsItems([
      { name: __('Správa zoznamov'), url: '/my-account/sets' },
      { name: title, url: props.currentPath },
    ]);
  }

  public componentDidMount() {
    if (!isSsr()) {
      window.addEventListener('beforeunload', this.beforeunload);
    }
    this.props.router.setRouteLeaveHook(
      (this.props as any).route,
      this.leaveConfirmation,
    );

    // Nacitame povodny state, ak v stave mame ulozeny rovnaky state. Pojem rovnaky v tomto pripade znamena, ze
    // sa ID v URL zhoduje s ID v stave (uprava zoznamu), alebo ID v stave je null a zaroven URL neobsahuje
    // ID (vytvaranie noveho zoznam)
    if (
      this.props.newSetState &&
      !this.props.creatingNewSetState &&
      ((!this.props.params.id && !this.props.newSetState.skvelkoSetId) ||
        this.props.newSetState.skvelkoSetId === Number(this.props.params.id))
    ) {
      this.setState({ ...this.props.newSetState });
    }

    this.setState({ confirmUnsaved: false });
    this.props.dispatch(setCreatingNewSetState(false));
    this.openModal(null);
  }

  // docs: https://github.com/ReactTraining/react-router/blob/v3/docs/guides/ConfirmingNavigation.md
  public leaveConfirmation = () => {
    const text = prop(this, 'props.notSavedChangesText.body');
    return !this.state.confirmUnsaved && text && this.changed()
      ? removeHtmlTags(text)
      : true; // Do not show alert
  };

  public componentWillUnmount() {
    this.props.dispatch(setNewSetState(this.state));
    if (!isSsr()) {
      window.removeEventListener('beforeunload', this.beforeunload);
    }
  }

  public beforeunload(e) {
    e.preventDefault();
    e.returnValue = true;
  }

  public async loadSkvelkoSet(id) {
    const skvelkoSetResult = await API.searchSkvelkoSet({
      id,
      limit: 5,
      withGoods: true,
      withProducts: true,
      withLog: true,
    });
    if (skvelkoSetResult.search.length > 0) {
      const skvelkoSet = skvelkoSetResult.search[0];
      const availability = skvelkoSet.skvelkoSetAvailabilityId
        ? skvelkoSet.skvelkoSetAvailabilityId
        : 2;
      this.setState({
        loader: false,
        skvelkoSetId: skvelkoSet.id,
        name1: skvelkoSet.name1,
        oldName1: skvelkoSet.name1,
        name2: skvelkoSet.name2,
        oldName2: skvelkoSet.name2,
        name3: skvelkoSet.name3,
        oldName3: skvelkoSet.name3,
        note: skvelkoSet.note,
        oldNote: skvelkoSet.note,
        availabilityId: availability,
        oldAvailabilityId: availability,
        logs: skvelkoSet.skvelkoSetLogs ? skvelkoSet.skvelkoSetLogs : null,
        oldSelectedProducts: getProductsFromResponse(skvelkoSet),
      });

      if (!this.state.selectedProducts || !this.state.selectedProducts.length) {
        this.setState({
          selectedProducts: getProductsFromResponse(skvelkoSet),
        });
      }
    }
  }

  public onName1Change = (event) => {
    this.setState({
      name1: event.target.value,
    });
  };

  public onName2Change = (event) => {
    this.setState({
      name2: event.target.value,
    });
  };

  public onName3Change = (event) => {
    this.setState({
      name3: event.target.value,
    });
  };

  public onNoteChange = (event) => {
    this.setState({
      note: event.target.value,
    });
  };

  public onSourceSelectChange = (event) => {
    this.setState({
      selectedSourceId:
        event.target.value === -1 || event.target.value === '-1'
          ? null
          : event.target.value,
    });
  };

  public onAvailabilityChange = () => {
    this.setState((state) => ({
      availabilityId: state.availabilityId === 1 ? 2 : 1,
    }));
  };

  public async saveSet() {
    if (
      !this.isTeacher() &&
      this.state.availabilityId === 2 &&
      !this.state.confirmInaccessible
    ) {
      this.openModal('inaccessible');
      return;
    }

    this.setState({ loaderSave: true });
    this.setState({ confirmInaccessible: false });

    const { selectedProducts } = this.state;

    const request = {
      name1: this.state.name1 || '',
      name2: this.state.name2 || '',
      name3: this.state.name3 || '',
      note: this.state.note || '',
      skvelko_set_availability_id: this.state.availabilityId,
      awarded_user_id: null,
      source_id: this.state.selectedSourceId,

      goods: selectedProducts.map((item, i) => {
        return {
          id: undefined,
          good_id: item.product.main_good.good_id,
          count: parseInt(item.count, 10),
          position: i,
        };
      }),
    };

    let skvelkoSetResponse: any;

    if (
      selectedProducts.length > 0 ||
      (this.props.user && this.props.user.fa === true)
    ) {
      if (this.state.skvelkoSetId) {
        skvelkoSetResponse = await API.updateSkvelkoSet(
          this.state.skvelkoSetId,
          {},
          request,
        );

        if (skvelkoSetResponse.status === 'OK') {
          this.setState({
            skvelkoSetId: skvelkoSetResponse.skvelkoSet.id,
          });
          await this.loadSkvelkoSet(this.state.skvelkoSetId);
          this.openModal('updated');
        } else if (skvelkoSetResponse.status === 'ERROR') {
          if (skvelkoSetResponse.type === 'NOT_UNIQUE') {
            this.openModal('not_unique');
          } else if (skvelkoSetResponse.type === 'NOT_NAME') {
            this.openModal('not_name');
          }
        }
      } else {
        skvelkoSetResponse = await API.createSkvelkoSet({}, request);
        if (skvelkoSetResponse.status === 'OK') {
          await this.loadSkvelkoSet(skvelkoSetResponse.skvelkoSet.id);
          this.openModal('created');
        } else if (skvelkoSetResponse.status === 'ERROR') {
          if (skvelkoSetResponse.type === 'NOT_UNIQUE') {
            this.openModal('not_unique');
          } else if (skvelkoSetResponse.type === 'NOT_NAME') {
            this.openModal('not_name');
          }
        }
      }
    } else {
      this.openModal('warning');
    }
    this.setState({ loaderSave: false });
  }

  public openModal(type: string | null) {
    let createdModal = false;
    let updateModal = false;
    let warningModal = false;
    let notUniqueModal = false;
    let notNameModal = false;
    let inaccessibleModal = false;
    let notSavedModal = false;

    switch (type) {
      case 'created':
        createdModal = true;
        break;
      case 'updated':
        updateModal = true;
        break;
      case 'warning':
        warningModal = true;
        break;
      case 'not_unique':
        notUniqueModal = true;
        break;
      case 'not_name':
        notNameModal = true;
        break;
      case 'inaccessible':
        inaccessibleModal = true;
        break;
      case 'not_saved':
        notSavedModal = true;
        break;
    }

    this.setState({
      modalCreatedOpen: createdModal,
      modalUpdatedOpen: updateModal,
      modalWarningOpen: warningModal,
      modalNotUniqueOpen: notUniqueModal,
      modalNotNameOpen: notNameModal,
      modalInaccessibleOpen: inaccessibleModal,
      modalNotSavedOpen: notSavedModal,
    });
  }

  public closeSetsModal = (e) => {
    this.openModal(null);
    e.preventDefault();
    this.props.dispatch(push('/my-account/sets'));
  };

  public closeModal = () => {
    this.openModal(null);
  };

  public confirmInaccessible = () => {
    this.setState({ confirmInaccessible: true });
    this.confirmSaveSet();
  };

  public confirmSaveSet = () => {
    this.closeModal();
    this.setState({ confirmUnsaved: true });
    setTimeout(() => {
      this.saveSet().then();
    }, 100);
  };

  public goToSetList = () => {
    this.closeModal();
    this.setState({ skvelkoSetId: undefined }); // vynutit obnovenie zakladneho stavu
    setTimeout(() => {
      this.props.dispatch(push('/my-account/sets'));
    }, 100);
  };

  public goToEditProducts = () => {
    this.setState({ confirmUnsaved: true }, () => {
      this.props.dispatch(push(URL_SET_EDIT_PRODUCTS));
    });
  };

  public confirmBack = () => {
    this.setState({ confirmUnsaved: true });
    this.goToSetList();
  };

  public reorder = (array, startIndex, endIndex) => {
    const [removed] = array.splice(startIndex, 1);
    array.splice(endIndex, 0, removed);
    return array;
  };

  public isTeacher(): boolean {
    return isTeacher(this.props.user);
  }

  public changedProduct(item, products): boolean {
    const currentItem = products.find(
      (selectedProduct) =>
        selectedProduct.product.product_id === item.product.product_id,
    );
    return !currentItem || currentItem.count !== item.count;
  }

  public changedProducts(): boolean {
    return (
      this.state.oldSelectedProducts.some((item) => {
        return this.changedProduct(item, this.state.selectedProducts);
      }) ||
      this.state.selectedProducts.some((item) => {
        return this.changedProduct(item, this.state.oldSelectedProducts);
      }) ||
      this.state.selectedProducts
        .map((item) => item.product.product_id)
        .join() !==
        this.state.oldSelectedProducts
          .map((item) => item.product.product_id)
          .join()
    );
  }

  public changed(): boolean {
    const {
      name1,
      oldName1,
      name2,
      oldName2,
      name3,
      oldName3,
      note,
      oldNote,
      availabilityId,
      oldAvailabilityId,
      //selectedSourceId,
    } = this.state;

    return (
      name1 !== oldName1 ||
      name2 !== oldName2 ||
      name3 !== oldName3 ||
      note !== oldNote ||
      availabilityId !== oldAvailabilityId ||
      //selectedSourceId !== oldSelectedSourceId || // oldSelectedSourceId is never loaded
      this.changedProducts()
    );
  }

  public back = () => {
    if (this.changed()) {
      this.openModal('not_saved');
    } else {
      this.goToSetList();
    }
  };

  public render() {
    const placeholder = '/images/placeholder-46.png';
    const placeholder2x = '/images/placeholder-46@2x.png';

    const sourceItems = [
      {
        value: 3,
        name: 'Škola',
      },
      {
        value: 1,
        name: 'Obchodný zástupca',
      },
      {
        value: 2,
        name: 'Web školy',
      },
    ];

    const isAdmin = this.props.user && this.props.user.fa === true;
    const teacher = this.isTeacher();

    const setPrice = countFinalPriceOfProducts(this.state.selectedProducts);

    const schoolEmail = prop(
      this.props.dashboardData,
      'organization.contactPerson.email',
    );

    const available = this.state.availabilityId === 1;

    return (
      <Loader loading={this.state.loader}>
        <MainBlock className={'no-top-padding'}>
          {this.state.modalCreatedOpen && (
            <ConfirmModal
              title={__('Zoznam bol úspešne vytvorený')}
              buttonText={__('OK')}
              onButtonClick={this.closeSetsModal}
              onCloseButtonClick={this.closeSetsModal}
            />
          )}

          {this.state.modalUpdatedOpen && (
            <ConfirmModal
              title={__('Zoznam bol úspešne zmenený')}
              buttonText={__('OK')}
              onButtonClick={this.closeSetsModal}
              onCloseButtonClick={this.closeSetsModal}
            />
          )}

          {this.state.modalWarningOpen && (
            <WarningModal
              title={__('Upozornenie')}
              bodyText={__(
                'Zoznam nie je možné uložiť pretože neobsahuje žiadne produkty',
              )}
              buttonText={__('OK')}
              onButtonClick={this.closeModal}
              onCloseButtonClick={this.closeModal}
            />
          )}

          {this.state.modalNotUniqueOpen && (
            <WarningModal
              title={__('Upozornenie')}
              bodyText={__(
                'Zoznam nie je možné uložiť, pretože zoznam s rovnakým názvom sa už v systéme nachádza. Ak chcete obnoviť zmazaný zoznam, vytvorte nový s rovnakým názvom.',
              )}
              buttonText={__('OK')}
              onButtonClick={this.closeModal}
              onCloseButtonClick={this.closeModal}
            />
          )}

          {this.state.modalNotNameOpen && (
            <WarningModal
              title={__('Upozornenie')}
              bodyText={__(
                'Zoznam musí mať vyplnený prívlastok, alebo ročník a zaroveň triedu.',
              )}
              buttonText={__('OK')}
              onButtonClick={this.closeModal}
              onCloseButtonClick={this.closeModal}
            />
          )}

          {this.state.modalInaccessibleOpen &&
            !teacher &&
            this.props.saveInaccessibleText && (
              <ChooseModal
                title={this.props.saveInaccessibleText.name}
                bodyText={this.props.saveInaccessibleText.body}
                button1Text={__('Neuložiť')}
                button2Text={__('Uložiť')}
                onButton1Click={this.closeModal}
                onButton2Click={this.confirmInaccessible}
                onCloseButtonClick={this.closeModal}
              />
            )}

          {this.state.modalNotSavedOpen && this.props.notSavedChangesText && (
            <ChooseModal
              title={this.props.notSavedChangesText.name}
              bodyText={this.props.notSavedChangesText.body}
              button1Text={__('Naspať')}
              button2Text={__('Uložiť')}
              onButton1Click={this.confirmBack}
              onButton2Click={this.confirmSaveSet}
              onCloseButtonClick={this.closeModal}
            />
          )}

          <Title>{__('Názov zoznamu')}</Title>
          <FormWrapper>
            <TitleRow>
              <FirstCol>
                <SetFor>{__('Zoznam pre')}</SetFor>
                <SelectSet>
                  <StyledSelect
                    name="nr"
                    value={this.state.name1}
                    items={[
                      { value: '' },
                      { value: '1.' },
                      { value: '2.' },
                      { value: '3.' },
                      { value: '4.' },
                      { value: '5.' },
                      { value: '6.' },
                      { value: '7.' },
                      { value: '8.' },
                      { value: '9.' },
                    ]}
                    onChange={this.onName1Change}
                  />
                </SelectSet>
              </FirstCol>
              <SecondCol>
                <StyledSelect
                  name="name"
                  value={this.state.name2}
                  items={[
                    { value: '' },
                    { value: __('ročník') },
                    { value: 'A' },
                    { value: 'B' },
                    { value: 'C' },
                    { value: 'D' },
                    { value: 'E' },
                    { value: 'F' },
                    { value: 'G' },
                    { value: 'H' },
                  ]}
                  onChange={this.onName2Change}
                />
              </SecondCol>
              <ThirdCol>
                <StyledInput
                  name="descr"
                  value={this.state.name3}
                  placeholder={__('Nepovinný prívlastok názvu')}
                  onChange={this.onName3Change}
                />
                <Descr>{__('napr. Výtvarná výchova')}</Descr>
              </ThirdCol>
            </TitleRow>
          </FormWrapper>
          <Title>{__('Stav dostupnosti pre objednávanie')}</Title>
          <Switch
            disabled={teacher}
            onChange={this.onAvailabilityChange}
            label={
              available
                ? __('Zobrazené rodičom na daffer.sk')
                : __('Nezobrazené rodičom na daffer.sk')
            }
            checked={available}
          />
          {!available &&
            teacher &&
            this.props.teacherAvailabilityText &&
            schoolEmail && (
              <TeacherAvailabilityTextWrapper
                dangerouslySetInnerHTML={{
                  __html: this.props.teacherAvailabilityText.body.replace(
                    /{{ SCHOOL_EMAIL }}/g,
                    schoolEmail,
                  ),
                }}
              />
            )}
          <Title>{__('Obsah zoznamu')}</Title>

          {this.state.selectedProducts && this.state.selectedProducts.length ? (
            <Table>
              <Thead>
                <Tr>
                  <Th colSpan={2}>{__('Produkty')}</Th>
                  <Th>{__('Počet')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.selectedProducts.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td style={{ width: 40 }}>{`${index + 1}.`}</Td>
                      <Td>
                        <Thumb
                          src={item.product.picture}
                          placeholder={placeholder}
                          retinaPlaceholder={placeholder2x}
                          w={32}
                          h={32}
                        />
                        <SetName>{item.product.name}</SetName>
                      </Td>
                      <Td style={{ width: 40 }} className={'center'}>
                        {item.count}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
              <TFoot>
                <Tr>
                  <Th colSpan={3}>
                    <LeftContent>
                      {__('Celková cena za zoznam')}:{' '}
                      <strong>{formatPrice(setPrice, 'EUR')}</strong>
                      <Small>
                        {__(
                          '(cena sa môže zmeniť ak je niektorý produkt v akcii alebo sa zmení jeho cena)',
                        )}
                      </Small>
                    </LeftContent>
                    <RightContent className={'right'}>
                      <SetButton onClick={this.goToEditProducts}>
                        <SetButtonContent>
                          <SetButtonIcon icon="cog" />
                          <SetButtonText>{__('Upraviť obsah')}</SetButtonText>
                        </SetButtonContent>
                      </SetButton>
                    </RightContent>
                  </Th>
                </Tr>
              </TFoot>
            </Table>
          ) : (
            <NotSets>
              {__('Zoznam zatiaľ neobsahuje žiaden produkt.')}
              <SetButton
                className={'separation'}
                onClick={this.goToEditProducts}
              >
                <SetButtonContent>
                  <SetButtonIcon icon="cog" />
                  <SetButtonText>{__('Pridať produkty')}</SetButtonText>
                </SetButtonContent>
              </SetButton>
            </NotSets>
          )}

          {this.props.user && this.props.user.fa === true ? (
            <React.Fragment>
              <Title>Obsah cez</Title>
              <SourceSelect
                name="SourceSelect"
                value={this.state.selectedSourceId}
                items={sourceItems}
                onChange={this.onSourceSelectChange}
              />
            </React.Fragment>
          ) : (
            ''
          )}
          {isAdmin && this.state.logs ? (
            <React.Fragment>
              <Title>{__('Daffer poznamka')}</Title>
              <TextArea value={this.state.note} onChange={this.onNoteChange} />
            </React.Fragment>
          ) : (
            ''
          )}
          <Loader loading={this.state.loaderSave}>
            {this.changed() && (
              <ButtonStyled className="primary lg" onClick={this.saveSet}>
                {__('Uložiť zoznam')}
              </ButtonStyled>
            )}
          </Loader>
          {isAdmin && this.state.logs ? (
            <div>
              <Title>Archív zmien sady</Title>
              <LogTabel>
                <thead>
                  <tr>
                    <th>Dátum</th>
                    <th>Názov</th>
                    <th>Ǔčet</th>
                    <th>Cena</th>
                    <th>Položiek</th>
                    <th>Stav</th>
                    <th>Obsah cez</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.logs.map((log) =>
                    log.log.id &&
                    log.log.created_at &&
                    log.log.user &&
                    log.log.price &&
                    log.log.itemsCount &&
                    log.log.availability ? (
                      <tr key={log.log.id}>
                        <td>{formatStringDate(log.log.created_at)}</td>
                        <td>{log.log.name || ''}</td>
                        <td>{log.log.user}</td>
                        <td>{log.log.price}</td>
                        <td>{log.log.itemsCount}</td>
                        <td>{log.log.availability}</td>
                        <td>{log.log.created_by || ''}</td>
                      </tr>
                    ) : (
                      ''
                    ),
                  )}
                </tbody>
              </LogTabel>
            </div>
          ) : (
            ''
          )}
        </MainBlock>
      </Loader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    teacherAvailabilityText: state.myAccount.teacherAvailabilityText,
    saveInaccessibleText: state.myAccount.saveInaccessibleText,
    notSavedChangesText: state.myAccount.notSavedChangesText,
    newSetState: state.myAccount.newSetState,
    creatingNewSetState: state.myAccount.creatingNewSetState,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    dashboardData: state.myAccount.dashboardData,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'NewSet' })(withRouter(NewSet)),
);
