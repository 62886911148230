import { formatStringDate, parseTextWithShortcode } from '../../../utilities';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { Content } from '../../../containers/CMS/CMS';
import { FacebookProvider, Like } from 'react-facebook';
import ProductPreview from '../../_helpers/product/ProductPreview';
import { __ } from 'react-i18n/lib';
import {
  ProductColWrapper,
  ProductsRowWrapper,
} from '../../../containers/Category/Category';
import { Title } from '../Page/ContentContainer';
import ContestGeneratedForm from '../../ContestGeneratedForm/ContestGeneratedForm';
import MetaTags from '../../MetaTags/MetaTags';

const ProductsHeading = styled.h1`
  font-size: ${rem(30)};
  color: #211e1f;
  font-weight: 700;
  &:after {
    content: ' ';
    display: block;
    border: ${rem(2)} solid ${({ theme }) => theme.color.secondary};
    width: ${rem(125)};
  }
`;

const ArticleDate = styled.div`
  color: gray;
`;

const ProductsWrapper = styled.div`
  clear: left;
`;

interface Props {
  contentData: any;
  connectedProducts?: any;
  location?: any;
}

class ArticleSingle extends React.PureComponent<Props> {
  public render() {
    const facebookId = process.env.REACT_APP_FACEBOOK_APP_ID;
    let href: any = null;
    if (window && window.location && window.location.href) {
      href = window.location.href;
    } else if (this.props.location) {
      href = this.props.location.pathname;
    }

    const metaTags = {};
    if (this.props.contentData?.draft) {
      metaTags['robots'] = 'noindex';
    }

    return (
      <div>
        <MetaTags tags={metaTags} />
        <Title>{this.props.contentData.name}</Title>
        <ArticleDate>
          {' '}
          {formatStringDate(this.props.contentData.custom_date)}{' '}
        </ArticleDate>

        <>
          {parseTextWithShortcode(
            this.props?.contentData?.json_content?.body,
          ).map((item: any) => {
            if (item.type === 'text') {
              return (
                <Content
                  dangerouslySetInnerHTML={{
                    __html: item.value,
                  }}
                />
              );
            } else if (item.type === 'formular-sutaz') {
              return (
                <ContestGeneratedForm
                  nameJson={item.parameters.name}
                  customJson={item.parameters.custom}
                />
              );
            }

            return '';
          })}
        </>

        {facebookId && href ? (
          <>
            <FacebookProvider appId={facebookId}>
              <Like
                href={href}
                colorScheme="dark"
                showFaces={true}
                share={true}
              />
            </FacebookProvider>
          </>
        ) : (
          ''
        )}

        {this.props.connectedProducts &&
        this.props.connectedProducts.products &&
        this.props.connectedProducts.products.length ? (
          <ProductsWrapper>
            <ProductsHeading>
              {__('Odporúčané produkty v eshope')}
            </ProductsHeading>
            <ProductsRowWrapper>
              {this.props.connectedProducts.products.map((product) => {
                return (
                  <ProductColWrapper key={product.product_id}>
                    <ProductPreview product={product} />
                  </ProductColWrapper>
                );
              })}
            </ProductsRowWrapper>
          </ProductsWrapper>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default ArticleSingle;
