import * as React from 'react';
import { Link } from 'react-router';
import SwiperSlider from 'react-id-swiper';
import styled from 'styled-components';
import { rem } from 'polished';
import { Container } from '../../../theme/libraries/grid';
import ImagePlaceholder from '../../_helpers/Image/ImagePlaceholder';
import { Media, MediaContextProvider } from '../../../theme/media';
import { filterBannersByUser } from '../../../utilities';

const SwiperWrapper: any = styled.div`
  display: block;
  ${({ theme }) => theme.media(1024)} {
    padding-top: ${rem(10)};
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: ${rem(20)};
  }

  ${(props: any) =>
    props.device === 'phone'
      ? `
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: ${rem(7)};
    }
    
    .swiper-pagination-bullet:not(.swiper-pagination-bullet-active)  {
      background: black;
      opacity: 0.32;
    }
  `
      : ''};

  .swiper-button-prev,
  .swiper-button-next {
    @media (max-width: ${rem(425)}) {
      width: ${rem(32)};
      height: ${rem(32)};
    }
  }

  ${({ theme }) => theme.media('lg')} {
    .swiper-button-prev {
      left: ${rem(110)};
    }

    .swiper-button-next {
      right: ${rem(110)};
    }
  }

  @media (max-width: ${rem(1024)}) {
    .swiper {
      padding: 0;
    }
  }
`;

const SwiperContainer = styled(Container)`
  &:before,
  &:after {
    display: block;
    content: '';
  }
`;

const SlideWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.color.white};
  max-width: ${rem(1170)};
  margin: auto;
`;

const LinkUrl = styled(Link)`
  margin: auto;
`;

const DivLink = styled.div`
  margin: auto;
`;

const Image = styled(ImagePlaceholder)`
  display: block;
  padding: 0;
`;

interface SwiperSlideProps {
  title: string;
  descr?: string;
  url?: string;
  image: string;
  text?: string;
}

class SwiperSlide extends React.Component<SwiperSlideProps> {
  public render() {
    const { title, url, image } = this.props;
    return (
      <SlideWrapper>
        {url ? (
          <LinkUrl to={url} title={title}>
            <Image
              src={image}
              alt={title}
              notLazyloading={true}
              notResize={true}
              notWebp={true}
              className="swiper-lazy"
            />
          </LinkUrl>
        ) : (
          <DivLink title={title}>
            <Image
              src={image}
              alt={title}
              notLazyloading={true}
              notResize={true}
              notWebp={true}
              className="swiper-lazy"
            />
          </DivLink>
        )}
        <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
      </SlideWrapper>
    );
  }
}

interface SwiperSlidesProps {
  banners: any[];
  device?: string;
  user: any;
}

class SwiperSlides extends React.Component<SwiperSlidesProps> {
  public render() {
    const { banners, device } = this.props;

    const params = {
      navigation: {
        nextEl: `.swiper-button-next`,
        prevEl: `.swiper-button-prev`,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      loop: true,
      lazy: true,
    };

    return (
      <SwiperWrapper device={device}>
        <SwiperContainer className="swiper">
          <SwiperSlider {...params}>
            {(banners || [])
              .filter((b) => b.device === device)
              .map((banner, i) => {
                return (
                  <div key={i}>
                    <SwiperSlide
                      title={banner.b_name}
                      descr={banner.b_description}
                      url={banner.b_url}
                      text={banner.b_text}
                      image={banner.b_path}
                    />
                  </div>
                );
              })}
          </SwiperSlider>
        </SwiperContainer>
      </SwiperWrapper>
    );
  }
}

interface SwiperProps {
  renderMediaContextProvider?: boolean;
}

class BannerSwiper extends React.Component<SwiperProps & SwiperSlidesProps> {
  public render() {
    const { banners, renderMediaContextProvider, user } = this.props;

    if (!banners?.length) {
      return '';
    }

    const filteredBanners = filterBannersByUser(banners, user);

    const renderSwiperMedia = () => (
      <>
        <Media lessThan="swiperThreshold">
          <SwiperSlides
            banners={filteredBanners}
            device={'phone'}
            user={user}
          />
        </Media>
        <Media greaterThanOrEqual="swiperThreshold">
          <SwiperSlides
            banners={filteredBanners}
            device={'desktop'}
            user={user}
          />
        </Media>
      </>
    );

    return renderMediaContextProvider ? (
      <MediaContextProvider>{renderSwiperMedia()}</MediaContextProvider>
    ) : (
      renderSwiperMedia()
    );
  }
}

export default BannerSwiper;
