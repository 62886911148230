import { rem } from 'polished';
import {
  ButtonStyledStyles,
  ButtonStyles,
} from '../../../_helpers/form/Button';
import styled, { css } from 'styled-components';

export const CategoryTextStyle = css`
  font-size: ${rem(14)};
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(16)};
  }
`;

export const MoreButton = styled.a`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${CategoryTextStyle};
`;
