import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import { Content, Title } from '../../components/_layout/Page/ContentContainer';
import { Heading2 } from '../../components/_helpers/Heading/Heading';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { Buttons } from './Login';
import { ContentContainerBlock } from '../../components/_helpers/Contact/ContactStyles';
import { Form, Field } from 'react-final-form';
import Alert from '../../components/Alert/Alert';
import API from '../../services/API';
import { Container } from '../../theme/libraries/grid';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { composeValidators, required } from '../../utilities/validators';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';
import { URL_LOGIN } from '../../constants';
import { InputStyled } from '../../components/_helpers/form/Input';

const Wrapper = styled(Content)`
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(40)};
  }
`;

export const Heading = styled(Heading2)`
  margin-top: 0;
  margin-bottom: ${rem(40)};
`;

const Input = styled(InputStyled)`
  display: block;
  width: 100%;
  margin-bottom: ${rem(10)};
`;

const AlertWrapper = styled.div`
  margin-top: ${rem(10)};
  margin-bottom: ${rem(10)};
`;

const FormWrapper = styled.div`
  margin-bottom: ${rem(30)};
`;

const InfoWrapper = styled.div`
  margin-bottom: ${rem(15)};
`;

const SubTitle = styled.div`
  margin-bottom: ${rem(15)};
`;

const EmailWrapper = styled.span`
  color: red;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
  }
`;

const FormColumn = styled.div`
  width: 100%;
  ${({ theme }) => theme.media('sm')} {
    width: 48%;
  }
`;

const InputLabel = styled.div`
  margin-bottom: ${rem(5)};
`;

interface Props {
  dispatch: any;
  text?: {
    body: string;
  };
}

const ForgottenPasswordCompany = (props: Props) => {
  const [response, setResponse] = React.useState<boolean | null>(null);

  let searchParams: any = null;
  if (window) {
    searchParams = new URLSearchParams(window.location.search);
  }

  const onSubmit = async (values) => {
    if (searchParams) {
      try {
        const response = await API.lostPasswordCompany(
          {},
          {
            ico: searchParams.get('ico'),
            email_manager: searchParams.get('email'),
            name: values['name'],
            email: values['email'],
            phone: values['phone'],
          },
        );
        if (response.status == 'OK') {
          setResponse(true);
        } else {
          setResponse(false);
        }
      } catch (e) {
        setResponse(false);
      }
    }
  };

  return (
    <Container className="base">
      <Wrapper>
        <ContentContainerBlock>
          <Breadcrumbs
            items={[
              { name: 'Môj účet', url: URL_LOGIN },
              { name: 'Zabudnuté heslo', url: '/zabudnute-heslo' },
            ]}
          />
          <Title>{__('Získanie prístupu do účtu organizácie')}</Title>

          {response === true ? (
            <AlertWrapper>
              <Alert type="success"> {__('Vašu žiadosť sme odoslali')} </Alert>
            </AlertWrapper>
          ) : (
            ''
          )}

          {response === false ? (
            <AlertWrapper>
              <Alert type="error">
                {' '}
                {__('Vašu žiadosť sa nepodarilo odoslať')}{' '}
              </Alert>
            </AlertWrapper>
          ) : (
            ''
          )}

          <InfoWrapper>
            <div>
              {' '}
              {__('IČO organizácie')}:{' '}
              <b>{searchParams ? searchParams.get('ico') : ''}</b>{' '}
            </div>
            <div>
              {' '}
              {__('Email správcu účtu')}:{' '}
              <EmailWrapper>
                <b>{searchParams ? searchParams.get('email') : ''}</b>
              </EmailWrapper>{' '}
            </div>
            <div>
              {' '}
              {__(
                'Ak potrebujete získať prístup do účtu organizácie zaslaním nového hesla, ale nemáte prístup k emailu správcu účtu, vyplňte, prosím, nasledovný formulár. Budeme Vás kontaktovať a pomôžeme Vám vyriešiť Vašu situáciu.',
              )}
            </div>
          </InfoWrapper>

          <SubTitle>
            <b>Kontaktná osoba:</b>
          </SubTitle>

          <Form
            onSubmit={onSubmit}
            // tslint:disable-next-line:jsx-no-lambda
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormWrapper>
                  <FormRow>
                    <FormColumn>
                      <Field name="name" validate={composeValidators(required)}>
                        {({ input, meta }) => (
                          <>
                            <InputLabel> {__('Meno')}: </InputLabel>
                            <Input type="input" {...input} className="lg" />
                            {meta.error && meta.touched && (
                              <ErrorPopup>{meta.error}</ErrorPopup>
                            )}
                          </>
                        )}
                      </Field>
                    </FormColumn>
                    <FormColumn>
                      <Field
                        name="email"
                        validate={composeValidators(required)}
                      >
                        {({ input, meta }) => (
                          <>
                            <InputLabel> {__('Email')}: </InputLabel>
                            <Input type="input" {...input} className="lg" />
                            {meta.error && meta.touched && (
                              <ErrorPopup>{meta.error}</ErrorPopup>
                            )}
                          </>
                        )}
                      </Field>
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn>
                      <Field
                        name="phone"
                        validate={composeValidators(required)}
                      >
                        {({ input, meta }) => (
                          <>
                            <InputLabel> {__('Telefón')}: </InputLabel>
                            <Input type="input" {...input} className="lg" />
                            {meta.error && meta.touched && (
                              <ErrorPopup>{meta.error}</ErrorPopup>
                            )}
                          </>
                        )}
                      </Field>
                    </FormColumn>
                  </FormRow>
                </FormWrapper>

                <Buttons>
                  <ButtonStyled
                    disabled={response === true ? true : false}
                    className="primary lg"
                    type="submit"
                  >
                    {__('Odoslať')}
                  </ButtonStyled>
                </Buttons>
              </form>
            )}
          />
        </ContentContainerBlock>
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    text: state.login.forgottenPasswordText,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ForgottenPasswordCompany' })(
    ForgottenPasswordCompany,
  ),
);
