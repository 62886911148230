import * as actionTypes from './constants';

export const setFaq = faq => ({
  type: actionTypes.SET_FAQ,
  payload: {
    faq,
  },
});

export const loadFaq = () => {
  return async (dispatch, getState, API) => {
    try {
      const faq = await API.loadArticles({
        sitemapId: 85,
      });

      dispatch(setFaq(faq));
    } catch (e) {
      console.log(e);
    }
  };
};
