import * as React from 'react';
import { Helmet } from 'react-helmet';

// Online chat script
const SmartsuppChat = () => {
  return (
    <Helmet>
      <script type="text/javascript">
        {`
      var _smartsupp = _smartsupp || {};
      _smartsupp.key = '31b92bcfc7d154465cfbac2499f8f7a3d2b9567e';
      window.smartsupp||(function(d) {
      var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
      s=d.getElementsByTagName('script')[0];c=d.createElement('script');
      c.type='text/javascript';c.charset='utf-8';c.async=true;
      c.onload = function() { setTimeout(function(){ 
          var iframe = document.getElementById('chat-application-iframe');
          var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
          var button = innerDoc.querySelectorAll('[data-cy]')[0];
          if(button) {
            button.click();
          }
      }, 3000);};
      c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
    })(document);
    `}
      </script>
    </Helmet>
  );
};

export default SmartsuppChat;
