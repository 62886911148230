// import { createSelector } from 'reselect';
// import { prop } from '../../utilities';

// const cartDomainSelector = state => state.cart;

// export const cartTotalPriceSelector = createSelector(
//   cartDomainSelector,
//   substate => prop(substate, 'totalPrice', null),
// );

// export const cartIdSelector = createSelector(
//   cartDomainSelector,
//   substate => {
//     return substate.cartId;
//   },
// );

// export const cartCurrencySelector = createSelector(
//   cartDomainSelector,
//   substate => prop(substate, 'currency', null),
// );

// export const cartStepSelector = createSelector(
//   cartDomainSelector,
//   substate => prop(substate, 'step', null),
// );

// export const cartFreeDeliveryInfoSelector = createSelector(
//   cartDomainSelector,
//   substate => prop(substate, 'freeDeliveryInfo', null),
// );

// export const cartFreeDeliveryInfoDataSelector = createSelector(
//   cartFreeDeliveryInfoSelector,
//   substate => prop(substate, 'data', {}),
// );

// export const cartFreeDeliveryInfoIsFetchingSelector = createSelector(
//   cartFreeDeliveryInfoSelector,
//   substate => prop(substate, 'isFetching', false),
// );

// export const cartCountSelector = createSelector(
//   cartDomainSelector,
//   cart => cart.productsCount,
// );

// export const cartItemsSelector = createSelector(
//   cartDomainSelector,
//   cart => cart.cartItems || {},
// );

// export const cartDataDomainSelector = createSelector(
//   cartDomainSelector,
//   substate => prop(substate, 'cartData', null),
// );

import { createSelector } from 'reselect';
import { prop } from '../../utilities';

const cartDomainSelector = (state) => state.cart;

export const cartDataDomainSelector = createSelector(
  cartDomainSelector,
  (substate) => prop(substate, 'cartData', null),
);

export const cartTotalPriceSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'total_price', 0),
);

export const cartIdSelector = createSelector(
  cartDataDomainSelector,
  (substate) => {
    return prop(substate, 'id', {});
  },
);

export const cartCurrencySelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'currency', null),
);

export const cartStepSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'step', null),
);

export const cartFreeDeliverySelector = createSelector(
  cartDomainSelector,
  (substate) => prop(substate, 'freeDelivery', {}),
);

export const cartFreeDeliveryDataSelector = createSelector(
  cartFreeDeliverySelector,
  (substate) =>
    prop(substate, 'freeDeliveryData', {
      remaining_price: 0,
      cart_price: 0,
      currency: 'EUR',
    }),
);

export const cartFreeDeliveryIsFetchingSelector = createSelector(
  cartFreeDeliverySelector,
  (substate) => prop(substate, 'isFetching', false),
);

export const cartCountSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'total_items_count', 0),
);

export const cartGainedPoints = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'assigned_points', 0),
);

export const cartItemsSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'items', []),
);

const cartDeliveryAndPaymentOptionsSelector = createSelector(
  cartDomainSelector,
  (substate) => prop(substate, 'deliveryAndPaymentOptions'),
);

export const deliveryAndPaymentOptionsIsFetchingSelector = createSelector(
  cartDeliveryAndPaymentOptionsSelector,
  (substate) => prop(substate, 'isFetching', true),
);

export const deliveryAndPaymentOptionsDataSelector = createSelector(
  cartDeliveryAndPaymentOptionsSelector,
  (substate) => prop(substate, 'data', null),
);

export const cartIsFetchingSelector = createSelector(
  cartDomainSelector,
  (substate) => prop(substate, 'isFetching', true),
);

export const deliverySelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'delivery', null),
);

export const paymentSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'payment', null),
);

export const customerDataSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'billing_address', {}),
);

export const productInCartUpdatingSelector = createSelector(
  cartDomainSelector,
  (substate) => prop(substate, 'productInCartUpdating', false),
);

export const applyGiftcardIsFetchingSelector = createSelector(
  cartDomainSelector,
  (substate) => prop(substate, 'giftcardIsFetching', false),
);

export const companyDataSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'company', {}),
);

export const termsAcceptSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'terms_accept', false),
);

export const newsletterAcceptSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'newsletter_accept', false),
);

export const companyNewsletterAcceptSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'company_newsletter_accept', false),
);

export const billingCompanySelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'billing_company', false),
);

export const noteSelector = createSelector(cartDataDomainSelector, (substate) =>
  prop(substate, 'note', ''),
);

export const becomeMemberSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'become_member', false),
);

export const createdOrderIdSelector = createSelector(
  cartDomainSelector,
  (substate) => prop(substate, 'createdOrderId', ''),
);

export const giftsSelector = createSelector(cartDomainSelector, (substate) =>
  prop(substate, 'gifts', { data: [], isFetching: true }),
);

export const selectedGiftSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'chosen_gift', null),
);

export const cartErrorSelector = createSelector(
  cartDomainSelector,
  (substate) => prop(substate, 'error', null),
);

export const giftcardsSelector = createSelector(
  cartDataDomainSelector,
  (substate) => prop(substate, 'giftcards', []),
);
