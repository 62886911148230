import * as actionTypes from './constants';

const initialState = {
  activeVariantById: {},
  currentProductId: null,
  productDetail: null,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_VARIANT:
      return {
        ...state,
        activeVariantById: {
          ...state.activeVariantById,
          [action.payload.variant.product_id]: action.payload.variant,
        },
      };
    case actionTypes.SET_CURRENT_PRODUCT_ID:
      return {
        ...state,
        currentProductId: action.payload.id,
      };
    case actionTypes.SET_CURRENTPRODUCT_DATA:
      return {
        ...state,
        productDetail: action.payload.productData,
      };
    case actionTypes.SET_LINKED_PRODUCTS:
      return {
        ...state,
        linkedProducts: action.payload.linkedProducts,
      };
    case actionTypes.SET_RELATED_PRODUCTS:
      return {
        ...state,
        relatedProducts: action.payload.relatedProducts,
      };
    case actionTypes.SET_RELATED_PRODUCTS_MODAL:
      return {
        ...state,
        relatedProductsModal: action.payload.relatedProductsModal,
      };
    case actionTypes.SET_RELATED_PRODUCTS_MODAL_LOADER:
      return {
        ...state,
        relatedProductsModalLoader: action.payload.relatedProductsModalLoader,
      };
    case actionTypes.SET_LOADED_LAST_SEEN_PRODUCTS_LOADER:
      return {
        ...state,
        loadedLastSeenProductsLoader:
          action.payload.loadedLastSeenProductsLoader,
      };
    case actionTypes.SET_LOADED_LAST_SEEN_PRODUCTS:
      return {
        ...state,
        loadedLastSeenProducts: action.payload.loadedLastSeenProducts,
      };
    case actionTypes.SET_UPSELL:
      return {
        ...state,
        upsell: action.payload.upsell,
      };
    case actionTypes.UPDATE_CURRENT_PRODUCT_FAVORITE:
      if (state && state.productDetail) {
        const productDetail: any = state.productDetail;
        productDetail.is_user_favorite = action.payload;
      }

      return {
        ...state,
      };

    default:
      return state;
  }
};
