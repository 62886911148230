import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import Icon from './Icon';

const Wrapper = styled.div`
  width: ${rem(44)};
  height: ${rem(44)};
  padding: ${rem(7)};
  background: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
`;

const GiftIcon = styled(Icon)`
  ${({ theme }) => theme.size(30)};
  fill: ${({ theme }) => theme.color.white};
`;

class GiftBadge extends React.Component {
  public render() {
    return (
      <Wrapper>
        <GiftIcon icon="gift" />
      </Wrapper>
    );
  }
}

export default GiftBadge;
