import * as React from 'react';
import styled from 'styled-components';

const VariantName = styled.b``;

const VariantValue = styled.p`
  display: inline-block;
  margin: 0;
`;

interface VariantProps {
  variant: any;
}

class Variant extends React.PureComponent<VariantProps> {
  public render() {
    const { variant } = this.props;
    return (
      <div>
        <VariantName>{`${variant.attrib_name}: `}</VariantName>
        <VariantValue
          dangerouslySetInnerHTML={{
            __html: variant.attrib_value
              ? variant.attrib_value
              : variant.attrib_value_name,
          }}
        ></VariantValue>
      </div>
    );
  }
}

export default Variant;
