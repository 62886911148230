import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { Link } from 'react-router';
import styled from 'styled-components';
import { rem } from 'polished';
import { Heading3 } from '../../components/_helpers/Heading/Heading';
import { Hr } from '../../components/_helpers/Hr/Hr';
import {
  Body,
  Head,
  Table,
  TableWrapper,
  Td,
  Th,
  Tr,
} from '../../components/_helpers/Table/ResponsiveTable';
import { MainBlock } from './Ucet';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { loadRenumerationData } from './actions';
import API, { ThenArg } from '../../services/API';
import { formatStringDate, prop } from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';

const SubTitle = styled(Heading3)``;

const SubTitleSuccess = styled.span`
  color: ${({ theme }) => theme.color.success};
  font-weight: 700;
`;

const Credit = styled.p`
  margin: ${rem(20)} 0;
`;

const TableLink = styled(Link)`
  display: inline-block;
  margin-left: ${rem(40)};
`;

interface RemuneraionSystemAwardedProps {
  dispatch: (a: any) => void;
  setTitle: (title: string, link?: string | null) => void;
  renumerationData: {
    customerDetails: ThenArg<typeof API.getCustomerDetails>;
    creditHistory: ThenArg<typeof API.searchSkvelkoCreditHistory>;
    awardedUsers: ThenArg<typeof API.searchSkvelkoAwardedUsers>;
    skvelkoClasses: ThenArg<typeof API.searchSkvelkoClass>;
  };
  renumerationLoader: boolean;
}

class RemuneraionSystemAwarded extends React.PureComponent<RemuneraionSystemAwardedProps> {
  public static async getInitialProps(props) {
    await props.dispatch(loadRenumerationData());
  }

  constructor(props) {
    super(props);
    props.setTitle(__('Vernostné body'));
  }

  public onTabChange = (index) => {
    this.setState({
      activeTabIndex: index,
    });
  };

  public render() {
    const { renumerationLoader, renumerationData } = this.props;
    const creditHistory = prop(renumerationData, 'creditHistory.search', []);

    return (
      <React.Fragment>
        <MainBlock className={'no-top-padding'}>
          <Loader loading={renumerationLoader}>
            <SubTitle>
              {__('Aktuálne dostupné vernostné body')}:{' '}
              <SubTitleSuccess>
                {prop(
                  renumerationData,
                  'customerDetails.trust_points',
                  0,
                ).toFixed(2)}{' '}
                €
              </SubTitleSuccess>
            </SubTitle>
            <Credit>
              {__('Vernostné body získané za celú históriu spolupráce')}:{' '}
              <strong>
                {prop(
                  renumerationData,
                  'customerDetails.total_trust_points_reached',
                  0,
                ).toFixed(2)}{' '}
              </strong>
            </Credit>
          </Loader>
          <Hr />
          <SubTitle>{__('Prehľad využívania vernostných bodov')}</SubTitle>
          <Loader loading={renumerationLoader}>
            <TableWrapper>
              <Table>
                <Head>
                  <Tr>
                    <Th>{__('Dátum')}</Th>
                    <Th>{__('Popis')}</Th>
                    <Th>{__('Suma')}</Th>
                  </Tr>
                </Head>
                <Body>
                  {creditHistory.map((item, i) => {
                    return (
                      <Tr key={i}>
                        <Td>{formatStringDate(item.createdAt)}</Td>
                        <Td>{item.description}</Td>
                        <Td>
                          {parseFloat(item.value).toFixed(2)} {'€'}
                          <TableLink to="/">{__('Detail')}</TableLink>
                        </Td>
                      </Tr>
                    );
                  })}
                </Body>
              </Table>
            </TableWrapper>
          </Loader>
        </MainBlock>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    renumerationData: state.myAccount.renumerationData,
    renumerationLoader: state.myAccount.renumerationLoader,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'RemuneraionSystemAwarded' })(
    RemuneraionSystemAwarded,
  ),
);
