import * as actionTypes from './constants';
import {
  getSchoolName,
  getSkvelkoSetName,
  isSsr,
  loadOtherText,
  scrollToElement,
} from '../../utilities';
import { setBanners } from '../App/actions';

export const setUniversalSets = (universalSets) => ({
  type: actionTypes.SET_UNI_SETS,
  payload: {
    universalSets,
  },
});

export const setUniversalSetsSpecial = (universalSetsSpecial) => ({
  type: actionTypes.SET_UNI_SETS_SPECIAL,
  payload: {
    universalSetsSpecial,
  },
});

export const setCooperations = (cooperations) => {
  return {
    type: actionTypes.SET_COOPERATIONS,
    payload: {
      cooperations,
    },
  };
};

export const setSkvelkoLoader = (skvelkoLoader) => {
  return {
    type: actionTypes.SET_SKVELKO_LOADER,
    payload: {
      skvelkoLoader,
    },
  };
};

export const setSkvelkoSetsLoader = (skvelkoSetsLoader) => {
  return {
    type: actionTypes.SET_SKVELKO_SETS_LOADER,
    payload: {
      skvelkoSetsLoader,
    },
  };
};

export const setSkvelkoCities = (skvelkoCities) => {
  return {
    type: actionTypes.SET_SKVELKO_CITIES,
    payload: {
      skvelkoCities,
    },
  };
};

export const setSkvelkoSchoolsByCity = (skvelkoSchools) => {
  return {
    type: actionTypes.SET_SKVELKO_SCHOOLS_BY_CITY,
    payload: {
      skvelkoSchools,
    },
  };
};

export const setSkvelkoSetsBySchools = (skvelkoSets) => {
  return {
    type: actionTypes.SET_SKVELKO_SETS_BY_SCHOOL,
    payload: {
      skvelkoSets,
    },
  };
};

export const setSkvelkoClassesBySchools = (skvelkoClasses) => {
  return {
    type: actionTypes.SET_SKVELKO_CLASSES_BY_SCHOOL,
    payload: {
      skvelkoClasses,
    },
  };
};

export const setSkvelkoSet = (skvelkoSet) => {
  return {
    type: actionTypes.SET_SKVELKO_SET,
    payload: {
      skvelkoSet,
    },
  };
};

export const setNewsletterLoader = (newsletterLoader) => {
  return {
    type: actionTypes.SET_NEWSLETTER_LOADER,
    payload: {
      newsletterLoader,
    },
  };
};

export const loadBanners = () => {
  return async (dispatch, getState, API) => {
    const banners = await API.loadBanners({ position: 'HOMEPAGE' });
    dispatch(setBanners(banners));
  };
};

export const loadUniversalSets = () => {
  return async (dispatch, getState, API) => {
    const universalSetsResult = await API.searchSkvelkoSetPublic({
      schoolIco: '11111111',
      withSchoolData: true,
    });
    let universalSets = universalSetsResult.search;

    dispatch(setUniversalSets(universalSets));

    const universalSetsResult2 = await API.searchSkvelkoSetPublic({
      schoolIco: '222222',
      withSchoolData: true,
    });

    universalSets = universalSets.concat(universalSetsResult2.search);
    dispatch(setUniversalSets(universalSets));
  };
};

export const loadUniversalSetsSpecial = () => {
  return async (dispatch, getState, API) => {
    const universalSetsSpecial = await API.loadProductList('SADY');
    dispatch(setUniversalSetsSpecial(universalSetsSpecial));
  };
};

export const loadCooperations = () => {
  return async (dispatch, getState, API) => {
    try {
      const cooperationsWithcontent: any = [];
      const cooperations = await API.loadSitemapSubtree('COOPERATION');
      for (const cooperation of cooperations.sitemap_tree) {
        const newCooperation = await API.loadSitemap(cooperation.id);
        cooperationsWithcontent.push(newCooperation);
      }
      dispatch(setCooperations(cooperationsWithcontent));
    } catch (e) {
      console.log(e);
    }
  };
};

export const loadSkvelkoCities = () => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(setSkvelkoLoader(true));
      const citiesObj = await API.getSkvelkoSchoolCities({
        univerzalSetsFirst: true,
      });

      const cities = new Array();
      citiesObj.cities.forEach((city, i) => {
        cities[i] = new Object({ value: city });
      });
      dispatch(setSkvelkoCities(cities));
      dispatch(setSkvelkoLoader(false));
    } catch (e) {
      console.log(e);
    }
  };
};

export const loadSkvelkoSchoolsByCity = (city) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(setSkvelkoLoader(true));
      const schoolsObj = await API.searchSkvelkoSchools({
        cityName: city,
        onlyWithSets: true,
      });
      const schools = new Array();
      schoolsObj.schools.forEach((school, i) => {
        schools[i] = new Object({
          value: school.id,
          name: getSchoolName(school),
        });
      });
      dispatch(setSkvelkoSchoolsByCity(schools));
      dispatch(setSkvelkoLoader(false));
    } catch (e) {
      console.log(e);
    }
  };
};

export const loadSkvelkoSetsBySchool = (schoolId) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(setSkvelkoLoader(true));
      const setsObj = await API.searchSkvelkoSetPublic({ schoolId });
      const sets = setsObj?.search?.map((set) => ({
        value: set.id,
        name: getSkvelkoSetName(set.name1, set.name2, set.name3),
      }));
      dispatch(setSkvelkoSetsBySchools(sets));
      dispatch(setSkvelkoLoader(false));
    } catch (e) {
      console.log(e);
    }
  };
};

export const loadSkvelkoClassesBySchool = (clubUserId) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(setSkvelkoLoader(true));
      const classesObj = await API.searchSkvelkoClassPublic({ clubUserId });
      const classes = new Array();
      classesObj.search.forEach((skvelkoClass, i) => {
        classes[i] = new Object({
          value: skvelkoClass.id,
          name: skvelkoClass.name,
        });
      });
      dispatch(setSkvelkoClassesBySchools(classes));
      dispatch(setSkvelkoLoader(false));
    } catch (e) {
      console.log(e);
    }
  };
};

export const loadSkvelkoSet = (id) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(setSkvelkoSetsLoader(true));
      const setObj = await API.searchSkvelkoSetPublic({
        id,
        withGoods: true,
        withProducts: true,
        withSchoolDeliveryActivated: true,
      });
      if (setObj.search.length) {
        dispatch(setSkvelkoSet(setObj.search[0]));
      }
      dispatch(setSkvelkoSetsLoader(false));
    } catch (e) {
      console.log(e);
    }
  };
};

export const loadFilterFromLocation = (id) => {
  return async (dispatch, getState, API) => {
    try {
      const {
        search: [skvelkoSet],
      } = await API.searchSkvelkoSetPublic({
        id,
        withSchoolData: true,
        withGoods: true,
        withProducts: true,
      });

      if (!skvelkoSet) {
        return;
      }

      const city = skvelkoSet?.city || '';
      const schoolId = skvelkoSet?.clubUserId;
      const schoolFullName = skvelkoSet?.schoolFullName;
      const schoolName = skvelkoSet?.schoolName;
      const skvelkoId = skvelkoSet?.id;
      const skvelkoFullname = `Zoznam pre ${skvelkoSet?.fullName}`;

      dispatch(loadSkvelkoSchoolsByCity(city));
      dispatch(setSelectedCityName(city));
      dispatch(loadSkvelkoSetsBySchool(schoolId));
      dispatch(setSelectedSchoolId(schoolId));
      dispatch(loadSkvelkoClassesBySchool(schoolId));
      dispatch(setSelectedSkvelkoSetId(skvelkoId));
      dispatch(setCityIsSelected(true));
      dispatch(setSchoolIsSelected(true));
      dispatch(setSkvelkoSet(skvelkoSet));

      dispatch(
        setCurrentFilterTitle(
          schoolFullName || schoolName || '',
          '',
          skvelkoFullname,
        ),
      );

      dispatch(setCurrentSet({ id: skvelkoId, name: skvelkoFullname }));
      scrollToAnchor('scroll-anchor');
    } catch (e) {
      console.error(e);
    }
  };
};

export const selectSchool = (clubUserId) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(setSkvelkoSetsLoader(true));
      const result = await API.getSkvelkoSchoolCity({
        clubUserId,
      });
      if (result && result.city) {
        dispatch(setCityIsSelected(true));
        dispatch(setSelectedCityName(result.city));
        dispatch(loadSkvelkoSchoolsByCity(result.city));
        dispatch(setSchoolIsSelected(true));
        dispatch(setSelectedSchoolId(clubUserId));
        dispatch(loadSkvelkoSetsBySchool(clubUserId));

        const schoolsObj = await API.searchSkvelkoSchools({
          cityName: result.city,
          onlyWithSets: true,
        });
        const schools = schoolsObj.schools.map((school) => {
          const name = getSchoolName(school);
          if (school.id === clubUserId) {
            dispatch(setCurrentSchoolName(name));
          }
          return {
            value: school.id,
            name,
          };
        });
        dispatch(setSkvelkoSchoolsByCity(schools));
      }
      dispatch(setSkvelkoSetsLoader(false));
    } catch (e) {
      console.log(e);
    }
  };
};

export const resetFilter = () => {
  return async (dispatch, getState, API) => {
    dispatch(setSelectedCityName(''));
    dispatch(setSelectedSchoolId(null));

    dispatch(setCityIsSelected(false));
    dispatch(setSchoolIsSelected(false));

    dispatch(setSkvelkoSchoolsByCity([]));
    dispatch(setSkvelkoSetsBySchools([]));
    dispatch(setSkvelkoClassesBySchools([]));

    dispatch(setSkvelkoSet(null));
    dispatch(setCurrentFilterTitleEmpty());
  };
};

export const setNewsletterResponse = (newsletterResponse) => {
  return {
    type: actionTypes.SET_NEWSLETTER_RESPONSE,
    payload: {
      newsletterResponse,
    },
  };
};

export const sendNewsletter = (email, type: string | undefined = undefined) => {
  return async (dispatch, getState, API) => {
    dispatch(setNewsletterLoader(true));
    try {
      await API.subscribeNewsletter(
        {
          engine: 'smartemailing',
          type: type,
        },
        {
          email,
          first_name: '',
          last_name: '',
          source: '',
          status: 'subscribed',
        },
      );
      dispatch(setNewsletterResponse(true));
    } catch (e) {
      dispatch(setNewsletterResponse(false));
      console.log(e);
    }
    dispatch(setNewsletterLoader(false));
  };
};

export const setSelectedCityName = (selectedCityName) => {
  return {
    type: actionTypes.SET_SELECTED_CITY_NAME,
    payload: {
      selectedCityName,
    },
  };
};

export const setSelectedSchoolId = (selectedSchoolId) => {
  return {
    type: actionTypes.SET_SELECTED_SCHOOL_ID,
    payload: {
      selectedSchoolId,
    },
  };
};

export const setSelectedSkvelkoSetId = (selectedSkvelkoSetId) => {
  return {
    type: actionTypes.SET_SELECTED_SKVELKO_SET_ID,
    payload: {
      selectedSkvelkoSetId,
    },
  };
};

export const setSelectedClassId = (selectedClassId) => {
  return {
    type: actionTypes.SET_SELECTED_CLASS_ID,
    payload: {
      selectedClassId,
    },
  };
};

export const setSchoolIsSelected = (schoolIsSelected) => {
  return {
    type: actionTypes.SET_SCHOOL_IS_SELECTED,
    payload: {
      schoolIsSelected,
    },
  };
};

export const setCityIsSelected = (cityIsSelected) => {
  return {
    type: actionTypes.SET_CITY_IS_SELECTED,
    payload: {
      cityIsSelected,
    },
  };
};

export const setCurrentFilterTitle = (schoolName, cityName, setName) => {
  const currentFilterTitle = schoolName + ' - ' + setName;
  return {
    type: actionTypes.SET_CURRENT_FILTER_TITLE,
    payload: {
      currentFilterTitle,
      currentSchoolName: schoolName,
    },
  };
};

export const setCurrentSchoolName = (currentSchoolName) => {
  return {
    type: actionTypes.SET_CURRENT_SCHOOL_NAME,
    payload: {
      currentSchoolName,
    },
  };
};

export const setCurrentFilterTitleEmpty = () => {
  return {
    type: actionTypes.SET_CURRENT_FILTER_TITLE,
    payload: {
      currentFilterTitle: '',
    },
  };
};

export const setCurrentSet = (currentSet) => {
  return {
    type: actionTypes.SET_CURRENT_SET,
    payload: {
      currentSet,
    },
  };
};

export const setCurrentClassId = (currentClassId) => {
  return {
    type: actionTypes.SET_CURRENT_CLASS_ID,
    payload: {
      currentClassId,
    },
  };
};

export const setSetPopupText = (setPopupText) => ({
  type: actionTypes.SET_SET_POPUP_TEXT,
  payload: {
    setPopupText,
  },
});

export const loadSetPopupText = () =>
  loadOtherText('SET_POPUP_TEXT', setSetPopupText);

export const setWarningRemoveFromSetText = (warningRemoveFromSetText) => ({
  type: actionTypes.SET_WARNING_REMOVE_FROM_SET_TEXT,
  payload: {
    warningRemoveFromSetText,
  },
});

export const loadWarningRemoveFromSetText = () =>
  loadOtherText('WARNING_REMOVE_PRODUCT_FROM_SET', setWarningRemoveFromSetText);

export const resetSkvelko = () => {
  return async (dispatch, getState, API) => {
    dispatch(setCityIsSelected(false));
    dispatch(setSchoolIsSelected(false));
    dispatch(setSkvelkoSet(null));
    dispatch(setSelectedCityName(null));
    dispatch(setSelectedSchoolId(null));
    dispatch(setSelectedSkvelkoSetId(null));
  };
};

export const scrollToAnchor = (className) => {
  if (!isSsr()) {
    if (window.innerWidth > 1024) {
      scrollToElement(className, 140, 200);
    } else {
      scrollToElement(className, 0, 200);
    }
  }
};
