import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { isOutOfStock } from '../../../utilities';

export const ProductAvailabilityWrapper = styled.p`
  display: block;
  font-size: ${rem(12)};
  margin: 0;
  ${({ theme }) => theme.media(1024)} {
    font-size: ${rem(14)};
  }
  &.on-stock {
    color: ${({ theme }) => theme.color.success};
  }
  &.not-available {
    color: ${({ theme }) => theme.color.error};
  }
  &.order-only {
    color: ${({ theme }) => theme.color.info};
  }
`;

interface AvailabilityProps {
  className?: string;
  product: any;
}

class Availability extends React.PureComponent<AvailabilityProps> {
  public render() {
    const { className, product } = this.props;
    const availUniqId =
      product?.main_good?.availability?.avail_uniqid || __('Nedostupné');
    let availabilityClassName = 'on-stock';
    if (isOutOfStock(product)) {
      availabilityClassName = 'not-available';
    }
    return (
      <ProductAvailabilityWrapper
        className={`${className} ${availabilityClassName}`}
      >
        {availUniqId}
      </ProductAvailabilityWrapper>
    );
  }
}

export default Availability;
