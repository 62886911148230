import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { Button2, StyledFooter } from './ChooseModal';
import API, { ThenArg } from '../../../services/API';
import { prop } from '../../../utilities';
import Overlay from '../Overlay/Overlay';
import { ContentModal, ContentModalBody } from './constants';
import { Loader } from '../Loader/Loader';

interface ModalProps {
  articleId: number;
  onCloseButtonClick?: (event) => void;
}

const ArticleModal = (props: ModalProps) => {
  const [content, setContent] = React.useState<
    ThenArg<typeof API.loadSitemap> | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setLoading(true);
    API.loadArticleDetail(props.articleId).then((r) => {
      setContent(r);
      setLoading(false);
    });
  }, [props.articleId]);

  const { onCloseButtonClick } = props;
  const title = prop(content, 'json_content.name', __('Obsah'));
  const body = prop(content, 'json_content.body', '');

  if (loading) {
    return (
      <Overlay>
        <Loader loading={loading} />
      </Overlay>
    );
  } else {
    return (
      <ContentModal
        title={title}
        body={
          <ContentModalBody
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        }
        footer={
          <StyledFooter>
            <Button2 className="primary lg" onClick={onCloseButtonClick}>
              {__('Zavrieť')}
            </Button2>
          </StyledFooter>
        }
        onCloseButtonClick={onCloseButtonClick}
      />
    );
  }
};

export default ArticleModal;
