import * as React from 'react';
import {
  formatPrice,
  formatStringDate,
  isCompany,
  prop,
} from '../../../utilities';
import { Td, Tr } from '../../_helpers/Table/ResponsiveTable';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import {
  Button,
  ButtonStyledStyles,
  ButtonStyles,
} from '../../_helpers/form/Button';
import { rem } from 'polished';
import Icon from '../../_helpers/Icon/Icon';
import { push } from 'react-router-redux';
import { orderAgainAction } from '../../../containers/App/actions';

interface OrderHistorySingleProps {
  order: any;
  user: any;
  dispatch: any;
}

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const CustomButton = styled(Button)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  white-space: nowrap;
  padding: ${rem(4)};
`;

const OrderAgainButton = styled(CustomButton)`
  margin-left: ${rem(8)};
`;

const OrderDateTime = styled.span`
  white-space: nowrap;
  color: ${({ theme }) => theme.color.blue};
`;

const OrderDate = styled.span`
  font-weight: bold;
`;

const ButtonText = styled.span`
  margin-left: ${rem(4)};
  vertical-align: middle;
`;

const BlueIcon = styled(Icon)`
  fill: ${({ theme }) => theme.color.blue};
`;

const PriceWrapper = styled.span`
  font-size: large;
  ${({ theme }) => theme.media('xs')} {
    font-size: initial;
  }
`;

const Price = styled.span``;

const PriceWithWat = styled.span`
  font-weight: bold;
`;

const BlueText = styled.span`
  color: ${({ theme }) => theme.color.blue};
`;

const CustomTd = styled(Td)`
  vertical-align: top;
  ${({ theme }) => theme.media(1100)} {
    padding: ${rem(8)} ${rem(20)};
  }
`;

const CustomButtonTd = styled(Td)`
  ${({ theme }) => theme.media(1100)} {
    padding-top: ${rem(12)};
  }
`;

const CompanyPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 18px;
`;

export const OrderHistorySingle = (props: OrderHistorySingleProps) => {
  const { order, user } = props;

  const orderNr = prop(order, 'order_nr', '');
  const orderDate = formatStringDate(
    prop(order, 'created_datetime'),
    'DD.MM.YYYY',
  );

  const orderTime = formatStringDate(prop(order, 'created_datetime'), 'HH:mm');

  const orderLink = `/my-account/order/${prop(order, 'public_id', '')}`;
  const orderCurrency = prop(order, 'order_tpricecurrency', '');

  const isUserCompany = isCompany(user);
  const orderPriceWithWat = prop(order, 'order_tprice');
  const orderPriceWithoutWat = prop(order, 'order_tprice_without_vat');

  const payment = prop(order, 'payment.payment_typename');

  const onClickOrderAgain = () => {
    const publicId = prop(order, 'public_id', '');
    props.dispatch(
      orderAgainAction(publicId, (result) => {
        props.dispatch(push('/kosik'));
      }),
    );
  };

  const isDemand = order.isDemand;

  return (
    <Tr>
      <CustomTd>
        <BoxWrapper>
          <OrderDateTime>
            <OrderDate>{orderDate}</OrderDate> ({orderTime} hod)
          </OrderDateTime>
          <PriceWrapper>
            {isDemand ? (
              <div>
                <b>{__('Žiadosť o cenu')}</b>
              </div>
            ) : isUserCompany ? (
              <CompanyPriceWrapper>
                <Price>
                  {' '}
                  {formatPrice(orderPriceWithoutWat, orderCurrency)} bez DPH
                </Price>
                <PriceWithWat>
                  {' '}
                  {formatPrice(orderPriceWithWat, orderCurrency)} s DPH
                </PriceWithWat>
              </CompanyPriceWrapper>
            ) : (
              <>
                <PriceWithWat>
                  {' '}
                  {formatPrice(orderPriceWithWat, orderCurrency)}
                </PriceWithWat>{' '}
                (s DPH)
              </>
            )}
          </PriceWrapper>
        </BoxWrapper>
      </CustomTd>
      <CustomTd>
        <BoxWrapper>
          <span>
            <BlueText>
              {isDemand ? __('Číslo žiadosti') : __('Číslo objednávky')}:
            </BlueText>{' '}
            {orderNr}
          </span>
          {!isDemand && (
            <span>
              <BlueText>{__('Spôsob platby')}:</BlueText> {payment}
            </span>
          )}
        </BoxWrapper>
      </CustomTd>
      <CustomButtonTd>
        <ButtonsWrapper>
          <CustomButton onClick={() => props.dispatch(push(orderLink))}>
            <BlueIcon icon="order_detail" />
            <ButtonText>{__('Detail')}</ButtonText>
          </CustomButton>
          {!isDemand && (
            <OrderAgainButton onClick={onClickOrderAgain}>
              <BlueIcon icon="cart2" />
              <ButtonText>{__('Objednať znovu')} </ButtonText>
            </OrderAgainButton>
          )}
        </ButtonsWrapper>
      </CustomButtonTd>
      {/* 
        <Td>{orderNr}</Td>
        <Td>{orderDate}</Td>
        <Td>{formatPrice(orderPrice, orderCurrency)}</Td>
        <Td>{payment}</Td>
        <Td>
          <OrderLink to={orderLink}>{__('Detail')}</OrderLink>
        </Td>
      */}
    </Tr>
  );
};

export default OrderHistorySingle;
