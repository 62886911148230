import onClickOutside from 'react-onclickoutside';
import React from 'react';
import styled from 'styled-components';

const OutsideComponent = styled.div``;

interface OnClickOutsideComponentIO {
  className?: string;
  onClickOutside: () => void;
}

class OnClickOutsideComponent extends React.Component<
  OnClickOutsideComponentIO
> {
  public handleClickOutside(evt) {
    if (this.props.onClickOutside) {
      this.props.onClickOutside();
    }
  }

  public render() {
    return (
      <OutsideComponent className={this.props.className}>
        {' '}
        {this.props.children}{' '}
      </OutsideComponent>
    );
  }
}

export default onClickOutside(OnClickOutsideComponent);
