import * as React from 'react';
import styled from 'styled-components';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import MetaTags from '../../components/MetaTags/MetaTags';
import { Row, MainCol, CategoriesCol } from '../../theme/libraries/grid';
import {
  capitalizeFirstLetter,
  categoryMetaHandler,
  removePTag,
} from '../../utilities';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import {
  ContainerIndent,
  Title,
} from '../../components/_layout/Page/ContentContainer';
import { Media, MediaContextProvider } from '../../theme/media';
import CategoriesListNew from '../../components/_helpers/Categories/CategoriesListNew';
import API from '../../services/API';
import { useState } from 'react';
import { __ } from 'react-i18n/lib';
import CategoriesFurniture from '../../components/_helpers/Categories/CategoriesFurniture';
import { rem } from 'polished';
import { setCurrentCategoryGroupId } from '../App/actions';
import {
  CategoryDescriptionWrapper,
  DescriptinoText,
  InfoIcon,
  ShowMoreButton,
} from './Category';

const TitleStyled = styled(Title)`
  margin-bottom: ${rem(32)};
`;

const CategoriesListNewStyled = styled(CategoriesListNew)`
  margin-bottom: ${rem(10)};
`;

export interface CategoryProps {
  category?: any;
  dispatch: any;
}

const FurnitureCategoryGroup = (props: CategoryProps) => {
  const { category, dispatch } = props;

  const [categoryGroup, setCategoryGroup] = useState([] as any);
  const [categoryGroupContent, setCategoryGroupContent] = useState<any>(null);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

  React.useEffect(() => {
    API.loadCategoriesGroups({
      active: '1',
      withCategories: 1,
      categoryTree: true,
      limit: 1,
      id: props.category.id,
      filterEmpty: true,
    }).then((categoriesGroup) => {
      if (categoriesGroup.total) {
        setCategoryGroup(categoriesGroup.catalog_category_groups[0].categories);
        setCategoryGroupContent(
          categoriesGroup.catalog_category_groups[0].content,
        );
        setCurrentCategoryGroupId(props.category.id);
      }
    });
  }, [props.category.id]);

  const items: any = [
    {
      name: __('Internetový obchod'),
      url: '/',
    },
    {
      name: capitalizeFirstLetter(category.name),
      url: category.url,
    },
  ];

  const body =
    categoryGroupContent &&
    categoryGroupContent.draft !== true &&
    typeof categoryGroupContent.json_content?.body === 'string'
      ? removePTag(categoryGroupContent.json_content?.body)
      : null;

  const onDescriptionButtonClick = async () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };

  const renderCategoryDesctiption = (body, shortBody) => {
    if (shortBody && body) {
      return (
        <CategoryDescriptionWrapper isEnd={isDescriptionOpen ? true : false}>
          <DescriptinoText
            dangerouslySetInnerHTML={{
              __html: shortBody,
            }}
          />

          {isDescriptionOpen ? (
            <ShowMoreButton onClick={onDescriptionButtonClick}>
              {' '}
              <InfoIcon icon="info" />
              {__('Menej informácií')}
            </ShowMoreButton>
          ) : (
            <ShowMoreButton onClick={onDescriptionButtonClick}>
              {' '}
              <InfoIcon icon="info" />
              {__('Viac informácií')}
            </ShowMoreButton>
          )}

          {isDescriptionOpen ? (
            <DescriptinoText
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            />
          ) : (
            <></>
          )}
        </CategoryDescriptionWrapper>
      );
    } else if (shortBody) {
      return (
        <CategoryDescriptionWrapper isEnd={true}>
          <DescriptinoText
            dangerouslySetInnerHTML={{
              __html: shortBody,
            }}
          />
        </CategoryDescriptionWrapper>
      );
    } else if (body) {
      return (
        <CategoryDescriptionWrapper isEnd={true}>
          <DescriptinoText
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        </CategoryDescriptionWrapper>
      );
    }

    return <></>;
  };

  return (
    <MediaContextProvider>
      <ContainerIndent className="base">
        <MetaTags tags={categoryMetaHandler(category, 'sk', 'Daffer.sk')} />
        <Row>
          <Media greaterThanOrEqual="lg">
            <CategoriesCol>
              {categoryGroup && categoryGroup.length ? (
                <CategoriesListNewStyled
                  dispatch={dispatch}
                  categoryGroup={categoryGroup}
                />
              ) : (
                ''
              )}
            </CategoriesCol>
          </Media>
          <MainCol>
            <Breadcrumbs items={items} />
            <TitleStyled>{capitalizeFirstLetter(category.name)}</TitleStyled>
            {renderCategoryDesctiption(body, null)}
            <CategoriesFurniture categories={categoryGroup} />
          </MainCol>
        </Row>
      </ContainerIndent>
    </MediaContextProvider>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FurnitureCategoryGroup' })(FurnitureCategoryGroup),
);
