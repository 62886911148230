import SetPreview from './SetPreview';
import { getSetUrl } from '../../../utilities';
import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  ${({ theme }) => theme.media('md')} {
    margin: 0;
  }
`;

interface SetPreviewsProps {
  sets: any;
  onUniversalSetClick?: (setId) => void;
}

const SetPreviews = (props: SetPreviewsProps) => {
  const onUniversalSetClick = (setId) => {
    if (props.onUniversalSetClick) {
      props.onUniversalSetClick(setId);
    }
  };

  const { sets } = props;

  return (
    <Wrapper>
      {(sets &&
        sets.length &&
        sets.map((set, index) => {
          return (
            <SetPreview
              key={index}
              name={set.schoolName + ' - ' + set.fullName}
              picture={`images/uni_set_${index % 13}.jpg`}
              url={getSetUrl(set.id)}
              onSetClick={onUniversalSetClick.bind(null, set.id)}
            />
          );
        })) ||
        ''}
    </Wrapper>
  );
};

export default SetPreviews;
