import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { ButtonStyledStyles, ButtonStyles } from '../form/Button';
import BaseModal, { Footer } from './BaseModal';

const ModalDiv = styled(BaseModal)`
  width: ${rem(560)};
  ${({ disableBackground }) =>
    disableBackground &&
    css`
      div > div {
        background: none;
      }
    `}
`;

const Row = styled.div`
  display: flex;
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  & + & {
    margin-top: ${rem(15)};
  }
`;

const StyledFooter = styled(Footer)`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  ${({ theme }) => theme.media(600)} {
    justify-content: space-between;
  }
`;

const ModalButtonStyles = css`
  flex: 1 0 100%;
  ${({ theme }) => theme.media(600)} {
    flex: 0 0 auto;
  }
`;

const ContinueShopping = styled.button`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${ModalButtonStyles};
  margin-top: ${rem(10)};
  ${({ theme }) => theme.media(600)} {
    margin-top: 0;
    order: -1;
  }
`;

const GoToCart = styled(Link)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${ModalButtonStyles};
`;

interface ModalProps {
  onCloseButtonClick?: (event) => void;
  onGoToCartClick?: () => void;
  loading?: boolean;
}

class SetAddedToCartModal extends React.PureComponent<ModalProps> {
  public render() {
    if (this.props.loading) {
      return (
        <ModalDiv
          disableBackground={true}
          title={__('Sada sa vkladá do košíka')}
          body={
            <Row>
              {__(
                'Vkladanie viac produktov do košíka chvíľu trvá. Prosíme Vás o strpenie.',
              )}
            </Row>
          }
          onCloseButtonClick={this.props.onCloseButtonClick}
          loading={this.props.loading}
          notShowClose={true}
        />
      );
    } else {
      return (
        <ModalDiv
          title={__('Zoznam bol pridaný do košíka')}
          body={<Row>{__('Zoznam bol pridaný do košíka')}</Row>}
          footer={
            <StyledFooter>
              <GoToCart
                to=""
                className="primary lg"
                onClick={this.props.onGoToCartClick}
              >
                {__('Prejsť do košíka')}
              </GoToCart>
              <ContinueShopping
                className="lg"
                onClick={this.props.onCloseButtonClick}
              >
                {__('Pokračovať v nakupovaní')}
              </ContinueShopping>
            </StyledFooter>
          }
          onCloseButtonClick={this.props.onCloseButtonClick}
          loading={this.props.loading}
        />
      );
    }
  }
}

export default SetAddedToCartModal;
