import { rgba } from 'polished';

const colors = {
  white: '#ffffff',
  black: '#000000',
  primary: '#e5173f',
  primaryDark: '#cc1438',
  secondary: '#ffdd00',
  secondaryLighter: '#fff4ad',
  secondaryLight: '#fdf7c9',
  success: '#46b53a',
  error: '#e5173f',
  errorLight: '#FBE8EC',
  info: '#337bcc',
};

export const color = {
  ...colors,
  background: '#f4f4f4',
  text: '#232323',
  link: '#337BCC',
  blue: '#406df3',
  purple: '#495395',
  green: '#348112',
  selection: `${rgba(colors.primary, 0.5)}`,
  white: colors.white,
  gray97: '#f9f9f9',
  gray95: '#f4f4f4',
  gray93: '#eee',
  gray89: '#e3e3e3',
  gray87: '#ddd',
  gray83: '#e0e0e0',
  gray81: '#d5d5d5',
  gray80: '#ccc',
  gray70: '#b4b4b4',
  gray66: '#aaa',
  gray64: '#ABABAB',
  gray60: '#999',
  gray56: '#7c7c7c',
  gray38: '#6a6a6a',
  gray34: '#575757',
  gray16: '#282828',
  gray14: '#232323',
  codeBackground: '#ebfcff',
  codeColor: '#166472',
  available: '#BEF0BE',
  gradient: {
    primary: `linear-gradient(to bottom, ${colors.primary}, ${colors.primaryDark})`,
    primaryHover: `linear-gradient(to bottom, ${colors.primary}, ${colors.primaryDark}, ${colors.primary})`,
  },
  navigation: {
    background: `linear-gradient(to bottom, ${colors.primaryDark}, ${colors.primary}, ${colors.primary})`,
    backgroundActive: colors.secondary,
  },
  overlay: rgba(colors.black, 0.25),
  overlaySecondary: rgba(colors.black, 0.5),
  overlayMobile: '#282828',
  alert: {
    success: {
      background: rgba(colors.success, 0.05),
      border: rgba(colors.success, 0.1),
    },
    error: {
      background: rgba(colors.error, 0.05),
      border: rgba(colors.error, 0.1),
    },
    info: {
      background: rgba(colors.info, 0.05),
      border: rgba(colors.info, 0.1),
    },
  },
  social: {
    facebook: '#3b5998',
    youtube: '#ff0000',
  },
  slider: {
    background: '#3F4A94',
  },
  cartButtonBackground: '#e8e8e8',
};
