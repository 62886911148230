import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Swiper from '../Swiper/Swiper';
import ProductPreview from '../product/ProductPreview';
import {
  HomeSwiper1200Styles,
  HomeSwiperStyles,
} from '../../_layout/Homepage/SliderStyles';
import { Heading2 } from '../Heading/Heading';
import { Loader } from '../Loader/Loader';

const Wrapper = styled.div``;

const SwiperWrapper = styled.div`
  .swiper-container {
    padding-bottom: ${rem(52)};
  }
  .swiper-container .swiper-slide {
    height: auto;
    display: flex;
  }
  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: ${rem(20)};
  }
  .swiper-button-prev,
  .swiper-button-next {
    &.swiper-button-disabled {
      pointer-events: auto;
    }
  }
  ${HomeSwiperStyles};
  ${({ theme }) => theme.media(1024)} {
    .swiper-container {
      padding: 0 ${({ theme }) => rem(theme.grid.padding / 2)};
      &:before,
      &:after {
        content: '';
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
    .swiper-pagination {
      display: none;
    }
  }
  ${({ theme }) => theme.media(1200)} {
    margin-right: ${rem(-30)};
    margin-left: ${rem(-30)};
    ${HomeSwiper1200Styles};
    .swiper-container {
      padding-right: ${rem(30)};
      padding-left: ${rem(30)};
    }
  }
`;

const ProductHeading = styled(Heading2)`
  text-align: center;
  margin: ${rem(20)} 0;
  ${({ theme }) => theme.media('sm')} {
    text-align: left;
  }
`;

interface ProductsSliderProps {
  title?: string;
  products: any[];
  breakpoints?: any;
}

interface ProductsSliderState {
  loading: boolean;
}

class ProductsSlider extends React.PureComponent<
  ProductsSliderProps,
  ProductsSliderState
> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public render() {
    const { title, products, breakpoints } = this.props;

    const swiperParams = {
      slidesPerView: 2,
      spaceBetween: 2,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1023: {
          slidesPerView: 4,
        },
        577: {
          slidesPerView: 3,
        },
      },
    };

    if (breakpoints) {
      swiperParams.breakpoints = {
        ...swiperParams.breakpoints,
        ...breakpoints,
      };
    }

    if (products && products.length) {
      return (
        <Wrapper>
          {(title && <ProductHeading>{title}</ProductHeading>) || ''}
          <SwiperWrapper>
            <Loader loading={this.state.loading}>
              <Swiper params={swiperParams}>
                {products.map((item, i) => {
                  return (
                    <div key={i}>
                      <ProductPreview
                        product={item}
                        setLoading={(loading) => {
                          this.setState({ loading: loading });
                        }}
                      />
                    </div>
                  );
                })}
              </Swiper>
            </Loader>
          </SwiperWrapper>
        </Wrapper>
      );
    } else {
      return '';
    }
  }
}

export default ProductsSlider;
