import styled from 'styled-components';

export const Flexbox: any = styled.div`
  display: flex;
`;

export const FlexRow: any = styled(Flexbox)`
  flex-direction: row;
`;

export const FlexRowCenter: any = styled(FlexRow)`
  align-items: center;
  justify-content: center;
`;

export const FlexRowCenterVertical: any = styled(FlexRow)`
  align-items: center;
`;

export const FlexRowCenterHorizontal: any = styled(FlexRow)`
  justify-content: center;
`;

export const FlexCol: any = styled(Flexbox)`
  flex-direction: column;
`;

export const FlexColCenter: any = styled(FlexCol)`
  align-items: center;
  justify-content: center;
`;

export const FlexColCenterVertical: any = styled(FlexCol)`
  justify-content: center;
`;

export const FlexColCenterHorizontal: any = styled(FlexCol)`
  align-items: center;
`;

export const FlexBetween: any = styled(Flexbox)`
  align-items: center;
  justify-content: space-between;
`;

export const FlexBetweenTop: any = styled(FlexBetween)`
  align-items: flex-start;
`;

export const HalfContainer: any = styled.div`
  width: 50%;
  &:first-of-type {
    margin-right: 20px;
  }
  &:last-of-type {
    margin-left: 20px;
  }
  @media all and (max-width: 700px) {
    width: 100%;
    &:first-of-type {
      margin-right: 0;
    }
    &:last-of-type {
      margin-left: 0;
    }
  }
`;
