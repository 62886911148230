import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { ButtonStyles } from '../../components/_helpers/form/Button';
import { __ } from 'react-i18n/lib';
import { CaretStyles } from '../../components/_helpers/Icon/Caret';
import OnClickOutsideComponent from '../../components/_helpers/OnClickOutsideComponent/OnClickOutsideComponent';
import Pagination from '../../components/Pagination/Pagination';
import { Media, MediaContextProvider } from '../../theme/media';

const Wrapper = styled(OnClickOutsideComponent)`
  background: ${({ theme }) => theme.color.white};
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  ${({ theme }) => theme.media('sm')} {
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    margin-right: 0;
    margin-left: 0;
    box-shadow: ${({ theme }) => theme.boxShadow.small};
  }
`;

const ButtonWrapper = styled.div`
  position: relative;

  & + & {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }

  ${({ theme }) => theme.media('sm')} {
    min-height: ${rem(46)};
    display: inline-block;
    border-right: ${rem(2)} solid ${({ theme }) => theme.color.gray93};

    & + & {
      border-top: none;
    }
  }
`;

const ToggleButton = styled.button`
  ${ButtonStyles};
  font-size: ${rem(14)};
  display: block;
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)}
  width: 100%;
  text-align: left;
  padding: ${rem(17)} ${rem(35)} ${rem(17)} ${rem(20)};
  outline: none;
  ${({ theme }) => theme.media('sm')} {
    display: inline-block;
    width: auto;
  }

  position: absolute;
  top: ${rem(-45)};
  right: 0;

  ${({ theme }) => theme.media('sm')} {
    position: initial;
  }
`;

const Caret = styled.span`
  ${CaretStyles};
  position: absolute;
  top: 50%;
  right: ${rem(20)};
  transform: translateY(-50%);
`;

const DropdownStyles = css`
  background: ${({ theme }) => theme.color.white};
  width: 100%;
  ${({ theme }) => theme.media('sm')} {
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    width: ${rem(300)};
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 11;
    transform: translateY(${rem(2)});
    box-shadow: ${({ theme }) => theme.boxShadow.small};
  }
`;

const SortDropdown = styled.div`
  ${DropdownStyles};
  padding: ${rem(10)} 0;
`;

const Sort = styled.button`
  ${ButtonStyles};
  width: 100%;
  font-size: ${rem(14)};
  padding: ${rem(10)} ${rem(20)};
  text-align: left;
  &.active {
    background: ${({ theme }) => theme.color.gray95};
  }
`;

interface Props {
  onSortChange: (sort: string, sortDir: string) => void;
  sort: Array<{
    name: string;
    sort: string;
    sortDir: string;
  }>;
  paginationTotal?: number;
  paginationCurrent?: number | string;
  onPageChanged?: (pageNumner: number) => void;
  isSearch: boolean;
  isSortable: boolean;
}

interface States {
  visibleDropdown: number | null;
  sort: string;
  sortDir: string;
}

class Filter extends React.PureComponent<Props, States> {
  constructor(props) {
    super(props);

    this.state = {
      visibleDropdown: null,
      sort: '',
      sortDir: '',
    };
  }

  public setVisibleDropdownIndex = (index) => {
    if (
      (index !== null && this.state.visibleDropdown === null) ||
      index !== this.state.visibleDropdown
    ) {
      this.setState({
        visibleDropdown: index,
      });
    } else {
      this.setState({
        visibleDropdown: null,
      });
    }
  };

  public setSort = (sort, sortDir) => {
    this.props.onSortChange(sort, sortDir);
    this.setState({
      sort,
      sortDir,
      visibleDropdown: null,
    });
  };

  public getWrappedRender() {
    return (
      <ButtonWrapper>
        {this.props.isSortable ? (
          <>
            <ToggleButton onClick={this.setVisibleDropdownIndex.bind(null, 2)}>
              {__('Zoradiť')} <Caret />
            </ToggleButton>
            {this.state.visibleDropdown === 2 && (
              <SortDropdown>
                {this.props.sort.map((sort, i) => {
                  return (
                    <Sort
                      key={i}
                      onClick={this.setSort.bind(null, sort.sort, sort.sortDir)}
                      className={
                        (this.state.sort === sort.sort &&
                          this.state.sortDir === sort.sortDir) ||
                        (this.props.isSearch &&
                          sort.sort === 'sale_count' &&
                          this.state.sort === '') ||
                        (!this.props.isSearch &&
                          sort.sort === 'sale_count' &&
                          this.state.sort === '')
                          ? 'active'
                          : ''
                      }
                    >
                      {sort.name}
                    </Sort>
                  );
                })}
              </SortDropdown>
            )}
          </>
        ) : (
          ''
        )}
      </ButtonWrapper>
    );
  }

  public render() {
    let { paginationCurrent } = this.props;
    const { paginationTotal, onPageChanged } = this.props;
    if (typeof paginationCurrent === 'string') {
      paginationCurrent = parseInt(paginationCurrent, 10);
    }
    return (
      <MediaContextProvider>
        <Media greaterThanOrEqual="md">
          <Wrapper
            onClickOutside={this.setVisibleDropdownIndex.bind(null, null)}
          >
            {this.getWrappedRender()}

            {paginationCurrent && paginationTotal && onPageChanged ? (
              <Pagination
                current={paginationCurrent}
                total={paginationTotal}
                onPageChanged={onPageChanged}
                inFilter={true}
              />
            ) : (
              ''
            )}
          </Wrapper>
        </Media>

        <Media lessThan="md">
          <Wrapper
            onClickOutside={this.setVisibleDropdownIndex.bind(null, null)}
          >
            {this.getWrappedRender()}
          </Wrapper>
          {paginationCurrent && paginationTotal && onPageChanged ? (
            <Pagination
              current={paginationCurrent}
              total={paginationTotal}
              onPageChanged={onPageChanged}
              inFilter={false}
            />
          ) : (
            ''
          )}
        </Media>
      </MediaContextProvider>
    );
  }
}

export default Filter;
