import { createSelector } from 'reselect';

const productDomain = state => state.product;

const activeVariantDomain = createSelector(
  productDomain,
  product => product.activeVariantById || {},
);

export const currentProductIdSelector = createSelector(
  productDomain,
  product => product.currentProductId,
);

export const activeVariantByIdSelector = createSelector(
  activeVariantDomain,
  currentProductIdSelector,
  (product, currentId) => product[currentId] || {},
);
