import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { ErrorPopup, InputStyledStyles, InputStyles } from '../form/FormStyles';
import { rem } from 'polished';
import { CheckboxStyled } from '../form/Checkbox';
import { ContactButtonPrimary, ContactHeading } from './ContactStyles';
import { isEmailValid } from '../../../utilities';
import EmailAutocomplete from '../form/EmailAutocomplete';
import { Loader } from '../Loader/Loader';
import { isPhone } from '../../../utilities/validators';

const InputStyledContactForm = css`
  border-color: ${({ theme }) => theme.color.gray87};
  font-size: ${rem(16)};
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(16)};
  }
  &::placeholder {
    color: ${({ theme }) => theme.color.text};
  }
`;

const Wrapper = styled.form`
  background: ${({ theme }) => theme.color.background};
  padding: ${rem(20)};
  ${({ theme }) => theme.media('sm')} {
    padding: ${rem(28)};
  }
`;

const ContactFormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: ${rem(-10)};
  margin-left: ${rem(-10)};
`;

const ContactFormCol = styled.div`
  flex: 0 0 ${({ theme }) => theme.grid.col.col12};
  padding-right: ${rem(10)};
  padding-left: ${rem(10)};
  ${({ theme }) => theme.media(600)} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col6};
  }
`;

const ContactInputCss = css`
  ${InputStyles};
  ${InputStyledStyles};
  ${InputStyledContactForm};
  display: block;
  width: 100%;
  margin-bottom: ${rem(8)};
`;

const ContactInput = styled.input`
  ${ContactInputCss};
`;

const ContactInputEmail = styled(EmailAutocomplete)`
  ${ContactInputCss};
`;

const ContactTextarea = styled.textarea`
  ${InputStyles};
  ${InputStyledStyles};
  ${InputStyledContactForm};
  display: block;
  width: 100%;
  height: auto;
`;

const ContactCheckbox = styled(CheckboxStyled)`
  ${InputStyledContactForm};
  margin: 0;
  &:before {
    border-color: ${({ theme }) => theme.color.gray87};
  }
  &.error {
    :before {
      border-color: ${({ theme }) => theme.color.error};
    }
  }
`;

const Submit = styled(ContactButtonPrimary)``;

const Success = styled.p`
  color: ${({ theme }) => theme.color.success};
`;

const ContactFormHeading = styled(ContactHeading)`
  margin-top: 0;
`;

interface Props {
  dispatch: (action: any) => void;
}

interface States {
  loading: boolean;
  valueName: string;
  valueOrganization: string;
  valueEmail: string;
  valuePhone: string;
  valueMessage: string;
  errorName: string;
  errorOrganization: string;
  errorEmail: string;
  errorPhone: string;
  errorMessage: string;
  agreeWithTerms: boolean | null;
  formSuccessfullySent: boolean;
  formSendError: boolean;
}

class ContactForm extends React.PureComponent<Props, States> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      valueName: '',
      valueOrganization: '',
      valueEmail: '',
      valuePhone: '',
      valueMessage: '',
      errorName: '',
      errorOrganization: '',
      errorEmail: '',
      errorPhone: '',
      errorMessage: '',
      agreeWithTerms: null,
      formSuccessfullySent: false,
      formSendError: false,
    };
  }

  public validateForm = (e) => {
    e.preventDefault();
    if (this.state.formSuccessfullySent) {
      this.setState({ formSuccessfullySent: false });
    }
    if (this.state.valueName === '') {
      this.setState({ errorName: __('Zadajte vaše meno') });
    }
    if (this.state.valueEmail === '') {
      this.setState({ errorEmail: __('Zadajte váš email') });
    } else {
      if (!isEmailValid(this.state.valueEmail)) {
        this.setState({ errorEmail: __('Prosím, skontrolujte email') });
      }
    }
    if (this.state.valueName === '') {
      this.setState({ errorName: __('Zadajte vaše meno') });
    }
    if (this.state.valueMessage === '') {
      this.setState({ errorMessage: __('Zadajte vašu správu') });
    }
    if (this.state.agreeWithTerms === null) {
      this.setState({ agreeWithTerms: false });
    }
    if (!isPhone(this.state.valuePhone)) {
      this.setState({
        errorPhone: __('Nevalídne mobilné číslo'),
      });
    }
    if (
      this.state.errorName !== '' ||
      this.state.errorOrganization !== '' ||
      this.state.errorEmail !== '' ||
      this.state.errorPhone !== '' ||
      this.state.errorMessage !== '' ||
      !this.state.agreeWithTerms
    ) {
      return;
    }

    this.props.dispatch(this.submitForm());
  };

  public changeName = (e) => {
    if (this.state.errorName !== '') {
      this.setState({ errorName: '' });
    }
    this.setState({
      valueName: e.target.value,
    });
  };

  public changeOrganization = (e) => {
    if (this.state.errorOrganization !== '') {
      this.setState({ errorOrganization: '' });
    }
    this.setState({
      valueOrganization: e.target.value,
    });
  };

  public changeEmail = (e) => {
    if (this.state.errorEmail !== '') {
      this.setState({ errorEmail: '' });
    }
    this.setState({
      valueEmail: e.target.value,
    });
  };

  public changePhone = (e) => {
    if (this.state.errorPhone !== '') {
      this.setState({ errorPhone: '' });
    }
    this.setState({
      valuePhone: e.target.value,
    });
  };

  public changeMessage = (e) => {
    if (this.state.errorMessage !== '') {
      this.setState({ errorMessage: '' });
    }
    this.setState({
      valueMessage: e.target.value,
    });
  };

  public submitForm = () => {
    return async (dispatch, getState, API) => {
      this.setState({ loading: true });
      try {
        const currentState = getState();
        await API.contactForm(
          {
            xAcceptLanguage: currentState.general.lang,
          },
          {
            email: this.state.valueEmail,
            organization: this.state.valueOrganization,
            name: this.state.valueName,
            phone: this.state.valuePhone,
            message: this.state.valueMessage,
          },
        );
        this.setState({
          formSuccessfullySent: true,
          valueEmail: '',
          valueOrganization: '',
          valueMessage: '',
          valueName: '',
          valuePhone: '',
          agreeWithTerms: null,
        });
      } catch (e) {
        this.setState({ formSendError: true });
      }
      this.setState({ loading: false });
    };
  };

  public setAgreeWithTerms = (e) => {
    this.setState({ agreeWithTerms: e.target.checked });
  };

  public render() {
    const isMobile = (window && window.innerWidth) < 600;
    return (
      <Loader loading={this.state.loading}>
        <Wrapper onSubmit={this.validateForm} className="contactForm">
          <ContactFormHeading className="MarginTopNone">
            <strong>{__('Kontaktný formulár')}</strong>
          </ContactFormHeading>

          {this.state.formSuccessfullySent ? (
            <Success>{__('Odoslanie prebehlo úspešne')}</Success>
          ) : (
            ''
          )}
          {this.state.formSendError ? (
            <ErrorPopup>
              {__('Nepodarilo sa odoslať formulár. Skúste to, prosím, neskôr')}
            </ErrorPopup>
          ) : (
            ''
          )}

          <ContactFormRow>
            <ContactFormCol>
              <ContactInput
                name="name"
                placeholder={`${__('Vaše meno')} (${__('povinné')})`}
                value={this.state.valueName}
                onChange={this.changeName}
              />
              {this.state.errorName !== '' ? (
                <ErrorPopup>{this.state.errorName}</ErrorPopup>
              ) : (
                ''
              )}

              <ContactInput
                name="organization"
                placeholder={`${__('Firma / organizácia')}`}
                value={this.state.valueOrganization}
                onChange={this.changeOrganization}
              />

              <ContactInputEmail
                name="email"
                placeholder={`${__('Váš email')} (${__('povinné')})`}
                value={this.state.valueEmail}
                onChange={this.changeEmail}
              />
              {this.state.errorEmail !== '' ? (
                <ErrorPopup>{this.state.errorEmail}</ErrorPopup>
              ) : (
                ''
              )}

              <ContactInput
                name="phone"
                type="tel"
                placeholder={`${__('Telefón')}`}
                value={this.state.valuePhone}
                onChange={this.changePhone}
              />
              {this.state.errorPhone !== '' ? (
                <ErrorPopup>{this.state.errorPhone}</ErrorPopup>
              ) : (
                ''
              )}
            </ContactFormCol>

            <ContactFormCol>
              <ContactTextarea
                rows={isMobile ? 6 : 9}
                name="message"
                placeholder={`${__('Vaša správa')}`}
                value={this.state.valueMessage}
                onChange={this.changeMessage}
              />
              {this.state.errorMessage !== '' ? (
                <ErrorPopup>{this.state.errorMessage}</ErrorPopup>
              ) : (
                ''
              )}
            </ContactFormCol>
          </ContactFormRow>

          <ContactCheckbox
            id="gdpr"
            name="gdpr"
            value="1"
            onChange={this.setAgreeWithTerms}
            className={
              this.state.agreeWithTerms === null || this.state.agreeWithTerms
                ? ''
                : 'error'
            }
          >
            {`${__('Súhlasím so')} `}
            <a href="/gdpr" target="_blank">
              {__('spracovaním osobných údajov')}
            </a>
          </ContactCheckbox>
          {this.state.agreeWithTerms === null || this.state.agreeWithTerms ? (
            ''
          ) : (
            <ErrorPopup>
              {__(
                'Formulár nie je možné odoslať bez súhlasu o spracovaní osobných údajov.',
              )}
            </ErrorPopup>
          )}

          <Submit type="submit" className="primary">
            {__('Odoslať')}
          </Submit>
        </Wrapper>
      </Loader>
    );
  }
}

export default ContactForm;
