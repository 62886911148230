import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import Alert from '../../components/Alert/Alert';
import {
  Body,
  Head,
  Table,
  TableWrapper,
  Td,
  Th,
  Tr,
} from '../../components/_helpers/Table/ResponsiveTable';
import {
  MyAccountBtnIcon,
  MyAccountTextBtn,
  MyAccountBtnContent,
  MyAccountText,
} from './MojUcet';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { MainBlock } from './Ucet';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  loadRenumerationData,
  removeSkvelkoAwardedUserAndReload,
} from './actions';
import API, { ThenArg } from '../../services/API';
import ChooseModal from '../../components/_helpers/Modal/ChooseModal';
import { push } from 'react-router-redux';
import { isCompany, isSchool, prop } from '../../utilities';
import { loadCmsContent } from '../App/actions';

const StyledTd = styled(Td)`
  padding-top: ${rem(7)};
  padding-bottom: ${rem(7)};
`;

const Buttons = styled.div``;

const AlertWrapper = styled.div`
  margin-bottom: ${rem(15)};
`;

const AwardedInfoAlert = styled(Alert)`
  margin-top: ${rem(40)};
`;

interface RemunerationSystemAwardedStates {
  renumerationSettingsModalClassId: number | null;
  removeSkvelkoAwardedPersonId: number | null;
}

interface RemunerationSystemAwardedProps {
  dispatch: (a: any) => void;
  setTitle: (title: string, link?: string | null) => void;
  renumerationData: {
    customerDetails: ThenArg<typeof API.getCustomerDetails>;
    creditHistory: ThenArg<typeof API.searchSkvelkoCreditHistory>;
    awardedUsers: ThenArg<typeof API.searchSkvelkoAwardedUsers>;
    skvelkoClasses: ThenArg<typeof API.searchSkvelkoClass>;
  };
  currentPath: string;
  cmsContent?: any;
  user?: any;
}

class RemunerationSystemAwarded extends React.PureComponent<
  RemunerationSystemAwardedProps,
  RemunerationSystemAwardedStates
> {
  public static async getInitialProps(props) {
    const promises: any[] = [];

    promises.push(props.dispatch(loadRenumerationData()));
    promises.push(props.dispatch(loadCmsContent('TEACHER')));
    promises.push(props.dispatch(loadCmsContent('COMPANY_USER')));

    await Promise.all(promises);
  }

  constructor(props) {
    super(props);

    this.state = {
      renumerationSettingsModalClassId: null,
      removeSkvelkoAwardedPersonId: null,
    };

    const title = __('Používatelia účtu');
    props.setTitle(title);
    props.setBreadcrumbsItems([{ name: title, url: props.currentPath }]);
  }

  public handleRemoveSkvelkoAwardedUserClick = (skvelkoAwardedUserId) => {
    this.setState({ removeSkvelkoAwardedPersonId: skvelkoAwardedUserId });
  };

  public handleModalSkvelkoAwardedUserRemove = () => {
    this.props.dispatch(
      removeSkvelkoAwardedUserAndReload(
        this.state.removeSkvelkoAwardedPersonId,
      ),
    );
    this.handleCloseModalSkvelkoAwardedUserRemove();
  };

  public handleCloseModalSkvelkoAwardedUserRemove = () => {
    this.setState({ removeSkvelkoAwardedPersonId: null });
  };

  public handleAddAwardedPersonButtonClick = () => {
    this.props.dispatch(push('/my-account/renumeration/new-user'));
  };

  public render() {
    const { renumerationData, cmsContent, user } = this.props;

    let sitemapUid: string = '';
    if (isSchool(user)) {
      sitemapUid = 'TEACHER';
    }
    if (isCompany(user)) {
      sitemapUid = 'COMPANY_USER';
    }

    const awardedUsers = prop(renumerationData, 'awardedUsers.search', []);
    const body = prop(
      cmsContent,
      `${sitemapUid}.content.json_content.body`,
      '',
    );

    return (
      <React.Fragment>
        {this.state.removeSkvelkoAwardedPersonId && (
          <ChooseModal
            title={__('Odstránenie používateľa účtu')}
            bodyText="Naozaj chcete odstrániť používateľa účtu?"
            button1Text={__('Nie')}
            button2Text={__('Áno')}
            onCloseButtonClick={this.handleCloseModalSkvelkoAwardedUserRemove}
            onButton1Click={this.handleCloseModalSkvelkoAwardedUserRemove}
            onButton2Click={this.handleModalSkvelkoAwardedUserRemove}
          />
        )}

        <MainBlock className={'no-top-padding'}>
          {awardedUsers.length ? (
            <TableWrapper>
              <Table>
                <Head>
                  <Tr>
                    <Th>{__('Meno')}</Th>
                    <Th>{__('E-mail')}</Th>
                    <Th className="empty" />
                  </Tr>
                </Head>
                <Body>
                  {awardedUsers.map((item, i) => {
                    return (
                      <Tr key={i}>
                        <StyledTd>
                          {this.getUserNameFromAwardedUser(item.awardedUser)}
                        </StyledTd>
                        <StyledTd>{item.awardedUser.email}</StyledTd>
                        <StyledTd className="buttons">
                          <Buttons>
                            <MyAccountTextBtn
                              onClick={this.handleRemoveSkvelkoAwardedUserClick.bind(
                                this,
                                item.id,
                              )}
                            >
                              <MyAccountBtnContent>
                                <MyAccountBtnIcon icon="trash" />
                                <MyAccountText>Zmaž</MyAccountText>
                              </MyAccountBtnContent>
                            </MyAccountTextBtn>
                          </Buttons>
                        </StyledTd>
                      </Tr>
                    );
                  })}
                </Body>
              </Table>
            </TableWrapper>
          ) : (
            <AlertWrapper>
              <Alert>
                {__('Používateľa účtu')}{' '}
                <strong>
                  {__('pridáte kliknutím na "Pridať používateľa"')}
                </strong>
              </Alert>
            </AlertWrapper>
          )}
          <ButtonStyled
            className="primary lg"
            onClick={this.handleAddAwardedPersonButtonClick}
          >
            {__('Pridať používateľa')}
          </ButtonStyled>
          {body ? (
            <AwardedInfoAlert>
              <div
                dangerouslySetInnerHTML={{
                  __html: body,
                }}
              />
            </AwardedInfoAlert>
          ) : (
            ''
          )}
        </MainBlock>
      </React.Fragment>
    );
  }

  public getUserNameFromAwardedUser(item) {
    if (item.name && item.surname) {
      return item.name + ' ' + item.surname;
    } else if (item.name) {
      return item.name;
    } else if (item.surname) {
      return item.surname;
    }
    return '---';
  }

  public getUserNameOrEmailFromAwardedUser(item) {
    if (item.name && item.surname) {
      return item.name + ' ' + item.surname;
    } else if (item.name) {
      return item.name;
    } else if (item.surname) {
      return item.surname;
    } else {
      return item.email;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    renumerationData: state.myAccount.renumerationData,
    cmsContent: state.general.cmsContent,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'RemunerationSystemAwarded' })(
    RemunerationSystemAwarded,
  ),
);
