import * as React from 'react';
import { Field, Form } from 'react-final-form';
import styled, { css } from 'styled-components';
import { composeValidators, email, required } from '../../utilities/validators';
import { InputStyled } from '../_helpers/form/Input';
import { ErrorPopup } from '../_helpers/form/FormStyles';
import { SelectStyled } from '../_helpers/form/Select';
import { __ } from 'react-i18n';
import { CheckboxStyled } from '../_helpers/form/Checkbox';
import { ButtonStyled } from '../_helpers/form/Button';
import { Loader } from '../_helpers/Loader/Loader';
import API from '../../services/API';

interface ContestGeneratedFormProps {
  nameJson: string;
  customJson: string;
}

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 10px;
  flex-direction: column;

  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
  }
`;

const FormCol = styled.div`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
`;

const InputRow = styled.div`
  margin-bottom: 15px;
`;

const FormLabel = styled.div``;

const FormInputCss = css`
  width: 100%;
  ${(props: any) => {
    return props.error ? 'border:2px solid red;' : '';
  }}
`;

const FormInput = styled(InputStyled)`
  ${FormInputCss};
`;

const StyledSelect = styled(SelectStyled)`
  ${FormInputCss};
`;

const ContestGeneratedForm = (props: ContestGeneratedFormProps) => {
  const [data, setData] = React.useState<{
    nameObj: { id: string; value: string };
    customObj: { text: string; values: [{ name: string; value: string }] };
  }>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSent, setIsSent] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (props.nameJson && props.customJson) {
      const parsedName = JSON.parse(
        props.nameJson
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/\<br \/\>/g, '')
          .replace(/\<\/p\>/g, '')
          .replace(/\<p\>/g, '')
          .replace(/\</g, '[')
          .replace(/\>/g, ']')
          .replace(/ /g, ''),
      );

      const parsedCustom = JSON.parse(
        props.customJson
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/\<br \/\>/g, '')
          .replace(/\<\/p\>/g, '')
          .replace(/\<p\>/g, '')
          .replace(/\</g, '[')
          .replace(/\>/g, ']')
          .replace(/ /g, ''),
      );

      setData({ nameObj: parsedName, customObj: parsedCustom });
    }
  }, []);

  const onSubmit = async (values) => {
    setIsLoading(true);
    await API.sendGeneratedContest(
      {},
      {
        name: values.name,
        surname: values.surname,
        email: values.email,
        custom_value: values.custom,
        se_id: data?.nameObj.id,
        se_value: data?.nameObj.value,
      },
    );
    setIsLoading(false);
    setIsSent(true);
  };

  if (data && !isSent) {
    return (
      <Loader loading={isLoading}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormWrapper>
                <FormCol>
                  <Field name="name" validate={required}>
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> Meno </FormLabel>
                        <FormInput
                          {...input}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>

                  <Field
                    name="email"
                    validate={composeValidators(required, email)}
                  >
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> Email </FormLabel>
                        <FormInput
                          {...input}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>
                </FormCol>
                <FormCol>
                  <Field name="surname" validate={required}>
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> Priezvisko </FormLabel>
                        <FormInput
                          {...input}
                          error={meta.error && meta.touched}
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>

                  <Field name="custom" validate={required} type="select">
                    {({ input, meta }) => (
                      <InputRow
                        className={meta.error && meta.touched ? 'error' : ''}
                      >
                        <FormLabel> {data.customObj.text} </FormLabel>
                        <StyledSelect
                          {...input}
                          error={meta.error && meta.touched}
                        >
                          <option hidden={true} value="">
                            {__('Vyberte')}
                          </option>
                          {data.customObj.values.map((option, i) => {
                            return (
                              <option key={i} value={option.value}>
                                {option.name}
                              </option>
                            );
                          })}
                        </StyledSelect>
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </InputRow>
                    )}
                  </Field>
                </FormCol>
              </FormWrapper>

              <Field name="terms_accept" validate={required} type="checkbox">
                {({ input, meta }) => (
                  <InputRow
                    className={meta.error && meta.touched ? 'error' : ''}
                  >
                    <CheckboxStyled {...input} value="1" id="terms_accept">
                      <span>
                        Súhlasím so zasielaním informačných emailov a
                        marketingových ponúk tovarov a služieb
                      </span>
                    </CheckboxStyled>
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                  </InputRow>
                )}
              </Field>

              <ButtonStyled className="primary" type="submit" disabled={isSent}>
                {__('Odoslať')}
              </ButtonStyled>
            </form>
          )}
        />
      </Loader>
    );
  } else if (data && isSent) {
    return <div>{__('Boli ste zaradený do sútaže')}</div>;
  } else {
    return <></>;
  }
};

export default ContestGeneratedForm;
