import * as React from 'react';
import styled, { css } from 'styled-components';
import { Container, Row } from '../../../theme/libraries/grid';
import { Button } from '../../_helpers/form/Button';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import { CloseIconWhite } from '../../_helpers/Icon/Close';
import { isSsr } from '../../../utilities';

interface MobileOverlayProps {
  title: string;
  visible: boolean;
  children: any;
  onClose: () => void;
}

function MobileOverlay({
  title = '',
  visible = false,
  children = '',
  onClose,
}: MobileOverlayProps) {
  return (
    <Wrapper visible={visible}>
      <MobileHeader>
        <Title>{title}</Title>
        <Close onClick={onClose}>
          <CloseCircle>
            <CloseIconWhite />
          </CloseCircle>
          <CloseText>{__('Zavrieť')}</CloseText>
        </Close>
      </MobileHeader>
      <Container className="base">
        <Row>{children}</Row>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: none;
  background: ${({ theme }) => theme.color.gray16};
  width: 100%;
  height: 100%;
  padding-top: ${rem(60)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 51;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ${({ visible }) => {
    if (visible && !isSsr() && window.innerWidth <= 992) {
      return css`
        display: block;
      `;
    }
    return css``;
  }};
  ${({ theme }) => theme.media('md')} {
    display: none;
  }
`;

const MobileHeader = styled.header`
  background: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  line-height: 1;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;

const Title = styled.p`
  font-size: ${rem(24)};
  padding: ${rem(18)} ${rem(48)} ${rem(18)} ${rem(24)};
  margin: 0;
`;

const Close = styled(Button)`
  line-height: ${20 / 16};
  position: absolute;
  top: ${rem(8)};
  right: ${rem(10)};
`;

const CloseCircle = styled.div`
  display: inline-block;
  border: ${rem(2)} solid ${({ theme }) => theme.color.white};
  border-radius: 50%;
  padding: ${rem(3)};
  line-height: ${rem(20)};
`;

const CloseText = styled.div`
  font-size: ${rem(10)};
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: ${rem(-0.5)}
  margin-top: ${rem(2)};
`;

export { MobileOverlay };
