import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Clearfix } from './h5b-helpers';

export const grid = {
  padding: 30,
  col: {
    col1: (1 / 12) * 100 + '%',
    col2: (2 / 12) * 100 + '%',
    col3: (3 / 12) * 100 + '%',
    col4: (4 / 12) * 100 + '%',
    col5: (5 / 12) * 100 + '%',
    col6: (6 / 12) * 100 + '%',
    col7: (7 / 12) * 100 + '%',
    col8: (8 / 12) * 100 + '%',
    col9: (9 / 12) * 100 + '%',
    col10: (10 / 12) * 100 + '%',
    col11: (11 / 12) * 100 + '%',
    col12: (12 / 12) * 100 + '%',
  },
};

const containerSizes = [
  {
    name: 'newsletter',
    width: 650,
  },
  {
    name: 'narrow',
    width: 960,
  },
  {
    name: 'base',
    width: 1170,
  },
  {
    name: 'swiper',
    width: 1352,
  },
  {
    name: 'wide',
    width: 1440,
  },
];

export const Row = styled(Clearfix)`
  margin-right: ${rem(-grid.padding / 2)};
  margin-left: ${rem(-grid.padding / 2)};
`;

export const Col = styled.div`
  float: left;
  min-height: ${rem(1)};
  width: 100%;
  padding-right: ${rem(grid.padding / 2)};
  padding-left: ${rem(grid.padding / 2)};
`;

const ContainerStyles = css`
  padding-right: ${rem(grid.padding / 2)};
  padding-left: ${rem(grid.padding / 2)};
  margin: auto;
  ${containerSizes.map((item) => {
    return `&.${item.name} {max-width: ${rem(item.width)};}`;
  })}
`;

export const Container = styled.div`
  ${ContainerStyles};
`;

export const ContainerClearfix = styled(Clearfix)`
  ${ContainerStyles};
`;

const leftPanelWidth = 245;
const panelGap = 40;

export const CategoriesColStyles = css`
  display: none;
  width: ${({ theme }) => rem(leftPanelWidth + theme.grid.padding)};
  position: relative;
  ${({ theme }) => theme.media('md')} {
    display: block;
    margin-bottom: ${rem(40)};
  }
`;

export const MainColStyles = css`
  ${({ theme }) => theme.media('md')} {
    padding-left: ${({ theme }) =>
      rem(leftPanelWidth + panelGap + theme.grid.padding / 2)};
    margin-left: ${({ theme }) => rem(-(leftPanelWidth + theme.grid.padding))};
  }
`;

export const CategoriesCol = styled(Col)`
  ${CategoriesColStyles};
`;

export const MainCol = styled(Col)`
  ${MainColStyles};
`;

export const Sidebar = styled(Col)`
  font-size: ${rem(14)};
  ${({ theme }) => theme.media('md')} {
    width: ${({ theme }) => theme.grid.col.col3};
  }
`;

export const SidebarTitle = styled.p`
  font-size: ${rem(16)};
  font-weight: 700;
`;

export const ContentWithSidebar = styled(Col)`
  ${({ theme }) => theme.media('md')} {
    width: ${(props: any) =>
      props.fullWidth ? '100%' : ({ theme }) => theme.grid.col.col9};
  }
`;

export const MainContainer: any = styled.div`
  width: ${({ theme }) => theme.mainContainerSize || '1170px'};
  margin: 0 auto;
  padding: 0 15px;
  min-height: ${(props: any) => props.minHeight || '100px'};

  @media all and (max-width: ${({ theme }) =>
      theme.mainContainerSize || '1170px'}) {
    width: 100%;
    margin: 0;
  }
`;
