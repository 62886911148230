import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { transitions } from '../../../theme/libraries/transition';
import Icon from '../Icon/Icon';

export const ButtonDisableFocusOutline = css`
  &:focus {
    outline: none;
  }
`;

export const ButtonStyles = css`
  display: inline-block;
  background: transparent;
  border: none;
  color: inherit;
  font-size: 100%;
  line-height: 1.15;
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  padding: 0;
  appearance: none;
  cursor: pointer;
  box-shadow: none;
  ${({ theme }) => theme.media('sm')} {
    font-size: ${({ theme }) => rem(theme.form.font.size)};
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:hover {
    text-decoration: none;
  }
  ${ButtonDisableFocusOutline}
`;

export const ButtonDefault = styled.button`
  ${ButtonStyles};
`;

const ButtonStyledNewStylesCss = css`
  font-weight: normal;
  font-size: ${rem(18)};
  padding-top: ${rem(8)};
  padding-bottom: ${rem(8)};
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(16)};
  }
`;

export const ButtonStyledNewStyles = css`
  ${ButtonStyledNewStylesCss};
  &.primary {
    ${ButtonStyledNewStylesCss};
  }
`;

export const ButtonStyledStyles = css`
  background: ${({ theme }) => theme.color.white};
  border: ${({ theme }) => rem(theme.form.border.width)}
    ${({ theme }) => theme.form.border.style}
    ${({ theme }) => theme.form.border.color};
  border-radius: ${({ theme }) => rem(theme.form.border.radius)};
  padding: ${({ theme }) => rem(theme.form.padding.horizontal)}
    ${({ theme }) => rem(theme.form.padding.vertical)};
  ${transitions('background, border-color, color, box-shadow')};
  outline: none;
  &:disabled {
    opacity: 0.8;
  }

  &:focus {
    border-color: ${({ theme }) => theme.form.border.colorFocus};
    box-shadow: ${({ theme }) => theme.boxShadow.primary};
  }

  &:not(:disabled):hover {
    background: ${({ theme }) => theme.form.border.color};
  }

  &.primary {
    background: ${({ theme }) => theme.color.gradient.primaryHover};
    background-size: 100% 200%;
    border-color: transparent;
    color: ${({ theme }) => theme.color.white};
    font-weight: 700;

    &:not(:disabled):hover {
      background: ${({ theme }) => theme.color.gradient.primaryHover};
      background-size: 100% 200%;
      background-position: 0 100%;
      border-color: transparent;
    }
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.lg {
    padding: ${rem(14)} ${rem(30)};
    ${({ theme }) => theme.media('sm')} {
      font-size: ${rem(16)};
      padding: ${rem(14)} ${rem(40)};
    }
  }

  &.separation {
    margin-left: ${rem(16)};
  }

  &.separationBottom {
    margin-bottom: ${rem(16)};
    ${({ theme }) => theme.media('xs')} {
      margin-bottom: 0;
    }
  }
`;

export const ButtonStyled = styled(ButtonDefault)`
  ${ButtonStyledStyles};
`;

const ButtonIconWrapper = styled(Icon)`
  ${({ theme }) => theme.size(18)};
  margin: ${rem(-3)} ${rem(-1)};
  transform: translateY(${rem(-1)});
  ${ButtonStyled}.primary & {
    fill: ${({ theme }) => theme.color.white};
  }
`;

export const ButtonMoreStyles = css`
  border: ${rem(2)} solid ${({ theme }) => theme.form.border.color};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  font-size: ${rem(14)};
  padding: ${rem(8)} ${rem(18)};
  ${({ theme }) => theme.transitions('background-color, border-color, color')};
`;

export const ButtonMoreHoverStyles = css`
  background: ${({ theme }) => theme.color.primary};
  border-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
`;

interface ButtonProps {
  type?: 'submit' | 'reset';
  icon?: string;
  disabled?: boolean;
  children: any;
  className?: string;
  click?: (e?) => void; // deprecated
  onClick?: (e?) => void;
}

export class Button extends React.Component<ButtonProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { type, disabled, children, className } = this.props;
    return (
      <ButtonDefault
        type={type ? type : 'button'}
        disabled={disabled}
        className={className}
        onClick={this.handleClick}
      >
        {children}
      </ButtonDefault>
    );
  }

  private handleClick = (e) => {
    if (this.props.click) {
      this.props.click(e);
    }
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };
}

interface ButtonIconProps {
  className?: string;
  icon: string;
}

export class ButtonIcon extends React.Component<ButtonIconProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { className, icon } = this.props;
    return <ButtonIconWrapper icon={icon} className={className} />;
  }
}
