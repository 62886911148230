import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { loadCartInfoText } from '../../containers/Cart/actions';

export const Wrapper = styled.div``;

export const InfoLink = styled.a`
  color: #c2203f;
  text-decoration: underline;
`;

interface CartInfoBoxProps {
  dispatch: any;
  cartInfoText: string;
}

const CartInfoBox = (props: CartInfoBoxProps) => {
  const { dispatch, cartInfoText } = props;

  React.useEffect(() => {
    dispatch(loadCartInfoText());
  }, []);

  if (cartInfoText) {
    return (
      <Wrapper
        dangerouslySetInnerHTML={{
          __html: cartInfoText,
        }}
      ></Wrapper>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  return {
    cartInfoText: state.cart.cartInfoText,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartInfoBox' })(CartInfoBox),
);
