import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import ImagePlaceholder from '../../_helpers/Image/ImagePlaceholder';
import { ButtonStyles } from '../../_helpers/form/Button';
import MetaTags from '../../MetaTags/MetaTags';
import { Link } from 'react-router';

const ClickableClass = css`
  &.clickable:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: ${rem(20)} 0;
`;

const Item = styled.li`
  display: flex;
  flex: 1 1 ${({ theme }) => theme.grid.col.col12};
  list-style: none;
  margin: ${rem(20)} 0;
  ${({ theme }) => theme.media('sm')} {
    flex: 1 1 ${({ theme }) => theme.grid.col.col6};
  }
`;

const ThumbWrapper = styled.div`
  flex: 0 0 ${rem(80)};
  margin-right: ${rem(20)};
  ${ClickableClass};
`;

const Thumb = styled(ImagePlaceholder)`
  border-radius: 50%;
`;

const Body = styled.div`
  font-size: ${rem(14)};
  min-width: 0;
`;

const Title = styled.p`
  font-size: ${rem(16)};
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  ${ClickableClass};
`;

const Address = styled.p`
  margin: ${rem(5)} 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const More = styled(Link)`
  ${ButtonStyles};
  color: ${({ theme }) => theme.color.link};
`;

interface StoreItemProps {
  item: any;
  onShopClick: (url: string) => void;
}

class StoreItem extends React.PureComponent<StoreItemProps> {
  public render() {
    const { item } = this.props;
    const placeholder = '/images/placeholder-100.png';
    const placeholder2x = '/images/placeholder-100@2x.png';
    return (
      <Item>
        <ThumbWrapper
          className={item.url ? 'clickable' : ''}
          onClick={
            item.url ? this.props.onShopClick.bind(null, item.url) : null
          }
        >
          <Thumb
            src={
              item.content.photogallery[0]
                ? item.content.photogallery[0].document_path
                : null
            }
            placeholder={placeholder}
            retinaPlaceholder={placeholder2x}
            w={100}
            h={100}
          />
        </ThumbWrapper>
        <Body>
          <Title
            className={item.url ? 'clickable' : ''}
            onClick={
              item.url ? this.props.onShopClick.bind(null, item.url) : null
            }
          >
            {item.name}
          </Title>
          <Address>{item.address}</Address>
          {item.url && (
            <More onClick={this.props.onShopClick.bind(null, item.url)}>
              {__('Detail predajne')}
            </More>
          )}
        </Body>
      </Item>
    );
  }
}

interface StoreListProps {
  items: any;
  onShopClick: (url: string) => void;
}

class StoreList extends React.PureComponent<StoreListProps> {
  public render() {
    const { items } = this.props;
    return (
      <Wrapper>
        <MetaTags tags={{ title: `Daffer.sk - Zoznam predajní` }} />
        {items &&
          items.map((item, i) => {
            return (
              <StoreItem
                key={i}
                item={item}
                onShopClick={this.props.onShopClick}
              />
            );
          })}
      </Wrapper>
    );
  }
}

export default StoreList;
