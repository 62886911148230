import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  loadSchoolArticle,
  loadSchoolArticleConnections,
} from '../App/actions';
import { Container } from '../../theme/libraries/grid';
import {
  Content,
  Title,
  TitleSecondary,
} from '../../components/_layout/Page/ContentContainer';
import styled from 'styled-components';
import { rem } from 'polished';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { URL_SCHOOL_ARTICLES } from '../../constants';
import { formatStringDate, parseTextWithShortcode } from '../../utilities';
import { __ } from 'react-i18n/lib';
import { Loader } from '../../components/_helpers/Loader/Loader';
import MetaTags from '../../components/MetaTags/MetaTags';
import { ProductColWrapper, ProductsRowWrapper } from '../Category/Category';
import ProductPreview from '../../components/_helpers/product/ProductPreview';
import FlipBook from '../../components/FlipBook/FlipBook';
import ContestGeneratedForm from '../../components/ContestGeneratedForm/ContestGeneratedForm';

const ContainerStyled = styled(Container)`
  ${({ theme }) => theme.media(0, 'md')} {
    padding-right: 0;
    padding-left: 0;
  }
`;

const ContentStyled = styled(Content)`
  margin-bottom: ${rem(10)};
  padding: ${rem(10)} ${rem(40)} ${rem(40)};
`;

const TitleStyled = styled(Title)`
  margin-bottom: ${rem(30)};
`;

const Published = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.color.blue};
  margin: 0;
`;

const ProductsWrapper = styled.div`
  clear: left;
`;

export interface SchoolArticleProps {
  dispatch: any;
  contentId: number;
  lang: string;
  schoolArticle: any;
  schoolArticleLoading: boolean;
  schoolArticleConnections: any;
  schoolArticleConnectionsLoading: boolean;
}

interface SchoolArticleState {}

class SchoolArticle extends React.Component<
  SchoolArticleProps,
  SchoolArticleState
> {
  public static async getInitialProps(parentProps) {
    const promises: any[] = [];
    promises.push(
      parentProps.dispatch(
        loadSchoolArticle(parentProps.contentId, parentProps.lang || 'sk'),
      ),
    );

    promises.push(
      parentProps.dispatch(
        loadSchoolArticleConnections(
          parentProps.contentId,
          parentProps.lang || 'sk',
        ),
      ),
    );

    await Promise.all(promises);

    return parentProps;
  }

  public render() {
    const {
      schoolArticle,
      schoolArticleLoading,
      schoolArticleConnections,
      schoolArticleConnectionsLoading,
      contentId,
    } = this.props;
    const article = schoolArticle[contentId];
    const connections = schoolArticleConnections[contentId];
    let metaTags = article?.json_content?.meta ? article.json_content.meta : {};

    if (article?.draft) {
      metaTags['robots'] = 'noindex';
    }

    return (
      <Loader loading={schoolArticleLoading || schoolArticleConnectionsLoading}>
        <ContainerStyled className="base">
          <ContentStyled>
            {metaTags ? (
              <MetaTags tags={metaTags} />
            ) : (
              <MetaTags tags={{ title: 'Daffer.sk' }} />
            )}

            <Breadcrumbs
              items={[
                { name: 'Pre školské zariadenia', url: URL_SCHOOL_ARTICLES },
                { name: 'Aktuality pre školy', url: URL_SCHOOL_ARTICLES },
                { name: article.name, url: article.url },
              ]}
            />

            <TitleStyled>{article.name}</TitleStyled>
            <Published>
              {__('Publikované')}:{' '}
              {formatStringDate(article.custom_date, 'D. MMM YYYY')}
            </Published>

            {parseTextWithShortcode(article.json_content.body).map(
              (item: any) => {
                if (item.type === 'text') {
                  return (
                    <Content
                      dangerouslySetInnerHTML={{
                        __html: item.value,
                      }}
                    />
                  );
                } else if (item.type === 'flipbook') {
                  return (
                    <FlipBook
                      contentId={contentId}
                      fileName={item.parameters.name}
                      pages={item.parameters.pages}
                    />
                  );
                } else if (item.type === 'formular-sutaz') {
                  return (
                    <ContestGeneratedForm
                      nameJson={item.parameters.name}
                      customJson={item.parameters.custom}
                    />
                  );
                }

                return '';
              },
            )}

            {connections?.products?.length ? (
              <ProductsWrapper>
                <TitleSecondary>
                  {__('Odporúčané produkty v eshope')}
                </TitleSecondary>
                <ProductsRowWrapper>
                  {connections.products.map((product) => {
                    return (
                      <ProductColWrapper key={product.product_id}>
                        <ProductPreview product={product} />
                      </ProductColWrapper>
                    );
                  })}
                </ProductsRowWrapper>
              </ProductsWrapper>
            ) : (
              ''
            )}
          </ContentStyled>
        </ContainerStyled>
      </Loader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    schoolArticle: state.general.schoolArticle,
    schoolArticleLoading: state.general.schoolArticleLoading,
    schoolArticleConnections: state.general.schoolArticleConnections,
    schoolArticleConnectionsLoading:
      state.general.schoolArticleConnectionsLoading,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SchoolArticle' })(SchoolArticle),
);
