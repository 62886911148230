import { loadOtherText } from '../../utilities';
import * as actionTypes from './constants';

export const setForgottenPasswordText = (forgottenPasswordText) => ({
  type: actionTypes.SET_FORGOTTEN_PASSWORD_TEXT,
  payload: {
    forgottenPasswordText,
  },
});

export const loadForgottenPasswordText = () =>
  loadOtherText('FORGOTTEN_PASSWORD', setForgottenPasswordText);

export const logout = () => {
  return async (dispatch, getState, API) => {
    try {
      await API.oauthLogout();
    } catch (e) {
      // silence
    }
  };
};
