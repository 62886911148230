import * as actionTypes from './constants';
import { loadOtherText, prop } from '../../utilities';
import { push } from 'react-router-redux';
import dayjs from 'dayjs';

// ---------------------- DASHBOARD -------------------------------
export const setDashboard = (dashboardData) => ({
  type: actionTypes.SET_DASHBOARD,
  payload: {
    dashboardData,
  },
});

export const loadDashboard = () => {
  return async (dispatch, getState, API) => {
    const dashboardData = await API.getDashboardData();
    dispatch(setDashboard(dashboardData));
  };
};

export const setSkvelkoAdvantageText = (skvelkoAdvantageText) => ({
  type: actionTypes.SET_SKVELKO_ADVANTAGE_TEXT,
  payload: {
    skvelkoAdvantageText,
  },
});

export const loadSkvelkoAdvantageText = () =>
  loadOtherText('ACCOUNT_SKVELKO_ADVANTAGE', setSkvelkoAdvantageText);

export const loadTextDeliverySchool = () =>
  loadOtherText('INFO_DELIVERY_SCHOOL', setDeliverySchoolText);

export const loadHowToEarnPointsText = () =>
  loadOtherText('HOW_TO_EARN_POINTS_TEXT', setHowToEarnPointsText);

export const setDeliverySchoolText = (deliverySchoolText) => ({
  type: actionTypes.SET_DELIVERY_SCHOOL_TEXT,
  payload: {
    deliverySchoolText,
  },
});

export const setHowToEarnPointsText = (howToEarnPointsText) => ({
  type: actionTypes.SET_HOW_TO_EARN_POINTS_TEXT,
  payload: {
    howToEarnPointsText,
  },
});

// ----------------------------------------------------------------
// ----------------------- SETS -----------------------------------
export const setSkvelkoSetListLoader = (skvelkoSetListLoader) => ({
  type: actionTypes.SET_SKVELKO_SET_LIST_LOADER,
  payload: {
    skvelkoSetListLoader,
  },
});

export const setSetList = (setList) => ({
  type: actionTypes.SET_SET_LIST,
  payload: {
    setList,
  },
});

export const loadSkvelkoSetList = () => {
  return async (dispatch, getState, API) => {
    dispatch(setSkvelkoSetListLoader(true));
    const setList = await API.searchSkvelkoSet({
      withPrice: true,
      withAwardedUser: true,
      withCount: true,
    });
    dispatch(setSetList(setList));
    dispatch(setSkvelkoSetListLoader(false));
  };
};

export const removeSkvelkoSetListAndReload = (skvelkoSetId) => {
  return async (dispatch, getState, API) => {
    dispatch(setSkvelkoSetListLoader(true));
    try {
      await API.changeAvailabilitySkvelkoSet(skvelkoSetId, 4);
    } catch (e) {
      console.error(e);
    }
    dispatch(loadSkvelkoSetList());
  };
};

export const changeAvailabilitySkvelkoSetListAndReload = (
  skvelkoSetId,
  availabilityId,
) => {
  return async (dispatch, getState, API) => {
    dispatch(setSkvelkoSetListLoader(true));
    try {
      await API.changeAvailabilitySkvelkoSet(skvelkoSetId, availabilityId);
    } catch (e) {
      console.error(e);
    }
    await dispatch(loadSkvelkoSetList());
  };
};

export const copySkvelkoSet = (skvelkoSetId) => {
  return async (dispatch, getState, API) => {
    dispatch(setSkvelkoSetListLoader(true));
    const result = await API.copySkvelkoSet(skvelkoSetId);
    dispatch(setSkvelkoSetListLoader(false));
    if (result && result.skvelkoSet && result.skvelkoSet.id) {
      dispatch(push('/my-account/set/edit/' + result.skvelkoSet.id));
    }
  };
};

export const setSkvelkoSet = (skvelkoSet) => ({
  type: actionTypes.SET_SKVELKO_SET,
  payload: {
    skvelkoSet,
  },
});

export const loadSkvelkoSet = (id) => {
  return async (dispatch, getState, API) => {
    const skvelkoSetResult = await API.searchSkvelkoSet({
      id,
      withGoods: true,
    });
    if (skvelkoSetResult.search.length > 0) {
      dispatch(setSkvelkoSet(skvelkoSetResult.search[0]));
    }
  };
};
// ----------------------------------------------------------------
// --------------------- PRODUCTS FOR SETS ------------------------
export const setProductsForSetsLoader = (productsForSetsLoader) => ({
  type: actionTypes.SET_PRODUCTS_FOR_SETS_LOADER,
  payload: {
    productsForSetsLoader,
  },
});

export const setProductsForSets = (productsForSets) => ({
  type: actionTypes.SET_PRODUCTS_FOR_SETS,
  payload: {
    productsForSets,
  },
});

export const setCategoriesForSets = (categoriesForSets) => ({
  type: actionTypes.SET_CATEGORIES_FOR_SETS,
  payload: {
    categoriesForSets,
  },
});

export const loadProductsForSets = (filters) => {
  return async (dispatch, getState, API) => {
    const limit = 12;

    const searchFilters = {
      q: filters.q ? filters.q : undefined,
      categoryId:
        filters.categoryId && filters.categoryId > 0
          ? filters.categoryId
          : undefined,
      limit,
      offset: filters.page ? (filters.page - 1) * limit : 0,
      onlyForSets: true,
      withRetailPrice: true,
    };

    dispatch(setProductsForSetsLoader(true));
    const productsResult = await API.searchProducts(searchFilters);
    dispatch(setProductsForSets(productsResult));
    dispatch(setProductsForSetsLoader(false));
  };
};

export const loadCategoriesForSets = () => {
  return async (dispatch, getState, API) => {
    const categoriesResult = await API.loadCategories({
      limit: Number.MAX_SAFE_INTEGER,
    });
    dispatch(setCategoriesForSets(categoriesResult));
  };
};

export const setTotalSetProducts = (totalSetProducts) => ({
  type: actionTypes.SET_TOTAL_SET_PRODUCTS,
  payload: {
    totalSetProducts,
  },
});

export const loadTotalSetProducts = () => {
  return async (dispatch, getState, API) => {
    const searchFilters = {
      limit: 1,
      offset: 0,
      onlyForSets: true,
    };

    const productsResult = await API.searchProducts(searchFilters);
    dispatch(setTotalSetProducts(productsResult.total));
  };
};

// ----------------------------------------------------------------
// ----------------------- CLASSROOMS -----------------------------
export const setClassListLoader = (classListLoader) => ({
  type: actionTypes.SET_CLASS_LIST_LOADER,
  payload: {
    classListLoader,
  },
});

export const setClassSearch = (classSearch) => ({
  type: actionTypes.SET_CLASS_SEARCH,
  payload: {
    classSearch,
  },
});

export const loadClasses = () => {
  return async (dispatch, getState, API) => {
    dispatch(setClassListLoader(true));
    const classSearch = await API.searchSkvelkoClass({});
    dispatch(setClassSearch(classSearch));
    dispatch(setClassListLoader(false));
  };
};

export const removeAndReloadClass = (skvelkoClassId) => {
  return async (dispatch, getState, API) => {
    dispatch(setClassListLoader(true));
    await API.removeSkvelkoClass(skvelkoClassId);
    dispatch(loadClasses());
  };
};

export const createAndReloadClass = (className) => {
  return async (dispatch, getState, API) => {
    dispatch(setClassListLoader(true));
    await API.createSkvelkoClass({}, { name: className });
    dispatch(loadClasses());
  };
};

export const updateAndReloadClass = (skvelkoClassId, className) => {
  return async (dispatch, getState, API) => {
    dispatch(setClassListLoader(true));
    await API.updateSkvelkoClass(skvelkoClassId, {}, { name: className });
    dispatch(loadClasses());
  };
};
// ----------------------------------------------------------------
// ----------------------- RENUMERATION ---------------------------
export const setRenumerationLoader = (renumerationLoader) => ({
  type: actionTypes.SET_RENUMERATION_LOADER,
  payload: {
    renumerationLoader,
  },
});

export const setRenumerationData = (renumerationData) => ({
  type: actionTypes.SET_RENUMERATION_DATA,
  payload: {
    renumerationData,
  },
});

export const loadRenumerationData = () => {
  return async (dispatch, getState, API) => {
    const state = getState();
    const userId = state.auth.user.id;
    dispatch(setRenumerationLoader(true));
    const [
      customerDetails,
      creditHistory,
      awardedUsers,
      skvelkoClasses,
    ] = await Promise.all([
      API.getCustomerDetails(userId),
      API.searchSkvelkoCreditHistory({ onlyNegative: true }),
      API.searchSkvelkoAwardedUsers({}),
      API.searchSkvelkoClass({ withAwardedUser: true }),
    ]);
    dispatch(setRenumerationLoader(false));

    const renumerationData = {
      customerDetails,
      creditHistory,
      awardedUsers,
      skvelkoClasses,
    };

    dispatch(setRenumerationData(renumerationData));
  };
};

export const updateClassAwardedPersonAndReload = (
  skvelkoClassId,
  skvelkoAwardedUserId,
) => {
  return async (dispatch, getState, API) => {
    dispatch(setRenumerationLoader(true));
    await API.updateSkvelkoClass(skvelkoClassId, {}, { skvelkoAwardedUserId });
    dispatch(loadRenumerationData());
  };
};

export const removeSkvelkoAwardedUserAndReload = (skvelkoAwardedUserId) => {
  return async (dispatch, getState, API) => {
    dispatch(setRenumerationLoader(true));
    await API.removeSkvelkoAwardedUser(skvelkoAwardedUserId);
    dispatch(loadRenumerationData());
  };
};
// ----------------------------------------------------------------
// ----------------- ORDERS OVERVIEW ------------------------------

export const setOrdersBySetLoader = (ordersBySetLoader) => ({
  type: actionTypes.SET_ORDERS_BY_SET_LOADER,
  payload: {
    ordersBySetLoader,
  },
});

export const setOrdersBySet = (ordersBySet) => ({
  type: actionTypes.SET_ORDERS_BY_SET,
  payload: {
    ordersBySet,
  },
});

export const setOrdersForSet = (ordersForSet) => ({
  type: actionTypes.SET_ORDERS_FOR_SET,
  payload: {
    ordersForSet,
  },
});

export const loadGroupedOrdersBySet = (
  from,
  to,
  onlyWithDeliveryToSchool = false,
) => {
  return async (dispatch, getState, API) => {
    dispatch(setOrdersBySetLoader(true));
    dispatch(setOrdersForSet([]));
    const ordersBySetResult = await API.getOrdersGroupedBySet({
      dateFrom: formatToSqlDate(from),
      dateTo: formatToSqlDate(to),
      onlyWithDeliveryToSchool,
    });
    dispatch(setOrdersBySet(ordersBySetResult));
    dispatch(setOrdersBySetLoader(false));
  };
};

export const loadOrdersForSet = (
  skvelkoSetId,
  from,
  to,
  onlyWithDeliveryToSchool = false,
) => {
  skvelkoSetId = String(skvelkoSetId).split(' ').join(', ').toString();
  return async (dispatch, getState, API) => {
    dispatch(setOrdersBySetLoader(true));
    const ordersForSetResponse = await API.getSkvelkoOrdersSearch({
      skvelkoSetId,
      dateFrom: formatToSqlDate(from),
      dateTo: formatToSqlDate(to),
      onlyWithDeliveryToSchool,
    });
    const state = getState();
    const ordersForSet = state.myAccount.ordersForSet;
    ordersForSet.push({
      skvelkoSetId: skvelkoSetId.split(', ').join(' ').toString(),
      orders: ordersForSetResponse.orders,
    });

    dispatch(setOrdersForSet(ordersForSet));
    dispatch(setOrdersBySetLoader(false));
  };
};

export function formatToSqlDate(date) {
  try {
    const formattedDate = dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    if (formattedDate === 'Invalid Date') {
      return undefined;
    }
    return formattedDate;
  } catch (e) {
    return undefined;
  }
}
// ----------------------------------------------------------------
// --------------------- MY ORDERS --------------------------------
export const setMyOrdersLoader = (myOrdersLoader) => ({
  type: actionTypes.SET_MY_ORDERS_LOADER,
  payload: {
    myOrdersLoader,
  },
});

export const setMyOrders = (myOrders) => ({
  type: actionTypes.SET_MY_ORDERS,
  payload: {
    myOrders,
  },
});

export const loadMyOrders = (clubUserId) => {
  return async (dispatch, getState, API) => {
    dispatch(setMyOrdersLoader(true));
    const orders = await API.getOrders(clubUserId, {
      limit: 10000,
      includeChildren: true,
    });
    dispatch(setMyOrders(orders));
    dispatch(setMyOrdersLoader(false));
  };
};
// ----------------------------------------------------------------
// --------------------- ORDER DETAIL -----------------------------
const requestOrderData = () => ({
  type: actionTypes.REQUEST_ORDER_DATA,
});

const receiveOrderDataSuccess = (order) => ({
  type: actionTypes.RECEIVE_ORDER_DATA_SUCCESS,
  payload: {
    order,
  },
});

const receiveOrderDataError = (error) => ({
  type: actionTypes.RECEIVE_ORDER_DATA_ERROR,
  payload: {
    error,
  },
});

export const loadOrderData = (orderId: number) => {
  return async (dispatch, getState, API) => {
    try {
      dispatch(requestOrderData());
      // const userId = userSelector(getState()).id;
      const order = await API.getOrder(orderId);
      dispatch(receiveOrderDataSuccess(order));
    } catch (e) {
      dispatch(receiveOrderDataError(e));
    }
  };
};
// ----------------------------------------------------------------
// --------------------- SETTINGS ---------------------------------
export const setUserDetailLoader = (userDetailLoader) => ({
  type: actionTypes.SET_USER_DETAIL_LOADER,
  payload: {
    userDetailLoader,
  },
});

export const setUserDetail = (userDetail) => ({
  type: actionTypes.SET_USER_DETAIL,
  payload: {
    userDetail,
  },
});

export const setLastBillingAddress = (lastBillingAddress) => ({
  type: actionTypes.SET_LAST_BILLING_ADDRESS,
  payload: {
    lastBillingAddress,
  },
});

export const setLastDeliveryAddress = (lastDeliveryAddress) => ({
  type: actionTypes.SET_LAST_DELIVERY_ADDRESS,
  payload: {
    lastDeliveryAddress,
  },
});

export const setUseDeliveryAddress = (useDeliveryAddress) => ({
  type: actionTypes.SET_USE_DELIVERY_ADDRESS,
  payload: {
    useDeliveryAddress,
  },
});

export const loadUserDetail = (userId) => {
  return async (dispatch, getState, API) => {
    dispatch(setUserDetailLoader(true));

    const userDetail = await API.getCustomerDetails(userId);
    dispatch(setUserDetail(userDetail));
    dispatch(setUseDeliveryAddress(userDetail.use_delivery_address));

    const billingAddresses = await API.getBillingAddresses(userId, {
      sort: 'created_at',
      sortDir: 'desc',
    });
    if (billingAddresses && billingAddresses.length > 0) {
      dispatch(setLastBillingAddress(billingAddresses[0]));
    } else {
      dispatch(setLastBillingAddress(null));
    }

    const deliveryAddresses = await API.getDeliveryAddresses(userId, {
      sort: 'created_at',
      sortDir: 'desc',
    });
    if (deliveryAddresses && deliveryAddresses.length > 0) {
      dispatch(setLastDeliveryAddress(deliveryAddresses[0]));
    } else {
      dispatch(setLastDeliveryAddress(null));
    }

    dispatch(setUserDetailLoader(false));
  };
};

// ------------------ TEACHER AVAILABILITY TEXT -----------------------------
export const setTeacherAvailabilityText = (teacherAvailabilityText) => ({
  type: actionTypes.SET_INFO_TEACHER_AVAILABILITY,
  payload: {
    teacherAvailabilityText,
  },
});

export const loadTeacherAvailabilityText = () =>
  loadOtherText('INFO_TEACHER_AVAILABILITY', setTeacherAvailabilityText);
// ----------------------------------------------------------------

// ------------------ SAVE INACCESSIBLE TEXT -----------------------------
export const setSaveInaccessibleText = (saveInaccessibleText) => ({
  type: actionTypes.SET_INFO_SAVE_INACCESSIBLE,
  payload: {
    saveInaccessibleText,
  },
});

export const loadSaveInaccessibleText = () =>
  loadOtherText('INFO_SAVE_INACCESSIBLE', setSaveInaccessibleText);
// ----------------------------------------------------------------

// ------------------ EDIT SET MOBILE TEXT -----------------------------
export const setEditSetMobileText = (editSetMobileText) => ({
  type: actionTypes.ALERT_EDIT_SET_MOBILE,
  payload: {
    editSetMobileText,
  },
});

export const loadEditSetMobileText = () =>
  loadOtherText('ALERT_EDIT_SET_MOBILE', setEditSetMobileText);
// ----------------------------------------------------------------

// ------------------ NOT SAVED CHANGES TEXT -----------------------------
export const setNotSavedChangesText = (notSavedChangesText) => ({
  type: actionTypes.SET_NOT_SAVED_CHANGES,
  payload: {
    notSavedChangesText,
  },
});

export const loadNotSavedChangesText = () =>
  loadOtherText('INFO_NOT_SAVED_SET', setNotSavedChangesText);
// ----------------------------------------------------------------

export const loadMostFrequentPurchased = () => {
  return async (dispatch, getState, API) => {
    const previousProducts = prop(
      getState(),
      'myAccount.mostFrequentPurchased.products',
      [],
    );
    const offset = (previousProducts && previousProducts.length) || 0;
    dispatch(setMostFrequentPurchasedLoader(true));
    const productsFilter = {
      limit: offset === 0 ? 3 : 10,
      offset: offset,
      withPublish: '1',
      mostFrequentPurchased: true,
    };

    const products = await API.searchProducts(productsFilter);

    dispatch(
      setMostFrequentPurchased(
        offset === 0
          ? products
          : {
              ...products,
              products: [...previousProducts, ...products.products],
            },
      ),
    );

    dispatch(setMostFrequentPurchasedLoader(false));
  };
};

export const loadFavoriteProductsForWidget = () => {
  return async (dispatch, getState, API) => {
    const previousProducts = prop(
      getState(),
      'myAccount.favoriteProductsWidget.products',
      [],
    );
    const offset = (previousProducts && previousProducts.length) || 0;
    dispatch(setFavoriteProductsWidgetLoader(true));
    const productsFilter = {
      limit: offset === 0 ? 3 : 10,
      offset: offset,
      withPublish: '1',
      onlyUserFavorites: true,
    };

    const products = await API.searchProducts(productsFilter);

    dispatch(
      setFavoriteProductsWidget(
        offset === 0
          ? products
          : {
              ...products,
              products: [...previousProducts, ...products.products],
            },
      ),
    );

    dispatch(setFavoriteProductsWidgetLoader(false));
  };
};

/*
 * Remove product form user's favorites products list
 */
export const removeFromUsersFavouriteProducts = (
  productId: number,
  userId: number,
) => {
  return async (dispatch, getState, API) => {
    dispatch(setFavoriteProductsWidgetLoader(true));
    const previousProducts = prop(
      getState(),
      'myAccount.favoriteProductsWidget.products',
      [],
    );

    const favoriteProductsWidget = prop(
      getState(),
      'myAccount.favoriteProductsWidget',
      [],
    );

    // Filter products
    const filteredProducts = previousProducts.filter(
      (product) => product.product_id != productId,
    );

    // Update user's favorite products
    const response = await API.unfavoriteProduct(userId, productId);

    // If update successful, update redux state
    if (response && favoriteProductsWidget) {
      dispatch(
        setFavoriteProductsWidget({
          ...favoriteProductsWidget,
          products: filteredProducts,
          total: favoriteProductsWidget.total - 1,
        }),
      );
    }

    // Hide loader
    dispatch(setFavoriteProductsWidgetLoader(false));
  };
};

export const setMostFrequentPurchased = (mostFrequentPurchased) => ({
  type: actionTypes.SET_MOST_FREQUENT_PURCHASED,
  payload: {
    mostFrequentPurchased,
  },
});

export const setFavoriteProductsWidget = (favoriteProductsWidget) => ({
  type: actionTypes.SET_FAVORITE_PRODUCTS_WIDGET,
  payload: {
    favoriteProductsWidget,
  },
});

export const setMostFrequentPurchasedLoader = (
  mostFrequentPurchasedLoader,
) => ({
  type: actionTypes.SET_MOST_FREQUENT_PURCHASED_LOADER,
  payload: {
    mostFrequentPurchasedLoader,
  },
});

export const setFavoriteProductsWidgetLoader = (
  favoriteProductsWidgetLoader,
) => ({
  type: actionTypes.SET_FAVORITE_PRODUCTS_WIDGET_LOADER,
  payload: {
    favoriteProductsWidgetLoader,
  },
});

export const loadProductsCustomer = () => {
  return async (dispatch, getState, API) => {
    const previousProducts = prop(
      getState(),
      'myAccount.productsCustomer.products',
      [],
    );
    const offset = (previousProducts && previousProducts.length) || 0;
    const productsFilter = {
      limit: offset === 0 ? 3 : 10,
      offset: offset,
      withPublish: '1',
      onlyWithCustomerPrice: true,
    };
    dispatch(setProductsCustomerLoader(true));
    const products = await API.searchProducts(productsFilter);
    dispatch(
      setProductsCustomer(
        offset === 0
          ? products
          : {
              ...products,
              products: [...previousProducts, ...products.products],
            },
      ),
    );
    dispatch(setProductsCustomerLoader(false));
  };
};

export const setProductsCustomer = (productsCustomer) => ({
  type: actionTypes.SET_PRODUCTS_CUSTOMER,
  payload: {
    productsCustomer,
  },
});

export const setProductsCustomerLoader = (productsCustomerLoader) => ({
  type: actionTypes.SET_PRODUCTS_CUSTOMER_LOADER,
  payload: {
    productsCustomerLoader,
  },
});

export const loadCustomerCard = () => {
  return async (dispatch, getState, API) => {
    const customerCard = await API.getCustomerCard();
    dispatch(setCustomerCard(customerCard));
  };
};

export const setCustomerCard = (customerCard) => ({
  type: actionTypes.SET_CUSTOMER_CARD,
  payload: {
    customerCard,
  },
});

export const setNewSetState = (newSetState) => ({
  type: actionTypes.SET_NEW_SET_STATE,
  payload: {
    newSetState,
  },
});

export const setCreatingNewSetState = (creatingNewSetState) => ({
  type: actionTypes.SET_CREATING_NEW_SET,
  payload: {
    creatingNewSetState,
  },
});

export const loadCustomerProductsText = () =>
  loadOtherText('CUSTOMER_PRODUCTS_TEXT', setCustomerProductsText);

export const setCustomerProductsText = (customerProductText) => ({
  type: actionTypes.SET_CUSTOMER_PRODUCTS_TEXT,
  payload: {
    customerProductText,
  },
});

export const loadSetBulkOrder = () =>
  loadOtherText('SET_BULK_ORDER', setSetBulkOrderText);

export const setSetBulkOrderText = (setBulkOrderText) => ({
  type: actionTypes.SET_SET_BULK_ORDER_TEXT,
  payload: {
    setBulkOrderText,
  },
});

export const loadThankYouSkvelkoBoxText = () =>
  loadOtherText('THANKYOU_SKVELKOBOX', setThankYouSkvelkoBoxText);

export const setThankYouSkvelkoBoxText = (thankYouSkvelkoBoxText) => ({
  type: actionTypes.SET_THANKYOU_SKVELKOBOX_TEXT,
  payload: {
    thankYouSkvelkoBoxText,
  },
});
