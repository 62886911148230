import * as React from 'react';
import { capitalizeFirstLetter } from '../../../utilities';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import { grid } from '../../../theme/libraries/grid';

const Wrapper = styled.ul`
  margin: 0 ${rem(-grid.padding / 2)} ${rem(16)} ${rem(-grid.padding / 2)};
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) => theme.media('xs')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${({ theme }) => theme.media('md')} {
    margin-left: 0;
    margin-right: 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Item = styled.li`
  list-style: none;
  background: ${({ theme }) => theme.color.gray97};
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  margin: 0 ${rem(-2)} ${rem(-2)} 0;
  padding: ${rem(10)};
`;

const ItemLink = styled(Link)`
  color: inherit;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
`;

const ItemName = styled.p`
  margin: 0;
  padding-top: ${rem(20)};
  padding-bottom: ${rem(10)};
  line-height: 1;
  font-size: ${rem(18)};
  font-weight: 800;
  text-align: center;
`;

const ItemImage = styled(ImagePlaceholder)`
  margin: 0;
`;

interface categoriesFurnitureProps {
  categories: any[];
}

const CategoriesFurniture = (props: categoriesFurnitureProps) => {
  const { categories } = props;

  const placeholder = '/images/placeholder-200.png';
  const placeholder2x = '/images/placeholder-200@2x.png';

  return (
    <>
      {categories?.length ? (
        <Wrapper>
          {categories.map((item, i) => (
            <Item key={i}>
              <ItemLink to={item?.url}>
                <ItemImage
                  src={item?.image}
                  placeholder={placeholder}
                  retinaPlaceholder={placeholder2x}
                  w={255}
                  h={255}
                />
                <ItemName>
                  {capitalizeFirstLetter(item?.category_name)}
                </ItemName>
              </ItemLink>
            </Item>
          )) || ''}
        </Wrapper>
      ) : (
        ''
      )}
    </>
  );
};

export default CategoriesFurniture;
