import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { InputStyled } from '../_helpers/form/Input';
import { Button, ButtonStyledStyles } from '../_helpers/form/Button';
import { applyGiftcardOrCustomerCard } from '../../containers/Cart/actions';
import { Loader } from '../_helpers/Loader/Loader';

const Wrapper = styled.form`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.color.white};
  padding: ${rem(20)};
  margin-top: ${rem(2)};
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  ${({ theme }) => theme.media('sm')} {
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    margin-top: 0;
  }
`;

const Text = styled.p`
  display: none;
  font-size: ${rem(14)};
  margin: 0 ${rem(10)} 0 0;
  ${({ theme }) => theme.media(1024)} {
    display: block;
  }
`;

const CartVoucherInput = styled(InputStyled)`
  flex: 1 1 auto;
  e.targetmargin-right: ${rem(10)};
  min-width: ${rem(16)};
  width: 250px;
  margin-right: ${rem(10)};
`;

const Submit = styled(Button)`
  flex: 0 0 auto;
  ${ButtonStyledStyles};
`;

interface Props {
  dispatch: any;
  render: boolean;
  onSubmitted?: () => void;
}

interface State {
  value: string;
  loading: boolean;
}

class CartVoucherForm extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { value: '', loading: false };
  }

  public submit = async () => {
    if (this.state.value && this.state.value !== '') {
      this.setState({ loading: true });
      await this.props.dispatch(applyGiftcardOrCustomerCard(this.state.value));
      if (this.props.onSubmitted) {
        this.props.onSubmitted();
      }
      this.setState({ loading: false });
    }
  };

  public onChange = (e) => {
    this.setState({ value: e.target.value });
  };

  public render() {
    if (!this.props.render) {
      return '';
    }
    return (
      <Loader loading={this.state.loading}>
        <Wrapper>
          <Text>
            {__('Použiť ')}
            <strong>{__('zľavový kupón')}</strong>
            {__(' alebo ')}
            <strong>{__('zákaznícku kartu')}</strong>
          </Text>
          <CartVoucherInput
            name="voucher"
            type="text"
            placeholder={__('Kód kupónu / karty')}
            value={this.state.value}
            onChange={this.onChange}
          />
          <Submit onClick={this.submit}>{__('Uplatniť')}</Submit>
        </Wrapper>
      </Loader>
    );
  }
}

export default CartVoucherForm;
