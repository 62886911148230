import styled, { css } from 'styled-components';
import {
  ButtonStyledNewStyles,
  ButtonStyledStyles,
  ButtonStyles,
} from '../../_helpers/form/Button';
import {
  CMSPreview as CMSPreviewProp,
  CMSPreviewsData,
} from '../../../containers/Home/constants';
import React from 'react';
import { isMobile } from '../../../utilities';
import { rem } from 'polished';
import { Link } from 'react-router';

const CMSPreviewsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

const CMSPreviewWrapper = styled.div`
  position: relative;
  flex: 0 0 ${({ theme }) => theme.grid.col.col12};
  &:not(:last-child) {
    margin-bottom: ${rem(2)};
  }
  ${({ theme }) => theme.media('sm')} {
    margin-bottom: 0;
    flex: 0 0 ${({ theme }) => theme.grid.col.col4};
    padding: 0 ${rem(5)};
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

const CMSPreviewContent = styled.div`
  height: 100%;
  background: url('${(props) => props.imageMobile}');
  background-repeat: no-repeat;
  background-size: cover;
  padding: ${rem(25)};
  text-align: center;
  ${({ theme }) => theme.media('sm')} {
    text-align: left;
    background-size: initial;
    background-image: none;
    background-color: ${(props) => props.bgColor};
    padding-bottom: ${rem(60)};
  }
  ${({ theme }) => theme.media('md')} {
    height: ${rem(300)};
    background-image: url('${(props) => props.image}');
    padding-bottom: ${rem(25)};
    padding-left: ${rem(110)};
  }
  ${({ theme }) => theme.media(1200)} {
    padding-left: ${rem(160)};
  }
`;

const TextCss = css`
  color: ${({ theme }) => theme.color.white};
  text-shadow: ${({ theme }) => theme.color.black} 1px 0 10px;
  ${({ theme }) => theme.media('sm')} {
    text-shadow: none;
    color: ${({ theme }) => theme.color.black};
  }
`;

const Title = styled.h3`
  ${TextCss};
  margin: 0;
  font-size: ${rem(24)};
  font-weight: 800;
  line-height: 1.25;
`;

const Description = styled.p`
  ${TextCss};
  font-size: ${rem(16)};
  ${({ theme }) => theme.media('sm')} {
    margin: 0;
    display: none;
  }
`;

const Items = styled.ul`
  list-style: none;
  margin: ${rem(4)} 0 ${rem(14)} 0;
  padding: 0;
  display: none;
  ${({ theme }) => theme.media('sm')} {
    display: block;
  }
`;

const Item = styled.li`
  ${TextCss};
  font-size: ${rem(15)};
  padding-left: ${rem(16)};
  text-indent: ${rem(-11.2)};
  &:before {
    content: '• ';
    font-weight: 800;
    color: ${({ theme }) => theme.color.gray56};
  }
`;

const ButtonCss = css`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${ButtonStyledNewStyles};
  ${({ theme }) => theme.media('sm', 'md')} {
    position: absolute;
    left: ${rem(20)};
    bottom: ${rem(20)};
  }
`;

const Button = styled(Link)`
  ${ButtonCss};
`;

const ButtonExternal = styled.a`
  ${ButtonCss};
`;

interface CMSPreviewsProps {
  cmsPreviewsData: CMSPreviewsData;
}

interface CMSPreviewProps {
  cmsPreview: CMSPreviewProp;
}

const CMSPreview = (props: CMSPreviewProps) => {
  const {
    cmsPreview: {
      image,
      imageMobile,
      name,
      nameMobile,
      description,
      items,
      buttonText,
      buttonLink,
      bgColor,
    },
  } = props;

  const mobile = isMobile();

  return (
    <CMSPreviewWrapper>
      <CMSPreviewContent
        bgColor={bgColor}
        image={image}
        imageMobile={imageMobile}
      >
        <Title>{mobile && nameMobile ? nameMobile : name}</Title>
        <Description>{description}</Description>
        <Items>
          {items.map((item: string, index: number) => {
            return <Item key={index}>{item}</Item>;
          })}
        </Items>
        {buttonLink.startsWith('https') ? (
          <ButtonExternal
            href={buttonLink}
            target={'_blank'}
            className="primary"
          >
            {buttonText}
          </ButtonExternal>
        ) : (
          <Button to={buttonLink} className="primary">
            {buttonText}
          </Button>
        )}
      </CMSPreviewContent>
    </CMSPreviewWrapper>
  );
};

const CMSPreviews = (props: CMSPreviewsProps) => {
  const { cmsPreviewsData } = props;

  return (
    <CMSPreviewsWrapper>
      {cmsPreviewsData.map((cmsPreview: CMSPreviewProp, index: number) => {
        return <CMSPreview cmsPreview={cmsPreview} key={index} />;
      })}
    </CMSPreviewsWrapper>
  );
};

export default CMSPreviews;
