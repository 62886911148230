import { css } from 'styled-components';
import { rem } from 'polished';

export const HomeSwiperStyles = css`
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  .swiper-container {
    &:before,
    &:after {
      display: block;
      background: ${({ theme }) => theme.color.background};
      width: ${rem(19)};
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 2;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
  .swiper-slide {
    box-shadow: ${({ theme }) => theme.boxShadow.small};
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    line-height: 1;
    .swiper-pagination-bullet {
      background: ${({ theme }) => theme.color.black};
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    background: ${({ theme }) => theme.color.white};
    &:before,
    &:after {
      background: ${({ theme }) => theme.color.black};
    }
    &.swiper-button-disabled {
      opacity: 1;
      &:before,
      &:after {
        background: ${({ theme }) => theme.color.gray87};
      }
    }
  }
`;

export const HomeSwiper1200Styles = css`
  .swiper-container {
    &:before,
    &:after {
      width: ${rem(29)};
    }
  }
`;
