import { __ } from 'react-i18n';

// TITLES
export const title = (def = '', t = '') => `${def} - ${t}`;
export const registartionTitle = (def = '') => title(def, __('Registrácia'));
export const loginTitle = (def = '') => title(def, __('Prihlásenie'));

export const cartTitle = (def = '') => title(def, __('Košík'));
export const cartDeliveryPaymentTitle = (def = '') =>
  title(def, __('Doprava a platba'));
export const cartShopperFormTitle = (def = '') =>
  title(def, __('Informácie o Vás'));

export const accountChangePasswordTitle = (def = '') =>
  title(def, __('Zmena hesla'));
export const accountOrdersTitle = (def = '') =>
  title(def, __('História objednávok'));
export const accountTitle = (def = '') => title(def, __('Môj účet'));
export const accuntDetailsTitle = (def = '') => title(def, __('Detaily účtu'));
export const accuntAddressesTitle = (def = '') =>
  title(def, __('Moje dodacie adresy'));
export const accountAddAddressTitle = (def = '') =>
  title(def, __('Pridanie dodacej adresy'));
export const accountEditAddressTitle = (def = '') =>
  title(def, __('Úprava dodacej adresy'));
export const getSearchResultsTitle = (def, q) =>
  title(def, `${__('Výsledky vyhľadávania pre výraz')} "${q}"`);

// ROUTES
export const getRegistrationRoute = () => __('routes:registracia');
export const getLoginRoute = () => __('routes:prihlasenie');
export const getLogoutRoute = () => __('routes:odhlasenie');

export const getCartRoute = () => __('routes:cart');
export const getCartDeliveryPaymentRoute = () =>
  __('routes:cart/delivery-and-payment');
export const getCartFormRoute = () => __('routes:cart/shopper-form');
export const getFinishOrderRoute = () => __('routes:finish-order-message');
export const getHomepageRoute = () => '/';
export const getSearchRoute = q => `${__('routes:vyhladavanie')}?q=${q}`;

// MSGES
export const getSuccesfullLoginMsg = () =>
  __(
    'Boli ste úspešne zaregistrovaní. Pre dokončenie registrácie prosím aktivujte svoj účet kliknutím na URL v emaile.',
  );
export const cartConfirmDeleteItemMsg = () =>
  __('Naozaj chcete odstrániť produkt z košíka?');
export const emptyCartTitleMsg = () => __('Váš košík je prázdny');
export const emptyCartMsg = () =>
  __(
    'Pre pridanie produktu do košíka sa prosím vrátte späť na hlavnú stránku, vyberte si produkt o ktorý máte záujem a kliknute na červenú ikonku košíka',
  );
export const gotoHomepageMsg = () => __('Prejsť na hlavnú stránku');
export const continueInOrderMsg = () => __('Pokračovať v objednávke');
export const createOrderMsg = () => __('Objednať s povinnosťou platby');
