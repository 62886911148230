import * as React from 'react';
import styled from 'styled-components';
import { Button, ButtonStyledStyles } from '../form/Button';
import BaseModal, { Footer } from './BaseModal';

const FooterButtonWrapper = styled(Footer)`
  width: 100%;
  text-align: center;
`;

const FooterButton = styled(Button)`
  ${ButtonStyledStyles};
`;

interface ModalProps {
  title?: string;
  bodyText?: string;
  buttonText?: string;
  onButtonClick?: (event) => void;
  onCloseButtonClick?: (event) => void;
}

class WarningModal extends React.PureComponent<ModalProps> {
  public render() {
    const { title, bodyText, buttonText } = this.props;

    return (
      <BaseModal
        title={title}
        body={bodyText}
        footer={
          <FooterButtonWrapper>
            <FooterButton className="lg" click={this.props.onButtonClick}>
              {buttonText}
            </FooterButton>
          </FooterButtonWrapper>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default WarningModal;
