import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { getTotal, prop, scrollToTop } from '../../utilities';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { useState } from 'react';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { BreadcrumbsItems, PRODUCTS_PER_PAGE } from '../../constants';
import {
  defaultSearchParameters,
  DEFAULT_FILTER_TYPES,
  onPageChangeDefault,
  onSortChangeDefault,
  SORT_CATEGORY,
} from '../Category/constants';
import Filter from '../Category/Filter';
import { useFilter } from '../../hooks/filter';
import { ProductColWrapper, ProductsRowWrapper } from '../Category/Category';
import { SearchProductType } from '../../utilities/product';
import SetCategoryPreview from '../../components/_helpers/Sets/SetCategoryPreview';
import ProductPreview from '../../components/_helpers/product/ProductPreview';
import Pagination from '../../components/Pagination/Pagination';
import { CategoryPlaceholder } from '../../components/Category/Category';
import API from '../../services/API';
import { concatSearchProductsAndSkvelkoSetsAndSoldOutProducts } from '../../utilities/product';
import styled from 'styled-components';
import { rem } from 'polished';

const Wrapper = styled.div`
  margin-top: ${rem(0)};
`;

interface FavoriteProductsProps {
  setTitle: (title: string, link?: string | null, titleCount?: number) => void;
  dispatch: (a: any) => void;
  setBreadcrumbsItems: (breadcrumbsItems: BreadcrumbsItems) => void;
  location: any;
  currentPath: string;
}

const FavoriteProducts = (props: FavoriteProductsProps) => {
  const title = __('Obľúbené produkty');

  //props.setTitle(title,null);
  props.setBreadcrumbsItems([{ name: title, url: props.currentPath }]);

  const [productsSearch, setProductsSearch] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const { filters, addFilter } = useFilter({
    ...DEFAULT_FILTER_TYPES,
    queryLocation: props.location.query,
    dispatch: props.dispatch,
  });

  const limit = PRODUCTS_PER_PAGE;
  const sort = SORT_CATEGORY;

  const onPageChange = (page) => onPageChangeDefault(addFilter, page);
  const onSortChange = (clickedSort, clickedSortDir) =>
    onSortChangeDefault(addFilter, clickedSort, clickedSortDir);

  React.useEffect(() => {
    let parameters: any = defaultSearchParameters(filters, limit);
    parameters = {
      ...parameters,
      onlyUserFavorites: true,
    };

    API.searchProducts(parameters).then((productsData) => {
      const items = concatSearchProductsAndSkvelkoSetsAndSoldOutProducts(
        productsData.products,
        productsData.skvelko_sets,
        [],
        productsData.positions,
      );

      props.setTitle(title, null, productsData.total);

      productsData['items'] = items;
      setProductsSearch(items.length > 0 ? productsData : {});
      setLoading(false);
      scrollToTop();
    });
  }, [filters]);

  const getMainCol = () => {
    const paginationTotal = getTotal(productsSearch);
    return (
      <div>
        <Loader loading={loading}>
          {productsSearch.total > limit ? (
            <Filter
              sort={sort}
              onSortChange={onSortChange}
              paginationCurrent={filters.page}
              paginationTotal={paginationTotal}
              onPageChanged={onPageChange}
              isSearch={false}
              isSortable={true}
            />
          ) : (
            <Filter
              sort={sort}
              onSortChange={onSortChange}
              isSearch={false}
              isSortable={true}
            />
          )}
          {productsSearch.items && productsSearch.items.length ? (
            <>
              <ProductsRowWrapper>
                {productsSearch.items.map((item) => {
                  if (item.type === SearchProductType.SKVELKO_SET) {
                    return (
                      <ProductColWrapper key={item.item.id}>
                        <SetCategoryPreview skvelkoSet={item.item} />
                      </ProductColWrapper>
                    );
                  } else {
                    return (
                      <ProductColWrapper key={item.item.product_id}>
                        <ProductPreview product={item.item} />
                      </ProductColWrapper>
                    );
                  }
                })}
              </ProductsRowWrapper>
              {productsSearch.total > limit && (
                <Pagination
                  current={filters.page}
                  total={paginationTotal}
                  onPageChanged={onPageChange}
                />
              )}
            </>
          ) : loading ? (
            ''
          ) : (
            <CategoryPlaceholder text={__('Žiadné produkty sa nenašli')} />
          )}
        </Loader>
      </div>
    );
  };

  return <Wrapper> {getMainCol()} </Wrapper>;
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    myOrders: state.myAccount.myOrders,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FavoriteProducts' })(FavoriteProducts),
);
