import React from 'react';
import styled from 'styled-components';
import { __ } from 'react-i18n/lib';
import {
  formatPrice,
  getProductPrices,
  isOutOfStock,
  prop,
  removeHtmlTags,
} from '../../../utilities';
import ImagePlaceholder from '../../_helpers/Image/ImagePlaceholder';
import { Link } from 'react-router';
import { em, rem } from 'polished';
import API, { ThenArg } from '../../../services/API';
import {
  AddToCart,
  AddToCartIcon,
} from '../../_helpers/product/ProductPreview';
import {
  getOnStockCount,
  setAddToCartMaxItemExceededModalProduct,
  setAddToCartModalProduct,
  setAddToCartModalProductOutOfStock,
  setSelectVariantModal,
} from '../../../containers/App/actions';
import { hasProductVariants } from '../../../containers/Product/actions';
import { addItemToCart } from '../../../containers/Cart/actions';

const ItemsWrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
`;

const ItemWrapper = styled.li`
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.gray93};
`;

const Item = styled.div`
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  font-size: ${rem(12)};
  ${({ theme }) => theme.media('xs')} {
    font-size: ${rem(14)};
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;

  ${({ theme }) => theme.media('xs')} {
    flex-direction: row;
    align-items: center;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.p`
  margin: 0;
  font-weight: bold;
  color: black;
`;

const Availability = styled.p`
  margin: 0 0 0 ${rem(6)};
  color: ${({ theme }) => theme.color.error};
  &.available {
    color: ${({ theme }) => theme.color.success};
  }

  @media (max-width: ${em(380)}) {
    display: none;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.1;
  text-align: right;
  margin-left: ${rem(12)};
`;

const CartButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.1;
  text-align: right;
  margin-left: ${rem(12)};
`;

const Description = styled.p`
  display: none;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${rem(200)};
  color: ${({ theme }) => theme.color.gray38};
  ${({ theme }) => theme.media('xs')} {
    display: inline-block;
  }
  ${({ theme }) => theme.media('md')} {
    max-width: ${rem(400)};
  }
`;

const Price = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${rem(16)};
  font-weight: bold;
  ${({ theme }) => theme.media('xs')} {
    font-size: ${rem(20)};
  }
`;

const PriceSecondary = styled.small`
  margin: 0;
  font-size: ${rem(11)};
  ${({ theme }) => theme.media('xs')} {
    font-size: ${rem(12)};
  }
`;

const Image = styled(ImagePlaceholder)`
  width: ${(props) => rem(props.w)};
  height: ${(props) => rem(props.h)};
  margin: ${rem(10)} ${rem(10)} ${rem(10)} 0;
  ${({ theme }) => theme.media('xs')} {
    margin: ${rem(10)};
  }
`;

const More = styled.li`
  position: relative;
  align-self: center;
  border: ${rem(1)} solid ${({ theme }) => theme.color.gray93};
  border-top: none;
  padding: ${rem(4)} ${rem(48)};
  border-bottom-left-radius: ${rem(6)};
  border-bottom-right-radius: ${rem(6)};
  cursor: pointer;
`;

const MoreText = styled.p`
  margin: 0;
  &:before {
    position: absolute;
    left: ${rem(20)};
    content: '❭';
    display: inline-block;
    transform: rotate(90deg);
  }
`;

const RemoveProductFromFavorites = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const CloseIcon = styled.div`
  background: url('/images/close_white.svg') no-repeat;
  width: 12px;
  height: 12px;
  margin: 0px;
  padding-top: 1px;
  padding-left: 1px;
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  background: #8c8c8c no-repeat;
  padding: 0px;
  border-radius: 22px;
  padding-top: 1px;
  padding-left: 2px;
`;

const RemoveProductText = styled.p`
  color: #8c8c8c;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 6px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const AddToCartWrapper = styled.div``;

const CustomAddToCart = styled(AddToCart)`
  position: static;
  padding: ${rem(6)};
  ${({ theme }) => theme.media('xs')} {
    padding: ${rem(12)};
  }
`;

const CustomAddToCartIcon = styled(AddToCartIcon)`
  ${({ theme }) => theme.size(20)};
  ${({ theme }) => theme.media('xs')} {
    ${({ theme }) => theme.size(32)};
  }
`;

interface ProductPreviewsProps {
  items: any[];
  total: number;
  user: any;
  cart: ThenArg<typeof API.getCart>;
  loadMore?: () => void;
  maxItems?: number;
  dispatch: any;
  removeProduct: any;
  fromFavoritesProducts: boolean;
}

const ProductPreviews = (props: ProductPreviewsProps) => {
  const {
    items,
    loadMore,
    total,
    user,
    cart,
    dispatch,
    removeProduct,
    fromFavoritesProducts,
  } = props;

  const maxItems = prop(props, 'maxItems', 20);
  const orderItemsSize = items.length;

  return (
    <ItemsWrapper>
      {items.map((item: any, index) => {
        return (
          <ProductPreview
            key={index}
            item={item}
            user={user}
            cart={cart}
            dispatch={dispatch}
            removeProduct={removeProduct}
            fromFavoritesProducts={fromFavoritesProducts}
          />
        );
      })}
      {orderItemsSize < total && orderItemsSize < maxItems && (
        <More onClick={loadMore || null}>
          <MoreText>{__('Zobrazit viac')}</MoreText>
        </More>
      )}
    </ItemsWrapper>
  );
};

interface OrderItemPreviewProps {
  item: any;
  user: any;
  dispatch: any;
  cart: ThenArg<typeof API.getCart>;
  removeProduct: any;
  fromFavoritesProducts: boolean;
}

const ProductPreview = (props: OrderItemPreviewProps) => {
  const placeholder = '/images/placeholder-46.png';
  const placeholder2x = '/images/placeholder-46@2x.png';

  const { item, cart, user, removeProduct, fromFavoritesProducts } = props;

  const getProductData = () => {
    const [publish] = item.publish;
    const {
      showWithVat,
      price,
      priceWithoutVat,
      currencyId,
    } = getProductPrices(item, item?.main_good, cart, user);
    return {
      ...item,
      showWithVat: showWithVat,
      availability_id: item.main_good.availability_id,
      currency_id: currencyId,
      unitprice: price,
      unitprice_without_vat: priceWithoutVat,
      description: prop(publish, 'product_description'),
    };
  };

  let itemData: any = getProductData();

  const addToCart = async (e) => {
    e.preventDefault();
    if (getOnStockCount(props.cart, props.item) < 1) {
      props.dispatch(
        setAddToCartModalProductOutOfStock({ product: props.item }),
      );
      return;
    }

    if (hasProductVariants(props.item)) {
      props.dispatch(setSelectVariantModal(props.item));
      return;
    }

    const goodId = props.item.main_good.good_id;
    const quantityLimit = prop(props.item, 'main_good.order_quantity_limit');
    const itemInCart = cart?.items?.find(
      (item) => prop(item, 'good.good_id') === goodId,
    );
    const countInCart = itemInCart && itemInCart.count ? itemInCart.count : 0;
    const count = quantityLimit ? quantityLimit + countInCart : 1;

    try {
      await props.dispatch(
        addItemToCart(props.item.main_good.product_id, goodId, count),
      );

      props.dispatch(setAddToCartModalProduct({ product: props.item, count }));
    } catch (e: any) {
      if (e?.details?.name === 'MAX_ITEMS_EXCEEDED') {
        await props.dispatch(setAddToCartMaxItemExceededModalProduct(true));
      }
    } finally {
    }
  };

  return (
    <ItemWrapper>
      <Item>
        <LeftSide>
          <Link to={itemData.url}>
            <Image
              src={itemData.picture}
              placeholder={placeholder}
              retinaPlaceholder={placeholder2x}
              w={60}
              h={60}
            />
          </Link>
          <ContentWrapper>
            <Link to={itemData.url}>
              <NameWrapper>
                <Title>{itemData.name}</Title>
                {itemData.on_demand === 0 ? (
                  itemData.availability_id === 1 ? (
                    <Availability className={'available'}>
                      {__('Skladom')}
                    </Availability>
                  ) : (
                    <Availability>{__('Nedostupné')}</Availability>
                  )
                ) : (
                  <></>
                )}
              </NameWrapper>
              {itemData.description ? (
                <Description>
                  {removeHtmlTags(itemData.description)}
                </Description>
              ) : (
                ''
              )}
            </Link>
            {fromFavoritesProducts ? (
              <RemoveProductFromFavorites>
                <IconWrapper>
                  <CloseIcon />
                </IconWrapper>
                <RemoveProductText
                  onClick={() => removeProduct(itemData.product_id, user.id)}
                >
                  Odstrániť z obľúbených
                </RemoveProductText>
              </RemoveProductFromFavorites>
            ) : null}
          </ContentWrapper>
        </LeftSide>
        <RightSide>
          <PriceWrapper>
            {itemData.showWithVat && itemData.unitprice_without_vat ? (
              <>
                <Price>
                  {formatPrice(itemData.unitprice, itemData.currency_id)}
                </Price>
              </>
            ) : itemData.unitprice ? (
              <>
                <Price>
                  {formatPrice(
                    itemData.unitprice_without_vat,
                    itemData.currency_id,
                  )}
                </Price>
                <PriceSecondary>
                  {__('s DPH')}{' '}
                  {formatPrice(
                    itemData.main_good.final_price,
                    itemData.currency_id,
                  )}
                </PriceSecondary>
              </>
            ) : (
              ''
            )}
          </PriceWrapper>
          <CartButtonWrapper>
            {!isOutOfStock(itemData) ? (
              <AddToCartWrapper>
                <CustomAddToCart
                  className={'add-to-cart'}
                  onClick={addToCart}
                  aria-label={__('Pridať do košíka')}
                >
                  {/* Don't remove add-to-cart class! */}
                  <CustomAddToCartIcon icon="cart2" />
                </CustomAddToCart>
              </AddToCartWrapper>
            ) : (
              ''
            )}
          </CartButtonWrapper>
        </RightSide>
      </Item>
    </ItemWrapper>
  );
};

export default ProductPreviews;
