import { createMedia } from '@artsy/fresnel';

const ExampleAppMedia = createMedia({
  breakpoints: {
    sm: 0,
    xs: 568,
    md: 768,
    lg: 992,
    xl: 1024,
    xxl: 1192,
    swiperThreshold: 620,
  },
});

export const mediaStyle = ExampleAppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = ExampleAppMedia;
