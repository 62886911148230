import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Swiper from '../Swiper/Swiper';
import {
  HomeSwiper1200Styles,
  HomeSwiperStyles,
} from '../../_layout/Homepage/SliderStyles';
import { Heading2 } from '../Heading/Heading';
import { Loader } from '../Loader/Loader';
import UpsellProductPreview from './UpsellProductPreview';
import API from '../../../services/API';
import { useRef } from 'react';

const Wrapper = styled.div``;

const SwiperWrapper = styled.div`
  .swiper-container {
    padding-bottom: ${rem(52)};
  }
  .swiper-container .swiper-slide {
    height: auto;
    display: flex;
  }
  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: ${rem(20)};
  }
  .swiper-button-prev,
  .swiper-button-next {
    &.swiper-button-disabled {
      pointer-events: auto;
    }
  }
  ${HomeSwiperStyles};
  ${({ theme }) => theme.media(1024)} {
    .swiper-container {
      padding: 0 ${({ theme }) => rem(theme.grid.padding / 2)};
      &:before,
      &:after {
        content: '';
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
    .swiper-pagination {
      display: none;
    }
  }
  ${({ theme }) => theme.media(1200)} {
    margin-right: ${rem(-30)};
    margin-left: ${rem(-30)};
    ${HomeSwiper1200Styles};
    .swiper-container {
      padding-right: ${rem(30)};
      padding-left: ${rem(30)};
    }
  }
`;

const ProductHeading = styled(Heading2)`
  text-align: left;
  margin: ${rem(20)} 0;
  font-weight: bold;
  margin-bottom: ${rem(8)};
  margin-top: ${rem(8)};
  margin-left: ${rem(10)};

  ${({ theme }) => theme.media('xs')} {
    margin-left: ${rem(15)};
  }
`;

interface UpsellSliderProps {
  title?: string;
  upsell: any;
  breakpoints?: any;
  ignoreInCartProducts?: boolean;
  cart?: any;
  showModal?: boolean;
}

const UpsellSlider = (props: UpsellSliderProps) => {
  const swiperRef = useRef(null);
  const impressedProductIds = useRef<number[]>([]);

  const {
    title,
    upsell,
    breakpoints,
    ignoreInCartProducts,
    cart,
    showModal,
  } = props;
  const cartId = cart?.id;

  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (cartId) {
      API.impressionUpsell(
        upsell.id,
        {},
        {
          type: 'UPSELL',
          cart_id: cart.id,
        },
      );
    }
  }, [cartId]);

  let products = upsell.assigned_products.map(
    (assignedProduct) => assignedProduct.product,
  );

  if (ignoreInCartProducts && cart && cart.items && cart.items.length) {
    const cartProductIds = cart.items
      .map((item) => item?.product?.product_id)
      .filter((o) => o);
    products = products.filter(
      (product) => !cartProductIds.includes(product.product_id),
    );
  }

  const swiperParams = {
    slidesPerView: 2,
    spaceBetween: 2,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1023: {
        slidesPerView: 4,
      },
      577: {
        slidesPerView: 3,
      },
    },
  };

  const onProductsShow = (productsIds) => {
    if (cartId && upsell && upsell.type !== 'PRODUCT_CART_FIRST_STEP_HIDDEN') {
      const productIdsToImpress = productsIds.filter(
        (pId) => !impressedProductIds.current.includes(pId),
      );
      if (productIdsToImpress.length) {
        API.impressionUpsell(
          upsell.id,
          {},
          {
            type: 'UPSELL_PRODUCT',
            cart_id: cart.id,
            product_ids: productIdsToImpress,
          },
        );
        const newImpressedProductIds = impressedProductIds.current.concat(
          productIdsToImpress,
        );
        impressedProductIds.current = newImpressedProductIds;
      }
    }
  };

  if (breakpoints) {
    swiperParams.breakpoints = {
      ...swiperParams.breakpoints,
      ...breakpoints,
    };
  }

  if (products && products.length) {
    return (
      <Wrapper>
        {(title && <ProductHeading>{title}</ProductHeading>) || ''}
        <SwiperWrapper>
          <Loader loading={loading}>
            <Swiper
              ref={swiperRef}
              params={swiperParams}
              onProductsShow={onProductsShow}
            >
              {products.map((item, i) => {
                return (
                  <div key={i} data-product_id={item.product_id}>
                    <UpsellProductPreview
                      product={item}
                      setLoading={(loading) => {
                        setLoading(loading);
                      }}
                      upsellId={upsell.id}
                      showModal={showModal}
                    />
                  </div>
                );
              })}
            </Swiper>
          </Loader>
        </SwiperWrapper>
      </Wrapper>
    );
  } else {
    return <></>;
  }
};

export default UpsellSlider;
