import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';
import { __ } from 'react-i18n/lib';
import API, { ThenArg } from '../../../services/API';
import { getProp } from '../../../utilities';
import { Button2, StyledFooter } from './ChooseModal';

const StyledModal = styled(BaseModal)`
  width: ${rem(510)};
`;

const CloseButton = styled(Button2)``;

interface ModalProps {
  cart: ThenArg<typeof API.getCart>;
  onCloseButtonClick?: (event) => void;
}

class RecognizedCustomerCardModal extends React.PureComponent<ModalProps> {
  public render() {
    const { cart, onCloseButtonClick } = this.props;
    const sale = getProp(cart, 'customer_card_discount', '');
    return (
      <StyledModal
        title={__('Rozpoznali sme Váš email vo vernostnom systéme!')}
        body={`${__(
          'Keďže ste náš verný zákazník, prislúcha Vám <b>zľava',
        )} ${sale} % ${__(
          'z nákupu</b>.<br>Zľava sa nevzťahuje na akciový tovar, tovar s dlhodobo zníženou cenou a na nami vybraný tovar. <br>UPOZORNENIE: Po uplatnení zľavy nemusí Vaša objednávka spĺňať podmienky na dopravu zdarma. Skontrolujte si, prosím, sumu za dopravu.',
        )}`}
        footer={
          <StyledFooter>
            <CloseButton className="primary lg" onClick={onCloseButtonClick}>
              {__('Ok, ďakujem')}
            </CloseButton>
          </StyledFooter>
        }
        onCloseButtonClick={onCloseButtonClick}
      />
    );
  }
}

export default RecognizedCustomerCardModal;
