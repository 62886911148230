import { USER_COOKIE } from '../App/constants';
import * as cookies from 'react-cookies';
import { LOGIN_USER, LOGOUT_USER } from './constants';

const token = cookies.load(USER_COOKIE);

const authInitialState = {
  user: null,
  isFetchingUser: Boolean(token),
  token,
};

export const authReducer = (state = authInitialState, { type, payload }) => {
  switch (type) {
    case LOGIN_USER:
      return {
        ...state,
        user: payload.user,
        isFetchingUser: false,
        token: payload.token,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        token: null,
      };
    default:
      return state;
  }
};
