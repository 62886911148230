import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { Container } from '../../theme/libraries/grid';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import { Content, Title } from '../../components/_layout/Page/ContentContainer';
import { InputStyled } from '../../components/_helpers/form/Input';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import { Heading2 } from '../../components/_helpers/Heading/Heading';
import {
  ButtonStyled,
  ButtonStyledStyles,
  ButtonStyles,
} from '../../components/_helpers/form/Button';
import { Link } from 'react-router';
import { UrlHashService } from '../../services/UrlHashService';
import Alert from '../../components/Alert/Alert';
import * as cookie from 'react-cookies';
import MetaTags from '../../components/MetaTags/MetaTags';
import { CART_COOKIE_ID } from '../Cart/constants';
import { logout } from './actions';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import { loadRegistrationText } from '../App/actions';
import { USER_COOKIE } from '../App/constants';
import { loginUserAndLoadCart } from '../Auth/action';
import { isSsr, loadGlobalMetaTags } from '../../utilities';
import { URL_LOGIN, URL_REGISTRATION_RETAIL } from '../../constants';
import Icon from '../../components/_helpers/Icon/Icon';

const ContainerLogin = styled(Container)``;

const Wrapper = styled(Content)`
  font-size: ${rem(14)};
  padding: ${rem(20)};
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    padding: ${rem(40)};
    padding-top: ${rem(20)};
    margin-bottom: ${rem(40)};
  }
`;

export const Heading = styled(Heading2)`
  margin-top: 0;
  margin-bottom: ${rem(40)};
`;

const LoginBlocks = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media('md')} {
    flex-direction: row;
  }
`;

const LoginBlockCss = css`
  padding-top: ${rem(20)};
  flex: 1 0 ${({ theme }) => theme.grid.col.col12};
  ${({ theme }) => theme.media('md')} {
    padding-top: 0;
    flex: 1 0 ${({ theme }) => theme.grid.col.col6};
  }
`;

const LoginLeftBlock = styled.div`
  ${LoginBlockCss};
`;

const LoginRightBlock = styled.div`
  ${LoginBlockCss};
  ${({ theme }) => theme.media('md')} {
    padding-left: ${rem(40)};
    border-top: none !important;
    border-left: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
`;

export const FormWrapper = styled.div`
  max-width: ${rem(290)};
  margin-bottom: ${rem(30)};
`;

const LoginInputCss = css`
  display: block;
  width: 100%;
  margin-bottom: ${rem(10)};
`;

const LoginInputPassword = styled(InputStyled)`
  ${LoginInputCss};
  &.lg {
    padding-right: ${rem(48)};
  }
`;

const LoginInputUsername = styled(EmailAutocomplete)`
  ${LoginInputCss};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.media('xs')} {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const ForgotPasswordLink = styled(Link)`
  font-size: ${rem(14)};
  margin-top: ${rem(20)};
  ${({ theme }) => theme.media('xs')} {
    margin-top: 0;
    margin-left: ${rem(20)};
  }
`;

const PasswordIcon = styled(Icon)`
  position: absolute;
  top: ${rem(8)};
  right: ${rem(12)};
  ${({ theme }) => theme.size(40)};
  fill: ${({ theme }) => theme.color.gray70};
`;

const RegistrationLink = styled(Link)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  margin-top: ${rem(30)};
`;

const ErrorMessage = styled(Alert)`
  margin-bottom: ${rem(10)};
`;

const TitleStyled = styled(Title)`
  margin-bottom: ${rem(48)};
`;

const LoginInputPasswordWrapper = styled.div`
  position: relative;
`;

const metaUid = 'LOGIN';

interface LoginProps {
  push: (path: string) => any;
  registrationText: any;
  globalMetaTags: any;
}

interface LoginState {
  defaultEmail: string;
  error: string;
  newPasswordRequest: boolean;
  showPassword: boolean;
}

class Login extends React.Component<LoginProps, LoginState> {
  public static async getInitialProps(parentProps) {
    const { dispatch, registrationText } = parentProps;
    const promises: any[] = [];

    promises.push(dispatch(loadGlobalMetaTags(metaUid)));

    if (!registrationText) {
      promises.push(dispatch(loadRegistrationText()));
    }

    await Promise.all(promises);
  }

  public static getDerivedStateFromProps(props, state) {
    if (isSsr()) {
      return;
    }
    const hashService = new UrlHashService();
    if (hashService.get('error')) {
      const email = decodeURIComponent(hashService.get('username', ''));
      const error = decodeURIComponent(hashService.get('error', ''));

      if (email) {
        state = { ...state, defaultEmail: email };
      }
      if (error) {
        state = { ...state, error };
      }
    }
    if (hashService.get('new_password')) {
      state = { ...state, newPasswordRequest: true };
    }

    if (hashService.get('access_token')) {
      if (cookie.load(USER_COOKIE)) {
        props.dispatch(logout());
        cookie.remove(USER_COOKIE, { path: '/' });
      }

      props.dispatch(
        loginUserAndLoadCart(
          hashService.get('access_token'),
          hashService.get('cart_id'),
        ),
      );

      if (props.push) {
        if (hashService.get('skvelko_type') === '1') {
          props.push('/');
        } else {
          props.push('/my-account');
        }
      }
    } else {
      cookie.remove(USER_COOKIE, { path: '/' });
    }

    return state;
  }

  constructor(props) {
    super(props);

    this.state = {
      defaultEmail: '',
      error: '',
      newPasswordRequest: false,
      showPassword: false,
    };
  }

  public registrationText = () => {
    return {
      __html: this.props.registrationText?.body,
    };
  };

  public getLoginUrl = () => {
    return `${
      process.env.REACT_APP_API_BASE_URL
    }/oauth/authorize?redirect_uri=${
      process.env.REACT_APP_URL
    }${URL_LOGIN}&client_id=eshop&scope=all${
      cookie.load(CART_COOKIE_ID)
        ? '&cart_id=' + cookie.load(CART_COOKIE_ID)
        : ''
    }`;
  };

  public submit = (event) => {
    event.preventDefault();

    const form: any = document.getElementById('form');

    if (form) {
      form.action = this.getLoginUrl();
      form.submit();
    }
  };

  public handlePasswordIcon = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  public render() {
    const { globalMetaTags } = this.props;
    const { error, newPasswordRequest, defaultEmail } = this.state;
    return (
      <ContainerLogin className="base">
        {globalMetaTags[metaUid] ? (
          <MetaTags tags={globalMetaTags[metaUid]} />
        ) : (
          <MetaTags tags={{ title: 'Daffer.sk - Registrácia' }} />
        )}
        <Wrapper>
          <Breadcrumbs
            items={[
              { name: 'Môj účet', url: '' },
              { name: 'Prihlásenie', url: '' },
            ]}
          />
          <LoginBlocks>
            <LoginLeftBlock>
              <TitleStyled>{__('Prihlásenie')}</TitleStyled>
              {error && <ErrorMessage type="error"> {error} </ErrorMessage>}
              {newPasswordRequest && (
                <ErrorMessage type="info">
                  {' '}
                  {__(
                    'Váš účet vyžaduje obnovu hesla. Do vašej emailovej schránky sme zaslali link na obnovu hesla.',
                  )}{' '}
                </ErrorMessage>
              )}

              <form action={this.getLoginUrl()} method="POST" id={'form'}>
                <FormWrapper>
                  <LoginInputUsername
                    name="username"
                    defaultValue={defaultEmail}
                    placeholder={`${__('E-mail')} / ${__('IČO')}`}
                    className="lg"
                  />
                  <LoginInputPasswordWrapper>
                    <LoginInputPassword
                      type={this.state.showPassword ? 'text' : 'password'}
                      name={'password'}
                      placeholder={__('Heslo')}
                      className={'lg'}
                    />
                    <PasswordIcon
                      icon={'password'}
                      onClick={this.handlePasswordIcon}
                    />
                  </LoginInputPasswordWrapper>

                  <CheckboxStyled name="remember_me" value="1" id="remember_me">
                    {__('Zapamätať prihlásenie')}
                  </CheckboxStyled>
                </FormWrapper>

                <Buttons>
                  <ButtonStyled className="primary lg" onClick={this.submit}>
                    {__('Prihlásiť sa')}
                  </ButtonStyled>
                  <ForgotPasswordLink to="/zabudnute-heslo">
                    {__('Zabudli ste heslo?')}
                  </ForgotPasswordLink>
                </Buttons>
              </form>
            </LoginLeftBlock>

            <LoginRightBlock>
              <TitleStyled>{__('Nová registrácia')}</TitleStyled>
              <div dangerouslySetInnerHTML={this.registrationText()} />
              <Buttons>
                <RegistrationLink
                  to={URL_REGISTRATION_RETAIL}
                  className="primary lg"
                >
                  {__('Nová registrácia')}
                </RegistrationLink>
              </Buttons>
            </LoginRightBlock>
          </LoginBlocks>
        </Wrapper>
      </ContainerLogin>
    );
  }
}

const mapStateToProps = (state, actions) => {
  return {
    push: actions.router.push,
    registrationText: state.general.registrationText,
    globalMetaTags: state.general.globalMetaTags,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Login' })(Login),
);
