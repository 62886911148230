import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';

const ModalDiv = styled(BaseModal)`
  width: ${rem(760)};
`;

const DpdTable = styled.table`
  display: block;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  ${({ theme }) => theme.media('md')} {
    display: table;
  }
`;

const DpdTr = styled.tr``;
const DpdTableLabel = styled.td`
  width: 80px;
  font-weight: bold;
`;

const DpdTableText = styled.td``;

const Title = styled.div`
  display: block;
  font-size: ${rem(25)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.gray89};
  margin-bottom: 10px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

interface ModalProps {
  deliveryPoint: any;
  onCloseButtonClick?: (event) => void;
}

const weekDays = [
  __('Nedeľa'),
  __('Pondelok'),
  __('Utorok'),
  __('Streda'),
  __('Štvrtok'),
  __('Piatok'),
  __('Sobota'),
];

export function formatTime(time: string): string {
  const newTime = time.match(/.{2}/g);
  return newTime ? newTime.join(':') : '';
}

const prepareOpeningHours = openingHoursData => {
  const openingHours: any = [];

  // magicTrick
  const sortedOpeningHours: Array<{
    id: number;
    from: string;
    to: string;
  }> = openingHoursData.sort((obj1, obj2) => {
    if (obj1.id > obj2.id || obj1.from > obj2.from) {
      return 1;
    }

    if (obj1.id < obj2.id || obj1.from < obj2.from) {
      return -1;
    }

    return 0;
  });

  sortedOpeningHours.forEach(
    (hours: { id: number; from: string; to: string }) => {
      if (!openingHours[hours.id]) {
        openingHours[hours.id] = [];
      }

      openingHours[hours.id].push({
        from: formatTime(hours.from),
        to: formatTime(hours.to),
      });
    },
  );

  return openingHours;
};

class DpdDeliveryPointModal extends React.PureComponent<ModalProps> {
  public render() {
    const openingHours = prepareOpeningHours(
      this.props.deliveryPoint.openingHours.day,
    );
    const fullAddress =
      this.props.deliveryPoint.name +
      ', ' +
      this.props.deliveryPoint.street +
      ' ' +
      this.props.deliveryPoint.houseno +
      ', ' +
      this.props.deliveryPoint.city;
    // @ts-ignore
    return (
      <ModalDiv
        title={fullAddress}
        body={
          <div>
            <Section>
              <Title>{__('Adresa')}</Title>
              <DpdTable>
                <DpdTr>
                  <DpdTableLabel>{__('Názov')}</DpdTableLabel>
                  <DpdTableText>{this.props.deliveryPoint.name}</DpdTableText>
                </DpdTr>
                <DpdTr>
                  <DpdTableLabel>{__('Ulica')}</DpdTableLabel>
                  <DpdTableText>
                    {this.props.deliveryPoint.street}{' '}
                    {this.props.deliveryPoint.houseno}
                  </DpdTableText>
                </DpdTr>
                <DpdTr>
                  <DpdTableLabel>{__('Mesto')}</DpdTableLabel>
                  <DpdTableText>
                    {this.props.deliveryPoint.zip}{' '}
                    {this.props.deliveryPoint.city}
                  </DpdTableText>
                </DpdTr>
              </DpdTable>
            </Section>
            <Section>
              <Title>{__('Otváracie hodiny')}</Title>
              <DpdTable>
                {openingHours.map((day, i) => {
                  return (
                    <DpdTr key={i}>
                      <DpdTableLabel>{weekDays[i]}:</DpdTableLabel>
                      {day.map(
                        (dayHours: { from: string; to: string }, dhi) => {
                          return (
                            <DpdTableText key={dhi}>
                              {dayHours.from} : {dayHours.to}
                            </DpdTableText>
                          );
                        },
                      )}
                    </DpdTr>
                  );
                })}
              </DpdTable>
            </Section>
            {this.props.deliveryPoint.how_to_get_to ? (
              <Section>
                <Title>{__('Umiestnenie výdajného miesta')}</Title>
                <DpdTable>
                  <DpdTr>
                    <DpdTableText>
                      {this.props.deliveryPoint.how_to_get_to}
                    </DpdTableText>
                  </DpdTr>
                </DpdTable>
              </Section>
            ) : (
              ''
            )}
            {this.props.deliveryPoint.parking_info ? (
              <Section>
                <Title>{__('Dostupnosť autom')}</Title>
                <DpdTable>
                  <DpdTr>
                    <DpdTableText>
                      {this.props.deliveryPoint.parking_info}
                    </DpdTableText>
                  </DpdTr>
                </DpdTable>
              </Section>
            ) : (
              ''
            )}
          </div>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default DpdDeliveryPointModal;
