import * as React from 'react';
import { getUrl, isObjectEmpty } from '../../../../utilities';
import { rem } from 'polished';
import styled from 'styled-components';
import { Link } from 'react-router';
import ImagePlaceholder from '../../../_helpers/Image/ImagePlaceholder';
import { Media, MediaContextProvider } from '../../../../theme/media';

const List = styled.ul`
  padding: 0;
  margin: 0 0 ${rem(10)} 0;
  list-style: none;
`;

const WebContentItem = styled.li`
  padding: ${rem(9)} ${rem(9)} ${rem(9)} 0;
  font-size: ${rem(16)};
  line-height: 1;
`;

const WebContentLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
`;

const WebContentImg = styled(ImagePlaceholder)`
  min-width: ${(props) => rem(props.w)};
  min-height: ${(props) => rem(props.h)};
  width: ${(props) => rem(props.w)};
  height: ${(props) => rem(props.h)};
  vertical-align: middle;
`;

const WebContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WebContentName = styled.p`
  padding: ${rem(2)} 0 ${rem(2)} ${rem(12)};
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${({ theme }) => theme.media('xs')} {
    &.bold {
      font-weight: bold;
    }
  }
`;

interface WebContentListProps {
  webContent: any;
  onRedirect?: (url) => void;
}

const WebContentList = (props: WebContentListProps) => {
  const { webContent, onRedirect } = props;

  const placeholder = '/images/placeholder-46.png';
  const placeholder2x = '/images/placeholder-46@2x.png';

  return (
    <>
      {webContent?.web_content?.length ? (
        <List>
          {webContent.web_content.map((item, index) => (
            <WebContentItem key={index}>
              <MediaContextProvider>
                <Media lessThan="xs">
                  <WebContentLink
                    to={getUrl(item.url)}
                    onClick={onRedirect?.bind(undefined, item.url)}
                  >
                    <WebContentImg
                      src={item?.json_content?.meta?.og_image}
                      w={88}
                      h={42}
                      placeholder={placeholder}
                      retinaPlaceholder={placeholder2x}
                      notLazyloading={true}
                    />
                    <WebContentName>{item?.json_content?.name}</WebContentName>
                  </WebContentLink>
                </Media>
                <Media greaterThanOrEqual="xs">
                  <WebContentLink
                    to={getUrl(item.url)}
                    onClick={onRedirect?.bind(undefined, item.url)}
                  >
                    <WebContentImg
                      src={item?.json_content?.meta?.og_image}
                      w={190}
                      h={100}
                      placeholder={placeholder}
                      retinaPlaceholder={placeholder2x}
                      notLazyloading={true}
                    />
                    <WebContentWrapper>
                      {item?.json_content?.meta?.title &&
                      !isObjectEmpty(item.json_content.meta.title) ? (
                        <WebContentName className={'bold'}>
                          {item?.json_content?.meta?.title}
                        </WebContentName>
                      ) : (
                        <WebContentName className={'bold'}>
                          {item?.json_content?.name}
                        </WebContentName>
                      )}
                      {item?.json_content?.meta?.description &&
                      !isObjectEmpty(item.json_content.meta.description) ? (
                        <WebContentName>
                          {item.json_content.meta.description}
                        </WebContentName>
                      ) : (
                        ''
                      )}
                    </WebContentWrapper>
                  </WebContentLink>
                </Media>
              </MediaContextProvider>
            </WebContentItem>
          ))}
        </List>
      ) : (
        ''
      )}
    </>
  );
};

export default WebContentList;
