import { css } from 'styled-components';
import { rem } from 'polished';

export const BlockAppearanceStyles = css`
  background: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.media('sm')} {
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  }
`;

export const BlockShadowStyles = css`
  box-shadow: ${({ theme }) => theme.boxShadow.small};
`;

export const BlockMarginStyles = css`
  margin-right: 0;
  margin-left: 0;
`;
