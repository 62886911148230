import * as actionTypes from './constants';

const initialState = {
  skvelkoSetListLoader: false,
  productsForSetsLoader: false,
  renumerationLoader: false,

  ordersBySetLoader: false,

  ordersBySet: { orders: [] },

  ordersForSet: [],
};

export const myAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DASHBOARD:
      return {
        ...state,
        dashboardData: action.payload.dashboardData,
      };
    case actionTypes.SET_SKVELKO_ADVANTAGE_TEXT:
      return {
        ...state,
        skvelkoAdvantageText: action.payload.skvelkoAdvantageText,
      };

    case actionTypes.SET_SET_LIST:
      return {
        ...state,
        setList: action.payload.setList,
      };

    case actionTypes.SET_SKVELKO_SET:
      return {
        ...state,
        skvelkoSet: action.payload.skvelkoSet,
      };
    case actionTypes.SET_SKVELKO_SET_LIST_LOADER:
      return {
        ...state,
        skvelkoSetListLoader: action.payload.skvelkoSetListLoader,
      };

    case actionTypes.SET_PRODUCTS_FOR_SETS:
      return {
        ...state,
        productsForSets: action.payload.productsForSets,
      };

    case actionTypes.SET_CATEGORIES_FOR_SETS:
      return {
        ...state,
        categoriesForSets: action.payload.categoriesForSets,
      };

    case actionTypes.SET_PRODUCTS_FOR_SETS_LOADER:
      return {
        ...state,
        productsForSetsLoader: action.payload.productsForSetsLoader,
      };

    case actionTypes.SET_CLASS_LIST_LOADER:
      return {
        ...state,
        classListLoader: action.payload.classListLoader,
      };

    case actionTypes.SET_CLASS_SEARCH:
      return {
        ...state,
        classSearch: action.payload.classSearch,
      };

    case actionTypes.SET_RENUMERATION_DATA:
      return {
        ...state,
        renumerationData: action.payload.renumerationData,
      };

    case actionTypes.SET_RENUMERATION_LOADER:
      return {
        ...state,
        renumerationLoader: action.payload.renumerationLoader,
      };

    case actionTypes.SET_ORDERS_BY_SET_LOADER:
      return {
        ...state,
        ordersBySetLoader: action.payload.ordersBySetLoader,
      };

    case actionTypes.SET_ORDERS_BY_SET:
      return {
        ...state,
        ordersBySet: action.payload.ordersBySet,
      };

    case actionTypes.SET_ORDERS_FOR_SET:
      return {
        ...state,
        ordersForSet: action.payload.ordersForSet,
      };

    case actionTypes.SET_MY_ORDERS_LOADER:
      return {
        ...state,
        myOrdersLoader: action.payload.myOrdersLoader,
      };

    case actionTypes.SET_MY_ORDERS:
      return {
        ...state,
        myOrders: action.payload.myOrders,
      };

    case actionTypes.REQUEST_ORDER_DATA: {
      return {
        ...state,
        orderDetail: {
          isFetching: true,
        },
      };
    }

    case actionTypes.RECEIVE_ORDER_DATA_SUCCESS: {
      return {
        ...state,
        orderDetail: {
          isFetching: false,
          order: action.payload.order,
        },
      };
    }

    case actionTypes.RECEIVE_ORDER_DATA_ERROR: {
      return {
        ...state,
        orderDetail: {
          isFetching: false,
          error: action.payload.error,
        },
      };
    }

    case actionTypes.SET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload.userDetail,
      };

    case actionTypes.SET_USER_DETAIL_LOADER:
      return {
        ...state,
        userDetailLoader: action.payload.userDetailLoader,
      };

    case actionTypes.SET_TOTAL_SET_PRODUCTS:
      return {
        ...state,
        totalSetProducts: action.payload.totalSetProducts,
      };

    case actionTypes.SET_LAST_BILLING_ADDRESS:
      return {
        ...state,
        lastBillingAddress: action.payload.lastBillingAddress,
      };

    case actionTypes.SET_LAST_DELIVERY_ADDRESS:
      return {
        ...state,
        lastDeliveryAddress: action.payload.lastDeliveryAddress,
      };

    case actionTypes.SET_USE_DELIVERY_ADDRESS:
      return {
        ...state,
        useDeliveryAddress: action.payload.useDeliveryAddress,
      };

    case actionTypes.SET_CUSTOMER_CARD:
      return {
        ...state,
        customerCard: action.payload.customerCard,
      };

    case actionTypes.SET_DELIVERY_SCHOOL_TEXT:
      return {
        ...state,
        deliverySchoolText: action.payload.deliverySchoolText,
      };
    case actionTypes.SET_HOW_TO_EARN_POINTS_TEXT:
      return {
        ...state,
        howToEarnPointsText: action.payload.howToEarnPointsText,
      };

    case actionTypes.SET_INFO_TEACHER_AVAILABILITY:
      return {
        ...state,
        teacherAvailabilityText: action.payload.teacherAvailabilityText,
      };

    case actionTypes.SET_INFO_SAVE_INACCESSIBLE:
      return {
        ...state,
        saveInaccessibleText: action.payload.saveInaccessibleText,
      };

    case actionTypes.ALERT_EDIT_SET_MOBILE:
      return {
        ...state,
        editSetMobileText: action.payload.editSetMobileText,
      };

    case actionTypes.SET_NOT_SAVED_CHANGES:
      return {
        ...state,
        notSavedChangesText: action.payload.notSavedChangesText,
      };

    case actionTypes.SET_NEW_SET_STATE:
      return {
        ...state,
        newSetState: action.payload.newSetState,
      };

    case actionTypes.SET_CREATING_NEW_SET:
      return {
        ...state,
        creatingNewSetState: action.payload.creatingNewSetState,
      };

    case actionTypes.SET_MOST_FREQUENT_PURCHASED:
      return {
        ...state,
        mostFrequentPurchased: action.payload.mostFrequentPurchased,
      };

    case actionTypes.SET_MOST_FREQUENT_PURCHASED_LOADER:
      return {
        ...state,
        mostFrequentPurchasedLoader: action.payload.mostFrequentPurchasedLoader,
      };

    case actionTypes.SET_FAVORITE_PRODUCTS_WIDGET:
      return {
        ...state,
        favoriteProductsWidget: action.payload.favoriteProductsWidget,
      };

    case actionTypes.SET_FAVORITE_PRODUCTS_WIDGET_LOADER:
      return {
        ...state,
        favoriteProductsWidgetLoader: action.payload.favoriteProductsWidget,
      };

    case actionTypes.SET_CUSTOMER_PRODUCTS_TEXT:
      return {
        ...state,
        customerProductText: action.payload.customerProductText,
      };

    case actionTypes.SET_PRODUCTS_CUSTOMER:
      return {
        ...state,
        productsCustomer: action.payload.productsCustomer,
      };

    case actionTypes.SET_PRODUCTS_CUSTOMER_LOADER:
      return {
        ...state,
        productsCustomerLoader: action.payload.productsCustomerLoader,
      };

    case actionTypes.SET_SET_BULK_ORDER_TEXT:
      return {
        ...state,
        setBulkOrderText: action.payload.setBulkOrderText,
      };

    case actionTypes.SET_THANKYOU_SKVELKOBOX_TEXT:
      return {
        ...state,
        thankYouSkvelkoBoxText: action.payload.thankYouSkvelkoBoxText,
      };

    default:
      return state;
  }
};
