import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { Container, grid } from '../../theme/libraries/grid';
import Newsletter from '../../components/Newsletter/Newsletter';
import CMSPreviews from '../../components/_layout/Homepage/CMSPreviews';
import { sendNewsletter } from './actions';
import MetaTags from '../../components/MetaTags/MetaTags';
import {
  canShowHomepageSkvelkoSearch,
  loadGlobalMetaTags,
  prop,
} from '../../utilities';
import { CMSPreviewsData } from './constants';
import EshopOffer from '../../components/_layout/Homepage/EshopOffer';
import HomeCategories from '../../components/_layout/Homepage/HomeCategories';
import { Media, MediaContextProvider } from '../../theme/media';
import Highlights from '../../components/_layout/Homepage/Highlights';
import BannerSwiper from '../../components/_layout/BannerSwiper/BannerSwiper';
import {
  loadHomepageBanners,
  resetHomepageBanners,
  resetSchoolBanners,
} from '../App/actions';
import { BANNER_HOMEPAGE, HOMEPAGE_UNIQID } from '../../constants';
import SkvelkoSearchBox from '../Sets/SkvelkoSearchBox';

const ContainerHome = styled(Container)`
  padding-right: 0;
  padding-left: 0;
  ${({ theme }) => theme.media('sm')} {
    padding-right: ${rem(grid.padding / 2)};
    padding-left: ${rem(grid.padding / 2)};
  }
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
  background-clip: content-box;
`;

const SkvelkoContainer = styled(ContainerHome)`
  background-color: initial;
  padding-left: ${rem(15)};
  padding-right: ${rem(15)};
`;

const SkvelkoSearchBoxWrapper = styled.div`
  margin-top: ${rem(12)};
  margin-bottom: ${rem(12)};
`;

interface Props {
  homepageBanners: any[];
  parentProps: {};
  dispatch: any;
  isError: boolean;
  loaded: any;
  newsletterLoader: boolean;
  newsletterResponse: boolean | null;
  metaTags: any;
  categoriesGroup: any;
  deliveryFreePrice?: number;
  user: any;
  isFetchingUser: boolean;
  settings: any;
}

class Home extends React.Component<Props> {
  public static async getInitialProps(parentProps) {
    try {
      const promises: any[] = [];

      if (parentProps?.location?.query?.resetbanners) {
        promises.push(parentProps.dispatch(resetHomepageBanners()));
        promises.push(parentProps.dispatch(resetSchoolBanners()));
      }

      promises.push(parentProps.dispatch(loadHomepageBanners()));
      promises.push(parentProps.dispatch(loadGlobalMetaTags(HOMEPAGE_UNIQID)));

      await Promise.all(promises);

      return {
        parentProps,
        loaded: true,
      };
    } catch (exp) {
      return {
        isError: true,
      };
    }
  }

  public onNewsletterSubmit = (e) => {
    e.preventDefault();
    this.props.dispatch(sendNewsletter(e.target.email.value));
  };

  public render() {
    const cmsPreviewsData: CMSPreviewsData = [
      {
        image: '/images/homepage/bg01.jpg',
        imageMobile: '/images/homepage/bg01mobil.jpg',
        name: __('Sieť papiernictiev'),
        description: __('Sme tu pre Vás v 20 predajniach po celom Slovensku.'),
        items: [
          __('sme tu pre Vás v 20 predajniach po celom Slovensku'),
          __('ochotný personál'),
          __('široká ponuka pre školákov a domácnosť'),
        ],
        buttonText: __('Zoznam predajní'),
        buttonLink: '/predajne',
        bgColor: '#ffeff2',
      },
      {
        image: '/images/homepage/bg02.jpg',
        imageMobile: '/images/homepage/bg02mobil.jpg',
        name: __('Pre firmy a organizácie'),
        nameMobile: __('Daffer pre firmy a organizacie'),
        description: __(
          'Využívajte výhodné nakupovanie pre firemných zákazníkov.',
        ),
        items: [
          __('rýchle dodanie po celom Slovensku'),
          __('odborné poradenstvo prideleným asistentom'),
          __('výhodné ceny a množstevné zľavy'),
        ],
        buttonText: __('Viac info'),
        buttonLink: '/firmy-a-organizacie/nase-vyhody',
        bgColor: '#fff7c0',
      },
      {
        image: '/images/homepage/bg03.jpg',
        imageMobile: '/images/homepage/bg03mobil.jpg',
        name: __('Pre školské zariadenia'),
        nameMobile: __('Daffer pre školské zariadenia'),
        description: __('Ponúkame komplexné vybavenie pre školy a škôlky.'),
        items: [
          __('ponuka nábytku a vybavenia škôl'),
          __('doživotná záruka na kovové konštrukcie ESO'),
          __('obhliadka priestorov a odborná montáž'),
        ],
        buttonText: __('Viac info'),
        buttonLink: '/skoly',
        bgColor: '#eef5ff',
      },
    ];

    const highlights = [
      {
        icon: 'slovak_emblem',
        text: __('Slovenská firma na trhu od 1996'),
      },
      {
        icon: 'shipping',
        text: `${__('Doprava zadarmo už od')} ${
          this.props.deliveryFreePrice
        } €`,
      },
      {
        icon: 'location',
        text: __('Sieť predajní po celej SR'),
      },
    ];

    const eshopOfferLink =
      (this.props.categoriesGroup &&
        this.props.categoriesGroup.length &&
        this.props.categoriesGroup[0].uniqid_url) ||
      '/';

    const canShowSkvelkoSearchBox =
      this.props?.settings?.skvelkoHomepage == 1 &&
      !this.props.isFetchingUser &&
      canShowHomepageSkvelkoSearch(this.props.user);

    return (
      <MediaContextProvider>
        {this.props.metaTags ? (
          <MetaTags tags={this.props.metaTags} />
        ) : (
          <MetaTags tags={{ title: 'Daffer.sk' }} />
        )}

        {canShowSkvelkoSearchBox ? (
          <SkvelkoContainer className="base">
            <SkvelkoSearchBoxWrapper>
              <SkvelkoSearchBox redirectOnSearch={true} />
            </SkvelkoSearchBoxWrapper>
          </SkvelkoContainer>
        ) : (
          <></>
        )}

        <BannerSwiper
          banners={this.props.homepageBanners}
          user={this.props.user}
        />

        <ContainerHome className="base">
          <HomeCategories
            categoriesGroup={this.props.categoriesGroup}
            dispatch={this.props.dispatch}
          />
          <CMSPreviews cmsPreviewsData={cmsPreviewsData} />
          <Media lessThan="md">
            <EshopOffer link={eshopOfferLink} />
          </Media>
          <Media greaterThanOrEqual="md">
            <Highlights items={highlights} />
          </Media>
        </ContainerHome>
        <Media greaterThanOrEqual="md">
          <Newsletter
            loader={this.props.newsletterLoader}
            response={this.props.newsletterResponse}
            onSubmit={this.onNewsletterSubmit}
          />
        </Media>
      </MediaContextProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    homepageBanners: state.general.banners?.[BANNER_HOMEPAGE]?.banners,
    metaTags: state.general.globalMetaTags?.[HOMEPAGE_UNIQID],
    newsletterLoader: prop(state.home, 'newsletterLoader', false),
    newsletterResponse: prop(state.home, 'newsletterResponse', null),
    deliveryFreePrice: state.general.deliveryFreePrice,
    categoriesGroup: state.general.categoriesGroup,
    user: state.auth.user,
    isFetchingUser: state.auth.isFetchingUser,
    settings: state.general.settings,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Home' })(Home),
);
