import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import ImagePlaceholder from '../Image/ImagePlaceholder';

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 ${rem(-5)};
`;

const Item = styled.li`
  flex: 0 1 ${rem(150)};
  list-style: none;
  padding: ${rem(5)};
`;

const ThumbWrapper = styled.a`
  display: block;
  padding: ${rem(10)};
  cursor: pointer;
`;

const Thumb = styled(ImagePlaceholder)``;

interface PhotogalleryProps {
  items: Array<{
    document_path: string;
  }>;

  onThumbClick?: (index) => void;
}

class Photogallery extends React.PureComponent<PhotogalleryProps> {
  public render() {
    const placeholder = '/images/placeholder-150-100.png';
    const placeholder2x = '/images/placeholder-150-100@2x.png';

    return (
      <List>
        {this.props.items.map((item, i) => {
          if (item) {
            return (
              <Item key={i}>
                <ThumbWrapper
                  onClick={
                    this.props.onThumbClick
                      ? this.props.onThumbClick.bind(this, i)
                      : null
                  }
                >
                  <Thumb
                    src={item}
                    placeholder={placeholder}
                    retinaPlaceholder={placeholder2x}
                    w={150}
                    h={100}
                  />
                </ThumbWrapper>
              </Item>
            );
          } else {
            return '';
          }
        })}
      </List>
    );
  }
}

export default Photogallery;
