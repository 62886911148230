import { color } from './colors';
import { rem } from 'polished';

const caret =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMiAxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyIDE7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBwb2ludHM9IjAsMCAyLDAgMSwxICIvPjwvc3ZnPg==';

export const form = {
  border: {
    width: 2,
    style: 'solid',
    color: color.gray81,
    colorFocus: color.gray70,
    radius: 4,
  },
  background: {
    default: color.white,
    disabled: color.gray97,
    select: `${color.white} right ${rem(10)} center url(${caret}) no-repeat`,
  },
  color: {
    default: color.text,
    disabled: color.gray66,
    placeholder: color.gray60,
  },
  font: {
    size: 14,
  },
  padding: {
    horizontal: 10,
    vertical: 15,
  },
  checkbox: {
    size: 24,
    gutter: 10,
  },
  radio: {
    size: 24,
    gutter: 10,
  },
};
