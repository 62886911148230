import * as React from 'react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { MenuItems } from '../../constants';

export const DropdownStyles = css`
  display: none;
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 11;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
`;

const Wrapper = styled.ul`
  ${DropdownStyles};
  font-size: ${rem(14)};
`;

const Item = styled.li`
  list-style: none;
  & + & {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
`;

const DLink = styled(Link)`
  display: block;
  color: inherit;
  padding: ${rem(14)};
  text-decoration: none;
  ${({ theme }) => theme.transition('color')};
  &.highlighted {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const Hover = styled.span`
  display: inline-block;
  position: relative;
  &:before {
    display: block;
    content: '';
    ${({ theme }) => theme.size(0, 1)};
    background: ${({ theme }) => theme.color.text};
    ${({ theme }) => theme.transition('width')};
    position: absolute;
    bottom: ${rem(2)};
    left: 0;
  }
  ${DLink}.highlighted & {
    &:before {
      background: ${({ theme }) => theme.color.primary};
    }
  }
  ${DLink}:hover & {
    &:before {
      width: 100%;
    }
  }
`;

interface DropdownProps {
  className?: string;
  items: MenuItems;
  onItemClick?: (e) => void;
}

class Dropdown extends React.PureComponent<DropdownProps> {
  public render() {
    const { className, items } = this.props;

    return (
      <Wrapper className={className}>
        {items.map((item, i) => {
          return (
            <Item key={i}>
              <DLink
                to={item.url}
                className={
                  item.highlighted !== undefined && item.highlighted
                    ? 'highlighted'
                    : ''
                }
                onClick={this.props.onItemClick}
              >
                <Hover>{item.name}</Hover>
              </DLink>
            </Item>
          );
        })}
      </Wrapper>
    );
  }
}

export default Dropdown;
