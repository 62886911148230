import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';
import { Col, Row } from '../../../theme/libraries/grid';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import { ThumbStyles } from '../product/product-styles';
import { Heading3 } from '../Heading/Heading';
import {
  ProductDownloads,
  ProductShortDescription,
} from '../../Product/Product';

const StyledModal = styled(BaseModal)`
  width: ${rem(510)};
`;

const ThumbCol = styled(Col)`
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media('xs')} {
    width: ${({ theme }) => rem(140 + theme.grid.padding)};
  }
`;

const Thumb = styled(ImagePlaceholder)`
  display: block;
  margin: auto;
  ${ThumbStyles};
`;

const MainCol = styled(Col)`
  ${({ theme }) => theme.media('xs')} {
    padding-left: ${({ theme }) => rem(140 + theme.grid.padding + 10)};
    margin-left: ${({ theme }) => rem(-(140 + theme.grid.padding))};
  }
`;

const Title = styled(Heading3)`
  margin: ${rem(10)} 0 ${rem(15)};
  &:first-child {
    line-height: 1;
    margin-top: 0;
  }
`;

interface ModalProps {
  product: any;
  onCloseButtonClick?: (event) => void;
}

class ProductPreviewModal extends React.PureComponent<ModalProps> {
  public render() {
    const { product } = this.props;
    const placeholder = '/images/placeholder-200.png';
    const placeholder2x = '/images/placeholder-200@2x.png';

    const plu = product?.plu || product?.product_plu;
    const ean = product?.ean || product?.product_ean;

    const body = (
      <Row>
        <ThumbCol>
          <Thumb
            src={product && product.picture}
            placeholder={placeholder}
            retinaPlaceholder={placeholder2x}
            w={200}
            h={200}
          />
        </ThumbCol>
        <MainCol>
          <span>Kód: </span> {plu?.startsWith('S') ? `${plu}-${ean}` : plu}
          {product && (
            <React.Fragment>
              <Title>{__('Popis produktu')}</Title>
              {
                <ProductShortDescription
                  product={product}
                  className="preview"
                />
              }
            </React.Fragment>
          )}
          {/*{product && product.attribs.length ? (*/}
          {/*  <React.Fragment>*/}
          {/*    <Title>{__('Parametre')}</Title>*/}
          {/*    <ProducParams product={product} className="preview" />*/}
          {/*  </React.Fragment>*/}
          {/*) : (*/}
          {/*  ''*/}
          {/*)}*/}
          {product && product.publish[0].content.downloads.length ? (
            <React.Fragment>
              <Title>{__('Na stiahnutie')}</Title>
              <ProductDownloads product={product} className="preview" />
            </React.Fragment>
          ) : (
            ''
          )}
        </MainCol>
      </Row>
    );
    return (
      <StyledModal
        title={<strong>{product?.name || ''}</strong>}
        body={body}
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default ProductPreviewModal;
