import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { Container } from '../../theme/libraries/grid';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import styled from 'styled-components';
import { ContentContainerBlock } from '../../components/_helpers/Contact/ContactStyles';
import { rem } from 'polished';
import { Content } from '../../components/_layout/Page/ContentContainer';
import { __ } from 'react-i18n/lib';
import { HeadingIconStyles } from '../../components/Cart/ThankYou';
import Icon from '../../components/_helpers/Icon/Icon';
import {
  ButtonStyledStyles,
  ButtonStyles,
} from '../../components/_helpers/form/Button';
import { Link } from 'react-router';
import { loadFinished, setFinishedText } from './action';
import { OtherText } from '../../constants';

const Wrapper = styled(Content)`
  font-size: ${rem(14)};
  text-align: center;
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(40)};
  }
  ${({ theme }) => theme.media('lg')} {
    padding: ${rem(40)};
  }
`;

const Success = styled(Icon)`
  ${HeadingIconStyles};
`;

const FinishedButton = styled(Link)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  margin-top: ${rem(40)};
`;

const RegistrationContent = styled.div``;

interface FinishedProps {
  textId: string;
  finishedText?: OtherText;
}

class Finished extends React.Component<FinishedProps> {
  public static async getInitialProps(parentProps) {
    if (parentProps.textId)
      await parentProps.dispatch(setFinishedText(undefined));
    await parentProps.dispatch(loadFinished(parentProps.textId));
  }

  public render() {
    const { finishedText } = this.props;

    return (
      <Container className="base">
        <Breadcrumbs />
        <Wrapper>
          <ContentContainerBlock>
            <Success icon="checkCircle" />
            {finishedText?.body ? (
              <RegistrationContent
                dangerouslySetInnerHTML={{
                  __html: finishedText.body,
                }}
              />
            ) : (
              ''
            )}
            <FinishedButton to="/" className="primary lg">
              {__('Ponuka internetového obchodu')}
            </FinishedButton>
          </ContentContainerBlock>
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    finishedText: state.registration.finishedText,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Finished' })(Finished),
);
