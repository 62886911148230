import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { Label } from '../../components/_helpers/form/Label';
import { InputWithSearchIcon } from '../../components/_helpers/form/FormStyles';
import { InputStyled } from '../../components/_helpers/form/Input';
import ProductPreview from '../../components/_helpers/product/ProductPreview';
import { TextPlaceholder } from '../../components/_helpers/Placeholders';
import { ProductCol, ProductsRow } from '../../components/Category/Category';
import { MainBlock } from './Ucet';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import { loadProductsForSets, loadCategoriesForSets } from './actions';
import Pagination from '../../components/Pagination/Pagination';
import { prop } from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';
import {
  TITLE_ACCOUNT_PRODUCTS_FOR_SETS,
  TITLE_ACCOUNT_SETS,
  URL_ACCOUNT_SETS,
} from '../../constants';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 ${({ theme }) => theme.grid.col.col12};
  max-width: ${rem(280)};
  ${({ theme }) => theme.media('xs')} {
    max-width: initial;
    & + & {
      margin-top: ${rem(10)};
    }
  }
  ${({ theme }) => theme.media(1024)} {
    flex: 0 0 ${({ theme }) => theme.grid.col.col6};
    & + & {
      padding-left: ${rem(25)};
      margin-top: 0;
    }
  }
`;

export const SearchInput = styled(InputStyled)`
  flex: 0 0 100%;
  ${InputWithSearchIcon};
  ${({ theme }) => theme.media('xs')} {
    flex: 1 1 auto;
    width: ${rem(100)};
  }
  ${({ theme }) => theme.media(1024)} {
    flex: 1 0 auto;
    width: ${rem(100)};
  }
`;

const StyledLabel = styled(Label)`
  flex: 0 0 100%;
  ${({ theme }) => theme.media('xs')} {
    flex: 1 1 auto;
    padding-right: ${rem(10)};
  }
  ${({ theme }) => theme.media(1024)} {
    flex: 0 1 auto;
    ${SearchInput} + & {
      margin-left: ${rem(25)};
    }
  }
`;

const Products = styled(ProductsRow)`
  margin: 0;
`;

const ProductStyledCol = styled(ProductCol)`
  ${({ theme }) => theme.media('sm')} {
    width: ${({ theme }) => theme.grid.col.col6};
  }
  ${({ theme }) => theme.media(940)} {
    width: ${({ theme }) => theme.grid.col.col4};
  }
  ${({ theme }) => theme.media(1170)} {
    width: ${({ theme }) => theme.grid.col.col3};
  }
`;

const ProductsWrapper = styled(MainBlock)`
  margin-top: 20px;
`;

const NoProductsPlaceholder = styled(TextPlaceholder)``;

interface ProductsForSetsProps {
  productsForSets: ThenArg<typeof API.searchProducts>;
  categoriesForSets: any;
  productsForSetsLoader: boolean;
  dispatch: (a: any) => void;
  setTitle: (title: string, link?: string | null) => void;
  currentPath: string;
}

interface ProductsForSetsState {
  filters: {
    categoryId: number | null;
    q: string | null;
    page: number;
  };
}

class ProductsForSets extends React.PureComponent<
  ProductsForSetsProps,
  ProductsForSetsState
> {
  public static async getInitialProps(props) {
    props.dispatch(loadProductsForSets({}));
    props.dispatch(loadCategoriesForSets());
  }

  constructor(props) {
    super(props);

    props.setBreadcrumbsItems([
      { name: TITLE_ACCOUNT_SETS, url: URL_ACCOUNT_SETS },
      { name: TITLE_ACCOUNT_PRODUCTS_FOR_SETS, url: props.currentPath },
    ]);

    this.state = {
      filters: {
        categoryId: null,
        q: null,
        page: 1,
      },
    };
  }

  public filtersChange(filters) {
    this.setState({ filters });
    this.props.dispatch(loadProductsForSets(filters));
  }

  public onQueryChange = (event) => {
    const filters = this.state.filters;
    filters.q = event.target.value;
    filters.page = 1;
    this.filtersChange(filters);
  };

  public onCategoryChange = (event) => {
    const filters = this.state.filters;
    filters.categoryId = event.target.value;
    this.filtersChange(filters);
  };

  public onPageChanged = (page) => {
    const filters = this.state.filters;
    filters.page = page;
    this.filtersChange(filters);
  };

  public render() {
    const { productsForSets, productsForSetsLoader } = this.props;
    const products = productsForSets ? productsForSets.products : [];

    const totalPages = productsForSets
      ? Math.ceil(productsForSets.total / productsForSets.limit)
      : 1;

    return (
      <React.Fragment>
        <MainBlock className={'no-top-padding'}>
          <Form>
            <FormGroup>
              <StyledLabel>{__('Vyhľadávanie')}</StyledLabel>
              <SearchInput
                placeholder={__('Názov produktu')}
                onChange={this.onQueryChange}
              />
            </FormGroup>
          </Form>
        </MainBlock>

        {/*
        <MainBlock className="border-top" />
         */}
        <ProductsWrapper className="gray">
          <Loader loading={productsForSetsLoader}>
            <Products>
              {products.length ? (
                products.map((product: any, i) => {
                  return (
                    <ProductStyledCol key={product.product_id}>
                      <ProductPreview
                        product={product}
                        small={true}
                        fromSkvelkoSet={true}
                      />
                    </ProductStyledCol>
                  );
                })
              ) : (
                <NoProductsPlaceholder>
                  {__('Žiadne produkty sa nenašli')}
                </NoProductsPlaceholder>
              )}
            </Products>
          </Loader>
        </ProductsWrapper>
        {totalPages > 1 && (
          <Pagination
            current={this.state.filters.page}
            total={totalPages}
            onPageChanged={this.onPageChanged}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productsForSets: state.myAccount.productsForSets,
    categoriesForSets: state.myAccount.categoriesForSets,
    productsForSetsLoader: state.myAccount.productsForSetsLoader,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ProductsForSets' })(ProductsForSets),
);
