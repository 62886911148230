import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { Content } from '../../components/_layout/Page/ContentContainer';
import { rem } from 'polished';
import Icon from '../../components/_helpers/Icon/Icon';
import { CartHeading } from '../../components/Cart/CartStyles';
import { __ } from 'react-i18n';
import { getBaseUrl } from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';
import API from '../../services/API';

const ThankYouWrapper = styled(Content)`
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const ThankYouHeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${rem(24)};
`;

const ThankYouHeadingIconStyles = css`
  ${({ theme }) => theme.size(55)};
  fill: ${({ theme }) => theme.color.success};
  &.error {
    fill: ${({ theme }) => theme.color.error};
  }
`;

const ThankYouHeadingIcon = styled(Icon)`
  ${ThankYouHeadingIconStyles};
  margin-right: ${rem(15)};
`;

const ErrorHeadingIcon = styled(ThankYouHeadingIcon)`
  fill: red;
`;

const ThankYouHeading = styled(CartHeading)`
  margin: 0;
`;

export interface UnsubscribeProps {
  params: {
    userHash: string;
  };
  setTitleText: (title: string) => void;
  setClosePath: (path: string) => void;
}

const Unsubscribe = (props: UnsubscribeProps) => {
  props.setTitleText(__('Odhlásenie z newlestera'));
  props.setClosePath(getBaseUrl('/'));
  const [status, setStatus] = React.useState<number>(0);
  React.useEffect(() => {
    API.unsubscribeNewsletter(props.params.userHash)
      .then((body) => {
        if (body.success) {
          setStatus(1);
        } else {
          setStatus(2);
        }
      })
      .catch((e) => {
        setStatus(2);
      });
  }, []);

  return (
    <ThankYouWrapper>
      <ThankYouHeadingWrapper>
        {status === 0 ? (
          <>
            <Loader loading={true}></Loader>
          </>
        ) : (
          <></>
        )}
        {status === 1 ? (
          <>
            <ThankYouHeadingIcon icon="checkCircle" />
            <ThankYouHeading>
              {__('Boli ste úspešne odhlásený z newlestera')}
            </ThankYouHeading>
          </>
        ) : (
          <></>
        )}
        {status === 2 ? (
          <>
            <ErrorHeadingIcon icon="errorCircle" />
            <ThankYouHeading>
              {__('Odhlásenie z newlestera sa nepodarilo')}
            </ThankYouHeading>
          </>
        ) : (
          <></>
        )}
      </ThankYouHeadingWrapper>
    </ThankYouWrapper>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Unsubscribe' })(Unsubscribe),
);
