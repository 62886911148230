import { loadOtherText } from '../../utilities';
import * as actionTypes from './constants';

export const loadRetailIntroLess = () =>
  loadOtherText('REG_RETAIL_INTRO_LESS', setRetailIntroLess);
export const loadRetailIntroMore = () =>
  loadOtherText('REG_RETAIL_INTRO_MORE', setRetailIntroMore);
export const loadCompanyIntroLess = () =>
  loadOtherText('REG_COMPANY_INTRO_LESS', setCompanyIntroLess);
export const loadCompanyIntroMore = () =>
  loadOtherText('REG_COMPANY_INTRO_MORE', setCompanyIntroMore);
export const loadSchoolIntroLess = () =>
  loadOtherText('REG_SCHOOL_INTRO_LESS', setSchoolIntroLess);
export const loadSchoolIntroMore = () =>
  loadOtherText('REG_SCHOOL_INTRO_MORE', setSchoolIntroMore);

export const loadFinished = (textId) => loadOtherText(textId, setFinishedText);

export const detectCustomerCard = (email) => {
  return async (dispatch, getState, API) => {
    try {
      const detect = await API.hasEmailCustomerCard({ email: email });
      dispatch(setHasCustomerCardDetect(detect));
    } catch (e) {
      dispatch(setHasCustomerCardDetect(false));
    }
  };
};

export const setRetailIntroLess = (retailIntroLess) => ({
  type: actionTypes.SET_RETAIL_INTRO_LESS,
  payload: {
    retailIntroLess,
  },
});

export const setRetailIntroMore = (retailIntroMore) => ({
  type: actionTypes.SET_RETAIL_INTRO_MORE,
  payload: {
    retailIntroMore,
  },
});

export const setCompanyIntroLess = (companyIntroLess) => ({
  type: actionTypes.SET_COMPANY_INTRO_LESS,
  payload: {
    companyIntroLess,
  },
});

export const setCompanyIntroMore = (companyIntroMore) => ({
  type: actionTypes.SET_COMPANY_INTRO_MORE,
  payload: {
    companyIntroMore,
  },
});

export const setSchoolIntroLess = (schoolIntroLess) => ({
  type: actionTypes.SET_SCHOOL_INTRO_LESS,
  payload: {
    schoolIntroLess,
  },
});

export const setSchoolIntroMore = (schoolIntroMore) => ({
  type: actionTypes.SET_SCHOOL_INTRO_MORE,
  payload: {
    schoolIntroMore,
  },
});

export const setHasCustomerCardDetect = (hasCustomerCardDetect) => ({
  type: actionTypes.SET_HAS_CUSTOMER_CARD_DETECT,
  payload: {
    hasCustomerCardDetect,
  },
});

export const setFinishedText = (finishedText) => ({
  type: actionTypes.SET_FINISHED_TEXT,
  payload: {
    finishedText,
  },
});
