import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';
import { Select, SelectStyledStyles } from '../form/Select';
import { updateCart } from '../../../containers/Cart/actions';
import { Button1, StyledFooter } from './ChooseModal';

const StyledModal = styled(BaseModal)``;

const StyledSelect = styled(Select)`
  ${SelectStyledStyles};
  display: block;
  width: 100%;
  margin-top: ${rem(10)};
  ${({ theme }) => theme.media('sm')} {
    width: initial;
  }
`;

const CloseButton = styled(Button1)``;

const CustomStyledFooter = styled(StyledFooter)`
  flex-flow: row-reverse;
`;

interface ModalProps {
  title?: string;
  className?: string;
  eshopGifts: Array<any>;
  cart: any;
  dispatch: any;
  onCloseButtonClick: (event) => void;
  onContinueButtonClick: (event) => void;
}

interface ModalState {
  className: string;
  updating: boolean;
}

class CartGiftModal extends React.PureComponent<ModalProps, ModalState> {
  constructor(props) {
    super(props);
    this.state = {
      className: props.className ? props.className : '',
      updating: false,
    };
  }

  public onClassNameChange = (event) => {
    this.setState({
      className: event.target.value,
    });
  };

  public render() {
    const { title, eshopGifts, cart, dispatch } = this.props;
    const chosenGift = cart?.chosen_gift;
    const notSelectedGiftValue = '0';

    const handleGiftChange = async (event) => {
      const newCart = {
        ...cart,
        chosen_gift: {
          product_id:
            event.target.value && event.target.value !== notSelectedGiftValue
              ? event.target.value
              : undefined,
        },
      };

      this.setState({ updating: true });
      await dispatch(updateCart(newCart));
      this.setState({ updating: false });
    };

    const body = (
      <div>
        {__(
          'Nákupom nad 40 € ste získali nárok na darček v hodnote 40 € k nákupu zdarma. Zvoľte si svoj darček:',
        )}
        <StyledSelect
          name="gift_choose"
          onChange={handleGiftChange}
          items={[
            {
              product_name: __('Nemám záujem o darček'),
              product_id: '0',
              product_plu: '0',
            },
          ]
            .concat(eshopGifts)
            .map((eshopGift) => {
              return {
                name:
                  eshopGift.product_id !== '0'
                    ? eshopGift.product_name + ' ZŠ'
                    : eshopGift.product_name,
                value: eshopGift.product_id,
                selected: eshopGift.product_id === chosenGift?.product_id,
              };
            })}
        ></StyledSelect>
      </div>
    );

    const footer = (
      <CustomStyledFooter>
        <CloseButton
          className="primary lg"
          onClick={this.props.onContinueButtonClick}
          disabled={this.state.updating}
        >
          {__('Pokračovať')}
        </CloseButton>
      </CustomStyledFooter>
    );

    return (
      <StyledModal
        title={title}
        body={body}
        onCloseButtonClick={this.props.onCloseButtonClick}
        footer={footer}
      />
    );
  }
}

export default CartGiftModal;
