import * as React from 'react';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { __ } from 'react-i18n';
import {
  canShowCustomerProducts,
  getTotal,
  prop,
  scrollToTop,
} from '../../utilities';
import Filter from '../Category/Filter';
import ProductPreview from '../../components/_helpers/product/ProductPreview';
import Pagination from '../../components/Pagination/Pagination';
import { CategoryPlaceholder } from '../../components/Category/Category';
import { ProductColWrapper, ProductsRowWrapper } from '../Category/Category';
import {
  BreadcrumbsItems,
  PRODUCTS_PER_PAGE,
  URL_REQUEST_PRODUCT_PRICE,
} from '../../constants';
import { useState } from 'react';
import { useFilter } from '../../hooks/filter';
import API from '../../services/API';
import {
  DEFAULT_FILTER_TYPES,
  defaultSearchParameters,
  onPageChangeDefault,
  onSortChangeDefault,
  SORT_CATEGORY,
} from '../Category/constants';
import { MainBlock } from './Ucet';
import { loadCustomerProductsText } from './actions';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { rem } from 'polished';
import { Loader } from '../../components/_helpers/Loader/Loader';

const AddProductButton = styled(ButtonStyled)`
  margin-bottom: ${rem(24)};
`;

const UpperTextWrapper = styled.div`
  & > p {
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

interface ProductsCustomerProps {
  dispatch: any;
  user: any;
  location: any;
  currentPath: string;
  setTitle: (
    titleText: string | null,
    titleLink?: string | null,
    titleCount?: number | null,
  ) => void;
  setBreadcrumbsItems: (breadcrumbsItems: BreadcrumbsItems) => void;
  customerProductText: {
    body: string;
  };
}

const ProductsCustomer = (props: ProductsCustomerProps) => {
  const {
    dispatch,
    location,
    currentPath,
    setTitle,
    setBreadcrumbsItems,
    customerProductText,
    user,
  } = props;

  const [productsSearch, setProductsSearch] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const { filters, addFilter, setDefaults } = useFilter({
    ...DEFAULT_FILTER_TYPES,
    queryLocation: location.query,
    dispatch,
  });

  const limit = PRODUCTS_PER_PAGE;
  const sort = SORT_CATEGORY;
  const onPageChange = (page) => onPageChangeDefault(addFilter, page);
  const onSortChange = (clickedSort, clickedSortDir) =>
    onSortChangeDefault(addFilter, clickedSort, clickedSortDir);
  const paginationTotal = getTotal(productsSearch);
  const title = __('Produkty za dohodnuté ceny');

  React.useEffect(() => {
    if (!canShowCustomerProducts(user)) {
      dispatch(push('/my-account'));
    }
    setTitle(title);
    setBreadcrumbsItems([{ name: title, url: currentPath }]);
    setDefaults();
    if (!customerProductText) {
      dispatch(loadCustomerProductsText());
    }
  }, []);

  React.useEffect(() => {
    setTitle(title, null, prop(productsSearch, 'total', null));
    scrollToTop();
  }, [productsSearch]);

  React.useEffect(() => {
    setLoading(true);
    let parameters: any = defaultSearchParameters(filters, limit);
    delete parameters.availabilityId;
    parameters = {
      ...parameters,
      onlyWithCustomerPrice: true,
    };
    API.searchProducts(parameters).then((productsData) => {
      setProductsSearch(productsData.products.length > 0 ? productsData : {});
      setLoading(false);
    });
  }, [filters]);

  const handleAddProduct = () => dispatch(push(URL_REQUEST_PRODUCT_PRICE));

  return (
    <Loader loading={loading}>
      <MainBlock className={'no-top-padding'}>
        {customerProductText && (
          <UpperTextWrapper
            dangerouslySetInnerHTML={{ __html: customerProductText.body }}
          />
        )}
        <AddProductButton className="primary lg" onClick={handleAddProduct}>
          {__('Pridať produkt')}
        </AddProductButton>
        {productsSearch.total > limit ? (
          <Filter
            sort={sort}
            onSortChange={onSortChange}
            paginationCurrent={filters.page}
            paginationTotal={paginationTotal}
            onPageChanged={onPageChange}
            isSearch={false}
            isSortable={true}
          />
        ) : (
          <Filter
            sort={sort}
            onSortChange={onSortChange}
            isSearch={false}
            isSortable={true}
          />
        )}
        {productsSearch.products && productsSearch.products.length ? (
          <>
            <ProductsRowWrapper>
              {productsSearch.products.map((product) => {
                return (
                  <ProductColWrapper key={product.product_id}>
                    <ProductPreview product={product} />
                  </ProductColWrapper>
                );
              })}
            </ProductsRowWrapper>
            {productsSearch.total > limit && (
              <Pagination
                current={filters.page}
                total={paginationTotal}
                onPageChanged={onPageChange}
              />
            )}
          </>
        ) : loading ? (
          ''
        ) : (
          <CategoryPlaceholder text={__('Žiadné produkty sa nenašli')} />
        )}
      </MainBlock>
    </Loader>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    customerProductText: state.myAccount.customerProductText,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ProductsCustomer' })(ProductsCustomer),
);
