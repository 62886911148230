import * as React from 'react';
import Finished from './Finished';

class FinishedRetail extends React.Component {
  public render() {
    return <Finished textId={'REG_FINISHED_RETAIL'} />;
  }
}

export default FinishedRetail;
