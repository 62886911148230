import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import Alert from '../../components/Alert/Alert';
import { Button } from '../../components/_helpers/form/Button';
import Icon from '../../components/_helpers/Icon/Icon';
import {
  formatPrice,
  formatStringDate,
  getBaseUrl,
  isChildCompany,
  isCompany,
  isRetail,
  isSchoolOrTeacher,
  isTeacher,
  prop,
} from '../../utilities';
import { MainBlock } from './Ucet';
import {
  loadDashboard,
  loadFavoriteProductsForWidget,
  loadHowToEarnPointsText,
  loadMostFrequentPurchased,
  loadProductsCustomer,
  loadSkvelkoAdvantageText,
  loadTextDeliverySchool,
} from './actions';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import API, { ThenArg } from '../../services/API';
import { push } from 'react-router-redux';
import { ChematCustomerTypeSalePrice } from '../../library/ChematCustomerTypeSalePrice';
import { URL_CREDIT } from '../../constants';
import { ContactPerson } from '../../components/_helpers/Contact/ContactPersons';
import { theme } from '../../theme/theme';
import Tabs from '../../components/Tabs/Tabs';
import FavoriteProductsWidget from '../../components/_layout/Account/FavoriteProductsWidget';
import MostFrequentPurchased from '../../components/_layout/Account/MostFrequentPurchased';
import ProductsCustomer from '../../components/_layout/Account/ProductsCustomer';

const StyledRow = styled.div`
  margin-right: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-left: ${({ theme }) => rem(-theme.grid.padding / 2)};
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media(1024)} {
    display: flex;
  }
`;

const LeftCol = styled.div`
  padding-left: ${({ theme }) => rem(theme.grid.padding / 2)};
  padding-right: 10px;
  ${({ theme }) => theme.media(1024)} {
    flex: 1 1 ${({ theme }) => theme.grid.col.col6};
  }
`;

const RightCol = styled.div`
  padding-right: ${({ theme }) => rem(theme.grid.padding / 2)};
  padding-left: 10px;
  margin-top: ${rem(20)};
  ${({ theme }) => theme.media(1024)} {
    flex: 1 1 ${({ theme }) => theme.grid.col.col6};
    margin-top: 0;
  }
`;

export const MyAccountBtn = styled(Button)`
  padding: ${rem(10)};
`;

export const MyAccountTextBtn = styled(Button)`
  padding: ${rem(7)};
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  margin-left: ${rem(5)};
`;

export const MyAccountText = styled.div`
  color: #337bcc;
  display: inline-block;
  margin-left: ${rem(10)};
  vertical-align: middle;
`;

export const MyAccountBtnContent = styled.div``;

export const MyAccountBtnIcon = styled(Icon)`
  ${({ theme }) => theme.size(20)};
  ${({ theme }) => theme.transitions('fill, transform', '.5s')};
  ${MyAccountBtn}:hover &,
  ${MyAccountBtn}:focus & {
    fill: ${({ theme }) => theme.color.primary};
  }
`;

export const ButtonCogIcon = styled(MyAccountBtnIcon)`
  ${MyAccountBtn}:hover &,
  ${MyAccountBtn}:focus & {
    transform: rotate(90deg);
  }
`;

const Text = styled.p`
  margin: 0;
  & + & {
    margin-top: ${rem(10)};
  }
  &.blue {
    color: ${({ theme }) => theme.color.blue};
  }
`;

const Bold = styled(Text)`
  font-weight: 700;
`;

const BoldColored = styled(Bold)`
  color: ${({ theme }) => theme.color.blue};
`;

const Box = styled.div`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  font-size: ${rem(14)};
  position: relative;
  & + & {
    margin-top: ${rem(10)};
  }
  &.success {
    border-color: ${({ theme }) => theme.color.success};
    color: ${({ theme }) => theme.color.success};
  }
  &.error {
    border-color: ${({ theme }) => theme.color.error};
    color: ${({ theme }) => theme.color.error};
  }
`;

const BoxHeader = styled.div`
  position: relative;
  padding: ${rem(8)} ${rem(60)} ${rem(8)} ${rem(25)};
  background: ${({ theme }) => theme.color.gray97};
  &:not(:first-child) {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
  &:not(:last-child) {
    border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
`;

const BoxHeaderTitle = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-weight: 700;
  margin-right: ${rem(30)};
`;

const BoxHeaderLink = styled.a`
  position: absolute;
  top: ${rem(8)};
  right: ${rem(25)};
`;

const BoxDivider = styled.hr`
  border-color: ${({ theme }) => theme.color.gray97};
  margin: 0;
  padding: 0;
  border: none;
  color: ${({ theme }) => theme.color.gray93};
  height: 2px;
  background-color: ${({ theme }) => theme.color.gray93};
`;

const BoxContent = styled.div`
  padding: ${rem(10)} ${rem(25)} ${rem(10)} ${rem(25)};
`;

const BoxNumberDataWrapper = styled.div`
  display: flex;
  &.error {
    color: ${({ theme }) => theme.color.error};
  }
`;

const BoxLeftText = styled.div`
  flex-grow: 1;
  margin-right: 25px;
`;

const BoxRightText = styled.div`
  &.blue {
    color: ${({ theme }) => theme.color.blue};
  }
  &.red {
    color: ${({ theme }) => theme.color.error};
  }
  &.gray {
    color: ${({ theme }) => theme.color.gray64};
  }
  &.green {
    color: ${({ theme }) => theme.color.green};
  }
`;

const BoxText = styled.p`
  margin: 0;
  &.blue {
    color: ${({ theme }) => theme.color.blue};
  }
  &.red {
    color: ${({ theme }) => theme.color.error};
  }
  &.gray {
    color: ${({ theme }) => theme.color.gray64};
  }
  &.bold {
    font-weight: 700;
  }
  &.separate {
    margin-top: ${rem(10)};
    margin-bottom: ${rem(10)};
  }
`;

const AdditionalText = styled.div`
  display: inline;
  &.green {
    color: ${({ theme }) => theme.color.green};
  }
`;

const EditableText = styled.div`
  ol {
    padding-left: ${rem(16)};
  }
`;

const BoxLink = styled.a`
  display: block;
  cursor: pointer;
`;

const EnumerationTextPoints = styled.span`
  font-weight: bold;
  white-space: nowrap;
`;

const EnumerationTextPrice = styled.span`
  white-space: nowrap;
`;

interface MojUcetProps {
  dashboardData: ThenArg<typeof API.getDashboardData>;
  skvelkoAdvantageText: { body: string; name: string };
  deliverySchoolText: any;
  howToEarnPointsText: any;
  setTitle: (title: string, link?: string | null) => void;
  dispatch: (a: any) => void;
  user: any;
  cart: ThenArg<typeof API.getCart>;
  currentPath: string;
  mostFrequentPurchased: ThenArg<typeof API.searchProducts>;
  mostFrequentPurchasedLoader: boolean;
  favoriteProductsWidget: ThenArg<typeof API.searchProducts>;
  favoriteProductsWidgetLoader: boolean;
  productsCustomer: ThenArg<typeof API.searchProducts>;
  productsCustomerLoader: boolean;
  dealer: ThenArg<typeof API.loadDealerByUserId>;
}

interface MojUcetStates {
  activeTabIndex: string | null;
  showMoreDeliverySchool: boolean;
  showHowToEarnPoints: boolean;
  dealer?: ThenArg<typeof API.loadDealerByUserId>;
}

enum AccountTabsIds {
  FAVORITE_PRODUCTS = 'favorite_products',
  MOST_FREQUENTLY_PURCHASED_PRODUCTS = 'most_purchased_products',
  PRODUCTS_WITH_MY_PRICE = 'products_with_my_price',
}

class MojUcet extends React.Component<MojUcetProps, MojUcetStates> {
  public static async getInitialProps(props) {
    const promises: any[] = [];

    if (!props.dashboardData) {
      promises.push(props.dispatch(loadDashboard()));
    }

    if (!props.skvelkoAdvantageText) {
      promises.push(props.dispatch(loadSkvelkoAdvantageText()));
    }

    if (!props.deliverySchoolText) {
      promises.push(props.dispatch(loadTextDeliverySchool()));
    }

    if (!props.howToEarnPointsText) {
      promises.push(props.dispatch(loadHowToEarnPointsText()));
    }

    if (!props.mostFrequentPurchased) {
      promises.push(props.dispatch(loadMostFrequentPurchased()));
    }

    if (!props.favoriteProductsWidget) {
      promises.push(props.dispatch(loadFavoriteProductsForWidget()));
    }

    if (!props.productsCustomer) {
      promises.push(props.dispatch(loadProductsCustomer()));
    }

    await Promise.all(promises);
  }

  async componentDidMount() {
    if (this.props.user.id) {
      const { dealer } = await API.loadDealerByUserId(this.props.user.id);

      if (dealer) {
        this.setState({ dealer: dealer });
      }
    }
  }

  constructor(props) {
    super(props);
    const title = __('Nástenka');
    props.setTitle(title);
    props.setBreadcrumbsItems([{ name: title, url: props.currentPath }]);

    this.state = {
      activeTabIndex: null,
      showMoreDeliverySchool: false,
      showHowToEarnPoints: false,
    };
  }

  public onBlockButtonClick = (url) => {
    this.props.dispatch(push(getBaseUrl(url)));
  };

  public toggleShowMoreDeliverySchool = () => {
    this.setState({
      showMoreDeliverySchool: !this.state.showMoreDeliverySchool,
    });
  };

  public toggleHowToEarnMorePoints = () => {
    this.setState({
      showHowToEarnPoints: !this.state.showHowToEarnPoints,
    });
  };

  public toggleTab = (index) => {
    this.setState({
      activeTabIndex: index,
    });
  };

  public render() {
    let person;

    if (this.state.dealer) {
      person = {
        name: prop(this, 'state.dealer.name'),
        phone: prop(this, 'state.dealer.mobile_number'),
        phone2: prop(this, 'state.dealer.phone_number'),
        email: prop(this, 'state.dealer.email'),
        /*
        picture: `/images/contact/${
          prop(this, 'state.dealer.photo_path')
            ? prop(this, 'state.dealer.photo_path')
            : 'vrskova.png'
        }`,
        */
        picture: `${
          process.env.REACT_APP_API_BASE_URL
        }/image?query=dealers/${prop(this, 'state.dealer.photo_path')}`,
      };
    } else {
      person = null;
    }

    const { dashboardData } = this.props;

    const user = prop(this, 'props.user');
    let typeSalePrice = prop(this, 'props.user.type_sale_price');

    const individualnaZlava = prop(this, 'props.user.individualna_zlava');
    const customerCardDiscount = prop(
      this,
      'props.user.customer_card_discount',
    );
    const school = isSchoolOrTeacher(user);
    const teacher = isTeacher(user) || isChildCompany(user);
    const company = isCompany(user);
    const retail = isRetail(user);

    if (dashboardData && dashboardData.organization) {
      typeSalePrice = dashboardData.organization.type_sale_price;
    }

    const salePercentage = individualnaZlava || customerCardDiscount;

    let typeSalePriceText;
    switch (typeSalePrice) {
      case ChematCustomerTypeSalePrice.PC1: {
        typeSalePriceText = __('Nakupujete za veľkoobchodné ceny');
        break;
      }
      case ChematCustomerTypeSalePrice.PC2: {
        typeSalePriceText = __(
          'Nakupujete za veľkoobchodné ceny pre ďaľší predaj',
        );
        break;
      }
      default: {
        typeSalePriceText = __('Nakupujete za maloobchodné ceny');
      }
    }

    const items: any = [];

    if (prop(this.props.favoriteProductsWidget, 'total', 0)) {
      items.push({
        name: 'Obľúbené produkty',
        id: AccountTabsIds.FAVORITE_PRODUCTS,
      });

      if (!this.state.activeTabIndex) {
        this.toggleTab(AccountTabsIds.FAVORITE_PRODUCTS);
      }
    }

    if (prop(this.props.mostFrequentPurchased, 'total', 0)) {
      items.push({
        name: 'Najčastejšie nakupované produkty',
        id: AccountTabsIds.MOST_FREQUENTLY_PURCHASED_PRODUCTS,
      });

      if (!this.state.activeTabIndex) {
        this.toggleTab(AccountTabsIds.MOST_FREQUENTLY_PURCHASED_PRODUCTS);
      }
    }

    if (prop(this.props.productsCustomer, 'total', 0)) {
      items.push({
        name: 'Produkty za dohodnuté ceny',
        id: AccountTabsIds.PRODUCTS_WITH_MY_PRICE,
      });

      if (!this.state.activeTabIndex) {
        this.toggleTab(AccountTabsIds.PRODUCTS_WITH_MY_PRICE);
      }
    }

    const renderLoggedInDataText = () => {
      return (
        <>
          {dashboardData && (
            <React.Fragment>
              {(school || company) && !teacher ? (
                <BoxText className={'gray separate'}>Kontaktná osoba</BoxText>
              ) : (
                ''
              )}
              {teacher ? (
                <BoxText className={'gray separate'}>Používateľ účtu</BoxText>
              ) : (
                ''
              )}

              <BoldColored>
                {teacher
                  ? `${user.name} ${user.surname}`
                  : dashboardData.contactPerson.name}
              </BoldColored>
              <Text>
                {teacher ? user.phone : dashboardData.contactPerson.phone}
                <br />
                {teacher ? user.email : dashboardData.contactPerson.email}
              </Text>
            </React.Fragment>
          )}
        </>
      );
    };

    return (
      <MainBlock className={'no-top-padding'}>
        <StyledRow>
          <LeftCol>
            {school || company || teacher ? (
              <Box>
                <BoxHeader>
                  <BoxHeaderTitle>{__('Organizácia')}</BoxHeaderTitle>
                  <BoxHeaderLink href="/my-account/settings">
                    {__('Upraviť')}
                  </BoxHeaderLink>
                </BoxHeader>
                <BoxContent>
                  {dashboardData && (
                    <React.Fragment>
                      <BoxText className="blue bold">
                        {dashboardData.organization.name}
                      </BoxText>
                      <BoxText>
                        {dashboardData.organization.street}{' '}
                        {dashboardData.organization.streetNumber}
                        {(dashboardData.organization.town ||
                          dashboardData.organization.zip) &&
                          dashboardData.organization.street &&
                          ','}
                        {dashboardData.organization.town}{' '}
                        {dashboardData.organization.zip}
                      </BoxText>
                      <Text>{renderLoggedInDataText()}</Text>
                    </React.Fragment>
                  )}
                </BoxContent>
                {school && !teacher ? (
                  <>
                    <BoxHeader>
                      <BoxHeaderTitle>{__('Vernostné body')}</BoxHeaderTitle>
                      <BoxHeaderLink href={URL_CREDIT}>
                        {__('Zobraziť')}
                      </BoxHeaderLink>
                    </BoxHeader>

                    <BoxContent>
                      <BoxNumberDataWrapper>
                        <BoxLeftText>
                          {__('Stav vernostných bodov')}

                          {this.state.showHowToEarnPoints ? (
                            ''
                          ) : (
                            <BoxLink
                              onClick={this.toggleHowToEarnMorePoints.bind(
                                null,
                              )}
                            >
                              {__('Ako získať a použiť vernostné body?')}
                            </BoxLink>
                          )}
                        </BoxLeftText>
                        <BoxRightText>
                          {dashboardData && dashboardData.points ? (
                            <>
                              <EnumerationTextPoints>
                                {formatPrice(
                                  dashboardData.points,
                                  '',
                                  2,
                                  false,
                                  true,
                                )}
                              </EnumerationTextPoints>
                              <EnumerationTextPrice>
                                {` (zľava ${formatPrice(
                                  dashboardData.points,
                                  '',
                                  2,
                                  false,
                                  true,
                                )} €)`}
                              </EnumerationTextPrice>
                            </>
                          ) : (
                            '-'
                          )}
                        </BoxRightText>
                      </BoxNumberDataWrapper>
                      {this.state.showHowToEarnPoints ? (
                        <EditableText
                          dangerouslySetInnerHTML={{
                            __html: this.props.howToEarnPointsText?.body,
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </BoxContent>
                    <BoxHeader>
                      <BoxHeaderTitle>Používatelia účtu</BoxHeaderTitle>
                      <BoxHeaderLink href="/my-account/renumeration">
                        {__('Zobraziť')}
                      </BoxHeaderLink>
                    </BoxHeader>
                    <BoxContent>
                      <BoxNumberDataWrapper>
                        <BoxLeftText>
                          {__('Ďalší používatelia účtu')}
                        </BoxLeftText>
                        <BoxRightText>
                          {dashboardData ? dashboardData.awardedUsers : '-'}
                        </BoxRightText>
                      </BoxNumberDataWrapper>
                    </BoxContent>
                  </>
                ) : (
                  ''
                )}
              </Box>
            ) : (
              <>
                <Box>
                  <BoxHeader>
                    <BoxHeaderTitle>{__('Účet')}</BoxHeaderTitle>
                    <BoxHeaderLink href="/my-account/settings">
                      {__('Zobraziť')}
                    </BoxHeaderLink>
                  </BoxHeader>
                  <BoxContent>
                    {dashboardData &&
                      dashboardData.organization &&
                      dashboardData.organization.name && (
                        <>{dashboardData.organization.name}</>
                      )}

                    {dashboardData &&
                      dashboardData.contactPerson &&
                      dashboardData.contactPerson.email && (
                        <>{dashboardData.contactPerson.email}</>
                      )}
                  </BoxContent>
                </Box>
              </>
            )}
          </LeftCol>
          <RightCol>
            {teacher ? (
              <Box>
                <BoxHeader>
                  <BoxHeaderTitle>{__('Cenová hladina')}</BoxHeaderTitle>
                </BoxHeader>
                <BoxContent>
                  <Text className={'blue'}>
                    {typeSalePriceText}
                    {salePercentage
                      ? ` + ${__('extra zľava')} ${salePercentage}%`
                      : ''}
                  </Text>
                </BoxContent>
                {person && (
                  <>
                    <BoxHeader>
                      <BoxHeaderTitle>
                        {__('Váš obchodný zástupca')}
                      </BoxHeaderTitle>
                    </BoxHeader>
                    <ContactPerson item={person} fromAccountDetail={true} />
                  </>
                )}
              </Box>
            ) : null}
            {school && !teacher ? (
              <Box>
                <BoxHeader>
                  <BoxHeaderTitle>{__('Cenová hladina')}</BoxHeaderTitle>
                </BoxHeader>
                <BoxContent>
                  <Text className={'blue'}>
                    {typeSalePriceText}
                    {salePercentage
                      ? ` + ${__('extra zľava')} ${salePercentage}%`
                      : ''}
                  </Text>
                </BoxContent>
                {person && (
                  <>
                    <BoxHeader>
                      <BoxHeaderTitle>
                        {__('Váš obchodný zástupca')}
                      </BoxHeaderTitle>
                    </BoxHeader>
                    <ContactPerson item={person} fromAccountDetail={true} />
                  </>
                )}
                <BoxHeader>
                  <BoxHeaderTitle>
                    {__('Zoznamy pomôcok skvelko')}
                  </BoxHeaderTitle>
                  <BoxHeaderLink href="/my-account/sets">
                    {__('Zobraziť')}
                  </BoxHeaderLink>
                </BoxHeader>

                <BoxContent>
                  <BoxNumberDataWrapper>
                    <BoxLeftText>{__('Dostupné na objednávanie')}</BoxLeftText>
                    <BoxRightText> {this.getSkvelkoSetCount(1)} </BoxRightText>
                  </BoxNumberDataWrapper>
                  <BoxNumberDataWrapper
                    className={this.getSkvelkoSetCount(2) > 0 ? 'error' : ''}
                  >
                    <BoxLeftText>
                      {__('Nedostupné na objednávanie')}
                    </BoxLeftText>
                    <BoxRightText> {this.getSkvelkoSetCount(2)} </BoxRightText>
                  </BoxNumberDataWrapper>
                </BoxContent>

                <BoxDivider />
                <BoxContent>
                  <BoxNumberDataWrapper>
                    <BoxLeftText>
                      {__(
                        'Spoločné doručenie objednávok žiakov do školy zdarma',
                      )}
                      {dashboardData &&
                      dashboardData.deliveryToSchoool.active ? (
                        <AdditionalText className="green">
                          {' '}
                          {__('povolené pre objednávky do')}{' '}
                          {formatStringDate(
                            dashboardData.deliveryToSchoool.until,
                          )}{' '}
                        </AdditionalText>
                      ) : (
                        ''
                      )}

                      {this.state.showMoreDeliverySchool ? (
                        ''
                      ) : (
                        <BoxLink
                          onClick={this.toggleShowMoreDeliverySchool.bind(null)}
                        >
                          {__('Ako nastaviť spoločné doručenie?')}
                        </BoxLink>
                      )}
                    </BoxLeftText>
                    {dashboardData && dashboardData.deliveryToSchoool.active ? (
                      <>
                        <BoxRightText className="green">
                          {' '}
                          {__('Aktívne')}
                        </BoxRightText>
                      </>
                    ) : (
                      <>
                        <BoxRightText className="red">
                          {' '}
                          {__('Neaktívne')}
                        </BoxRightText>
                      </>
                    )}
                  </BoxNumberDataWrapper>
                  {this.state.showMoreDeliverySchool ? (
                    <EditableText
                      dangerouslySetInnerHTML={{
                        __html: this.props.deliverySchoolText?.body,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </BoxContent>
              </Box>
            ) : (
              ''
            )}

            {company || retail ? (
              <Box>
                <BoxHeader>
                  <BoxHeaderTitle>{__('Cenová hladina')}</BoxHeaderTitle>
                </BoxHeader>
                <BoxContent>
                  <Text className={'blue'}>
                    {typeSalePriceText}
                    {salePercentage
                      ? ` + ${__('extra zľava')} ${salePercentage}%`
                      : ''}
                  </Text>
                </BoxContent>
                {person && (
                  <>
                    <BoxHeader>
                      <BoxHeaderTitle>
                        {__('Váš obchodný zástupca')}
                      </BoxHeaderTitle>
                    </BoxHeader>
                    <ContactPerson item={person} fromAccountDetail={true} />
                  </>
                )}
              </Box>
            ) : (
              ''
            )}
          </RightCol>
        </StyledRow>

        {items.length ? (
          <>
            <Tabs
              items={items}
              activeId={this.state.activeTabIndex}
              onTabChange={this.toggleTab}
              selectedBackgroundColor={theme.color.secondary}
              selectedTextColor={theme.color.black}
            />
            {this.state.activeTabIndex === AccountTabsIds.FAVORITE_PRODUCTS ? (
              <FavoriteProductsWidget
                dispatch={this.props.dispatch}
                user={this.props.user}
                cart={this.props.cart}
                favoriteProductsWidget={this.props.favoriteProductsWidget}
                favoriteProductsWidgetLoader={
                  this.props.favoriteProductsWidgetLoader
                }
              />
            ) : null}

            {this.state.activeTabIndex ===
            AccountTabsIds.MOST_FREQUENTLY_PURCHASED_PRODUCTS ? (
              <MostFrequentPurchased
                dispatch={this.props.dispatch}
                user={this.props.user}
                cart={this.props.cart}
                mostFrequentPurchased={this.props.mostFrequentPurchased}
                mostFrequentPurchasedLoader={
                  this.props.mostFrequentPurchasedLoader
                }
              />
            ) : null}

            {this.state.activeTabIndex ===
            AccountTabsIds.PRODUCTS_WITH_MY_PRICE ? (
              <ProductsCustomer
                dispatch={this.props.dispatch}
                user={this.props.user}
                cart={this.props.cart}
                productsCustomer={this.props.productsCustomer}
                productsCustomerLoader={this.props.productsCustomerLoader}
              />
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}

        {school && !teacher && this.props.skvelkoAdvantageText && (
          <Alert heading={this.props.skvelkoAdvantageText.name}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.skvelkoAdvantageText.body,
              }}
            />
          </Alert>
        )}
      </MainBlock>
    );
  }

  public getSkvelkoSetCount(availabilityId) {
    const { dashboardData } = this.props;
    if (dashboardData) {
      const availability = dashboardData.skvelkoSetAvailabilities.find(
        (availabilityf) =>
          availabilityf.skvelko_set_availability_id === availabilityId,
      );
      if (availability) {
        return availability.count || 0;
      }
    }

    return 0;
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardData: state.myAccount.dashboardData,
    skvelkoAdvantageText: state.myAccount.skvelkoAdvantageText,
    deliverySchoolText: state.myAccount.deliverySchoolText,
    howToEarnPointsText: state.myAccount.howToEarnPointsText,
    user: state.auth.user,
    cart: state.cart.cart,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    mostFrequentPurchased: state.myAccount.mostFrequentPurchased,
    mostFrequentPurchasedLoader: state.myAccount.mostFrequentPurchasedLoader,
    favoriteProductsWidget: state.myAccount.favoriteProductsWidget,
    favoriteProductsWidgetLoader: state.myAccount.favoriteProductsWidgetLoader,
    productsCustomer: state.myAccount.productsCustomer,
    productsCustomerLoader: state.myAccount.productsCustomerLoader,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MojUcet' })(MojUcet),
);
