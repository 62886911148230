import * as actionTypes from './constants';

export const setShopNetworkMenu = shopNetworkMenu => ({
  type: actionTypes.SET_SHOP_NETWORK_MENU,
  payload: {
    shopNetworkMenu,
  },
});

export const loadShopNetworkMenu = () => {
  return async (dispatch, getState, API) => {
    try {
      const sitemapSubtree = await API.loadSitemapSubtree('SHOP_NETWORK');
      if (sitemapSubtree) {
        dispatch(setShopNetworkMenu(sitemapSubtree.sitemap_tree));
      } else {
        dispatch(setShopNetworkMenu(null));
      }
    } catch (e) {
      dispatch(setShopNetworkMenu(null));
    }
  };
};

export const setShopDetail = shopDetail => ({
  type: actionTypes.SET_SHOP_DETAIL,
  payload: {
    shopDetail,
  },
});

export const loadShopDetail = id => {
  return async (dispatch, getState, API) => {
    try {
      const shopDetail = await API.loadShopDetail(id);

      dispatch(setShopDetail(shopDetail));
    } catch (e) {
      dispatch(setShopDetail(null));
    }
  };
};
