import * as React from 'react';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { rem, rgba } from 'polished';
import { connect } from 'react-redux';
import { __ } from 'react-i18n';
import {
  ClearfixStyles,
  VisuallyHiddenStyles,
} from '../../theme/libraries/h5b-helpers';
import { capitalizeFirstLetter, getUrl, prop } from '../../utilities';
import { Media, MediaContextProvider } from '../../theme/media';
import { Col } from '../../theme/libraries/grid';

export const CategoryListWrapper = styled.ul`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${rem(2)};
  font-weight: 700;
  margin: 0;
  padding: 0;
  ${({ theme }) => theme.media('sm')} {
    box-shadow: ${({ theme }) => theme.boxShadow.small};
  }
  margin-bottom: ${rem(10)};
  ${ClearfixStyles};
`;

export const Item = styled.li`
  list-style: none;
  &.hidden {
    display: none;
  }
  &.active {
    font-weight: 700;
    background: ${({ theme }) => theme.color.secondaryLight};
  }
  ${ClearfixStyles};
`;

const ItemRow = styled.div`
  width: 100%;
  ${ClearfixStyles};
  ul ul ul & {
    padding-left: ${rem(8)};
  }
  ul ul ul ul & {
    padding-left: ${rem(16)};
  }
  ul ul ul ul ul & {
    padding-left: ${rem(24)};
  }
`;

const ItemCheckboxWrap = styled.div`
  float: left;
  width: ${rem(36)};
  height: ${rem(36)};
  padding: ${rem(6)};
`;

const ItemCheckboxLabel = styled.label`
  cursor: pointer;
`;

const ItemCheckboxBox = styled.span`
  display: inline-block;
  background: ${({ theme }) => rgba(theme.color.white, 0.24)};
  border: ${rem(2)} solid ${({ theme }) => rgba(theme.color.black, 0.07)};
  border-radius: ${rem(1)};
  ${({ theme }) => theme.size(24)};
  padding: ${rem(10)};
  position: relative;
  pointer-events: none;
  &:before,
  &:after {
    display: none;
    content: '';
    background: ${({ theme }) => theme.color.gray80};
    ${({ theme }) => theme.size(14, 2)};
    position: absolute;
    top: ${rem(9)};
    left: ${rem(3)};
  }
  &:after {
    ${({ theme }) => theme.transition('transform')};
    transform: rotate(90deg);
  }
  ${({ hasChildren }) => {
    if (hasChildren) {
      return css`
        pointer-events: auto;
        &:before,
        &:after {
          display: block;
        }
      `;
    }
  }}
`;

const SubList = styled.ul`
  clear: both;
  font-weight: 400;
  width: 100%;
  margin: 0;
  padding: 0;
  & & {
    // display: none;
  }
  &:first-child {
    font-weight: 700;
  }
  li.active {
    background: ${({ theme }) => theme.color.secondaryLight};
    font-weight: 700;
    span {
      background: rgba(255, 255, 255, 1);
      border: ${rem(2)} solid rgba(243, 228, 137, 1);
      &:before,
      &:after {
        background: rgba(209, 193, 92, 1);
      }
    }
    &.alone,
    li.active {
      background: ${({ theme }) => theme.color.secondary};
      span {
        background: rgba(255, 247, 195, 1);
        border: ${rem(2)} solid rgba(225, 194, 0, 1);
        &:before,
        &:after {
          background: rgba(175, 152, 2, 1);
        }
      }
    }
  }
  ${({ opened }) => {
    if (opened) {
      return css`
        display: block;
      `;
    }
  }}
`;

const ItemCheckbox = styled.input`
  ${VisuallyHiddenStyles};
  &:checked + ${ItemCheckboxBox} {
    &:after {
      transform: rotate(0deg);
    }
  }
  &:checked ~ ${SubList} {
    display: block;
  }
`;

const ItemLinkStyles = css`
  display: block;
  float: left;
  color: inherit;
  font-size: ${rem(15)};
  text-decoration: none;
  width: 100%;
  padding: ${rem(7)} ${rem(8)} ${rem(7)} ${rem(40)};
  margin-left: ${rem(-36)};
  ${({ theme }) => theme.transition('background-color')};
`;

export const ItemLink = styled(Link)`
  ${ItemLinkStyles};
  &:hover {
    text-decoration: none;
  }
`;

const SubItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`;

const Sidebar = styled(Col)`
  font-size: ${rem(14)};
  ${({ theme }) => theme.media('md')} {
    width: ${({ theme }) => theme.grid.col.col3};
  }
`;

const SidebarWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

interface MenuListProps {
  dispatch: any;
  sitemapTree: any[];
  breadcrumbItems: any[];
  className?: string;
  propsPath?: string;
  currentPath?: string;
  reloadState?: boolean;
  currentCategoryId?: number;
  currentCategoryGroupId?: number;
  currentCategoryGroup?: any;
  user?: any;
}

interface MenuListStates {
  checkedItems: {};
}

class MenuList extends React.PureComponent<MenuListProps, MenuListStates> {
  constructor(props) {
    super(props);
    this.state = { checkedItems: this.getCheckedItems() };
    this.handleChange = this.handleChange.bind(this);
  }

  public getCheckedItems() {
    let checkedItems = {};
    if (!this.props.breadcrumbItems) {
      return checkedItems;
    }

    for (const breadcrumbItem of this.props.breadcrumbItems) {
      if (breadcrumbItem.id) {
        checkedItems[breadcrumbItem.id] = true;
      }
    }

    /*
    console.log("BREADCRUMB ITEMS");
    console.log(this.props.breadcrumbItems);

    let checkedItems = {};
    if (!this.props.sitemapTree) {
      return checkedItems;
    }

    const trimmedPathname = this.props.currentPath?this.props.currentPath.replace(/^\/+|\/+$/g, ''):'';

    function checkChecked(item, parentIds){
        const trimmerUrl = item.url.replace(/^\/+|\/+$/g, '');

        if(trimmedPathname === trimmerUrl){
            checkedItems[item.id] = true;
            for(const parentId of parentIds){
                checkedItems[parentId] = true;
            }
        }

        if(item.children && item.children.length){
            for(const childItem of item.children){
                checkChecked(childItem,[...parentIds,childItem.id]);
            }
        }
    }

    for (const item of this.props.sitemapTree) {
        checkChecked(item,[item.id]);
    }
    */

    return checkedItems;
  }

  public getCurrentCategories = (category, checkedItems) => {
    let newCheckedItems = { ...checkedItems };
    let isNewCheckedItem = false;

    if (!category) {
      return [newCheckedItems, isNewCheckedItem];
    }

    if (this.props.currentCategoryId === category.id) {
      newCheckedItems[category.id] = true;
      isNewCheckedItem = true;
    }

    if (category.children?.length && !isNewCheckedItem) {
      for (const children of category.children) {
        if (!isNewCheckedItem) {
          [newCheckedItems, isNewCheckedItem] = this.getCurrentCategories(
            children,
            newCheckedItems,
          );
          if (isNewCheckedItem) {
            newCheckedItems[category.id] = true;
          }
        }
      }
    }

    return [newCheckedItems, isNewCheckedItem];
  };

  public componentDidUpdate(prevProps) {
    if (prevProps.breadcrumbItems && this.props.breadcrumbItems) {
      if (
        prevProps.breadcrumbItems.length !== this.props.breadcrumbItems.length
      ) {
        this.setState({ checkedItems: this.getCheckedItems() });
      } else {
        let counter = 0;
        for (const item of prevProps.breadcrumbItems) {
          if (item.id !== this.props.breadcrumbItems[counter].id) {
            this.setState({ checkedItems: this.getCheckedItems() });
            break;
          }
          counter++;
        }
      }
    }
  }

  public handleChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => {
      const newCheckedItems = { ...prevState.checkedItems };
      newCheckedItems[item] = isChecked;
      return {
        ...prevState,
        checkedItems: newCheckedItems,
      };
    });
  };

  public onClickLink = (id) => {};

  public render() {
    const { className, sitemapTree } = this.props;

    const getSitemap = (item) => {
      return (
        <Item
          key={item.id}
          className={this.state.checkedItems[item.id] ? 'active' : ''}
        >
          <SubList>{getSubSitemap(item)}</SubList>
        </Item>
      );
    };

    const getSubSitemap = (item) => {
      const hasChildren = item.children && item.children.length;
      let clazz = this.state.checkedItems[item.id] ? 'active' : '';
      if (!hasChildren) {
        clazz += ' alone';
      }

      return (
        <SubItem key={item.id} className={clazz}>
          <ItemRow>
            <ItemCheckboxWrap>
              <ItemCheckboxLabel>
                {hasChildren ? (
                  <ItemCheckbox
                    value="1"
                    type="checkbox"
                    name={item.id}
                    checked={this.state.checkedItems[item.id] === true}
                    onChange={this.handleChange}
                    aria-label={
                      __('Otvoriť stránku') +
                      ' ' +
                      capitalizeFirstLetter(item.text)
                    }
                  />
                ) : (
                  ''
                )}
                <ItemCheckboxBox hasChildren={hasChildren} />
              </ItemCheckboxLabel>
            </ItemCheckboxWrap>
            <ItemLink
              to={getUrl(item.url)}
              onClick={this.onClickLink.bind(null, item.id)}
            >
              {capitalizeFirstLetter(item.text)}
            </ItemLink>
          </ItemRow>
          {this.state.checkedItems[item.id] && hasChildren ? (
            <SubList>
              {item.children.map((children) => getSubSitemap(children))}
            </SubList>
          ) : (
            ''
          )}
        </SubItem>
      );
    };

    return (
      <MediaContextProvider>
        <Media greaterThanOrEqual="lg">
          <Sidebar>
            <SidebarWrapper>
              <CategoryListWrapper className={className}>
                {sitemapTree ? sitemapTree.map((item) => getSitemap(item)) : ''}
              </CategoryListWrapper>
            </SidebarWrapper>
          </Sidebar>
        </Media>
      </MediaContextProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    currentCategoryId: prop(state, 'general.currentCategoryId'),
    currentCategoryGroupId: prop(state, 'general.currentCategoryGroupId'),
    currentCategoryGroup: prop(state, 'general.currentCategoryGroup'),
  };
};

export default connect(mapStateToProps)(MenuList);
