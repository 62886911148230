import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import { cookieAgree, cookieDisagree } from '../../utilities';
import * as cookie from 'react-cookies';

const Wrapper = styled.div``;

const CustomCheckboxStyled = styled(CheckboxStyled)``;

const CookiesChooser = () => {
  const onAgreeClick = () => {
    cookieAgree();
    if (window && window.location) {
      window.location.href = window.location.href;
    }
  };

  const onDisagreeClick = () => {
    cookieDisagree();
    if (window && window.location) {
      window.location.href = window.location.href;
    }
  };

  return (
    <Wrapper>
      <CustomCheckboxStyled
        name="cookie_setup"
        id="cookie_setup"
        value={
          cookie.load('COOKIES_REJECT') === 0 ||
          cookie.load('COOKIES_REJECT') === '0'
            ? '1'
            : '0'
        }
        checked={
          cookie.load('COOKIES_REJECT') === 0 ||
          cookie.load('COOKIES_REJECT') === '0'
            ? true
            : false
        }
        onChange={() => {
          cookie.load('COOKIES_REJECT') === 0 ||
          cookie.load('COOKIES_REJECT') === '0'
            ? onDisagreeClick()
            : onAgreeClick();
        }}
      >
        {__(
          'Súhlasím so spracovaním analytických a reklamných cookies a cookies tretích strán.',
        )}
      </CustomCheckboxStyled>

      <CustomCheckboxStyled
        disabled={true}
        name="cookie_setup_mandatory"
        id="cookie_setup_mandatory"
        value={'1'}
        checked={1}
      >
        {__('Nevyhnutné - Základné súbory cookies')}
      </CustomCheckboxStyled>
    </Wrapper>
  );
};

export default CookiesChooser;
