import * as React from 'react';
import { __ } from 'react-i18n';
import styled, { css } from 'styled-components';
import { InputStyled } from '../../components/_helpers/form/Input';
import { rem } from 'polished';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import {
  InputStyles,
  InputStyledStyles,
  ErrorPopup,
} from '../../components/_helpers/form/FormStyles';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { Form, Field, FormSpy } from 'react-final-form';
import API from '../../services/API';
import Alert from '../../components/Alert/Alert';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import { composeValidators, phone, required } from '../../utilities/validators';
import { resetForm, scrollToElement, scrollToError } from '../../utilities';
import { Title } from '../../components/_layout/Page/ContentContainer';
import { ANCHOR_FORM } from '../../constants';
import { Location } from 'history';

const ComplaintLabel = styled.div`
  margin-bottom: ${rem(8)};
`;

const ComplaintInputCss: any = css`
  width: 100%;
  ${(props: any) => {
    return props.error ? 'border:1px solid red;' : '';
  }}
`;

const ComplaintInput: any = styled(InputStyled)`
  ${ComplaintInputCss};
`;

const ComplaintInputEmail: any = styled(EmailAutocomplete)`
  ${ComplaintInputCss};
`;

const ComplaintTextarea: any = styled.textarea`
  ${InputStyles};
  ${InputStyledStyles};
  ${ComplaintInputCss};
  height: auto;
`;

const ComplaintRow = styled(Clearfix)`
  margin-bottom: ${rem(18)};

  &.full {
    width: 100%;
  }

  &:not(.full) {
    flex: 0 0 100%;

    ${({ theme }) => theme.media('sm')} {
      flex: 0 0 50%;

      &:nth-child(odd) {
        padding-right: ${rem(10)};
      }

      &:nth-child(even) {
        padding-left: ${rem(10)};
      }
    }
  }
`;

const Submit = styled(ButtonStyled)`
  display: block;
  width: ${rem(180)};
  margin: ${rem(20)} auto 0 auto;
`;

const ComplainForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${rem(30)};
`;

interface ComplainFormProps {
  location: Location;
}

interface ComplainFormState {
  loading: boolean;
  status: number;
  currentPath?: string;
  file0?: any;
  file0Error?: string;
  file1?: any;
  file1Error?: string;
}

class ComplaintForm extends React.Component<
  ComplainFormProps,
  ComplainFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: 0,
      file0: undefined,
      file1: undefined,
      file0Error: undefined,
      file1Error: undefined,
    };
  }

  public componentDidMount() {
    if (this.props.location?.hash === `#${ANCHOR_FORM}`) {
      this.scrollToAnchor();
    }
  }

  public componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location?.hash !== this.props.location?.hash &&
      this.props.location?.hash === `#${ANCHOR_FORM}`
    ) {
      this.scrollToAnchor();
    }
  }

  public validateFile(name: string): boolean {
    if (this.state[name]?.size > 5000000) {
      const state = {};
      state[`${name}Error`] = 'Maximalna povolená veľkosť pre súborov je 5MB';
      this.setState(state);
      return true;
    }

    const state = {};
    state[`${name}Error`] = undefined;
    this.setState(state);
    return false;
  }

  public validateFiles(): boolean {
    return this.validateFile('file0') || this.validateFile('file1');
  }

  public onSubmit = async (values, form) => {
    if (this.validateFiles()) {
      return;
    }

    this.setState({ loading: true });

    if (this.state.file0) {
      values = { ...values, file0: this.state.file0 };
    }

    if (this.state.file1) {
      values = { ...values, file1: this.state.file1 };
    }

    try {
      await API.complainForm(
        {
          xAcceptLanguage: 'sk',
        },
        values,
      );
      this.setState({ status: 1, file0: undefined, file1: undefined });
      resetForm(values, form);
    } catch (e) {
      this.setState({ status: -1 });
    }
    this.setState({ loading: false });
    this.scrollToAnchor();
  };

  public scrollToAnchor() {
    scrollToElement('scroll-anchor', window.innerWidth > 1024 ? 200 : 50, 10);
  }

  public render() {
    return (
      <>
        <Title className={'scroll-anchor'}>{__('Reklamačný formulár')}</Title>

        {this.state.status === 1 && (
          <Alert type="success">
            {' '}
            {__('Reklamácia bola poslaná úspešne')}{' '}
          </Alert>
        )}

        {this.state.status === -1 && (
          <Alert type="error"> {__('Reklamáciu sa nepodarilo odoslať')} </Alert>
        )}

        <Form
          onSubmit={this.onSubmit}
          render={({ handleSubmit }) => (
            <ComplainForm onSubmit={handleSubmit}>
              <Field name="name" validate={required}>
                {({ input, meta }) => (
                  <ComplaintRow
                    className={meta.error && meta.touched ? 'error' : ''}
                  >
                    <ComplaintLabel>{__('Vaše meno')}</ComplaintLabel>
                    <ComplaintInput
                      {...input}
                      error={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                  </ComplaintRow>
                )}
              </Field>

              <Field name="address" validate={required}>
                {({ input, meta }) => (
                  <ComplaintRow
                    className={meta.error && meta.touched ? 'error' : ''}
                  >
                    <ComplaintLabel>{__('Adresa')}</ComplaintLabel>
                    <ComplaintInput
                      {...input}
                      error={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                  </ComplaintRow>
                )}
              </Field>

              <Field name="phone" validate={composeValidators(required, phone)}>
                {({ input, meta }) => (
                  <ComplaintRow
                    className={meta.error && meta.touched ? 'error' : ''}
                  >
                    <ComplaintLabel>{__('Telefónne číslo')}</ComplaintLabel>
                    <ComplaintInput
                      {...input}
                      type="tel"
                      error={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                  </ComplaintRow>
                )}
              </Field>

              <Field name="email" validate={required}>
                {({ input, meta }) => (
                  <ComplaintRow
                    className={meta.error && meta.touched ? 'error' : ''}
                  >
                    <ComplaintLabel>{__('Email')}</ComplaintLabel>
                    <ComplaintInputEmail
                      {...input}
                      error={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                  </ComplaintRow>
                )}
              </Field>

              <Field name="invoice_number" validate={required}>
                {({ input, meta }) => (
                  <ComplaintRow
                    className={meta.error && meta.touched ? 'error' : ''}
                  >
                    <ComplaintLabel>
                      {__('Číslo objednávky alebo faktúry')}
                    </ComplaintLabel>
                    <ComplaintInput
                      {...input}
                      error={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                  </ComplaintRow>
                )}
              </Field>

              <Field name="iban" validate={required}>
                {({ input, meta }) => (
                  <ComplaintRow
                    className={meta.error && meta.touched ? 'error' : ''}
                  >
                    <ComplaintLabel>
                      {__('Číslo účtu pre vrátenie platby (IBAN)')}
                    </ComplaintLabel>
                    <ComplaintInput
                      {...input}
                      error={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                  </ComplaintRow>
                )}
              </Field>

              <Field name="content" validate={required}>
                {({ input, meta }) => (
                  <ComplaintRow className={'full'}>
                    <ComplaintLabel>
                      {__(
                        'Zoznam reklamovaného tovaru, množstvo a predmet reklamácie alebo popis vady',
                      )}
                    </ComplaintLabel>
                    <ComplaintTextarea
                      {...input}
                      rows={6}
                      error={meta.error && meta.touched}
                    />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                  </ComplaintRow>
                )}
              </Field>

              <Field name="file0">
                {({ input, meta }) => (
                  <ComplaintRow className={'full'}>
                    <ComplaintLabel>{__('Príloha č. 1')} </ComplaintLabel>
                    <ComplaintInput
                      {...input}
                      type={'file'}
                      onChange={({ target }) => {
                        this.setState(
                          { file0: target.files[0] },
                          this.validateFile.bind(this, 'file0'),
                        );
                        input.onChange(target.value);
                      }}
                      error={
                        (meta.error && meta.touched) || this.state.file0Error
                      }
                    />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                    {this.state.file0Error && (
                      <ErrorPopup>{this.state.file0Error}</ErrorPopup>
                    )}
                  </ComplaintRow>
                )}
              </Field>

              <Field name="file1">
                {({ input, meta }) => (
                  <ComplaintRow className={'full'}>
                    <ComplaintLabel>{__('Príloha č. 2')} </ComplaintLabel>
                    <ComplaintInput
                      {...input}
                      type={'file'}
                      onChange={({ target }) => {
                        this.setState(
                          { file1: target.files[0] },
                          this.validateFile.bind(this, 'file1'),
                        );
                        input.onChange(target.value);
                      }}
                      error={
                        (meta.error && meta.touched) || this.state.file1Error
                      }
                    />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                    {this.state.file1Error && (
                      <ErrorPopup>{this.state.file1Error}</ErrorPopup>
                    )}
                  </ComplaintRow>
                )}
              </Field>

              <Submit type="submit" className="primary">
                {__('Odoslať')}
              </Submit>

              <FormSpy
                subscription={{ submitFailed: true, modified: false }}
                onChange={() => {
                  scrollToError();
                }}
              />
            </ComplainForm>
          )}
        />
      </>
    );
  }
}

export default ComplaintForm;
