import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { formatStringDate } from '../../../utilities';
import { Link } from 'react-router';

const Wrapper = styled.div`
  display: block;
`;

const PaginationWrapper = styled.div`
  display: block;
`;

const ListWrapper = styled.div`
  display: block;
  ${({ theme }) => theme.media(425)} {
    display: flex;
  }
`;

const ListLeft = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  ${({ theme }) => theme.media(425)} {
    width: 50%;
    margin-right: ${rem(30)};
  }
`;

const ListRight = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  ${({ theme }) => theme.media(425)} {
    width: 50%;
    margin-right: ${rem(30)};
  }
`;

const Article = styled.article`
  display: block;
  padding: ${rem(19)};
  border: ${rem(1)} solid ${({ theme }) => theme.color.gray93};
  background-color: ${({ theme }) => theme.color.white};
  color: #383838;
  font-weight: 500;
  margin-bottom: ${rem(62)};
  word-wrap: break-word;
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: ' ';
  }
`;

const ImageContainer = styled.div`
  position: relative;
  margin: ${rem(-20)} ${rem(-20)} ${rem(29)};
`;

const Anchor = styled(Link)`
  display: block;
  position: relative;
  &:hover {
    text-decoration: none;
  }
`;

const Title = styled.h2`
  font-size: ${rem(18)};
  font-weight: 500;
  color: #211e1f;
`;

const Image = styled.img`
  min-width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
`;

const Meta = styled.p``;

const MetaPublished = styled.span`
  color: #383838;
  font-size: ${rem(14)};
`;

const Content = styled.p`
  color: #383838;
`;

const PaginationLeft = styled.div`
  float: left;
`;

const PaginationRight = styled.div`
  float: right;
`;

const PaginationAchor = styled(Anchor)`
  color: #e5173f;
  font-size: ${rem(16)};
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

interface PropsItem {
  article: any;
}

interface PropsList {
  articles: any;
  paginationNextLink: string | null;
  paginationPreviousLink: string | null;
  onClick: () => void;
  onLoaded: () => void;
}

class ArticleListItem extends React.PureComponent<PropsItem> {
  public render() {
    this.props.article.date = formatStringDate(this.props.article.custom_date);
    return (
      <Article id={this.props.article.id}>
        <ImageContainer>
          <Anchor to={this.props.article.url}>
            <Image src={this.props.article.image} />
          </Anchor>
        </ImageContainer>
        <Anchor to={this.props.article.url}>
          <Title>{this.props.article.name}</Title>
        </Anchor>
        <Meta>
          <MetaPublished>{this.props.article.date}</MetaPublished>
        </Meta>
        <Content
          dangerouslySetInnerHTML={{
            __html: this.props?.article?.json_content?.anotation,
          }}
        />
      </Article>
    );
  }
}

class ArticleList extends React.PureComponent<PropsList> {
  public render() {
    this.props.onLoaded();

    return (
      <Wrapper>
        {window && window.innerWidth && window.innerWidth >= 425 ? (
          <ListWrapper>
            <ListLeft>
              {this.props.articles.map((item, i) => {
                if (i % 2 === 0) {
                  return <ArticleListItem key={item.id} article={item} />;
                } else {
                  return '';
                }
              })}
            </ListLeft>
            <ListRight>
              {this.props.articles.map((item, i) => {
                if (i % 2 === 1) {
                  return <ArticleListItem key={item.id} article={item} />;
                } else {
                  return '';
                }
              })}
            </ListRight>
          </ListWrapper>
        ) : (
          <ListWrapper>
            {this.props.articles.map((item) => {
              return <ArticleListItem key={item.id} article={item} />;
            })}
          </ListWrapper>
        )}
        <PaginationWrapper>
          {this.props.paginationNextLink ? (
            <PaginationLeft>
              <PaginationAchor
                to={this.props.paginationNextLink}
                onClick={this.props.onClick.bind(null)}
              >
                « Staršie záznamy
              </PaginationAchor>
            </PaginationLeft>
          ) : (
            ''
          )}
          {this.props.paginationPreviousLink ? (
            <PaginationRight>
              <PaginationAchor
                to={this.props.paginationPreviousLink}
                onClick={this.props.onClick.bind(null)}
              >
                Ďalšie položky »
              </PaginationAchor>
            </PaginationRight>
          ) : (
            ''
          )}
        </PaginationWrapper>
      </Wrapper>
    );
  }
}

export default ArticleList;
