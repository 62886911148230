import * as React from 'react';
import styled from 'styled-components';
import { getImagePath } from '../../../utilities/product';
import LazyLoad from 'react-lazyload';

const Img = styled.img`
  padding: 0;
`;

const LazyLoadImg = styled(LazyLoad)`
  padding: 0;
`;

interface ImageProps {
  src: string | null;
  placeholder: string;
  retinaPlaceholder?: string;
  className?: string;
  w?: number;
  h?: number;
  width?: number;
  height?: number;
  notLazyloading?: boolean;
  notResize?: boolean;
  notWebp?: boolean;
  onClick?: () => void;
  ignoreRequestHeight?: boolean;
  alt?: string;
}

class ImagePlaceholder extends React.PureComponent<ImageProps> {
  public renderImage() {
    const {
      src,
      placeholder,
      retinaPlaceholder,
      className,
      w,
      h,
      width,
      height,
      notResize,
      notWebp,
      onClick,
      ignoreRequestHeight,
      alt,
    } = this.props;
    const srcSize = { width: w ? w : 100, height: h ? h : 100 };
    const srcSetSize = { width: w ? w * 2 : 100, height: h ? h * 2 : 100 };
    return (
      <Img
        width={width}
        height={height}
        src={
          src && src !== 'undefined.png' && src.constructor !== Object
            ? getImagePath(
                src,
                srcSize,
                !notResize,
                notWebp,
                false,
                ignoreRequestHeight,
              )
            : placeholder
        }
        srcSet={
          src &&
          src !== 'undefined.png' &&
          src.constructor !== Object &&
          retinaPlaceholder
            ? getImagePath(
                src,
                srcSetSize,
                !notResize,
                notWebp,
                false,
                ignoreRequestHeight,
              ) + ' 2x'
            : retinaPlaceholder
        }
        className={className}
        loading="lazy"
        onClick={onClick}
        alt={alt ? alt : ''}
      />
    );
  }

  public render() {
    const { w, notLazyloading } = this.props;
    if (notLazyloading) {
      return this.renderImage();
    } else {
      return (
        <LazyLoadImg height={w ? w : 100}>{this.renderImage()}</LazyLoadImg>
      );
    }
  }
}

export default ImagePlaceholder;
