import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { Container } from '../../theme/libraries/grid';
import styled from 'styled-components';
import { rem } from 'polished';
import { Heading2 } from '../../components/_helpers/Heading/Heading';
import * as cookie from 'react-cookies';
import API from '../../services/API';
import { USER_COOKIE } from '../App/constants';
import { URL_LOGIN } from '../../constants';
import { CART_COOKIE_ID } from '../Cart/constants';

export const Heading = styled(Heading2)`
  margin-top: 0;
  margin-bottom: ${rem(40)};
`;

class Logout extends React.Component<{}> {
  public static getDerivedStateFromProps(props, state) {
    API.oauthLogout();
    cookie.remove(USER_COOKIE, { path: '/' });
    cookie.remove(CART_COOKIE_ID, { path: '/' });
    window.location.replace(URL_LOGIN);
  }

  constructor(props) {
    super(props);
  }

  public render() {
    return <Container className="base" />;
  }
}

const mapStateToProps = (state, actions) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Logout' })(Logout),
);
