import { __ } from 'react-i18n/lib';

export const getDay = (day) => {
  switch (day) {
    case 1:
      return __('Pondelok');

    case 2:
      return __('Utorok');

    case 3:
      return __('Streda');

    case 4:
      return __('Štvrtok');

    case 5:
      return __('Piatok');

    case 6:
      return __('Sobota');

    case 7:
      return __('Nedeľa');

    default:
      return '';
  }
};

const formatOpeningHours = (openingHours, day) =>
  openingHours
    .filter((o) => o.day === day)
    .reduce(
      (acc, curr) => `${acc} ${curr.opening_time} - ${curr.closing_time}`,
      '',
    );

export const formatShop = (shop) => {
  if (!shop.opening_hours) {
    return shop;
  }

  const openingHours = shop.opening_hours
    .sort((a, b) => a.day - b.day) // zoradenie podla dni
    .map((o) => ({
      ...o,
      // 09:00:00 -> 09:00
      opening_time: o.opening_time.substring(0, o.opening_time.length - 3),
      closing_time: o.closing_time.substring(0, o.closing_time.length - 3),
    }));
  let dayNameStart = '';

  shop.opening_hours_formatted = openingHours
    .filter((o, i) => o.day !== openingHours[i + 1]?.day)
    .reduce((acc, o) => {
      const dayName = getDay(o.day);
      const formattedOpeningHours = formatOpeningHours(openingHours, o.day);
      if (
        formattedOpeningHours === formatOpeningHours(openingHours, o.day + 1)
      ) {
        if (dayNameStart === '') {
          dayNameStart = dayName;
        }
      } else {
        acc.push(
          dayNameStart === ''
            ? dayName + ': ' + formattedOpeningHours
            : dayNameStart + ' - ' + dayName + ': ' + formattedOpeningHours,
        );
        dayNameStart = '';
      }
      return acc;
    }, []);
  return shop;
};
