import * as React from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import { rem } from 'polished';
import { Button } from '../../_helpers/form/Button';
import Icon from '../../_helpers/Icon/Icon';

const List = styled.ul`
  padding: 0;
  margin: 0;
`;

const Item = styled.li`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  list-style: none;
  & + & {
    margin-top: ${rem(20)};
  }
`;

const Header = styled(Button)`
  display: block;
  width: 100%;
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  font-size: ${rem(16)};
  font-weight: 700;
  text-align: left;
  padding: ${rem(20)} ${rem(50)} ${rem(20)} ${rem(20)};
  position: relative;
  outline: none;
`;

const Chevron = styled(Icon)`
  ${({ theme }) => theme.size(12)};
  margin-top: ${rem(-6)};
  position: absolute;
  top: 50%;
  right: ${rem(30)};
  ${({ theme }) => theme.transition('transform')};
  &.active {
    transform: rotate(180deg);
  }
`;

const Body = styled.div`
  font-size: ${rem(14)};
  padding: 0 ${rem(20)} ${rem(20)};
`;

interface FaqItemProps {
  q: string;
  a: string;
}

interface FaqItemStates {
  height: number | string;
}

class FaqItem extends React.PureComponent<FaqItemProps, FaqItemStates> {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }
  public createAnswer = (annot) => {
    return { __html: annot };
  };

  public render() {
    const { a, q } = this.props;
    const { height } = this.state;
    return (
      <Item>
        <Header type="button" click={this.toggle}>
          {q}
          <Chevron icon="chevron" className={height !== 0 ? 'active' : ''} />
        </Header>
        <AnimateHeight height={height}>
          <Body dangerouslySetInnerHTML={this.createAnswer(a)} />
        </AnimateHeight>
      </Item>
    );
  }
  private toggle = () => {
    const { height } = this.state;
    this.setState({
      height: height === 0 ? 'auto' : 0,
    });
  };
}

interface FaqListProps {
  items: any;
}

class FaqList extends React.PureComponent<FaqListProps> {
  public render() {
    return (
      <List>
        {this.props.items.map((item, i) => {
          return (
            <FaqItem key={i} q={item.name} a={item?.json_content?.anotation} />
          );
        })}
      </List>
    );
  }
}

export default FaqList;
