import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { __ } from 'react-i18n';
import { loadHtmlSitemap } from '../App/actions';
import ContentContainer from '../../components/_layout/Page/ContentContainer';
import { ContentContainerBlock } from '../../components/_helpers/Contact/ContactStyles';

interface SitemapProps {
  htmlSitemap: any;
}

class Sitemap extends React.Component<SitemapProps> {
  public static async getInitialProps(props) {
    await props.dispatch(loadHtmlSitemap('sk'));
  }

  constructor(props) {
    super(props);
  }

  public render() {
    return (
      <ContentContainer title={__('Mapa stránok')}>
        <ContentContainerBlock>
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.htmlSitemap ? this.props.htmlSitemap.html : '',
            }}
          />
        </ContentContainerBlock>
      </ContentContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    htmlSitemap: state.general.htmlSitemap,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Sitemap' })(Sitemap),
);
