import * as React from 'react';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import '../../defaults';
import { Container } from '../../theme/libraries/grid';
import ThankYou from '../../components/Cart/ThankYou';
import API from '../../services/API';
import { Loader } from '../../components/_helpers/Loader/Loader';

const FinishOrder = (props) => {
  const [order, setOrder] = React.useState(null);

  React.useEffect(() => {
    API.getOrder(props.params.orderPublicId).then((orderResult: any) => {
      setOrder(orderResult);
    });
  }, []);

  return (
    <Container className="base">
      {order ? (
        <ThankYou order={order} dispatch={props.dispatch} user={props.user} />
      ) : (
        <Loader loading={true} />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'FinishOrder' })(FinishOrder),
);
