import * as React from 'react';
import { __ } from 'react-i18n/lib';
import {
  RegistrationHeading,
  RegistrationErrorPopup,
  RegistrationInput,
  RegistrationLabel,
  RegistrationFormCol,
  RegistrationFormRow,
} from './styles';
import { Field, Form } from 'react-final-form';
import { Loader } from '../_helpers/Loader/Loader';
import { required } from '../../utilities/validators';
import {
  onRegistrationSubmit,
  referRegistrationContactPersonSection,
  RegistrationProps,
  renderRegistrationAlert,
  renderRegistrationIcoInput,
  renderRegistrationPasswordInputs,
  renderRegistrationSubmitButton,
  renderRegistrationTermsCheckbox,
} from './constants';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  loadCompanyIntroLess,
  loadCompanyIntroMore,
} from '../../containers/Registration/action';
import { OtherText, URL_REGISTRATION_COMPLETE_COMPANY } from '../../constants';
import RegistrationIntro from './RegistrationIntro';
import { ChematCustomerType } from '../../library/ChematCustomerType';
import MetaTags from '../MetaTags/MetaTags';
import { loadGlobalMetaTags } from '../../utilities';

const metaUid = 'REG_COMPANY';

interface RegistrationCompanyProps {
  companyIntroLess: OtherText;
  companyIntroMore: OtherText;
  globalMetaTags: any;
}

const RegistrationCompany = (
  props: RegistrationProps & RegistrationCompanyProps,
) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(undefined);
  const [showPassword, setShowPassword] = React.useState(false);

  const handlePasswordIcon = () => {
    setShowPassword(!showPassword);
  };

  const {
    dispatch,
    companyIntroLess,
    companyIntroMore,
    globalMetaTags,
  } = props;

  const onSubmit = async (values) =>
    onRegistrationSubmit(
      dispatch,
      setSubmitting,
      setSubmitError,
      {
        email: values.contact_email,
        login: values.ico,
        ico: values.ico,
        password: values.password,
        password_again: values.password_confirm,
        skvelko_type: ChematCustomerType.COMPANY,
        company_name: values.company_name,
        contact_name: values.contact_name,
        phone: values.contact_phone,
        lang: 'sk',
      },
      URL_REGISTRATION_COMPLETE_COMPANY,
    );

  const renderCompanyNameInput = () => (
    <Field name="company_name" validate={required}>
      {({ input, meta }) => (
        <RegistrationFormCol>
          <RegistrationLabel htmlFor="company_name">
            {__('Názov organizácie')}:
          </RegistrationLabel>
          <RegistrationInput {...input} type="text" />
          {meta.error && meta.touched && (
            <RegistrationErrorPopup>{meta.error}</RegistrationErrorPopup>
          )}
        </RegistrationFormCol>
      )}
    </Field>
  );

  return (
    <>
      {globalMetaTags[metaUid] ? (
        <MetaTags tags={globalMetaTags[metaUid]} />
      ) : (
        <MetaTags tags={{ title: 'Daffer.sk - Registrácia' }} />
      )}
      <Loader loading={submitting}>
        <RegistrationHeading>{__('Výhody registrácie')}:</RegistrationHeading>
        <RegistrationIntro
          dispatch={dispatch}
          loadLess={loadCompanyIntroLess}
          loadMore={loadCompanyIntroMore}
          textLess={companyIntroLess}
          textMore={companyIntroMore}
        />
        <RegistrationHeading>{__('Registračný formulár')}:</RegistrationHeading>
        {renderRegistrationAlert(submitError)}
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <RegistrationFormRow>
                {renderCompanyNameInput()}

                {renderRegistrationIcoInput()}
              </RegistrationFormRow>

              {renderRegistrationPasswordInputs(
                handlePasswordIcon,
                showPassword,
              )}

              {referRegistrationContactPersonSection()}

              {renderRegistrationTermsCheckbox(dispatch)}

              {renderRegistrationSubmitButton()}
            </form>
          )}
        />
      </Loader>
    </>
  );
};

RegistrationCompany.getInitialProps = async (parentProps) => {
  const { dispatch } = parentProps;
  const promises: any[] = [];

  promises.push(dispatch(loadGlobalMetaTags(metaUid)));

  await Promise.all(promises);
};

const mapStateToProps = (state) => {
  return {
    companyIntroLess: state.registration.companyIntroLess,
    companyIntroMore: state.registration.companyIntroMore,
    globalMetaTags: state.general.globalMetaTags,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'RegistrationCompany' })(RegistrationCompany),
);
