import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';
import { StyledFooter, Button2 } from './ChooseModal';
import API, { ThenArg } from '../../../services/API';
import { getOnStockCount } from '../../../containers/App/actions';

const ModalDiv = styled(BaseModal)`
  width: ${rem(560)};
`;

const Row = styled.div`
  display: flex;
  & + & {
    margin-top: ${rem(15)};
  }
`;

const CloseButton = styled(Button2)``;

interface ModalProps {
  product: any;
  cart: ThenArg<typeof API.getCart>;
  onCloseButtonClick?: (event) => void;
}

class ProductAddedToCartModalOutOfStock extends React.PureComponent<
  ModalProps
> {
  public render() {
    const { product, cart } = this.props;

    const onStockCount = getOnStockCount(cart, product);
    const isOutOfStock = onStockCount <= 0;

    return (
      <ModalDiv
        title={__('Produkt nie je možné pridať do košíka')}
        body={
          <Row>
            {isOutOfStock ? (
              <p>{__('Produkt momentálnie nie je skladom.')}</p>
            ) : (
              <p>
                {__('Je možné vložiť maximálne ')}
                {onStockCount}
                {__(' ks, ktoré sú skladom.')}
              </p>
            )}
          </Row>
        }
        footer={
          <StyledFooter>
            <CloseButton
              className="primary lg"
              onClick={this.props.onCloseButtonClick}
            >
              {__('Pokračovať v nakupovaní')}
            </CloseButton>
          </StyledFooter>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default ProductAddedToCartModalOutOfStock;
