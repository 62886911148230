import * as React from 'react';
import {
  OtherText,
  TITLE_SKVELKOBOX,
  TITLE_SKVELKOBOX_BENEFITS,
  URL_SKVELKOBOX_BENEFITS,
} from '../../constants';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { loadCmsContent } from '../App/actions';
import { prop } from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';
import API, { ThenArg } from '../../services/API';
import { loadThankYouSkvelkoBoxText } from './actions';
import {
  Button,
  ButtonStyledStyles,
} from '../../components/_helpers/form/Button';
import styled from 'styled-components';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import Alert from '../../components/Alert/Alert';

const ButtonWrapper = styled.div`
  margin: ${rem(32)} 0;
  display: grid;
  justify-content: center;
`;

const AlertStyled = styled(Alert)`
  margin: ${rem(10)} 0;
  p {
    margin: 0;
  }
`;

const InterestedButton = styled(Button)`
  ${ButtonStyledStyles};
`;

const sitemapUid = 'SKVELKOBOX_BENEFITS';

interface SkvelkoBoxBenefitsProps {
  cmsContent?: any;
  cmsContentLoading?: boolean;
  userDetail?: ThenArg<typeof API.getCustomerDetails>;
  thankYouSkvelkoBoxText?: OtherText;
}

interface SkvelkoBoxBenefitsState {
  requestSkvelkoboxLoading: boolean;
  requestSkvelkoboxStatus: number;
  skvelkoboxDisabled: boolean;
}

class SkvelkoBoxBenefits extends React.PureComponent<
  SkvelkoBoxBenefitsProps,
  SkvelkoBoxBenefitsState
> {
  public static async getInitialProps(props) {
    props.dispatch(loadCmsContent(sitemapUid));
    if (!props.thankYouSkvelkoBoxText) {
      props.dispatch(loadThankYouSkvelkoBoxText());
    }
  }

  constructor(props) {
    super(props);

    props.setBreadcrumbsItems([
      { name: TITLE_SKVELKOBOX, url: URL_SKVELKOBOX_BENEFITS },
      { name: TITLE_SKVELKOBOX_BENEFITS, url: props.currentPath },
    ]);

    this.state = {
      requestSkvelkoboxLoading: false,
      requestSkvelkoboxStatus: 0,
      skvelkoboxDisabled: false,
    };
  }

  public async handleSubmit() {
    this.setState({
      requestSkvelkoboxLoading: true,
      requestSkvelkoboxStatus: 0,
    });

    try {
      await API.requestSkvelkoboxForm({ xAcceptLanguage: 'sk' });
      this.setState({
        requestSkvelkoboxStatus: 1,
        skvelkoboxDisabled: true,
      });
    } catch (e) {
      this.setState({ requestSkvelkoboxStatus: -1 });
    } finally {
      this.setState({ requestSkvelkoboxLoading: false });
    }
  }

  public render() {
    const {
      cmsContentLoading,
      cmsContent,
      userDetail,
      thankYouSkvelkoBoxText,
    } = this.props;
    const {
      requestSkvelkoboxStatus,
      requestSkvelkoboxLoading,
      skvelkoboxDisabled,
    } = this.state;
    const body = prop(
      cmsContent,
      `${sitemapUid}.content.json_content.body`,
      '',
    );
    return (
      <Loader loading={!!cmsContentLoading}>
        <div
          dangerouslySetInnerHTML={{
            __html: body?.replace(
              new RegExp('{{ SCHOOL_ID }}', 'g'),
              userDetail?.school_id || '',
            ),
          }}
        />
        <Loader loading={requestSkvelkoboxLoading}>
          {requestSkvelkoboxStatus === 1 ? (
            <AlertStyled type="success">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    thankYouSkvelkoBoxText?.body ||
                    __('Žiadosť bola úspešne odoslaná'),
                }}
              />
            </AlertStyled>
          ) : (
            ''
          )}

          {requestSkvelkoboxStatus === -1 ? (
            <AlertStyled type="error">
              {__('Pri zaslaní žiadosti sa vyskytla chyba')}
            </AlertStyled>
          ) : (
            ''
          )}

          <ButtonWrapper>
            <InterestedButton
              className="primary lg"
              onClick={this.handleSubmit.bind(this)}
              disabled={skvelkoboxDisabled}
            >
              {__('Mám záujem o Skvelkobox')}
            </InterestedButton>
          </ButtonWrapper>
        </Loader>
      </Loader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cmsContent: state.general.cmsContent,
    cmsContentLoading: state.general.cmsContentLoading,
    thankYouSkvelkoBoxText: state.myAccount.thankYouSkvelkoBoxText,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SkvelkoBoxBenefits' })(SkvelkoBoxBenefits),
);
