import * as React from 'react';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import '../../defaults';
import { Container } from '../../theme/libraries/grid';
import CartSteps from '../../components/Cart/CartSteps';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { rem } from 'polished';
import styled from 'styled-components';
import API, { ThenArg } from '../../services/API';
import { setAddToCartModalProduct, setCartHadUserModal } from '../App/actions';

const CartContainer = styled(Container)`
  padding-bottom: ${rem(64)};
`;

interface CartProps {
  cart: /*ThenArg<typeof API.getCart>*/ any;
  user: any;
  dispatch: any;
  children: any;
  routing: any;
}

const Cart = (props: CartProps) => {
  const { cart, dispatch, routing, user } = props;
  let onDemandCartItems: ThenArg<typeof API.getCart>['items'] = [];
  let regularCartItems: ThenArg<typeof API.getCart>['items'] = [];

  React.useEffect(() => {
    if (cart && cart.had_user && !cart.user_id && !user) {
      dispatch(setCartHadUserModal(true));
    }
    dispatch(setAddToCartModalProduct(null));
  }, []);

  // Split cart items
  if (cart && cart.items?.length > 0) {
    cart.items.forEach((cartItem) => {
      if (cartItem.product?.on_demand) {
        onDemandCartItems.push(cartItem);
      } else {
        regularCartItems.push(cartItem);
      }
    });
  }

  let cartStep = 1;

  if (cart && routing) {
    if (routing.locationBeforeTransitions.pathname.includes('kosik')) {
      cartStep = 1;
    }

    if (
      routing.locationBeforeTransitions.pathname.includes('doprava-a-platba')
    ) {
      cartStep = 2;
    }

    if (
      routing.locationBeforeTransitions.pathname.includes('udaje-pre-dorucenie')
    ) {
      cartStep = 3;
    }

    /*
    if(cart.step === 1 && routing.locationBeforeTransitions.pathname !== '/kosik'){
      props.dispatch(push('/kosik'));
    }

    if(cart.step === 2 && routing.locationBeforeTransitions.pathname !== '/doprava-a-platba'){
      props.dispatch(push('/doprava-a-platba'));
    }

    if(cart.step === 3 && routing.locationBeforeTransitions.pathname !== '/udaje-pre-dorucenie'){
      props.dispatch(push('/udaje-pre-dorucenie'));
    }
    */
  }

  return (
    <React.Fragment>
      <CartSteps
        actualStep={cartStep}
        dispatch={dispatch}
        cart={cart}
        isDemand={regularCartItems.length === 0 && onDemandCartItems.length > 0}
      />
      <CartContainer className="base">
        {cart ? (
          <div>
            {React.cloneElement(props.children, {
              ...props,
              onDemandCartItems,
              regularCartItems,
            })}
          </div>
        ) : (
          <Loader loading={true} />
        )}
      </CartContainer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    user: state.auth.user,
    routing: state.routing,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Cart' })(Cart),
);
