import * as React from 'react';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { __ } from 'react-i18n/lib';
import { prop } from '../../../utilities';
import { Button2, StyledFooter } from './ChooseModal';
import Overlay from '../Overlay/Overlay';
import { loadInfoMultipleStoresText } from '../../../containers/App/actions';
import BaseModal from './BaseModal';
import { rem } from 'polished';
import styled from 'styled-components';
import { Loader } from '../Loader/Loader';

const StyledModal = styled(BaseModal)`
  width: ${rem(510)};
`;

interface ModalProps {
  dispatch: any;
  infoMultipleStoresText?: string;
  infoMultipleStoresLoading?: boolean;
  onCloseButtonClick?: (event) => void;
}

const MultipleStoreInfoModal = (props: ModalProps) => {
  const {
    dispatch,
    infoMultipleStoresLoading,
    infoMultipleStoresText,
    onCloseButtonClick,
  } = props;

  React.useEffect(() => {
    if (!infoMultipleStoresText) {
      dispatch(loadInfoMultipleStoresText());
    }
  }, []);

  const name = prop(infoMultipleStoresText, 'name', __('Info'));
  const body = prop(infoMultipleStoresText, 'body', '');

  if (infoMultipleStoresLoading) {
    return (
      <Overlay>
        <Loader loading={infoMultipleStoresLoading} />
      </Overlay>
    );
  } else {
    return (
      <StyledModal
        title={name}
        body={body}
        footer={
          <StyledFooter>
            <Button2 className="primary lg" onClick={onCloseButtonClick}>
              {__('Zavrieť')}
            </Button2>
          </StyledFooter>
        }
        onCloseButtonClick={onCloseButtonClick}
      />
    );
  }
};

const mapStateToProps = (state) => {
  return {
    infoMultipleStoresText: state.general.infoMultipleStoresText,
    infoMultipleStoresLoading: state.general.infoMultipleStoresLoading,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MultipleStoreInfoModal' })(MultipleStoreInfoModal),
);
