import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { formatPrice } from '../../utilities';
import { PriceCart } from './Cart1';
import {
  BlockAppearanceStyles,
  BlockMarginStyles,
  BlockShadowStyles,
} from '../_helpers/Block/Block';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import ImagePlaceholder from '../_helpers/Image/ImagePlaceholder';
import { ThumbStyles } from '../_helpers/product/product-styles';
import { __ } from 'react-i18n';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PerfectScrollbarStyles } from '../_helpers/PerfectScrollbar/PerfectScrollbarStyles';
import {
  hasCustomerTrustPoints,
  hasCustomerAppliedTrustPoints,
  getConsumedTrustPointsMaxForApply,
} from '../../containers/Cart/actions';
import { CheckboxStyled } from '../_helpers/form/Checkbox';
import { Loader } from '../_helpers/Loader/Loader';
import Icon from '../_helpers/Icon/Icon';
import API, { ThenArg } from '../../services/API';

const Wrapper = styled.div`
  ${BlockAppearanceStyles};
  ${BlockShadowStyles};
  font-size: ${rem(14)};
  ${BlockMarginStyles};
`;

const SummaryItems = styled(PerfectScrollbar)`
  ${PerfectScrollbarStyles};
  &.scrollable {
    height: ${rem(230)};
  }
`;

const ProductOnDemandInfoText = styled.p`
  margin-top: 12px;
  margin-bottom: 12px;
  color: #4d94ff;
  font-weight: bold;
  padding: 20px;
`;

const ProductsDiv = styled.div`
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  padding: ${rem(20)};
`;

const ProductsCount = styled.p`
  color: ${({ theme }) => theme.color.gray66};
  font-size: ${rem(14)};
  margin: 0 0 ${rem(20)};
`;

const ProductRow = styled(Clearfix)`
  padding-top: ${rem(10)};
  padding-bottom: ${rem(10)};
`;

const ThumbWrapper = styled.div`
  float: left;
  width: ${rem(75)};
`;

const Thumb = styled(ImagePlaceholder)`
  ${ThumbStyles};
  width: ${rem(60)};
`;

const ProductInfo = styled.div`
  float: left;
  width: 100%;
  padding-top: ${rem(10)};
  padding-right: ${rem(10)};
  padding-left: ${rem(75)};
  margin-left: ${rem(-75)};
`;

const ProductName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProductAmount = styled(ProductName)`
  color: ${({ theme }) => theme.color.gray66};
`;

const Body = styled.div`
  padding: ${rem(10)} ${rem(20)};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${rem(10)};
  padding-bottom: ${rem(10)};
`;

const RowSpecial = styled(Row)`
  background-color: ${({ theme }) => theme.color.gray87};
  flex-wrap: wrap;
  margin: 0 ${rem(-20)} ${rem(-10)} ${rem(-20)};
  padding: ${rem(10)} ${rem(20)};
`;

const RowSale = styled.div`
  display: contents;
`;

const BoldClass = css`
  &.bold {
    font-weight: bold;
  }
`;

const SaleClass = css`
  &.sale {
    color: ${({ theme }) => theme.color.purple};
  }
`;

const GiftClass = css`
  &.gift {
    color: ${({ theme }) => theme.color.success};
    margin: 0 auto;
  }
`;

const Key = styled.div`
  ${BoldClass};
  ${SaleClass};
  ${GiftClass};
  align-self: center;
`;

const KeyBold = styled(Key)`
  font-weight: bold;
`;

const Value = styled.div`
  ${BoldClass};
  ${SaleClass};
  ${GiftClass};
  &.sale {
    font-weight: bold;
  }
`;

const PriceRow = styled(Row)`
  padding: ${rem(2)} 0;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  padding: ${rem(20)};
`;

const Checkbox = styled(CheckboxStyled)``;

const GiftIcon = styled(Icon)`
  ${({ theme }) => theme.size(24)};
  fill: ${({ theme }) => theme.color.success};
`;

interface CartSummaryProps {
  items?: any[];
  cart?: any;
  customerDetails?: any;
  onApplyTrustPointsChange?: any;
  loading?: any;
  showWithVat?: boolean;
  onDemandCartItems: ThenArg<typeof API.getCart>['items'];
  regularCartItems: ThenArg<typeof API.getCart>['items'];
  isDemand: boolean;
}

const CartSummary = (props: CartSummaryProps) => {
  const placeholder = '/images/placeholder-100.png';
  const placeholder2x = '/images/placeholder-100@2x.png';

  const {
    items,
    cart,
    customerDetails,
    onApplyTrustPointsChange,
    loading,
    showWithVat,
    onDemandCartItems,
    regularCartItems,
    isDemand,
  } = props;

  const hasTrustPoints = hasCustomerTrustPoints(customerDetails);
  const hasAppliedTrustPoints = hasCustomerAppliedTrustPoints(cart);
  const consumedTrustPointsMaxForApply = getConsumedTrustPointsMaxForApply(
    cart,
  );

  const renderSummaryItems = (summaryItems) => {
    return summaryItems.map((item, i) => {
      return (
        <ProductRow key={i}>
          <ThumbWrapper>
            <Thumb
              src={item.product.picture}
              placeholder={placeholder}
              retinaPlaceholder={placeholder2x}
              w={100}
              h={100}
            />
          </ThumbWrapper>
          <ProductInfo>
            <ProductName>{item.product.name}</ProductName>
            <ProductAmount>{item.count} ks</ProductAmount>
          </ProductInfo>
        </ProductRow>
      );
    });
  };

  return (
    <Loader loading={loading}>
      <Wrapper>
        {items !== undefined && items !== null ? (
          <>
            {regularCartItems.length > 0 ? (
              <ProductsDiv>
                <ProductsCount>{`${__('Produkty')} (${
                  regularCartItems.length
                })`}</ProductsCount>
                <SummaryItems
                  className={regularCartItems.length > 3 ? 'scrollable' : ''}
                >
                  <div>
                    {renderSummaryItems(regularCartItems)}
                    {cart.chosen_gift?.gift_name ? (
                      <Row>
                        <Key className="gift">
                          <GiftIcon icon="gift" />
                        </Key>
                        <Value className="gift">
                          {cart.chosen_gift.gift_name}
                        </Value>
                      </Row>
                    ) : (
                      ''
                    )}
                  </div>
                </SummaryItems>
              </ProductsDiv>
            ) : (
              ''
            )}
            {onDemandCartItems.length > 0 ? (
              <ProductsDiv>
                <ProductsCount>{`${__('Produkty na dopyt')} (${
                  onDemandCartItems.length
                })`}</ProductsCount>
                <SummaryItems
                  className={onDemandCartItems.length > 3 ? 'scrollable' : ''}
                >
                  <div>
                    {renderSummaryItems(onDemandCartItems)}
                    {cart.chosen_gift?.gift_name ? (
                      <Row>
                        <Key className="gift">
                          <GiftIcon icon="gift" />
                        </Key>
                        <Value className="gift">
                          {cart.chosen_gift.gift_name}
                        </Value>
                      </Row>
                    ) : (
                      ''
                    )}
                  </div>
                </SummaryItems>
              </ProductsDiv>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        {!isDemand ? (
          <Body>
            {cart && cart.delivery ? (
              <Row>
                <Key> {cart.delivery.name} </Key>
                <Value>
                  {formatPrice(
                    showWithVat
                      ? cart.delivery.price.price
                      : cart.delivery.price.price_without_vat
                      ? cart.delivery.price.price_without_vat
                      : 0,
                    'EUR',
                  )}
                </Value>
              </Row>
            ) : (
              <Row>
                <Key>{__('Doprava')}</Key>
                <Value> --- </Value>
              </Row>
            )}

            {cart && cart.payment ? (
              <Row>
                <Key> {cart.payment.name} </Key>
                <Value>
                  {formatPrice(
                    showWithVat
                      ? cart.payment.price.price
                      : cart.payment.price.price_without_vat,
                    'EUR',
                  )}
                </Value>
              </Row>
            ) : (
              <Row>
                <Key>{__('Platba')}</Key>
                <Value> --- </Value>
              </Row>
            )}

            <Row>
              <Key>{__('Objednávka')}</Key>
              {showWithVat ? (
                <Value>
                  {formatPrice(
                    (cart.customer_card_discount > 0
                      ? cart.total_items_price_with_discount
                      : cart.total_items_price) || 0,
                    'EUR',
                  )}
                </Value>
              ) : (
                <Value>
                  {formatPrice(
                    (cart.customer_card_discount > 0
                      ? cart.total_items_price_with_discount_without_vat
                      : cart.total_items_price_without_vat) || 0,
                    'EUR',
                  )}
                </Value>
              )}
            </Row>

            {hasTrustPoints ? (
              <RowSpecial>
                <Key className="bold">
                  {`${__('Dostupné vernostné body')}: `}
                  {formatPrice(
                    customerDetails.trust_points,
                    '',
                    2,
                    false,
                    true,
                  )}
                </Key>
                <Checkbox
                  id="credit"
                  name="credit"
                  value="1"
                  checked={hasAppliedTrustPoints}
                  onChange={onApplyTrustPointsChange}
                >
                  {`${__('uplatniť zľavu')} (${__('max. výška zľavy je')} ${
                    cart.consumed_trust_points_max_for_apply_percentage
                  }% ${__('objednávky')} - `}
                  {formatPrice(consumedTrustPointsMaxForApply, 'EUR')})
                </Checkbox>
                {hasAppliedTrustPoints ? (
                  <RowSale>
                    <Key className="sale">{__('Zľava - vernostné body')}</Key>
                    <Value className="sale">
                      {formatPrice(cart.consumed_trust_points * -1, 'EUR')}
                    </Value>
                  </RowSale>
                ) : (
                  ''
                )}
              </RowSpecial>
            ) : hasAppliedTrustPoints ? (
              <Row>
                <Key className="sale">{__('Zľava - vernostné body')}</Key>
                <Value className="sale">
                  {formatPrice(cart.consumed_trust_points * -1, 'EUR')}
                </Value>
              </Row>
            ) : (
              ''
            )}
          </Body>
        ) : (
          ''
        )}

        {showWithVat && !isDemand ? (
          <Footer>
            <PriceRow>
              <Key>Celkom s DPH</Key>
              <PriceCart>{formatPrice(cart.total_price, 'EUR')}</PriceCart>
            </PriceRow>
          </Footer>
        ) : (
          ''
        )}
        {!showWithVat && !isDemand ? (
          <Footer>
            <PriceRow>
              <KeyBold>Celkom bez DPH</KeyBold>
              <PriceCart>
                {formatPrice(cart.total_price_without_vat, 'EUR')}
              </PriceCart>
            </PriceRow>
            <PriceRow>
              <Key>Celkom s DPH</Key>
              <PriceCart>{formatPrice(cart.total_price, 'EUR')}</PriceCart>
            </PriceRow>
          </Footer>
        ) : (
          ''
        )}
        {onDemandCartItems.length > 0 && !isDemand ? (
          <ProductOnDemandInfoText>
            {__('Cena celkom nezahŕňa produkty na dopyt')}
          </ProductOnDemandInfoText>
        ) : (
          ''
        )}
      </Wrapper>
    </Loader>
  );
};

export default CartSummary;
