import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { DropdownStyles } from '../../Dropdown/Dropdown';
import { ButtonStyled } from '../../_helpers/form/Button';
import { ThumbStyles } from '../../_helpers/product/product-styles';
import {
  ProductSmallAmount,
  ProductSmallInfoCol,
  ProductSmallName,
  ProductSmallPriceCol,
  ProductSmallThumbCol,
} from '../../Product/ProductStyles';
import { withRouter, WithRouterProps } from 'react-router';
import API, { ThenArg } from '../../../services/API';
import ImagePlaceholder from '../../_helpers/Image/ImagePlaceholder';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PerfectScrollbarStyles } from '../../_helpers/PerfectScrollbar/PerfectScrollbarStyles';
import { updateCart } from '../../../containers/Cart/actions';
import {
  hasProductCustomerPrice,
  isCustomerWarehouse,
  isDiscountInCart,
  isRetailType,
  prop,
  showPriceWithVat,
} from '../../../utilities';
import { Loader } from '../../_helpers/Loader/Loader';

const Wrapper = styled.div`
  ${DropdownStyles};
  display: block;
  font-size: ${rem(14)};
  width: ${rem(300)};
  top: 100%;
  right: ${rem(10)};
  z-index: 15;
  ${({ theme }) => theme.media(340)} {
    width: ${rem(320)};
  }
`;

const SummaryItemsWrapper = styled.div`
  padding: ${rem(20)};
`;

const SummaryItems = styled(PerfectScrollbar)`
  ${PerfectScrollbarStyles};
  &.scrollable {
    height: ${rem(293)};
  }
`;

const ItemsWrapper = styled.div``;

const Row = styled.div`
  display: flex;
  & + & {
    margin-top: ${rem(15)};
  }
`;

const Col = styled.div``;

const ThumbCol = styled(Col)`
  ${ProductSmallThumbCol};
`;

const InfoCol = styled(Col)`
  ${ProductSmallInfoCol};
`;

const PriceCol = styled(Col)`
  ${ProductSmallPriceCol};
`;

const Thumb = styled(ImagePlaceholder)`
  ${ThumbStyles};
  margin-right: ${rem(15)};
  padding: 0;
`;

const Name = styled.p`
  ${ProductSmallName};
`;

const Amount = styled.p`
  ${ProductSmallAmount};
`;

const Price = styled.p`
  margin: 0;
`;

const PriceOld = styled.p`
  font-size: ${rem(13)};
  font-weight: 400;
  text-decoration: line-through;
  margin: 0;
`;

const PriceSale = styled.p`
  color: ${({ theme }) => theme.color.primary};
  margin: 0;
`;

const PriceDiscount = styled.p`
  color: ${({ theme }) => theme.color.success};
  margin: 0;
`;

const PriceSaleOldWrapper = styled.div`
  margin: 0;
`;

const Placeholder = styled.p`
  font-size: ${rem(16)};
  font-weight: 700;
  text-align: center;
`;

const Separator = styled.hr`
  border: none;
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  margin: 0;
`;

const Footer = styled.footer`
  padding: ${rem(20)};
`;

const GoToCartButton = styled(ButtonStyled)`
  display: block;
  width: 100%;
`;

interface MiniCartProps {
  redirectToCart: any;
  closeModal: any;
  cart: ThenArg<typeof API.getCart>;
  user: any;
  isOnlyDemand: boolean;
  dispatch: any;
}

interface MiniCartStates {
  toCartButtonLoading: boolean;
}

class MiniCart extends React.PureComponent<
  WithRouterProps & MiniCartProps,
  MiniCartStates
> {
  constructor(props) {
    super(props);
    this.state = {
      toCartButtonLoading: false,
    };
  }

  public redirectToCart = async () => {
    if (this.props.cart) {
      const currentStep = prop(this.props.cart, 'step');
      if (currentStep !== 1) {
        this.setState({ toCartButtonLoading: true });

        const newCart = { ...this.props.cart, step: 1 };
        await this.props.dispatch(updateCart(newCart));

        this.setState({ toCartButtonLoading: false });
      }
    }

    this.props.redirectToCart();
    this.props.closeModal();
  };

  public render() {
    const { cart, user, isOnlyDemand } = this.props;

    const placeholder = '/images/placeholder-46.png';
    const placeholder2x = '/images/placeholder-46@2x.png';

    const isWarehouse = isCustomerWarehouse(user);
    const showWithVat = showPriceWithVat(user);

    return (
      <Wrapper>
        <Loader loading={this.state.toCartButtonLoading}>
          {cart && cart.items && cart.items.length > 0 ? (
            <ItemsWrapper>
              <SummaryItemsWrapper>
                <SummaryItems
                  className={cart.items.length > 5 ? 'scrollable' : ''}
                >
                  <div>
                    {cart.items.map((item, i) => {
                      const retailGroupPrice =
                        item &&
                        item.good &&
                        item.good.group_prices &&
                        item.good.group_prices.length &&
                        item.good.group_prices.find((g) =>
                          isRetailType(g.skupina_id),
                        );

                      const price =
                        !showWithVat && item.sum_price_without_vat
                          ? item.sum_price_without_vat
                          : item.sum_price
                          ? item.sum_price.toFixed(2)
                          : '0';
                      const count = item.count;
                      const oldPrice = item.good.oldprice
                        ? (
                            (showWithVat
                              ? item.good.oldprice
                              : item.good.oldprice /
                                (1 + item.good.vat_rate / 100)) * count
                          ).toFixed(2)
                        : 0;
                      const retailPrice = retailGroupPrice
                        ? retailGroupPrice.oldprice
                          ? (retailGroupPrice.oldprice * count).toFixed(2)
                          : retailGroupPrice.unitprice
                          ? (retailGroupPrice.unitprice * count).toFixed(2)
                          : null
                        : null;
                      const isSale = !!item.good.oldprice;
                      const isDiscount = !!item.good.unitprice_with_discount;
                      const isCustomerPrice = hasProductCustomerPrice(
                        item,
                        'good',
                      );
                      const discountPrice =
                        isDiscount && item.good.unitprice_with_discount
                          ? showWithVat
                            ? (
                                (item.good.unitprice_with_discount || 0) * count
                              ).toFixed(2)
                            : (
                                (item.good
                                  .unitprice_with_discount_without_vat || 0) *
                                count
                              ).toFixed(2)
                          : 0;

                      const isDemand = (item?.product as any)?.on_demand;

                      return (
                        <Row key={i}>
                          <ThumbCol>
                            <Thumb
                              src={
                                item.product.picture !== null
                                  ? item.product.picture
                                  : null
                              }
                              placeholder={placeholder}
                              retinaPlaceholder={placeholder2x}
                              w={46}
                              h={46}
                              notLazyloading={true}
                            />
                          </ThumbCol>
                          <InfoCol>
                            <Name>{item.product.name}</Name>
                            <Amount>
                              {item.count}&nbsp;{'ks'}
                            </Amount>
                          </InfoCol>
                          {!isDemand && (
                            <PriceCol>
                              {isCustomerPrice ? (
                                <PriceSaleOldWrapper>
                                  <PriceOld>
                                    {oldPrice}
                                    &nbsp;
                                    {'EUR'}
                                  </PriceOld>
                                  <PriceDiscount>
                                    {price}
                                    &nbsp;
                                    {'EUR'}
                                  </PriceDiscount>
                                </PriceSaleOldWrapper>
                              ) : isSale ? (
                                <PriceSaleOldWrapper>
                                  <PriceOld>
                                    {oldPrice}
                                    &nbsp;
                                    {'EUR'}
                                  </PriceOld>
                                  <PriceSale>
                                    {price}
                                    &nbsp;
                                    {'EUR'}
                                  </PriceSale>
                                </PriceSaleOldWrapper>
                              ) : isDiscountInCart(cart, user) && isDiscount ? (
                                <PriceSaleOldWrapper>
                                  <PriceOld>
                                    {price}
                                    &nbsp;
                                    {'EUR'}
                                  </PriceOld>
                                  <PriceDiscount>
                                    {discountPrice}
                                    &nbsp;
                                    {'EUR'}
                                  </PriceDiscount>
                                </PriceSaleOldWrapper>
                              ) : isWarehouse && retailPrice ? (
                                <PriceSaleOldWrapper>
                                  <PriceOld>
                                    {retailPrice}
                                    &nbsp;
                                    {'EUR'}
                                  </PriceOld>
                                  <PriceDiscount>
                                    {price}
                                    &nbsp;
                                    {'EUR'}
                                  </PriceDiscount>
                                </PriceSaleOldWrapper>
                              ) : (
                                <Price>
                                  {price}
                                  &nbsp;
                                  {'EUR'}
                                </Price>
                              )}
                            </PriceCol>
                          )}
                        </Row>
                      );
                    })}
                  </div>
                </SummaryItems>
              </SummaryItemsWrapper>
            </ItemsWrapper>
          ) : (
            <Placeholder>{__('Nákupný košík je prázdny')}</Placeholder>
          )}
          {cart && cart.items && cart.items.length > 0 && (
            <React.Fragment>
              <Separator />
              <Footer>
                <GoToCartButton
                  onClick={this.redirectToCart}
                  className="primary lg"
                >
                  {isOnlyDemand
                    ? __('Dokončiť kalkuláciu')
                    : __('Prejsť do košíka')}
                </GoToCartButton>
              </Footer>
            </React.Fragment>
          )}
        </Loader>
      </Wrapper>
    );
  }
}

export default withRouter(MiniCart);
