import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { InputDefaultStyles, InputStyles } from './FormStyles';

const TextareaDefault = styled.textarea`
  ${InputStyles};
  ${InputDefaultStyles};
  height: auto;
`;

export const TextareaStyled = styled(TextareaDefault)`
  background: ${({ theme }) => theme.form.background.default};
  border: ${({ theme }) => rem(theme.form.border.width)}
    ${({ theme }) => theme.form.border.style}
    ${({ theme }) => theme.form.border.color};
  border-radius: ${({ theme }) => rem(theme.form.border.radius)};
  color: ${({ theme }) => theme.form.color.default};
  padding: ${({ theme }) => rem(theme.form.padding.horizontal)}
    ${({ theme }) => rem(theme.form.padding.vertical)};
  &:focus {
    border-color: ${({ theme }) => theme.form.border.colorFocus};
  }
  &:disabled {
    background: ${({ theme }) => theme.form.background.disabled};
    color: ${({ theme }) => theme.form.color.disabled};
  }
`;

export const TextareaStyledBlock = styled(TextareaStyled)`
  display: block;
  width: 100% !important; /* zabráni zmene šírky pri zmene veľkosti v prehliadači */
`;

interface Props {
  disabled?: boolean;
  readonly?: boolean;
  children: any;
  className?: string;
  value?: string;
}

export class Textarea extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { disabled, children, className, readonly, value } = this.props;
    return (
      <TextareaDefault
        disabled={disabled}
        readOnly={readonly}
        className={className}
        value={value}
      >
        {children}
      </TextareaDefault>
    );
  }
}
