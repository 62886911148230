import styled from 'styled-components';
import * as React from 'react';
import { pdfjs, Document, Page as ReactPdfPage } from 'react-pdf';
import HTMLFlipBook from 'react-pageflip';
import { __ } from 'react-i18n';
import {
  Button,
  ButtonStyledStyles,
  ButtonStyles,
} from '../_helpers/form/Button';
import { rem } from 'polished';
import { Media } from '../../theme/media';

interface FlipBookProps {
  fileName: string;
  pages: string;
  contentId: number;
}

const CustomFlipBook: any = styled(HTMLFlipBook)`
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 50%);
`;

const CustomButton = styled(Button)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
`;

const NextButton = styled(CustomButton)``;

const PrevButton = styled(CustomButton)`
  margin-right: ${rem(20)};
`;

const ButtonsWrapper = styled.div`
  margin-top: ${rem(36)};
  text-align: center;
  ${({ theme }) => theme.media('md')} {
    margin-top: ${rem(12)};
  }
`;

const FlipBook = (props: FlipBookProps) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const width = 530;
  let flipBook: any = null;

  const Page = React.forwardRef(({ pageNumber }: any, ref: any) => {
    return (
      <div ref={ref}>
        <ReactPdfPage pageNumber={pageNumber} width={width} />
      </div>
    );
  });

  const nextButtonClick = () => {
    if (flipBook && flipBook.pageFlip()) {
      flipBook.pageFlip().flipNext();
    }
  };

  const prevButtonClick = () => {
    if (flipBook && flipBook.pageFlip()) {
      flipBook.pageFlip().flipPrev();
    }
  };

  return (
    <div>
      <Document
        file={`https://www.daffer.sk/api/v1/file?query=media/${props.contentId}/${props.fileName}`}
      >
        <CustomFlipBook
          width={550}
          height={550}
          size="stretch"
          minWidth={315}
          maxWidth={1000}
          minHeight={400}
          maxHeight={1533}
          ref={(el) => (flipBook = el)}
        >
          {Array.from(Array(Number.parseInt(props.pages))).map(
            (pageNumber, index) => {
              return <Page pageNumber={index + 1} />;
            },
          )}
        </CustomFlipBook>
        <ButtonsWrapper>
          <PrevButton
            className="primary"
            type="button"
            onClick={prevButtonClick}
          >
            <Media lessThan="md">{__('Predchadzajúca')}</Media>
            <Media greaterThanOrEqual="md">{__('Predchadzajúca strana')}</Media>
          </PrevButton>
          {/*
            [<span>{currentPage}</span> z <span>{props.pages}</span>]
            */}
          <NextButton
            className="primary"
            type="button"
            onClick={nextButtonClick}
          >
            <Media lessThan="md">{__('Ďalšia')}</Media>
            <Media greaterThanOrEqual="md">{__('Ďalšia strana ')}</Media>
          </NextButton>
        </ButtonsWrapper>
      </Document>
    </div>
  );
};

export default FlipBook;
