import React from 'react';
import { rem } from 'polished';
import { ClearfixStyles } from '../../../theme/libraries/h5b-helpers';
import { ButtonDefault, ButtonStyledStyles } from '../form/Button';
import styled from 'styled-components';
import { push } from 'react-router-redux';
import { Link } from 'react-router';

const TabsItems = styled.ul`
  display: flex;
  list-style: none;
  margin: ${rem(10)} ${rem(-1)} 0;
  padding: 0;
  ${ClearfixStyles};
`;

const TabItem = styled.li`
  padding: 0 ${rem(1)};
  &.primary {
    padding-top: ${rem(2)};
  }
  &:not(.primary) {
    padding-bottom: ${rem(2)};
  }
`;

const Tab = styled(ButtonDefault)`
  ${ButtonStyledStyles};
  display: flex;
  border-color: ${({ theme }) => theme.color.gray93};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: 700;
  font-size: ${rem(13)};
  padding: ${rem(6)} ${rem(4)};
  width: 100%;
  background-size: unset;
  background: ${({ theme }) => theme.color.gray93};
  &.primary {
    background: ${(props) =>
      props.selectedBackgroundColor
        ? props.selectedBackgroundColor
        : ({ theme }) => theme.color.primary};
    color: ${(props) =>
      props.selectedTextColor
        ? props.selectedTextColor
        : ({ theme }) => theme.color.white};
    &:not(:disabled):hover {
      background: ${(props) =>
        props.selectedBackgroundColor
          ? props.selectedBackgroundColor
          : ({ theme }) => theme.color.primary};
    }
  }
  &:focus {
    box-shadow: none;
  }
  ${({ theme }) => theme.media('xs')} {
    padding: ${rem(12)} ${rem(6)};
  }
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(14)};
    padding: ${rem(12)} ${rem(24)};
  }
`;

const TabLink = styled(Link)`
  color: inherit;
  &:hover {
    text-decoration: none;
  }
`;

const TabHr = styled.hr`
  margin: 0;
  border: ${rem(1)} solid
    ${(props) =>
      props.selectedBackgroundColor
        ? props.selectedBackgroundColor
        : ({ theme }) => theme.color.primary}; ;
`;

type TabItemType = {
  name: string;
  url: string;
};

interface TabsProps {
  tabs: TabItemType[];
  currentPath: string;
  selectedBackgroundColor?: any;
  selectedTextColor?: any;
  dispatch: any;
}

const Tabs = (props: TabsProps) => {
  const {
    tabs,
    currentPath,
    dispatch,
    selectedBackgroundColor,
    selectedTextColor,
  } = props;

  const handleClick = (url: string) => dispatch(push(url));

  return (
    <>
      <TabsItems>
        {tabs.map(({ name, url }, index) => {
          const className = url === currentPath && 'primary';
          return (
            <TabLink to={url} key={index}>
              <TabItem className={className}>
                <Tab
                  className={className}
                  onClick={handleClick.bind(undefined, url)}
                  selectedBackgroundColor={selectedBackgroundColor}
                  selectedTextColor={selectedTextColor}
                >
                  {name}
                </Tab>
              </TabItem>
            </TabLink>
          );
        })}
      </TabsItems>
      <TabHr selectedBackgroundColor={selectedBackgroundColor} />
    </>
  );
};

export default Tabs;
