import * as React from 'react';
import { Helmet } from 'react-helmet';
import { checkMetaTag, prop } from '../../utilities';
import { connect } from 'react-redux';

interface Props {
  tags: any;
  metaTags: null;
}

class MetaTags extends React.PureComponent<Props, {}> {
  public render() {
    const { tags, metaTags } = this.props;

    function renderDescr() {
      if (tags?.description) {
        return (
          <meta name="description" content={checkMetaTag(tags.description)} />
        );
      }
      if (metaTags && prop(metaTags, 'description')) {
        return (
          <meta
            name="description"
            content={checkMetaTag(prop(metaTags, 'description'))}
          />
        );
      }
      return null;
    }

    function renderKeyWords() {
      if (tags?.keywords) {
        return <meta name="keywords" content={checkMetaTag(tags.keywords)} />;
      }
      if (metaTags && prop(metaTags, 'keywords')) {
        return (
          <meta
            name="keywords"
            content={checkMetaTag(prop(metaTags, 'keywords'))}
          />
        );
      }
      return null;
    }
    return (
      <React.Fragment>
        {tags !== {} && (
          <Helmet>
            {tags?.title && <title>{checkMetaTag(tags.title)}</title>}

            {renderDescr()}

            {renderKeyWords()}

            {tags?.og_title && (
              <meta property="og:title" content={checkMetaTag(tags.og_title)} />
            )}
            {tags?.og_type ? (
              <meta property="og:type" content={checkMetaTag(tags.og_type)} />
            ) : (
              <meta property="og:type" content="website" />
            )}

            {tags?.og_description && (
              <meta
                property="og:description"
                content={checkMetaTag(tags.og_description)}
              />
            )}
            {tags?.og_url && (
              <meta property="og:url" content={checkMetaTag(tags.og_url)} />
            )}
            {tags?.og_image && (
              <meta property="og:image" content={checkMetaTag(tags.og_image)} />
            )}
            {tags?.og_image && tags?.og_image_width && (
              <meta property="og:image:width" content={tags.og_image_width} />
            )}
            {tags?.og_image && tags?.og_image_height && (
              <meta property="og:image:height" content={tags.og_image_height} />
            )}
            {tags?.og_article_author && (
              <meta
                name="article:author"
                content={checkMetaTag(tags.og_article_author)}
              />
            )}
            {tags?.og_article_section && (
              <meta
                name="article:section"
                content={checkMetaTag(tags.og_article_section)}
              />
            )}
            {tags?.og_article_tag && (
              <meta
                name="article:tag"
                content={checkMetaTag(tags.og_article_tag)}
              />
            )}
            {tags?.robots && (
              <meta name="robots" content={checkMetaTag(tags.robots)} />
            )}

            <meta
              name="google-site-verification"
              content="mNYq_qNR8B69ajC4WRIABkYbHnP3V-pUUAhcZt5jEns"
            />
          </Helmet>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    metaTags: state.general.metaTags,
  };
};
export default connect(mapStateToProps)(MetaTags);
