const namespace = 'Registration';

export const SET_RETAIL_INTRO_LESS = `${namespace}/SET_RETAIL_INTRO_LESS`;
export const SET_RETAIL_INTRO_MORE = `${namespace}/SET_RETAIL_INTRO_MORE`;
export const SET_COMPANY_INTRO_LESS = `${namespace}/SET_COMPANY_INTRO_LESS`;
export const SET_COMPANY_INTRO_MORE = `${namespace}/SET_COMPANY_INTRO_MORE`;
export const SET_SCHOOL_INTRO_LESS = `${namespace}/SET_SCHOOL_INTRO_LESS`;
export const SET_SCHOOL_INTRO_MORE = `${namespace}/SET_SCHOOL_INTRO_MORE`;

export const SET_FINISHED_TEXT = `${namespace}/SET_FINISHED_TEXT`;

export const SET_HAS_CUSTOMER_CARD_DETECT = `${namespace}/SET_HAS_CUSTOMER_CARD_DETECT`;
