import styled from 'styled-components';
import { rem } from 'polished';
import { Col } from '../../theme/libraries/grid';
import { Heading2 } from '../_helpers/Heading/Heading';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import { InputStyled } from '../_helpers/form/Input';
import { Label } from '../_helpers/form/Label';

export const CartHeading = styled(Heading2)`
  margin: ${rem(30)} 0;
`;

export const CartMainCol = styled(Col)`
  ${({ theme }) => theme.media('sm')} {
    width: ${({ theme }) => theme.grid.col.col7};
  }
  ${({ theme }) => theme.media('md')} {
    width: ${({ theme }) => theme.grid.col.col8};
  }
`;

export const CartSummaryCol = styled(Col)`
  margin-top: ${rem(10)};
  ${({ theme }) => theme.media('sm')} {
    width: ${({ theme }) => theme.grid.col.col5};
    margin-top: 0;
  }
  ${({ theme }) => theme.media('md')} {
    width: ${({ theme }) => theme.grid.col.col4};
  }
`;

export const CartFormRow = styled(Clearfix)`
  margin-right: ${rem(-10)};
  margin-left: ${rem(-10)};
  margin-bottom: ${rem(10)};
`;

export const CartInput: any = styled(InputStyled)`
  display: block;
  width: 100%;

  ${(props: any) => (props.error ? 'border-color: red;' : '')}
  ${(props: any) =>
    props.disabled
      ? 'border-color: #eeeeee; color: #b1b1b1 !important; background: white !important;'
      : ''}
`;

export const CartInputWrapper = styled.div`
  position: relative;
`;

export const CartFormCol = styled(Col)`
  padding-right: ${rem(10)};
  padding-left: ${rem(10)};
  ${({ theme }) => theme.media('xs')} {
    width: ${({ theme }) => theme.grid.col.col6};
    &.full-width {
      width: ${({ theme }) => theme.grid.col.col12};
    }
  }
`;

export const CartLabel = styled(Label)`
  display: block;
`;
