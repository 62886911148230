export class ContentService {
  public static getImage(image: string | null | undefined) {
    if (image) {
      return process.env.REACT_APP_API_BASE_URL + '/image/' + image;
    }
    return undefined;
  }

  public static getGalleryImage(image: string | null | undefined) {
    if (image) {
      return process.env.REACT_APP_API_BASE_URL + '/image?query=' + image;
    }
    return undefined;
  }

  public static getAppRelativeUrl(absoluteUrl: string | null | undefined) {
    if (absoluteUrl) {
      return process.env.REACT_APP_URL + '/' + absoluteUrl;
    }
    return undefined;
  }

  public static getImageWithParams(image: string | null | undefined, params) {
    if (image) {
      return (
        process.env.REACT_APP_API_BASE_URL +
        '/image/' +
        image +
        '?' +
        params.join('&')
      );
    }
    return undefined;
  }

  public static getDocument(
    documentUrl: string | null | undefined,
    path = 'media',
  ) {
    if (documentUrl) {
      return (
        process.env.REACT_APP_API_BASE_URL + '/file/' + path + '/' + documentUrl
      );
    }

    return '';
  }

  public static getFileAttachment(
    fileUrl: string | null | undefined,
    token: string | null = null,
  ) {
    if (!fileUrl) {
      return '';
    }

    let url =
      process.env.REACT_APP_API_BASE_URL + '/file-attachment?query=' + fileUrl;
    if (token) {
      url += '&token=' + token;
    }

    return url;
  }
}
