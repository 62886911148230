const namespace = 'Home';

export type CMSPreviewsData = CMSPreview[];

export interface CMSPreview {
  image: string;
  imageMobile: string;
  name: string;
  nameMobile?: string;
  description: string;
  items: string[];
  buttonText: string;
  buttonLink: string;
  bgColor: string;
}

export const SET_UNI_SETS = `${namespace}/SET_UNI_SETS`;
export const SET_UNI_SETS_SPECIAL = `${namespace}/SET_UNI_SETS_SPECIAL`;
export const SET_COOPERATIONS = `${namespace}/SET_COOPERATIONS`;
export const SET_SKVELKO_LOADER = `${namespace}/SET_SKVELKO_LOADER`;
export const SET_SKVELKO_SETS_LOADER = `${namespace}/SET_SKVELKO_SETS_LOADER`;
export const SET_SKVELKO_CITIES = `${namespace}/SET_SKVELKO_CITIES`;
export const SET_SKVELKO_SCHOOLS_BY_CITY = `${namespace}/SET_SKVELKO_SCHOOLS_BY_CITY`;
export const SET_SKVELKO_SETS_BY_SCHOOL = `${namespace}/SET_SKVELKO_SETS_BY_SCHOOL`;
export const SET_SKVELKO_CLASSES_BY_SCHOOL = `${namespace}/SET_SKVELKO_CLASSES_BY_SCHOOL`;
export const SET_SKVELKO_SET = `${namespace}/SET_SKVELKO_SET`;

export const SET_SELECTED_CITY_NAME = `${namespace}/SET_SELECTED_CITY_NAME`;
export const SET_SELECTED_SCHOOL_ID = `${namespace}/SET_SELECTED_SCHOOL_ID`;
export const SET_SELECTED_SKVELKO_SET_ID = `${namespace}/SET_SELECTED_SKVELKO_SET_ID`;
export const SET_SELECTED_CLASS_ID = `${namespace}/SET_SELECTED_CLASS_ID`;

export const SET_SCHOOL_IS_SELECTED = `${namespace}/SET_SCHOOL_IS_SELECTED`;
export const SET_CITY_IS_SELECTED = `${namespace}/SET_CITY_IS_SELECTED`;

export const SET_CURRENT_FILTER_TITLE = `${namespace}/SET_CURRENT_FILTER_TITLE`;
export const SET_CURRENT_SCHOOL_NAME = `${namespace}/SET_CURRENT_SCHOOL_NAME`;

export const SET_CURRENT_SET = `${namespace}/SET_CURRENT_SET`;
export const SET_CURRENT_CLASS_ID = `${namespace}/SET_CURRENT_CLASS_ID`;

export const SET_NEWSLETTER_LOADER = `${namespace}/SET_NEWSLETTER_LOADER`;
export const SET_NEWSLETTER_RESPONSE = `${namespace}/SET_NEWSLETTER_RESPONSE`;

export const SET_SET_POPUP_TEXT = `${namespace}/SET_SET_POPUP_TEXT`;
export const SET_WARNING_REMOVE_FROM_SET_TEXT = `${namespace}/SET_WARNING_REMOVE_FROM_SET_TEXT`;
