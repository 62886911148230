import { __ } from 'react-i18n';

export const SORT = [
  {
    name: __('Podľa obľúbenosti'),
    sort: 'favorite_sale_count',
    sortDir: 'desc',
  },
  {
    name: __('Podľa relevancie'),
    sort: 'relevance',
    sortDir: 'asc',
  },
  {
    name: __('Od najlacnejšieho'),
    sort: 'price',
    sortDir: 'asc',
  },
  {
    name: __('Od najdrahšieho'),
    sort: 'price',
    sortDir: 'desc',
  },
  {
    name: __('Podľa abecedy A-Z'),
    sort: 'alphabetically',
    sortDir: 'asc',
  },
  {
    name: __('Podľa abecedy Z-A'),
    sort: 'alphabetically',
    sortDir: 'desc',
  },
];

export const SORT_CATEGORY = [
  {
    name: __('Podľa obľúbenosti'),
    sort: 'favorite_sale_count',
    sortDir: 'desc',
  },
  {
    name: __('Od najlacnejšieho'),
    sort: 'price',
    sortDir: 'asc',
  },
  {
    name: __('Od najdrahšieho'),
    sort: 'price',
    sortDir: 'desc',
  },
  {
    name: __('Podľa abecedy A-Z'),
    sort: 'alphabetically',
    sortDir: 'asc',
  },
  {
    name: __('Podľa abecedy Z-A'),
    sort: 'alphabetically',
    sortDir: 'desc',
  },
];

export const DEFAULT_FILTER_TYPES = {
  filterTypes: [
    { name: 'page', urlName: 'page', defaultValue: '1' },
    { name: 'sort', urlName: 'sort', defaultValue: 'favorite_sale_count' },
    { name: 'sortDir', urlName: 'sort-dir', defaultValue: 'desc' },
    { name: 'q', urlName: 'q', defaultValue: '' },
  ],
};

export const MOST_PURCHASED_DEFAULT_FILTER_TYPES = {
  filterTypes: [
    { name: 'page', urlName: 'page', defaultValue: '1' },
    { name: 'sort', urlName: 'sort', defaultValue: 'sale_count' },
    { name: 'sortDir', urlName: 'sort-dir', defaultValue: 'desc' },
    { name: 'q', urlName: 'q', defaultValue: '' },
  ],
};

export const DEFAULT_PRODUCT_FILTER_TYPES_SEARCH = {
  filterTypes: [
    { name: 'page', urlName: 'page', defaultValue: '1' },
    { name: 'sort', urlName: 'sort', defaultValue: 'sale_count' },
    { name: 'sortDir', urlName: 'sort-dir', defaultValue: 'desc' },
    { name: 'q', urlName: 'q', defaultValue: '', isImmutable: true },
    { name: 'section', urlName: 'section', defaultValue: '0' },
  ],
};

export const DEFAULT_WEBCONTENT_FILTER_TYPES_SEARCH = {
  filterTypes: [
    { name: 'page', urlName: 'page', defaultValue: '1' },
    { name: 'q', urlName: 'q', defaultValue: '', isImmutable: true },
    { name: 'section', urlName: 'section', defaultValue: '1' },
  ],
};

export const DEFAULT_DEMAND_PRODUCT_FILTER_TYPES_SEARCH = {
  filterTypes: [
    { name: 'page', urlName: 'page', defaultValue: '1' },
    { name: 'sort', urlName: 'sort', defaultValue: 'sale_count' },
    { name: 'sortDir', urlName: 'sort-dir', defaultValue: 'desc' },
    { name: 'q', urlName: 'q', defaultValue: '', isImmutable: true },
    { name: 'section', urlName: 'section', defaultValue: '2' },
  ],
};

export const onSortChangeDefault = (addFilter, clickedSort, clickedSortDir) => {
  addFilter({ name: 'page', value: '1' });
  addFilter({ name: 'sort', value: clickedSort });
  addFilter({ name: 'sortDir', value: clickedSortDir });
};

export const onPageChangeDefault = (addFilter, page) => {
  addFilter({ name: 'page', value: String(page) });
};

export const defaultSearchParameters = (filters, limit) => {
  const offset =
    (parseInt(filters.page, 10) - 1) * limit
      ? (parseInt(filters.page, 10) - 1) * limit
      : 0;

  let parameters: any = {
    availabilityId: 1,
    q: filters.q,
    limit,
    offset,
    withAttribs: '1',
    withPublish: 1,
  };

  if (filters.sort !== 'relevance') {
    parameters = {
      ...parameters,
      sort: filters.sort,
      sortDir: filters.sortDir,
    };
  }

  return parameters;
};
