import { css } from 'styled-components';
import { rem } from 'polished';

export const PerfectScrollbarStyles = css`
  width: 100%;
  padding-right: ${rem(8)};
  position: relative;
  overflow: hidden;
  &:hover {
    .ps__rail-y {
      width: ${rem(8)};
    }
  }
  &.scrollable {
    
    &.ps--active-y {
      .ps__rail-y {
        display: block;
      }
    }
  }
  .ps__rail-y {
    display: none;
    background: ${({ theme }) => theme.color.gray93};
    border-radius: ${rem(2)};
    width: ${rem(4)};
    height: 100%;
    position: absolute;
    right: 0;
    ${({ theme }) => theme.transition('width')};
  }
  .ps__thumb-y {
    background: ${({ theme }) => theme.color.primary};
    border-radius: ${rem(4)}
    width: 100%;
    position: absolute;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
`;
