import * as React from 'react';
import { browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import configureStore from './configureStore';
import Routes from './routes';
import { getProp, prop } from './utilities/';
// import { LanguageProvider } from 'react-i18n';
// import { loadTranslations } from './configureTrans';
import API from './services/API';
import { isEdge, browserVersion } from 'react-device-detect';
// import { FullscreenLoading } from './components/_helpers/FullscreenLoading/FullscreenLoading';
import { hydrate } from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { __ } from 'react-i18n';

declare global {
  interface Window {
    dataLayer: any;
    __INIT_DATA__: any;
    __REDUX_STATE__: any;
  }
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [
    new Integrations.BrowserTracing(),
    //     {
    //   routingInstrumentation: Sentry.reactRouterV3Instrumentation(
    //       browserHistory,
    //       createRoutes(Routes),
    //       match
    //   ),
    // }
  ],
  // normalizeDepth: 10,
  tracesSampleRate: 1.0,
  debug: false,
});

API.setBaseUrl(process.env.REACT_APP_API_BASE_URL || '');

let store;
if (typeof window !== 'undefined') {
  const initialData = getProp(window, '__INIT_DATA__');
  if (!initialData || initialData === '{{INIT_DATA}}') {
    window.__INIT_DATA__ = {};
  }

  const reduxState = getProp(window, '__REDUX_STATE__');
  if (!reduxState || reduxState === '{{SSR_INITIAL_STATE}}') {
    window.__REDUX_STATE__ = {};
  }
  store = configureStore(window.__REDUX_STATE__);
  const historyObj = syncHistoryWithStore(browserHistory, store);

  // const loadTranslationsData = () => loadTranslations('');

  if (!(isEdge && browserVersion === '18')) {
    if (window.__REDUX_STATE__) {
      API.setToken(prop(window.__REDUX_STATE__, 'auth.token', undefined));
    }

    const onError = (error, errorInfo) => {
      /*
      try {
        const report = {
          windowLocation: window?.location?.toString(),
          navigatorVersion: navigator?.appVersion,
          outerWidth: window?.outerWidth,
          errorStack: error?.stack,
          errorComponentStack: errorInfo,
        };
        API.reportError(
          {},
          {
            report: JSON.stringify(report).replace(/<[^>]*>?/gm, ''),
            cartId: cookie?.load(CART_COOKIE_ID),
          },
        ).then();
      } catch (e) {
        console.error(e.toString());
      }
      */
    };

    const errorFallback = () => (
      <React.Fragment>
        {__(
          'Ospravedlňujeme sa. Nastala chyba v systéme. Kontaktujte prosím technickú podporu Daffer - 0903/85 46 47',
        )}
      </React.Fragment>
    );

    hydrate(
      <ThemeProvider theme={theme}>
        {/*<LanguageProvider*/}
        {/*  translations={window.__INIT_DATA__.translations}*/}
        {/*  loadCatalogues={loadTranslationsData}*/}
        {/*  loadingContainer={<FullscreenLoading />}*/}
        {/*>*/}
        <Sentry.ErrorBoundary fallback={errorFallback} onError={onError}>
          <Provider store={store}>
            <Routes history={historyObj} />
          </Provider>
        </Sentry.ErrorBoundary>
        {/*</LanguageProvider>*/}
      </ThemeProvider>,
      document.getElementById('root'),
    );
  }
}

export { store };
