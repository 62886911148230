import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';

const ModalDiv = styled(BaseModal)`
  width: ${rem(760)};
`;

const DpdTable = styled.table`
  display: block;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  ${({ theme }) => theme.media('md')} {
    display: table;
  }
`;

const DpdTr = styled.tr``;
const DpdTableLabel = styled.td`
  width: 80px;
  font-weight: bold;
`;

const DpdTableText = styled.td``;

const Title = styled.div`
  display: block;
  font-size: ${rem(25)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.gray89};
  margin-bottom: 10px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

interface ModalProps {
  packetaDeliveryPoint: any;
  onCloseButtonClick?: (event) => void;
}

export function formatTime(time: string): string {
  const newTime = time.match(/.{2}/g);
  return newTime ? newTime.join(':') : '';
}

class PacketaDeliveryPointModal extends React.PureComponent<ModalProps> {
  public render() {
    const fullAddress =
      this.props.packetaDeliveryPoint.place +
      ', ' +
      this.props.packetaDeliveryPoint.street +
      ' ';
    // @ts-ignore
    return (
      <ModalDiv
        title={fullAddress}
        body={
          <div>
            <Section>
              <Title>{__('Adresa')}</Title>
              <DpdTable>
                <DpdTr>
                  <DpdTableLabel>{__('Názov')}</DpdTableLabel>
                  <DpdTableText>
                    {this.props.packetaDeliveryPoint.name}
                  </DpdTableText>
                </DpdTr>
                <DpdTr>
                  <DpdTableLabel>{__('Ulica')}</DpdTableLabel>
                  <DpdTableText>
                    {this.props.packetaDeliveryPoint.street}{' '}
                    {this.props.packetaDeliveryPoint.houseno}
                  </DpdTableText>
                </DpdTr>
                <DpdTr>
                  <DpdTableLabel>{__('Mesto')}</DpdTableLabel>
                  <DpdTableText>
                    {this.props.packetaDeliveryPoint.zip}{' '}
                    {this.props.packetaDeliveryPoint.city}
                  </DpdTableText>
                </DpdTr>
              </DpdTable>
            </Section>
            <Section>
              <Title>{__('Otváracie hodiny')}</Title>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.packetaDeliveryPoint.openingHours
                    .compactShort,
                }}
              />
            </Section>
          </div>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default PacketaDeliveryPointModal;
