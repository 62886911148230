import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import ContentContainer from '../../components/_layout/Page/ContentContainer';
import { __ } from 'react-i18n';
import { ContentContainerBlock } from '../../components/_helpers/Contact/ContactStyles';
import styled, { css } from 'styled-components';
import { InputStyled } from '../../components/_helpers/form/Input';
import { rem } from 'polished';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { Form, Field, FormSpy } from 'react-final-form';
import Alert from '../../components/Alert/Alert';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import API from '../../services/API';
import {
  composeValidators,
  phone,
  postalCode,
  required,
} from '../../utilities/validators';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { scrollToError } from '../../utilities';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';
import { parsePostalCode } from '../../utilities/formParsers';

const LostCardLabel = styled.div``;

const LostCardInputCss: any = css`
  display: block;
  width: 100%;
  ${({ theme }) => theme.media('xs')} {
    max-width: ${rem(290)};
  }

  ${(props: any) => {
    return props.error ? 'border:1px solid red;' : '';
  }}
`;

const LostCardInput: any = styled(InputStyled)`
  ${LostCardInputCss};
`;

const LostCardInputEmail: any = styled(EmailAutocomplete)`
  ${LostCardInputCss};
`;

const LostCardRow = styled(Clearfix)`
  margin-right: ${rem(-5)};
  margin-bottom: ${rem(10)};

  ${({ theme }) => theme.media('xs')} {
    &.email {
      width: ${rem(290)};
    }
  }
`;

const Submit = styled(ButtonStyled)`
  display: block;
  width: ${rem(290)};
  margin-top: ${rem(20)};
`;

const LostCardForm = styled.form`
  width: ${rem(700)};
  max-width: 100%;
`;

interface LostCardFormProps {}

interface LostCardFormState {
  emailStatus: number;
  sendingEmail: boolean;
}

class LostCustomerCardForm extends React.Component<
  LostCardFormProps,
  LostCardFormState
> {
  constructor(props) {
    super(props);
    this.state = {
      emailStatus: 0,
      sendingEmail: false,
    };
  }

  public onSubmit = async (values) => {
    const copyValues = JSON.parse(JSON.stringify(values));

    this.setState({ sendingEmail: true });

    try {
      await API.lostCustomerCardEmail(
        {
          xAcceptLanguage: 'sk',
        },
        copyValues,
      );

      this.setState({ emailStatus: 1 });
    } catch (e) {
      this.setState({ emailStatus: -1 });
    }
    this.setState({ sendingEmail: false });
  };

  public render() {
    return (
      <ContentContainer>
        <Loader loading={this.state.sendingEmail}>
          <ContentContainerBlock>
            {this.state.emailStatus === 1 && (
              <Alert type="success">
                {' '}
                {__(
                  'Ďakujeme za vyplnenie formulára, budeme Vás kontaktovať.',
                )}{' '}
              </Alert>
            )}

            {this.state.emailStatus === -1 && (
              <Alert type="error">
                {' '}
                {__('Žiadosť sa nepodarilo odoslať')}{' '}
              </Alert>
            )}

            {this.state.emailStatus !== 1 && (
              <Form
                onSubmit={this.onSubmit}
                // tslint:disable-next-line:jsx-no-lambda
                render={({ handleSubmit }) => (
                  <LostCardForm onSubmit={handleSubmit}>
                    <Field name="name" validate={required}>
                      {({ input, meta }) => (
                        <LostCardRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <LostCardLabel>
                            {__('Meno a priezvisko*')}
                          </LostCardLabel>
                          <LostCardInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </LostCardRow>
                      )}
                    </Field>

                    <Field name="street" validate={required}>
                      {({ input, meta }) => (
                        <LostCardRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <LostCardLabel>{__('Ulica*')}</LostCardLabel>
                          <LostCardInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </LostCardRow>
                      )}
                    </Field>

                    <Field name="city" validate={required}>
                      {({ input, meta }) => (
                        <LostCardRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <LostCardLabel>{__('Mesto*')}</LostCardLabel>
                          <LostCardInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </LostCardRow>
                      )}
                    </Field>

                    <Field
                      name="zip"
                      validate={composeValidators(required, postalCode)}
                      parse={parsePostalCode}
                    >
                      {({ input, meta }) => (
                        <LostCardRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <LostCardLabel>{__('PSČ*')}</LostCardLabel>
                          <LostCardInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </LostCardRow>
                      )}
                    </Field>

                    <Field name="phone" validate={phone}>
                      {({ input, meta }) => (
                        <LostCardRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <LostCardLabel>{__('Telefónne číslo')}</LostCardLabel>
                          <LostCardInput
                            {...input}
                            type="tel"
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </LostCardRow>
                      )}
                    </Field>

                    <Field name="email" validate={required}>
                      {({ input, meta }) => (
                        <LostCardRow
                          className={
                            meta.error && meta.touched ? 'error email' : 'email'
                          }
                        >
                          <LostCardLabel>{__('Váš email*')}</LostCardLabel>
                          <LostCardInputEmail
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </LostCardRow>
                      )}
                    </Field>

                    <LostCardRow>
                      <Submit type="submit" className="primary">
                        {__('Odoslať')}
                      </Submit>
                    </LostCardRow>

                    <FormSpy
                      subscription={{ submitFailed: true, modified: false }}
                      onChange={() => {
                        scrollToError();
                      }}
                    />
                  </LostCardForm>
                )}
              />
            )}
          </ContentContainerBlock>
        </Loader>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'LostCustomerCardForm' })(LostCustomerCardForm),
);
