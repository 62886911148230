import React from 'react';
import styled from 'styled-components';
import { normalizeString } from '../../../../utilities';
import { CategoryTextStyle } from './styles';

const Text = styled.span`
  ${CategoryTextStyle};
  white-space: pre-wrap;
  &.highlight {
    color: ${({ theme }) => theme.color.primary};
  }
`;

interface HighLightedTextProps {
  text: string;
  highlight: string;
}

const HighLightedText = (props: HighLightedTextProps) => {
  const { text, highlight } = props;

  const normalizedHighlight = normalizeString(highlight);

  return (
    <>
      {highlight !== '' &&
        text
          .split(new RegExp(`(${highlight.replace(/[^a-z0-9-]/g, '')})`, 'gi'))
          .map((part, i) => (
            <Text
              key={i}
              className={
                normalizeString(part) === normalizedHighlight && 'highlight'
              }
            >
              {part}
            </Text>
          ))}
    </>
  );
};

export default HighLightedText;
