import * as React from 'react';
import styled from 'styled-components';
import BaseModal from './BaseModal';
import Swiper from '../Swiper/Swiper';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import { rem } from 'polished';

const StyledSwiper = styled(Swiper)`
  .swiper-button-next,
  .swiper-button-prev {
    &:before,
    &:after {
      background: ${({ theme }) => theme.color.text};
    }
  }
`;

const Image = styled(ImagePlaceholder)`
  display: block;
  margin: auto;
`;

const BaseModalStyled = styled(BaseModal)`
  width: ${rem(800)};
  min-height: ${rem(60)};
`;

interface ModalProps {
  pictures: string[];
  activeIndex?: number | null;
  onCloseButtonClick: () => void;
}

class GalleryModal extends React.PureComponent<ModalProps> {
  public mouseClickEvents = ['mousedown', 'click', 'mouseup'];
  public simulateMouseClick = (element) => {
    this.mouseClickEvents.forEach((mouseEventType) => {
      if (element) {
        element.dispatchEvent(
          new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
          }),
        );
      }
    });
  };

  public onKeyDown = (event) => {
    if (event.keyCode === 39) {
      // right
      const element = document.querySelector('div[class="swiper-button-next"]');
      this.simulateMouseClick(element);
    } else if (event.keyCode === 37) {
      // left
      const element = document.querySelector('div[class="swiper-button-prev"]');
      this.simulateMouseClick(element);
    } else if (event.keyCode === 27) {
      // esc
      this.props.onCloseButtonClick();
    }
  };

  public componentDidMount() {
    if (document) {
      document.addEventListener('keydown', this.onKeyDown, false);
    }
  }
  public componentWillUnmount() {
    if (document) {
      document.removeEventListener('keydown', this.onKeyDown, false);
    }
  }

  public render() {
    const { pictures, activeIndex } = this.props;

    const placeholder = '/images/placeholder-520.jpg';
    const placeholder2x = '/images/placeholder-520@2x.jpg';
    const swiperParams = {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      activeSlideKey: activeIndex ? activeIndex.toString() : null,
    };

    return (
      <BaseModalStyled
        body={
          <StyledSwiper params={swiperParams}>
            {pictures !== null &&
              pictures.length > 0 &&
              pictures.map((image, i) => {
                return (
                  <div key={i}>
                    <Image
                      src={image}
                      placeholder={placeholder}
                      retinaPlaceholder={placeholder2x}
                      w={1005}
                      h={1005}
                    />
                  </div>
                );
              })}
          </StyledSwiper>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default GalleryModal;
