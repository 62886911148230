import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { rem } from 'polished';
import styled from 'styled-components';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import { Col } from '../../theme/libraries/grid';
import { Label } from '../../components/_helpers/form/Label';
import { InputStyled } from '../../components/_helpers/form/Input';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import Alert from '../../components/Alert/Alert';
import API from '../../services/API';
import WarningModal from '../../components/_helpers/Modal/WarningModal';
import { push } from 'react-router-redux';
import { isEmailValid, prop } from '../../utilities';
import { PasswordIcon } from '../../components/Registration/styles';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import ConfirmModal from '../../components/_helpers/Modal/ConfirmModal';

export const MainBlock = styled.div`
  &.gray {
    background: ${({ theme }) => theme.color.gray97};
  }
  &.navigation {
    display: none;
    padding-bottom: 0;
  }
  &.border-top {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
  ${({ theme }) => theme.media('sm')} {
    padding: ${rem(40)};
    &.navigation {
      display: block;
    }
  }
`;

const RegRow = styled(Clearfix)`
  margin-right: ${rem(-5)};
  margin-left: ${rem(-5)};
`;

const RegCol = styled(Col)`
  padding-right: ${rem(5)};
  padding-left: ${rem(5)};
`;

const LabelCol = styled(RegCol)`
  ${({ theme }) => theme.media('xs')} {
    text-align: right;
    width: ${rem(85)};
    position: relative;
  }
`;

const InputCol = styled(RegCol)`
  ${({ theme }) => theme.media('xs')} {
    padding-left: ${rem(140)};
    margin-left: ${rem(-135)};
  }
`;

const RegistrationLabel = styled(Label)``;

const RegistrationInput: any = styled(InputStyled)`
  display: block;
  width: 100%;
  margin-bottom: ${rem(10)};
  ${({ theme }) => theme.media('xs')} {
    max-width: ${rem(290)};
  }

  ${(props: any) => {
    return props.error ? 'border:1px solid red;' : '';
  }}
`;

const ButtonWrapper = styled.div`
  margin-top: ${rem(20)};
`;

const ErrorMessage = styled(Alert)`
  margin-bottom: ${rem(5)};
`;

const ErrorsWrapper = styled.div`
  margin-bottom: ${rem(20)};
`;

const InputPasswordWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.media('xs')} {
    max-width: ${rem(290)};
  }
`;

interface NewSkvelkoAwardedUserProps {
  dispatch: (a: any) => void;
  setTitle: (title: string, link?: string | null) => void;
  currentPath: string;
  user: any;
}

interface NewSkvelkoAwardedUserState {
  name: string;
  surname: string;
  email: string;
  password: string;
  notifyUser: boolean;
  errors: {
    default: string | null;
    emailEmpty: boolean;
    emailInvalid: boolean;
    passwordEmpty: boolean;
    userAlreadyAssigned: boolean;
  };

  alreadyRegisteredModalOpen: boolean;
  successModalOpen: boolean;
  showPassword: boolean;
}

class NewSkvelkoAwardedUser extends React.PureComponent<
  NewSkvelkoAwardedUserProps,
  NewSkvelkoAwardedUserState
> {
  constructor(props) {
    super(props);
    const title = __('Nový používateľ');
    const backUrl = '/my-account/renumeration';
    props.setTitle(title, backUrl);
    props.setBreadcrumbsItems([
      { name: __('Používatelia účtu'), url: backUrl },
      { name: title, url: props.currentPath },
    ]);

    this.state = {
      name: '',
      surname: '',
      email: '',
      password: '',
      notifyUser: true,
      errors: {
        default: null,
        emailEmpty: false,
        emailInvalid: false,
        passwordEmpty: false,
        userAlreadyAssigned: false,
      },

      alreadyRegisteredModalOpen: false,
      successModalOpen: false,
      showPassword: false,
    };
  }

  public openModal(type: string | null) {
    let alreadyRegisteredModalOpen = false;
    let successModalOpen = false;

    switch (type) {
      case 'alreadyRegisteredModal':
        alreadyRegisteredModalOpen = true;
        break;
      case 'successModal':
        successModalOpen = true;
        break;
    }

    this.setState({
      alreadyRegisteredModalOpen,
      successModalOpen,
    });
  }

  public closeModal = () => {
    this.openModal(null);
  };

  public handlePasswordIcon = () => {
    this.setState((state) => ({
      showPassword: !state.showPassword,
    }));
  };

  public onInputChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case 'name':
        this.setState({ name: inputValue });
        break;
      case 'surname':
        this.setState({ surname: inputValue });
        break;
      case 'email':
        this.setState({ email: inputValue });
        break;
      case 'password':
        this.setState({ password: inputValue });
        break;
      case 'notify_user':
        this.setState({ notifyUser: !this.state.notifyUser });
        break;
    }
  };

  public validateForm() {
    let isValid = true;

    const errors = this.state.errors;

    if (!this.state.email) {
      errors.emailEmpty = true;
      isValid = false;
    } else {
      errors.emailEmpty = false;
      if (isEmailValid(this.state.email)) {
        errors.emailInvalid = false;
      } else {
        errors.emailInvalid = true;
        isValid = false;
      }
    }

    if (!this.state.password) {
      errors.passwordEmpty = true;
      isValid = false;
    } else {
      errors.passwordEmpty = false;
    }

    errors.userAlreadyAssigned = false;

    this.setState({
      errors,
    });

    return isValid;
  }

  public getStringErrors() {
    const errors: string[] = [];

    if (this.state.errors.emailEmpty) {
      errors.push(__('Email je povinný údaj'));
    }

    if (this.state.errors.emailInvalid) {
      errors.push(__('Email nie je v správnom tvare'));
    }

    if (this.state.errors.passwordEmpty) {
      errors.push(__('Heslo je povinný údaj'));
    }

    if (this.state.errors.userAlreadyAssigned) {
      errors.push('Tento používateľ už je priradený');
    }

    if (this.state.errors.default) {
      errors.push(this.state.errors.default);
    }

    return errors;
  }

  public onAddUserClick = async (event) => {
    const isValid = this.validateForm();
    if (isValid) {
      const createAwardedUserResponse: any = await API.createSkvelkoAwardedUser(
        {},
        {
          name: this.state.name,
          surname: this.state.surname,
          email: this.state.email,
          password: this.state.password,
          notify_user: this.state.notifyUser,
        },
      );

      if (createAwardedUserResponse.status === 'USER_ALREADY_REGISTERED') {
        this.openModal('alreadyRegisteredModal');
      }
      if (createAwardedUserResponse.status === 'USER_ALREADY_ASSIGNED') {
        this.setState({
          errors: { ...this.state.errors, userAlreadyAssigned: true },
        });
      } else if (createAwardedUserResponse.status === 'OK') {
        this.openModal('successModal');
      }
    }

    this.forceUpdate();
  };

  public onAssignUserClick = async (event) => {
    const assignUserResponse: any = await API.assignSkvelkoAwardedUser(
      {},
      {
        email: this.state.email,
      },
    );
    if (assignUserResponse.status === 'USER_ALREADY_REGISTERED') {
      this.setState({
        errors: { ...this.state.errors, userAlreadyAssigned: true },
      });
    } else if (assignUserResponse.status === 'OK') {
      this.openModal('successModal');
    }
  };

  public onSuccessButtonClick = (event) => {
    this.props.dispatch(push('/my-account/renumeration'));
  };

  public render() {
    const errors = this.getStringErrors();

    return (
      <MainBlock className={'no-top-padding'}>
        {/*----------- MODALS ---------------*/}
        {this.state.alreadyRegisteredModalOpen && (
          <ConfirmModal
            title={__('Užívateľ už existuje')}
            bodyText={__('Užívateľ s daným emailom už existuje')}
            buttonText={__('Ok')}
            onCloseButtonClick={this.closeModal}
            onButtonClick={this.closeModal}
          />
        )}

        {this.state.successModalOpen && (
          <WarningModal
            title={__('Užívateľ bol priradený')}
            bodyText={__('Užívateľ bol priradený')}
            onButtonClick={this.onSuccessButtonClick}
            buttonText={__('OK')}
            onCloseButtonClick={this.closeModal}
          />
        )}
        {/*----------------------------------*/}

        {errors.length > 0 && (
          <ErrorsWrapper>
            {errors.map((error, i) => {
              return (
                <ErrorMessage key={i} type="error">
                  {error}
                </ErrorMessage>
              );
            })}
          </ErrorsWrapper>
        )}

        <RegRow>
          <LabelCol>
            <RegistrationLabel>{__('Meno')}:</RegistrationLabel>
          </LabelCol>
          <InputCol>
            <RegistrationInput
              type="text"
              name="name"
              placeholder={`${__('Meno')}`}
              onChange={this.onInputChange}
            />
          </InputCol>
        </RegRow>

        <RegRow>
          <LabelCol>
            <RegistrationLabel>{__('Priezvisko')}:</RegistrationLabel>
          </LabelCol>
          <InputCol>
            <RegistrationInput
              type="text"
              name="surname"
              placeholder={`${__('Priezvisko')}`}
              onChange={this.onInputChange}
            />
          </InputCol>
        </RegRow>

        <RegRow>
          <LabelCol>
            <RegistrationLabel>{__('E-mail')}:</RegistrationLabel>
          </LabelCol>
          <InputCol>
            <RegistrationInput
              type="email"
              name="email"
              placeholder={`${__('E-mail')}`}
              onChange={this.onInputChange}
            />
          </InputCol>
        </RegRow>

        <RegRow>
          <LabelCol>
            <RegistrationLabel>{__('Heslo')}:</RegistrationLabel>
          </LabelCol>
          <InputCol>
            <InputPasswordWrapper>
              <RegistrationInput
                type={this.state.showPassword ? 'text' : 'password'}
                name="password"
                placeholder={`${__('Heslo')}`}
                onChange={this.onInputChange}
              />
              <PasswordIcon
                icon={'password'}
                onClick={this.handlePasswordIcon}
              />
            </InputPasswordWrapper>
          </InputCol>
        </RegRow>

        <RegRow>
          <LabelCol></LabelCol>
          <InputCol>
            <CheckboxStyled
              name="notify_user"
              value="1"
              id="notify_user"
              onChange={this.onInputChange}
              checked={this.state.notifyUser}
            >
              {__('Informovať užívateľa o novom účte emailom')}
            </CheckboxStyled>
          </InputCol>
        </RegRow>

        <ButtonWrapper>
          <ButtonStyled className="primary lg" onClick={this.onAddUserClick}>
            {__('Pridať používateľa')}
          </ButtonStyled>
        </ButtonWrapper>
      </MainBlock>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'NewSkvelkoAwardedUser' })(NewSkvelkoAwardedUser),
);
