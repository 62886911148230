import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const TableWrapper = styled.div`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media(1100)} {
    margin-bottom: ${rem(40)};
  }
`;

export const Table = styled.div`
  font-size: ${rem(14)};
  ${({ theme }) => theme.media(1100)} {
    display: table;
    border-collapse: collapse;
    width: 100%;
  }
`;

export const Head = styled.div`
  padding: ${rem(5)} 0;
  ${({ theme }) => theme.media(1100)} {
    display: table-header-group;
  }
`;

export const Body = styled.div`
  ${({ theme }) => theme.media(1100)} {
    display: table-row-group;
  }
`;

export const Tr = styled.div`
  ${Body} &:not(:first-child) {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
  ${({ theme }) => theme.media('xs')} {
    display: flex;
    flex-wrap: wrap;
  }
  ${({ theme }) => theme.media(1100)} {
    display: table-row;
    ${Head} & {
      border-top-right-radius: ${({ theme }) =>
        rem(theme.borderRadius.default)};
    }
  }
`;

const ThTdStyles = css`
  padding: ${rem(5)} ${rem(10)};
  ${({ theme }) => theme.media(1100)} {
    display: table-cell;
    padding: ${rem(17)} ${rem(20)};
  }
`;

export const Th = styled.div`
  font-weight: 700;
  ${ThTdStyles};
  &.status {
    width: auto;
  }
  &.empty {
    display: none;
  }
  ${({ theme }) => theme.media('xs')} {
    flex: 1 1 ${({ theme }) => theme.grid.col.col4};
  }
  ${({ theme }) => theme.media(1100)} {
    width: auto;
    &.empty {
      display: table-cell;
    }
  }
`;

export const Td = styled.div`
  ${ThTdStyles};
  &.success {
    color: ${({ theme }) => theme.color.success};
  }
  &.error {
    color: ${({ theme }) => theme.color.error};
  }
  ${({ theme }) => theme.media('xs')} {
    flex: 1 0 ${({ theme }) => theme.grid.col.col4};
    &.buttons {
      flex: 1 0 ${({ theme }) => theme.grid.col.col12};
    }
  }
  ${({ theme }) => theme.media(1100)} {
    &.buttons {
      white-space: nowrap;
      width: 1%;
      padding-right: ${rem(10)};
    }
  }
`;
