import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';
import { formatShop } from '../../../utilities/shops';

const ModalDiv = styled(BaseModal)`
  width: ${rem(760)};
`;

const Table = styled.table`
  display: block;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  ${({ theme }) => theme.media('md')} {
    display: table;
  }
`;

const Tr = styled.tr``;
const TableLabel = styled.td`
  width: 80px;
  font-weight: bold;
`;

const TableText = styled.td``;

const Title = styled.div`
  display: block;
  font-size: ${rem(25)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.gray89};
  margin-bottom: 10px;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

interface ModalProps {
  shopPersonalPickup: any;
  onCloseButtonClick?: (event) => void;
}

class ShopPersonalPickupModal extends React.PureComponent<ModalProps> {
  public render() {
    let { shopPersonalPickup } = this.props;
    shopPersonalPickup = formatShop(shopPersonalPickup);
    return (
      <ModalDiv
        title={shopPersonalPickup.name}
        body={
          <div>
            <Section>
              <Title>{__('Adresa')}</Title>
              <Table>
                <Tr>
                  <TableLabel>{__('Názov')}</TableLabel>
                  <TableText>{shopPersonalPickup.name}</TableText>
                </Tr>
                <Tr>
                  <TableLabel>{__('Ulica')}</TableLabel>
                  <TableText>{shopPersonalPickup.address}</TableText>
                </Tr>
                <Tr>
                  <TableLabel>{__('Mesto')}</TableLabel>
                  <TableText>
                    {shopPersonalPickup.zip} {shopPersonalPickup.city}
                  </TableText>
                </Tr>
              </Table>
            </Section>
            <Section>
              <Title>{__('Otváracie hodiny')}</Title>
              <Table>
                {shopPersonalPickup.opening_hours_formatted?.map((oh, i) => {
                  return <p key={i}>{oh}</p>;
                })}
              </Table>
            </Section>
          </div>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default ShopPersonalPickupModal;
