import * as actionTypes from './constants';

const initialState = {};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FORGOTTEN_PASSWORD_TEXT:
      return {
        ...state,
        forgottenPasswordText: action.payload.forgottenPasswordText,
      };
    default:
      return state;
  }
};
