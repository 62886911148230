export class ImageService {
  public static getImage(image: string | null | undefined) {
    if (image) {
      return process.env.REACT_APP_API_BASE_URL + '/image?query=' + image;
    }
    return undefined;
  }

  public static getImageWithParams(image: string | null | undefined, params) {
    if (image) {
      return (
        process.env.REACT_APP_API_BASE_URL +
        '/image?query=' +
        image +
        '&' +
        params.join('&')
      );
    }
    return undefined;
  }
}
