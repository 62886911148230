import * as React from 'react';
import styled from 'styled-components';
import { __ } from 'react-i18n/lib';
import { CloseIconWhite } from '../../_helpers/Icon/Close';
import { rem } from 'polished';
import { Button } from '../../_helpers/form/Button';
import { browserHistory, Link } from 'react-router';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { push } from 'react-router-redux';

export enum HeaderSimpleType {
  BASE,
  CART,
}

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.color.primary};
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 11;
  padding: ${rem(20)} ${rem(10)} ${rem(20)} ${rem(20)};
  max-height: ${rem(60)};
  ${({ theme }) => theme.media('md')} {
    padding: ${rem(20)} ${rem(40)};
  }
  ${({ theme }) => theme.media('md')} {
    max-height: initial;
  }
  ${({ theme }) => theme.transition('transform')};

  @media print {
    position: relative;
  }
`;

const CloseCircle = styled.div`
  display: inline-block;
  border: ${rem(2)} solid ${({ theme }) => theme.color.white};
  border-radius: 50%;
  padding: ${rem(3)};
  line-height: ${rem(20)};
`;

const CloseCircleCart = styled(CloseCircle)`
  ${({ theme }) => theme.media('md')} {
    line-height: ${rem(25)};
    width: ${rem(35)};
    font-weight: bold;
    vertical-align: top;
  }
`;

const CloseText = styled.div`
  display: block;
  font-size: ${rem(10)};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  margin: 0;
  ${({ theme }) => theme.media('md')} {
    display: inline-block;
    padding-left: ${rem(8)};
    font-size: ${rem(16)};
    font-weight: normal;
  }
`;

const CloseTextCart = styled(CloseText)`
  text-align: left;
  text-transform: none;
`;

const Close = styled(Button)``;

const LogoLink = styled(Link)`
  display: inline-block;
  ${({ theme }) => theme.media('xs')} {
    max-width: ${rem(280)};
    margin: 0;
  }
`;

const Logo = styled.img`
  height: ${rem(32)};
  ${({ theme }) => theme.media('xs')} {
    height: ${rem(54)};
  }
`;

const TitleWrapper = styled.div`
  flex: 1;
`;

const Title = styled.h1`
  font-size: ${rem(16)};
  font-weight: normal;
  color: ${({ theme }) => theme.color.white};
  padding-left: ${rem(16)};
  margin: 0;
  ${({ theme }) => theme.media('xs')} {
    font-size: ${rem(24)};
  }
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(30)};
  }

  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
`;

const Subtitle = styled.h2`
  font-weight: normal;
  font-size: ${rem(10)};
  ${({ theme }) => theme.media('xs')} {
    font-size: ${rem(12)};
  }
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(14)};
  }
  color: ${({ theme }) => theme.color.white};
  margin: 0;
  padding-left: ${rem(16)};
`;

const Phone = styled.div`
  display: none;
  text-align: right;
  padding-right: ${rem(30)};
  color: #fff;

  ${({ theme }) => theme.media('md')} {
    display: inline-block;
  }
`;

const ItemHeaderTextNormal = styled.div`
  font-size: ${rem(10)};
  display: inline-block;
  text-transform: uppercase;
  padding-top: 5px;

  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(18)};
    padding-top: 0;
  }
`;

const ItemHeaderTextSmall = styled.div`
  font-size: ${rem(12)};
  display: none;

  ${({ theme }) => theme.media('md')} {
    display: block;
  }
`;

interface HeaderSimpleProps {
  titleText: string;
  subtitleText: string;
  closePath?: string;
  headerType?: HeaderSimpleType;
  prevPath: string;
  dispatch: any;
  location: any;
}

const HeaderSimple = (props: HeaderSimpleProps) => {
  const logo = '/images/logo-simple.svg';

  const {
    titleText,
    headerType,
    subtitleText,
    closePath,
    prevPath,
    location,
  } = props;

  const goBack = () => {
    if (closePath) {
      props.dispatch(push(closePath));
    } else if (prevPath && prevPath != location.pathname) {
      props.dispatch(push(prevPath));
    } else {
      browserHistory.goBack();
    }
  };

  const renderCartType = () => {
    return (
      <Wrapper>
        <LogoLink to="/">
          <Logo src={logo} alt="Daffer.sk" />
        </LogoLink>
        <Title>{titleText}</Title>
        <Phone>
          <ItemHeaderTextSmall>
            {__('Pracovné dni od 7:00 do 15:30')}
          </ItemHeaderTextSmall>
          <ItemHeaderTextNormal>{__('046 / 519 87 11')}</ItemHeaderTextNormal>
        </Phone>
        <Close onClick={goBack}>
          <CloseCircleCart>
            <CloseIconWhite />
          </CloseCircleCart>
          <CloseTextCart>
            <ItemHeaderTextNormal>{__('Zavrieť')}</ItemHeaderTextNormal>
            <ItemHeaderTextSmall>
              {__('a pokračovať v nákupe')}
            </ItemHeaderTextSmall>
          </CloseTextCart>
        </Close>
      </Wrapper>
    );
  };

  const renderBaseType = () => {
    return (
      <Wrapper>
        <LogoLink to="/">
          <Logo src={logo} alt="Daffer.sk" />
        </LogoLink>
        <TitleWrapper>
          <Title>{titleText}</Title>
          {subtitleText ? <Subtitle>{subtitleText}</Subtitle> : ''}
        </TitleWrapper>
        <Close onClick={goBack}>
          <CloseCircle>
            <CloseIconWhite />
          </CloseCircle>
          <CloseText>{__('Zavrieť')}</CloseText>
        </Close>
      </Wrapper>
    );
  };

  const render = () => {
    switch (headerType) {
      case HeaderSimpleType.CART:
        return renderCartType();
      default:
        return renderBaseType();
    }
  };

  return render();
};

const mapStateToProps = (state) => {
  return {
    prevPath: state.general.prevPath,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'HeaderSimple' })(HeaderSimple),
);
