const namespace = 'Product';

export const SET_ACTIVE_VARIANT = `${namespace}/SET_ACTIVE_VARIANT`;
export const SET_CURRENT_PRODUCT_ID = `${namespace}/SET_CURRENT_PRODUCT_ID`;
export const SET_CURRENTPRODUCT_DATA = `${namespace}/SET_CURRENTPRODUCT_DATA`;
export const SET_LINKED_PRODUCTS = `${namespace}/SET_LINKED_PRODUCTS`;
export const SET_RELATED_PRODUCTS = `${namespace}/SET_RELATED_PRODUCTS`;
export const SET_RELATED_PRODUCTS_MODAL = `${namespace}/SET_RELATED_PRODUCTS_MODAL`;
export const SET_RELATED_PRODUCTS_MODAL_LOADER = `${namespace}/SET_RELATED_PRODUCTS_MODAL_LOADER`;
export const SET_LOADED_LAST_SEEN_PRODUCTS = `${namespace}/SET_LOADED_LAST_SEEN_PRODUCTS`;
export const SET_LOADED_LAST_SEEN_PRODUCTS_LOADER = `${namespace}/SET_LOADED_LAST_SEEN_PRODUCTS_LOADER`;
export const UPDATE_CURRENT_PRODUCT_FAVORITE = `${namespace}/UPDATE_CURRENT_PRODUCT_FAVORITE`;

export const LAST_SEEN_PRODUCTS_KEY = 'lastSeenProducts';
export const SET_UPSELL = `${namespace}/SET_UPSELL`;
