import * as React from 'react';
import styled from 'styled-components';

const RectWhite = styled.rect`
  fill: ${({ theme }) => theme.color.white};
`;

const PathWhite = styled.path`
  fill: ${({ theme }) => theme.color.white};
`;

const Svg = styled.svg`
  ${({ theme, size }) => theme.sizeWidth(size)};
`;

interface SkvelkoProps {
  size: number;
}

class Skvelko extends React.Component<SkvelkoProps> {
  public render() {
    return (
      <Svg x="0px" y="0px" viewBox="0 0 424.6 124.3" size={this.props.size}>
        <g>
          <path
            d="M144.3,97.6c-12.8-2.8-24.8-10.1-31.9-18.4c10.9-6.2,18.8-17.1,22.7-31.9l-17.3-4.6c-1.8,6.7-5.3,14.5-12.1,19.5l3.4-46.4
		l-17.8-1.3l-7,94.6l17.8,1.3l1.1-15c10,9.6,23.7,16.8,37.2,19.7L144.3,97.6z"
          />
          <polygon points="152.7,109.4 135.4,36.9 152.8,32.7 160.7,65.9 167.4,33 184.9,36.6 170.1,109.1 	" />
          <path
            d="M210.5,114.4c-0.6,0-1.2,0-1.8-0.1c-8.7-0.6-16.1-5.4-21-13.5c-4.2-7-6.1-16-5.4-25.4c1.8-24.8,14.9-40.8,33.4-40.8
		c7.7,0,14.7,4.1,18.6,11.1c4.3,7.7,4.1,17.4-0.6,25.1c-3.5,5.7-12.2,14.9-32.7,15.8c0.5,1.9,1.2,3.6,2.1,5c1.8,3,4.2,4.7,7,4.9
		c5.3,0.4,10.2-4.9,12.6-10.4l16.3,7.3C233,106.3,222.1,114.4,210.5,114.4z M215.6,52.5c-7.7,0-12.2,7.6-14.3,16.2
		c8.4-0.5,14.5-3,17-7.1c1.7-2.7,1.3-5.5,0.3-7.1C217.9,53.1,216.8,52.5,215.6,52.5z"
          />

          <rect
            x="199.6"
            y="48.6"
            transform="matrix(2.786564e-02 -0.9996 0.9996 2.786564e-02 187.2116 307.5239)"
            width="104.1"
            height="17.9"
          />
          <path
            d="M296.6,79.9c11.7-6.4,20.5-17.9,25.1-33.5l-17.1-5c-2.5,8.7-7.7,18.8-17.9,23.6l3.7-55.8l-17.8-1.2l-6.7,100.9l17.8,1.2
		l1.1-16c8,10.7,19.5,19.3,33.7,24.9l6.6-16.6C312.4,97.4,302.6,89.6,296.6,79.9z"
          />
          <path
            d="M398,52.8L398,52.8c-9,1.4-13.7,2-22.3,2.6c-1.2-7.6-3.9-13.9-7.9-18.3c-4.3-4.6-9.8-7.1-16.1-7.1
		c-8.6,0-15.8,3.7-19.6,10.2c-1.3,2.2-2.2,4.6-2.5,7c-6.7,18.4-8,45.9,0.6,58.5c3.5,5.1,8.6,7.9,14.3,7.9
		c16.7,0,28.9-15.8,31.5-40.4c9.2-0.6,15-1.2,25.2-2.8L419.8,58l1.1-0.7L398,52.8z M347.4,49.3c0.5-0.9,2.1-1.4,4.3-1.4
		c1.3,0,2.1,0.4,2.9,1.3c1.1,1.2,2.1,3.2,2.9,6.1c-8.2-1-9.8-3.4-9.9-3.7C347.1,50.8,347,49.9,347.4,49.3z M345,95.8
		c-0.9-1.2-3-5.7-3.1-15.6c0-3.4,0.2-7,0.6-10.5c4.3,2,9.5,3.1,15.4,3.6C356.3,84.4,351.9,95.2,345,95.8z"
          />
          <path
            d="M83.1,22.4l1.7-8.6c1-4.9-2.2-9.6-7-10.6c-0.6-0.1-1.1-0.2-1.7-0.2c-4.2,0-8,3-8.8,7.3L66,16.3c-0.6-0.7-1.2-1.4-1.9-2
		c-6.3-6.2-14-9.1-22.2-8.6C23,7,8.6,25.5,4.7,42.3C1.1,58.2,6.1,71.8,18.3,78.8c8.8,5.1,19.1,3,28.2,1.2c14.5-2.9,16.5-2,18.4,3
		c1.6,4,0.2,9.6-3.6,14.1c-3.5,4.2-8.3,6.5-12.1,5.8c-4.6-0.8-8.8-6.1-11.7-14.8l-16.9,5.8c3.6,10.7,11,24,25.4,26.6
		c1.5,0.3,3.1,0.4,4.6,0.4c8.9,0,17.9-4.5,24.5-12.4c7.9-9.5,10.4-21.8,6.5-31.9c-7.8-20.3-27.1-16.4-38.6-14
		c-6,1.2-12.8,2.6-15.7,0.9c-6.9-4-6.1-12.4-5-17c2.7-11.8,12.3-22.2,20.9-22.8c3.1-0.2,5.9,0.9,8.5,3.5c5.5,5.4,8.5,15.1,8.2,21.5
		l17.7,2.1l3.8-19.4l0,0l1.6-8.2C83,22.8,83,22.7,83.1,22.4z"
          />
          <RectWhite
            x="73.4"
            y="13.3"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 41.2673 88.6354)"
            width="2"
            height="11.9"
          />
          <RectWhite
            x="72.5"
            y="17.8"
            transform="matrix(0.1915 -0.9815 0.9815 0.1915 36.136 91.3435)"
            width="2"
            height="11.9"
          />
          <PathWhite
            d="M402.1,58.7c-1.5-0.7-2.7-2-3.4-3.4l11.5,2.2c1,1.5,1.2,2.8,0.6,4.4l-9.9,5.7c0.3-1.7,1.8-4,2.4-4.2
		C401.6,61,402.1,58.7,402.1,58.7z"
          />
          <PathWhite
            d="M70.1,11c0.6-2.8,3-4.9,5.9-4.9c0.4,0,0.8,0,1.1,0.1c1.5,0.3,2.9,1.2,3.8,2.5c0.9,1.3,1.2,2.9,0.9,4.5
		l-0.7,3.5l-11.7-2.3L70.1,11z"
          />
        </g>
      </Svg>
    );
  }
}

export default Skvelko;
