import * as React from 'react';
import styled from 'styled-components';

const Circle = styled.circle`
  fill: ${({ theme }) => theme.color.secondary};
`;

const Path = styled.path`
  fill: ${({ theme }) => theme.color.text};
`;

const Svg = styled.svg`
  ${({ theme }) => theme.size(30)};
  position: absolute;
  left: 12%;
`;

class Check extends React.Component {
  public render() {
    return (
      <Svg x="0px" y="0px" viewBox="0 0 1000 1000">
        <Circle cx="761.3" cy="731.6" r="231.6" />
        <Path d="M891.2,674.2c9.5-12.5,7.6-30.1-4.3-39.2l-9.3-7.1c-11.9-9.1-29.4-6.3-38.9,6.2L734.2,771.1l-75.3-57.5,c-11.9-9.1-29.4-6.3-38.9,6.2l-7.4,9.7c-9.5,12.5-7.6,30.1,4.3,39.2l106.2,81c11.9,9.1,29.4,6.3,38.9-6.2l7.4-9.7,c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.3,0.4-0.5,0.6-0.8L891.2,674.2z" />
      </Svg>
    );
  }
}

export default Check;
