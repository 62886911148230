import * as React from 'react';
import { capitalizeFirstLetter, getBaseUrl } from '../../../utilities';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';
import Icon from '../Icon/Icon';
import ImagePlaceholder from '../Image/ImagePlaceholder';
import { grid } from '../../../theme/libraries/grid';
import { ChematCustomerType } from '../../../library/ChematCustomerType';
import { __ } from 'react-i18n';

const Wrapper = styled.ul`
  margin: 0 ${rem(-grid.padding / 2)} ${rem(16)} ${rem(-grid.padding / 2)};
  padding: 0;
  background: ${({ theme }) => theme.color.white};
  display: grid;
  grid-gap: 0 1rem;
  ${({ theme }) => theme.media('xs')} {
    grid-template-columns: 1fr 1fr;
  }
  ${({ theme }) => theme.media('md')} {
    margin-left: 0;
    margin-right: 0;
    grid-gap: 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Item = styled.li`
  position: relative;
  list-style: none;
  margin: ${rem(-1)} 0;
  border-bottom: solid ${rem(2)} ${({ theme }) => theme.color.gray95};
  border-top: solid ${rem(2)} ${({ theme }) => theme.color.gray95};
`;

const ItemLink = styled(Link)`
  color: inherit;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const ItemName = styled.p`
  margin: 0;
  max-width: 70%;
  padding-right: ${rem(24)};
  line-height: 1;
  font-size: ${rem(15)};
`;

const ItemImage = styled(ImagePlaceholder)`
  width: ${(props) => rem(props.w)};
  height: ${(props) => rem(props.h)};
  margin: 0 ${rem(8)} 0 ${rem(24)};
`;

const ItemCaret = styled(Icon)`
  fill: ${({ theme }) => theme.color.gray83};
  ${({ theme }) => theme.size(12)};
  position: absolute;
  top: 50%;
  right: ${rem(15)};
  transform: translate3d(0, -50%, 0);
`;

interface categoryListMobileProps {
  categories: any[];
  user: any;
}

const CategoryList = (props: categoryListMobileProps) => {
  const { categories, user } = props;

  const placeholder = '/images/placeholder-100.png';
  const placeholder2x = '/images/placeholder-100@2x.png';

  return (
    <>
      {categories?.length ? (
        <Wrapper>
          {categories.map((item, i) => {
            if (
              (!user || user.skvelko_type === ChematCustomerType.RETAIL) &&
              item.category_number === 'KAT_SEASON'
            ) {
              return '';
            }

            if (
              user &&
              user.skvelko_type !== ChematCustomerType.RETAIL &&
              item.category_number === 'KAT_ACTION'
            ) {
              return '';
            }

            return (
              <Item key={i}>
                <ItemLink to={getBaseUrl(item?.url)}>
                  <ItemImage
                    src={item?.image}
                    placeholder={placeholder}
                    retinaPlaceholder={placeholder2x}
                    w={32}
                    h={32}
                    alt={
                      __('Kategória') +
                      ' ' +
                      capitalizeFirstLetter(item?.category_name)
                    }
                  />
                  <ItemName>
                    {capitalizeFirstLetter(item?.category_name)}
                  </ItemName>
                </ItemLink>
                <ItemCaret icon="arrow" />
              </Item>
            );
          })}
        </Wrapper>
      ) : (
        ''
      )}
    </>
  );
};

export default CategoryList;
