import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { InputDefaultStyles, InputStyles } from './FormStyles';

const SelectDefault = styled.select`
  ${InputStyles};
  ${InputDefaultStyles};
`;

export const SelectStyledStyles = css`
  background: ${({ theme }) => theme.form.background.select};
  background-size: ${rem(8)} ${rem(4)};
  border: ${({ theme }) => rem(theme.form.border.width)}
    ${({ theme }) => theme.form.border.style}
    ${({ theme }) => theme.form.border.color};
  border-radius: ${({ theme }) => rem(theme.form.border.radius)};
  color: ${({ theme }) => theme.form.color.default};
  padding: ${({ theme }) => rem(theme.form.padding.horizontal)}
    ${({ theme }) => rem(theme.form.padding.vertical)};
  padding-right: ${rem(30)};
  &:focus {
    border-color: ${({ theme }) => theme.form.border.colorFocus};
  }
  &:disabled {
    background: ${({ theme }) => theme.form.background.disabled};
    color: ${({ theme }) => theme.form.color.disabled};
  }
  &::placeholder {
    color: ${({ theme }) => theme.form.color.placeholder};
  }
`;
export const SelectStyled = styled(SelectDefault)`
  ${SelectStyledStyles};
`;

export const InputStyledBlock = styled(SelectStyled)`
  display: block;
  width: 100%;
`;

export const SelectWhiteStyles = css`
  border-color: transparent;
`;

const Option = styled.option``;

interface Props {
  name: string;
  disabled?: boolean;
  className?: string;
  multiple?: boolean;
  value?: string;
  defaultValue?: string;
  items: Array<{
    value: string;
    name?: string;
    selected?: boolean;
    disabled?: boolean;
  }>;
  onChange?: (event) => void;
  placeholder?: string;
}

export class Select extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      disabled,
      className,
      items,
      multiple,
      value,
      defaultValue,
      onChange,
      name,
    } = this.props;
    return (
      <SelectDefault
        name={name}
        disabled={disabled}
        className={className}
        multiple={multiple}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {this.props.placeholder && (
          <React.Fragment>
            <option hidden={true}>{this.props.placeholder}</option>
          </React.Fragment>
        )}
        {items.map((item, i) => {
          return (
            <Option
              key={i}
              value={item.value}
              selected={item.selected ? item.selected : false}
              disabled={item.disabled ? item.disabled : false}
            >
              {item.name ? item.name : item.value}
            </Option>
          );
        })}
      </SelectDefault>
    );
  }
}
