import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { Col, Row } from '../../../theme/libraries/grid';
import Photogallery from '../../_helpers/Photogallery/Photogallery';
import Icon from '../../_helpers/Icon/Icon';
import { ButtonStyles } from '../../_helpers/form/Button';
import MetaTags from '../../MetaTags/MetaTags';
import GalleryModal from '../../_helpers/Modal/GalleryModal';
import { prop } from '../../../utilities';
import { formatShop } from '../../../utilities/shops';

const Wrapper = styled.div`
  font-size: ${rem(14)};
`;

const BackToStoreList = styled.button`
  ${ButtonStyles};
  color: inherit;
  font-size: ${rem(22)};
  text-decoration: none;
  outline: none;
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(22)};
  }
`;

const BackToStoreIcon = styled(Icon)`
  ${({ theme }) => theme.size(20)};
  margin-right: ${rem(10)};
  transform: translateY(${rem(-2)});
`;

const InfoRow = styled(Row)`
  margin-bottom: ${rem(20)};
`;

const StoreCol = styled(Col)`
  ${({ theme }) => theme.media('md')} {
    width: 20%;
    &.big {
      width: 60%;
    }
  }
`;

const ColTitle = styled.p`
  font-weight: 700;
  margin-bottom: ${rem(20)};
`;

const StoreRow = styled.p`
  margin: 0 0 ${rem(6)};
  a {
    color: ${({ theme }) => theme.color.link};
    text-decoration: none;
  }
`;

const CustomTextWrapper = styled.div`
  margin-bottom: ${rem(15)};
`;

interface StoreDetailProps {
  detail: any;
  onClickBack: (id: null) => void;
}

interface StoreDetailState {
  modalVisible: boolean;
  activeIndex: number | null;
}

class StoreDetail extends React.PureComponent<
  StoreDetailProps,
  StoreDetailState
> {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      activeIndex: null,
    };
  }

  public onThumbClick = (index) => {
    this.setState({ modalVisible: true, activeIndex: index });
  };

  public closeModal = () => {
    this.setState({ modalVisible: false });
  };

  public metaHandler = (shopDetail, defaultTitle) => {
    let metaTags: any = {};
    if (shopDetail && shopDetail.content.id !== null) {
      metaTags = prop(shopDetail.content.json_content, 'meta', {
        title: defaultTitle,
      });
    } else {
      metaTags.title = defaultTitle;
    }

    return metaTags;
  };

  public render() {
    let { detail } = this.props;

    detail = formatShop(detail);

    let images = [];
    if (detail && detail.content && detail.content.photogallery) {
      images = detail.content.photogallery.map((item, i) => {
        return item.document_path;
      });
    }
    images.shift();

    const tags = this.metaHandler(
      detail,
      detail ? `Daffer.sk` : 'Daffer.sk - Predajňa',
    );

    return (
      <Wrapper>
        {detail ? (
          <React.Fragment>
            <MetaTags tags={tags} />
            <BackToStoreList onClick={this.props.onClickBack.bind(null, null)}>
              <BackToStoreIcon icon="back" />
              {detail.name}
            </BackToStoreList>
            <InfoRow>
              <StoreCol>
                <ColTitle>{__('Adresa')}</ColTitle>
                <StoreRow>{detail.address}</StoreRow>
                <StoreRow>{detail.city}</StoreRow>
                <StoreRow>{detail.zip}</StoreRow>
              </StoreCol>
              <StoreCol>
                <ColTitle>{__('Kontakty')}</ColTitle>
                <StoreRow>
                  <a href={`tel:${detail.phone}`}>{detail.phone}</a>
                </StoreRow>
                <StoreRow>
                  <a href={`mailto:${detail.email}`}>{detail.email}</a>
                </StoreRow>
              </StoreCol>
              <StoreCol className={'big'}>
                {detail.opening_hours_formatted ? (
                  <React.Fragment>
                    <ColTitle>{__('Otváracie hodiny')}</ColTitle>
                    {detail.opening_hours_formatted.map((oh, i) => {
                      return <StoreRow key={i}>{oh}</StoreRow>;
                    })}
                  </React.Fragment>
                ) : (
                  ''
                )}
              </StoreCol>
            </InfoRow>

            {detail.content &&
            detail.content.json_content &&
            detail.content.json_content.body ? (
              <CustomTextWrapper
                dangerouslySetInnerHTML={{
                  __html: detail.content?.json_content?.body,
                }}
              />
            ) : (
              ''
            )}

            <Photogallery items={images} onThumbClick={this.onThumbClick} />
            {this.state.modalVisible && (
              <GalleryModal
                pictures={images}
                activeIndex={this.state.activeIndex}
                onCloseButtonClick={this.closeModal}
              />
            )}
          </React.Fragment>
        ) : (
          ''
        )}
      </Wrapper>
    );
  }
}

export default StoreDetail;
