import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { Media, MediaContextProvider } from '../../theme/media';
import Newsletter, {
  NewsletterType,
} from '../../components/Newsletter/Newsletter';
import {
  filterBannersByUser,
  loadGlobalMetaTags,
  prop,
  scrollToTop,
} from '../../utilities';
import { sendNewsletter } from '../Home/actions';
import MetaTags from '../../components/MetaTags/MetaTags';
import { loadSchoolArticles, loadSchoolBanners } from '../App/actions';
import { Content, Title } from '../../components/_layout/Page/ContentContainer';
import styled from 'styled-components';
import {
  BANNER_SCHOOL,
  NEWSLETTER_TYPE_SCHOOL,
  SCHOOL_ARTICLES_PER_PAGE,
  SCHOOL_ARTICLES_UNIQID,
  URL_SCHOOL_ARTICLES,
} from '../../constants';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import { Container } from '../../theme/libraries/grid';
import SchoolArticlesList from '../../components/SchoolArticle/SchoolArticlesList';
import Pagination from '../../components/Pagination/Pagination';
import BannerSwiper from '../../components/_layout/BannerSwiper/BannerSwiper';

const ContainerStyled = styled(Container)`
  ${({ theme }) => theme.media(0, 'md')} {
    padding-right: 0;
    padding-left: 0;
  }
`;

const ContentStyled = styled(Content)`
  padding: ${rem(10)} ${rem(10)} 0;
  ${({ theme }) => theme.media('md')} {
    padding: ${rem(10)} ${rem(20)} 0;
    margin: ${rem(10)} 0;
  }
`;

const TitleStyled = styled(Title)`
  margin-bottom: ${rem(30)};
`;

const Wrapper = styled.div`
  ${({ theme }) => theme.media('md')} {
    margin-left: ${rem(20)};
  }
`;

const BannerWrapper = styled.div`
  width: 100%;
`;

interface SchoolArticlesProps {
  dispatch: any;
  schoolBanners: any[];
  metaTags: any;
  newsletterLoader: boolean;
  newsletterResponse: boolean | null;
  schoolArticles: any;
  user: any;
}

interface SchoolArticlesState {
  currentPage: number;
}

class SchoolArticles extends React.Component<
  SchoolArticlesProps,
  SchoolArticlesState
> {
  public static async getInitialProps(parentProps) {
    const promises: any[] = [];

    promises.push(
      parentProps.dispatch(loadSchoolArticles(0, SCHOOL_ARTICLES_PER_PAGE)),
    );

    promises.push(parentProps.dispatch(loadSchoolBanners()));

    promises.push(
      parentProps.dispatch(loadGlobalMetaTags(SCHOOL_ARTICLES_UNIQID)),
    );

    await Promise.all(promises);

    return parentProps;
  }

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
    };
  }

  public onNewsletterSubmit = (e) => {
    e.preventDefault();
    this.props.dispatch(
      sendNewsletter(e.target.email.value, NEWSLETTER_TYPE_SCHOOL),
    );
  };

  public onPageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
    this.props.dispatch(
      loadSchoolArticles(
        (pageNumber - 1) * SCHOOL_ARTICLES_PER_PAGE,
        SCHOOL_ARTICLES_PER_PAGE,
      ),
    );
    scrollToTop();
  };

  public render() {
    const { schoolArticles, schoolBanners, metaTags, user } = this.props;
    const { currentPage } = this.state;

    const filteredSchoolBanners = filterBannersByUser(schoolBanners, user);

    return (
      <MediaContextProvider>
        {metaTags ? (
          <MetaTags tags={metaTags} />
        ) : (
          <MetaTags tags={{ title: 'Daffer.sk' }} />
        )}

        {currentPage === 1 ? (
          <BannerWrapper>
            <BannerSwiper banners={filteredSchoolBanners} user={user} />
          </BannerWrapper>
        ) : (
          ''
        )}

        <ContainerStyled className="base">
          <ContentStyled>
            <Wrapper>
              <Breadcrumbs
                items={[
                  { name: 'Pre školské zariadenia', url: URL_SCHOOL_ARTICLES },
                  { name: 'Aktuality pre školy', url: URL_SCHOOL_ARTICLES },
                ]}
              />
              <TitleStyled>{__('Aktuality pre školy')}</TitleStyled>
            </Wrapper>
            <SchoolArticlesList schoolArticles={schoolArticles} />

            {schoolArticles?.total > SCHOOL_ARTICLES_PER_PAGE && (
              <Pagination
                current={currentPage}
                total={Math.ceil(
                  schoolArticles.total / SCHOOL_ARTICLES_PER_PAGE,
                )}
                onPageChanged={this.onPageChange}
              />
            )}
          </ContentStyled>
        </ContainerStyled>

        <Media greaterThanOrEqual="md">
          <Newsletter
            loader={this.props.newsletterLoader}
            response={this.props.newsletterResponse}
            onSubmit={this.onNewsletterSubmit}
            type={NewsletterType.SCHOOL}
          />
        </Media>
      </MediaContextProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    schoolBanners: state.general.banners?.[BANNER_SCHOOL]?.banners,
    metaTags: state.general.globalMetaTags?.[SCHOOL_ARTICLES_UNIQID],
    newsletterLoader: prop(state.home, 'newsletterLoader', false),
    newsletterResponse: prop(state.home, 'newsletterResponse', null),
    schoolArticles: state.general.schoolArticles,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SchoolArticles' })(SchoolArticles),
);
