import * as React from 'react';
import { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Filter from './Filter';
import { __ } from 'react-i18n';
import { useFilter } from '../../hooks/filter';
import { PRODUCTS_PER_PAGE, UrlMapTypes } from '../../constants';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import MetaTags from '../../components/MetaTags/MetaTags';
import { Row, MainCol, CategoriesCol } from '../../theme/libraries/grid';
import API from '../../services/API';
import {
  ProductsRow,
  ProductCol,
  CategoryTitle,
  CategoryPlaceholder,
} from '../../components/Category/Category';
import ProductPreview from '../../components/_helpers/product/ProductPreview';
import Pagination from '../../components/Pagination/Pagination';
import {
  brandMetaHandler,
  capitalizeFirstLetter,
  categoryMetaHandler,
  getGiftCategoryDesktopBannerFromSettings,
  getGiftWebContentIdFromSettings,
  getTotal,
  isCartGiftsValid,
  removePTag,
  scrollToTop,
} from '../../utilities';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { ContainerIndent } from '../../components/_layout/Page/ContentContainer';
import CategoriesListNew from '../../components/_helpers/Categories/CategoriesListNew';
import { Content } from '../CMS/CMS';
import { Media, MediaContextProvider } from '../../theme/media';
import {
  DEFAULT_FILTER_TYPES,
  defaultSearchParameters,
  onPageChangeDefault,
  onSortChangeDefault,
  SORT_CATEGORY,
} from './constants';
import { Loader } from '../../components/_helpers/Loader/Loader';
import SetCategoryPreview from '../../components/_helpers/Sets/SetCategoryPreview';
import { loadSetPopupText } from '../Home/actions';
import {
  concatSearchProductsAndSkvelkoSetsAndSoldOutProducts,
  SearchProductType,
} from '../../utilities/product';
import { theme } from '../../theme/theme';
import { SectionTitle } from '../Search/styles';
import CategoryList from '../../components/_helpers/Categories/CategoryList';
import { rem } from 'polished';
import { forceCheck } from 'react-lazyload';
import { setArticleModal, setCurrentCategoryGroupId } from '../App/actions';
import { ChematCustomerType } from '../../library/ChematCustomerType';
import GiftsBannerMobile from '../../components/_helpers/Sets/GiftsBannerMobile';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import Icon from '../../components/_helpers/Icon/Icon';
import * as cookie from 'react-cookies';

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${theme.color.white};
    ${({ theme }) => theme.media('md')} {
      background-color: ${theme.color.background};
    }
  }
`;

export interface CategoryProps {
  categoryGroup?: any[];
  category?: any;
  htmlSitemap?: any;
  brand?: any;
  location: any;
  dispatch: any;
  currentCategoryGroup?: any;
  lang?: string;
  mainGroupCategory?: any;
  currentCategoryId?: number;
  currentCategory?: number;
  urlMapData?: any;
  user?: any;
  settings?: any;
}

export const ProductsRowWrapper: any = styled(ProductsRow)`
  display: flex;
  flex-wrap: wrap;
  ${(props) => (props.withoutPagination ? `margin-bottom:${rem(20)}` : '')};
  ${({ theme }) => theme.media('sm')} {
    ${(props) => (props.withoutPagination ? `margin-bottom:${rem(25)}` : '')};
  }
`;

export const ProductColWrapper = styled(ProductCol)`
  & > div {
    height: 100%;
  }
`;
const GiftImage = styled.img`
  width: 100%;
  margin-bottom: ${rem(10)};
  cursor: pointer;
`;

const MainColWrapper = styled(MainCol)`
  padding-top: ${rem(10)};
`;

export const CategoryDescriptionWrapper: any = styled.div`
  ${(props) => (props.isEnd ? `margin-bottom:${rem(15)}` : '')};
`;

export const DescriptinoText = styled.div`
  display: flow-root;
`;

export const ShowMoreButton = styled(ButtonStyled)`
  padding: ${rem(7)};
  margin-top: ${rem(7)};
  margin-bottom: ${rem(15)};
`;

export const InfoIcon = styled(Icon)`
  fill: ${({ theme }) => theme.color.blue};
  width: ${rem(20)};
  height: ${rem(20)};
  margin-right: ${rem(5)};
`;

const Category = (props: CategoryProps) => {
  const {
    category,
    brand,
    dispatch,
    currentCategoryGroup,
    currentCategoryId,
    urlMapData,
    settings,
  } = props;

  const [productsSearch, setProductsSearch] = useState({} as any);
  const [categoryGroup, setCategoryGroup] = useState([] as any);
  const [fullCategoryGroup, setFullCategoryGroup] = useState(null as any);
  const [subtree, setSubtree] = useState([] as any);
  const [htmlSitemap, setHtmlSitemap] = useState([] as any);
  const [loading, setLoading] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

  const { filters, addFilter, setDefaults } = useFilter({
    ...DEFAULT_FILTER_TYPES,
    queryLocation: props.location.query,
    dispatch: props.dispatch,
  });

  const limit = PRODUCTS_PER_PAGE;
  const sort = SORT_CATEGORY;
  const onPageChange = (page) => onPageChangeDefault(addFilter, page);
  const onSortChange = (clickedSort, clickedSortDir) =>
    onSortChangeDefault(addFilter, clickedSort, clickedSortDir);

  React.useEffect(() => {
    if (
      !(Object.entries(filters).length === 0 && filters.constructor === Object)
    ) {
      setLoading(true);
      if (props.category && props.category.sitemap_id) {
        API.loadSitemap(props.category.sitemap_id).then((sitemap) => {
          setHtmlSitemap(sitemap);
        });
        API.loadCategoriesGroups({
          active: '1',
          withCategories: 1,
          categoryTree: true,
          limit: 1,
          id: props.category.id,
          filterEmpty: true,
        }).then((categoriesGroup) => {
          if (categoriesGroup.total) {
            setCategoryGroup(
              categoriesGroup.catalog_category_groups[0].categories,
            );
            setFullCategoryGroup(categoriesGroup.catalog_category_groups[0]);
            setCurrentCategoryGroupId(props.category.id);
          }
        });
      } else {
        setHtmlSitemap(null);
      }

      let parameters: any = defaultSearchParameters(filters, limit);
      parameters = {
        ...parameters,
        hasOldprice: props.category?.url === 'akcia' ? 1 : 0,
        isNewActive:
          props.category?.category_number === 'KAT_NEW_PRODUCT' ? 1 : 0,
      };

      if (category && !parameters.hasOldprice && !parameters.isNewActive) {
        parameters = {
          ...parameters,
          categoryId: category.categories_ids
            ? category.categories_ids
            : category.category_id,
        };
      }

      if (brand) {
        parameters = {
          ...parameters,
          brandId: brand.brand_id,
        };
      }

      const lamitecTesting = cookie.load('lamitec_testing');

      parameters = {
        ...parameters,
        includeSkvelkoSets: true,
        disableIgnoreLamitec: lamitecTesting == 1 ? true : false,
      };

      API.searchProducts(parameters).then((productsData) => {
        const items = concatSearchProductsAndSkvelkoSetsAndSoldOutProducts(
          productsData.products,
          productsData.skvelko_sets,
          [],
          productsData.positions,
        );
        productsData['items'] = items;
        setProductsSearch(items.length > 0 ? productsData : {});
        setLoading(false);
        scrollToTop();
      });
    }
  }, [filters]);

  if (window) {
    React.useLayoutEffect(() => {
      forceCheck();
    });
  }

  React.useEffect(() => {
    setDefaults();
    loadSetPopupText();
  }, [category, brand]);

  React.useEffect(() => {
    getCategoryGroup().then(/*silence*/);
  }, [category]);

  React.useEffect(() => {
    if (urlMapData?.type === UrlMapTypes.CategoryGroup) {
      setSubtree(categoryGroup);
    } else if (currentCategoryId) {
      API.loadSubTree(currentCategoryId, { filterEmpty: true }).then((r) => {
        setSubtree(r);
      });
    }
  }, [category, categoryGroup]);

  const onGiftOpen = async () => {
    dispatch(setArticleModal(getGiftWebContentIdFromSettings(settings)));
  };

  const onDescriptionButtonClick = async () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };

  const getCategoryGroup = async () => {
    if (props.currentCategoryGroup && props.currentCategoryGroup.categories) {
      setCategoryGroup(props.currentCategoryGroup.categories);
      setFullCategoryGroup(props.currentCategoryGroup);
      return;
    }
    if (!category || !category.url || !category.category_id) {
      return;
    }
    let categoryId = category.category_id;
    const urlMap = category.url.split('/')[0];
    if (urlMap !== category.url) {
      const urlMapData = await API.getUrlMap(
        {
          url: category.url.replace(/^\//, ''),
        },
        {
          xAcceptLanguage: props.lang,
        },
      );
      if (urlMapData && urlMapData.data && urlMapData.data.category_id) {
        categoryId = urlMapData.data.category_id;
      }
    }
    if (categoryId) {
      API.loadCategoriesGroups({
        active: '1',
        withCategories: 1,
        categoryTree: true,
        limit: 1,
        categoryId: categoryId,
        filterEmpty: true,
      }).then((categoriesGroup) => {
        if (categoriesGroup.total) {
          setCategoryGroup(
            categoriesGroup.catalog_category_groups[0].categories,
          );
          setFullCategoryGroup(categoriesGroup.catalog_category_groups[0]);
        } else {
          API.loadCategoriesGroups({
            active: '1',
            withCategories: 1,
            categoryTree: true,
            limit: 100,
            categoryIdAndParent: categoryId,
            filterEmpty: true,
          }).then((categoriesGroup) => {
            if (categoriesGroup.total) {
              setCategoryGroup(
                categoriesGroup.catalog_category_groups[0].categories,
              );
              setFullCategoryGroup(categoriesGroup.catalog_category_groups[0]);
            }
          });
        }
      });
    }
  };

  const isSchoolWebCategory =
    category &&
    category.parent_categories &&
    category?.parent_categories[0]?.category_id === 932
      ? true
      : false;

  const renderCategoryDesctiption = (body, shortBody) => {
    if (shortBody && body) {
      return (
        <CategoryDescriptionWrapper isEnd={isDescriptionOpen ? true : false}>
          <DescriptinoText
            dangerouslySetInnerHTML={{
              __html: shortBody,
            }}
          />

          {isDescriptionOpen ? (
            <ShowMoreButton onClick={onDescriptionButtonClick}>
              {' '}
              <InfoIcon icon="info" />
              {__('Menej informácií')}
            </ShowMoreButton>
          ) : (
            <ShowMoreButton onClick={onDescriptionButtonClick}>
              {' '}
              <InfoIcon icon="info" />
              {__('Viac informácií')}
            </ShowMoreButton>
          )}

          {isDescriptionOpen ? (
            <DescriptinoText
              dangerouslySetInnerHTML={{
                __html: body,
              }}
            />
          ) : (
            <></>
          )}
        </CategoryDescriptionWrapper>
      );
    } else if (shortBody) {
      return (
        <CategoryDescriptionWrapper isEnd={true}>
          <DescriptinoText
            dangerouslySetInnerHTML={{
              __html: shortBody,
            }}
          />
        </CategoryDescriptionWrapper>
      );
    } else if (body) {
      return (
        <CategoryDescriptionWrapper isEnd={true}>
          <DescriptinoText
            dangerouslySetInnerHTML={{
              __html: body,
            }}
          />
        </CategoryDescriptionWrapper>
      );
    }

    return <></>;
  };

  const getMainCol = () => {
    const paginationTotal = getTotal(productsSearch);
    const shortBody =
      category &&
      category.content &&
      category.content.length &&
      category.content[0].draft !== true &&
      typeof category.content[0].json_content?.short_body === 'string'
        ? removePTag(category.content[0].json_content?.short_body)
        : null;
    const body =
      category &&
      category.content &&
      category.content.length &&
      category.content[0].draft !== true &&
      typeof category.content[0].json_content?.body === 'string'
        ? removePTag(category.content[0].json_content?.body)
        : null;

    return (
      <div>
        {category &&
        category.category_name &&
        category.category_name === 'Nezaradene' ? (
          <MetaTags tags={{ robots: 'noindex' }} />
        ) : (
          ''
        )}
        {htmlSitemap &&
        htmlSitemap.content &&
        typeof htmlSitemap.content.json_content.body === 'string' ? (
          <React.Fragment>
            <Content
              dangerouslySetInnerHTML={{
                __html: htmlSitemap.content.json_content.body,
              }}
            />
          </React.Fragment>
        ) : (
          ''
        )}

        {renderCategoryDesctiption(body, shortBody)}

        <CategoryList categories={subtree} user={props.user} />
        <SectionTitle>
          <strong>{__('Produkty')}</strong> ({productsSearch.total || 0})
        </SectionTitle>
        <Loader loading={loading}>
          {productsSearch.total > limit ? (
            <Filter
              sort={sort}
              onSortChange={onSortChange}
              paginationCurrent={filters.page}
              paginationTotal={paginationTotal}
              onPageChanged={onPageChange}
              isSearch={false}
              isSortable={true}
            />
          ) : (
            <Filter
              sort={sort}
              onSortChange={onSortChange}
              isSearch={false}
              isSortable={true}
            />
          )}
          {productsSearch.items && productsSearch.items.length ? (
            <>
              <ProductsRowWrapper
                withoutPagination={!(productsSearch.total > limit)}
              >
                {productsSearch.items.map((item) => {
                  if (item.type === SearchProductType.SKVELKO_SET) {
                    return (
                      <ProductColWrapper key={item.item.id}>
                        <SetCategoryPreview skvelkoSet={item.item} />
                      </ProductColWrapper>
                    );
                  } else {
                    return (
                      <ProductColWrapper key={item.item.product_id}>
                        <ProductPreview
                          product={item.item}
                          fromSchoolWebCategory={isSchoolWebCategory}
                          setLoading={(loading) => setLoading(loading)}
                        />
                      </ProductColWrapper>
                    );
                  }
                })}
              </ProductsRowWrapper>
              {productsSearch.total > limit && (
                <Pagination
                  current={filters.page}
                  total={paginationTotal}
                  onPageChanged={onPageChange}
                />
              )}
            </>
          ) : loading ? (
            ''
          ) : (
            <CategoryPlaceholder text={__('Žiadné produkty sa nenašli')} />
          )}
        </Loader>
      </div>
    );
  };

  let categoryName = category ? category.category_name : '';

  let htmlSitemapName = null;
  if (htmlSitemap && htmlSitemap.content) {
    htmlSitemapName = htmlSitemap.content.json_content.name;
  }
  const categoryGroupName = htmlSitemapName
    ? htmlSitemapName
    : currentCategoryGroup
    ? currentCategoryGroup.name
    : '';
  let brandName = brand ? brand.brand_name : '';

  const items: any = [];
  if (category) {
    if (category.parent_categories && category.parent_categories.length) {
      const stopCategoryIds: number[] = [];
      if (
        fullCategoryGroup &&
        fullCategoryGroup.categories &&
        fullCategoryGroup.categories.length
      ) {
        for (const fullCategoryGroupCategory of fullCategoryGroup.categories) {
          stopCategoryIds.push(fullCategoryGroupCategory.category_id);
        }

        items.push({
          name: capitalizeFirstLetter(fullCategoryGroup.name),
          url: fullCategoryGroup.uniqid_url,
        });
      }

      let brEnabled = false;
      for (const parentCategory of category.parent_categories) {
        if (stopCategoryIds.includes(parentCategory.category_id)) {
          brEnabled = true;
        }

        if (brEnabled) {
          items.push({
            name: capitalizeFirstLetter(parentCategory.category_name),
            url: items.length !== 0 ? parentCategory.url : null,
          });
        }
      }

      if (currentCategoryGroup) {
        items.splice(0, 1, {
          name: capitalizeFirstLetter(currentCategoryGroup.name),
          url: currentCategoryGroup.uniqid_url,
        });
      }
    } else if (category.name && category.uniqid_url) {
      categoryName = category.name;
      category.url = category.uniqid_url;
    }

    items.push({
      name: capitalizeFirstLetter(categoryName),
      url: category.url,
    });

    if (
      category.uniqid === 'KAT_AKCIA_SEZONA' &&
      props.user &&
      props.user.skvelko_type !== ChematCustomerType.RETAIL
    ) {
      categoryName = __('Novinky / Sezóna');
      if (items && items.length >= 1) {
        items[0].name = __('Novinky / Sezóna');
      }
    }

    return (
      <MediaContextProvider>
        <ContainerIndent className="base">
          <GlobalStyle />
          <MetaTags tags={categoryMetaHandler(category, 'sk', 'Daffer.sk')} />
          <Row>
            <Media greaterThanOrEqual="lg">
              <CategoriesCol>
                {categoryGroup && categoryGroup.length ? (
                  <>
                    {isCartGiftsValid(settings, props.user) &&
                    currentCategoryGroup &&
                    currentCategoryGroup.uniqid !== 'FURNITURE_SCHOOL' ? (
                      <GiftImage
                        src={getGiftCategoryDesktopBannerFromSettings(settings)}
                        onClick={onGiftOpen}
                      />
                    ) : (
                      <></>
                    )}

                    <CategoriesListNew
                      dispatch={dispatch}
                      categoryGroup={categoryGroup}
                      user={props.user}
                    />
                  </>
                ) : (
                  ''
                )}
              </CategoriesCol>
            </Media>

            <Media lessThan="lg">
              {isCartGiftsValid(settings, props.user) ? (
                <GiftsBannerMobile
                  withoutPadding={true}
                  onLinkClick={onGiftOpen}
                />
              ) : (
                <></>
              )}
            </Media>

            <MainColWrapper>
              <Breadcrumbs items={items} />
              <CategoryTitle
                name={
                  categoryName
                    ? capitalizeFirstLetter(categoryName)
                    : categoryGroupName
                    ? capitalizeFirstLetter(categoryGroupName)
                    : ''
                }
              />
              {getMainCol()}
            </MainColWrapper>
          </Row>
        </ContainerIndent>
      </MediaContextProvider>
    );
  } else {
    return (
      <MediaContextProvider>
        <ContainerIndent className="base">
          <GlobalStyle />
          <MetaTags tags={brandMetaHandler(brand, 'sk', 'Daffer.sk')} />
          <Row>
            <Media greaterThanOrEqual="lg">
              <CategoriesCol>
                <CategoriesListNew
                  dispatch={dispatch}
                  categoryGroup={props.mainGroupCategory}
                />
              </CategoriesCol>
            </Media>
            <MainCol>
              <Breadcrumbs items={[{ name: brandName, url: brand.url }]} />
              {getMainCol()}
            </MainCol>
          </Row>
        </ContainerIndent>
      </MediaContextProvider>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    mainGroupCategory: state.general.mainGroupCategory,
    currentCategoryGroup: state.general.currentCategoryGroup,
    currentCategoryId: state.general.currentCategoryId,
    currentCategory: state.general.currentCategory,
    urlMapData: state.general.urlMapData,
    user: state.auth.user,
    settings: state.general.settings,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Category' })(Category),
);
