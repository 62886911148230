import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { ContentContainersStyles } from '../../_layout/Page/ContentContainer';
import { ButtonStyled } from '../form/Button';

export const ContentContainerBlock = styled.div`
  ${ContentContainersStyles};
  & + & {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
  &.inverse {
    background: #f3f5ff;
  }
`;

const ContactRefStyles = css`
  font-size: ${rem(16)};
  ${({ theme }) => theme.media(769)} {
    font-size: ${rem(18)};
  }
`;

export const ContactRef = styled.a`
  ${ContactRefStyles};
  font-weight: 400;
`;

export const ContactRefSpecial = styled.a`
  ${ContactRefStyles};
  font-weight: 600;
`;

export const ContactHeading = styled.h2`
  font-size: ${rem(24)};
  margin-bottom: ${rem(48)};
  color: #211e1f;
  font-weight: 400;
  position: relative;
  margin-left: ${rem(16)};
  ${({ theme }) => theme.media(769)} {
    margin-left: 0};
  }
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    background: ${({ theme }) => theme.color.secondary};
    width: ${rem(125)};
    height: ${rem(4)};
    bottom: ${rem(-20)};
    left: 0;
  }
  & > strong {
    font-weight: 800;
  }
`;

export const ContactButtonPrimary = styled(ButtonStyled)`
  width: ${rem(150)};
  height: ${rem(50)};
  border-radius: ${rem(25)};
  font-size: ${rem(16)};
  display: block;
  margin: 0 auto;
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(16)};
  }
`;

export const ContactButtonSecondaryWrapper = styled.div`
  margin-top: ${rem(16)};
`;

export const ContactButtonWrapper = styled.div`
  margin-top: ${rem(19)};
`;

const ContactButtonSecondarySmaller = css`
  width: ${rem(135)};
  height: ${rem(50)};
  span {
    margin: ${rem(8)} ${rem(6)} ${rem(8)} 0;
  }
`;

const ContactButtonSecondaryBigger = css`
  width: ${rem(190)};
  height: ${rem(34)};
  border-radius: ${rem(17)};
  font-size: ${rem(14)};
  span {
    margin: 0 ${rem(6)} 0 0;
  }
`;

const Icon = css`
  float: left;
  width: ${rem(18)};
  height: ${rem(18)};
`;

export const ContactButtonSecondary = styled(ButtonStyled)`
  width: auto;
  height: ${rem(40)};
  border-radius: ${rem(12)};
  font-size: ${rem(16)};
  color: ${({ theme }) => theme.color.text};
  display: block;
  text-align: left;
  span {
    margin-right: ${rem(8)};
  }
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(14)};
  }
  ${({ theme }) => theme.media(600)} {
    padding: 0;
    span {
      margin-right: ${rem(0)};
    }
    ${ContactButtonSecondarySmaller};
  }
  ${({ theme }) => theme.media(769)} {
    ${ContactButtonSecondaryBigger};
  }
  ${({ theme }) => theme.media(1024)} {
    ${ContactButtonSecondarySmaller};
  }
  ${({ theme }) => theme.media(1140)} {
    ${ContactButtonSecondaryBigger};
  }
  &.icon {
    padding-left: ${rem(12)};
  }

  ${({ theme }) => theme.media(360)} {
    margin: 0 0 0 0;
  }
`;

export const IconRightRed = styled.span`
  ${Icon};
  background: url('/images/icons/icon-right-red.png') no-repeat;
`;

export const IconPlusRed = styled.span`
  ${Icon};
  background: url('/images/icons/icon-plus-red.png') no-repeat;
`;

export const IconPlusWhite = styled.span`
  ${Icon};
  background: url('/images/icons/icon-plus-white.png') no-repeat;
`;

export const IconMinusRed = styled.span`
  ${Icon};
  background: url('/images/icons/icon-minus-red.png') no-repeat;
`;

export const IconMinusWhite = styled.span`
  ${Icon};
  background: url('/images/icons/icon-minus-white.png') no-repeat;
`;

export const MapImg = styled.img`
  margin-top: ${rem(16)};
  display: none;
  ${({ theme }) => theme.media('sm')} {
    display: block;
  }
`;
