import * as React from 'react';
import Finished from './Finished';

class FinishedCompany extends React.Component {
  public render() {
    return <Finished textId={'REG_FINISHED_SCHOOL'} />;
  }
}

export default FinishedCompany;
