import React from 'react';
import API, { ThenArg } from '../../../services/API';
import {
  loadMostFrequentPurchased,
  removeFromUsersFavouriteProducts,
} from '../../../containers/Ucet/actions';
import { __ } from 'react-i18n/lib';
import ProductPreviews from './OrderItemPreview';
import { prop } from '../../../utilities';
import { Loader } from '../../_helpers/Loader/Loader';

interface MostFrequentPurchasedProps {
  dispatch: any;
  user: any;
  cart: ThenArg<typeof API.getCart>;
  mostFrequentPurchased:
    | ThenArg<typeof API.loadMostFrequentPurchased>
    | undefined;
  mostFrequentPurchasedLoader: boolean;
}

const MostFrequentPurchased = (props: MostFrequentPurchasedProps) => {
  const loadMore = () => {
    props.dispatch(loadMostFrequentPurchased());
  };

  const {
    mostFrequentPurchased,
    mostFrequentPurchasedLoader,
    user,
    cart,
    dispatch,
  } = props;

  const items = prop(mostFrequentPurchased, 'products', []);
  const total = prop(mostFrequentPurchased, 'total', 0);

  return (
    <>
      {items && items.length ? (
        <>
          <Loader loading={mostFrequentPurchasedLoader || false}>
            <ProductPreviews
              items={items}
              total={total}
              user={user}
              cart={cart}
              loadMore={loadMore}
              dispatch={dispatch}
              removeProduct={removeFromUsersFavouriteProducts}
              fromFavoritesProducts={false}
            />
          </Loader>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default MostFrequentPurchased;
