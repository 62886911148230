import * as React from 'react';
import styled from 'styled-components';
import { Link, WithRouterProps } from 'react-router';
import { rem, rgba } from 'polished';
import { __ } from 'react-i18n/lib';
import { capitalizeFirstLetter, getUrl, prop } from '../../../utilities';
import { connect } from 'react-redux';
import { SCHOOL_ARTICLES_UNIQID } from '../../../constants';

interface NavigationProps {
  cmsTree: any;
  schoolArticle: any;
  singleSchoolArticle: any;
  currentUniqs: string[];
}

function CustomCMSMenu({
  cmsTree = [],
  schoolArticle,
  singleSchoolArticle,
  currentUniqs,
}: NavigationProps & WithRouterProps) {
  const [currentLink, setcurrentLink] = React.useState('');

  React.useEffect(() => {
    if (window && window.location) {
      setcurrentLink(window.location.href);
    }
  }, [
    window && window.location ? window.location.href : '',
    singleSchoolArticle,
  ]);

  return (
    <CCList>
      {cmsTree && cmsTree.length
        ? cmsTree.map((item, i) => {
            const link = item.link ? getUrl(item.link) : getUrl(item.url);

            const childrenLinks =
              item.children && item.children.length
                ? item.children.map((item) => {
                    return item.link ? getUrl(item.link) : getUrl(item.url);
                  })
                : [];

            childrenLinks.push(link);

            let isCurrent = false;

            if (currentUniqs && currentUniqs.length) {
              isCurrent = currentUniqs.includes(item.uniqid);
            } else {
              isCurrent =
                childrenLinks.some((v) => currentLink.includes(v)) ||
                (item.uniqid === SCHOOL_ARTICLES_UNIQID &&
                  schoolArticle &&
                  Object.values(schoolArticle)
                    .map((sa: any) => getUrl(sa.url))
                    .some((v) => currentLink.includes(v)));
            }

            return (
              <CCItem key={i}>
                <CCLink
                  className={isCurrent ? 'current' : ''}
                  to={item.link ? getUrl(item.link) : getUrl(item.url)}
                >
                  <CCLinkText
                    dangerouslySetInnerHTML={{
                      __html: capitalizeFirstLetter(item.name),
                    }}
                  ></CCLinkText>
                </CCLink>
              </CCItem>
            );
          })
        : ''}
    </CCList>
  );
}

const CCList = styled.ul`
  display: none;
  color: ${({ theme }) => theme.color.white};
  align-items: stretch;
  font-size: ${rem(15)};
  font-weight: 700;
  width: 100%;
  margin: 0 0 ${rem(-25)};
  padding: 0;
  list-style: none;
  position: relative;
  ${({ theme }) => theme.media('md')} {
    display: flex;
  }
`;

const CCLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.primary};
  border-right: ${rem(1)} solid ${({ theme }) => rgba(theme.color.black, 0.1)};
  border-left: ${rem(1)} solid ${({ theme }) => rgba(theme.color.white, 0.1)};
  color: inherit;
  text-align: center;
  height: ${rem(50)};
  padding: ${rem(3)} ${rem(8)};
  &:hover,
  &.current {
    background: ${({ theme }) => theme.color.secondary};
    color: ${({ theme }) => theme.color.black};
    text-decoration: none;
  }
`;

const CCLinkText = styled.span`
  max-width: ${rem(150)};
`;

const SubList = styled.ul`
  display: none;
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  color: ${({ theme }) => theme.color.black};
  flex-wrap: wrap;
  background: ${({ theme }) => theme.color.white};
  width: 100%;
  padding: ${rem(2)} ${rem(9)} ${rem(6)};
  margin: 0;
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: ${({ theme }) => theme.boxShadow.categories};
`;

const CCItem = styled.li`
  flex: 1 1 auto;
  &:first-child ${CCLink} {
    border-top-left-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-bottom-left-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
  }
  &:last-child ${CCLink} {
    border-top-right-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    border-bottom-right-radius: ${({ theme }) =>
      rem(theme.borderRadius.default)};
  }
  &.hover ${SubList} {
    display: flex;
  }
`;

const mapStateToProps = (state) => {
  return {
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    urlMapData: prop(state, 'general.urlMapData'),
    currentCategoryGroupId: state.general.currentCategoryGroupId,
    schoolArticle: state.general.schoolArticle,
    singleSchoolArticle: state.general.singleSchoolArticle,
    currentUniqs: prop(state, 'general.currentUniqs'),
  };
};

export default connect(mapStateToProps)(CustomCMSMenu);
