import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem, rgba } from 'polished';
import { Container, ContainerClearfix } from '../../../theme/libraries/grid';
import {
  BlockAppearanceStyles,
  BlockMarginStyles,
} from '../../_helpers/Block/Block';
import MetaTags from '../../MetaTags/MetaTags';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';

const Wrapper = styled(ContainerClearfix)`
  padding: 0;
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(20)};
  }
`;

export const ContentContainersStyles = css`
  padding: ${rem(10)} ${rem(16)};
  ${({ theme }) => theme.media('md')} {
    padding: ${rem(40)};
  }
`;

export const Content = styled.div`
  ${BlockAppearanceStyles};
  ${BlockMarginStyles};
`;

export const Header = styled.header`
  padding: ${rem(20)} ${rem(40)} 0 ${rem(40)};
`;

export const Title = styled.h1`
  font-size: ${rem(24)};
  margin: 0 0 ${rem(6)};
  line-height: 1;
  color: #211e1f;
  font-weight: 800;
  position: relative;
  margin-bottom: ${rem(30)};
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    background: ${({ theme }) => theme.color.secondary};
    width: ${rem(125)};
    height: ${rem(4)};
    bottom: ${rem(-10)};
    left: 0;
  }
  ${({ theme }) => theme.media(600)} {
    font-size: ${rem(36)};
  }
`;

export const TitleSecondary = styled(Title)`
  font-size: ${rem(16)};
  ${({ theme }) => theme.media(600)} {
    font-size: ${rem(24)};
  }
`;

export const TitleCount = styled.span`
  display: inline-block;
  background: ${({ theme }) => rgba(theme.color.black, 0.03)};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  font-size: ${rem(18)};
  font-weight: 400;
  line-height: 1;
  padding: ${rem(11)} ${rem(10)};
  margin-left: ${rem(10)};
`;

const Body = styled.div``;

export const ContainerIndent = styled(Container)`
  //padding-top: ${rem(10)};
  ${({ theme }) => theme.media('md')} {
    padding-top: ${rem(20)};
  }
`;

interface ContentContainerProps {
  children: any;
  breadcrumbsItems?: any;
  title?: string;
  tags?: any;
}

class ContentContainer extends React.PureComponent<ContentContainerProps> {
  public render() {
    const { title, breadcrumbsItems } = this.props;

    return (
      <Wrapper className="base">
        <Breadcrumbs items={breadcrumbsItems} />
        {this.getMetaTags()}
        <Content>
          {title ? (
            <Header>
              <Title>{title}</Title>
            </Header>
          ) : (
            ''
          )}
          <Body>{this.props.children}</Body>
        </Content>
      </Wrapper>
    );
  }

  public getMetaTags = () => {
    const { title, tags } = this.props;
    let customTitle = title;
    if (tags && tags.title) {
      customTitle = tags.title;
    }

    if (tags) {
      return <MetaTags tags={tags} />;
    } else if (title) {
      return <MetaTags tags={{ title: `Daffer.sk - ${customTitle}` }} />;
    } else {
      return '';
    }
  };
}

export default ContentContainer;
