import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Footer, NextButton } from './Cart1';
import { CartHeading } from './CartStyles';
import Icon from '../_helpers/Icon/Icon';
import { Content } from '../_layout/Page/ContentContainer';
import Alert from '../Alert/Alert';
import API from '../../services/API';
import {
  isSsr,
  formatPrice,
  scrollToElement,
  isTrustpay,
  isTrustCard,
  isGpWebPay,
  getPaymentDescription,
  showPriceWithVat,
  isOrderPaid,
} from '../../utilities';
import CartRadios from './CartRadios';
import { FlexRowCenterVertical } from '../_helpers/Flexbox/Flexbox';
import { Loader } from '../_helpers/Loader/Loader';
import { ContentContainerBlock } from '../_helpers/Contact/ContactStyles';
import { sendPurchaseEvent } from '../../utilities/ga4_events';

const Wrapper = styled(Content)`
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(40)};
  }
`;

const ThankYouContainerBlock = styled(ContentContainerBlock)`
  padding-top: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    padding-top: ${rem(40)};
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${rem(24)};
`;

export const Heading = styled(CartHeading)`
  margin: 0;
`;

export const HeadingIconStyles = css`
  ${({ theme }) => theme.size(55)};
  fill: ${({ theme }) => theme.color.success};
  &.error {
    fill: ${({ theme }) => theme.color.error};
  }
`;

const HeadingIcon = styled(Icon)`
  ${HeadingIconStyles};
  margin-right: ${rem(15)};
`;

const Text = styled.p`
  margin: ${rem(4)} 0;
  font-size: ${rem(16)};
  &.error {
    color: ${({ theme }) => theme.color.error};
  }
`;

const EditorText = styled(Text)`
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const ThankYouFooter = styled(Footer)`
  margin-bottom: 0;
`;

const PayOrderWrapper = styled(FlexRowCenterVertical)`
  margin-top: ${rem(20)};
`;

const PayImageWrapper = styled.div`
  width: ${rem(50)};
  height: ${rem(40)};
  margin-right: ${rem(15)};
`;

const Button = styled(NextButton)`
  margin-top: ${rem(30)};
`;

const PaymentStatus = styled.p`
  margin: 0;
  font-size: ${rem(23)};
  font-weight: bold;
  &.success {
    color: ${({ theme }) => theme.color.success};
  }
  &.error {
    color: ${({ theme }) => theme.color.error};
  }
`;

const TrustpayIframeWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
`;

interface ThankYouProps {
  user: any;
  order: any;
  dispatch: any;
}

const ThankYou = (props: ThankYouProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [payments, setPayments] = React.useState([]);
  const [paymentSelected, setPaymentSelected] = React.useState(undefined);
  const [paymentChangeError, setPaymentChangeError] = React.useState('');

  const { order, user } = props;
  const isError = window?.location?.href?.includes('error');
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const publicId = props?.order?.public_id;
  const paymentType = order?.payment?.payment_type;
  const orderPayment = (order?.payment?.translation || []).find(
    (p) => p?.lang_id === 'sk',
  );
  const orderDelivery = (order?.delivery?.translation || []).find(
    (d) => d?.lang_id === 'sk',
  );
  const textThanksPayment = orderPayment?.payment_thanks;
  const textFailurePayment = orderPayment?.payment_failure;
  const textThanksDelivery = orderDelivery?.delivery_thanks;
  const textWarningDelivery = orderDelivery?.delivery_warning;
  const paymentIsTrustpay = isTrustpay(paymentType);
  const paymentIsTrustcard = isTrustCard(paymentType);
  const paymentIsGpWebPay = isGpWebPay(paymentType);
  const showWithVat = showPriceWithVat(user);
  const paymentIsOnline =
    paymentIsTrustpay || paymentIsTrustcard || paymentIsGpWebPay;
  const isPaid =
    order.payment_status === 1 || order.order_paid_datetime !== null;

  const paymentCanChange = !isPaid && isError && paymentIsOnline;
  const isCanceled = order?.order_status === 'CANCELLED';
  let trustPayPaymentElement;

  const redirectPay = (payment): boolean => {
    if (isTrustCard(payment)) {
      onTrustCardClick();
    } else if (isTrustpay(payment)) {
      onTrustPayClick();
    } else if (isGpWebPay(payment)) {
      onGpWebPayClick();
    } else {
      return false;
    }
    return true;
  };

  React.useEffect(() => {
    const gtm = process.env.REACT_APP_GTM;
    const pixel = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

    if (
      order &&
      !isSsr() &&
      order.analytics_sended === 0 &&
      isOrderPaid(order)
    ) {
      const totalPrice = order.payment?.order_tprice;
      const paymentPrice = order.payment?.payment_price;
      const deliveryPrice = order.payment?.delivery_price;
      const revenuePrice =
        Math.round((totalPrice - paymentPrice - deliveryPrice) * 100) / 100;
      const vatRate = order.payment.vat_rate;
      let itemTax = -1;

      if (vatRate !== 0) {
        itemTax = Math.round(revenuePrice * (vatRate / 100) * 100) / 100; // calculate tax
      }

      // Facebook Pixel
      // Facebook Pixel
      if (pixel) {
        import('react-facebook-pixel')
          .then((module) => module.default)
          .then((ReactPixel) => {
            ReactPixel.init(pixel, undefined, {
              autoConfig: true,
              debug: false,
            });
            ReactPixel.pageView();
            const contentsIds: number[] = [];
            const contentType = 'product';
            const contents = [] as any;
            order.items?.forEach((item) => {
              const id = item?.product_id;
              const quantity = item?.quantity;
              if (id && quantity) {
                const content = {
                  id,
                  quantity,
                };
                contentsIds.push(item.product_id);
                contents.push(content);
              }
            });
            ReactPixel.track('Purchase', {
              currency: 'EUR',
              value: revenuePrice,
              content_ids: contentsIds,
              content_type: contentType,
              contents,
            });
          });
      }

      // Google Tag Manager / Google Analytics
      if (gtm) {
        const items: any[] = [];
        order.items?.forEach((item) => {
          const itemId = item?.product_id;
          const itemName = item?.product_name;
          const itemPrice = item?.unitprice;
          const itemQuantity = item?.quantity;
          if (itemId && itemName && itemPrice && itemQuantity) {
            items.push({
              name: itemName,
              id: itemId,
              price: itemPrice,
              quantity: itemQuantity,
            });
          }
        });

        window.dataLayer.push({
          event: 'trackTrans',
          ecommerce: {
            purchase: {
              actionField: {
                id: order.order_nr,
                revenue: revenuePrice,
                tax: itemTax,
                shipping: deliveryPrice,
              },
              products: items,
            },
          },
        });

        sendPurchaseEvent({
          transaction_id: order.order_nr,
          shipping: deliveryPrice,
          value: revenuePrice,
          items: items.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              price: item.price,
              quantity: item.quantity,
            };
          }),
        });
      }

      API.updateOrderAnalytics(order.public_id).catch(() =>
        console.error('Update order analytics failed!'),
      );
    }

    if (window?.location?.href?.includes('error')) {
      setPaymentSelected(order.payment?.payment_type);
      if (isPaid) {
        removeErrorAndRedirect();
      } else {
        API.getPaymentsInfo(order.public_id, {}).then(
          (paymentResponse: any) => {
            setPayments(paymentResponse?.payment);
            setIsLoading(false);
          },
        );
      }
    } else if (
      window?.location?.href?.includes('redirect') &&
      !isPaid &&
      !paymentIsTrustpay
    ) {
      redirectPay(paymentType);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }

    if (!order.isDemand) {
      loadScripts();
    }
  }, []);

  const loadScripts = () => {
    const existingScript = document.getElementById('jquery');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = '/js/jquery.min.js';
      script.id = 'jquery_script';
      document.body.appendChild(script);
      script.onload = () => {
        const trustpay = document.getElementById('jquery');
        if (!trustpay) {
          const script = document.createElement('script');
          script.src =
            'https://mapi.trustpay.eu/mapi5/Scripts/TrustPay/popup.js';
          script.id = 'trustpay_script';
          document.body.appendChild(script);
        }
      };
    }
  };

  const onTrustPayClick = () =>
    (window.location.href = `${apiUrl}/payment/trustpay/${publicId}?type=bank`);

  const onTrustCardClick = () =>
    (window.location.href = `${apiUrl}/payment/trustpay/${publicId}?type=card`);

  const onGpWebPayClick = () =>
    (window.location.href = `${apiUrl}/payment/gpwebpay/${publicId}?type=card`);

  const removeErrorAndRedirect = () =>
    (window.location.href = window.location.href
      .replace('?redirect', '')
      .replace('?error', ''));

  const getDeliveryAddress = (order) => {
    const deliverySchoolBaseInfo = order?.delivery_school_base_info;
    if (deliverySchoolBaseInfo) {
      const street = deliverySchoolBaseInfo.street;
      const streetNum = deliverySchoolBaseInfo.street_number;
      const zip = deliverySchoolBaseInfo.zip;
      const city = deliverySchoolBaseInfo.city;
      return `${street} ${streetNum}, ${zip} ${city}`;
    }

    const delivery = order.delivery;
    const company = order.company;
    const person = delivery?.person;
    const name =
      company?.name !== '' ? company?.name : `${person.name} ${person.surname}`;
    const street = delivery?.delivery_addr_street;
    const streetNum = delivery?.delivery_addr_street_number;
    const zip = delivery?.delivery_addr_zip;
    const city = delivery?.delivery_addr_city;
    const country = delivery?.delivery_addr_country;
    return `${name}, ${street} ${streetNum}, ${zip}, ${city}, ${country}`;
  };

  const getDemandDeliveryAddress = (order) => {
    const phone = order?.person?.phone ? order?.person?.phone : '';
    const name = order?.person?.name ? order?.person?.name : '';
    const surname = order?.person?.surname ? order?.person?.surname : '';

    return `${phone}, ${name} ${surname}`;
  };

  const paymentOptions = {
    name: 'payment',
    items: payments
      .filter((payment: any) => {
        if (
          order?.disable_card_payments &&
          (payment?.payment_id == 'GPWEBPAY' ||
            payment?.payment_id == 'TRUSTCARD')
        )
          return false;
        return true;
      })
      .map((payment: any) => {
        return {
          id: payment?.payment_id,
          label: payment?.payment_name,
          price: payment?.price.price,
          description: getPaymentDescription(payment),
        };
      }),
  };

  const onPaymentChange = (e) => {
    const value = e?.target?.value;
    if (value) {
      setPaymentSelected(value);
    }
  };

  const paymentChange = () =>
    API.changeOrderPayment(order.public_id, { paymentType: paymentSelected })
      .then(() => {
        if (paymentSelected === 'TRUSTPAY') {
          trustPayPaymentElement.click();
        } else if (!redirectPay(paymentSelected)) {
          removeErrorAndRedirect();
        }
      })
      .catch((r) => {
        const paymentError = r?.details?.description;
        if (paymentError) {
          setPaymentChangeError(paymentError);
        } else {
          setPaymentChangeError(
            __('Pri zmene spôsoby platby sa vyskytol problém'),
          );
        }
        scrollToElement('error');
      });

  const renderDemandOrder = () => (
    <>
      <HeadingWrapper>
        <HeadingIcon icon="checkCircle" />
        <Heading>{__('Ďakujeme za Vašu žiadosť o cenovú kalkuláciu')}</Heading>
      </HeadingWrapper>

      <Text>
        {__('Info o žiadosti zasielame na')}:{' '}
        <strong>{order.person.email}</strong>
      </Text>

      <Text>
        {__('Číslo žiadosti')}: <strong>{order.order_nr}</strong>
      </Text>

      <Text>
        {__('Kontaktné údaje')}:{' '}
        <strong>{getDemandDeliveryAddress(order)}</strong>
      </Text>
    </>
  );

  const renderClassicOrder = () => (
    <>
      <HeadingWrapper>
        <HeadingIcon icon="checkCircle" />
        <Heading>{__('Ďakujeme za Váš nákup')}</Heading>
      </HeadingWrapper>

      {(paymentIsOnline &&
        (isError ? (
          <PaymentStatus className={'error'}>
            {__(
              'Platba nebola úspešná. Pre dokončenie objednávky je potrebné dokončiť platbu.',
            )}
          </PaymentStatus>
        ) : isPaid ? (
          <PaymentStatus className={'success'}>
            {__('Platba prebehla úspešne.')}
          </PaymentStatus>
        ) : (
          ''
        ))) ||
        ''}

      <Text>
        {__('Info o objednávke zasielame na')}:{' '}
        <strong>{order.person.email}</strong>
      </Text>

      {(textWarningDelivery && textWarningDelivery !== '' && (
        <EditorText
          dangerouslySetInnerHTML={{
            __html: textWarningDelivery,
          }}
        />
      )) ||
        ''}

      <Text>
        {__('Číslo objednávky')}: <strong>{order.order_nr}</strong>
      </Text>

      <Text>
        {__('Celková suma objednávky')}:{' '}
        <strong>{formatPrice(order.payment.order_tprice, 'EUR')}</strong>
      </Text>

      {order.delivery.delivery_typename ? (
        <Text>
          {__('Spôsob dopravy')}:{' '}
          <strong>{order.delivery.delivery_typename}</strong>
        </Text>
      ) : (
        ''
      )}

      <Text>
        {__('Adresa doručenia')}: <strong>{getDeliveryAddress(order)}</strong>
      </Text>

      {(textThanksDelivery && textThanksDelivery !== '' && (
        <EditorText
          dangerouslySetInnerHTML={{
            __html: textThanksDelivery,
          }}
        />
      )) ||
        ''}

      {order.payment.payment_typename !== 'undefined' ? (
        <Text>
          {__('Spôsob platby')}:{' '}
          <strong>{order.payment.payment_typename}</strong>
        </Text>
      ) : (
        ''
      )}

      {(textThanksPayment && textThanksPayment !== '' && !paymentIsOnline && (
        <Text>{textThanksPayment}</Text>
      )) ||
        ''}

      {(textFailurePayment && textFailurePayment !== '' && isError && (
        <Text className={'error'}>{textFailurePayment}</Text>
      )) ||
        ''}

      {paymentCanChange && (
        <>
          {paymentChangeError && paymentChangeError !== '' && (
            <Alert type="error" className="error">
              {paymentChangeError}
            </Alert>
          )}
          <CartRadios
            data={paymentOptions}
            onChange={onPaymentChange}
            user={user}
            selected={paymentSelected}
            showWithVat={showWithVat}
          />
          <Button className="primary lg" onClick={paymentChange}>
            {__('Zaplatiť objednávku')}
          </Button>
        </>
      )}

      <ThankYouFooter>
        {!isPaid && !isError && (
          <>
            {paymentIsTrustpay && (
              <PayOrderWrapper>
                <PayImageWrapper>
                  <img
                    src="/images/cart/payments/trustpay.png"
                    alt={'payment_icon'}
                  />
                </PayImageWrapper>
                <NextButton
                  className="primary lg show-popup"
                  //onClick={onTrustPayClick}
                >
                  {__('Zaplatiť objednávku')}
                </NextButton>
              </PayOrderWrapper>
            )}
            {paymentIsTrustcard && (
              <PayOrderWrapper>
                <PayImageWrapper>
                  <img
                    src="/images/cart/payments/trustpay.png"
                    alt={'payment_icon'}
                  />
                </PayImageWrapper>
                <NextButton className="primary lg" onClick={onTrustCardClick}>
                  {__('Zaplatiť objednávku')}
                </NextButton>
              </PayOrderWrapper>
            )}

            {paymentIsGpWebPay && (
              <PayOrderWrapper>
                <PayImageWrapper>
                  <img
                    src="/images/cart/payments/gp_webpay.jpg"
                    alt={'payment_icon'}
                  />
                </PayImageWrapper>
                <NextButton className="primary lg" onClick={onGpWebPayClick}>
                  {__('Zaplatiť objednávku')}
                </NextButton>
              </PayOrderWrapper>
            )}
          </>
        )}
      </ThankYouFooter>
    </>
  );

  return (
    <>
      {!order.isDemand ? (
        <TrustpayIframeWrapper>
          <iframe id="TrustPayFrame" src={order.paymentUrl}></iframe>
        </TrustpayIframeWrapper>
      ) : (
        ''
      )}
      <Loader loading={isLoading}>
        <input
          ref={(input) => (trustPayPaymentElement = input)}
          type="hidden"
          className="show-popup"
        />
        <Wrapper>
          <ThankYouContainerBlock>
            {isCanceled ? (
              <>
                <HeadingWrapper>
                  <HeadingIcon icon="statusError" className={'error'} />
                  <Heading>{__('Objednávka zrušená')}</Heading>
                </HeadingWrapper>

                <Text>
                  {__('Číslo objednávky')}: <strong>{order.order_nr}</strong>
                </Text>
              </>
            ) : order.isDemand ? (
              <>{renderDemandOrder()}</>
            ) : (
              <>{renderClassicOrder()}</>
            )}
          </ThankYouContainerBlock>
        </Wrapper>
      </Loader>
    </>
  );
};

export default ThankYou;
