import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { formatPrice, formatStringDate } from '../../utilities';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.color.success};
  padding: ${rem(20)};
  margin-top: ${rem(2)};
  ${({ theme }) => theme.media('sm')} {
    margin-top: 0;
  }
  & > p {
    width: 100%;
  }
`;

const Text = styled.p`
  display: inline-block;
  color: ${({ theme }) => theme.color.white};
  font-size: ${rem(16)};
  margin: 0;
`;

const TextCode = styled(Text)`
  font-weight: 600;
  text-align: left;
`;

const TextCodeBlack = styled(TextCode)`
  color: ${({ theme }) => theme.color.black};
`;

const TextDiscount = styled(Text)`
  float: right;
  text-align: right;
  text-transform: uppercase;
`;

const TextDiscountNotValid = styled(TextDiscount)`
  text-transform: none;
  color: #ffde00;
  background: #0d5805;
  font-weight: 600;
  padding: 0 ${rem(10)};
`;

const TextDiscountNoMobile = styled(TextDiscount)`
  display: none;
  ${({ theme }) => theme.media('sm')} {
    display: inline-block;
  }
`;

const TextDescription = styled(Text)`
  display: block;
`;

const Delimeter = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${rem(2)};
  height: ${rem(2)};
  position: relative;
  overflow: hidden;
  margin: ${rem(10)} 0;
`;

interface Props {
  cart: any;
}

class CartVoucherApplied extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      cart,
      cart: {
        customer_card_discount,
        customer_card,
        total_customer_card_price,
        is_giftcards_valid,
        total_items_price,
      },
    } = this.props;
    const giftcard = cart?.giftcards?.length && cart.giftcards[0];
    const {
      discount,
      price_validity,
      discount_currency,
      freeshipping,
      card_number,
      date_validity,
      count_use,
      price,
    } = giftcard;
    const isDiscount = discount_currency || discount;
    const isNominalDiscount = !!discount_currency;

    const discountPrice =
      cart.total_items_price - cart.total_items_price_with_discount;

    return (
      <Wrapper>
        {customer_card_discount && customer_card ? (
          <Text>
            <TextCode>{`${__('Zákaznícka karta')} -`}&nbsp;</TextCode>
            <TextCodeBlack>{customer_card}</TextCodeBlack>
            <TextDiscountNoMobile>
              {__('Výška zľavy')}&nbsp;
              <strong>{`-${customer_card_discount} %`}</strong>
            </TextDiscountNoMobile>
            {total_customer_card_price ? (
              <TextDescription>
                {`${__('Zľava')} ${customer_card_discount}% ${__(
                  'na celý nákup',
                )}. ${__('Neplatí pre vybraný a akciový tovar')}.`}
              </TextDescription>
            ) : (
              <TextDescription>
                {`${__(
                  'Zákaznícku kartu nie je možné kombinovať so zľavovým kupónom',
                )}.`}
              </TextDescription>
            )}
            <Delimeter />
            {total_customer_card_price ? (
              <TextDiscount>
                {`${__('Ušetrili ste')} `}
                <strong>
                  -{formatPrice(total_customer_card_price, 'EUR')}
                </strong>
              </TextDiscount>
            ) : (
              ''
            )}
          </Text>
        ) : (
          ''
        )}
        {giftcard ? (
          <Text>
            <TextCode>{`${__('Zľavový kupón')} -`}&nbsp;</TextCode>
            <TextCodeBlack>{card_number}</TextCodeBlack>
            {isDiscount ? (
              <TextDiscountNoMobile>
                {__('Výška zľavy')}&nbsp;
                {discount_currency ? (
                  <strong>-{formatPrice(discount, 'EUR')}</strong>
                ) : (
                  <strong>-{discount} %</strong>
                )}
              </TextDiscountNoMobile>
            ) : (
              ''
            )}
            <TextDescription>
              {isDiscount ? (
                <>
                  {`${__('Zľava')} `}
                  {discount_currency
                    ? formatPrice(discount, 'EUR')
                    : `${discount} %`}
                  {__(' na celý nákup')}
                  {freeshipping ? __(' a doprava zdarma') : ''}
                </>
              ) : freeshipping ? (
                __('Doprava zdarma')
              ) : (
                ''
              )}
              {price_validity ? __(' pri nákupe nad ') : ''}
              {price_validity
                ? formatPrice(price_validity, 'EUR', 2, false, true)
                : ''}
              {date_validity
                ? `${__(' do ')} ${formatStringDate(date_validity)}`
                : ''}
              {count_use
                ? `. ${__('Kupón je možné použiť ')} ${count_use} krát`
                : ''}
              {isDiscount && !isNominalDiscount
                ? `. ${__('Neplatí pre vybraný a akciový tovar')}`
                : ''}
              .
            </TextDescription>
            {price_validity &&
            cart &&
            !is_giftcards_valid &&
            total_items_price < price_validity ? (
              <>
                <Delimeter />
                <TextDiscountNotValid>
                  {__('Zlavu získate až pri nákupe nad')}&nbsp;
                  {formatPrice(price_validity, 'EUR', 2, false, true)}
                </TextDiscountNotValid>
              </>
            ) : isDiscount ? (
              <>
                <Delimeter />
                <TextDiscount>
                  {__('Ušetrili ste')}&nbsp;
                  <strong>
                    {formatPrice(
                      discountPrice > price ? price : discountPrice,
                      'EUR',
                    )}
                  </strong>
                </TextDiscount>
              </>
            ) : (
              ''
            )}
          </Text>
        ) : (
          ''
        )}
      </Wrapper>
    );
  }
}

export default CartVoucherApplied;
