import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Container } from '../../../theme/libraries/grid';
import { ButtonStyledStyles, ButtonStyles } from '../form/Button';
import { prop } from '../../../utilities';
import ChooseModal from '../Modal/ChooseModal';
import { connect } from 'react-redux';
import { MediaContextProvider } from '../../../theme/media';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.secondary};
  font-size: ${rem(12)};
  padding: ${rem(10)};
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 12;
  p {
    margin: 0;
  }
`;

export const StyledContianer = styled(Container)`
  ${({ theme }) => theme.media(640)} {
  }

  ${({ theme }) => theme.media('md')} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: ${rem(190)};
  }
  ${({ theme }) => theme.media(1530)} {
    padding-right: 0;
  }
`;

export const Buttons = styled.div`
  margin-top: ${rem(10)};
  ${({ theme }) => theme.media('md')} {
    flex: 0 0 auto;
    margin-top: 0;
    margin-left: ${rem(10)};
  }
`;

const CookiesBtnStyles = css`
  font-size: ${rem(12)};
  border-color: transparent;
  padding: ${rem(5)};
  margin-right: ${rem(5)};
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(12)};
  }
`;

/*
const MoreInfo = styled(Link)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${CookiesBtnStyles};
`;
*/

export const Agree = styled.button`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  ${CookiesBtnStyles};
`;

export const BottomLink = styled(Link)``;

interface Props {
  text: any;
  onClickAgree: () => void;
  onClickDisAgree: () => void;
  currentPath?: string;
}

class Cookies extends React.PureComponent<Props> {
  public render() {
    return (
      <MediaContextProvider>
        {this.props.currentPath !== '/cookies' ? (
          <ChooseModal
            title={__('Používanie cookies')}
            bodyText={
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      this.props?.text?.content?.json_content?.body +
                      " <a href='/sukromie'> Viac informácií </a> ",
                  }}
                />
              </>
            }
            button1Text={__('Nesúhlasím')}
            button2Text={__('Súhlasím')}
            onButton1Click={this.props.onClickDisAgree}
            onButton2Click={this.props.onClickAgree}
            rightPrimary={false}
            /*
            footer={
              <StyledFooter>
                <Button2
                  className="primary lg"
                  onClick={this.props.onClickAgree}
                >
                  {__('Súhlasím')}
                </Button2>
                <Button1 className="lg" onClick={this.props.onClickDisAgree}>
                  {__('Nesúhlasím')}
                </Button1>
              </StyledFooter>
            }
            */
            notShowClose={true}
          />
        ) : (
          ''
        )}
        {/*
        <Media greaterThanOrEqual="lg">
          <Wrapper>
            <StyledContianer className="base">
              {this.props.text.content ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props?.text?.content?.json_content?.body,
                  }}
                />
              ) : (
                ''
              )}
              <Buttons>
                <MoreInfo to="/sukromie">{__('Viac informácií')}</MoreInfo>
                <Agree className="primary" onClick={this.props.onClickAgree}>
                  {__('Súhlasím')}
                </Agree>
              </Buttons>
            </StyledContianer>
          </Wrapper>
        </Media>
        */}
      </MediaContextProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
  };
};

export default connect(mapStateToProps)(Cookies);
