import React from 'react';
import { capitalizeFirstLetter, getUrl, prop } from '../../../utilities';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import { Link } from 'react-router';
import styled, { css } from 'styled-components';
import ImagePlaceholder from '../../_helpers/Image/ImagePlaceholder';
import { ButtonDisableFocusOutline } from '../../_helpers/form/Button';
import { push } from 'react-router-redux';
import { Media, MediaContextProvider } from '../../../theme/media';
//import { URL_SETS } from '../../../constants';

const Wrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;

const Item = styled.li`
  display: flex;
  flex: 0 0 ${({ theme }) => theme.grid.col.col12};
  align-items: center;
  justify-content: space-between;
  padding: 0 ${rem(16)};
  margin: 0;
  border-bottom: solid ${rem(2)} ${({ theme }) => theme.color.gray95};

  &.special {
    background-color: ${({ theme }) => theme.color.secondary};
    padding-top: ${rem(8)};
    .content {
      justify-content: right;
    }
    img {
      width: auto;
      height: auto;
    }
  }
  ${({ theme }) => theme.media('sm')} {
    border: none;
    flex: 0 0 ${({ theme }) => theme.grid.col.col6};
    &.special {
      border: none;
      background-color: ${({ theme }) => theme.color.secondaryLighter};
    }
  }
`;

const ThumbnailWrap = styled(Link)`
  min-width: ${rem(60)};
  display: flex;
  justify-content: center;
  align-self: center;
  height: ${rem(84)};
  ${({ theme }) => theme.media('sm')} {
    min-width: ${rem(64)};
  }
`;

const Thumbnail = styled(ImagePlaceholder)`
  width: ${rem(60)};
  height: ${rem(60)};
  ${({ theme }) => theme.media('sm')} {
    width: ${rem(64)};
    height: ${rem(64)};
  }
`;

const ItemContent = styled.div`
  padding-left: ${rem(16)};
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 2;
  text-align: left;
`;

const Title = styled(Link)`
  color: ${({ theme }) => theme.color.black};
  font-size: ${rem(16)};
  font-weight: 700;
  margin: 0;
  &.hover {
    cursor: pointer;
  }
  ${({ theme }) => theme.media('sm')} {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const Items = styled.p`
  line-height: ${16 / 14};
  margin: 0;
  display: none;
  ${({ theme }) => theme.media('sm')} {
    display: block;
  }
`;

const ItemPipe = styled.span`
  display: inline-block;
  margin: 0 ${rem(6)};
  color: ${({ theme }) => theme.color.primary};
`;

const TextCss = css`
  color: inherit;
  font-size: ${rem(14)};
  font-weight: 400;
  margin: 0;
  &.special {
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

/*
const Description = styled.p`
  ${TextCss};
  display: none;
  ${({ theme }) => theme.media('md')} {
    display: inline-block;
  }
`;
*/

const ItemTextLink = styled(Link)`
  ${TextCss};
`;

const Hr = styled.hr`
  border: none;
  color: ${({ theme }) => theme.color.gray95};
  background-color: ${({ theme }) => theme.color.gray95};
  margin: ${rem(8)} auto;
  width: 75%;
  height: ${rem(2)};
`;

const Button = styled.button`
  ${ButtonDisableFocusOutline};
  width: fit-content;
  height: fit-content;
  line-height: 0.5;
  padding: ${rem(4)};
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.gray70};
  border-radius: 50%;
  border: none;
  font-weight: 800;
  font-size: ${rem(20)};
`;

/*
const SpecialWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
*/

interface HomeCategoriesProps {
  dispatch: any;
  categoriesGroup: any;
}

const HomeCategories = (props: HomeCategoriesProps) => {
  const placeholder = '/images/placeholder-46.png';
  const placeholder2x = '/images/placeholder-46@2x.png';

  const { categoriesGroup } = props;

  //const categoriesGroupSize = categoriesGroup && categoriesGroup.length;

  function redirect(to: string) {
    props.dispatch(push(to));
  }

  /*
  const setTitle = __('Zoznamy pomôcok na nový školský rok - Skvelko');
  const setDescription = __(
    'Zoznamy pomôcok, ktoré odporučili učitelia na jednotlivých školách',
  );
  const setLinkText = __('Zobraziť zoznamy pre vašu školu');
  */

  function getThumbnailUrl(category) {
    if (category.uniqid === 'KAT_AKCIA_SEZONA') {
      return 'images/akcia.jpg';
    }
    const photogallery = prop(category, 'content.photogallery');
    return prop(
      photogallery && photogallery.length && photogallery[0],
      'document_path',
    );
  }

  function getCategoryGroupUrl(category) {
    const url = category.uniqid_url;
    if (!url) {
      const categories = category.categories;
      return categories &&
        categories.length &&
        categories[0] &&
        categories[0].url
        ? categories[0].url
        : null;
    }
    return getUrl(url);
  }

  return (
    <MediaContextProvider>
      <Media lessThan="md">
        <Wrapper>
          {/*
          <Item key={-1} className={'special'}>
            <Link to={URL_SETS}>
              <SpecialWrapper>
                <ThumbnailWrap to={URL_SETS} aria-label={setTitle}>
                  <Thumbnail
                    src={'images/school_child.png'}
                    placeholder={placeholder}
                    retinaPlaceholder={placeholder2x}
                    width={56}
                    height={84}
                    w={56}
                    h={84}
                    alt={setTitle}
                  />
                </ThumbnailWrap>
                <ItemContent className={'content'}>
                  <Title to={URL_SETS}>{setTitle}</Title>
                </ItemContent>
                <Button>+</Button>
              </SpecialWrapper>
            </Link>
          </Item>
          */}
          {categoriesGroup &&
            categoriesGroup.length &&
            categoriesGroup.map((category, index: number) => {
              const thumbnailUrl = getThumbnailUrl(category);
              const url = getCategoryGroupUrl(category);
              return (
                <Item key={index} onClick={redirect.bind(null, url)}>
                  <ThumbnailWrap
                    to={url}
                    aria-label={capitalizeFirstLetter(category.name)}
                  >
                    <Thumbnail
                      src={thumbnailUrl}
                      placeholder={placeholder}
                      retinaPlaceholder={placeholder2x}
                      notLazyloading={true}
                      width={60}
                      height={60}
                      w={60}
                      h={60}
                      alt={capitalizeFirstLetter(category.name)}
                    />
                  </ThumbnailWrap>
                  <ItemContent>
                    <Title to={url}>
                      {capitalizeFirstLetter(category.name)}
                    </Title>
                  </ItemContent>
                  <Button>+</Button>
                </Item>
              );
            })}
        </Wrapper>
      </Media>
      <Media greaterThanOrEqual="md">
        <Wrapper>
          {/*
          <Item key={-1} className={'special'}>
            <ThumbnailWrap to={URL_SETS} aria-label={setTitle}>
              <Thumbnail
                src={'images/school_child.png'}
                placeholder={placeholder}
                retinaPlaceholder={placeholder2x}
                width={56}
                height={84}
                w={56}
                h={84}
                alt={setTitle}
              />
            </ThumbnailWrap>
            <ItemContent className={'content'}>
              <Title to={URL_SETS}>{setTitle}</Title>
              <Description>{setDescription}</Description>
              <ItemTextLink to={URL_SETS} className={'special'}>
                {setLinkText}
              </ItemTextLink>
            </ItemContent>
          </Item>
          */}
          {categoriesGroup &&
            categoriesGroup.length &&
            categoriesGroup.map((category, index: number) => {
              const hasSubcategory =
                category && category.categories && category.categories.length;
              const thumbnailUrl = getThumbnailUrl(category);
              const url = getCategoryGroupUrl(category);

              return (
                <React.Fragment key={index}>
                  {index % 2 === 1 || index == 0 ? '' : <Hr />}
                  <Item>
                    <ThumbnailWrap
                      to={url}
                      aria-label={capitalizeFirstLetter(category.name)}
                    >
                      <Thumbnail
                        src={thumbnailUrl}
                        placeholder={placeholder}
                        retinaPlaceholder={placeholder2x}
                        width={64}
                        height={64}
                        w={64}
                        h={64}
                        alt={capitalizeFirstLetter(category.name)}
                      />
                    </ThumbnailWrap>
                    <ItemContent>
                      <Title to={url}>
                        {capitalizeFirstLetter(category.name)}
                      </Title>
                      <Items>
                        {(hasSubcategory &&
                          category.categories &&
                          category.categories.map((subcategory, k) => {
                            if (k < 6) {
                              return (
                                <React.Fragment key={k}>
                                  {k !== 0 && <ItemPipe>|</ItemPipe>}
                                  <ItemTextLink to={getUrl(subcategory.url)}>
                                    {capitalizeFirstLetter(
                                      subcategory.category_name,
                                    )}
                                  </ItemTextLink>
                                </React.Fragment>
                              );
                            } else if (k === 6) {
                              return (
                                <React.Fragment key={k}>
                                  <ItemPipe>|</ItemPipe>
                                  <ItemTextLink to={url}>
                                    {`${__('ďalšie')}...`}
                                  </ItemTextLink>
                                </React.Fragment>
                              );
                            } else {
                              return '';
                            }
                          })) ||
                          ''}
                      </Items>
                    </ItemContent>
                  </Item>
                </React.Fragment>
              );
            })}
        </Wrapper>
      </Media>
    </MediaContextProvider>
  );
};

export default HomeCategories;
