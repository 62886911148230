import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import MetaTags from '../../MetaTags/MetaTags';
import { formatShop } from '../../../utilities/shops';
import { __ } from 'react-i18n/lib';
import {
  ContactButtonPrimary,
  IconRightRed,
} from '../../_helpers/Contact/ContactStyles';
import { SelectStyled } from '../../_helpers/form/Select';
import { ContentService } from '../../../services/ContentService';
import { Link } from 'react-router';

const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0 ${rem(4)};
  ${({ theme }) => theme.media(769)} {
    margin: 0;
  }
`;

const MobileWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const Item = styled.li`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  display: flex;
  flex: 0 1 ${({ theme }) => theme.grid.col.col3};
  list-style: none;
  margin: ${rem(-1)};
  padding: 0;
`;

const Body = styled.a`
  font-size: ${rem(14)};
  min-width: 0;
  color: ${({ theme }) => theme.color.text};
  padding: ${rem(6)};
  ${({ theme }) => theme.media('sm')} {
    flex: 1 1 ${({ theme }) => theme.grid.col.col3};
  }
  ${({ theme }) => theme.media(769)} {
    padding: ${rem(12)};
  }
  &:hover {
    text-decoration: none;
  }
`;

const Title = styled.p`
  font-size: ${rem(17)};
  margin: 0;
  font-weight: 800;
  ${({ theme }) => theme.media(769)} {
    font-size: ${rem(16)};
  }
`;

const Address = styled.p`
  margin: ${rem(5)} 0 ${rem(5)} ${rem(20)};
  font-size: ${rem(16)};
  font-weight: 400;
  color: ${({ theme }) => theme.color.link};
  ${({ theme }) => theme.media(769)} {
    font-weight: 600;
    font-size: ${rem(13)};
  }
`;

const Icon = styled(IconRightRed)`
  margin: ${rem(10)} 0;
  ${({ theme }) => theme.media(769)} {
    margin: ${rem(5)} 0;
  }
`;

const OpeningHours = styled.p`
  margin: 0;
  font-size: ${rem(12)};
`;

const Button = styled(ContactButtonPrimary)`
  width: ${rem(192)};
  margin: 0 auto;
`;

const StyledSelect = styled(SelectStyled)`
  max-width: 100%;
  margin-bottom: ${rem(24)};
`;

interface StoreItemProps {
  item: any;
}

class StoreItem extends React.PureComponent<StoreItemProps> {
  public render() {
    let { item } = this.props;
    item = formatShop(item);
    const isPC = (window && window.innerWidth) > 768;
    return (
      <Item>
        <Body href={item.url ? ContentService.getAppRelativeUrl(item.url) : ''}>
          <Title>{item.name}</Title>
          <Icon />
          <Address>{item.address}</Address>
          {isPC && item.opening_hours_formatted
            ? item.opening_hours_formatted.map((oh, i) => {
                return <OpeningHours key={i}>{oh}</OpeningHours>;
              })
            : ''}
        </Body>
      </Item>
    );
  }
}

interface StoreItemsMobileProps {
  items: any;
  dispatch: any;
}

interface StoreItemsMobileState {
  selected: string | null;
}

class StoreItemsMobile extends React.PureComponent<
  StoreItemsMobileProps,
  StoreItemsMobileState
> {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
    };
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  public onSelectChange(e) {
    this.setState({ selected: e.target.value });
  }

  public render() {
    const { items } = this.props;
    const { selected } = this.state;
    return (
      <MobileWrapper>
        <StyledSelect onChange={this.onSelectChange}>
          <option value="">{__('Zvoľte predajňu')}</option>
          {items &&
            items.map((item, i) => {
              if (item.url && item.name) {
                return (
                  <option key={i} value={item.url}>
                    {item.name}
                  </option>
                );
              } else {
                return;
              }
            })}
        </StyledSelect>
        {selected ? (
          <Link to={selected}>
            <Button className="primary">{__('Zobraziť predajňu')}</Button>
          </Link>
        ) : (
          <></>
        )}
      </MobileWrapper>
    );
  }
}

interface StoreListProps {
  items: any;
  dispatch: any;
}

class StoreListContact extends React.PureComponent<StoreListProps> {
  public render() {
    const { items, dispatch } = this.props;
    const isMobile = (window && window.innerWidth) < 600;
    return (
      <Wrapper>
        <MetaTags tags={{ title: `Daffer.sk - Zoznam predajní` }} />
        {isMobile ? (
          <StoreItemsMobile items={items} dispatch={dispatch} />
        ) : (
          items &&
          items.map((item, i) => {
            return <StoreItem key={i} item={item} />;
          })
        )}
      </Wrapper>
    );
  }
}

export default StoreListContact;
