import * as React from 'react';
import { push } from 'react-router-redux';

export interface UseFilterInput {
  filterTypes: FilterType[];
  queryLocation?: any;
  settings?: {
    setAutoUrl?: boolean; // default true
    generateFromUrl?: boolean; // default true
  };
  dispatch: any;
}

export interface FilterType {
  name: string;
  urlName: string;
  defaultValue?: any;
  isImmutable?: boolean;
}

export interface Filter {
  name: string;
  value: string;
}

export const useFilter = (input: UseFilterInput) => {
  const [filters, setFilters] = React.useState([] as Filter[]);
  const [outFilters, setOutFilters] = React.useState({} as any);

  const { filterTypes, queryLocation, dispatch } = input;

  React.useEffect(() => {
    setDefaults(true);
    if (hasGenerateFromUrl()) {
      generateFromUrl();
    }

    generateOutFilters();
  }, [queryLocation]);

  const filterTypeExist = (name) => {
    for (const filterType of filterTypes) {
      if (filterType.name === name) {
        return true;
      }
    }
    return false;
  };

  const filterExist = (name) => {
    for (const filter of filters) {
      if (filter.name === name) {
        return true;
      }
    }

    return false;
  };

  const addFilter = (newFilter: Filter, generateUrl: boolean = true) => {
    if (!filterTypeExist(newFilter.name)) {
      throw new Error(`Filter with name ${newFilter.name} does not exist`);
    }

    const newFilters = filters;
    const filterIndex = filters.findIndex(
      (findFilter) => findFilter.name === newFilter.name,
    );
    if (filterIndex !== -1) {
      newFilters.splice(filterIndex, 1);
    }

    newFilters.push(newFilter);

    setFilters(newFilters);
    generateOutFilters();

    if (hasAutoUrl() && generateUrl === true) {
      setUrl();
    }
  };

  const getFilterUrlName = (name: string): string | undefined => {
    const filterType = filterTypes.find(
      (findFilterType) => findFilterType.name === name,
    );
    if (filterType) {
      if (filterType.urlName) {
        return filterType.urlName;
      } else {
        return filterType.name;
      }
    }

    return undefined;
  };

  const getUrl = () => {
    const urlFilters: string[] = [];

    for (const filter of filters) {
      const urlName = getFilterUrlName(filter.name);
      if (urlName) {
        urlFilters.push(urlName + '=' + filter.value);
      }
    }

    return urlFilters.join('&');
  };

  const setUrl = () => {
    const location = window.location.pathname + '?' + getUrl();
    /*
    window.history.pushState(location, '', location);
    */
    dispatch(push(location));
  };

  const generateOutFilters = (): any => {
    const filtersArray: any[] = [];

    for (const filterType of filterTypes) {
      const filter = filters.find(
        (findFilter) => findFilter.name === filterType.name,
      );
      if (
        filter === null ||
        filter === undefined ||
        filter.value === undefined
      ) {
        filtersArray[filterType.name] = filterType.defaultValue;
      } else {
        filtersArray[filterType.name] = filter.value;
      }
    }
    setOutFilters({ ...filtersArray });
  };

  const generateFromUrl = () => {
    if (queryLocation) {
      Object.keys(queryLocation).forEach((name) => {
        const filterType = filterTypes.find(
          (findFilterType) => findFilterType.urlName === name,
        );
        if (filterType) {
          addFilter(
            {
              name: filterType.name,
              value: queryLocation[name],
            },
            false,
          );
        }
      });
    }
  };

  const hasAutoUrl = () => {
    if (input.settings && input.settings.setAutoUrl === false) {
      return false;
    }

    return true;
  };

  const hasGenerateFromUrl = () => {
    if (input.settings && input.settings.generateFromUrl === false) {
      return false;
    }

    return true;
  };

  const setDefaults = (force: boolean = false) => {
    for (const filterType of filterTypes) {
      if (filterType.isImmutable) {
        continue;
      }
      if (!filterExist(filterType.name) || force === true) {
        addFilter(
          {
            name: filterType.name,
            value: filterType.defaultValue,
          },
          false,
        );
      }
    }
  };

  return {
    filters: outFilters,
    rawFilters: filters,
    addFilter,
    getUrl,
    setUrl,
    generateFromUrl,
    setDefaults,
    setFilters,
  };
};
