import * as React from 'react';
import styled from 'styled-components';
import { FlexRowCenter } from '../Flexbox/Flexbox';

interface Props {
  loading: boolean;
  children?: any;
  style?: React.CSSProperties;
  dim?: boolean;
  primaryColor?: string;
  secondaryColor?: string;
  size?: number;
}

export class Loader extends React.Component<Props> {
  public render() {
    const { loading, children, style } = this.props;
    let { dim } = this.props;

    if (dim === undefined) {
      dim = true;
    }

    let LoadingCircleComponent = (
      <LoadingCircle className={loading === true ? 'active' : ''} />
    );

    if (this.props.primaryColor && this.props.secondaryColor) {
      const customStyle = {
        borderTop: `1.1em solid ${({ theme }) =>
          theme.colors.loaderSecondary || this.props.secondaryColor}`,
        borderBottom: `1.1em solid ${({ theme }) =>
          theme.colors.loaderSecondary || this.props.secondaryColor}`,
        borderRight: `1.1em solid ${({ theme }) =>
          theme.colors.loaderSecondary || this.props.secondaryColor}`,
        borderLeft: `1.1em solid ${({ theme }) =>
          theme.colors.loaderPrimary || this.props.primaryColor}`,
      };
      LoadingCircleComponent = (
        <LoadingCircle
          style={customStyle}
          className={loading === true ? 'active' : ''}
        />
      );
    }

    return (
      <LoaderStyle style={style}>
        {dim && <CircleBackdrop className={loading === true ? 'active' : ''} />}

        {LoadingCircleComponent}

        {children}
      </LoaderStyle>
    );
  }
}

const CircleBackdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  display: none;

  &.active {
    display: block;
    z-index: ${({ theme }) => theme.index.priorityContentShadow};
  }
`;

const LoadingCircle = styled.div`
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid
    ${({ theme }) => theme.colors.loaderSecondary || 'rgba(126, 206, 210, 0.4)'};
  border-right: 1.1em solid
    ${({ theme }) => theme.colors.loaderSecondary || 'rgba(126, 206, 210, 0.4)'};
  border-bottom: 1.1em solid
    ${({ theme }) => theme.colors.loaderSecondary || 'rgba(126, 206, 210, 0.4)'};
  border-left: 1.1em solid
    ${({ theme }) => theme.colors.loaderPrimary || 'rgba(126, 206, 210, 1)'};
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  /* z-index: 400; */
  display: none;

  &.active {
    display: block;
    z-index: ${({ theme }) => theme.index.priorityContentShadow};
  }
  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    margin-top: -5em;
    margin-left: -5em;
    position: absolute;
    left: 50%;
    top: 50%;
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoaderStyle = styled.div`
  width: auto;
  height: auto;
  position: relative;
  /* z-index: 400; */
`;

export const LoaderWrapper = styled(FlexRowCenter)`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background: rgba(255, 255, 255, 0.6);
`;
