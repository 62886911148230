import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { Container } from '../../theme/libraries/grid';
import { push } from 'react-router-redux';
import { updateCart } from '../../containers/Cart/actions';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.gray95};
  margin-top: ${rem(-35)};

  ${({ theme }) => theme.media('md')} {
    padding-bottom: ${rem(0)};
    margin-top: ${rem(-5)};
  }
`;

const List = styled.div`
  margin: ${rem(0)} auto;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media('md')} {
    margin: ${rem(10)} auto ${rem(10)} auto;
    padding-bottom: ${rem(15)};
  }
`;

const Step: any = styled.div`
  letter-spacing: 0;
  color: ${({ theme }) => theme.color.text};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'initial')};
  width: ${rem(60)};
`;

const StepCircle = styled.span`
  border-radius: 50%;
  width: ${rem(40)};
  height: ${rem(40)};
  border: 2px solid ${({ theme }) => theme.color.gray87};
  color: ${({ theme }) => theme.color.gray87};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: ${({ theme }) => theme.color.white};

  ${Step}.active & {
    border: 2px solid ${({ theme }) => theme.color.primary};
    background-color: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.white};
    text-decoration: none;
  }
`;

const StepNumber = styled.span`
  margin-left: ${rem(4)};
`;

const StepDesc = styled.span`
  margin-top: ${rem(10)};
  font-size: ${rem(13)};
  line-height: 1;
  text-align: center;
`;

const StepSeparator = styled.div`
  width: ${rem(64)};
  height: 0;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.gray87};
  margin: ${rem(-40)} ${rem(-10)} 0 ${rem(-10)};
`;

interface CartStepsInterface {
  actualStep: number;
  dispatch: any;
  cart: any;
  isDemand: boolean;
}

const CartSteps = (props: CartStepsInterface) => {
  const { isDemand } = props;
  const onClickStep = (toStep) => {
    if (toStep > props.actualStep) {
      return;
    }

    const newCart = { ...props.cart, step: toStep };
    props.dispatch(updateCart(newCart));

    if (toStep === 1) {
      props.dispatch(push('/kosik'));
    } else if (toStep === 2) {
      props.dispatch(push('/doprava-a-platba'));
    } else if (toStep === 3) {
      props.dispatch(push('/udaje-pre-dorucenie'));
    }
  };

  const steps = [
    {
      name: __('Obsah košíka'),
    },
    {
      name: __('Doprava a platba'),
    },
    {
      name: __('Adresa doručenia'),
    },
  ];

  const demandSteps = [
    /*
    {
      stepNumber: 1,
      name: __('Obsah košíka'),
    },
    {},
    {
      stepNumber: 2,
      name: __('Kontaktné údaje'),
    },
    */
  ];

  const generateCartSteps = (steps) => {
    return steps
      .map((step, i) => {
        if (step.name) {
          return (
            <React.Fragment key={i}>
              <Step
                onClick={onClickStep.bind(null, i + 1)}
                className={props.actualStep - 1 === i ? 'active' : ''}
                clickable={props.actualStep - 1 >= i}
              >
                <StepCircle>
                  <StepNumber>
                    {step.stepNumber ? step.stepNumber : i + 1}.
                  </StepNumber>
                </StepCircle>
                <StepDesc>{step.name}</StepDesc>
              </Step>
              {steps.length > i + 1 ? <StepSeparator /> : ''}
            </React.Fragment>
          );
        }
        return null;
      })
      .filter((o) => o);
  };

  return (
    <Wrapper>
      <Container className="base">
        <List>{generateCartSteps(isDemand ? demandSteps : steps)}</List>
      </Container>
    </Wrapper>
  );
};

export default CartSteps;
