import * as React from 'react';
import styled from 'styled-components';
import SwiperSlider from 'react-id-swiper';

export const SwiperWrapper = styled.div``;

export interface SwiperIO {
  params?: any;
  children: any;
  className?: string;
  onProductsShow?: any;
}

class Swiper extends React.Component<SwiperIO> {
  public myRef;
  public initDone;

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  public render() {
    const { children, className } = this.props;
    let params = this.props.params || {};
    params = {
      ...params,
      rebuildOnUpdate: true,
      shouldSwiperUpdate: true,
      on: {
        init: () => {
          if (!this.initDone) {
            this.handleSlidesVisible();
            this.initDone = true;
          }
        },
        slideChange: (e) => {
          this.handleSlidesVisible();
          //return false;
        },
      },
    };

    return (
      <SwiperWrapper className={className}>
        <SwiperSlider ref={this.myRef} {...params}>
          {children}
        </SwiperSlider>
      </SwiperWrapper>
    );
  }

  public handleSlidesVisible = () => {
    const swiper = this.myRef.current.swiper;
    const { activeIndex, slides } = swiper;
    const slidesPerView = swiper.params.slidesPerView;

    const visibleSlides: any[] = [];
    for (let i = activeIndex; i < activeIndex + slidesPerView; i++) {
      if (slides[i]) {
        const slideId = slides[i].getAttribute('data-product_id');
        if (slideId) {
          visibleSlides.push(parseInt(slideId));
        }
      }
    }

    if (this.props.onProductsShow) {
      this.props.onProductsShow(visibleSlides);
    }
  };
}

export default Swiper;
