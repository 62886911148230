import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { em, rem } from 'polished';
import { ButtonStyled } from '../../_helpers/form/Button';
import Icon from '../../_helpers/Icon/Icon';
import { InputStyled } from '../../_helpers/form/Input';
import OnClickOutsideComponent from '../../_helpers/OnClickOutsideComponent/OnClickOutsideComponent';
import {
  combineWords,
  getBaseUrl,
  isTablet,
  removeDiacriticsToLower,
} from '../../../utilities';
import { Media, MediaContextProvider } from '../../../theme/media';
import MobileSearchResult from './SearchResult/MobileSearchResult';
import DesktopSearchResult from './SearchResult/DesktopSearchResult';
import OverlaySearch from '../../_helpers/Overlay/OverlaySearch';
import API, { ThenArg } from '../../../services/API';

const Wrapper = styled.div`
  margin: ${rem(8)} ${rem(16)};
  position: relative;
  z-index: ${(props) => (props.searchDropdownVisible ? 101 : 'inherit')};
  ${({ theme }) => theme.media('md')} {
    margin: ${rem(8)};
  }
`;

const SearchInput = styled(InputStyled)`
  display: block;
  padding-right: ${rem(80)};
  width: 100%;
  font-size: ${rem(15)};
  ${({ theme }) => theme.media('xs')} {
    font-size: ${rem(16)};
  }
  @media (max-width: ${em(991)}) {
    border: none;
    border-radius: ${rem(8)};
    padding-top: ${rem(8)};
    padding-bottom: ${rem(8)};
    padding-right: ${rem(64)};
  }
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

const SearchSubmit = styled(ButtonStyled)`
  background-color: transparent;
  border: none;
  line-height: 0;
  padding: ${rem(13)};
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: ${em(991)}) {
    padding: ${rem(8)} ${rem(16)} ${rem(8)} ${rem(8)};
  }
`;

const ClearSubmit = styled(SearchSubmit)`
  right: ${rem(38)};
  @media (max-width: ${em(991)}) {
    padding: ${rem(8)};
  }
  &:not(:disabled):hover {
    background: none;
  }
`;

const SearchIcon = styled(Icon)`
  ${({ theme }) => theme.size(16)};
  transform: scaleX(-1);
  @media (max-width: ${em(991)}) {
    fill: red;
  }
`;

const ClearIcon = styled(SearchIcon)`
  &:hover {
    fill: ${({ theme }) => theme.color.error};
  }
`;

interface SearchformProps {
  searchDropdownVisible: boolean;
  searchedQuery: string;
  searchedResults: any;
  searchedResultsLoader: boolean;
  onChangeSearchInput: (q: string) => void;
  onClickOutsideSearchForm: () => void;
  onRedirect: (url, skvelkoSetId) => void;
  onSubmitSearchInput: (section: string) => void;
  onSearchClick?: () => void;
  isMobile?: boolean;
  searchVisible?: boolean;
  user: any;
  cart: ThenArg<typeof API.getCart>;
  onSearchFocus?: (isFocus: boolean) => void;
  settings: any;
}

interface SearchformState {
  focused: boolean;
  waitForLoad: boolean;
}

class Searchform extends React.PureComponent<SearchformProps, SearchformState> {
  public searchRef;

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      waitForLoad: false,
    };

    this.searchRef = React.createRef();
  }

  public handleChangeSearchInput = (e) => {
    this.props.onChangeSearchInput(e.target.value);
  };

  public handleClickOutsideForm = () => {
    if (this.props.searchDropdownVisible) {
      this.props.onClickOutsideSearchForm();
    }
  };

  public onRedirect = (url, skvelkoSetId = null) => {
    this.props.onRedirect(getBaseUrl(url), skvelkoSetId);

    if (this.props.searchDropdownVisible) {
      this.props.onClickOutsideSearchForm();
    }

    if (this.props.onSearchClick) {
      this.props.onSearchClick();
    }
  };

  public onSubmitSearchInput = (section: string = '0') => {
    this.props.onSubmitSearchInput(section);

    if (this.props.onSearchClick) {
      this.props.onSearchClick();
    }
  };

  public onClearSearchInput = () => {
    this.props.onChangeSearchInput('');
  };

  public setFocus = (isFocus: boolean) => {
    if (this.props.onSearchFocus) {
      this.props.onSearchFocus(isFocus);
    }
    this.setState({
      focused: isFocus,
    });
  };

  public onFocus = () => {
    this.setFocus(true);
  };

  public onBlur = () => {
    this.setFocus(false);
  };

  public onSearchClick = () => {
    if (!this.props.searchedResultsLoader) {
      this.onSubmitSearchInput(this.getSearchType(this.props.searchedResults));
    } else {
      this.setState({
        waitForLoad: true,
      });
    }
  };

  public onKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!this.props.searchedResultsLoader) {
        if (this.state.focused) {
          this.onSubmitSearchInput(
            this.getSearchType(this.props.searchedResults),
          );
          this.searchRef.current.blur();
        }
      } else {
        this.setState({
          waitForLoad: true,
        });
      }
    }
  };

  public getSearchType(searchedResults): string {
    let isInProducts = false;
    let isInDemandProduts = false;
    let isInWeb = false;

    if (
      searchedResults &&
      searchedResults.products &&
      searchedResults.products.products
    ) {
      if (searchedResults?.products?.is_exact_match) {
        isInProducts = true;
      } else {
        for (const product of searchedResults.products.products) {
          if (
            combineWords(
              removeDiacriticsToLower(product.name).split(' '),
            ).includes(removeDiacriticsToLower(this.props.searchedQuery))
          ) {
            isInProducts = true;
            break;
          }
        }
      }
    }

    if (
      searchedResults &&
      searchedResults.demandProducts &&
      searchedResults.demandProducts.products
    ) {
      for (const product of searchedResults.demandProducts.products) {
        if (
          combineWords(
            removeDiacriticsToLower(product.name).split(' '),
          ).includes(removeDiacriticsToLower(this.props.searchedQuery))
        ) {
          isInDemandProduts = true;
          break;
        }
      }
    }

    if (
      searchedResults &&
      searchedResults.webContent &&
      searchedResults.webContent.web_content
    ) {
      for (const webContent of searchedResults.webContent.web_content) {
        if (
          combineWords(
            removeDiacriticsToLower(webContent.name).split(' '),
          ).includes(removeDiacriticsToLower(this.props.searchedQuery))
        ) {
          isInWeb = true;
          break;
        }
      }
    }

    if (!isInDemandProduts && !isInProducts && !isInWeb) {
      const productsLength = searchedResults?.products?.products?.length;
      const demandProductsLength =
        searchedResults?.demandProducts?.products?.length;
      const webContentLength = searchedResults?.webContent?.web_content?.length;

      if (
        productsLength > demandProductsLength &&
        productsLength > webContentLength
      ) {
        isInProducts = true;
      } else if (
        demandProductsLength > productsLength &&
        demandProductsLength > webContentLength
      ) {
        isInDemandProduts = true;
      } else if (
        webContentLength > productsLength &&
        webContentLength > demandProductsLength
      ) {
        isInWeb = true;
      }
    }

    if (isInDemandProduts) {
      return '2';
    } else if (isInProducts) {
      return '0';
    } else if (isInWeb) {
      return '1';
    } else {
      return '0';
    }
  }

  public componentDidMount() {
    if (document) {
      document.addEventListener('keydown', this.onKeyDown, false);
    }
  }
  public componentWillUnmount() {
    if (document) {
      document.removeEventListener('keydown', this.onKeyDown, false);
    }
  }

  public onchange() {
    //
  }

  public onClick = () => {
    this.props.onChangeSearchInput(this.props.searchedQuery);
  };

  public render() {
    const mobileClass = this.props.isMobile ? 'mobile' : '';

    const {
      searchedQuery,
      searchDropdownVisible,
      searchedResults,
      searchedResultsLoader,
      user,
      cart,
      settings,
    } = this.props;

    if (!this.props.searchedResultsLoader && this.state.waitForLoad) {
      this.setState({
        waitForLoad: false,
      });
      this.onSubmitSearchInput(this.getSearchType(this.props.searchedResults));
    }

    return (
      <>
        {searchDropdownVisible && !isTablet() && <OverlaySearch />}
        <OnClickOutsideComponent onClickOutside={this.handleClickOutsideForm}>
          <Wrapper
            className={mobileClass}
            searchDropdownVisible={searchDropdownVisible}
          >
            <SearchInput
              ref={this.searchRef}
              id={'search'}
              type={'search'}
              value={searchedQuery}
              placeholder={`${__('Vyhľadať')}...`}
              onInput={this.handleChangeSearchInput}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onchange}
              onClick={this.onClick}
              autoComplete="off"
            />
            {searchedQuery !== '' && (
              <ClearSubmit onClick={this.onClearSearchInput}>
                <ClearIcon icon="errorCircle" />
              </ClearSubmit>
            )}
            <SearchSubmit
              aria-label={__('Vyhľadať')}
              onClick={this.onSearchClick}
            >
              <SearchIcon icon="search" />
            </SearchSubmit>
            <MediaContextProvider>
              <Media lessThan="lg">
                <MobileSearchResult
                  searchDropdownVisible={searchDropdownVisible}
                  searchedQuery={searchedQuery}
                  searchedResults={searchedResults}
                  searchedResultsLoader={searchedResultsLoader}
                  onRedirect={this.onRedirect}
                  onSubmitSearchInput={this.onSubmitSearchInput}
                  handleClickOutsideForm={this.handleClickOutsideForm}
                  user={user}
                  cart={cart}
                  settings={settings}
                />
              </Media>
              <Media greaterThanOrEqual="lg">
                <DesktopSearchResult
                  searchDropdownVisible={searchDropdownVisible}
                  searchedQuery={searchedQuery}
                  searchedResults={searchedResults}
                  searchedResultsLoader={searchedResultsLoader}
                  onRedirect={this.onRedirect}
                  onSubmitSearchInput={this.onSubmitSearchInput}
                  handleClickOutsideForm={this.handleClickOutsideForm}
                  user={user}
                  cart={cart}
                  settings={settings}
                />
              </Media>
            </MediaContextProvider>
          </Wrapper>
        </OnClickOutsideComponent>
      </>
    );
  }
}

export default Searchform;
