import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';
import { StyledFooter, Button2 } from './ChooseModal';
import API, { ThenArg } from '../../../services/API';
import { prop } from '../../../utilities';

const ModalDiv = styled(BaseModal)`
  width: ${rem(560)};
`;

const Row = styled.div`
  display: flex;
  & + & {
    margin-top: ${rem(15)};
  }
`;

const CloseButton = styled(Button2)``;

interface ModalProps {
  product: any;
  goodId?: number;
  cart: ThenArg<typeof API.getCart>;
  onCloseButtonClick?: (event) => void;
}

class ProductAddedToCartModalQuantityLimit extends React.PureComponent<ModalProps> {
  public render() {
    const { product, cart } = this.props;

    const goodId = this.props.goodId
      ? this.props.goodId
      : prop(product, 'main_good.good_id');
    const quantityLimit = prop(product, 'main_good.order_quantity_limit');
    const itemInCart = cart?.items?.find(
      (item) => prop(item, 'good.good_id') === goodId,
    );
    const countInCart = itemInCart && itemInCart.count ? itemInCart.count : 0;

    return (
      <ModalDiv
        title={__('Produkt nie je možné pridať do košíka')}
        body={
          <Row>{`Minimálne množstvo na objednávku je ${quantityLimit} ks. ${
            countInCart > 0 ? `Momentálne máte v košíku ${countInCart} ks.` : ''
          }`}</Row>
        }
        footer={
          <StyledFooter>
            <CloseButton
              className="primary lg"
              onClick={this.props.onCloseButtonClick}
            >
              {__('Pokračovať v nakupovaní')}
            </CloseButton>
          </StyledFooter>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default ProductAddedToCartModalQuantityLimit;
