import * as actionTypes from './constants';
import {
  setCurrentCategoryGroup,
  setCurrentCategoryGroupId,
  setCurrentCategoryId,
} from '../App/actions';
import { objectsArraysEqual, prop } from '../../utilities';
import { LAST_SEEN_PRODUCTS_KEY } from './constants';

export const setActiveVariant = (variant) => ({
  type: actionTypes.SET_ACTIVE_VARIANT,
  payload: {
    variant,
  },
});

export const setCurrentProductId = (id) => ({
  type: actionTypes.SET_CURRENT_PRODUCT_ID,
  payload: {
    id,
  },
});

export const setProductData = (productData) => ({
  type: actionTypes.SET_CURRENTPRODUCT_DATA,
  payload: {
    productData,
  },
});

export const setLinkedProducts = (linkedProducts) => ({
  type: actionTypes.SET_LINKED_PRODUCTS,
  payload: {
    linkedProducts,
  },
});

export const setRelatedProducts = (relatedProducts) => ({
  type: actionTypes.SET_RELATED_PRODUCTS,
  payload: {
    relatedProducts,
  },
});

export const setRelatedProductsModal = (relatedProductsModal) => ({
  type: actionTypes.SET_RELATED_PRODUCTS_MODAL,
  payload: {
    relatedProductsModal,
  },
});

export const setRelatedProductsModalLoader = (relatedProductsModalLoader) => ({
  type: actionTypes.SET_RELATED_PRODUCTS_MODAL_LOADER,
  payload: {
    relatedProductsModalLoader,
  },
});

export const loadProductById = (productId) => {
  return async (dispatch, getState, API) => {
    const state = getState();
    const currentCategoryId = prop(state, 'general.currentCategoryId');
    const currentCategoryGroup = prop(
      getState(),
      'general.currentCategoryGroup',
      undefined,
    );
    const productData = await API.loadProduct(
      productId,
      currentCategoryId ? { categoryId: currentCategoryId } : {},
      {
        xAcceptLanguage: getState().general.lang,
      },
    );

    const { productCategoriesBreadcrumbs } = productData;
    if (productCategoriesBreadcrumbs?.length) {
      dispatch(
        setCurrentCategoryId(
          productCategoriesBreadcrumbs.slice(-1).pop()?.category_id,
        ),
      );
    }
    const linkedProducts = await API.loadProductConnections(productId, {
      connectionType: 'COLORS',
      limit: 16,
    });
    dispatch(setProductData(productData));

    linkedProducts.products.push(productData);
    const sorted = linkedProducts.products.sort((a, b) => {
      return a.product_id - b.product_id;
    });
    dispatch(setLinkedProducts(sorted));

    const categoryGroup = await API.loadCategoriesGroups({
      active: '1',
      withCategories: 1,
      categoryTree: true,
      productId,
      limit: 1,
      sort: 'id',
      filterEmpty: true,
    });
    const catalogCategoryGroups = categoryGroup.catalog_category_groups;

    if (catalogCategoryGroups.length) {
      let selectedCategoryGroup = catalogCategoryGroups.find(
        (item) => item.id === currentCategoryGroup?.id,
      );
      if (!selectedCategoryGroup) {
        selectedCategoryGroup = catalogCategoryGroups[0];
      }

      dispatch(setCurrentCategoryGroup(selectedCategoryGroup));
      dispatch(setCurrentCategoryGroupId(selectedCategoryGroup.id));
    }
  };
};

export const loadCurrentCategoryGroupByProductId = (
  productId,
  force: boolean = false,
) => {
  return async (dispatch, getState, API) => {
    const currentCategoryGroup = prop(
      getState(),
      'general.currentCategoryGroup',
      undefined,
    );
    if (currentCategoryGroup && !force) {
      dispatch(setCurrentCategoryGroupId(currentCategoryGroup.id));
      dispatch(setCurrentCategoryGroup(currentCategoryGroup));
      return;
    }

    const categoryGroup = await API.loadCategoriesGroups({
      active: '1',
      withCategories: 1,
      categoryTree: true,
      productId,
      limit: 1,
      sort: 'id',
      filterEmpty: true,
    });
    const catalogCategoryGroups = categoryGroup.catalog_category_groups;
    if (catalogCategoryGroups.length) {
      let selectedCategoryGroup = catalogCategoryGroups.find(
        (item) => item.id === currentCategoryGroup?.id,
      );
      if (!selectedCategoryGroup) {
        selectedCategoryGroup = catalogCategoryGroups[0];
      }

      dispatch(setCurrentCategoryGroup(selectedCategoryGroup));
      dispatch(setCurrentCategoryGroupId(selectedCategoryGroup.id));
    }
  };
};

export const loadRelatedProductsModal = (productId) =>
  loadRelatedProducts(
    productId,
    setRelatedProductsModal,
    setRelatedProductsModalLoader,
    4,
  );

export const loadRelatedProducts = (
  productId,
  setter: (payload) => any = setRelatedProducts,
  loader: ((value) => void) | undefined = undefined,
  limit: number = 16,
) => {
  return async (dispatch, getState, API) => {
    if (loader) {
      dispatch(loader(true));
    }

    let relatedProducts: any = null;

    relatedProducts = await API.loadProductConnections(productId, {
      connectionType: 'CROSSSELL',
      random: false,
      limit,
    });

    const productCount = relatedProducts?.products?.length
      ? relatedProducts?.products?.length
      : 0;

    if (productCount < limit) {
      const missedCount = limit - productCount;

      const autoCrosselRelatedProducts = await API.loadProductConnections(
        productId,
        {
          connectionType: 'AUTO-CROSSELL',
          random: true,
          missedCount,
        },
      );

      relatedProducts.products = relatedProducts.products.concat(
        autoCrosselRelatedProducts.products,
      );
    }

    if (relatedProducts?.products?.length) {
      relatedProducts.products = relatedProducts.products.filter(
        (obj1, i, arr) =>
          arr.findIndex((obj2) => obj2.product_id === obj1.product_id) === i,
      );
    }

    dispatch(setter(relatedProducts));

    if (loader) {
      dispatch(loader(false));
    }
  };
};

export const setUpsell = (upsell) => ({
  type: actionTypes.SET_UPSELL,
  payload: {
    upsell,
  },
});

export const loadProductUpsell = (productId) => {
  return async (dispatch, getState, API) => {
    try {
      const upsells = await API.searchUpsells({
        productIds: productId,
        withAssignedProducts: true,
        isActive: true,
      });
      if (upsells?.items?.length) {
        const upsell = upsells.items[0];
        const productsIds = upsell.assigned_products.map(
          (ap) => `${ap.product_id}`,
        );

        const { products } = await API.searchProducts({
          offset: 0,
          limit: 100,
          productsIds,
          availabilityId: 1,
        });

        upsell.assigned_products = upsell.assigned_products
          .map((ap) => {
            const actProduct = products.find(
              (p) => p.product_id == ap.product_id,
            );
            if (actProduct) {
              ap.product = actProduct;
              return ap;
            } else {
              return null;
            }
          })
          .filter((o) => o?.product);

        if (upsell.assigned_products.length) {
          dispatch(setUpsell(upsell));
        }
      } else {
        dispatch(setUpsell(null));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const hasProductInCartVariants = (product) => {
  return (
    product &&
    product.good &&
    product.good.web_attribs &&
    product.good.web_attribs.length > 0
  );
};

export const hasGoodVariants = (good) => {
  return good && good.web_attribs && good.web_attribs.length > 0;
};

export const getGoodVariant = (good) => {
  if (!hasGoodVariants(good)) {
    return false;
  }
  return good.web_attribs[0];
};

export const getProductInCartVariant = (product) => {
  if (!hasProductInCartVariants(product)) {
    return false;
  }
  return product.good.web_attribs[0];
};

export const getProductInCartDemandAttribs = (product) => {
  //if (!hasProductInCartVariants(product)) {
  //  return false;
  //}

  return product.demand_attribs;
};

export const hasProductVariants = (product) => {
  return (
    product &&
    product.goods &&
    product.goods.length > 1 &&
    product.attribs &&
    product.attribs.length > 0
  );
};

export const getBulkVariantProductsIds = (product, products): number[] => {
  const bulkVariantProductIds: number[] = [];
  const productAttribsValues: { attrib_id: number; value_id: number }[] = [];
  if (product.attribs && product.attribs.length) {
    for (const productAttrib of product.attribs) {
      for (const attribValue of productAttrib.values) {
        productAttribsValues.push({
          attrib_id: attribValue.attrib_id,
          value_id: attribValue.value_id,
        });
      }
    }

    for (const cProduct of products) {
      const cProductAttribsValues: {
        attrib_id: number;
        value_id: number;
      }[] = [];
      if (cProduct.attribs && cProduct.attribs.length) {
        for (const productAttrib of cProduct.attribs) {
          for (const attribValue of productAttrib.values) {
            cProductAttribsValues.push({
              attrib_id: attribValue.attrib_id,
              value_id: attribValue.value_id,
            });
          }
        }

        if (objectsArraysEqual(productAttribsValues, cProductAttribsValues)) {
          bulkVariantProductIds.push(cProduct.product_id);
        }
      }
    }
  }

  return bulkVariantProductIds;
};

export const getProductVariantName = (product) => {
  if (
    !product ||
    !product.attribs ||
    product.attribs.length < 1 ||
    !product.attribs[0] ||
    !product.attribs[0].attrib_name
  ) {
    return null;
  }
  return product.attribs[0].attrib_name;
};

export const getLastSeenProducts = (): number[] => {
  let localStorageSupported = 'localStorage' in window;
  if (localStorageSupported && localStorage) {
    return JSON.parse(
      localStorage.getItem(LAST_SEEN_PRODUCTS_KEY) || '[]',
    ).slice(-30);
  } else {
    return [];
  }
};

export const setLastSeenProducts = (data: number[]) => {
  let localStorageSupported = 'localStorage' in window;
  if (localStorageSupported && localStorage) {
    localStorage.setItem(LAST_SEEN_PRODUCTS_KEY, JSON.stringify(data));
  }
};

export const setLoadedLastSeenProductsLoader = (
  loadedLastSeenProductsLoader,
) => ({
  type: actionTypes.SET_LOADED_LAST_SEEN_PRODUCTS_LOADER,
  payload: {
    loadedLastSeenProductsLoader,
  },
});

export const setLoadedLastSeenProducts = (loadedLastSeenProducts) => ({
  type: actionTypes.SET_LOADED_LAST_SEEN_PRODUCTS,
  payload: {
    loadedLastSeenProducts,
  },
});

export const updateCurrentProductFavorite = (currentState) => ({
  type: actionTypes.UPDATE_CURRENT_PRODUCT_FAVORITE,
  payload: currentState,
});

export const loadLastSeenProducts = () => {
  return async (dispatch, getState, API) => {
    dispatch(setLoadedLastSeenProductsLoader(true));

    try {
      const products = await API.searchProducts({
        offset: 1,
        limit: 11,
        withPublish: '1',
        productsIds: getLastSeenProducts().map((p) => String(p)),
      });
      dispatch(setLoadedLastSeenProducts(products));
    } catch (e) {
    } finally {
      dispatch(setLoadedLastSeenProductsLoader(false));
    }
  };
};

export const addToFavouriteProducts = (
  productId: number,
  userId: number,
  callback: (response: any) => void,
) => {
  return async (dispatch, getState, API) => {
    const response = await API.favoriteProduct(userId, productId);
    if (callback) {
      callback(response);
    }
  };
};

export const removeFromFavouriteProducts = (
  productId: number,
  userId: number,
  callback: (response: any) => void,
) => {
  return async (dispatch, getState, API) => {
    const response = await API.unfavoriteProduct(userId, productId);
    if (callback) {
      callback(response);
    }
  };
};

export const sendAddToCartImpressions = (productIds: number[]) => {
  return async (dispatch, getState, API) => {
    const upsellId = process?.env?.REACT_APP_ADD_TO_CART_MODAL_UPSELL_ID;
    if (upsellId && productIds.length) {
      const cart = getState()?.cart?.cart;

      await API.impressionUpsell(
        upsellId,
        {},
        {
          type: 'UPSELL',
          cart_id: cart ? cart?.id : undefined,
        },
      );

      API.impressionUpsell(
        upsellId,
        {},
        {
          type: 'UPSELL_PRODUCT',
          cart_id: cart ? cart?.id : undefined,
          product_ids: productIds,
        },
      );
    }
  };
};
