import { Loader } from '../_helpers/Loader/Loader';
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import { __ } from 'react-i18n';
import Icon from '../_helpers/Icon/Icon';
import {
  formatPrice,
  getGiftCartRemain,
  getGiftWebContentIdFromSettings,
  prop,
} from '../../utilities';
//import { RadioStyled } from '../_helpers/form/Radio';
//import { Row } from '../../theme/libraries/grid';
import {
  /*loadProduct, */ setArticleModal,
  setGiftRemain,
} from '../../containers/App/actions';
//import { Gift } from '../../library/Gifts';
import { updateCart } from '../../containers/Cart/actions';
import GalleryModal from '../_helpers/Modal/GalleryModal';
//import { Link } from 'react-router';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import Overlay from '../_helpers/Overlay/Overlay';
import {
  Select,
  SelectStyledStyles,
} from '../../components/_helpers/form/Select';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: ${rem(10)} ${rem(20)};
  margin-top: ${rem(2)};
  box-shadow: ${({ theme }) => theme.boxShadow.small};
  font-size: ${rem(14)};

  ${({ theme }) => theme.media('sm')} {
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    margin-top: 0;
    margin-bottom: ${rem(15)};
  }
`;

/*
const RowStyled = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${rem(15)};
`;

const Col = styled.div`
  flex: 1 0 100%;

  ${({ theme }) => theme.media('sm')} {
    flex: 1 0 50%;
  }

  ${({ theme }) => theme.media('lg')} {
    flex: 1 0 33%;
  }
`;
*/

const P = styled.div`
  display: inline-block;
  align-self: center;
  margin: 0;

  &.success {
    color: ${({ theme }) => theme.color.success};
  }

  &.error {
    color: ${({ theme }) => theme.color.error};
  }
`;
/*
const PLink = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.color.black};
  margin-left: ${rem(34)};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;
*/

const TextWrapper = styled.div`
  text-align: center;
  padding-bottom: ${rem(8)};

  ${({ theme }) => theme.media('sm')} {
    text-align: initial;
    display: flex;
  }
`;

const GiftIcon = styled(Icon)`
  ${({ theme }) => theme.size(58)};
  fill: ${({ theme }) => theme.color.success};
  margin-right: ${rem(15)};
`;

/*
const RadioStyledStyled = styled(RadioStyled)`
  ${({ theme }) => theme.media('md')} {
    padding-bottom: 0;
    padding-top: 0;
  }
`;
*/

const StyledSelect = styled(Select)`
  ${SelectStyledStyles};
  display: block;
  width: 100%;
  margin-top: ${rem(10)};
  ${({ theme }) => theme.media('sm')} {
    width: initial;
  }
`;

const GiftText = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

const notSelectedGiftValue = '0';
const photosPlaceholder = ['images/placeholder-520.jpg'];

interface CartGiftProps {
  cart: any;
  dispatch: any;
  eshopGifts: any;
  product?: any;
  productLoading?: boolean;
  settings?: any;
}

const CartGift = (props: CartGiftProps) => {
  const [galleryProductId, setGalleryProductId] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  const {
    cart,
    dispatch,
    eshopGifts,
    product,
    productLoading,
    settings,
  } = props;

  /*
  const giftFirstDegree = eshopGifts?.find(
    (e) => e.product_plu === Gift.FIRST_DEGREE,
  );
  const giftSecondDegree = eshopGifts?.find(
    (e) => e.product_plu === Gift.SECOND_DEGREE,
  );
  */

  const chosenGift = cart?.chosen_gift;
  const remain = getGiftCartRemain(cart);

  let photos = prop(product, `${galleryProductId}.photogallery`, []);
  if (!photos.length) {
    photos = photosPlaceholder;
  }

  React.useEffect(() => {
    async function handleCartgift() {
      if (remain > 0 && chosenGift) {
        const newCart = {
          ...cart,
          chosen_gift: undefined,
        };

        setLoading(true);
        await dispatch(updateCart(newCart));
        setLoading(false);
      }
    }

    handleCartgift();
  }, [cart]);

  const handleGiftChange = async (event) => {
    if (remain > 0) {
      dispatch(setGiftRemain(true));
    } else {
      const newCart = {
        ...cart,
        chosen_gift: {
          product_id:
            event.target.value && event.target.value !== notSelectedGiftValue
              ? event.target.value
              : undefined,
        },
      };
      setLoading(true);
      await dispatch(updateCart(newCart));
      setLoading(false);
    }
  };

  const onGiftTextClick = () => {
    props.dispatch(setArticleModal(getGiftWebContentIdFromSettings(settings)));
  };

  /*
  const openGallery = (productId) => {
    dispatch(loadProduct(productId));
    setGalleryProductId(productId);
  };
  */

  const closeGallery = () => {
    setGalleryProductId(undefined);
  };

  if (eshopGifts?.length) {
    return (
      <>
        <Loader loading={loading}>
          <Wrapper>
            <TextWrapper>
              <GiftIcon icon="gift" />
              <P>
                <strong>
                  {__('Rozdávame')}{' '}
                  <GiftText onClick={onGiftTextClick}>{__('darčeky')}</GiftText>{' '}
                  {__('na podporu vzdelávania.')}
                </strong>{' '}
                {__(
                  'Nakúpte nad 40 € a vyberte si svoj darček v hodnote 40 €.',
                )}{' '}
                {remain > 0 ? (
                  <>
                    {__('Nakúpte za')}{' '}
                    <P className={'error'}>
                      {__('ďalších')}{' '}
                      <strong>{formatPrice(remain, 'EUR')}</strong>
                    </P>{' '}
                    {__('a získate')}{' '}
                    <P className={'success'}>
                      <strong>{__('darček zadarmo.')}</strong>
                    </P>{' '}
                  </>
                ) : (
                  ''
                )}
                {__(
                  'Ponuka platí pre maloobchodný nákup v internetovom obchode.',
                )}{' '}
                {__('Zvoľte si svoj darček')}:
                <div>
                  <StyledSelect
                    name="gift_choose"
                    onChange={handleGiftChange}
                    items={[
                      {
                        product_name: __('Nemám záujem o darček'),
                        product_id: '0',
                        product_plu: '0',
                      },
                    ]
                      .concat(eshopGifts)
                      .map((eshopGift) => {
                        return {
                          name:
                            eshopGift.product_id !== '0'
                              ? eshopGift.product_name + ' ZŠ'
                              : eshopGift.product_name,
                          value: eshopGift.product_id,
                          selected:
                            eshopGift.product_id === chosenGift?.product_id,
                        };
                      })}
                  ></StyledSelect>
                </div>
              </P>
            </TextWrapper>

            {/*
            <RowStyled>
              <Col>
                <RadioStyledStyled
                  name="gift"
                  id="0"
                  value={notSelectedGiftValue}
                  checked={!chosenGift}
                  onChange={handleGiftChange}
                >
                  {__('Nemám záujem o darček')}
                </RadioStyledStyled>
              </Col>
              <Col>
                <RadioStyledStyled
                  name="gift"
                  id="1"
                  value={giftFirstDegree?.product_id || '1'}
                  checked={chosenGift?.product_plu === Gift.FIRST_DEGREE}
                  onChange={handleGiftChange}
                >
                  <strong>
                    {__('Darček pre 1. stupeň ZŠ za 30 € zdarma')}
                  </strong>
                  <br />
                </RadioStyledStyled>
                <PLink
                  onClick={openGallery.bind(null, giftFirstDegree?.product_id)}
                >
                  {__('Zobraziť obsah darčeka')}
                </PLink>
              </Col>
              <Col>
                <RadioStyledStyled
                  name="gift"
                  id="2"
                  value={giftSecondDegree?.product_id || '2'}
                  checked={chosenGift?.product_plu === Gift.SECOND_DEGREE}
                  onChange={handleGiftChange}
                >
                  <strong>
                    {__('Darček pre 2. stupeň ZŠ za 30 € zdarma')}
                  </strong>
                  <br />
                </RadioStyledStyled>
                <PLink
                  onClick={openGallery.bind(null, giftSecondDegree?.product_id)}
                >
                  {__('Zobraziť obsah darčeka')}
                </PLink>
              </Col>
            </RowStyled>
            */}
          </Wrapper>
        </Loader>
        {galleryProductId &&
          (productLoading ? (
            <Overlay>
              <Loader loading={productLoading} />
            </Overlay>
          ) : (
            <GalleryModal
              pictures={photos}
              activeIndex={0}
              onCloseButtonClick={closeGallery}
            />
          ))}
      </>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  return {
    product: state.general.product,
    productLoading: state.general.productLoading,
    settings: state.general.settings,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartGift' })(CartGift),
);
