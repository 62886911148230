import * as React from 'react';
import styled from 'styled-components';
import {
  InputDefaultStyles,
  InputStyledStyles,
  InputStyles,
} from './FormStyles';

const InputDefault = styled.input`
  ${InputStyles};
  ${InputDefaultStyles};
  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  }
`;

export const InputStyled = styled(InputDefault)`
  ${InputStyledStyles};
`;

export const InputStyledBlock = styled(InputStyled)`
  display: block;
  width: 100%;
`;

interface Props {
  type?: string;
  name: string;
  disabled?: boolean;
  readonly?: boolean;
  children?: any;
  className?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (event) => void;
}

interface State {
  value: string;
}

export class Input extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      value: props.value,
    };
  }

  public onValueChanged = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  public render() {
    const {
      type,
      disabled,
      children,
      className,
      readonly,
      placeholder,
      defaultValue,
      name,
    } = this.props;
    return (
      <InputDefault
        onChange={this.onValueChanged}
        type={type ? type : 'text'}
        name={name}
        disabled={disabled}
        readOnly={readonly}
        className={className}
        value={this.state.value}
        placeholder={placeholder}
        defaultValue={defaultValue}
      >
        {children}
      </InputDefault>
    );
  }
}
