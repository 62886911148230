import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { Container } from '../../theme/libraries/grid';
import { rem } from 'polished';
import styled from 'styled-components';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import {
  ItemRadio,
  ItemRadioDesc,
  ItemRadioLabel,
  RadioItemsWrapper,
  RadioWrapper,
  RatioItem,
} from '../../components/_helpers/form/Radio';
import { useState } from 'react';
import {
  Select,
  SelectStyledStyles,
} from '../../components/_helpers/form/Select';
import API, { ThenArg } from '../../services/API';
import { loadUniversalSets } from '../Home/actions';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  loadSkvelkoSetList,
  setCreatingNewSetState,
  setNewSetState,
} from './actions';
import { Loader } from '../../components/_helpers/Loader/Loader';
import Alert from '../../components/Alert/Alert';
import { push } from 'react-router-redux';
import { URL_SET_NEW } from '../../constants';
import {
  getInitialStateNewSet,
  getProductsFromResponse,
  NewSetState,
} from './NewSet';

const ContainerRequestProductPrice = styled(Container)`
  ${({ theme }) => theme.media('sm')} {
    margin-top: ${rem(60)};
  }
`;

const AlertStyled = styled(Alert)`
  margin-bottom: ${rem(16)};
`;

const StyledSelect = styled(Select)`
  ${SelectStyledStyles};
  display: block;
  margin-top: ${rem(16)};
  width: ${rem(200)};
`;

const ButtonSubmit = styled(ButtonStyled)`
  float: right;
  margin-top: ${rem(32)};
`;

interface NewSetChooseProps {
  universalSets: any[];
  setList: ThenArg<typeof API.searchSkvelkoSet>;
  setTitleText: (title: string) => void;
  dispatch: (a: any) => void;
  skvelkoSetListLoader: boolean;
}

const NewSetChoose = (props: NewSetChooseProps) => {
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedUniversalValue, setSelectedUniversalValue] = useState(0);
  const [selectedCopyValue, setSelectedCopyValue] = useState(0);
  const [showUnselectedValue, setShowUnselectedValue] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    setTitleText,
    universalSets,
    setList,
    dispatch,
    skvelkoSetListLoader,
  } = props;

  const initialLoad = async () => {
    const promises: any[] = [];
    setLoading(true);

    if (!universalSets || !universalSets.length) {
      promises.push(dispatch(loadUniversalSets()));
    }

    if (!setList) {
      promises.push(dispatch(loadSkvelkoSetList()));
    }

    await Promise.all(promises);
    setLoading(false);
  };

  React.useEffect(() => {
    if (setTitleText) {
      setTitleText(__('Obsah zoznamu'));
    }
    initialLoad();
  }, []);

  const handleRadioChange = (event) => {
    setSelectedValue(Number(event.target.value));
    setSelectedUniversalValue(0);
    setSelectedCopyValue(0);
    setShowUnselectedValue(false);
  };

  const handleContinue = () => {
    if (
      (selectedValue === 1 && selectedUniversalValue === 0) ||
      (selectedValue === 2 && selectedCopyValue === 0)
    ) {
      setShowUnselectedValue(true);
      return;
    }

    switch (selectedValue) {
      case 0:
        dispatch(push(URL_SET_NEW));
        break;
      case 1:
        setNewSetStateBySelectedSetId(selectedUniversalValue, true);
        break;
      case 2:
        setNewSetStateBySelectedSetId(selectedCopyValue, false);
        break;
      default:
        console.log('Ouuu, something surprising has happened :/');
    }
  };

  const setNewSetStateBySelectedSetId = async (id, universal = false) => {
    const request = {
      id,
      limit: 1,
      withGoods: true,
      withProducts: true,
    };
    let skvelkoSetResult;
    if (universal) {
      skvelkoSetResult = await API.searchSkvelkoSetPublic(request);
    } else {
      skvelkoSetResult = await API.searchSkvelkoSet(request);
    }
    if (skvelkoSetResult.search.length > 0) {
      const skvelkoSet = skvelkoSetResult.search[0];
      dispatch(setCreatingNewSetState(false));
      let newState: NewSetState = {
        ...getInitialStateNewSet(),
        selectedProducts: getProductsFromResponse(skvelkoSet),
      };
      if (universal) {
        newState = {
          ...newState,
          name1: skvelkoSet.name1,
          oldName1: skvelkoSet.name1,
          name2: skvelkoSet.name2,
          oldName2: skvelkoSet.name2,
          name3: skvelkoSet.name3,
          oldName3: skvelkoSet.name3,
        };
      }
      dispatch(setNewSetState(newState));
    }
    dispatch(push(URL_SET_NEW));
  };

  const onlyAvailable = (set) => set?.skvelkoSetAvailabilityId === 1;

  const items = [
    {
      name: 'empty',
      label: __('Vybrať produkty jednotlivo'),
      desc: __(
        'Začnete prázdnym zoznamom a postupne si zvolíte obsah - položku po položke.',
      ),
    },
    {
      name: 'universal',
      label: __('Univerzálny odporúčaný zoznam'),
      desc: __(
        'Pre každý ročník sme pripravili univerzálny zoznam, ktorý môžete použiť ako základ pre svoju voľbu (s možnosťou dodatočného upravenia položiek).',
      ),
      selectSets: universalSets?.filter(onlyAvailable).map((set) => ({
        value: set?.id,
        name: `${set?.schoolName} ${set?.fullName}`,
      })),
      selectValue: selectedUniversalValue,
      selectOnChange: (event) => {
        setSelectedUniversalValue(event.target.value);
        setShowUnselectedValue(false);
      },
    },
    {
      name: 'copy',
      label: __('Skopírovať môj existujúci zoznam'),
      desc: __(
        'Ak Vám ako podklad pre nový zoznam vyhovuje obsah Vašého už existujúceho zoznamu - zvoľte z ktorého (s možnosťou dodatočného upravenia položiek).',
      ),
      selectSets: setList?.search?.map((set) => ({
        value: set?.id,
        name: set?.fullName,
      })),
      selectValue: selectedCopyValue,
      selectOnChange: (event) => {
        setSelectedCopyValue(event.target.value);
        setShowUnselectedValue(false);
      },
    },
  ];

  return (
    <ContainerRequestProductPrice className="newsletter">
      {showUnselectedValue ? (
        <AlertStyled type={'error'}>{__('Zvoľte ročník')}</AlertStyled>
      ) : (
        ''
      )}

      <Loader loading={loading || skvelkoSetListLoader}>
        <RadioWrapper>
          {items.map((item, index) => (
            <RadioItemsWrapper key={index}>
              <ItemRadio
                type="radio"
                name={item.name}
                id={index}
                value={index}
                onChange={handleRadioChange}
                checked={selectedValue === index}
              />
              <RatioItem htmlFor={index}>
                <ItemRadioLabel>{item.label}</ItemRadioLabel>
                <ItemRadioDesc>{item.desc}</ItemRadioDesc>
                {item.selectSets ? (
                  <StyledSelect
                    name="set"
                    value={item.selectValue}
                    placeholder={'Zvoľte ročník'}
                    items={item.selectSets}
                    disabled={selectedValue !== index}
                    onChange={item.selectOnChange}
                  />
                ) : (
                  ''
                )}
              </RatioItem>
            </RadioItemsWrapper>
          ))}
        </RadioWrapper>

        <ButtonSubmit
          className="primary lg"
          type="submit"
          onClick={handleContinue}
        >
          {__('Pokračovať')}
        </ButtonSubmit>
      </Loader>
    </ContainerRequestProductPrice>
  );
};

const mapStateToProps = (state) => {
  return {
    universalSets: state.home?.universalSets || [],
    setList: state.myAccount?.setList,
    skvelkoSetListLoader: state.myAccount.skvelkoSetListLoader,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'NewSetChoose' })(NewSetChoose),
);
