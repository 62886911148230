import * as React from 'react';
import BaseModal, { Footer } from './BaseModal';
import { ButtonStyledStyles, ButtonStyles, ButtonStyled } from '../form/Button';
import styled from 'styled-components';
import { rem } from 'polished';

const SubmitButton = styled(ButtonStyled)`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  margin-right: ${rem(5)};
  width: 100%;
`;

const StyledModal = styled(BaseModal)``;

interface ModalProps {
  title?: string;
  body: any;
  submitButtonText: string;
  onCloseButtonClick?: (event) => void;
  onSubmitButtonClick: (event) => void;
}

class SubmitFormModal extends React.PureComponent<ModalProps> {
  public render() {
    const { title, body, submitButtonText } = this.props;

    const footer = (
      <SubmitButton
        className="primary lg"
        onClick={this.props.onSubmitButtonClick}
      >
        {submitButtonText}
      </SubmitButton>
    );

    return (
      <StyledModal
        title={title}
        body={body}
        footer={<Footer>{footer}</Footer>}
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default SubmitFormModal;
