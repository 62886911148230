import * as React from 'react';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { composeValidators, required } from '../../utilities/validators';
import styled, { css } from 'styled-components';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';
import { InputStyled } from '../../components/_helpers/form/Input';
import { Container } from '../../theme/libraries/grid';
import { rem } from 'polished';
import { __ } from 'react-i18n';
import { SelectStyled } from '../../components/_helpers/form/Select';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import Icon from '../../components/_helpers/Icon/Icon';
import { DropdownStyles } from '../../components/Dropdown/Dropdown';
import ImagePlaceholder from '../../components/_helpers/Image/ImagePlaceholder';
import { ThumbStyles } from '../../components/_helpers/product/product-styles';
import API from '../../services/API';
import OnClickOutsideComponent from '../../components/_helpers/OnClickOutsideComponent/OnClickOutsideComponent';
import { canShowCustomerProducts, debounce, resetForm } from '../../utilities';
import Alert from '../../components/Alert/Alert';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { Loader } from '../../components/_helpers/Loader/Loader';

const ContainerRequestProductPrice = styled(Container)`
  ${({ theme }) => theme.media('sm')} {
    margin-top: ${rem(60)};
  }
`;

const Col = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
  }
`;

const Row = styled.div`
  flex: 1;

  ${({ theme }) => theme.media('sm')} {
    &:not(:first-child) {
      margin-left: ${rem(10)};
    }
    &:not(:last-child) {
      margin-right: ${rem(10)};
    }
  }
`;

const InputRow = styled.div`
  margin-bottom: ${rem(30)};
`;

const FormLabel = styled.strong`
  display: inline-block;
  padding-bottom: ${rem(8)};
`;

const FormInputCss = css`
  width: 100%;
  padding-top: ${rem(13)};
  padding-bottom: ${rem(13)};
  ${(props: any) => {
    return props.error ? 'border:2px solid red;' : '';
  }}
`;

const FormInput = styled(InputStyled)`
  ${FormInputCss};
`;

const SearchInput = styled(FormInput)`
  padding-right: ${rem(54)};
`;

const FormSelect = styled(SelectStyled)`
  ${FormInputCss};
  &.noLabel {
    ${({ theme }) => theme.media('sm')} {
      margin-top: ${rem(32)};
    }
  }
`;

const DescMessage = styled.em`
  color: ${({ theme }) => theme.color.info};
  margin: 0;
`;

const ButtonSubmit = styled(ButtonStyled)`
  float: right;
`;

const SearchWrapper = styled.div`
  position: relative;
`;

const SearchSubmit = styled(ButtonStyled)`
  background-color: transparent;
  border: none;
  line-height: 0;
  padding: ${rem(14)};
  position: absolute;
  top: 0;
  right: 0;
`;

const SearchIcon = styled(Icon)`
  ${({ theme }) => theme.size(16)};
`;

const SearchDropdown = styled(OnClickOutsideComponent)`
  ${DropdownStyles};
  font-size: ${rem(14)};
  width: 100%;
  height: 80vh;
  top: 100%;
  left: 0;
  z-index: 1;
  overflow-y: auto;
  transform: translateY(rem(5));
  ${({ theme }) => theme.media(1024)} {
    height: auto;
    max-height: ${rem(450)};
  }
  &.visible {
    display: block;
  }
`;

const SearchBlock = styled.div`
  padding: ${rem(20)};
  & + & {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
`;

const NotFound = styled(SearchBlock)`
  padding: ${rem(40)};
  text-align: center;
`;

const Products = styled(SearchBlock)``;

const Product = styled.a`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  margin-bottom: ${rem(20)};
  cursor: pointer;
  ${({ theme }) => theme.transition('color')};
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
  }
`;

const ThumbWrapper = styled.div`
  flex: 0 0 ${rem(75)};
`;

const Thumb = styled(ImagePlaceholder)`
  ${ThumbStyles};
  width: ${rem(46)};
  padding: 0;
`;

const Title = styled.p`
  margin: 0;
`;

const Meta = styled.div`
  flex: 1 1 auto;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: ${rem(20)};
`;

interface RequestProductPriceProps {
  user: any;
  dispatch: any;
  setTitleText: (title: string) => void;
  setClosePath: (path: string) => void;
}

const RequestProductPrice = (props: RequestProductPriceProps) => {
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [searchDropdownVisible, setSearchDropdownVisible] = useState(false);
  const [searchResultLoading, setSearchResultLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([] as any[]);
  const [searchDebounce] = debounce(
    () =>
      API.searchProducts({ q: searchQuery }).then(({ products }) => {
        setSearchResultLoading(false);
        setSearchResult(products || []);
      }),
    500,
  );

  const { setTitleText, setClosePath, dispatch, user } = props;
  const placeholder = '/images/placeholder-46.png';
  const placeholder2x = '/images/placeholder-46@2x.png';

  React.useEffect(() => {
    setClosePath('/my-account/products-customer');
    if (!canShowCustomerProducts(user)) {
      dispatch(push('/my-account'));
    }
    if (setTitleText) {
      setTitleText('Nový produkt za dohodnutú cenu');
    }
  }, []);

  const handleSearchChange = ([e], state, utils) => {
    const value = e.target.value;

    setSearchQuery(value);
    setSelectedProduct(undefined);

    utils.changeValue(state, 'search', () => value);

    if (value.length >= 3) {
      handleSearchSubmit();
    }
  };

  const handleSearchSubmit = () => {
    setSearchResultLoading(true);
    setSearchDropdownVisible(true);
    searchDebounce();
  };

  const handleSearchSelect = ([product], state, utils) => {
    const productName = product.name;

    setSearchDropdownVisible(false);
    setSearchQuery(productName);
    setSelectedProduct(product);

    utils.changeValue(state, 'search', () => productName);
    utils.resetFieldState('search');
  };

  const handleSearchClickOutside = () => {
    setSearchDropdownVisible(false);
  };

  const handleSearchClick = () => {
    if (searchResult.length) {
      setSearchDropdownVisible(true);
    }
  };

  const onSubmit = async (values, form) => {
    try {
      setRequestLoading(true);
      setRequestStatus(0);
      const product: any = selectedProduct;
      await API.requestCustomerPriceForm(
        {
          xAcceptLanguage: 'sk',
        },
        {
          ...values,
          product_name: product.name,
          produkt_plu: product.plu,
          quantity: values.quantity,
          period: values.period,
        },
      );
      setRequestLoading(false);
      setRequestStatus(1);

      resetForm(values, form);
    } catch (e) {
      setRequestLoading(false);
      setRequestStatus(-1);
    }
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const isSelectedProduct = () =>
    selectedProduct ? undefined : __('Nezvlolili ste produkt');

  const renderProduct = (form, product, i) => (
    <Product
      key={i}
      value={product.name}
      onClick={form.mutators.handleSearchSelect.bind(null, product)}
    >
      <ThumbWrapper>
        <Thumb
          src={product.picture}
          placeholder={placeholder}
          retinaPlaceholder={placeholder2x}
          notLazyloading={true}
        />
      </ThumbWrapper>
      <Meta>
        <Title>{product.name}</Title>
      </Meta>
    </Product>
  );

  const renderForm = ({ form, handleSubmit }) => (
    <Loader loading={requestLoading}>
      <form onSubmit={handleSubmit}>
        <Col>
          <Row>
            <Field
              name="search"
              validate={composeValidators(required, isSelectedProduct)}
            >
              {({ input, meta }) => (
                <InputRow className={meta.error && meta.touched ? 'error' : ''}>
                  <FormLabel>{__('Názov alebo kód produktu')}:</FormLabel>
                  <SearchWrapper>
                    <SearchInput
                      {...input}
                      type="search"
                      error={meta.error && meta.touched}
                      onClick={handleSearchClick}
                      onChange={form.mutators.handleSearchChange}
                      onKeyDown={handleSearchKeyDown}
                    />
                    <SearchSubmit onClick={handleSearchSubmit}>
                      <SearchIcon icon="search" />
                    </SearchSubmit>
                    <SearchDropdown
                      onClickOutside={handleSearchClickOutside}
                      className={searchDropdownVisible ? `visible` : ''}
                    >
                      <Loader loading={searchResultLoading}>
                        {searchResult.length ? (
                          <Products>
                            {searchResult.map((product, i) =>
                              renderProduct(form, product, i),
                            )}
                          </Products>
                        ) : !searchResultLoading ? (
                          <NotFound>
                            {__('Nenašli sa žiadne produtky')}
                          </NotFound>
                        ) : (
                          ''
                        )}
                      </Loader>
                    </SearchDropdown>
                  </SearchWrapper>
                  {meta.error && meta.touched && (
                    <ErrorPopup>{meta.error}</ErrorPopup>
                  )}
                </InputRow>
              )}
            </Field>
          </Row>
        </Col>
        <Col>
          <Row>
            <Field name="quantity" validate={required}>
              {({ input, meta }) => (
                <InputRow className={meta.error && meta.touched ? 'error' : ''}>
                  <FormLabel>{__('Predpokladané odberné množstvo')}:</FormLabel>
                  <FormInput {...input} error={meta.error && meta.touched} />
                  {meta.error && meta.touched && (
                    <ErrorPopup>{meta.error}</ErrorPopup>
                  )}
                  <DescMessage>{__('Napr: 20 balení')}</DescMessage>
                </InputRow>
              )}
            </Field>
          </Row>
          <Row>
            <Field name="period" validate={required}>
              {({ input, meta }) => (
                <InputRow className={meta.error && meta.touched ? 'error' : ''}>
                  <FormSelect
                    {...input}
                    error={meta.error && meta.touched}
                    className={'noLabel'}
                  >
                    <option value="" />
                    {['mesačne', 'kvartálne', 'ročne'].map((value, index) => {
                      return (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      );
                    })}
                  </FormSelect>
                  {meta.error && meta.touched && (
                    <ErrorPopup>{meta.error}</ErrorPopup>
                  )}
                </InputRow>
              )}
            </Field>
          </Row>
        </Col>
        <ButtonSubmit className="primary lg" type="submit">
          {__('Odoslať žiadosť')}
        </ButtonSubmit>
      </form>
    </Loader>
  );

  return (
    <ContainerRequestProductPrice className="narrow">
      {requestStatus === 1 && (
        <StyledAlert type="success">
          {__('Žiadosť o pridanie produktu bola úspešne odoslaná.')}
        </StyledAlert>
      )}

      {requestStatus === -1 && (
        <StyledAlert type="error">
          {__(
            'Žiadosť o pridanie produktu bola neúspešne odoslaná. Prosím kontaktujte zákaznicku podporu.',
          )}
        </StyledAlert>
      )}

      <Form
        onSubmit={onSubmit}
        render={renderForm}
        mutators={{
          handleSearchChange,
          handleSearchSelect,
        }}
      />
    </ContainerRequestProductPrice>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'RequestProductPrice' })(RequestProductPrice),
);
