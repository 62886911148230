import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

const H1 = styled.h1``;

interface HeadingProps {
  children: any;
  className?: string;
}

export class Heading1 extends React.PureComponent<HeadingProps> {
  public render() {
    const { children, className } = this.props;
    return <H1 className={className}>{children}</H1>;
  }
}

const H2 = styled.h2`
  font-size: ${rem(20)};
  font-weight: 400;
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(24)};
  }
`;

export class Heading2 extends React.PureComponent<HeadingProps> {
  public render() {
    const { children, className } = this.props;
    return <H2 className={className}>{children}</H2>;
  }
}

const H3 = styled.h3`
  font-size: ${rem(18)};
  font-weight: 400;
`;

export class Heading3 extends React.PureComponent<HeadingProps> {
  public render() {
    const { children, className } = this.props;
    return <H3 className={className}>{children}</H3>;
  }
}
