import * as React from 'react';
import { useFilter } from '../../hooks/filter';
import {
  DEFAULT_WEBCONTENT_FILTER_TYPES_SEARCH,
  defaultSearchParameters,
  onPageChangeDefault,
  onSortChangeDefault,
  SORT,
} from '../Category/constants';
import Filter from '../Category/Filter';
import { WEBS_PER_PAGE } from '../../constants';
import {
  getTotal,
  isObjectEmpty,
  isSameObject,
  scrollToTop,
} from '../../utilities';
import { rem } from 'polished';
import styled from 'styled-components';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { __ } from 'react-i18n';
import WebContentList from '../../components/_layout/Header/SearchResult/WebContentList';
import { Placeholder } from './styles';
import { loadSearchedWebContentResults } from '../App/actions';
import { forwardRef, useState } from 'react';

const Wrapper = styled.div`
  margin-top: ${rem(12)};
`;

interface SearchEshopProps {
  ref: any;
  dispatch: any;
  location: any;
  searchedResults: any;
  searchedResultsLoader: boolean;
}

const SearchWeb = forwardRef((props: SearchEshopProps, ref) => {
  const {
    dispatch,
    location,
    searchedResultsLoader,
    searchedResults: { webContent },
  } = props;

  const [prevFilters, setPrevFilters] = useState({});
  const { filters, addFilter } = useFilter({
    ...DEFAULT_WEBCONTENT_FILTER_TYPES_SEARCH,
    queryLocation: location?.query,
    dispatch: dispatch,
  });

  const limit = WEBS_PER_PAGE;
  const sort = SORT;
  const onPageChange = (page) => onPageChangeDefault(addFilter, page);
  const onSortChange = (clickedSort, clickedSortDir) =>
    onSortChangeDefault(addFilter, clickedSort, clickedSortDir);

  const handleFilterChanged = async () => {
    if (isObjectEmpty(filters) || isSameObject(prevFilters, filters)) {
      return;
    }

    let parameters = { ...defaultSearchParameters(filters, limit) };
    await dispatch(loadSearchedWebContentResults(parameters));
    scrollToTop();
  };

  React.useEffect(() => {
    handleFilterChanged().then();
    setPrevFilters(filters);
  }, [filters]);

  React.useEffect(() => {
    //setPrevFilters(filters);
    //setDefaults(true);
  }, []);

  React.useImperativeHandle(ref, () => ({
    resetFilter() {
      //setDefaults(true);
      addFilter({ name: 'section', value: '1' });
    },
  }));

  const paginationTotal = getTotal({ ...webContent, limit });

  const webContentCount = webContent?.total ?? 0;

  return (
    <Loader loading={searchedResultsLoader}>
      <Wrapper>
        {webContentCount > limit ? (
          <Filter
            sort={sort}
            onSortChange={onSortChange}
            paginationCurrent={filters.page}
            paginationTotal={paginationTotal}
            onPageChanged={onPageChange}
            isSearch={true}
            isSortable={false}
          />
        ) : (
          ''
        )}

        {webContent?.web_content?.length ? (
          <WebContentList webContent={webContent} />
        ) : (
          <Placeholder>{__('Obsah nenájdený')}</Placeholder>
        )}
      </Wrapper>
    </Loader>
  );
});

export default SearchWeb;
