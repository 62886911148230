import { css } from 'styled-components';
import { rem } from 'polished';

export const CaretStyles = css`
  display: inline-block;
  border: ${rem(4)} solid transparent;
  border-top-color: ${({ theme }) => theme.color.text};
  border-bottom: none;
  vertical-align: middle;
`;
