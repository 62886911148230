import * as React from 'react';
import { VisuallyHiddenStyles } from '../../../theme/libraries/h5b-helpers';
import { desaturate, lighten, rem } from 'polished';
import {
  CheckboxRadioLabelDefaultStyles,
  CheckboxRadioLabelStyledStyles,
} from './FormStyles';
import {
  CheckboxRadioInputProps,
  CheckboxRadioLabelProps,
  CheckboxRadioProps,
} from './FormInterfaces';
import styled from 'styled-components';

const CheckboxLabelDefault = styled.label.attrs({ type: 'xxx' })`
  ${CheckboxRadioLabelDefaultStyles};
`;

const CheckboxLabelStyled = styled(CheckboxLabelDefault)`
  ${CheckboxRadioLabelStyledStyles};
  padding-left: ${({ theme }) =>
    rem(theme.form.checkbox.size + theme.form.checkbox.gutter)};
  &:before {
    background: ${({ theme }) => theme.form.background.default};
    border: ${({ theme }) => rem(theme.form.border.width)}
      ${({ theme }) => theme.form.border.style}
      ${({ theme }) => theme.form.border.color};
    border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
    ${({ theme }) => theme.size(theme.form.checkbox.size)};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    ${({ theme }) => theme.transitions('background, border, box-shadow')};
  }
  &:after {
    ${({ theme }) => theme.size(12, 6)};
    border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.white};
    border-left: ${rem(2)} solid ${({ theme }) => theme.color.white};
    top: 50%;
    left: ${rem(6)};
    transform: translateY(${rem(-4)}) scale(0) rotate(0);
    ${({ theme }) => theme.transition('transform')};
  }
`;

const CheckboxInputDefault = styled.input.attrs({ type: 'checkbox' })`
  margin-right: ${rem(5)};
  &:disabled {
    & + ${CheckboxLabelDefault} {
      color: ${({ theme }) => theme.form.color.disabled};
    }
  }
`;

const CheckboxInputStyled = styled(CheckboxInputDefault)`
  ${VisuallyHiddenStyles};
  &:disabled {
    & + ${CheckboxLabelDefault} {
      &:before {
        background: ${({ theme }) => theme.form.background.disabled};
      }
    }
    &:checked {
      & + ${CheckboxLabelDefault} {
        &:before {
          background: ${({ theme }) =>
            desaturate(0.2, lighten(0.2, theme.color.primary))};
          border-color: ${({ theme }) =>
            desaturate(0.2, lighten(0.2, theme.color.primary))};
        }
      }
    }
  }
  &:focus {
    & + ${CheckboxLabelDefault} {
      &:before {
        border-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
  &:checked {
    & + ${CheckboxLabelDefault} {
      &:before {
        background: ${({ theme }) => theme.color.gradient.primary};
        border-color: ${({ theme }) => theme.color.primary};
      }
      &:after {
        transform: translateY(${rem(-4)}) scale(1) rotate(-45deg);
      }
    }
    &:focus {
      & + ${CheckboxLabelDefault} {
        &:before {
          box-shadow: ${({ theme }) => theme.boxShadow.primary};
        }
      }
    }
  }
`;

class CheckboxInput extends React.Component<CheckboxRadioInputProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { name, id, disabled, value, checked } = this.props;
    return (
      <CheckboxInputDefault
        name={name}
        id={id}
        disabled={disabled}
        value={value}
        checked={checked}
      />
    );
  }
}

class CheckboxLabel extends React.Component<CheckboxRadioLabelProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { id, children, className } = this.props;
    return (
      <CheckboxLabelDefault htmlFor={id} className={className}>
        {children}
      </CheckboxLabelDefault>
    );
  }
}

export class CheckboxDefault extends React.Component<CheckboxRadioProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      name,
      id,
      disabled,
      children,
      className,
      value,
      checked,
    } = this.props;
    return (
      <React.Fragment>
        <CheckboxInput
          name={name}
          id={id}
          disabled={disabled}
          value={value}
          checked={checked}
        />
        <CheckboxLabel id={id} className={className}>
          {children}
        </CheckboxLabel>
      </React.Fragment>
    );
  }
}

export class CheckboxStyled extends React.Component<CheckboxRadioProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      name,
      id,
      disabled,
      children,
      className,
      value,
      checked,
      onChange,
    } = this.props;
    return (
      <React.Fragment>
        <CheckboxInputStyled
          name={name}
          id={id}
          disabled={disabled}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <CheckboxLabelStyled htmlFor={id} className={className}>
          {children}
        </CheckboxLabelStyled>
      </React.Fragment>
    );
  }
}
