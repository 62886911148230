import API from "./services/API";
import { prop } from "./utilities/index";

export async function loadTranslations(location) {
  return API.loadLangTranslation(
    resolveLangByHostname(prop(location, "hostname"))
  );
}

export function resolveLangByHostname(hostname: string): string {
  switch (hostname) {
    case "beta.heliumking.sk":
      return "sk";
    case "beta.heliumking.ro":
      return "pl";
    default:
      return "sk";
  }
}
