import styled from 'styled-components';

export const CloseIcon = styled.span`
  display: inline-block;
  ${({ theme }) => theme.size(20, 2)};
  position: relative;
  vertical-align: middle;
  transform: translate3d(0, -50%, 0);
  &:before,
  &:after {
    display: block;
    content: '';
    background: ${({ theme }) => theme.color.black};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:before {
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  }
  &:after {
    transform: translate3d(-50%, -50%, 0) rotate(45deg);
  }
`;

export const CloseIconWhite = styled(CloseIcon)`
  &:before,
  &:after {
    background: ${({ theme }) => theme.color.white};
  }
`;
