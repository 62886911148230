import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import { Link } from 'react-router';
import GiftBadge from '../Icon/GiftBadge';
import { CloseIcon } from '../Icon/Close';
import * as cookie from 'react-cookies';
import { useState } from 'react';
import { theme } from '../../../theme/theme';

const OuterWrapper = styled.div`
  background-color: ${theme.color.gray97};
  padding-top: ${rem(10)};
  padding-bottom: ${rem(10)};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: ${rem(12)} ${rem(16)};
  background: ${({ theme }) => theme.color.white};
  margin-right: ${({ theme }) => (props) =>
    !props.withoutPadding ? rem(-theme.grid.padding / 2) : ''};
  margin-left: ${({ theme }) => (props) =>
    !props.withoutPadding ? rem(-theme.grid.padding / 2) : ''};
`;

const Text = styled.span`
  margin: 0;
  font-size: ${rem(15)};
  line-height: ${rem(18)};
`;

const LinkStyled = styled(Link)`
  font-size: ${rem(14)};
  text-decoration: underline;
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 0 0 80%;
  padding-left: ${rem(8)};
`;

const GiftWrapper = styled.div`
  flex: 0 0 ${rem(50)};
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: ${rem(6)};
  right: ${rem(6)};
`;

const CloseCircle = styled.div`
  display: inline-block;
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray70};
  border-radius: 50%;
  padding: ${rem(1)};
  line-height: ${rem(11)};
`;

const CloseIconStyled = styled(CloseIcon)`
  ${({ theme }) => theme.size(11, 2)};
  &:before,
  &:after {
    background: ${({ theme }) => theme.color.gray70};
  }
`;

interface GiftsBannerProps {
  onLinkClick: () => void;
  withoutPadding?: boolean;
}

const GiftsBannerMobile = (props: GiftsBannerProps) => {
  const cookieShowPopup = cookie.load('setShowGiftsBanner');

  const [show, setShow] = useState(
    cookieShowPopup === undefined || cookieShowPopup !== 'false',
  );

  const { onLinkClick } = props;

  const onClose = () => {
    setShow(false);
    cookie.save('setShowGiftsBanner', false, {
      path: '/',
      maxAge: 60 * 60 * 24 * 7, // 7 days
    });
  };

  if (show) {
    return (
      <OuterWrapper>
        <Wrapper withoutPadding={props.withoutPadding}>
          <GiftWrapper>
            <GiftBadge />
          </GiftWrapper>
          <TextWrapper>
            <Text>
              {__(
                'Nakúp nad 40€ a získaš vzdelávacie materiály za 40€ ako DARČEK!',
              )}
            </Text>
            <LinkStyled onClick={onLinkClick}>
              {__('Viac o darčekoch')}
            </LinkStyled>
          </TextWrapper>
          <CloseWrapper onClick={onClose}>
            <CloseCircle>
              <CloseIconStyled />
            </CloseCircle>
          </CloseWrapper>
        </Wrapper>
      </OuterWrapper>
    );
  } else {
    return <></>;
  }
};

export default GiftsBannerMobile;
