import * as React from 'react';
import CategoryList from '../../components/_layout/Header/SearchResult/CategoryList';
import styled from 'styled-components';
import { __ } from 'react-i18n/lib';
import { rem } from 'polished';
import Filter from '../Category/Filter';
import {
  concatSearchProductsAndSkvelkoSetsAndSoldOutProducts,
  SearchProductType,
} from '../../utilities/product';
import SetCategoryPreview from '../../components/_helpers/Sets/SetCategoryPreview';
import ProductPreview from '../../components/_helpers/product/ProductPreview';
import Pagination from '../../components/Pagination/Pagination';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { ProductColWrapper, ProductsRowWrapper } from '../Category/Category';
import { PRODUCTS_PER_PAGE } from '../../constants';
import {
  DEFAULT_PRODUCT_FILTER_TYPES_SEARCH,
  defaultSearchParameters,
  onPageChangeDefault,
  onSortChangeDefault,
  SORT,
} from '../Category/constants';
import { useFilter } from '../../hooks/filter';
import {
  getTotal,
  isObjectEmpty,
  isSameObject,
  scrollToTop,
} from '../../utilities';
import {
  loadSearchedCategoryResults,
  loadSearchedProductResults,
} from '../App/actions';
import { forwardRef, useState } from 'react';
import { Placeholder, SectionTitle } from './styles';

const Wrapper = styled.div`
  margin-top: ${rem(12)};
  ${({ theme }) => theme.media('md')} {
    margin-top: ${rem(28)};
  }
`;

const InfoText = styled.div`
  margin-top: ${rem(12)};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const InfoTextParagraph = styled.p`
  margin-bottom: 0px;
`;

interface SearchEshopProps {
  ref: any;
  dispatch: any;
  location: any;
  searchedResults: any;
  searchedResultsLoader: boolean;
  searchedQuery: string;
}

const SearchEshop = forwardRef((props: SearchEshopProps, ref) => {
  const {
    dispatch,
    location,
    searchedQuery,
    searchedResultsLoader,
    searchedResults: { categories, products },
  } = props;

  const { filters, addFilter } = useFilter({
    ...DEFAULT_PRODUCT_FILTER_TYPES_SEARCH,
    queryLocation: location?.query,
    dispatch: dispatch,
  });
  const [prevFilters, setPrevFilters] = useState({});

  const limit = PRODUCTS_PER_PAGE;
  const sort = SORT;
  const onPageChange = (page) => onPageChangeDefault(addFilter, page);
  const onSortChange = (clickedSort, clickedSortDir) =>
    onSortChangeDefault(addFilter, clickedSort, clickedSortDir);

  const categoryCount = categories?.total ?? 0;
  const productCount = products?.total ?? 0;
  const paginationTotal = getTotal(products);

  const handleFilterChanged = async () => {
    if (isObjectEmpty(filters) || isSameObject(prevFilters, filters)) {
      return;
    }

    let parameters = { ...defaultSearchParameters(filters, limit) };
    await dispatch(loadSearchedProductResults(parameters));
    scrollToTop();
  };

  React.useEffect(() => {
    handleFilterChanged().then();
    setPrevFilters(filters);
  }, [filters]);

  React.useEffect(() => {
    //setPrevFilters(filters);
    //setDefaults(true);
  }, []);

  React.useImperativeHandle(ref, () => ({
    resetFilter() {
      //setDefaults(true);
      addFilter({ name: 'section', value: '0' });
    },
  }));

  const showMoreCategories = async () => {
    await dispatch(
      loadSearchedCategoryResults({
        offset: 0,
        webShow: '1',
        parentCategories: true,
        q: location?.query?.q || '',
        limit: (categories?.catalog_category?.length || 0) + 10,
        isSchoolCategory: false,
      }),
    );
  };

  const productsConcated = concatSearchProductsAndSkvelkoSetsAndSoldOutProducts(
    products.products,
    products.skvelko_sets,
    [],
    products.positions,
  );

  return (
    <Loader loading={searchedResultsLoader}>
      <Wrapper>
        {(location?.query?.page || '1') === '1' ? (
          <>
            <SectionTitle>
              <strong>{__('Kategórie v eshope')}</strong> ({categoryCount})
            </SectionTitle>

            {categories?.catalog_category?.length ? (
              <CategoryList
                categories={categories}
                searchedQuery={searchedQuery}
                onMoreClick={showMoreCategories}
              />
            ) : searchedResultsLoader ? (
              ''
            ) : (
              <Placeholder>{__('Kategórie nenájdené')}</Placeholder>
            )}
          </>
        ) : (
          ''
        )}

        <SectionTitle>
          <strong>{__('Produkty v eshope')}</strong> ({productCount})
        </SectionTitle>

        {productCount > limit ? (
          <Filter
            sort={sort}
            onSortChange={onSortChange}
            paginationCurrent={filters.page}
            paginationTotal={paginationTotal}
            onPageChanged={onPageChange}
            isSearch={true}
            isSortable={true}
          />
        ) : (
          ''
        )}
        {productsConcated?.length ? (
          <>
            <ProductsRowWrapper>
              {productsConcated.map((item) => {
                if (item.type === SearchProductType.SKVELKO_SET) {
                  return (
                    <ProductColWrapper key={item.item.id}>
                      <SetCategoryPreview skvelkoSet={item.item} />
                    </ProductColWrapper>
                  );
                } else {
                  return (
                    <ProductColWrapper key={item.item.product_id}>
                      <ProductPreview product={item.item} />
                    </ProductColWrapper>
                  );
                }
              })}
            </ProductsRowWrapper>
            {productCount > limit && (
              <Pagination
                current={filters.page}
                total={paginationTotal}
                onPageChanged={onPageChange}
              />
            )}
          </>
        ) : searchedResultsLoader ? (
          ''
        ) : (
          <Placeholder>{__('Produkty nenájdené')}</Placeholder>
        )}
        <InfoText>
          <InfoTextParagraph>
            {__('Nenašli ste produkt alebo informáciu, ktorú ste hľadali?')}
          </InfoTextParagraph>
          <p style={{ paddingBottom: '12px', marginTop: '0px' }}>
            <a href="/kontakt/kontaktne-informacie?scrollToContactForm=true">
              Napíšte nám{' '}
            </a>
            {__('prosím, pokúsime sa vám pomôcť.')}
          </p>
        </InfoText>
      </Wrapper>
    </Loader>
  );
});

export default SearchEshop;
