import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const InputStyles = css`
  display: inline-block;
  background: ${({ theme }) => theme.color.white};
  border: none;
  border-radius: 0;
  color: ${({ theme }) => theme.color.text};
  line-height: 1.2;
  appearance: none;
  outline: none;
  box-shadow: none;
  ${({ theme }) => theme.media('sm')} {
    font-size: ${({ theme }) => rem(theme.form.font.size)};
  }
  &:disabled {
    cursor: not-allowed;
  }
  &[type='number'] {
    appearance: none;
    -moz-appearance: textfield;
  }
`;

export const InputDefaultStyles = css`
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  padding: ${rem(10)} ${rem(10)};
  ${({ theme }) => theme.transitions('border-color')};

  &.lg {
    ${({ theme }) => theme.media('sm')} {
      font-size: ${rem(16)};
    }
  }
`;

export const InputStyledStyles = css`
  background: ${({ theme }) => theme.form.background.default};
  border: ${({ theme }) => rem(theme.form.border.width)}
    ${({ theme }) => theme.form.border.style}
    ${({ theme }) => theme.form.border.color};
  border-radius: ${({ theme }) => rem(theme.form.border.radius)};
  color: ${({ theme }) => theme.form.color.default};
  padding: ${({ theme }) => rem(theme.form.padding.horizontal)}
    ${({ theme }) => rem(theme.form.padding.vertical)};
  &:focus {
    border-color: ${({ theme }) => theme.form.border.colorFocus};
  }
  &:disabled {
    background: ${({ theme }) => theme.form.background.disabled};
    color: ${({ theme }) => theme.form.color.disabled};
  }
  &::placeholder {
    color: ${({ theme }) => theme.form.color.placeholder};
  }
`;

export const CheckboxRadioLabelDefaultStyles = css`
  line-height: 1.15;
  &[for] {
    cursor: pointer;
  }
`;

export const CheckboxRadioLabelStyledStyles = css`
  display: inline-block;
  padding-top: ${({ theme }) =>
    rem(theme.form.border.width + theme.form.padding.horizontal)};
  padding-bottom: ${({ theme }) =>
    rem(theme.form.border.width + theme.form.padding.horizontal)};
  position: relative;
  ${({ theme }) => theme.media('sm')} {
    font-size: ${({ theme }) => rem(theme.form.font.size)};
  }
  &:before,
  &:after {
    display: block;
    content: '';
    position: absolute;
  }
`;

const searchIconB64 =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNi4xIiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgMTYuMSAxNiI+ICA8ZyBpZD0iR3JvdXBfMjI4NSIgZGF0YS1uYW1lPSJHcm91cCAyMjg1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwKSI+ICAgIDxwYXRoIGlkPSJQYXRoXzcwOCIgZGF0YS1uYW1lPSJQYXRoIDcwOCIgZD0iTTkuMiwyQTQuOTM4LDQuOTM4LDAsMCwwLDUuOCwzLjRhNC44MDgsNC44MDgsMCwwLDAsNi44LDYuOCw0Ljc2OSw0Ljc2OSwwLDAsMCwwLTYuOEE0LjkzOCw0LjkzOCwwLDAsMCw5LjIsMloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMSkiIGZpbGw9Im5vbmUiLz4gICAgPHBhdGggaWQ9IlBhdGhfNzA5IiBkYXRhLW5hbWU9IlBhdGggNzA5IiBkPSJNMTQsMkE2LjgsNi44LDAsMCwwLDIuNCw2LjhhNi44MjksNi44MjksMCwwLDAsMS4zLDRMLjIsMTQuM2EuOTY3Ljk2NywwLDAsMCwwLDEuNEExLjMzLDEuMzMsMCwwLDAsMSwxNmEuOTA4LjkwOCwwLDAsMCwuNy0uM2wzLjUtMy41YTYuNiw2LjYsMCwwLDAsNCwxLjNBNi44MjksNi44MjksMCwwLDAsMTYsNi43LDYuNTA4LDYuNTA4LDAsMCwwLDE0LDJabS0xLjQsOC4yQTQuODA4LDQuODA4LDAsMCwxLDUuOCwzLjRhNC44MjksNC44MjksMCwwLDEsNi44LDBBNC43NjksNC43NjksMCwwLDEsMTIuNiwxMC4yWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4xKSIgZmlsbD0iI2RkZCIvPiAgPC9nPjwvc3ZnPg==';

export const InputWithSearchIcon = css`
  background: transparent right ${rem(15)} center url(${searchIconB64})
    no-repeat;
  background-size: ${rem(16)};
  padding-right: ${rem(40)};
`;

export const ErrorPopup = styled.div`
  position: relative;
  width: fit-content;
  padding: ${rem(2)} ${rem(16)};
  border-radius: ${rem(4)};
  background: ${({ theme }) => theme.color.error};
  color: ${({ theme }) => theme.color.white};
  margin: ${rem(8)} 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: ${rem(12)};
    width: 0;
    height: 0;
    border-width: 0 ${rem(6)} ${rem(8)};
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-right-color: transparent;
    border-left-color: transparent;
    border-image: initial;
    border-bottom-color: ${({ theme }) => theme.color.error};
    margin-left: ${rem(-8)};
    margin-top: ${rem(-8)};
  }
`;
