import * as React from 'react';
import { __ } from 'react-i18n/lib';
import Alert from './Alert';
import { rem } from 'polished';
import styled from 'styled-components';
import {
  hasCartAvailabilityProblems,
  isProblemTypeAvailability,
} from '../../containers/Cart/actions';

export enum AlertAvailabilityType {
  ORDER,
  CART,
}

const AlertTitle = styled.h4`
  margin: 0;
  font-size: ${rem(24)};
  font-weight: normal;
  color: ${({ theme }) => theme.color.error};
`;

const AlertHeadline = styled.p`
  margin: 0;
  font-weight: bold;
  display: inline-block;
  color: ${({ theme }) => theme.color.error};
`;

const AlertStyled = styled(Alert)`
  margin: ${rem(10)} 0;
  padding-top: ${rem(12)};
`;

const Products = styled.ul`
  margin: 0;
`;

interface AlertAvailabilityProps {
  cart: any;
  type?: AlertAvailabilityType;
}

const AlertAvailability = (props: AlertAvailabilityProps) => {
  const {
    cart,
    cart: { problems },
    type,
  } = props;

  const renderOrderType = () => {
    return (
      <AlertStyled type="error">
        <AlertTitle>{__('Objednávka nebola odoslaná')}</AlertTitle>
        {__('Pri kontrole dostupnosti vybraného tovaru')}{' '}
        <em>({__('pred odoslaním objednávky')})</em> {__('sme zistili, že')}{' '}
        <strong>
          {__(
            'niektoré produkty z Vašeho košíka sa vypredali kým ste dokončili objednávku',
          )}
        </strong>{' '}
        <em>
          (
          {__(
            'v momente vloženia do košika ešte boli dostupné, ale teraz už nie sú',
          )}
          )
        </em>
        .<br />
        {__('Z Vašeho košíka sme odstránili nasledovne produkty')}):
        {renderProducts()}
        {__(
          'Skontrolujte prosím obsah košíka po uvedených zmenách a dokončite objednávku znova',
        )}
        .
      </AlertStyled>
    );
  };

  const renderCartType = () => {
    return (
      <AlertStyled type="error">
        <AlertTitle>{__('Obsah kosika sa zmenil')}</AlertTitle>
        {__('Pri kontrole dostupnosti vybraného tovaru sme zistili, že')}{' '}
        <strong>{__('niektoré produkty z Vašeho košíka sa vypredali')}</strong>{' '}
        .<br />
        {__('Z Vašeho košíka sme odstránili nasledovne produkty')}:
        {renderProducts()}
        {__('Skontrolujte prosím obsah košíka po uvedených zmenách')}.
      </AlertStyled>
    );
  };

  const renderProducts = () => {
    return (
      <Products>
        {(problems &&
          problems.length &&
          problems.filter(isProblemTypeAvailability).map(renderProduct)) ||
          ''}
      </Products>
    );
  };

  const renderProduct = (problem, index) => {
    const {
      payload,
      payload: { productName },
    } = problem;
    return (
      <li key={index}>
        {`${productName} - `}
        <AlertHeadline>{getProductChangeText(payload)}</AlertHeadline>
      </li>
    );
  };

  const getProductChangeText = payload => {
    const { oldCount, newCount } = payload;
    return newCount > 0
      ? `${__(
          'nie je skladom v pozadovanom mnozstve, mnozstvo sme zmenili z',
        )} ${oldCount} ${__('ks')} ${__('na')} ${newCount} ${__('ks')}`
      : __('nie je skladom');
  };

  const render = () => {
    if (!hasCartAvailabilityProblems(cart)) {
      return <></>;
    }

    switch (type) {
      case AlertAvailabilityType.CART:
        return renderCartType();
      case AlertAvailabilityType.ORDER:
        return renderOrderType();
      default:
        return renderCartType();
    }
  };

  return render();
};

export default AlertAvailability;
