import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import { Store } from '../../../constants';
import { isOutOfStock, prop } from '../../../utilities';

const Wrapper = styled.div`
  display: block;
  position: relative;
  font-size: ${rem(12)};
  margin: 0 0 ${rem(20)} 0;
  padding: ${rem(10)} ${rem(15)};

  &.on-stock {
    background: ${({ theme }) => theme.color.available};
  }

  &.not-available {
    background: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.white};
  }

  ${({ theme }) => theme.media(1024)} {
    font-size: ${rem(14)};
  }
`;
/*
const Icon = styled.div`
  position: absolute;
  top: ${rem(10)};
  right: ${rem(10)};
  width: ${rem(30)};
  height: ${rem(30)};
  line-height: ${rem(30)};
  font-weight: 800;
  text-align: center;
  color: ${({ theme }) => theme.color.black};
  background: ${({ theme }) => theme.color.white};
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
`;
*/

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

interface AvailabilityProps {
  dispatch: any;
  className?: string;
  productDetail: any;
}

const AvailabilityDetail = (props: AvailabilityProps) => {
  const { className, productDetail } = props;

  let availabilityClassName = 'on-stock';
  let isAvailable = true;
  if (isOutOfStock(productDetail)) {
    availabilityClassName = 'not-available';
    isAvailable = false;
  }
  const onstock = prop(productDetail, 'main_good.onstock', []);
  const onstoreChemat = onstock
    .filter((o) => o.storeId === Store.CHEMAT)
    .shift();
  const onstoreLamitec = onstock
    .filter((o) => o.storeId === Store.LAMITEC)
    .shift();
  const chematStockQty = prop(onstoreChemat, 'storeQty', 0);
  const lamitecStockQty = prop(onstoreLamitec, 'storeQty', 0);

  /*
  const handleIconClick = () => {
    dispatch(setInfoMultipleStoresShow(true));
  };
  */

  return (
    <Wrapper className={`${className} ${availabilityClassName}`}>
      {isAvailable ? (
        <>
          <strong>{__('Tovar je skladom.')}</strong> {__('Dodanie 2 - 5 dní')}
          {/*
          <Icon onClick={handleIconClick}>?</Icon>
          */}
          <Ul>
            {chematStockQty ? (
              <li>
                {__('Sklad Prievidza')}:{' '}
                {chematStockQty > 1000 ? (
                  <strong>{`${__('viac ako')} 1000 ${__('ks')}`}</strong>
                ) : (
                  <strong>{`${chematStockQty} ${__('ks')}`}</strong>
                )}
              </li>
            ) : (
              ''
            )}
            {lamitecStockQty ? (
              <li>
                {__('Sklad Bratislava')}:{' '}
                {chematStockQty > 1000 ? (
                  <strong>{`${__('viac ako')} 1000 ${__('ks')}`}</strong>
                ) : (
                  <strong>{`${lamitecStockQty} ${__('ks')}`}</strong>
                )}
              </li>
            ) : (
              ''
            )}
          </Ul>
        </>
      ) : (
        <>
          <strong>{__('Tovar nie je skladom.')}</strong>
          <br />
          {__('Tento produkt momentálne nie je možné objednať.')}
        </>
      )}
    </Wrapper>
  );
};

export default AvailabilityDetail;
