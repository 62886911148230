import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { Container } from '../../theme/libraries/grid';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from 'react-i18n/lib';
import { Content } from '../../components/_layout/Page/ContentContainer';
import { InputStyled } from '../../components/_helpers/form/Input';
import { Heading2 } from '../../components/_helpers/Heading/Heading';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { Buttons, FormWrapper } from './Login';
import { ContentContainerBlock } from '../../components/_helpers/Contact/ContactStyles';
import { Form, Field } from 'react-final-form';
import API from '../../services/API';
import Alert from '../../components/Alert/Alert';
import {
  composeValidators,
  password,
  required,
} from '../../utilities/validators';
import { Loader } from '../../components/_helpers/Loader/Loader';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';

const Wrapper = styled(Content)`
  margin-bottom: ${rem(20)};
  ${({ theme }) => theme.media('md')} {
    margin-bottom: ${rem(40)};
  }
`;

export const Heading = styled(Heading2)`
  margin-top: 0;
  margin-bottom: ${rem(40)};
`;

const PasswordInput = styled(InputStyled)`
  display: block;
  width: 100%;
  margin-bottom: ${rem(10)};
`;

const AlertWrapper = styled.div`
  margin-top: ${rem(-10)};
  margin-bottom: ${rem(10)};
`;

interface ForgottenPasswordProps {
  params: {
    token: string;
  };
}

const ForgottenPassword = (props: ForgottenPasswordProps) => {
  const [isFething, setIsFetching] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [changeError, setChangeError] = React.useState('');

  const onSubmit = async (values) => {
    setIsFetching(true);

    try {
      await API.newPassword(
        {},
        { new_password: values.password },
        { xLostPasswordToken: props.params.token },
      );

      setSuccess(true);
    } catch (exp) {
      if (exp.details) {
        if (exp.details.message) {
          setChangeError(exp.details.message);
        } else if (exp.details.description) {
          setChangeError(exp.details.description);
        }
      }
    }

    setIsFetching(false);
  };

  return (
    <Container className="base">
      <Wrapper>
        <ContentContainerBlock>
          <Heading>{__('Zadajte nové heslo')}</Heading>

          {changeError && (
            <AlertWrapper>
              <Alert type="error">
                <div dangerouslySetInnerHTML={{ __html: changeError }} />
              </Alert>
            </AlertWrapper>
          )}

          {success && (
            <AlertWrapper>
              <Alert type="success">{__('Heslo bolo úspešne zmenené')}</Alert>
            </AlertWrapper>
          )}

          <Form
            onSubmit={onSubmit}
            // tslint:disable-next-line:jsx-no-lambda
            render={({ handleSubmit }) => (
              <Loader loading={isFething}>
                <form onSubmit={handleSubmit}>
                  <Field
                    name="password"
                    validate={composeValidators(required, password)}
                  >
                    {({ input, meta }) => (
                      <FormWrapper>
                        <PasswordInput
                          {...input}
                          type="password"
                          placeholder={__('Heslo')}
                          className="lg"
                        />
                        {meta.error && meta.touched && (
                          <ErrorPopup>{meta.error}</ErrorPopup>
                        )}
                      </FormWrapper>
                    )}
                  </Field>

                  <Buttons>
                    <ButtonStyled className="primary lg" type="submit">
                      {__('Potvrdiť')}
                    </ButtonStyled>
                  </Buttons>
                </form>
              </Loader>
            )}
          />
        </ContentContainerBlock>
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = (state, actions) => {
  return {
    push: actions.router.push,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ForgottenPassword' })(ForgottenPassword),
);
