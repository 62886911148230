import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { BlockMarginStyles } from '../../components/_helpers/Block/Block';
import { ProductVariantSelect } from '../../components/Product/Product';
import * as cookie from 'react-cookies';
import { Popup } from '../../components/_helpers/Popup/Popup';
import InputSpinner from '../../components/_helpers/form/InputSpinner';
import {
  Footer,
  NextButton,
  PriceCart,
  TotalPriceCart,
  TotalPriceText,
} from '../../components/Cart/Cart1';

import {
  AmountCol,
  CartProductListWrapperStyles,
  Header,
  HeaderCol,
  HeaderColProduct,
  HeaderRow,
  Info,
  InfoCol,
  Item,
  List,
  Name,
  Price,
  PriceCol,
  Thumb,
  TotalPrice,
  TotalPriceCol,
  ThumbWrapperPreview,
  PreviewIcon,
  TotalPriceNewTitle,
  TotalPriceOld,
  TotalPriceNew,
  TotalPriceDiscountTitle,
} from '../../components/Cart/CartProductList';
import { useState } from 'react';

import {
  formatPrice,
  getDiscountPrice,
  getProductPrices,
  isDiscountInCart,
  itemIsSkvelkoAndGoodPriceEnabled,
} from '../../utilities';

import { setCurrentProductForPreview } from '../App/actions';
import { __ } from 'react-i18n';
import { ButtonIcon } from '../../components/_helpers/form/Button';

const Title = styled.p`
  font-size: ${rem(24)};
  font-weight: 800;
`;

const Wrapper = styled.div`
  ${BlockMarginStyles};
  ${CartProductListWrapperStyles};
`;

const AmountColWrapper = styled.div`
  position: relative;
`;

const NoPad = css`
  ${({ theme }) => theme.media('md')} {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
  }
`;

const StyledTotalPriceCol = styled(TotalPriceCol)`
  ${NoPad}
`;

const StyledFooter = styled(Footer)`
  ${({ theme }) => theme.media('sm')} {
    justify-content: flex-end;
  }
`;

const StyledButtonIcon = styled(ButtonIcon)`
  fill: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.size(24)};
  margin-right: ${rem(10)};
`;

const ItemStyled = styled(Item)`
  ${({ theme }) => theme.media('md')} {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
    &:not(:last-child) {
      border-bottom: ${rem(2)} solid ${({ theme }) => theme.color.background};
    }
  }
`;

const InfoColStyled = styled(InfoCol)`
  ${NoPad}
`;

const PriceColStyled = styled(PriceCol)`
  ${NoPad}
`;

const AmountColStyled = styled(AmountCol)`
  ${NoPad}
`;

const ListStyled = styled(List)`
  box-shadow: ${({ theme }) => theme.boxShadow.small};
`;

export interface SetsListProps {
  user: any;
  dispatch: any;
  currentFilterTitle: string;
  cart: any;
  setPopupText?: {
    name: string;
    body: string;
  };
  onItemChange: any;
  onVariationChange: any;
  addSetsToCartFromButton: () => void;
  items: any[];
  settings: any;
}

const SetsList = (props: SetsListProps) => {
  const [showPopup, setShowPopup] = useState(true);

  const {
    dispatch,
    items,
    cart,
    user,
    currentFilterTitle,
    onVariationChange,
    onItemChange,
    addSetsToCartFromButton,
    setPopupText,
    settings,
  } = props;

  const showPreview = (product) => {
    dispatch(setCurrentProductForPreview(product));
  };

  const placeholder = '/images/placeholder-100.png';
  const placeholder2x = '/images/placeholder-100@2x.png';

  const canShowPopup = () => {
    const cookieShowPopup = cookie.load('setPopupShow');
    return (
      (cookieShowPopup !== undefined && cookieShowPopup !== 'false') ||
      showPopup
    );
  };

  const hidePopup = () => {
    cookie.save('setPopupShow', false, {
      path: '/',
      maxAge: 60 * 60 * 24 * 14, // 14 days
    });
    setShowPopup(false);
  };

  const calculateFinalPrice = () => {
    let finalPrice = 0;
    items.forEach((item) => {
      const discountPrice = getDiscountPrice(cart, item.product, user);
      const isSale = !!item.product.main_good.oldprice;
      finalPrice +=
        discountPrice && !isSale
          ? item.count * discountPrice
          : item.count * item.product.main_good.final_price;
    });
    return finalPrice;
  };

  const sortFn = (a, b) => a?.product?.name.localeCompare(b?.product?.name);

  return (
    <>
      <Title className="filter-title"> {currentFilterTitle} </Title>
      <Wrapper>
        <Header>
          <HeaderRow>
            <HeaderColProduct>{__('Produkt')}</HeaderColProduct>
            <HeaderCol>{__('Cena')}</HeaderCol>
            <HeaderCol>{__('Množstvo')}</HeaderCol>
            <HeaderCol>{__('Cena spolu')}</HeaderCol>
          </HeaderRow>
        </Header>
        <ListStyled>
          {items.sort(sortFn).map((row, i) => {
            const count = row.count;
            const {
              currencyId,
              unitPrice,
              price,
              retailPrice,
              oldPrice,
              discountPrice,
              isSale,
              isCustomerPrice,
              isWarehouse,
            } = getProductPrices(
              row.product,
              row.product?.main_good,
              cart,
              user,
              count,
            );

            return (
              <React.Fragment key={i}>
                <ItemStyled className={row.isError ? 'error' : ''}>
                  <InfoColStyled>
                    <ThumbWrapperPreview
                      onClick={showPreview.bind(null, row.product)}
                    >
                      <Thumb
                        src={
                          row.product.picture !== null
                            ? row.product.picture
                            : ''
                        }
                        placeholder={placeholder}
                        retinaPlaceholder={placeholder2x}
                        w={100}
                        h={100}
                      />
                      <PreviewIcon icon="eye" />
                    </ThumbWrapperPreview>
                    <Info>
                      <Name>{row.product.name}</Name>
                      <ProductVariantSelect
                        product={row.product}
                        onChange={onVariationChange.bind(null, i)}
                        isError={Boolean(row.isError)}
                        attribsObject={row.attribObj ? row.attribObj : {}}
                      />
                    </Info>
                  </InfoColStyled>
                  <PriceColStyled>
                    <Price>{formatPrice(unitPrice, currencyId)}</Price>
                  </PriceColStyled>
                  {i === 0 && canShowPopup() ? (
                    <AmountColWrapper>
                      <Popup
                        content={setPopupText?.body || ''}
                        bottom={95}
                        bottomMobile={125}
                        onClose={hidePopup}
                      />
                      <AmountColStyled>
                        <InputSpinner
                          value={count}
                          onChange={onItemChange.bind(null, i)}
                          min={0}
                        />
                      </AmountColStyled>
                    </AmountColWrapper>
                  ) : (
                    <AmountColStyled>
                      <InputSpinner
                        value={count}
                        onChange={onItemChange.bind(null, i)}
                        min={0}
                      />
                    </AmountColStyled>
                  )}
                  <StyledTotalPriceCol>
                    {isCustomerPrice && retailPrice ? (
                      <div>
                        <TotalPriceDiscountTitle>
                          {__('Cena pre vás')}
                        </TotalPriceDiscountTitle>
                        <TotalPriceOld>
                          {formatPrice(retailPrice, currencyId)}
                        </TotalPriceOld>
                        <TotalPriceNew>
                          {formatPrice(price, currencyId)}
                        </TotalPriceNew>
                      </div>
                    ) : isSale && oldPrice ? (
                      <div>
                        {itemIsSkvelkoAndGoodPriceEnabled(row, settings) ? (
                          <TotalPriceNewTitle>
                            {__('Dobrá cena')}
                          </TotalPriceNewTitle>
                        ) : (
                          <TotalPriceNewTitle>{__('Akcia')}</TotalPriceNewTitle>
                        )}
                        <TotalPriceOld>
                          {formatPrice(oldPrice, currencyId)}
                        </TotalPriceOld>
                        <TotalPriceNew>
                          {formatPrice(price, currencyId)}
                        </TotalPriceNew>
                      </div>
                    ) : isDiscountInCart(cart, user) && discountPrice ? (
                      <div>
                        <TotalPriceDiscountTitle>
                          {__('Zľava')}
                        </TotalPriceDiscountTitle>
                        <TotalPriceOld>
                          {formatPrice(price, currencyId)}
                        </TotalPriceOld>
                        <TotalPriceNew>
                          {formatPrice(discountPrice, currencyId)}
                        </TotalPriceNew>
                      </div>
                    ) : isWarehouse && retailPrice ? (
                      <div>
                        <TotalPriceDiscountTitle>
                          {__('Zľava')}
                        </TotalPriceDiscountTitle>
                        <TotalPriceOld>
                          {formatPrice(retailPrice, currencyId)}
                        </TotalPriceOld>
                        <TotalPriceNew>
                          {formatPrice(price, currencyId)}
                        </TotalPriceNew>
                      </div>
                    ) : (
                      <TotalPrice>{formatPrice(price, currencyId)}</TotalPrice>
                    )}
                  </StyledTotalPriceCol>
                </ItemStyled>
              </React.Fragment>
            );
          })}
        </ListStyled>
      </Wrapper>
      <TotalPriceCart>
        <TotalPriceText>{__('Cena spolu')}</TotalPriceText>
        <PriceCart>{formatPrice(calculateFinalPrice(), 'EUR')}</PriceCart>
      </TotalPriceCart>
      <StyledFooter>
        <NextButton className="primary lg" onClick={addSetsToCartFromButton}>
          <StyledButtonIcon icon="cart" />
          {__('Vložiť zoznam do košíka')}
        </NextButton>
      </StyledFooter>
    </>
  );
};

export default SetsList;
