import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { __ } from 'react-i18n/lib';
import { ContainerIndent } from '../../components/_layout/Page/ContentContainer';
import {
  TITLE_SEARCH_ESHOP,
  TITLE_SEARCH_SHOOL_ESHOP,
  TITLE_SEARCH_WEB,
} from '../../constants';
import { theme } from '../../theme/theme';
import { WithRouterProps } from 'react-router';
import Tabs from '../../components/Tabs/Tabs';
import FoundedQueries from '../../components/_layout/Header/SearchResult/FoundedQueries';
import { prop, removeObjectFromArray } from '../../utilities';
import { MAX_FOUNDED_QUERIES } from '../../components/_layout/Header/SearchResult/constants';
import styled, { createGlobalStyle } from 'styled-components';
import SearchEshop from './SearchEshop';
import { rem } from 'polished';
import SearchWeb from './SearchWeb';
import { loadSearchedResults, setSearchInitialLoaded } from '../App/actions';
import SearchSchoolEshop from './SearchSchoolEshop';
import { push } from 'react-router-redux';
import { forceCheck } from 'react-lazyload';
import { Helmet } from 'react-helmet';
import * as cookie from 'react-cookies';
import { USER_COOKIE } from '../App/constants';
import { loginUser } from '../Auth/action';

const Title = styled.h1`
  margin: 0;
  font-weight: 800;
  font-size: ${rem(24)};
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(32)};
  }
`;

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${theme.color.white};
    ${({ theme }) => theme.media('md')} {
      background-color: ${theme.color.background};
    }
  }
`;

interface SearchProps {
  dispatch: any;
  searchedResults: any;
  searchedResultsLoader: boolean;
  searchInitialLoaded: boolean;
}

const Search = (props: SearchProps & WithRouterProps) => {
  const {
    dispatch,
    location,
    searchedResults,
    searchedResultsLoader,
    searchedResults: {
      categories,
      demandCategories,
      webContent,
      products,
      demandProducts,
      products: { founded_queries },
    },
  } = props;

  const searchEshopRef: any = React.useRef();
  const searchWebRef: any = React.useRef();
  const [activeTab, setActiveTab] = React.useState(
    Number(props?.location?.query?.section) || 0,
  );

  const [searchParamsForSection, setSearchParamsForSection] = React.useState<
    {
      type: any;
      searchParams: {
        key: string;
        value: string;
      }[];
    }[]
  >([]);

  React.useEffect(() => {
    if (window && window.location && window.location.href) {
      var url = new URL(window.location.href);
      const section = url.searchParams.get('section');
      setActiveTab(Number(section) || 0);
      forceCheck();
    }
  }, [window?.location?.href]);

  const productCount = products?.total ?? 0;
  const demandProductCount = demandProducts?.total ?? 0;
  const categoryCount = categories?.total ?? 0;
  const demandCategoryCount = demandCategories?.total ?? 0;
  const webContentCount = webContent?.total ?? 0;

  const productsFoundedQueries =
    founded_queries?.length && founded_queries?.slice(0, MAX_FOUNDED_QUERIES);

  const demandProductsFoundedQueries =
    demandProducts?.founded_queries?.length &&
    demandProducts?.founded_queries?.slice(0, MAX_FOUNDED_QUERIES);

  const searchedQuery = location?.query?.q || '';

  const onTabChange = (id) => {
    if (window?.location?.href) {
      var href = new URL(window.location.href);

      var searchParamsArray: any[] = [];
      href.searchParams.forEach(function (value, key) {
        searchParamsArray.push({ key: key, value: value });
      });
      removeObjectFromArray(searchParamsForSection, 'type', activeTab);
      searchParamsForSection.push({
        type: activeTab,
        searchParams: searchParamsArray,
      });

      setActiveTab(id);
      setSearchParamsForSection(searchParamsForSection);

      const nextSearchParamsForSection = searchParamsForSection.find(
        (item) => item.type === id,
      );
      if (nextSearchParamsForSection) {
        for (const searchParam of nextSearchParamsForSection.searchParams) {
          href.searchParams.set(searchParam.key, searchParam.value);
        }
        href.searchParams.set('section', id);
      } else {
        href.searchParams.set('page', '1');
        href.searchParams.set('section', id);
      }

      dispatch(push(href.toString()));
      forceCheck();
    }
  };

  return (
    <ContainerIndent className="base">
      <Helmet>
        <title> {__('Vyhľadávanie')} </title>
      </Helmet>
      <GlobalStyle />
      <Title>{__('Vyhľadávanie')}</Title>

      {activeTab == 0 ? (
        <FoundedQueries
          searchedResultsLoader={false}
          searchedQuery={searchedQuery}
          foundedQueries={productsFoundedQueries}
          showFoundQuery={true}
        />
      ) : (
        <></>
      )}

      {activeTab == 2 ? (
        <FoundedQueries
          searchedResultsLoader={false}
          searchedQuery={searchedQuery}
          foundedQueries={demandProductsFoundedQueries}
          showFoundQuery={true}
        />
      ) : (
        <></>
      )}

      <Tabs
        items={[
          {
            name: `${TITLE_SEARCH_ESHOP} (${productCount + categoryCount})`,
            body: (
              <SearchEshop
                ref={searchEshopRef}
                dispatch={dispatch}
                location={location}
                searchedQuery={searchedQuery}
                searchedResults={searchedResults}
                searchedResultsLoader={searchedResultsLoader}
              />
            ),
          },
          {
            name: `${TITLE_SEARCH_WEB} (${webContentCount})`,
            body: (
              <SearchWeb
                ref={searchWebRef}
                dispatch={dispatch}
                location={location}
                searchedResults={searchedResults}
                searchedResultsLoader={searchedResultsLoader}
              />
            ),
          },
          {
            name: `${TITLE_SEARCH_SHOOL_ESHOP} (${
              demandProductCount + demandCategoryCount
            })`,
            body: (
              <SearchSchoolEshop
                ref={searchEshopRef}
                dispatch={dispatch}
                location={location}
                searchedQuery={searchedQuery}
                searchedResults={searchedResults}
                searchedResultsLoader={searchedResultsLoader}
              />
            ),
          },
        ].map((p, index) => {
          return { ...p, id: index, className: 'normal' };
        })}
        activeId={activeTab}
        onTabChange={onTabChange}
        selectedBackgroundColor={theme.color.secondary}
        selectedTextColor={theme.color.black}
      />
    </ContainerIndent>
  );
};

Search.getInitialProps = async (props) => {
  const promises: any[] = [];

  if (!props.searchInitialLoaded) {
    if (cookie.load(USER_COOKIE)) {
      await props.dispatch(loginUser(cookie.load(USER_COOKIE)));
    }

    promises.push(
      props.dispatch(
        loadSearchedResults({ q: props?.location?.query?.q || '' }),
      ),
    );
    promises.push(props.dispatch(setSearchInitialLoaded(true)));
  }

  await Promise.all(promises);

  return props;
};

const mapStateToProps = (state) => {
  return {
    searchedResults: prop(state.general, 'searchedResults'),
    searchedResultsLoader: prop(state.general, 'searchedResultsLoader'),
    searchInitialLoaded: prop(state.general, 'searchInitialLoaded'),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'Search' })(Search),
);
