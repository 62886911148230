import * as React from 'react';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import { loadUserDetail } from './actions';
import { ChematCustomerType } from '../../library/ChematCustomerType';
import { push } from 'react-router-redux';
import API, { ThenArg } from '../../services/API';
import { prop } from '../../utilities';
import MetaTags from '../../components/MetaTags/MetaTags';
import Tabs from '../../components/_helpers/Tabs/Tabs';
import {
  TITLE_SKVELKOBOX,
  TITLE_SKVELKOBOX_BENEFITS,
  TITLE_SKVELKOBOX_INSTRUCTIONS,
  URL_SKVELKOBOX_BENEFITS,
  URL_SKVELKOBOX_INSTRUCTIONS,
} from '../../constants';
import { theme } from '../../theme/theme';
import styled from 'styled-components';
import { rem } from 'polished';
import { WithRouterProps } from 'react-router';

const Container = styled.div``;

const ChildrenWrapper = styled.div`
  margin-top: ${rem(20)};
`;

interface SkvelkoBoxParentProps {
  dispatch: (a: any) => void;
  user: any;
  children: any;
  setTitle: any;
  userDetail?: ThenArg<typeof API.getCustomerDetails>;
}

class SkvelkoBoxParent extends React.PureComponent<
  SkvelkoBoxParentProps & WithRouterProps
> {
  public static async getInitialProps(props) {
    if (
      props.user?.skvelko_type !== ChematCustomerType.SCHOOL &&
      props.user?.skvelko_type !== ChematCustomerType.SCHOOL_PERSON
    ) {
      props.dispatch(push('/my-account/my-orders'));
      return;
    }

    if (!props.userDetail) {
      await props.dispatch(loadUserDetail(props.user.id));
    }
  }

  constructor(props) {
    super(props);
    props.setTitle(TITLE_SKVELKOBOX);
  }

  public render() {
    const currentPath = prop(this, 'props.router.location.pathname', '/');
    const { dispatch, children } = this.props;
    return (
      <Container>
        <MetaTags tags={{ title: `Daffer.sk` }} />
        <Tabs
          tabs={[
            {
              name: TITLE_SKVELKOBOX_BENEFITS,
              url: URL_SKVELKOBOX_BENEFITS,
            },
            {
              name: TITLE_SKVELKOBOX_INSTRUCTIONS,
              url: URL_SKVELKOBOX_INSTRUCTIONS,
            },
          ]}
          currentPath={currentPath}
          dispatch={dispatch}
          selectedBackgroundColor={theme.color.secondary}
          selectedTextColor={theme.color.black}
        />
        <ChildrenWrapper>
          {React.cloneElement(children, { ...this.props })}
        </ChildrenWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userDetail: state.myAccount.userDetail,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SkvelkoBoxParent' })(SkvelkoBoxParent),
);
