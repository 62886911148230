import * as actionTypes from './constants';

const initialState = {};

export const helpAndSupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FAQ:
      return {
        ...state,
        faq: action.payload.faq,
      };
    default:
      return state;
  }
};
