export interface Ga4Item {
  item_id: string;
  item_name: string;
  quantity?: number;
  price?: number;
}

export interface Ga4ViewEvent {
  value: number;
  items: Ga4Item[];
}

export interface Ga4PurchaseEvent {
  transaction_id: string;
  value: number;
  shipping: number;
  items: Ga4Item[];
}

export interface Ga4AddToCartEvent {
  value: number;
  items: Ga4Item[];
}

export const sendViewItemEvent = (ga4ViewEvent: Ga4ViewEvent) => {
  if (!window) return;
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      currency: 'EUR',
      value: ga4ViewEvent.value,
      items: ga4ViewEvent.items.map((item) => {
        return {
          item_id: item.item_id,
          item_name: item.item_name,
        };
      }),
    },
  });
};

export const sendPurchaseEvent = (data: Ga4PurchaseEvent) => {
  if (!window) return;
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: data.transaction_id,
      value: data.value,
      shipping: data.shipping,
      currency: 'EUR',
      items: data.items.map((item) => {
        return {
          item_id: item.item_id,
          item_name: item.item_name,
          quantity: item.quantity,
          price: item.price,
        };
      }),
    },
  });
};

export const sendAddToCartEvent = (data: Ga4AddToCartEvent) => {
  if (!window) return;
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      value: data.value,
      items: data.items.map((item) => {
        return {
          item_id: item.item_id,
          item_name: item.item_name,
          quantity: item.quantity,
          price: item.price,
        };
      }),
    },
  });
};
