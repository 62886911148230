import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import Filter from '../../components/_layout/Homepage/Filter';
import {
  getGiftSetsDesktopBannerFromSettings,
  getGiftWebContentIdFromSettings,
  getSetUrl,
  isCartGiftsValid,
  prop,
} from '../../utilities';
import {
  loadSkvelkoCities,
  loadSkvelkoClassesBySchool,
  loadSkvelkoSchoolsByCity,
  loadSkvelkoSet,
  loadSkvelkoSetsBySchool,
  resetSkvelko,
  setCurrentClassId,
  setCurrentFilterTitle,
  setCurrentSet,
  setSkvelkoClassesBySchools,
  setSkvelkoLoader,
  setSkvelkoSetsBySchools,
} from '../Home/actions';
import { Media, MediaContextProvider } from '../../theme/media';
import styled from 'styled-components';
import { rem } from 'polished';
import GiftsBanner from '../../components/_helpers/Sets/GiftsBanner';
import GiftsBannerMobile from '../../components/_helpers/Sets/GiftsBannerMobile';
import { setArticleModal, setShowPopupSetSelect2 } from '../App/actions';
import { push, replace } from 'react-router-redux';

interface SkvelkoSearchBoxProps {
  user: any;
  dispatch: any;
  params?: {
    id?: string;
  };
  skvelkoIsLoading: boolean;
  skvelkoSetsIsLoading: boolean;
  skvelkoCities: any;
  skvelkoSchools: any;
  skvelkoClasses: any;
  skvelkoSets: any;
  skvelkoSetsIsEmpty: boolean;
  skvelkoClassesIsEmpty: boolean;
  settings: any;
  redirectOnSearch: boolean;
  currentSet: any;
  currentPath: any;
  skvelkoSet: any;
}

const SkvelkoSearchBox = (props: SkvelkoSearchBoxProps) => {
  const filterOnClassChange = (id) => {
    props.dispatch(setCurrentClassId(id));
  };

  const filterOnCityChange = (city) => {
    props.dispatch(setSkvelkoSetsBySchools([]));
    props.dispatch(setSkvelkoClassesBySchools([]));

    props.dispatch(loadSkvelkoSchoolsByCity(city));
  };

  const filterOnSchoolChange = (schoolId) => {
    props.dispatch(loadSkvelkoSetsBySchool(schoolId));
    props.dispatch(loadSkvelkoClassesBySchool(schoolId));
    props.dispatch(setCurrentClassId(null));
  };

  const onSearch = async (setId, classId, schoolName, cityName, setName) => {
    if (!props.redirectOnSearch) {
      props.dispatch(setSkvelkoLoader(true));
      props.dispatch(setCurrentFilterTitle(schoolName, cityName, setName));
      await props.dispatch(loadSkvelkoSet(setId));
      props.skvelkoSets.forEach((set) => {
        if (set.value === parseInt(setId, 10)) {
          props.dispatch(setCurrentSet({ id: setId, name: set.name }));
        }
      });
      props.dispatch(setSkvelkoLoader(false));
      const url = getSetUrl(setId);
      props.dispatch(replace(url));
      props.dispatch(setShowPopupSetSelect2(false));
    } else {
      props.dispatch(setCurrentSet(null));
      props.dispatch(resetSkvelko());
      props.dispatch(push(`/sada/${setId}`));
    }
  };

  const onGiftOpen = async () => {
    props.dispatch(
      setArticleModal(getGiftWebContentIdFromSettings(props.settings)),
    );
  };

  return (
    <MediaContextProvider>
      <Media greaterThanOrEqual="lg">
        <FilterWrapper>
          <Filter
            params={props.params}
            isLoading={props.skvelkoIsLoading}
            cities={props.skvelkoCities}
            schools={props.skvelkoSchools}
            classes={props.skvelkoClasses}
            sets={props.skvelkoSets}
            skvelkoSetsIsEmpty={props.skvelkoSetsIsEmpty}
            skvelkoClassesIsEmpty={props.skvelkoClassesIsEmpty}
            onCityChange={filterOnCityChange}
            onSchoolChange={filterOnSchoolChange}
            onClassChange={filterOnClassChange}
            onSearch={onSearch}
          />
          {isCartGiftsValid(props.settings, props.user) ? (
            <GiftsBanner
              bannerUrl={getGiftSetsDesktopBannerFromSettings(props.settings)}
              onLinkClick={onGiftOpen}
            />
          ) : (
            <></>
          )}
        </FilterWrapper>
      </Media>
      <Media lessThan="lg">
        {isCartGiftsValid(props.settings, props.user) ? (
          <GiftsBannerMobile onLinkClick={onGiftOpen} />
        ) : (
          <></>
        )}

        <Filter
          params={props.params}
          isLoading={props.skvelkoIsLoading}
          cities={props.skvelkoCities}
          schools={props.skvelkoSchools}
          classes={props.skvelkoClasses}
          sets={props.skvelkoSets}
          skvelkoSetsIsEmpty={props.skvelkoSetsIsEmpty}
          skvelkoClassesIsEmpty={props.skvelkoClassesIsEmpty}
          onCityChange={filterOnCityChange}
          onSchoolChange={filterOnSchoolChange}
          onClassChange={filterOnClassChange}
          onSearch={onSearch}
        />
      </Media>
    </MediaContextProvider>
  );
};

SkvelkoSearchBox.getInitialProps = async (props) => {
  const promises: any[] = [];
  if (!props.skvelkoCities || !props.skvelkoCities.length) {
    promises.push(props.dispatch(loadSkvelkoCities()));
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    skvelkoIsLoading: prop(state.home, 'skvelkoLoader', true),
    skvelkoSetsIsLoading: prop(state.home, 'skvelkoSetsLoader', false),
    skvelkoCities: prop(state.home, 'skvelkoCities', []),
    skvelkoSchools: prop(state.home, 'skvelkoSchools', []),
    skvelkoSets: prop(state.home, 'skvelkoSets', []),
    skvelkoClasses: prop(state.home, 'skvelkoClasses', []),
    skvelkoSetsIsEmpty: state.home.skvelkoSets
      ? !state.home.skvelkoSets.length
      : false,
    skvelkoClassesIsEmpty: state.home.skvelkoClasses
      ? !state.home.skvelkoClasses.length
      : false,
    settings: state.general.settings,
    currentSet: state.home.currentSet,
    skvelkoSet: state.home.skvelkoSet,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SkvelkoSearchBox' })(SkvelkoSearchBox),
);

const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 ${rem(5)};
`;
