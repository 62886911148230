import * as React from 'react';
import styled, { css } from 'styled-components';
import { __ } from 'react-i18n';
import { InputStyled } from '../../components/_helpers/form/Input';
import { ButtonStyled } from '../../components/_helpers/form/Button';
import { Form, Field, FormSpy } from 'react-final-form';
import API from '../../services/API';
import Alert from '../../components/Alert/Alert';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import { required } from '../../utilities/validators';
import { scrollToError, scrollToTop } from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';

const Wrapper = styled.div`
  width: 100%;
`;
const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 10px;
  flex-direction: column;
`;

const SubmitButtonWrapper = styled.div`
  margin-top: 15px;
`;

const FormCol = styled.div`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
`;

const InputRow = styled.div`
  margin-bottom: 15px;
`;

const FormLabel = styled.div``;

const FormInputCss = css`
  width: 100%;
  ${(props: any) => {
    return props.error ? 'border:2px solid red;' : '';
  }}
`;

const FormInput = styled(InputStyled)`
  ${FormInputCss};
`;

const MediaFormInput = styled(FormInput)`
  padding-top: 7px;
  padding-bottom: 7px;
`;

const FormInputEmail = styled(EmailAutocomplete)`
  ${FormInputCss};
`;

const FormField = styled.form``;

/*
const EmptyField = styled.div`
  visibility: hidden;
  display: none;
  ${({ theme }) => theme.media('sm')} {
    display: block;
  }
`;
*/

const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media('sm')} {
    flex-direction: row;
  }
`;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const GiftCardForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(0);
  const [valueAttachment, setValueAttachment] = React.useState(null);
  const [valueAttachmentFile, setValueAttachmentFile] = React.useState<any>(
    null,
  );

  const onSubmit = async (values) => {
    const copyValues = JSON.parse(JSON.stringify(values));

    if (valueAttachmentFile) {
      copyValues.attachment = await toBase64(valueAttachmentFile);
      copyValues.attachmentName = valueAttachmentFile.name;
    } else {
      copyValues.attachment = null;
    }

    setLoading(true);
    try {
      await API.giftCardForm(
        {
          xAcceptLanguage: 'sk',
        },
        copyValues,
      );
      setStatus(1);
    } catch (e) {
      setStatus(-1);
    }
    setLoading(false);
    scrollToTop();
  };

  const changeAttachment = (e) => {
    if (
      e &&
      e.target &&
      e.target.value &&
      e.target.files &&
      e.target.files[0]
    ) {
      setValueAttachmentFile(e.target.files[0]);
      setValueAttachment(e.target.value);
    }
  };

  return (
    <Wrapper>
      <Loader loading={loading}>
        {status === 1 && (
          <Alert type="success"> {__('Žiadosť bola poslaná úspešne')} </Alert>
        )}

        {status === -1 && (
          <Alert type="error"> {__('Žiadosť sa nepodarilo odoslať')} </Alert>
        )}

        <Form
          onSubmit={onSubmit}
          // tslint:disable-next-line:jsx-no-lambda
          render={({ handleSubmit }) => (
            <FormField onSubmit={handleSubmit}>
              <FormWrapper>
                <Row>
                  <FormCol>
                    <Field name="school_name" validate={required}>
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Názov školy (povinné) </FormLabel>
                          <FormInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>
                  </FormCol>
                  <FormCol>
                    <Field name="email" validate={required}>
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Váš email (povinné) </FormLabel>
                          <FormInputEmail
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>
                  </FormCol>
                </Row>
                <Row>
                  <FormCol>
                    <Field name="phone" validate={required}>
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Telefón (povinné)</FormLabel>
                          <FormInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>
                  </FormCol>
                  <FormCol>
                    <Field name="attachment">
                      {({ input, meta, onChange }) => (
                        <>
                          <FormLabel> Príloha (napr. zoznam žiakov) </FormLabel>
                          <MediaFormInput
                            {...input}
                            id="attachment"
                            type="file"
                            name="attachment"
                            onChange={changeAttachment}
                            value={valueAttachment}
                          />
                        </>
                      )}
                    </Field>
                  </FormCol>
                </Row>
                <Row>
                  <FormCol>
                    <Field name="gift_cards_count">
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> Množstvo darčekových kariet </FormLabel>
                          <FormInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>
                  </FormCol>
                  <FormCol>
                    <Field name="gift_cards_price">
                      {({ input, meta }) => (
                        <InputRow
                          className={meta.error && meta.touched ? 'error' : ''}
                        >
                          <FormLabel> V hodnote </FormLabel>
                          <FormInput
                            {...input}
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <ErrorPopup>{meta.error}</ErrorPopup>
                          )}
                        </InputRow>
                      )}
                    </Field>
                  </FormCol>
                </Row>
              </FormWrapper>

              <SubmitButtonWrapper>
                <ButtonStyled className="primary lg" type="submit">
                  {__('Odoslať')}
                </ButtonStyled>
              </SubmitButtonWrapper>

              <FormSpy
                subscription={{ submitFailed: true, modified: false }}
                onChange={() => {
                  scrollToError();
                }}
              />
            </FormField>
          )}
        />
      </Loader>
    </Wrapper>
  );
};
