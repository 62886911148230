import * as actionTypes from './constants';

const initialState = {};

export const shopDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHOP_NETWORK_MENU:
      return {
        ...state,
        shopNetworkMenu: action.payload.shopNetworkMenu,
      };
    case actionTypes.SET_SHOP_DETAIL:
      return {
        ...state,
        shopDetail: action.payload.shopDetail,
      };
    default:
      return state;
  }
};
