import * as actionTypes from './constants';

const initialState = {
  hasCustomerCardDetect: true,
};

export const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_RETAIL_INTRO_LESS:
      return {
        ...state,
        retailIntroLess: action.payload.retailIntroLess,
      };
    case actionTypes.SET_RETAIL_INTRO_MORE:
      return {
        ...state,
        retailIntroMore: action.payload.retailIntroMore,
      };
    case actionTypes.SET_COMPANY_INTRO_LESS:
      return {
        ...state,
        companyIntroLess: action.payload.companyIntroLess,
      };
    case actionTypes.SET_COMPANY_INTRO_MORE:
      return {
        ...state,
        companyIntroMore: action.payload.companyIntroMore,
      };
    case actionTypes.SET_SCHOOL_INTRO_LESS:
      return {
        ...state,
        schoolIntroLess: action.payload.schoolIntroLess,
      };
    case actionTypes.SET_SCHOOL_INTRO_MORE:
      return {
        ...state,
        schoolIntroMore: action.payload.schoolIntroMore,
      };
    case actionTypes.SET_HAS_CUSTOMER_CARD_DETECT:
      return {
        ...state,
        hasCustomerCardDetect: action.payload.hasCustomerCardDetect,
      };
    case actionTypes.SET_FINISHED_TEXT:
      return {
        ...state,
        finishedText: action.payload.finishedText,
      };
    default:
      return state;
  }
};
