const namespace = 'Account';

export const SET_DASHBOARD = `${namespace}/SET_DASHBOARD`;
export const SET_SKVELKO_ADVANTAGE_TEXT = `${namespace}/SET_SKVELKO_ADVANTAGE_TEXT`;

export const SET_SET_LIST = `${namespace}/SET_SET_LIST`;
export const SET_SKVELKO_SET = `${namespace}/SET_SKVELKO_SET`;
export const SET_SKVELKO_SET_LIST_LOADER = `${namespace}/SET_SKVELKO_SET_LIST_LOADER`;

export const SET_PRODUCTS_FOR_SETS = `${namespace}/SET_PRODUCTS_FOR_SETS`;
export const SET_CATEGORIES_FOR_SETS = `${namespace}/SET_CATEGORIES_FOR_SETS`;
export const SET_PRODUCTS_FOR_SETS_LOADER = `${namespace}/SET_PRODUCTS_FOR_SETS_LOADER`;

export const SET_CLASS_LIST_LOADER = `${namespace}/SET_CLASS_LIST_LOADER`;
export const SET_CLASS_SEARCH = `${namespace}/SET_CLASS_SEARCH`;

export const SET_RENUMERATION_DATA = `${namespace}/SET_RENUMERATION_DATA`;
export const SET_RENUMERATION_LOADER = `${namespace}/SET_RENUMERATION_LOADER`;

export const SET_ORDERS_BY_SET_LOADER = `${namespace}/SET_ORDERS_BY_SET_LOADER`;
export const SET_ORDERS_BY_SET = `${namespace}/SET_ORDERS_BY_SET`;

export const SET_ORDERS_FOR_SET = `${namespace}/SET_ORDERS_FOR_SET`;

export const SET_MY_ORDERS_LOADER = `${namespace}/SET_MY_ORDERS_LOADER`;
export const SET_MY_ORDERS = `${namespace}/SET_MY_ORDERS`;

export const REQUEST_ORDER_DATA = `${namespace}/REQUEST_ORDER_DATA`;
export const RECEIVE_ORDER_DATA_SUCCESS = `${namespace}/RECEIVE_ORDER_DATA_SUCCESS`;
export const RECEIVE_ORDER_DATA_ERROR = `${namespace}/RECEIVE_ORDER_DATA_ERROR`;

export const SET_USER_DETAIL_LOADER = `${namespace}/SET_USER_DETAIL_LOADER`;
export const SET_USER_DETAIL = `${namespace}/SET_USER_DETAIL`;

export const SET_TOTAL_SET_PRODUCTS = `${namespace}/SET_TOTAL_SET_PRODUCTS`;

export const SET_LAST_BILLING_ADDRESS = `${namespace}/SET_LAST_BILLING_ADDRESS`;
export const SET_LAST_DELIVERY_ADDRESS = `${namespace}/SET_LAST_DELIVERY_ADDRESS`;

export const SET_USE_DELIVERY_ADDRESS = `${namespace}/SET_USE_DELIVERY_ADDRESS`;

export const SET_INFO_TEACHER_AVAILABILITY = `${namespace}/INFO_TEACHER_AVAILABILITY`;
export const SET_INFO_SAVE_INACCESSIBLE = `${namespace}/INFO_SAVE_INACCESSIBLE`;
export const SET_NOT_SAVED_CHANGES = `${namespace}/SET_NOT_SAVED_CHANGES`;
export const ALERT_EDIT_SET_MOBILE = `${namespace}/ALERT_EDIT_SET_MOBILE`;

export const SET_DELIVERY_SCHOOL_TEXT = `${namespace}/SET_DELIVERY_SCHOOL_TEXT`;
export const SET_HOW_TO_EARN_POINTS_TEXT = `${namespace}/SET_HOW_TO_EARN_POINTS_TEXT`;

export const SET_CUSTOMER_CARD = `${namespace}/SET_CUSTOMER_CARD`;

export const SET_NEW_SET_STATE = `${namespace}/SET_NEW_SET_STATE`;
export const SET_CREATING_NEW_SET = `${namespace}/SET_CREATING_NEW_SET`;

export const SET_MOST_FREQUENT_PURCHASED = `${namespace}/SET_MOST_FREQUENT_PURCHASED`;
export const SET_MOST_FREQUENT_PURCHASED_LOADER = `${namespace}/SET_MOST_FREQUENT_PURCHASED_LOADER`;

export const SET_FAVORITE_PRODUCTS_WIDGET = `${namespace}/SET_FAVORITE_PRODUCTS_WIDGET`;
export const SET_FAVORITE_PRODUCTS_WIDGET_LOADER = `${namespace}/SET_FAVORITE_PRODUCTS_WIDGET_LOADER`;

export const SET_PRODUCTS_CUSTOMER = `${namespace}/SET_PRODUCTS_CUSTOMER`;
export const SET_PRODUCTS_CUSTOMER_LOADER = `${namespace}/SET_PRODUCTS_CUSTOMER_LOADER`;

export const SET_CUSTOMER_PRODUCTS_TEXT = `${namespace}/SET_CUSTOMER_PRODUCTS_TEXT`;

export const SET_SET_BULK_ORDER_TEXT = `${namespace}/SET_SET_BULK_ORDER_TEXT`;

export const SET_THANKYOU_SKVELKOBOX_TEXT = `${namespace}/SET_THANKYOU_SKVELKOBOX_TEXT`;

export type SelectedProducts = Array<{ count: string; product: any }>;
