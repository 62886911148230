import * as React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';

const LabelStyles = css`
  display: inline-block;
  ${({ theme }) => theme.media('sm')} {
    font-size: ${({ theme }) => rem(theme.form.font.size)};
  }
  &[for] {
    cursor: pointer;
  }
`;

const LabelDefault = styled.label`
  ${LabelStyles};
  padding: ${({ theme }) =>
      rem(theme.form.border.width + theme.form.padding.horizontal)}
    0;
`;

interface Props {
  children: any;
  className?: string;
  htmlFor?: string;
}

export class Label extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { children, className, htmlFor } = this.props;
    return (
      <LabelDefault className={className} htmlFor={htmlFor}>
        {children}
      </LabelDefault>
    );
  }
}
