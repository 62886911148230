import * as React from 'react';
import styled, { StyledFunction, css } from 'styled-components';
import { rem } from 'polished';
import Icon from '../_helpers/Icon/Icon';

const WrapperFunc: StyledFunction<
  AlertType & React.HTMLAttributes<HTMLDivElement>
> = styled.div;

const AlertIcon = styled(Icon)`
  ${({ theme }) => theme.size(20)};
  position: absolute;
  top: ${(props) => (props.top ? props.top : rem(20))};
  left: ${rem(22)};
`;

const Wrapper = WrapperFunc`
  background-color: transparent;
  border: ${rem(1)} solid transparent;
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  padding: ${rem(20)} ${rem(20)} ${rem(20)} ${rem(54)};
  font-size: ${rem(14)};
  position: relative;

  &.separator {
    margin-bottom: ${rem(20)};
  }

  ${({ type }) => {
    switch (type) {
      case 'error': {
        return css`
          background-color: ${({ theme }) =>
            theme.color.alert.error.background};
          border-color: ${({ theme }) => theme.color.alert.error.border};
          ${AlertIcon} {
            fill: ${({ theme }) => theme.color.error};
          }
        `;
      }
      case 'info': {
        return css`
          background-color: ${({ theme }) => theme.color.alert.info.background};
          border-color: ${({ theme }) => theme.color.alert.info.border};
          ${AlertIcon} {
            fill: ${({ theme }) => theme.color.info};
          }
        `;
      }
      case 'success': {
        return css`
          background-color: ${({ theme }) =>
            theme.color.alert.success.background};
          border-color: ${({ theme }) => theme.color.alert.success.border};
          ${AlertIcon} {
            fill: ${({ theme }) => theme.color.success};
          }
        `;
      }
      default: {
        return css``;
      }
    }
  }}
`;

const Heading = styled.p`
  margin: 0 0 ${rem(10)} 0;
  ${(props) => (props.fontSize ? `font-size:${props.fontSize};` : '')};
  ${(props) => (props.fontColor ? `color:${props.fontColor};` : '')};
  ${(props) =>
    props.fontWeight
      ? `font-weight:${props.fontWeight};`
      : 'font-weight: 700;'};
`;

const Description = styled.div`
  margin: 0;
  p:last-child {
    margin-bottom: 0;
  }
`;

interface AlertType {
  type?: 'error' | 'info' | 'success';
}

interface AlertProps extends AlertType {
  className?: string;
  children: any;
  heading?: string;
  headingFontSize?: string;
  headingFontColor?: string;
  headingFontWeight?: string;
  alertIconTop?: string;
  icon?: string;
}

class Alert extends React.PureComponent<AlertProps> {
  public render() {
    const {
      className,
      children,
      heading,
      icon,
      type,
      headingFontSize,
      headingFontColor,
      headingFontWeight,
      alertIconTop,
    } = this.props;

    return (
      <Wrapper className={className} type={type ? type : 'info'}>
        <AlertIcon icon={icon ? icon : 'infoOutline'} top={alertIconTop} />
        {heading ? (
          <Heading
            fontSize={headingFontSize}
            fontColor={headingFontColor}
            fontWeight={headingFontWeight}
          >
            {heading}
          </Heading>
        ) : (
          ''
        )}
        <Description>{children}</Description>
      </Wrapper>
    );
  }
}

export default Alert;
