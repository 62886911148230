import { useRef, useEffect } from 'react';
import { isSsr } from '../../../utilities';

const useUnload = fn => {
  const cb = useRef(fn);

  useEffect(() => {
    if (isSsr()) {
      return;
    }
    const onUnload = cb.current;
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, [cb]);
};

export default useUnload;
