import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import * as React from 'react';
import { Container } from '../../theme/libraries/grid';
import { prop, getBaseUrl } from '../../utilities';

interface Props {
  text: any;
  urlMapData: any;
}

class NotFound extends React.Component<Props> {
  public static async getInitialProps(props) {
    return props;
  }

  public text = () => {
    return {
      __html:
        this.props.text &&
        this.props.text.content &&
        this.props.text.content.json_content.body,
    };
  };

  public componentDidMount() {
    const urlMapData = this.props.urlMapData;
    if (urlMapData && urlMapData.type === 'redirect') {
      if (urlMapData.data && urlMapData.data.new_url && window) {
        window.location.replace(getBaseUrl(urlMapData.data.new_url));
      }
    }
  }

  public render() {
    return (
      <Container className="base">
        <h1>{this.props.text && this.props.text.text_name}</h1>
        <div dangerouslySetInnerHTML={this.text()} />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    text: prop(state.general.otherTexts, 'PAGE_NOT_FOUND'),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'NotFound' })(NotFound),
);
