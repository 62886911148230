import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router';

const Wrapper = styled(Link)`
  display: block;
  width: ${rem(245)};
  height: ${rem(90)};
  margin-bottom: ${rem(10)};
  background: url('${(props) => props.background}') no-repeat;
`;

interface BannerProps {
  background: string;
  url: string;
}

const Banner = (props: BannerProps) => {
  const { background, url } = props;

  return <Wrapper background={background} to={url} />;
};

export default Banner;
