import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { ListItemUnstyled } from '../_helpers/typography/ListStyles';
import { ClearfixStyles } from '../../theme/libraries/h5b-helpers';
import { ButtonDefault, ButtonStyledStyles } from '../_helpers/form/Button';

const TabsItems = styled.ul`
  display: flex;
  list-style: none;
  margin: ${rem(0)} ${rem(-1)} 0;
  padding: 0;
  ${ClearfixStyles};
`;

const TabHr = styled.hr`
  margin: ${rem(-2)} 0 0 0;
  border: ${rem(1)} solid
    ${(props) =>
      props.selectedBackgroundColor
        ? props.selectedBackgroundColor
        : ({ theme }) => theme.color.primary}; ;
`;

const Item = styled(ListItemUnstyled)`
  color: inherit;
  &:hover {
    text-decoration: none;
  }
`;

export const Tab: any = styled.div`
  padding-top: ${rem(20)};
  display: ${(props: any) => (props.visible ? 'block' : 'none')};
`;

const CustomTab = styled(ButtonDefault)`
  ${ButtonStyledStyles};
  display: flex;
  border-color: ${({ theme }) => theme.color.gray93};
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${rem(8)};
  border-top-left-radius: ${rem(8)};
  font-weight: 700;
  font-size: ${rem(14)};
  padding: ${rem(6)} ${rem(4)};
  width: 100%;

  @media only screen and (max-width: 435px) {
    max-width: 130px;
    padding: 6px 6px;
    min-height: 65px;
    vertical-align: middle;
    text-align: center;
  }

  @media only screen and (max-width: 375px) {
    max-width: 120px;
    padding: 6px 6px;
    min-height: 65px;
  }

  @media only screen and (max-width: 350px) {
    max-width: 100px;
    padding: 6px 6px;
    font-size: 12px;
    min-height: 60px;
  }

  background-size: unset;
  background: ${({ theme }) => theme.color.gray93};
  &.primary {
    background: ${(props) =>
      props.selectedBackgroundColor
        ? props.selectedBackgroundColor
        : ({ theme }) => theme.color.primary};
    color: ${(props) =>
      props.selectedTextColor
        ? props.selectedTextColor
        : ({ theme }) => theme.color.white};
    &:not(:disabled):hover {
      background: ${(props) =>
        props.selectedBackgroundColor
          ? props.selectedBackgroundColor
          : ({ theme }) => theme.color.primary};
    }
  }
  &.normal {
    font-weight: normal;
  }
  &:focus {
    box-shadow: none;
  }
  padding: ${rem(8)} ${rem(12)};
  ${({ theme }) => theme.media('xs')} {
    padding: ${rem(12)} ${rem(6)};
  }
  ${({ theme }) => theme.media('md')} {
    font-size: ${rem(14)};
    padding: ${rem(12)} ${rem(24)};
  }
`;

const TabItemStyled = styled.div`
  padding: 0 ${rem(1)};
  &.primary {
    padding-top: ${rem(2)};
  }
  &:not(.primary) {
    padding-bottom: ${rem(2)};
  }
`;

export type TabItem = {
  name: string;
  id?: string | number;
  body?: any;
  className?: string;
};

interface TabsProps {
  items: TabItem[];
  activeId: string | number | null;
  onTabChange: (id) => void;
  selectedBackgroundColor?: any;
  selectedTextColor?: any;
  className?: string;
}

class Tabs extends React.PureComponent<TabsProps> {
  public render() {
    const {
      items,
      activeId,
      selectedBackgroundColor,
      selectedTextColor,
      className,
    } = this.props;

    const [activeItem] = items.filter((i) => i.id === activeId);

    return (
      <div className={className}>
        <TabsItems>
          {items.map((item, i) => {
            return (
              <Item key={i}>
                <TabItemStyled
                  className={activeId === item.id ? 'primary' : ''}
                >
                  <CustomTab
                    className={`${activeId === item.id ? 'primary' : ''} ${
                      item.className || ''
                    }`}
                    onClick={this.props.onTabChange.bind(this, item.id)}
                    selectedBackgroundColor={selectedBackgroundColor}
                    selectedTextColor={selectedTextColor}
                  >
                    {item.name}
                  </CustomTab>
                </TabItemStyled>
              </Item>
            );
          })}
        </TabsItems>
        <TabHr selectedBackgroundColor={selectedBackgroundColor} />
        {activeItem?.body || ''}
      </div>
    );
  }
}

export default Tabs;
