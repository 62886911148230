import { rem } from 'polished';
import { ButtonIcon } from '../../_helpers/form/Button';
import * as React from 'react';
import styled from 'styled-components';

const FooterLinkWrapper = styled.a`
  margin: ${rem(10)} ${rem(5)};
  line-height: 1;
  &.wide {
    padding-right: ${rem(25)};
    @media only screen and (max-width: 970px) {
      padding-right: 0px;
      margin-right: 24px;

      &:last-child {
        margin-right: 0px;
      }
    }

    @media only screen and (max-width: 350px) {
      padding-right: 0px;
      margin-right: 6px;
    }
  }
  span {
    text-decoration: none !important;
    font-size: ${rem(14)};
    @media only screen and (max-width: 350px) {
      font-size: ${rem(13)} !important;
    }
    font-weight: normal !important;
    display: inline-block;
    margin-left: ${rem(10)};
  }
`;

const FooterLinkText = styled.span`
  color: black;
`;

const FooterLinkIcon = styled(ButtonIcon)`
  fill: ${({ theme }) => theme.color.primary};
  ${({ theme }) => theme.transition('fill')};
  &.facebook {
    fill: ${({ theme }) => theme.color.social.facebook};
  }
  &.youtube {
    fill: ${({ theme }) => theme.color.social.youtube};
  }
  ${FooterLinkWrapper}:focus &,
  ${FooterLinkWrapper}:not(:disabled):hover & {
    fill: ${({ theme }) => theme.color.black};
  }
`;

interface FooterLinkProps {
  icon: string;
  iconColor?: string;
  url: string;
  text?: string;
}

class FooterLink extends React.PureComponent<FooterLinkProps> {
  public render() {
    const { icon, url, text } = this.props;
    return (
      <FooterLinkWrapper href={url} className={text ? 'wide' : ''}>
        <FooterLinkIcon icon={icon} className={this.asignColorClass(icon)} />
        {text ? <FooterLinkText>{text}</FooterLinkText> : ''}
      </FooterLinkWrapper>
    );
  }

  private asignColorClass = (color) => {
    switch (color) {
      case 'facebook':
        return 'facebook';
      case 'youtube':
        return 'youtube';
      default:
        return '';
    }
  };
}

export default FooterLink;
