import * as React from 'react';
import { __ } from 'react-i18n/lib';
import { Button2, StyledFooter } from './ChooseModal';
import BaseModal from './BaseModal';
import { rem } from 'polished';
import styled from 'styled-components';
import { formatPrice, getGiftCartRemain } from '../../../utilities';

const P = styled.div`
  display: inline-block;
  margin: 0;

  &.success {
    color: ${({ theme }) => theme.color.success};
  }

  &.error {
    color: ${({ theme }) => theme.color.error};
  }
`;

const StyledFooterStyled = styled(StyledFooter)`
  border-top: 0;
  padding-top: 0;
  justify-content: center !important;
`;

const StyledModal = styled(BaseModal)`
  width: ${rem(510)};
`;

interface ModalProps {
  cart: any;
  onCloseButtonClick?: (event) => void;
}

const GiftRemainModal = (props: ModalProps) => {
  const { cart, onCloseButtonClick } = props;

  const remain = getGiftCartRemain(cart);

  return (
    <StyledModal
      title={<strong>{__('Ako získať darček zadarmo?')}</strong>}
      body={
        <>
          {__('Nakúpte za')}{' '}
          <P className={'error'}>
            {__('ďaľších')} <strong>{formatPrice(remain, 'EUR')}</strong>
          </P>{' '}
          {__('a získate')}{' '}
          <P className={'success'}>
            <strong>{__('darček zadarmo.')}</strong>
          </P>{' '}
        </>
      }
      footer={
        <StyledFooterStyled>
          <Button2 className="primary lg" onClick={onCloseButtonClick}>
            {__('Ok')}
          </Button2>
        </StyledFooterStyled>
      }
      onCloseButtonClick={onCloseButtonClick}
    />
  );
};

export default GiftRemainModal;
