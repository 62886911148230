import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Icon from '../../_helpers/Icon/Icon';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: ${rem(30)} 0;
  ${({ theme }) => theme.transition('margin')};
  ${({ theme }) => theme.media(1024)} {
    margin-left: ${rem(150)};
    margin-right: ${rem(150)};
  }
`;

const Item = styled.div`
  display: flex;
  flex: 1 0 ${({ theme }) => theme.grid.col.col12};
  align-items: center;
  justify-content: center;
  margin: ${rem(10)} 0;
  ${({ theme }) => theme.media('sm')} {
    flex: 1 0 ${({ theme }) => theme.grid.col.col4};
  }
`;

const IconWrapper = styled.div``;

const HighlightIcon = styled(Icon)`
  ${({ theme }) => theme.size(46)};
`;

const Text = styled.div`
  padding-left: ${rem(20)};
  font-size: ${rem(18)};
  font-weight: 700;
  max-width: ${rem(180)};
`;

interface HighlightsProps {
  items: Array<{
    icon: string;
    text: string;
  }>;
}

class Highlights extends React.PureComponent<HighlightsProps> {
  public render() {
    const { items } = this.props;
    return (
      <Wrapper>
        {items.map((item, i) => {
          return (
            <Item key={i}>
              <IconWrapper>
                <HighlightIcon icon={item.icon} />
              </IconWrapper>
              <Text>{item.text}</Text>
            </Item>
          );
        })}
      </Wrapper>
    );
  }
}

export default Highlights;
