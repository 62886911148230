import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';
import { StyledFooter, Button2 } from './ChooseModal';
import { ProductVariantSelect } from '../../Product/Product';
import { addItemToCart } from '../../../containers/Cart/actions';
import {
  setAddToCartMaxItemExceededModalProduct,
  setAddToCartModalProduct,
} from '../../../containers/App/actions';
import { Loader } from '../Loader/Loader';

const ModalDiv = styled(BaseModal)`
  width: ${rem(560)};
`;

const Row = styled.div`
  display: flex;
  & + & {
    margin-top: ${rem(15)};
  }
`;

const CloseButton = styled(Button2)``;

interface ModalProps {
  product: any;
  dispatch: any;
  onCloseButtonClick: (event) => void;
}

interface ModalStates {
  goodId: number | null;
  isError: boolean;
  isLoading: boolean;
  attribObj: {};
}

class SelectVariantModal extends React.PureComponent<ModalProps, ModalStates> {
  constructor(props) {
    super(props);
    this.state = {
      goodId: null,
      isError: false,
      isLoading: false,
      attribObj: {},
    };
  }

  public onVariationChange = (e) => {
    try {
      const { product } = this.props;
      const { attribObj } = this.state;

      const attribCount = product?.attribs?.length;
      const goods = product?.goods;

      const selectedIndex = e.target.options.selectedIndex;
      const attribName = e.target.options[selectedIndex].getAttribute(
        'data-key',
      );
      const attribValue = e.target.value;

      attribObj[attribName] = attribValue;
      this.setState({ attribObj: attribObj });

      let resGood;
      for (const good of goods) {
        if (good && good.web_attribs && good.web_attribs.length > 0) {
          let matchedAttribCount = 0;
          good.web_attribs.forEach((attrib) => {
            const attribName = attrib?.attrib_name;
            const attribValue = attrib?.values[0]?.attrib_value;

            if (attribObj[attribName] == attribValue) {
              matchedAttribCount += 1;
            }
          });
          if (matchedAttribCount == attribCount) {
            resGood = good;
            break;
          }
        }
      }

      if (resGood) {
        this.setState({ goodId: resGood.good_id });
        this.setState({ isError: false });
      }
    } catch (e) {}
  };

  public addToCart = async () => {
    const { dispatch, product, onCloseButtonClick } = this.props;
    const { goodId } = this.state;

    if (!goodId) {
      this.setState({ isError: true });
      return;
    }

    this.setState({ isLoading: true });
    try {
      await dispatch(addItemToCart(product.main_good.product_id, goodId, 1));
      dispatch(
        setAddToCartModalProduct({
          product,
          count: 1,
          goodId: this.state.goodId,
        }),
      );
    } catch (e: any) {
      if (e?.details?.name === 'MAX_ITEMS_EXCEEDED') {
        await this.props.dispatch(
          setAddToCartMaxItemExceededModalProduct(true),
        );
      }
    } finally {
      this.setState({ isLoading: false });
      this.setState({ isError: false });
      onCloseButtonClick(null);
    }
  };

  public render() {
    const { product, onCloseButtonClick } = this.props;
    const { isError, isLoading } = this.state;

    return (
      <ModalDiv
        title={__('Zvoľte variant produktu')}
        body={
          <Row>
            <ProductVariantSelect
              product={product}
              isError={isError}
              onChange={this.onVariationChange}
              attribsObject={this.state.attribObj}
            />
            <Loader loading={isLoading} />
          </Row>
        }
        footer={
          <StyledFooter>
            <CloseButton className="primary lg" onClick={this.addToCart}>
              {__('Pridať do košíka')}
            </CloseButton>
          </StyledFooter>
        }
        onCloseButtonClick={onCloseButtonClick}
      />
    );
  }
}

export default SelectVariantModal;
