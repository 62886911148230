const namespace = 'app';

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_DEFAULT_TITLE = 'SET_DEFAULT_TITLE';
export const SET_TOP_MENU_ITEMS = 'SET_TOP_MENU_ITEMS';
export const SET_MAIN_MENU = 'SET_MAIN_MENU';
export const SET_FOOTER_DATA = 'SET_FOOTER_DATA';
export const SET_CONTACT_INFO = 'SET_CONTACT_INFO';
export const SET_OTHER_TEXTS = 'SET_OTHER_TEXTS';
export const SET_SOCIAL_URLS = 'SET_SOCIAL_URLS';
export const SET_META_TAGS = 'SET_META_TAGS';
export const SET_PROTECTION_SETTINGS = 'SET_PROTECTION_SETTINGS';
export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';
export const SET_INVALID_EMAILS = 'SET_INVALID_EMAILS';
export const SET_SCHOOL_ARTICLES = 'SET_SCHOOL_ARTICLES';
export const SET_SCHOOL_ARTICLES_LOADING = 'SET_SCHOOL_ARTICLES_LOADING';
export const SET_SCHOOL_ARTICLE = 'SET_SCHOOL_ARTICLE';
export const SET_SINGLE_SCHOOL_ARTICLE = 'SET_SINGLE_SCHOOL_ARTICLE';
export const SET_SCHOOL_ARTICLE_LOADING = 'SET_SCHOOL_ARTICLE_LOADING';
export const SET_SCHOOL_ARTICLE_CONNECTIONS = 'SET_SCHOOL_ARTICLE_CONNECTIONS';
export const SET_SCHOOL_ARTICLE_CONNECTIONS_LOADING =
  'SET_SCHOOL_ARTICLE_LOADING_CONNECTIONS';
export const SET_BANNERS = 'SET_BANNERS';

export const LANG_COOKIE = 'lang';
export const CURRENCY_COOKIE = 'currency';
export const USER_COOKIE = 'user';

export const REQUEST_REGISTER_USER = `${namespace}/REQUEST_REGISTER_USER`;
export const RECEIVE_REGISTER_USER_SUCCESS = `${namespace}/RECEIVE_REGISTER_USER_SUCCESS`;
export const RECEIVE_REGISTER_USER_FAILURE = `${namespace}/RECEIVE_REGISTER_USER_FAILURE`;

export const REQUEST_LOGIN_USER = `${namespace}/REQUEST_LOGIN_USER`;
export const RECEIVE_LOGIN_USER_SUCCESS = `${namespace}/RECEIVE_LOGIN_USER_SUCCESS`;
export const RECEIVE_LOGIN_USER_FAILURE = `${namespace}/RECEIVE_LOGIN_USER_FAILURE`;

export const SET_CATEGORY_GROUP = `${namespace}/SET_CATEGORY_GROUP`;
export const SET_CATEGORY_GROUP_MAIN = `${namespace}/SET_CATEGORY_GROUP_MAIN`;
export const SET_CURRENT_CATEGORY_ID = `${namespace}/SET_CURRENT_CATEGORY_ID`;

export const SET_SEARCHED_RESULTS_PREVIEW_LOADER = `${namespace}/SET_SEARCHED_RESULTS_PREVIEW_LOADER`;
export const SET_SEARCHED_RESULTS_LOADER = `${namespace}/SET_SEARCHED_RESULTS_LOADER`;
export const SET_SEARCH_INITIAL_LOADED = `${namespace}/SET_SEARCH_INITIAL_LOADED`;
export const SET_SEARCHED_RESULTS_PREVIEW = `${namespace}/SET_SEARCHED_RESULTS_PREVIEW`;
export const SET_SEARCHED_RESULTS = `${namespace}/SET_SEARCHED_RESULTS`;

export const SET_ADD_TO_CART_MODAL_PRODUCT = `${namespace}/SET_ADD_TO_CART_MODAL_PRODUCT`;
export const SET_ADD_TO_CART_MODAL_PRODUCT_OUT_OF_STOCK = `${namespace}/SET_ADD_TO_CART_MODAL_PRODUCT_OUT_OF_STOCK`;
export const SET_ADD_TO_CART_MODAL_PRODUCT_QUANTITY_LIMIT = `${namespace}/SET_ADD_TO_CART_MODAL_PRODUCT_QUANTITY_LIMIT`;
export const SET_ADD_TO_CART_MAX_ITEMS_EXCEEDED_MODAL_PRODUCT = `${namespace}/SET_ADD_TO_CART_MAX_ITEMS_EXCEEDED_MODAL_PRODUCT`;
export const SET_SELECT_VARIANT_MODAL = `${namespace}/SET_SELECT_VARIANT_MODAL`;

export const CHANGE_AMOUNT_PRODUCT_OUT_OF_SOCK = `${namespace}/CHANGE_AMOUNT_PRODUCT_OUT_OF_SOCK`;

export const SET_REGISTRATION_SUCCESS_CMS = `${namespace}/SET_REGISTRATION_SUCCESS_CMS`;

export const SET_CURRENT_PRODUCT_FOR_PREVIEW = `${namespace}/SET_CURRENT_PRODUCT_FOR_PREVIEW`;

export const SET_HTML_SITEMAP = `${namespace}/SET_HTML_SITEMAP`;

export const SET_SHOP_NETWORK_CURRENT_ARTICLE = `${namespace}/SET_SHOP_NETWORK_CURRENT_ARTICLE`;

export const SET_CURRENT_CATEGORY_GROUP = `${namespace}/SET_CURRENT_CATEGORY_GROUP`;
export const SET_CURRENT_CATEGORY = `${namespace}/SET_CURRENT_CATEGORY`;
export const SET_CATEGORY_CHANGED = `${namespace}/SET_CATEGORY_CHANGED`;
export const SET_URLMAP_DATA = `${namespace}/SET_URLMAP_DATA`;

export const SET_SHOW_POPUP_SET_SELECT = `${namespace}/SET_SHOW_POPUP_SET_SELECT`;
export const SET_SHOW_POPUP_SET_SELECT2 = `${namespace}/SET_SHOW_POPUP_SET_SELECT2`;

export const SET_GENERAL_FREE_DELIVERY_INFO = `${namespace}/SET_GENERAL_FREE_DELIVERY_INFO`;
export const SET_STATE_CATEGORY_LIST_NEW = `${namespace}/SET_STATE_CATEGORY_LIST_NEW`;
export const SET_CURRENT_CATEGORY_GROUP_ID = `${namespace}/SET_CURRENT_CATEGORY_GROUP_ID`;

export const SET_ALERT_SCHOOL_TEXT = `${namespace}/SET_ALERT_SCHOOL_TEXT`;
export const SET_REGISTRATION_TEXT = `${namespace}/SET_REGISTRATION_TEXT`;
export const SET_SKVELKO_BOTTOM_TEXT = `${namespace}/SET_SKVELKO_BOTTOM_TEXT`;
export const SET_SKVELKO_DESCRIPTION_TEXT = `${namespace}/SET_SKVELKO_DESCRIPTION_TEXT`;

export const SET_DELIVERY_POINT_MODAL_POINT = `${namespace}/SET_DELIVERY_POINT_MODAL_POINT`;
export const SET_PACKETA_DELIVERY_POINT_MODAL_POINT = `${namespace}/SET_PACKETA_DELIVERY_POINT_MODAL_POINT`;
export const SET_SHOP_PERSONAL_PICKUP_MODAL = `${namespace}/SET_SHOP_PERSONAL_PICKUP_MODAL`;

export const SET_CMS_CONTENT_MODAL = `${namespace}/SET_CMS_CONTENT_MODAL`;
export const SET_ARTICLE_MODAL = `${namespace}/SET_ARTICLE_MODAL`;

export const SET_PREV_PATH = `${namespace}/SET_PREV_PATH`;

export const SET_INFO_MULTIPLE_STORES_TEXT = `${namespace}/SET_INFO_MULTIPLE_STORES_TEXT`;
export const SET_INFO_MULTIPLE_STORES_LOADING = `${namespace}/SET_INFO_MULTIPLE_STORES_LOADING`;
export const SET_INFO_MULTIPLE_STORES_SHOW = `${namespace}/SET_INFO_MULTIPLE_STORES_SHOW`;

export const SET_GIFT_REMAIN = `${namespace}/SET_GIFT_REMAIN`;

export const SET_CART_PROTECTION_MODAL = `${namespace}/SET_CART_PROTECTION_MODAL`;
export const SET_CART_PROTECTION_TEXT = `${namespace}/SET_CART_PROTECTION_TEXT`;
export const SET_CART_PROTECTION_TEXT_LOADING = `${namespace}/SET_CART_PROTECTION_TEXT_LOADING`;

export const SET_CART_HAD_USER_MODAL = `${namespace}/SET_CART_HAD_USER_MODAL`;

export const SET_MAILCHIMP_SCHOOL_TEXT = `${namespace}/SET_MAILCHIMP_SCHOOL_TEXT`;
export const SET_MAILCHIMP_SCHOOL_LOADING = `${namespace}/SET_MAILCHIMP_SCHOOL_LOADING`;

export const SET_CMS_CONTENT = `${namespace}/SET_CMS_CONTENT`;
export const SET_CMS_CONTENT_LOADING = `${namespace}/SET_CMS_CONTENT_LOADING`;

export const SET_PRODUCT = `${namespace}/SET_PRODUCT`;
export const SET_PRODUCT_LOADING = `${namespace}/SET_PRODUCT_LOADING`;

export const SET_GLOBAL_META_TAGS = `${namespace}/SET_GLOBAL_META_TAGS`;
export const SET_SEARCH_SESSION_ID = `${namespace}/SET_SEARCH_SESSION_ID`;
export const SET_SEARCH_ID = `${namespace}/SET_SEARCH_ID`;

export const SET_FAVORITE_PRODUCTS_COUNT = `${namespace}/SET_FAVORITE_PRODUCTS_COUNT`;
export const SET_CURRENT_UNIQS = `${namespace}/SET_CURRENT_UNIQS`;
