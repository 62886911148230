import * as React from 'react';
import styled, { css } from 'styled-components';
import { VisuallyHiddenStyles } from '../../../theme/libraries/h5b-helpers';
import { desaturate, lighten, rem } from 'polished';
import {
  CheckboxRadioLabelDefaultStyles,
  CheckboxRadioLabelStyledStyles,
} from './FormStyles';
import {
  CheckboxRadioInputProps,
  CheckboxRadioLabelProps,
  CheckboxRadioProps,
} from './FormInterfaces';
import {
  BlockAppearanceStyles,
  BlockMarginStyles,
  BlockShadowStyles,
} from '../Block/Block';

const RadioLabelDefault = styled.label`
  ${CheckboxRadioLabelDefaultStyles};
`;

export const RadioLabelStyledStyles = css`
  padding-left: ${({ theme }) =>
    rem(theme.form.radio.size + theme.form.radio.gutter)};
  &:before,
  &:after {
    background: ${({ theme }) => theme.form.background.default};
    border-radius: 50%;
  }
  &:before {
    background: ${({ theme }) => theme.form.background.default};
    border: ${({ theme }) => rem(theme.form.border.width)}
      ${({ theme }) => theme.form.border.style}
      ${({ theme }) => theme.form.border.color};
    ${({ theme }) => theme.size(theme.form.radio.size)};
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    ${({ theme }) => theme.transitions('background, border, box-shadow')};
  }
  &:after {
    ${({ theme }) => theme.size(12)};
    top: 50%;
    left: ${rem(6)};
    transform: translateY(${rem(-6)}) scale(0);
    ${({ theme }) => theme.transition('transform')};
  }
`;

const RadioLabelStyled = styled(RadioLabelDefault)`
  ${CheckboxRadioLabelStyledStyles};
  ${RadioLabelStyledStyles};
`;

const RadioInputDefault = styled.input`
  margin-right: ${rem(5)};
  &:disabled {
    & + ${RadioLabelDefault} {
      color: ${({ theme }) => theme.form.color.disabled};
    }
  }
`;

const RadioInputStyled = styled(RadioInputDefault)`
  ${VisuallyHiddenStyles};
  &:disabled {
    & + ${RadioLabelStyled} {
      color: ${({ theme }) => theme.form.color.disabled};
      &:before {
        background: ${({ theme }) => theme.form.background.disabled};
      }
    }
    &:checked {
      & + ${RadioLabelStyled} {
        &:before {
          background: ${({ theme }) =>
            desaturate(0.2, lighten(0.2, theme.color.primary))};
          border-color: ${({ theme }) =>
            desaturate(0.2, lighten(0.2, theme.color.primary))};
        }
      }
    }
  }
  &:focus {
    & + ${RadioLabelStyled} {
      &:before {
        border-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
  &:checked {
    & + ${RadioLabelStyled} {
      &:before {
        border-width: ${rem(12)};
        border-color: ${({ theme }) => theme.color.primary};
      }
      &:after {
        transform: translateY(${rem(-6)}) scale(1);
      }
    }
    &:focus {
      & + ${RadioLabelStyled} {
        &:before {
          box-shadow: ${({ theme }) => theme.boxShadow.primary};
        }
      }
    }
  }
`;

export const RadioWrapper = styled.div`
  ${BlockAppearanceStyles};
  ${BlockMarginStyles};
  ${({ theme }) => theme.media('sm')} {
    ${BlockShadowStyles};
  }
`;

export const RadioItemsWrapper = styled.div`
  & + & {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
`;

export const RatioItem = styled.label`
  ${CheckboxRadioLabelDefaultStyles};
  ${CheckboxRadioLabelStyledStyles};
  ${RadioLabelStyledStyles};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: ${rem(24)} ${rem(24)} ${rem(24)} ${rem(60)};
  &:before {
    top: ${rem(20)};
    left: ${rem(20)};
    transform: translateY(0);
  }
  &:after {
    top: ${rem(26)};
    left: ${rem(26)};
    transform: translateY(0) scale(0);
  }
  ${({ theme }) => theme.media('sm')} {
    font-size: ${rem(18)};
    padding: ${rem(22)} ${rem(22)} ${rem(22)} ${rem(60)};
  }
`;

export const ItemRadio = styled.input`
  ${VisuallyHiddenStyles};
  &:focus {
    & + ${RatioItem} {
      &:before {
        border-color: ${({ theme }) => theme.color.primary};
      }
    }
  }
  &:checked {
    & + ${RatioItem} {
      &:before {
        border-width: ${rem(12)};
        border-color: ${({ theme }) => theme.color.primary};
      }
      &:after {
        transform: translateY(0) scale(1);
      }
    }
    &:focus {
      & + ${RatioItem} {
        &:before {
          box-shadow: ${({ theme }) => theme.boxShadow.primary};
        }
      }
    }
  }
  &.disabled {
    & + ${RatioItem} {
      &:before {
        content: 'x';
        font-size: ${rem(20)};
        padding: 0 ${rem(5)};
        line-height: 0.9;
      }
    }
  }
`;

export const ItemRadioLabelWrapper = styled.div`
  flex: 1 0 70%;
`;

export const ItemRadioLabel = styled.span`
  flex: 1 0 70%;
`;

export const CartItemRadioLabel = styled.span``;

export const ItemRadioDesc = styled.span`
  padding-top: ${rem(6)};
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.color.blue};
`;

class RadioInput extends React.Component<CheckboxRadioInputProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { name, id, disabled, value, checked } = this.props;
    return (
      <RadioInputDefault
        type="radio"
        name={name}
        id={id}
        disabled={disabled}
        value={value}
        checked={checked}
      />
    );
  }
}

class RadioLabel extends React.Component<CheckboxRadioLabelProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const { id, children, className } = this.props;
    return (
      <RadioLabelDefault htmlFor={id} className={className}>
        {children}
      </RadioLabelDefault>
    );
  }
}

export class RadioDefault extends React.Component<CheckboxRadioProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      name,
      id,
      disabled,
      children,
      className,
      value,
      checked,
    } = this.props;
    return (
      <React.Fragment>
        <RadioInput
          name={name}
          id={id}
          disabled={disabled}
          value={value}
          checked={checked}
        />
        <RadioLabel id={id} className={className}>
          {children}
        </RadioLabel>
      </React.Fragment>
    );
  }
}

export class RadioStyled extends React.Component<CheckboxRadioProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {
      name,
      id,
      disabled,
      children,
      className,
      value,
      checked,
      onChange,
    } = this.props;
    return (
      <React.Fragment>
        <RadioInputStyled
          type="radio"
          name={name}
          id={id}
          disabled={disabled}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <RadioLabelStyled htmlFor={id} className={className}>
          {children}
        </RadioLabelStyled>
      </React.Fragment>
    );
  }
}
