import { rem } from 'polished';
import * as React from 'react';
import { __ } from 'react-i18n';
import styled from 'styled-components';
import ImagePlaceholder from '../../components/_helpers/Image/ImagePlaceholder';
import { CheckboxStyled } from '../../components/_helpers/form/Checkbox';
import { formatPrice } from '../../utilities';
import { NextButton } from '../../components/Cart/Cart1';
import { ButtonIcon } from '../../components/_helpers/form/Button';
import { SimpleProductVariantSelect } from '../../components/Product/Product';
import { addItemsToCart } from '../Cart/actions';
import { Loader } from '../../components/_helpers/Loader/Loader';
import API from '../../services/API';
import { setAddToCartModalProduct } from '../App/actions';

export interface ProductUpsellProps {
  upsell: any;
  originalProduct: any;
  dispatch: any;
}

export const ProductUpsell = (props: ProductUpsellProps) => {
  const { upsell, originalProduct, dispatch } = props;
  const produts = [originalProduct.productDetail].concat(
    upsell.assigned_products
      .filter(
        (item) =>
          item.product?.product_id !==
          originalProduct?.productDetail?.product_id,
      )
      .map((ap) => ap.product),
  );

  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState<number[]>(
    [],
  );

  const [selectedGoodIds, setSelectedGoodIds] = React.useState<
    { productId: number; goodId: number }[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorItems, setErrorITems] = React.useState<number[]>([]);

  const sumFinalPrice = produts.reduce((a, b) => {
    if (selectedCheckboxes.includes(b.product_id)) {
      return a + b.main_good.final_price;
    } else {
      return a + 0;
    }
  }, 0);

  React.useEffect(() => {
    API.impressionUpsell(
      upsell.id,
      {},
      {
        type: 'UPSELL',
      },
    );

    API.impressionUpsell(
      upsell.id,
      {},
      {
        type: 'UPSELL_PRODUCT',
        product_ids: produts.map((p) => p.product_id),
      },
    );
  }, []);

  React.useEffect(() => {
    const op = [originalProduct.productDetail].concat(
      upsell.assigned_products.map((ap) => ap.product),
    );
    setSelectedCheckboxes(op.map((p) => p.product_id));
  }, [upsell.id]);

  const onProductCheckboxChange = (productId: number) => {
    if (!selectedCheckboxes.includes(productId)) {
      setSelectedCheckboxes([...selectedCheckboxes, productId]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((sc) => sc !== productId),
      );
    }
  };

  const onVariationChange = (e, product) => {
    try {
      const attribCount = product?.attribs?.length;
      const goods = product?.goods;
      const selectedAttribValue = e.target.value;

      let resGood;
      for (const good of goods) {
        if (good && good.web_attribs && good.web_attribs.length > 0) {
          let matchedAttribCount = 0;
          good.web_attribs.forEach((attrib) => {
            const attribValue = attrib?.values[0]?.attrib_value;
            if (selectedAttribValue == attribValue) {
              matchedAttribCount += 1;
            }
          });
          if (matchedAttribCount == attribCount) {
            resGood = good;
            break;
          }
        }
      }

      if (resGood) {
        const alreadySelected = selectedGoodIds.find(
          (s) => s.productId === product.product_id,
        );
        if (alreadySelected) {
          alreadySelected.goodId = resGood.good_id;
          setSelectedGoodIds(selectedGoodIds);
        } else {
          setSelectedGoodIds([
            ...selectedGoodIds,
            { productId: product.product_id, goodId: resGood.good_id },
          ]);
        }

        setErrorITems(errorItems.filter((i) => i !== product.product_id));
      }
    } catch (e) {}
  };

  const addToCart = async () => {
    let items: {
      product_id: number;
      good_id: number;
      upsell_id?: number;
      is_upsell_main_product?: boolean;
      count: number;
    }[] = [];
    const errorItems: number[] = [];
    for (const product of produts) {
      if (selectedCheckboxes.includes(product.product_id)) {
        if (product.goods.length > 1) {
          const selectedGoodId = selectedGoodIds.find(
            (i) => i.productId === product.product_id,
          );
          if (selectedGoodId) {
            items.push({
              product_id: product.product_id,
              good_id: selectedGoodId.goodId,
              upsell_id: upsell.id,
              is_upsell_main_product:
                product.product_id ===
                originalProduct?.productDetail?.product_id
                  ? true
                  : false,
              count: 1,
            });
          } else {
            errorItems.push(product.product_id);
          }
        } else {
          items.push({
            product_id: product.product_id,
            good_id: product.main_good.good_id,
            upsell_id: upsell.id,
            is_upsell_main_product:
              product.product_id === originalProduct?.productDetail?.product_id
                ? true
                : false,
            count: 1,
          });
        }
      }
    }

    if (
      items.length === 1 &&
      items[0].product_id === originalProduct?.productDetail?.product_id
    ) {
      items = items.map((item) => {
        item.upsell_id = undefined;
        item.is_upsell_main_product = undefined;
        return item;
      });
    }

    if (items.length > 0 && errorItems.length === 0) {
      setIsLoading(true);
      await dispatch(addItemsToCart(items));
      dispatch(
        setAddToCartModalProduct({
          product: originalProduct.productDetail,
          count: 1,
          isUpsell: true,
        }),
      );
      setErrorITems([]);
      setIsLoading(false);
    } else {
      setErrorITems(errorItems);
    }
  };

  let imagesCounter = 0;
  return (
    <Loader loading={isLoading}>
      <Wrapper>
        <Title> {__('BONUSOVÝ TIP')} !!! </Title>
        <ImagesWrapper>
          {produts.map((product) => {
            const placeholderThumb = '/images/placeholder-200.png';
            const mainImage = product?.photogallery?.length
              ? product?.photogallery[0]
              : placeholderThumb;
            imagesCounter++;

            return (
              <>
                <Image
                  src={mainImage}
                  placeholder={placeholderThumb}
                  retinaPlaceholder={placeholderThumb}
                  w={360}
                  h={360}
                  notLazyloading={false}
                  alt={__('Upsell obrázok')}
                />
                {produts.length !== imagesCounter ? (
                  <ImagePlus>+</ImagePlus>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </ImagesWrapper>
        <DescriptionWrapper>{upsell.description}</DescriptionWrapper>
        <ProductsWrapper>
          {produts.map((product) => {
            return (
              <OuterProductWrapper>
                <ProductHr />
                <ProductWrapper>
                  <CheckboxStyled
                    name={`checkbox-` + product.product_id}
                    id={`checkbox-` + product.product_id}
                    children={undefined}
                    value={'1'}
                    checked={selectedCheckboxes.includes(product.product_id)}
                    onChange={() => onProductCheckboxChange(product.product_id)}
                  ></CheckboxStyled>
                  <ProductMiddleWrapper>
                    {product?.product_id ===
                    originalProduct?.productDetail?.product_id ? (
                      <ThisProduct>{__('Tento produkt')}</ThisProduct>
                    ) : (
                      <></>
                    )}
                    <ProductName>{product.name}</ProductName>
                    {product.goods.length > 1 ? (
                      <SelectVariant>
                        <SimpleProductVariantSelect
                          product={product}
                          onChange={(e) => onVariationChange(e, product)}
                          attribsObject={{}}
                          isError={
                            errorItems.includes(product.product_id)
                              ? true
                              : false
                          }
                          isSelected={
                            !!selectedGoodIds.find(
                              (d) => d.productId === product.product_id,
                            )
                          }
                        />
                      </SelectVariant>
                    ) : (
                      <></>
                    )}
                  </ProductMiddleWrapper>
                  <ProductPriceWrapper>
                    {formatPrice(
                      product.main_good.final_price,
                      product.main_good.currency_id,
                    )}
                  </ProductPriceWrapper>
                </ProductWrapper>
              </OuterProductWrapper>
            );
          })}
        </ProductsWrapper>
        <ProductHr />
        <FinalPriceWrapper>
          <FinalPriceText>{__('CENA SPOLU')}:</FinalPriceText>
          <FinalPriceValue>{formatPrice(sumFinalPrice, 'EUR')}</FinalPriceValue>
        </FinalPriceWrapper>
        <CartButton
          className="primary"
          onClick={addToCart}
          disabled={selectedCheckboxes.length === 0 ? true : false}
        >
          <CartIcon icon="cart2" />
          {__('Vybrané pridať do košíka')}
        </CartButton>
      </Wrapper>
    </Loader>
  );
};

const Title = styled.div`
  font-weight: 600;
`;

const Wrapper = styled.div`
  background: white;
  padding: ${rem(12)};
  margin-top: ${rem(10)};
`;

const ImagesWrapper = styled.div`
  display: flex;
  margin-top: ${rem(4)};
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const Image = styled(ImagePlaceholder)`
  display: block;
  border-radius: ${rem(4)};
  padding: 0;
  max-height: ${rem(60)};
  padding: ${rem(4)};

  display: inline-block;
  background: rgb(255, 255, 255);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0.375rem;
  border: 0.125rem solid transparent;
  transition: border-color 0.3s ease 0s;
`;

const ImagePlus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-size: x-large;
  font-weight: 600;
`;

const DescriptionWrapper = styled.div`
  font-size: ${rem(14)};
`;

const ProductsWrapper = styled.div``;

const OuterProductWrapper = styled.div``;

const ProductWrapper = styled.div`
  display: flex;
  margin-top: ${rem(15)};
  margin-bottom: ${rem(15)};
`;

const ProductMiddleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: ${rem(24)};
  justify-content: center;
`;

const ProductMiddleContent = styled.div``;

const SelectVariant = styled(ProductMiddleContent)`
  margin-top: ${rem(6)};
`;

const ThisProduct = styled(ProductMiddleContent)`
  font-size: ${rem(14)};
  color: #9b9b9b;
`;

const ProductName = styled(ProductMiddleContent)`
  font-size: ${rem(14)};
`;

const ProductPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: ${rem(18)};
  font-weight: 600;
`;

const FinalPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FinalPriceText = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const FinalPriceValue = styled.div`
  font-weight: 900;
  font-size: 24px;
`;

const ProductHr = styled.hr`
  width: 100%;
  background: none;
  border: none;
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  margin-left: -12px;
  padding-left: 24px;
`;

const CartButton = styled(NextButton)`
  width: 100%;
  margin-top: ${rem(8)};
`;

const CartIcon = styled(ButtonIcon)`
  ${({ theme }) => theme.size(24)};
  fill: ${({ theme }) => theme.color.white};
  margin-right: ${rem(10)};
`;
