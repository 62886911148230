import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  downloadBase64,
  formatPrice,
  formatStringDate,
  isSchool,
  isTeacher,
  prop,
  sanitizeFileName,
} from '../../utilities';
import { ButtonCogIcon } from './MojUcet';
import { Button, ButtonStyled } from '../../components/_helpers/form/Button';
import { MainBlock } from './Ucet';
import API, { ThenArg } from '../../services/API';
import {
  changeAvailabilitySkvelkoSetListAndReload,
  loadSkvelkoSetList,
  removeSkvelkoSetListAndReload,
  setCreatingNewSetState,
  setSkvelkoSetListLoader,
} from './actions';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import ChooseModal from '../../components/_helpers/Modal/ChooseModal';
import Alert from '../../components/Alert/Alert';
import { Loader } from '../../components/_helpers/Loader/Loader';
import {
  TITLE_ACCOUNT_SETS,
  TITLE_ACCOUNT_SETS_MANAGEMENT,
  URL_SET_NEW_CHOOSE,
} from '../../constants';
import { loadCmsContent } from '../App/actions';
import { Switch } from '../../components/_helpers/form/Switch';
import { InputStyled } from '../../components/_helpers/form/Input';
import { Field, Form } from 'react-final-form';

const SetButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.media(1024)} {
    white-space: nowrap;
  }
  margin-top: ${rem(12)};
`;

const AlertWrapper = styled.div`
  margin-bottom: ${rem(15)};
`;

const SetsWrapper = styled.div`
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  margin-bottom: ${rem(20)};
`;

const SetWrapper = styled.div`
  &:not(:first-child) {
    border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  }
`;

const SetContentWrapper = styled.div`
  padding: ${rem(8)} ${rem(18)};
`;

const SetContent = styled.div`
  ${({ theme }) => theme.media('xs')} {
    display: flex;
    justify-content: space-between;
  }
`;

const SetTitle = styled.h4`
  display: inline-block;
  margin: 0;
  font-size: ${rem(20)};
  font-weight: 800;
`;

const SetButton = styled(Button)`
  padding: ${rem(7)};
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  margin-right: ${rem(5)};
  margin-top: ${rem(2)};
  margin-bottom: ${rem(2)};
`;

const ToChematButton = styled(Button)`
  padding: ${rem(7)};
  border: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  border-radius: ${({ theme }) => rem(theme.borderRadius.default)};
  margin-right: ${rem(5)};
  margin-top: ${rem(2)};
  margin-bottom: ${rem(2)};
`;

const SetButtonContent = styled.div``;

const SetButtonIcon = styled(ButtonCogIcon)`
  fill: ${({ theme }) => theme.color.blue};
`;

const SetButtonText = styled.p`
  margin: 0 0 0 ${rem(10)};
  display: inline-block;
  font-size: ${rem(13)};
  ${({ theme }) => theme.media('xs')} {
    font-size: ${rem(14)};
  }
`;

const SetInfoContent = styled.div`
  margin-top: ${rem(8)};
  background: ${({ theme }) => theme.color.gray93};

  &.unavailable {
    background: ${({ theme }) => theme.color.errorLight};
  }
`;

const SetInfo = styled.p`
  display: inline-block;
  margin: 0;
  padding-left: ${rem(12)};
  font-size: ${rem(14)};
`;

const CmsContent = styled.div`
  margin-top: ${rem(18)};
`;

const MassPrintWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid #495395;
`;

const MassPrintHeader = styled.div`
  background-color: #495395;
  color: white;
  padding-left: ${rem(15)};
  padding-bottom: ${rem(2)};
`;

const MassPrintBody = styled.div`
  margin-top: 0;
  overflow: auto;
  padding: ${rem(15)};
`;

const MassPrintRow = styled.div`
  margin-bottom: ${rem(10)};
`;

const CustomInputStyled = styled(InputStyled)`
  width: ${rem(60)};
  margin-right: ${rem(20)};
  text-align: right;
`;

const PrintButton = styled(Button)`
  background-color: #495395;
  color: white;
  margin-top: ${rem(10)};
  padding-top: ${rem(8)};
  padding-bottom: ${rem(8)};
  padding-right: ${rem(15)};
  padding-left: ${rem(15)};
`;

const NewSetListButton = styled(ButtonStyled)`
  margin-right: ${rem(8)};
  width: 100%;
  color: white;
  &.lg {
    padding-right: 10px;
    padding-left: 10px;
  }

  ${({ theme }) => theme.media(320)} {
    width: initial;
  }

  ${({ theme }) => theme.media('xs')} {
    &.primary {
      color: white;
      margin-right: ${rem(12)};
    }
    &.lg {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`;

const WhatIsSkvelkoButton = styled(ButtonStyled)`
  margin-top: 10px;
  width: 100%;
  color: black;

  &.lg {
    padding-right: 10px;
    padding-left: 10px;
  }

  ${({ theme }) => theme.media(320)} {
    width: initial;
  }

  ${({ theme }) => theme.media('xs')} {
    margin-top: 0px;
    &.lg {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`;

const sitemapUid = 'SETS_MANAGEMENT';

interface SetsManagementProps {
  setSearch: ThenArg<typeof API.searchSkvelkoSet>;
  skvelkoSetListLoader: boolean;
  setTitle: (title: string, link?: string | null) => void;
  dispatch: (a: any) => void;
  user: any;
  currentPath: string;
  cmsContent?: any;
  cmsContentLoading?: boolean;
}

interface SetsManagementState {
  removeModalOpen: boolean;
  removeModalText: string;
  removeModalSkvelkoId: null;
  massPrintLoading: boolean;
  massPrintEnabled: boolean;
  exportSkvelkoSetResult: boolean | null;
  exportSkvelkoSetLoading: boolean;
}

class SetsManagement extends React.PureComponent<
  SetsManagementProps,
  SetsManagementState
> {
  public static async getInitialProps(props) {
    await props.dispatch(loadCmsContent(sitemapUid));
    await props.dispatch(loadSkvelkoSetList());
  }

  constructor(props) {
    super(props);

    props.setBreadcrumbsItems([
      { name: TITLE_ACCOUNT_SETS, url: props.currentPath },
      { name: TITLE_ACCOUNT_SETS_MANAGEMENT, url: props.currentPath },
    ]);

    this.state = {
      removeModalOpen: false,
      removeModalText: '',
      removeModalSkvelkoId: null,
      massPrintLoading: false,
      massPrintEnabled: false,
      exportSkvelkoSetResult: null,
      exportSkvelkoSetLoading: false,
    };
  }

  public onEditSetClick(skvelkoSetId) {
    this.props.dispatch(push('/my-account/set/edit/' + skvelkoSetId));
  }

  public onRemoveSetClick(skvelkoSetId, skvelkoSetName) {
    this.setState({
      removeModalOpen: true,
      removeModalText:
        __('Naozaj chcete zoznam') +
        ' ' +
        skvelkoSetName +
        ' ' +
        __('odstrániť'),
      removeModalSkvelkoId: skvelkoSetId,
    });
  }

  public async onAvailabilityChange(set) {
    this.props.dispatch(
      changeAvailabilitySkvelkoSetListAndReload(
        set.id,
        set.skvelkoSetAvailabilityId === 1 ? 2 : 1,
      ),
    );
  }

  public async onPrintSetClick(skvelkoSetId, setName) {
    this.props.dispatch(setSkvelkoSetListLoader(true));
    const result: any = await API.printSkvelkoSet(skvelkoSetId, {});
    if (result?.base64) {
      downloadBase64(
        result.base64,
        'pdf',
        sanitizeFileName(`Zoznam pre ${setName}`),
      );
    }
    this.props.dispatch(setSkvelkoSetListLoader(false));
  }

  public async onPrintColorLeafletClick(skvelkoSetId, setName) {
    this.props.dispatch(setSkvelkoSetListLoader(true));
    const result: any = await API.printSkvelkoSetColorLeaflet(skvelkoSetId, {});
    if (result.base64) {
      downloadBase64(
        result.base64,
        'pdf',
        sanitizeFileName(`Farebny letak pre ${setName}`),
      );
    }
    this.props.dispatch(setSkvelkoSetListLoader(false));
  }

  public async onPrintLeafletClick(skvelkoSetId, setName) {
    this.props.dispatch(setSkvelkoSetListLoader(true));
    const result: any = await API.printSkvelkoSetLeaflet(skvelkoSetId, {});
    if (result.base64) {
      downloadBase64(
        result.base64,
        'pdf',
        sanitizeFileName(`Letak pre ${setName}`),
      );
    }
    this.props.dispatch(setSkvelkoSetListLoader(false));
  }

  public async onExportToChematClick(skvelkoSetId) {
    this.setState({
      exportSkvelkoSetLoading: true,
    });
    try {
      const result: any = await API.exportSkvelkoSetToChemat(skvelkoSetId);
      if (result.status === 'OK') {
        this.setState({
          exportSkvelkoSetResult: true,
        });
      } else {
        this.setState({
          exportSkvelkoSetResult: false,
        });
      }
    } catch (e) {
      this.setState({
        exportSkvelkoSetResult: false,
      });
    } finally {
      this.setState({
        exportSkvelkoSetLoading: false,
      });
    }
  }

  public closeModal = () => {
    this.setState({
      removeModalOpen: false,
    });
  };

  public onModalRemoveSetClick = async () => {
    await this.props.dispatch(
      removeSkvelkoSetListAndReload(this.state.removeModalSkvelkoId),
    );
    this.setState({
      removeModalOpen: false,
    });
  };

  public onNewSetClick = () => {
    this.props.dispatch(setCreatingNewSetState(true));
    this.props.dispatch(push(URL_SET_NEW_CHOOSE));
  };

  public onMassSubmit = async (values) => {
    const setsToPrint: Array<{ set_id: number; count: number }> = [];

    for (const key of Object.keys(values)) {
      const id = key.split('-')[1];
      if (values[key] > 0) {
        setsToPrint.push({
          set_id: parseInt(id),
          count: parseInt(values[key]),
        });
      }
    }

    if (setsToPrint.length) {
      this.setState({ massPrintLoading: true });
      const result: any = await API.massPrintSkvelkoSetLeaflet({}, setsToPrint);
      this.setState({ massPrintLoading: false });
      if (result.base64) {
        if (window) {
          const printJS = require('print-js');
          printJS({ printable: result.base64, type: 'pdf', base64: true });
        }
      }
    }
  };

  public render() {
    const sets = this.props.setSearch ? this.props.setSearch.search : [];

    const { removeModalText, removeModalOpen } = this.state;

    const {
      skvelkoSetListLoader,
      cmsContentLoading,
      cmsContent,
      user,
    } = this.props;

    const cmsContentBody = prop(
      cmsContent,
      `${sitemapUid}.content.json_content.body`,
    );

    const teacher = isTeacher(this.props.user);

    return (
      <Loader loading={skvelkoSetListLoader}>
        <MainBlock className={'no-top-padding'}>
          {removeModalOpen && (
            <ChooseModal
              title={__('Potvrdenie')}
              bodyText={removeModalText}
              button1Text={__('NIE')}
              button2Text={__('ÁNO')}
              onButton1Click={this.closeModal}
              onButton2Click={this.onModalRemoveSetClick}
              onCloseButtonClick={this.closeModal}
            />
          )}

          {this.state.exportSkvelkoSetResult !== null ? (
            <AlertWrapper>
              <Alert
                type={this.state.exportSkvelkoSetResult ? 'success' : 'error'}
              >
                {this.state.exportSkvelkoSetResult
                  ? __('Sada bola odoslaná do chematu')
                  : __('Sadu sa nepodarilo odoslať do chematu')}
              </Alert>
            </AlertWrapper>
          ) : (
            ''
          )}

          {sets.length ? (
            <SetsWrapper>
              <Loader loading={this.state.exportSkvelkoSetLoading}>
                {sets.map((set, i) => {
                  const available = set.skvelkoSetAvailabilityId === 1;
                  return (
                    <SetWrapper key={i}>
                      <SetContentWrapper>
                        <SetContent>
                          <SetTitle>
                            {__('Zoznam pre')} {set.fullName}
                          </SetTitle>

                          <Switch
                            disabled={teacher}
                            onClick={this.onAvailabilityChange.bind(this, set)}
                            label={
                              available
                                ? __('Zobrazené rodičom na daffer.sk')
                                : __('Nezobrazené rodičom na daffer.sk')
                            }
                            checked={available}
                          />
                        </SetContent>

                        <SetInfoContent
                          className={available ? '' : 'unavailable'}
                        >
                          <SetInfo>
                            {__('Cena')}:{' '}
                            <strong>
                              {formatPrice(set?.price ?? 0, 'EUR')}
                            </strong>
                          </SetInfo>

                          <SetInfo>
                            {__('Položiek v zozname')}: {set.count}
                          </SetInfo>

                          {set.updated_at && (
                            <SetInfo>
                              {__('Posledná úprava')}:{' '}
                              {formatStringDate(set.updated_at)}
                            </SetInfo>
                          )}
                        </SetInfoContent>

                        <SetButtons>
                          <SetButton
                            onClick={this.onEditSetClick.bind(this, set.id)}
                          >
                            <SetButtonContent>
                              <SetButtonIcon icon="cog" />
                              <SetButtonText>
                                {__('Zobraziť / Upraviť')}
                              </SetButtonText>
                            </SetButtonContent>
                          </SetButton>

                          <SetButton
                            onClick={this.onPrintSetClick.bind(
                              this,
                              set.id,
                              set.fullName,
                            )}
                          >
                            <SetButtonContent>
                              <SetButtonIcon icon="printer" />
                              <SetButtonText>
                                {__('Stiahnúť zoznam pre tlač (PDF)')}
                              </SetButtonText>
                            </SetButtonContent>
                          </SetButton>

                          <SetButton
                            onClick={this.onPrintColorLeafletClick.bind(
                              this,
                              set.id,
                              set.fullName,
                            )}
                          >
                            <SetButtonContent>
                              <SetButtonIcon icon="folder" />
                              <SetButtonText>
                                {__('Stiahnúť farebný leták (PDF)')}
                              </SetButtonText>
                            </SetButtonContent>
                          </SetButton>

                          {isSchool(user) ? (
                            <SetButton
                              onClick={this.onRemoveSetClick.bind(
                                this,
                                set.id,
                                set.fullName,
                              )}
                            >
                              <SetButtonContent>
                                <SetButtonIcon icon="trash" />
                                <SetButtonText>{__('Zmazať')}</SetButtonText>
                              </SetButtonContent>
                            </SetButton>
                          ) : (
                            ''
                          )}

                          {(user?.fa === true && (
                            <>
                              <SetButton
                                onClick={this.onPrintLeafletClick.bind(
                                  this,
                                  set.id,
                                  set.fullName,
                                )}
                              >
                                <SetButtonContent>
                                  <SetButtonText>
                                    {__('Tlač letáku')}
                                  </SetButtonText>
                                </SetButtonContent>
                              </SetButton>

                              <ToChematButton
                                onClick={this.onExportToChematClick.bind(
                                  this,
                                  set.id,
                                )}
                              >
                                <SetButtonContent>
                                  <SetButtonText>
                                    {__('Poslať do chematu')}
                                  </SetButtonText>
                                </SetButtonContent>
                              </ToChematButton>
                            </>
                          )) ||
                            ''}
                        </SetButtons>
                      </SetContentWrapper>
                    </SetWrapper>
                  );
                })}
              </Loader>
            </SetsWrapper>
          ) : (
            <AlertWrapper>
              <Alert>{__('Zoznam pridáte kliknutím na "Pridať zoznam"')}</Alert>
            </AlertWrapper>
          )}
          {user?.fa === true && sets && sets.length ? (
            <Loader loading={this.state.massPrintLoading}>
              <MassPrintWrapper>
                <MassPrintHeader>{__('Hromadná tlač lístkov')}</MassPrintHeader>
                <MassPrintBody>
                  <Form
                    onSubmit={this.onMassSubmit}
                    render={({ handleSubmit }) => (
                      <>
                        {sets.map((set, i) => {
                          return (
                            <MassPrintRow>
                              <Field name={'set-' + set.id} defaultValue="0">
                                {({ input, meta }) => (
                                  <CustomInputStyled {...input} />
                                )}
                              </Field>
                              x&nbsp;&nbsp;
                              <b>
                                {__('Zoznam pre')} {set.fullName}
                              </b>
                            </MassPrintRow>
                          );
                        })}
                        <div>
                          <PrintButton onClick={handleSubmit}>
                            {' '}
                            Tlačiť{' '}
                          </PrintButton>
                        </div>
                      </>
                    )}
                  />
                </MassPrintBody>
              </MassPrintWrapper>
            </Loader>
          ) : (
            ''
          )}

          <NewSetListButton className="primary lg" onClick={this.onNewSetClick}>
            {__('Pridať zoznam')}
          </NewSetListButton>

          <a href="/pre-skolske-zariadenia/co-je-skvelko">
            <WhatIsSkvelkoButton className="lg">
              {__('Čo je skvelko?')}
            </WhatIsSkvelkoButton>
          </a>
        </MainBlock>
        <Loader loading={!!cmsContentLoading}>
          <CmsContent dangerouslySetInnerHTML={{ __html: cmsContentBody }} />
        </Loader>
      </Loader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    setSearch: state.myAccount.setList,
    skvelkoSetListLoader: state.myAccount.skvelkoSetListLoader,
    user: state.auth.user,
    currentPath: prop(state.routing, 'locationBeforeTransitions.pathname', ''),
    cmsContent: state.general.cmsContent,
    cmsContentLoading: state.general.cmsContentLoading,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SetsManagement' })(SetsManagement),
);
