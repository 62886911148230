import styled from 'styled-components';
import { rem } from 'polished';

export const Hr = styled.hr`
  background: none;
  border: none;
  border-top: ${rem(2)} solid ${({ theme }) => theme.color.gray93};
  margin: ${rem(20)} 0;
  ${({ theme }) => theme.media(1024)} {
    margin: ${rem(40)} 0;
  }
`;
