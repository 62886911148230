import * as React from 'react';
import { __ } from 'react-i18n/lib';
import styled from 'styled-components';
import { rem } from 'polished';
import BaseModal from './BaseModal';
import { StyledFooter, Button2 } from './ChooseModal';

const ModalDiv = styled(BaseModal)`
  width: ${rem(560)};
`;

const Row = styled.div`
  display: flex;
  & + & {
    margin-top: ${rem(15)};
  }
`;

const CloseButton = styled(Button2)``;

interface ModalProps {
  onCloseButtonClick?: (event) => void;
}

class AmountProductOutOfStockModal extends React.PureComponent<ModalProps> {
  public render() {
    return (
      <ModalDiv
        title={__('Nie je možné zmeniť počet kusov.')}
        body={
          <Row>
            <p>{__('Viac kusov momentálne nemáme na sklade.')}</p>
          </Row>
        }
        footer={
          <StyledFooter>
            <CloseButton
              className="primary lg"
              onClick={this.props.onCloseButtonClick}
            >
              {__('Pokračovať v nakupovaní')}
            </CloseButton>
          </StyledFooter>
        }
        onCloseButtonClick={this.props.onCloseButtonClick}
      />
    );
  }
}

export default AmountProductOutOfStockModal;
