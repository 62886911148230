import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  loadSetBulkOrder,
  loadSkvelkoSetList,
  loadUserDetail,
} from './actions';
import {
  OtherText,
  TITLE_ACCOUNT_BULK_ORDER,
  TITLE_ACCOUNT_SETS,
  URL_ACCOUNT_SETS,
} from '../../constants';
import styled from 'styled-components';
import { __ } from 'react-i18n';
import { Field, Form } from 'react-final-form';
import { Label } from '../../components/_helpers/form/Label';
import { TextareaStyledBlock } from '../../components/_helpers/form/Textarea';
import { Heading } from '../../components/Cart/Cart3';
import {
  composeValidators,
  email,
  phone,
  phoneMobile,
  required,
} from '../../utilities/validators';
import { InputStyled } from '../../components/_helpers/form/Input';
import EmailAutocomplete from '../../components/_helpers/form/EmailAutocomplete';
import { Clearfix } from '../../theme/libraries/h5b-helpers';
import { rem } from 'polished';
import { Col } from '../../theme/libraries/grid';
import API, { ThenArg } from '../../services/API';
import {
  formatPrice,
  removeFalsyValues,
  round,
  scrollToError,
  scrollToTop,
} from '../../utilities';
import { Loader } from '../../components/_helpers/Loader/Loader';
import {
  Button,
  ButtonStyledStyles,
} from '../../components/_helpers/form/Button';
import { PriceCart, TotalPriceText } from '../../components/Cart/Cart1';
import {
  AmountCol,
  EmptyRow,
  EmptyTd,
  Header,
  HeaderColCenter,
  HeaderColProduct,
  HeaderColRight,
  HeaderRow,
  Info,
  InfoCol,
  Item,
  List,
  Name,
  Price,
  PriceCol,
} from '../../components/Cart/CartProductList';
import InputSpinner from '../../components/_helpers/form/InputSpinner';
import Alert from '../../components/Alert/Alert';
import { ErrorPopup } from '../../components/_helpers/form/FormStyles';

const BulkOrderText = styled.div``;

const FormOrder = styled.form``;

const OrderLabel = styled(Label)`
  display: block;
`;

const OrderTextarea = styled(TextareaStyledBlock)``;

const OrderInput: any = styled(InputStyled)`
  display: block;
  width: 100%;
  ${(props: any) => (props.error ? 'border-color: red' : '')}
`;

const OrderRow = styled(Clearfix)`
  margin-right: ${rem(-10)};
  margin-left: ${rem(-10)};
  margin-bottom: ${rem(10)};
`;

const OrderCol = styled(Col)`
  padding-right: ${rem(10)};
  padding-left: ${rem(10)};
  ${({ theme }) => theme.media('xs')} {
    width: ${({ theme }) => theme.grid.col.col4};
  }
`;

const OrderHeading = styled(Heading)`
  &.in-content {
    margin-top: ${rem(16)};
  }
`;

const Submit = styled(Button)`
  ${ButtonStyledStyles};
  float: right;
  margin-top: ${rem(16)};
`;

const TotalPriceRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
`;

interface SetBulkOrderProps {
  user: ThenArg<typeof API.tokeninfo>;
  userDetail: ThenArg<typeof API.getCustomerDetails>;
  setList: ThenArg<typeof API.searchSkvelkoSet>;
  setBulkOrderText: OtherText;
}
interface SetBulkOrderState {
  isLoading: boolean;
  setCount: object;
  error: string | undefined;
  success: string | undefined;
}

class SetBulkOrder extends React.PureComponent<
  SetBulkOrderProps,
  SetBulkOrderState
> {
  public static async getInitialProps(props) {
    const promises: any[] = [props.dispatch(loadSkvelkoSetList())];

    if (!props.userDetail) {
      promises.push(props.dispatch(loadUserDetail(props.user?.id)));
    }

    if (!props.setBulkOrderText) {
      promises.push(props.dispatch(loadSetBulkOrder()));
    }

    await Promise.all(promises);
  }

  constructor(props) {
    super(props);

    props.setBreadcrumbsItems([
      { name: TITLE_ACCOUNT_SETS, url: URL_ACCOUNT_SETS },
      { name: TITLE_ACCOUNT_BULK_ORDER, url: props.currentPath },
    ]);

    this.state = {
      isLoading: false,
      setCount: {},
      error: undefined,
      success: undefined,
    };
  }

  public onSubmit = async (values, form) => {
    this.setState(() => ({ success: undefined }));

    if (!Object.keys(this.state.setCount).length) {
      this.setState(() => ({
        error: __('Zadajte množstvo aspoň pri jednej sade'),
      }));
      scrollToTop();
      return;
    }

    this.setState(() => ({ isLoading: true }));
    try {
      await API.createBulkSkvelkoOrder(
        {},
        {
          note: values.note,
          name: values.name,
          phone: values.phone,
          email: values.email,
          sets: Object.entries(this.state.setCount).map(([id, count]) => ({
            id: Number(id),
            count,
          })),
        },
      );
      this.setState(() => ({
        setCount: {},
        success: __('Objednávka bola úspešne odoslaná'),
      }));
    } catch (e) {
      this.setState(() => ({
        error: __('Pri odoslaní objednávky sa vyskytla chyba'),
      }));
    } finally {
      scrollToTop();
      this.setState(() => ({ isLoading: false }));
    }
  };

  public onItemCountChange = (id, count) => {
    this.setState((state) => ({
      error: undefined,
      setCount: removeFalsyValues({ ...state.setCount, [id]: count }),
    }));
  };

  public getTotalPrice = () => {
    return (this.props.setList?.search || []).reduce(
      (acc, set) => acc + this.getSetCount(set) * this.getSetPrice(set),
      0,
    );
  };

  public getSetCount = (set) =>
    set?.id ? this.state.setCount[set.id] || 0 : 0;

  public getSetPrice = (set) => round(set?.price ?? 0, 2);

  public render() {
    const { setBulkOrderText, userDetail, setList } = this.props;
    const { isLoading, error, success } = this.state;
    const bulkText = setBulkOrderText?.body;

    if (!userDetail) {
      return <></>;
    }

    return (
      <Loader loading={isLoading}>
        {(bulkText && (
          <BulkOrderText dangerouslySetInnerHTML={{ __html: bulkText }} />
        )) ||
          ''}

        {(error && (
          <Alert type="error" className={'separator'}>
            {error}
          </Alert>
        )) ||
          ''}
        {(success && (
          <Alert type="success" className={'separator'}>
            {success}
          </Alert>
        )) ||
          ''}

        <Header>
          <HeaderRow>
            <HeaderColProduct>{__('Zoznam')}</HeaderColProduct>
            <HeaderColRight>{__('Cena / ks')}</HeaderColRight>
            <HeaderColCenter>{__('Množstvo')}</HeaderColCenter>
            <HeaderColRight>{__('Cena spolu')}</HeaderColRight>
          </HeaderRow>
        </Header>
        <List>
          {(setList?.search || []).map((set, i) => {
            const count = this.getSetCount(set);
            const price = this.getSetPrice(set);
            return (
              <React.Fragment key={i}>
                <Item>
                  <InfoCol>
                    <Info>
                      <Name className={'big'}>
                        {__('Zoznam pre')} {set?.fullName}
                      </Name>
                    </Info>
                  </InfoCol>

                  <PriceCol>
                    <Price>{formatPrice(price, 'EUR')}</Price>
                  </PriceCol>
                  <AmountCol>
                    <InputSpinner
                      value={count}
                      onChange={this.onItemCountChange.bind(null, set?.id)}
                      min={0}
                    />
                  </AmountCol>
                  <PriceCol>
                    <Price>{formatPrice(count * price, 'EUR')}</Price>
                  </PriceCol>
                </Item>
                <EmptyRow>
                  <EmptyTd />
                  <EmptyTd />
                  <EmptyTd />
                  <EmptyTd />
                </EmptyRow>
              </React.Fragment>
            );
          })}
        </List>
        <TotalPriceRow>
          <TotalPriceText>{__('Celkom s DPH')}</TotalPriceText>
          <PriceCart>{formatPrice(this.getTotalPrice(), 'EUR')}</PriceCart>
        </TotalPriceRow>

        <Form
          onSubmit={this.onSubmit}
          render={({ handleSubmit }) => (
            <FormOrder onSubmit={handleSubmit}>
              <Field name="note" type="textarea">
                {({ input, meta }) => (
                  <>
                    <OrderLabel htmlFor="note">
                      {__('Poznámka k objednávke')}
                    </OrderLabel>
                    <OrderTextarea rows={3} {...input} />
                    {meta.error && meta.touched && (
                      <ErrorPopup>{meta.error}</ErrorPopup>
                    )}
                  </>
                )}
              </Field>

              <OrderHeading className="in-content small">
                {__('Kontaktná osoba')}
              </OrderHeading>

              <OrderRow>
                <Field
                  name="name"
                  validate={required}
                  defaultValue={userDetail?.contact_name}
                >
                  {({ input, meta }) => (
                    <OrderCol>
                      <OrderLabel htmlFor="name">{__('Meno')}</OrderLabel>
                      <OrderInput
                        {...input}
                        error={meta.error && meta.touched}
                      />
                      {meta.error && meta.touched && (
                        <ErrorPopup>{meta.error}</ErrorPopup>
                      )}
                    </OrderCol>
                  )}
                </Field>

                <Field
                  name="email"
                  validate={composeValidators(required, email)}
                  defaultValue={userDetail?.email}
                >
                  {({ input, meta }) => (
                    <OrderCol>
                      <OrderLabel htmlFor="email">{__('E-mail')}</OrderLabel>
                      <EmailAutocomplete
                        {...input}
                        error={meta.error && meta.touched}
                      />
                      {meta.error && meta.touched && (
                        <ErrorPopup>{meta.error}</ErrorPopup>
                      )}
                    </OrderCol>
                  )}
                </Field>

                <Field
                  name="phone"
                  validate={composeValidators(required, phone, phoneMobile)}
                  defaultValue={userDetail?.telefon}
                >
                  {({ input, meta }) => (
                    <OrderCol>
                      <OrderLabel htmlFor="phone">
                        {__('Mobilné číslo')}
                      </OrderLabel>
                      <OrderInput
                        {...input}
                        type="tel"
                        error={meta.error && meta.touched}
                      />
                      {meta.error && meta.touched && (
                        <ErrorPopup>{meta.error}</ErrorPopup>
                      )}
                    </OrderCol>
                  )}
                </Field>
              </OrderRow>

              <Submit
                className="primary lg"
                type="submit"
                onClick={scrollToError}
              >
                {__('Odoslať')}
              </Submit>
            </FormOrder>
          )}
        />
      </Loader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    setList: state.myAccount.setList,
    setBulkOrderText: state.myAccount.setBulkOrderText,
    userDetail: state.myAccount.userDetail,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'SetBulkOrder' })(SetBulkOrder),
);
