import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { formatStringDate } from '../../../utilities';
import { Link } from 'react-router';
import Pagination from '../../Pagination/Pagination';
import { push } from 'react-router-redux';

const Wrapper = styled.div`
  display: block;
`;

const ListWrapper = styled.div`
  display: block;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) => theme.media('md')} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Article = styled.article`
  display: block;
  padding: ${rem(10)};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.color.gray93};
  background-color: ${({ theme }) => theme.color.white};
  color: #383838;
  font-weight: 500;
  word-wrap: break-word;
  flex: 1 0 21%;
  padding-bottom: 0px;
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: ' ';
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;

const Anchor = styled(Link)`
  display: block;
  position: relative;
  &:hover {
    text-decoration: none;
  }
`;

const Title = styled.h2`
  font-size: ${rem(15)};
  font-weight: 700;
  color: #211e1f;
  text-align: center;
`;

const Image = styled.img`
  min-width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  object-fit: cover;
  height: 140px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #88888888;
  &:hover {
    opacity: 1;
  }
`;

const FaPlus = styled.b`
  color: white;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
`;

interface PropsItem {
  article: any;
}

interface PropsList {
  articles: any;
  currentPage: number;
  totalPages: number;
  pathName: string;
  onClick: () => void;
  onLoaded: () => void;
  dispatch;
}

class PictureTitleListItem extends React.PureComponent<PropsItem> {
  public render() {
    this.props.article.date = formatStringDate(this.props.article.custom_date);

    return (
      <Article id={this.props.article.id}>
        <ImageContainer>
          <Anchor to={this.props.article.url}>
            <Image
              src={this.props.article.image}
              width={175}
              height={140}
              w={175}
              h={140}
            />
            <Overlay>
              <FaPlus>+</FaPlus>
            </Overlay>
          </Anchor>
        </ImageContainer>
        <Anchor to={this.props.article.url}>
          <Title>{this.props.article.name}</Title>
        </Anchor>
      </Article>
    );
  }
}

class PictureTitleList extends React.PureComponent<PropsList> {
  public onPageChange = (page) => {
    const pathName = this.props.pathName + '?page=' + page;
    this.props.dispatch(push(pathName));
  };

  public render() {
    this.props.onLoaded();

    return (
      <Wrapper>
        {this.props.totalPages > 1 ? (
          <Pagination
            current={this.props.currentPage}
            total={this.props.totalPages}
            onPageChanged={this.onPageChange}
          />
        ) : (
          ''
        )}

        <ListWrapper>
          {this.props.articles.map((item) => {
            return <PictureTitleListItem key={item.id} article={item} />;
          })}
        </ListWrapper>

        {this.props.totalPages > 1 ? (
          <Pagination
            current={this.props.currentPage}
            total={this.props.totalPages}
            onPageChanged={this.onPageChange}
          />
        ) : (
          ''
        )}
      </Wrapper>
    );
  }
}

export default PictureTitleList;
