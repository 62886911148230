import styled, { css } from 'styled-components';
import { Col, Row } from '../../theme/libraries/grid';
import { rem } from 'polished';

const galleryWidth = 360;
const galleryGap = 18;

export const ProductRow = styled(Row)`
  margin-bottom: ${rem(20)};
`;

export const GalleryCol = styled(Col)`
  ${({ theme }) => theme.media(1024)} {
    width: ${({ theme }) => rem(galleryWidth + theme.grid.padding)};
    position: relative;
  }
`;

export const ProductMetaCol = styled(Col)`
  ${({ theme }) => theme.media(1024)} {
    padding-left: ${({ theme }) =>
      rem(galleryWidth + galleryGap + theme.grid.padding)};
    margin-left: ${({ theme }) => rem(-(galleryWidth + theme.grid.padding))};
  }
`;

export const ProductSmallThumbCol = css`
  flex: 0 0 ${rem(60)};
  padding-right: ${rem(15)};
  padding-left: 0;
`;

export const ProductSmallInfoCol = css`
  flex: 1 1 auto;
  overflow: hidden;
`;

export const ProductSmallPriceCol = css`
  flex: 1 1 1%;
  font-weight: 700;
  padding-right: 0;
  padding-left: ${rem(20)};
`;

export const ProductSmallName = css`
  margin: 0 0 ${rem(5)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProductSmallAmount = css`
  color: ${({ theme }) => theme.color.gray66};
  margin: 0;
`;
